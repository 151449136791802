<template>
  <div
    class="game-choose-words-container"
    :style="{ backgroundImage: 'url(\'' + bgImg + '\')' }"
  >
    <div class="left-container">
      <div class="phone-top">
        <p class="left-pinyin">{{ phonePinyin.join(' ') }}</p>
        <p class="left-hanzi">{{ phoneHanzi.join('') }}</p>
        <img
          @click="handleDeleteWord"
          src="@/assets/img/16-GAMES/G23-phone-number/delete.svg"
          alt=""
        />
      </div>
      <div class="phone-content-wrapper">
        <div
          class="phone-content"
          v-for="(item, index) in processedLevels[currentLevelIndex] &&
          processedLevels[currentLevelIndex].jsonData"
          :key="index"
          @click="handleImageClick(item)"
          @mouseover="hoveredItem = index"
          @mouseleave="hoveredItem = null"
        >
          <div class="img-item">
            <img
              :src="index === hoverId ? hoverImg : defaultImg"
              @mouseenter="getWordsId(index, (isHover = true))"
              @mouseleave="getWordsId(index, (isHover = false))"
              alt=""
            />
            <div class="text-container">
              <span class="pinyin">{{ item.pinyin }}</span>
              <span class="hanzi">{{ item.hanzi }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 确认按钮 -->
      <div class="btn" @click="handleConfirmAnswer">
        <img
          :src="
            isCorrect
              ? require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-submit-green.svg`)
              : require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-submit-red.svg`)
          "
          alt=""
        />
      </div>
    </div>
    <div class="right-container">
      <div class="right-img">
        <img
          :src="
            processedLevels[currentLevelIndex] &&
            processedLevels[currentLevelIndex].img
          "
          alt=""
          v-if="processedLevels[currentLevelIndex].img"
        />
      </div>
      <div class="speaker-wrapper" @click="handlePlayPinyinAudio">
        <AudioPlayer
          :isPlay="isStartPlaying"
          :audioUrl="
            processedLevels[currentLevelIndex] &&
            processedLevels[currentLevelIndex].audioSrc
          "
          :title="
            processedLevels[currentLevelIndex] &&
            processedLevels[currentLevelIndex].answer
          "
        />
      </div>
      <div class="right-bottom">
        <p class="right-pinyin"></p>
        <p class="right-hanzi"></p>
      </div>
      <div class="right-bottom">
        <p
          class="right-hanzi"
          v-if="
            processedLevels[currentLevelIndex] &&
            processedLevels[currentLevelIndex].img === ''
          "
        >
          ?
        </p>
      </div>
    </div>

    <Star :totalStarNumber="processedLevels.length" :currentIndex="starIndex" />
  </div>
</template>
<script>
import { getPinyinByZh } from '@/assets/js/axios/kidsApi'
import AudioPlayer from '@/components/Course/GamePage/AudioPlayer'
import Star from '@/components/Course/GamePage/Star'
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          bgImg: '',
          addLevelList: []
        }
      }
    }
  },
  components: {
    AudioPlayer,
    Star
  },
  data() {
    return {
      localData: {
        bgImg: require('@/assets/img/16-GAMES/G65-stories-3-cards/level1-background.svg'),
        processedLevels: [
          [
            {
              fieldName: 'img',
              label: '故事图片',
              type: 3,
              value:
                'https://static.nihaocafe.com/image/kids/845d6578-21fe-4e54-b9d9-f9226a230fdc.svg'
            },
            {
              fieldName: 'optionList',
              label: '汉字选项',
              type: 1,
              value: '他在说你好'
            },
            {
              fieldName: 'answer',
              label: '正确答案',
              type: 1,
              value: '你好'
            },
            {
              fieldName: 'audioSrc',
              label: '音频',
              type: 2,
              value:
                'https://static.nihaocafe.com/image/kids/6efb6647-5e25-43d0-84a2-a64f6cd56beb.mp3'
            }
          ]
        ]
      },
      bgImg: '',
      processedLevels: [],
      currentLevelIndex: 0,
      starIndex: 0,
      hoveredItem: null,
      hoverId: null,
      defaultImg: require('@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg'),
      hoverImg: require('@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg'),
      phonePinyin: [],
      phoneHanzi: [],
      isCorrect: false,
      isStartPlaying: null,
      clickImgSocketInfo: {},
      confirmSocketInfo: {},
      delSocketInfo: {},
      audioSocketInfo: {}
    }
  },
  computed: {
    currentLevel() {
      return this.componentProps.addLevelList[this.currentLevelIndex]
    }
  },
  methods: {
    async initData() {
      const { addLevelList, bgImg, isTemplatePrview } = this.componentProps
      if (isTemplatePrview) return
      this.bgImg = bgImg
      this.processedLevels = []
      if (addLevelList && addLevelList.length) {
        addLevelList.forEach((item, index) => {
          this.processedLevels[index] = {}
          item.forEach((ele) => {
            this.processedLevels[index][ele.fieldName] = ele.value
          })
        })
      }
      const allPromise = []
      this.processedLevels.forEach(async (level) => {
        if (level.optionList) {
          allPromise.push(this.getJsonData(level.optionList))
        }
      })

      const testArr = await Promise.all(allPromise)
      this.processedLevels = testArr.map((item, index) => {
        return {
          ...this.processedLevels[index],
          jsonData: item
        }
      })
    },
    getWordsId(index, isHover) {
      // 获取hover元素的id
      const data = {
        clickType: 20004,
        data: {
          value: {
            index,
            isHover
          }
        },
        text: '测试SyllabusPagePage组件内hover事件socket'
      }
      this.$bus.$emit('kids_webSocket_sendInfo', data)
    },

    /**
     * @description: 获取分词数据format
     * @param {*} value
     * @return {*}
     */
    async getJsonData(content) {
      if (!content) return
      const stringValue = content.split('')
      const promiseAll = []
      stringValue.forEach((item) => {
        promiseAll.push(getPinyinByZh({ content: item }))
      })

      const test = await Promise.all(promiseAll)
      if (test.length) {
        return test.map((item) => {
          const res = JSON.parse(item.data)
          return {
            hanzi: res[0].wordZh,
            pinyin: res[0].wordPy,
            id: res[0].wordZh + res[0].wordPy
          }
        })
      } else {
        return []
      }
    },

    /**
     * @description: 点击图片，将拼音和汉字添加到数组中
     * @param {*} item
     * @return {*}
     */
    handleImageClick(item, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickImgSocketInfo = {
          item,
          value: Math.random()
        }
      }
      this.phonePinyin.push(item.pinyin)
      this.phoneHanzi.push(item.hanzi)
    },

    /**
     * @description: 删除最后一个拼音和汉字
     * @param {*}
     * @return {*}
     */

    handleDeleteWord(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.delSocketInfo = {
          value: Math.random()
        }
      }
      this.phonePinyin.pop()
      this.phoneHanzi.pop()
    },

    /**
     * @description: 播放音频
     * @return {*}
     */
    handlePlayPinyinAudio(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.audioSocketInfo = {
          value: Math.random()
        }
      }
      this.isStartPlaying = Math.random()
    },

    /**
     * @description: 点击确认按钮，将输入的汉字与正确答案比较，判断是否正确
     * @return {*}
     */
    handleConfirmAnswer(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.confirmSocketInfo = {
          value: Math.random()
        }
      }
      const currentLevel = this.processedLevels[this.currentLevelIndex]
      const correctAnswer = currentLevel.answer

      if (this.phoneHanzi.join('') === correctAnswer) {
        this.isCorrect = true
        this.starIndex++
        if (this.currentLevelIndex < this.processedLevels.length - 1) {
          this.currentLevelIndex++
          this.resetLevelState()
        } else {
          startConfetti()
          playCorrectSound
        }
      } else {
        this.isCorrect = false
      }
    },

    /**
     * @description:   重置当前关卡状态
     * @return {*}
     */
    resetLevelState() {
      this.phonePinyin = []
      this.phoneHanzi = []
      this.isCorrect = false
    }
  },
  mounted() {
    this.$bus.$on('clickSpeakingSentenceImg', ({ item }) => {
      this.handleImageClick(item, true)
    })
    this.$bus.$on('clickToCheckAnswer', () => {
      this.handleConfirmAnswer('socket', true)
    })
    this.$bus.$on('clickSingleCardImg', () => {
      this.handleDeleteWord('socket', true)
    })
    this.$bus.$on('clickRecognitionPage', () => {
      this.handlePlayPinyinAudio('socket', true)
    })
    this.$bus.$on('hoverSyllabusPage', ({ index, isHover }) => {
      if (isHover) {
        this.hoverId = index
      } else {
        this.hoverId = null
      }
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickSpeakingSentenceImg')
    this.$bus.$off('clickToCheckAnswer')
    this.$bus.$off('clickSingleCardImg')
    this.$bus.$off('clickRecognitionPage')
    this.$bus.$off('hoverSyllabusPage')
  },
  watch: {
    componentProps: {
      async handler(value) {
        // console.log('触发了watch', value)

        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    },
    phoneHanzi: {
      handler(newVal) {
        this.isCorrect =
          this.processedLevels &&
          newVal.join('') ===
            this.processedLevels[this.currentLevelIndex].answer
      },
      deep: true
    },
    clickImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004602,
          data: { value },
          text: 'SpeakingSentence点击Img socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    confirmSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20058,
          data: { value },
          text: 'SpeakingSentence点击Img socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    delSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20059,
          data: { value },
          text: 'SpeakingSentence点击Img socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20002,
          data: { value },
          text: '测试RecognitionPage组件内点击事件socket'
        }
        this.$bus.$emit('kids_webSocket_sendInfo', data)
      },
      deep: true
    }
  }
}
</script>
<style scoped lang="scss">
.game-choose-words-container {
  width: 100%;
  height: 100%;
  border-radius: 56px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  -moz-background-size: 100% 100%;
  background-color: #ffdbb8;
}
.left-container {
  position: absolute;
  left: 10%;
  top: 10%;
  width: 42%;
  height: 80%;
  background: #ffffff;
  border: 15px solid #224e96;
  border-radius: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .phone-top {
    width: 80%;
    height: 50px;
    // background: blue;
    border-bottom: 2px solid #cfcecb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    img {
      position: absolute;
      right: 0;
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  .phone-content-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
    margin-left: 12px;
    transition: all 0.3s ease-out;
    cursor: pointer;
  }
  .phone-content {
    margin-top: 10px;
    min-width: 80px;
    flex-direction: row;
    flex: 0 0 30%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .img-item {
      max-width: 100%;
      height: auto;
      position: relative;
    }
    img {
      width: 100%;
      // display:block;
    }
  }
  .text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    pointer-events: none;
    .pinyin {
      display: block;
      font-size: 14px;
    }
    .hanzi {
      display: block;
      font-size: 18px;
      margin-top: 4px;
    }
  }

  .btn {
    width: 30%;
    cursor: pointer;
    margin-top: 10px;
    &:hover {
      opacity: 0.7;
    }
    img {
      width: 100%;
    }
  }
}
.right-container {
  background: #ce524c;
  position: absolute;
  width: 30%;
  height: 40%;
  top: 27%;
  right: 10%;
  border-radius: 28px;
  .right-img {
    width: 100%;
    height: 100%;
    border-radius: 28px;
    margin-top: -2px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      border-radius: 28px;
      object-fit: cover;
    }
  }
  .right-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 5%;
    .right-hanzi {
      font-size: 28px;
    }
  }
}
.left-pinyin {
  font-size: 16px;
}
.left-hanzi {
  font-size: 22px;
}
.speaker-wrapper {
  margin-top: 120px;
  position: absolute;
  top: 76%;
  right: 22%;
  width: 60px;
  height: 65px;
  background-color: #cd4c3f;
  border-radius: 50%;
  text-align: center;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
</style>
