var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-photo-container" }, [
    _c(
      "div",
      {
        staticClass: "finger",
        on: {
          click: function ($event) {
            return _vm.handleClickCamera()
          },
        },
      },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/img/17-Tutorials/instructions.svg"),
            alt: "",
          },
        }),
      ]
    ),
    _c(
      "div",
      { staticClass: "photo-content" },
      [
        _c("SceneSwitchThumbnails", {
          attrs: { buttons: _vm.buttonList, numBers: _vm.numBers },
          on: { changeTheNumbers: _vm.changeTheNumbers },
        }),
        _c("img", {
          staticClass: "backgoround-photo",
          attrs: {
            src: require("@/assets/img/FixedPicture/gamePhotoBg.svg"),
            alt: "",
          },
        }),
        _c(
          "div",
          { staticClass: "photo-area" },
          [
            !_vm.imgList[this.numBers - 1].answerImg
              ? _c("img", {
                  staticClass: "photo-border",
                  attrs: {
                    src: require("@/assets/img/FixedPicture/photoBorderTransition.svg"),
                    alt: "",
                  },
                })
              : _vm._e(),
            _vm.imgList[this.numBers - 1].answerImg
              ? _c("img", {
                  staticClass: "photo-border",
                  attrs: {
                    src: require("@/assets/img/FixedPicture/photoBorder.svg"),
                    alt: "",
                  },
                })
              : _vm._e(),
            _vm._l(_vm.imgList, function (item, index) {
              return [
                index === _vm.numBers - 1
                  ? _c("div", { key: index, staticClass: "photo-item" }, [
                      item.answerImg || item.grayImg
                        ? _c("img", {
                            attrs: {
                              src: item.hasClicked
                                ? item.answerImg
                                : item.grayImg,
                              alt: "",
                            },
                          })
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
        _c("div", { staticClass: "customlesson-conter-right-text" }, [
          _vm.titleInfo && Object.keys(_vm.titleInfo).length
            ? _c("div", { staticClass: "right-text" }, [
                _c("span", { staticClass: "pinyin font-pinyin-medium" }, [
                  _vm._v(_vm._s(_vm.titleInfo.pinyin)),
                ]),
                _c("span", { staticClass: "font-hanzi-medium" }, [
                  _vm._v(_vm._s(_vm.titleInfo.hanzi)),
                ]),
              ])
            : _vm._e(),
          _vm._m(0),
          _c(
            "div",
            { staticClass: "bottom-text" },
            [
              _vm._l(_vm.imgList, function (item, index) {
                return [
                  index === _vm.numBers - 1 &&
                  item.hasClicked &&
                  (item.wordPy || item.wordZh)
                    ? _c(
                        "div",
                        { key: index + "textarea", staticClass: "text-info" },
                        [
                          _c(
                            "span",
                            { staticClass: "pinyin font-pinyin-medium" },
                            [_vm._v(_vm._s(item.wordPy))]
                          ),
                          _c("span", { staticClass: "font-hanzi-medium" }, [
                            _vm._v(_vm._s(item.wordZh)),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "camera" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/img/16-GAMES/G04-photo-studio/camera.svg"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }