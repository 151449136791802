<template>
  <div class="game-prcatice-container">
    <div class="game-content">
      <div class="game-background">
        <Star
          :totalStarNumber="totalStars"
          :currentIndex="answersfound"
          v-if="totalStars"
        />
        <div class="backgroundImg_n_DragArea">
          <div
            class="imageWrapper"
            v-if="ObjectList[question] && ObjectList[question].image"
          >
            <img class="objectDisplay" :src="ObjectList[question].image" />
          </div>
          <div class="bottonsWrapper">
            <div
              v-for="(item, index) in chineseList"
              :key="index"
              class="content-words-two"
              @click="foundAnswer(index)"
            >
              <span class="font-pinyin-medium pinyin">{{ item.pinying }}</span>
              <span class="font-hanzi-medium">{{ item.hanzi }}</span>
            </div>
          </div>
        </div>
      </div>
      <CommonButton
        @shuffleClickEvent="handleShuffleClickEvent"
        v-if="answersfound === 0"
      />
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import Star from "@/components/Course/GamePage/Star";
import CommonButton from "../GameComponent/CommonButton.vue";
import { templatePreviewMixin } from "./templatePreviewMixin.js";
export default {
  mixins: [templatePreviewMixin],
  components: {
    Star,
    CommonButton,
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          practiceList: [],
        };
      },
    },
  },
  mounted() {
    // this.initData()
    this.$bus.$on("RecognitionNumberClick", ({ index }) => {
      this.foundAnswer(index, true);
    });
    this.$bus.$on("shuffleArray", ({ ramdomList }) => {
      this.ObjectList = [];
      this.ObjectList = ramdomList;

      this.shuffleSocketValue = true;
    });
  },
  beforeDestroy() {
    this.$bus.$off("RecognitionNumberClick");
    this.$bus.$off("shuffleArray");
  },
  watch: {
    socketInfo: {
      handler(value) {
        const data = {
          clickType: 20019,
          data: { value },
          text: "RecognitionNumberGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    shuffleSocketInfo: {
      handler(value) {
        if (!this.shuffleSocketValue) {
          const data = {
            clickType: 2001004,
            data: { value },
            text: "DragGameBtn shuffleSocketValue",
          };
          this.$bus.$emit("kids_webSocket_sendInfo", data);
        }
      },
      deep: true,
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value;
        if (isTemplatePrview) return;
        this.initData();
      },
      deep: true,
    },
  },
  data() {
    return {
      localData: {
        ObjectList: [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G41-choose-abcde/lesson-25/image-1.svg"),
            number: 1,
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G41-choose-abcde/lesson-25/image-2.svg"),
            number: 2,
            showObj: true,
          },
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G41-choose-abcde/lesson-25/image-3.svg"),
            number: 1,
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G41-choose-abcde/lesson-25/image-4.svg"),
            number: 2,
            showObj: true,
          },
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G41-choose-abcde/lesson-25/image-5.svg"),
            number: 1,
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G41-choose-abcde/lesson-25/image-6.svg"),
            number: 2,
            showObj: true,
          },
        ],
        chineseList: [
          {
            id: 1,
            pinying: "zhè",
            number: 1,
            hanzi: "这",
          },
          {
            id: 2,
            pinying: "nà",
            number: 2,
            hanzi: "那",
          },
        ],
        totalStars: 6,
        answersfound: 0,
      },
      ObjectList: [],
      chineseList: [],
      answersfound: 0,
      totalStars: null,
      isLastStep: false,

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 5,
            numBerValueAfterClick: null,
          },
          {
            type: "next",
            startNumberRange: 5,
            endNumberRange: 6,
            numBerValueAfterClick: 6,
          },
        ],
      },

      question: 0,

      success_audio_sound: require("@/assets/audio/correct_answer.mp3"),
      wrong_audio_sound: require("@/assets/audio/wrong_answer.mp3"),
      objectDisplay: "",
      socketInfo: {},
      shuffleSocketInfo: {},
      shuffleSocketValue: null,
      foundAnswerValue: 0,
    };
  },
  methods: {
    /**
     * @description: 乱序回调
     * @return {*}
     */
    handleShuffleClickEvent() {
      const orderList = this.shuffleArray(this.ObjectList);
      this.ObjectList = [];
      this.ObjectList = orderList;

      this.shuffleSocketValue = false;

      this.shuffleSocketInfo = {
        ramdomList: this.ObjectList,
        ramdomIndex: Math.random(),
      };
    },
    initData() {
      //   console.log(this.componentProps, '----')
      this.chineseList = [];
      this.ObjectList = [];
      if (
        this.componentProps.practiceList &&
        this.componentProps.practiceList.length
      ) {
        this.componentProps.practiceList.forEach((item, index) => {
          this.chineseList.push({
            id: index + 1,
            pinying: item[0].value,
            number: index + 1,
            hanzi: item[1].value,
            srcList:
              item[2].value && item[2].value.length
                ? item[2].value.map((ele) => {
                    return {
                      id: index + 1,
                      image: ele.value,
                      number: index + 1,
                      showObj: true,
                    };
                  })
                : [],
          });
        });
        const list = this.chineseList.map((item) => item.srcList);

        this.ObjectList = list.flat();
        this.totalStars = this.ObjectList.length;
        // console.log(this.ObjectList,'ObjectList');
      }
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
    foundAnswer(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.socketInfo = {
          index,
          value: Math.random(),
        };
        this.foundAnswerValue++;
      }

      //  let elementList = this.$refs[`SI_${this.draggedStroke}`];
      //   console.log(
      //     'button clicked 1:' +
      //       this.chineseList[index].number +
      //       ' 2: ' +
      //       this.ObjectList[this.question].number
      //   )
      if (
        this.chineseList[index].number == this.ObjectList[this.question].number
      ) {
        //  this.objectDisplay = this.ObjectList[this.question].image
        // this.ObjectList[this.question].showObj = false;
        this.answersfound++;
        this.timer = setTimeout(() => {
          if (this.question + 1 < this.ObjectList.length) {
            this.question++;
            this.objectDisplay = "";
          } else {
            this.isLastStep = true;
            playCorrectSound();
            startConfetti();
          }
        }, 1000);
        // if (this.answersfound == 5) {
        //   this.isLastStep = true
        //   playCorrectSound()
        //   startConfetti()
        // } else {
        //   playCorrectSound(true, false)
        // }
      } else {
        playCorrectSound(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.game-prcatice-container {
  width: 100%;
  height: 100%;
  line-height: 1;
  .game-content {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 48px;
    position: relative;
    overflow: hidden;
    .game-background {
      text-align: -webkit-center;
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url("../../../../assets/img/03-Backgrounds/background-practice.svg");
      border-radius: 48px;
      margin: auto;
      overflow: hidden;
      .backgroundImg_n_DragArea {
        position: relative;
        width: -moz-fit-content;
        background-color: #214e96;
        width: 70%;
        height: 75%;
        top: 12%;
        flex-direction: column;
        border-radius: 30px;
        display: flex;
        .imageWrapper {
          position: absolute;
          width: 90%;
          height: 60%;
          background: #ffffff;
          top: 5%;
          left: 5%;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          .objectDisplay {
            width: 100%;
            height: 100%;
          }
        }
        .bottonsWrapper {
          display: flex;
          bottom: 0;
          height: -moz-fit-content;
          justify-content: center;
          height: 30%;
          width: 80%;
          position: absolute;
          left: 10%;
          border-top-right-radius: 30px;
          border-top-left-radius: 30px;
          justify-content: space-evenly;
          align-items: center;
          .content-words-two {
            height: 10vh;
            width: 10vh;
            z-index: 1;
            border-radius: 15px;
            background-color: #cd4c3f;
            border-bottom: 8px solid #ac3d32;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin: 0 4px;
            span {
              color: #fff;
              font-size: 25px;
            }
            span:nth-child(2) {
              color: #fff;
              font-size: 30px;
            }
          }
          .content-words-two:active {
            transform: scale(0.95);
          }
          .content-words-two:hover {
            transform: scale(1.1);
            border-radius: 15px;
            background-color: #912016;
            border-top: 8px solid #6e0c03;
            border-bottom: 0px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            span {
              color: #fff;
              font-size: 25px;
            }
            span:nth-child(2) {
              color: #fff;
              font-size: 30px;
            }
          }
        }
      }
    }
  }
}
</style>
