<template>
  <div class="game-photo-container">
    <div class="finger" @click="handleClickCamera()">
      <img src="@/assets/img/17-Tutorials/instructions.svg" alt="" />
    </div>
    <div class="photo-content">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="numBers"
        @changeTheNumbers="changeTheNumbers"
      >
      </SceneSwitchThumbnails>
      <img
        :src="require('@/assets/img/FixedPicture/gamePhotoBg.svg')"
        alt=""
        class="backgoround-photo"
      />
      <div class="photo-area">
        <img
          :src="require('@/assets/img/FixedPicture/photoBorderTransition.svg')"
          v-if="!imgList[this.numBers - 1].answerImg"
          alt=""
          class="photo-border"
        />
        <img
          :src="require('@/assets/img/FixedPicture/photoBorder.svg')"
          v-if="imgList[this.numBers - 1].answerImg"
          alt=""
          class="photo-border"
        />

        <template v-for="(item, index) in imgList">
          <div :key="index" v-if="index === numBers - 1" class="photo-item">
            <img
              :src="item.hasClicked ? item.answerImg : item.grayImg"
              alt=""
              v-if="item.answerImg || item.grayImg"
            />
          </div>
        </template>
      </div>

      <div class="customlesson-conter-right-text">
        <div
          class="right-text"
          v-if="titleInfo && Object.keys(titleInfo).length"
        >
          <span class="pinyin font-pinyin-medium">{{ titleInfo.pinyin }}</span>
          <span class="font-hanzi-medium">{{ titleInfo.hanzi }}</span>
        </div>
        <div class="camera">
          <img src="@/assets/img/16-GAMES/G04-photo-studio/camera.svg" alt="" />
        </div>
        <div class="bottom-text">
          <template v-for="(item, index) in imgList">
            <div
              :key="index + 'textarea'"
              v-if="
                index === numBers - 1 &&
                item.hasClicked &&
                (item.wordPy || item.wordZh)
              "
              class="text-info"
            >
              <span class="pinyin font-pinyin-medium">{{ item.wordPy }}</span>
              <span class="font-hanzi-medium">{{ item.wordZh }}</span>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import SceneSwitchThumbnails from '../GameComponent/SceneSwitchThumbnails.vue'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    SceneSwitchThumbnails
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          titleZh: '',
          titlePy: '',
          photoList: []
        }
      }
    }
  },
  watch: {
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 30005,
          data: { value },
          text: 'practicePage页面按钮socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      }
    },
    clickAsideSocketInfo: {
      handler(value) {
        const data = {
          clickType: 3000501,
          data: { value },
          text: 'practicePage页面按钮socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      }
    },
    numBers: {
      handler(value) {
        if (value % 2 == 0) {
          this.nextStop = true
          // console.log(value)
        }
      }
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  mounted() {
    // this.initData()
    this.$bus.$on('clickPractFourPage', () => {
      this.handleClickCamera('socket', true)
    })
    this.$bus.$on('clickPractFourPageMenu', ({ index }) => {
      this.changeTheNumbers(index, true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickPractFourPage')
    this.$bus.$off('clickPractFourPageMenu')
  },
  data() {
    return {
      localData: {
        buttonList: [
          {
            image: require('@/assets/img/16-GAMES/G04-photo-studio/lesson-48/menu-1.svg'),
            startNumberRange: 1,
            endNumberRange: 1
          },
          {
            image: require('@/assets/img/16-GAMES/G04-photo-studio/lesson-48/menu-2.svg'),
            startNumberRange: 2,
            endNumberRange: 2
          },
          {
            image: require('@/assets/img/16-GAMES/G04-photo-studio/lesson-48/menu-1.svg'),
            startNumberRange: 3,
            endNumberRange: 3
          },
          {
            image: require('@/assets/img/16-GAMES/G04-photo-studio/lesson-48/menu-2.svg'),
            startNumberRange: 4,
            endNumberRange: 4
          }
        ],
        imgList: [
          {
            grayImg: require('@/assets/img/16-GAMES/G04-photo-studio/lesson-48/photo-1-1.svg'),
            answerImg: require('@/assets/img/16-GAMES/G04-photo-studio/lesson-48/photo-1-2.svg'),
            hasClicked: false,
            position: 0
          },
          {
            grayImg: require('@/assets/img/16-GAMES/G04-photo-studio/lesson-48/photo-2-1.svg'),
            answerImg: require('@/assets/img/16-GAMES/G04-photo-studio/lesson-48/photo-2-2.svg'),
            hasClicked: false,
            position: 1
          },
          {
            grayImg: require('@/assets/img/16-GAMES/G04-photo-studio/lesson-48/photo-3-1.svg'),
            answerImg: require('@/assets/img/16-GAMES/G04-photo-studio/lesson-48/photo-3-2.svg'),
            hasClicked: false,
            position: 0
          },
          {
            grayImg: require('@/assets/img/16-GAMES/G04-photo-studio/lesson-48/photo-4-1.svg'),
            answerImg: require('@/assets/img/16-GAMES/G04-photo-studio/lesson-48/photo-4-2.svg'),
            hasClicked: false,
            position: 1
          }
        ],
        titleInfo: {
          pinyin: '...ma',
          hanzi: '……吗？'
        }
      },
      buttonList: [],
      imgList: [],
      titleInfo: {},
      numBers: 1, //start with the finder

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'speak',
            startNumberRange: 1,
            endNumberRange: 100
          }
        ]
      },
      clickSocketInfo: {},
      clickAsideSocketInfo: {},
      buttonImgList: [
        {
          id: 1,
          image: require('@/assets/img/FixedPicture/yellow-white-question.svg')
        },
        {
          id: 2,
          image: require('@/assets/img/FixedPicture/bulb.svg')
        }
      ]
    }
  },
  methods: {
    initData() {
      console.log(this.componentProps, '0000')
      if (this.componentProps.titlePy || this.componentProps.titleZh) {
        this.titleInfo = {
          pinyin: this.componentProps.titlePy,
          hanzi: this.componentProps.titleZh
        }
      }
      this.imgList = []
      this.buttonList = []
      if (
        this.componentProps.photoList &&
        this.componentProps.photoList.length
      ) {
        this.componentProps.photoList.forEach((item, index) => {
          this.imgList.push({
            answerImg: item[1].value,
            hasClicked: false,
            grayImg: item[0].value,
            wordZh: item[2].value,
            wordPy: item[3].value
          })
          this.buttonList.push({
            image: this.buttonImgList[index % this.buttonImgList.length].image,
            startNumberRange: index + 1,
            endNumberRange: index + 1
          })
        })
      }
      //   console.log('-------', this.imgList)
    },
    changeTheNumbers(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickAsideSocketInfo = {
          index,
          value: Math.random()
        }
      }
      this.numBers = index
    },
    handleClickCamera(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickSocketInfo = {
          value: Math.random()
        }
      }
      playCorrectSound(true, false)
      //   this.$emit('changeTheBgImg', this.numBers)
      this.imgList[this.numBers - 1].hasClicked = true

      if (
        this.numBers === this.imgList.length &&
        this.imgList[this.numBers - 1].hasClicked
      ) {
        playCorrectSound()
        startConfetti()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.game-photo-container {
  width: 100%;
  height: 100%;
  position: relative;
  .finger {
    position: absolute;
    bottom: 0;
    left: 22%;
    z-index: 10;
    width: 45%;
    cursor: pointer;
    img {
      width: 80%;
    }
  }
  .photo-content {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-color: #f7f4e0;
    border-radius: 58px;
    position: relative;
    .backgoround-photo {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    .photo-area {
      position: relative;
      width: 100%;
      height: 100%;
      .photo-border {
        width: 30%;
        position: absolute;
        top: 15%;
        left: 35%;
        max-width: 300px;
      }
      .photo-item {
        position: absolute;
        width: 26%;
        top: 23%;
        left: 37%;
        max-width: 250px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    // .imgLeft {
    //   pointer-events: none;
    //   width: 100%;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   img {
    //     width: 70%;
    //   }
    //   .customlesson-picture-text {
    //     position: absolute;
    //     top: 77%;
    //     left: 50%;
    //     z-index: 12;
    //     transform: rotate(-7deg);
    //     span {
    //       color: #cd4c3f;
    //       font-size: 50px;
    //       font-family: 'GenSenRoundedTW';
    //       display: inline-block;
    //     }
    //   }
    // }
    // .imgRight {
    //   width: 100%;
    //   position: absolute;
    //   top: 0;
    //   left: 40%;
    //   img {
    //     width: 60%;
    //   }
    //   .customlesson-picture-text {
    //     position: absolute;
    //     top: 79%;
    //     left: 10%;
    //     z-index: 13;
    //     transform: rotate(7deg);
    //     span {
    //       color: #fff;
    //       font-size: 50px;
    //       font-family: 'GenSenRoundedTW';
    //       display: inline-block;
    //     }
    //   }
    //   .user-avatar {
    //     position: absolute;
    //     top: 38%;
    //     width: 14%;
    //     height: 26%;
    //     left: 11%;
    //     border-radius: 50%;
    //   }
    // }
    .customlesson-conter-right-text {
      width: 98%;
      // height: 95%;
      .right-text {
        background-color: #cd4c3f;
        border-top-right-radius: 29px;
        border-bottom-right-radius: 29px;
        position: absolute;
        top: 6%;
        left: 0;
        display: flex;
        justify-content: center;
        flex-flow: column;
        z-index: 9;
        width: auto;
        height: auto;
        padding: 3%;

        span:nth-child(1) {
          color: #ffffff;
          font-size: 35px;
        }
        span:nth-child(2) {
          color: #ffffff;
          font-size: 50px;
          font-family: 'GenSenRoundedTW';
        }
      }
      .camera {
        position: absolute;
        bottom: 0;
        left: 10%;
        width: 25%;
        img {
          width: 100%;
          // cursor: pointer;
        }
      }
      .bottom-text {
        width: 100%;
        .text-info {
          position: absolute;
          bottom: 15%;
          right: 0;
          padding: 3%;
          color: #ffffff;
          background-color: #cd4c3f;
          border-top-left-radius: 29px;
          border-bottom-left-radius: 29px;
          display: flex;
          flex-direction: column;
          width: auto;
          height: auto;
          justify-content: center;
          span:nth-child(1) {
            color: #ffffff;
            font-size: 35px;
          }
          span:nth-child(2) {
            color: #ffffff;
            font-size: 50px;
            font-family: 'GenSenRoundedTW';
          }
        }
      }
    }
  }
}
</style>
