<template>
  <div class="container">
    <div class="mainCont">
      <Star :totalStarNumber="optionList.length" :currentIndex="steps" />
      <div class="background">
        <img class="bg-image" :src="bgImage" />
        <div class="backgroundImg_n_DragArea">
          <div class="drop-area" :class="{ startDrop: isStartDrop }">
            <img
              v-for="(item, index) in optionList[steps]"
              :key="index + 'rain'"
              class="drop-item"
              :style="{
                bottom: Math.random() * 40 + '%',
                left: Math.random() * 10 + 20 * (index + 1) + '%'
              }"
              :src="item.image"
              @click="foundAnswer(item, index)"
            />
          </div>
        </div>

        <div class="answer-area">
          <div class="answer-box">
            <div
              v-for="(item, index) in answerList[steps]"
              :key="index"
              class="answer-item"
            >
              <span v-if="item.isShow" class="pinyin font-pinyin-medium">{{
                item.answerPy
              }}</span>
              <span v-if="item.isShow" class="font-hanzi-medium">{{
                item.answerZh
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Star from '@/components/Course/GamePage/Star'
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    Star
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          bgImage: '',
          optionList: []
        }
      }
    }
  },
  data() {
    return {
      steps: 0,
      bgImage: '',
      optionList: [],
      answerList: [],
      // 测试数据
      localData: {
        steps: 0,
        correctAnswer: [],
        bgImage: require('@/assets/img/16-GAMES/G53-drops/background.svg'),
        optionList: [
          [
            {
              index: 1,
              image: require('@/assets/img/16-GAMES/G53-drops/lesson-56/drop-xing.svg'),
              isAnswer: true
            },
            {
              image: require('@/assets/img/16-GAMES/G53-drops/lesson-56/drop-shui.svg')
            },
            {
              image: require('@/assets/img/16-GAMES/G53-drops/lesson-56/drop-er.svg')
            }
          ],
          [
            {
              image: require('@/assets/img/16-GAMES/G53-drops/lesson-56/drop-mi.svg')
            },
            {
              image: require('@/assets/img/16-GAMES/G53-drops/lesson-56/drop-dian.svg'),
              isAnswer: true,
              index: 2
            },
            {
              image: require('@/assets/img/16-GAMES/G53-drops/lesson-56/drop-you.svg')
            }
          ],
          [
            {
              image: require('@/assets/img/16-GAMES/G53-drops/lesson-56/drop-chang.svg')
            },
            {
              image: require('@/assets/img/16-GAMES/G53-drops/lesson-56/drop-me.svg')
            },
            {
              image: require('@/assets/img/16-GAMES/G53-drops/lesson-56/drop-qu.svg'),
              isAnswer: true,
              index: 3
            }
          ]
        ],
        answerList: [
          [
            {
              answerPy: 'gāo',
              answerZh: '高',
              isChooseCorrect: true,
              isShow: true,
              id: 1
            },
            {
              id: 1,
              answerZh: '兴',
              answerPy: 'xìng',
              isChooseCorrect: false
            }
          ],
          [
            {
              id: 2,
              answerZh: '商',
              answerPy: 'shāng',
              isChooseCorrect: true,
              isShow: true
            },
            {
              id: 2,
              answerZh: '店',
              answerPy: 'diàn',
              isChooseCorrect: false
            }
          ],
          [
            {
              id: 3,
              answerZh: '去',
              answerPy: 'qù',
              isChooseCorrect: false
            },
            {
              id: 3,
              answerZh: '哪',
              answerPy: 'nǎ',
              isChooseCorrect: true,
              isShow: true
            },
            {
              id: 3,
              answerZh: '儿',
              answerPy: 'ér',
              isChooseCorrect: true,
              isShow: true
            }
          ]
        ],
        isStartDrop: true
      },
      correctIndex: [],
      correctAnswer: [],
      isStartDrop: true,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'speak',
            startNumberRange: 0,
            endNumberRange: 100
          }
        ]
      },
      isLastStep: false,
      rainSocketInfo: {}
    }
  },
  mounted() {
    // this.isStartDrop = true
    this.$bus.$on('clickRainDrop', ({ item, index }) => {
      this.foundAnswer(item, index, true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickRainDrop')
  },
  watch: {
    rainSocketInfo: {
      handler(value) {
        // console.log(value)
        const data = {
          clickType: 20038,
          data: { value },
          text: 'RainDropGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  methods: {
    initData() {
      this.correctAnswer = []
      this.componentProps.optionList.forEach((item) => {
        if (item[1].value) {
          this.correctIndex = item[1].options.map((ele, index) => {
            if (item[1].value === ele.value) {
              this.correctAnswer.push(index)
              return index
            }
          })
        }
      })
     //  console.log(this.correctAnswer, '111')

      this.answerList = []
      this.bgImage = this.componentProps.bgImage
      this.answerList = this.componentProps.optionList.map((item, index) => {
        return item[2].value.map((ele) => {
          return {
            answerPy: ele[0].value,
            answerZh: ele[1].value,
            isShow: ele[2].value,
            id: ele[2].value ? null : this.correctAnswer[index]
          }
        })
      })
      // console.log(this.answerList, '0000')


      this.optionList = []
      this.optionList = this.componentProps.optionList.map((item, index) => {
        return item[0].value.map((ele, indexSon) => {
          return {
            indexSon,
            image: ele.value,
            isAnswer: indexSon === this.correctAnswer[index]
            // isAnswer: index === 0
          }
        })
      })
      // console.log(this.optionList, '222')
    },
    updateNumbers(value) {
      this.numBers = value
    },
    nextPage() {
      this.$bus.$emit('nextButton', true)
    },
    foundAnswer(item, index, isFromSocket = false) {
      this.answerList[this.steps].forEach((ele) => {
        if (ele.id === item.indexSon) {
          ele.isShow = true
        }
      })
      if (!isFromSocket) {
        this.rainSocketInfo = {
          item,
          index,
          value: Math.random()
        }
      }
      // console.log(item)
      if (item.isAnswer) {
        playCorrectSound(true, false)
      

        this.isStartDrop = false
        setTimeout(() => {
          this.isStartDrop = true
          this.steps++
          // this.initData()
          if (this.steps === this.answerList.length) {
            this.steps = this.answerList.length
            this.isLastStep = true
            playCorrectSound()
            startConfetti()
            this.isStartDrop = false
          }
        }, 1000)
      } else {
        playCorrectSound(false)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background: #fff;
}

.drop-area {
  height: 100%;
  width: 100%;
  top: -100%;
  position: absolute;
}

@keyframes dropAnim {
  0% {
    top: -100%;
  }

  100% {
    top: 0;
  }
}
.startDrop {
  animation: dropAnim 10s linear infinite;
  animation-direction: normal;
  animation-iteration-count: 100;
}

.drop-item {
  position: absolute;
  width: 13vh;
  cursor: pointer;
}
.drop-item:active {
  transform: scale(0.95);
}
.drop-item:hover {
  transform: scale(1.1);
}

.answer-area {
  bottom: 0;
  width: 50%;
  height: 150px;
  position: absolute;
  display: flex;
  justify-content: center;
  left: 25%;
  .redBackground {
    width: 100%;
    height: 50%;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    //@debug  background-color: #d87065;
    position: absolute;
    bottom: 0;
  }
  .answer-box {
    display: flex;
    bottom: 0;
    height: -moz-fit-content;
    justify-content: space-between;
    height: 90%;
    width: 78%;
    position: absolute;
    bottom: 10%;
    border-radius: 30px;
    border-top-left-radius: 30px;
    background-color: #f4d737;
    justify-content: space-evenly;
    align-items: center;
  }
}
.answer-item {
  max-width: 27%;
  min-width: 80px;
  min-height: 100px;
  z-index: 1;
  border-radius: 15px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  cursor: pointer;
  font-weight: 600;
  span {
    color: #224e96;
    font-size: 25px;
  }
  span:nth-child(2) {
    color: #224e96;
    font-size: 30px;
  }
  img {
    width: 60%;
  }
}

.mainCont {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 48px;
  position: relative;
  overflow: hidden;
}
.blueBg {
  background: #abeaff !important;
}
.background {
  text-align: -webkit-center;
  position: absolute;
  height: 100%;
  width: 100%;
  background: #f9f6e6;
  border-radius: 48px;
  margin: auto;
  overflow: hidden;
  .bg-image {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
    object-fit: cover;
  }
}
.backgroundImg_n_DragArea {
  position: relative;
  width: -moz-fit-content;
  width: 100%;
  height: calc(100%);
  justify-content: center;
  z-index: 0;
  border-radius: 10%;
  display: flex;
}
</style>
