<template>
  <div class="word-container">
    <div
      class="word-box"
      :class="{
        hover: isHover && wordInfo.wordPy,
        symbolItem: !wordInfo.wordPy,
      }"
      @mouseenter="handleHover"
      @mouseleave="handleNotHover"
      @click="handlePlayWords"
    >
      <div class="pinyin font-pinyin-medium">
        {{ wordInfo.wordPy }}
      </div>
      <div class="hanzi font-hanzi-medium">
        {{ wordInfo.wordZh }}
      </div>
    </div>
    <div
      class="img-box"
      v-if="false"
      :class="{ hover: isHover }"
      @mouseenter="handleHover"
      @mouseleave="handleNotHover"
      @click="handlePlayWords"
    >
      <div class="stroke-img">
        <img :src="wordInfo.imgUrl" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    wordInfo: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      isHover: false,
    };
  },
  mounted() {
    const { group, line, index: wordIndex } = this.wordInfo;

    this.$bus.$on(
      "hoverSyllabusPage",
      ({ id, sortNo,listIndex, isHover, isStudent }) => {
        if (isStudent) {
          if (id === this.wordInfo.id  && this.wordInfo.sortNo === sortNo && this.wordInfo.levelKey === listIndex) {
            this.isHover = isHover;
          }
        }
      }
    );
    this.$bus.$on("playWords", ({ listIndex, index, useIndex }) => {
      if (listIndex !== this.pageType) {
        if (group === listIndex && wordIndex === index) {
          this.handlePlayWords();
        }
      } else {
        if (group === listIndex && line === index && wordIndex === useIndex) {
          this.handlePlayWords();
        }
      }
    });
  },

  beforeDestroy() {
    this.$bus.$off("hoverSyllabusPage");
    this.$bus.$off("playWords");
  },
  methods: {
    handlePlayWords() {
      // to do list
      // if (this.$store.state.toolBar.isTeacherPoint) {
      this.$emit("hoverTHisWords");
      //   return;
      // }
      this.$emit("clickToPlayWords");
    },
    handleHover() {
      this.isHover = true;
      this.$emit("hoverTHisWords");
    },
    handleNotHover() {
      this.isHover = false;
      this.$emit("notHoverTHisWords");
    },
  },
};
</script>

<style lang="scss" scoped>
.word-container {
  width: 100%;
  height: 100%;
  color: #224e96;

  .word-box {
    padding: 2px 10px;
    border-radius: 10px;
    margin: 0 4px;
    cursor: pointer;
    .pinyin {
      // font-family: "STHEITISCPINYIN-LIGHT";
      text-align: center;
      font-size: 1.1em !important;
      line-height: 30px !important;
      min-height: 30px;
      margin-bottom: -8px;
    }
    .hanzi {
      text-align: center;
      white-space: nowrap;
    }
  }
  .symbolItem {
    padding: 0 0;
    margin: 0;
    .pinyin,
    .hanzi {
      font-size: 18px !important;
    }
  }
  .hover {
    background: #e0e9f7;
  }
  .interhover {
    background: #e0e9f7;
  }
  .symbol-box {
    width: 100%;
    height: calc(100% - 10px);
    display: flex;
    justify-content: center;
    align-items: center;

    .symbol {
      font-size: 1.6em !important;
      z-index: 100;
    }
    // symbolType: 0word 1、 2： 3 “ 4！ 5…… 6。7”
  }
  .img-box {
    padding: 2px 10px;
    border-radius: 10px;
    margin: 0 4px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .pinyin {
      // font-family: "STHEITISCPINYIN-LIGHT";
      text-align: center;
      font-size: 1.1em !important;
      line-height: 30px !important;
      min-height: 30px;
      margin-bottom: -5px;
    }
    .hiddleBlock {
      opacity: 0;
    }
    .stroke-img {
      img {
        width: 100%;
        max-width: 40px;
      }
    }
  }
  .type-1 {
    align-items: flex-end;
    .symbol {
      max-width: 20px;
      margin-left: -10px;
    }
  }
  .type-2 {
    align-items: flex-end;
    .symbol {
      max-width: 2em;
      margin-left: -10px;
      margin-bottom: 5px;
    }
  }
  .type-3 {
    align-items: flex-end;
    .symbol {
      max-width: 20px;
      margin-bottom: 10px;
    }
  }
  .type-7 {
    align-items: flex-end;
    .symbol {
      max-width: 20px;
      margin-bottom: 10px;
    }
  }
  .type-4 {
    align-items: flex-end;
    .symbol {
      // font-size: 2em !important;
      margin-bottom: 2px;
    }
  }
  .type-5 {
    align-items: flex-end;
    .symbol {
      max-width: 50px;
      // margin-bottom: -5px;
      // max-width: 2em;
      margin-left: -10px;
      margin-bottom: 5px;
    }
  }

  .type-6 {
    align-items: flex-end;

    .symbol {
      max-width: 20px;
      margin-left: -10px;
    }
  }
}
</style>
