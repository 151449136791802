<template>
  <div class="game-find-picture">
    <div class="game-find-pic-content">
      <!-- <div class="title-area" v-if="Object.keys(titleInfo).length">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">{{ titleInfo.hanzi }}</div>
        </div>
      </div> -->
      <Star :totalStarNumber="totalStars" :currentIndex="answersfound" />

      <div class="game-body">
        <div class="game-box" :class="{ 'number-bg-color': !isNumberGame }">
          <div class="game-left-box">
            <div class="game-aside-content">
              <div
                v-for="(item, index) in leftList"
                :key="index"
                class="item-content"
                :class="{ 'item-content-number': isNumberGame }"
                @click="foundAnswer(item.id)"
              >
                <span class="font-pinyin-medium pinyin" v-if="item.pinyin">{{
                  item.pinyin
                }}</span>
                <span class="font-hanzi-medium hanzi">{{ item.element }}</span>
              </div>
            </div>
          </div>

          <div
            class="game-center-box"
            v-for="(item, index) in questionInfoList"
            :key="index + 'question'"
            v-show="question === index"
          >
            <!-- 中间的背景图 -->
            <img class="objectDisplay fadeIn" :src="item.bgImg" v-if="item.bgImg"/>
            <!-- 中间的数字和pinyin -->
            <div class="number-area">
              <span class="font-pinyin-large pinyin">{{ item.pinyin }}</span>
              <span class="font-hanzi-large hanzi">{{ item.hanzi }}</span>
            </div>
            <div class="speaker-wrapper" @click="playSoundWord">
              <!-- <img :src="volumeImgSrc" alt="" class="speaker-img" /> -->
              <AudioPlayer
                :isPlay="isStartPlaying"
                :audioUrl="songUrl"
                :showAudioElement="index > 0 ? false : true"
                :title="item.hanzi"
              />
            </div>
          </div>

          <div class="game-right-box">
            <div class="game-aside-content">
              <div
                v-for="(item, index) in rightList"
                :key="index"
                class="item-content"
                :class="{ 'item-content-number': isNumberGame }"
                @click="foundAnswer(item.id)"
              >
                <span class="font-pinyin-medium pinyin" v-if="item.pinyin">{{
                  item.pinyin
                }}</span>
                <span class="font-hanzi-medium">{{ item.element }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Star from '@/components/Course/GamePage/Star'
import AudioPlayer from '@/components/Course/GamePage/AudioPlayer'
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    Star,
    AudioPlayer
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          findByPictureList: []
        }
      }
    }
  },
  watch: {
    gameSocket: {
      handler(value) {
        const data = {
          clickType: 20027,
          data: { value },
          text: 'NumberClickGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002701,
          data: { value },
          text: 'NumberClickGame点击喇叭socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  mounted() {
    // this.dataInit()
    this.$bus.$on('chooseElementToCheck', ({ index }) => {
      this.foundAnswer(index, true)
    })
    this.$bus.$on('chooseElementAudio', () => {
      this.playSoundWord('socket', true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('chooseElementToCheck')
    this.$bus.$off('chooseElementAudio')
  },
  data() {
    return {
      // titleInfo: {
      //   pinyin: 'Jǐ?',
      //   hanzi: '几?'
      // },
      answersfound: 0,
      totalStars: 0,
      isNumberGame: false,
      leftList: [],
      rightList: [],
      questionInfoList: [],
      isStartPlaying: null,
      songUrl: null,
      question: 0,
      isLastStep: false,
      wordSoundUrl: '',
      gameSocket: {},
      audioSocketInfo: {},
      localData: {
        isNumberGame: true,
        totalStars: 10,
        leftList: [
          {
            id: 1,
            pinyin: 'yī',
            element: 1
          },
          {
            id: 2,
            pinyin: 'èr',
            element: 2
          },
          {
            id: 3,
            pinyin: 'sān',
            element: 3
          },
          {
            id: 4,
            pinyin: 'sì',
            element: 4
          },
          {
            id: 5,
            pinyin: 'wǔ',
            element: 5
          }
        ],
        rightList: [
          {
            id: 6,
            pinyin: 'liù',
            element: 6
          },
          {
            id: 7,
            pinyin: 'qī',
            element: 7
          },
          {
            id: 8,
            pinyin: 'bā',
            element: 8
          },
          {
            id: 9,
            pinyin: 'jiǔ',
            element: 9
          },
          {
            id: 10,
            pinyin: 'shí',
            element: 10
          }
        ],
        questionInfoList: [
          {
            id: 5,
            pinyin: 'wǔ',
            hanzi: '五',
            bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/ball.svg'),
            sound: 'http://test.nihaokids.com/media/5.f9ad60e6.mp3'
          },
          {
            id: 8,
            pinyin: 'bā',
            hanzi: '八',
            bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/ball.svg'),
            sound: 'http://test.nihaokids.com/media/8.1c386503.mp3'
          },
          {
            id: 2,
            pinyin: 'èr',
            hanzi: '二',
            bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/ball.svg'),
            sound: 'http://test.nihaokids.com/media/2.83f95e36.mp3'
          },
          {
            id: 7,
            pinyin: 'qī',
            hanzi: '七',
            bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/ball.svg'),
            sound: 'http://test.nihaokids.com/media/7.d008b766.mp3'
          },
          {
            id: 6,
            pinyin: 'liù',
            hanzi: '六',
            bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/ball.svg'),
            sound: 'http://test.nihaokids.com/media/6.ca727aa5.mp3'
          },
          {
            id: 4,
            pinyin: 'sì',
            hanzi: '四',
            bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/ball.svg'),
            sound: 'http://test.nihaokids.com/media/4.66645cc0.mp3'
          },
          {
            id: 1,
            pinyin: 'yī',
            hanzi: '一',
            bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/ball.svg'),
            sound: 'http://test.nihaokids.com/media/1.f1ef5bd7.mp3'
          },
          {
            id: 9,
            pinyin: 'jiǔ',
            bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/ball.svg'),
            hanzi: '九',
            sound: 'http://test.nihaokids.com/media/9.cc763c6e.mp3'
          },
          {
            id: 10,
            pinyin: 'shí',
            bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/ball.svg'),
            hanzi: '十',
            sound: 'http://test.nihaokids.com/media/10.41dc17da.mp3'
          },
          {
            id: 3,
            pinyin: 'sān',
            bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/ball.svg'),
            hanzi: '三',
            sound: 'http://test.nihaokids.com/media/3.044aeba6.mp3'
          }
        ]
      }
    }
  },
  methods: {
    initData() {
      // console.log(this.componentProps.findByPictureList, '----')
      this.questionInfoList = []
      this.leftList = []
      this.rightList = []
      if (
        this.componentProps.findByPictureList &&
        this.componentProps.findByPictureList.length
      ) {
        this.componentProps.findByPictureList.forEach((item, index) => {
          this.questionInfoList.push({
            id: index + 1,
            pinyin: item[4].value,
            hanzi: item[5].value,
            sound: item[3].value,
            bgImg: item[2].value
          })
          // console.log(
          //   parseInt(this.componentProps.findByPictureList.length / 2) + 1,
          //   '00000'
          // )
          if (
            index < parseInt(this.componentProps.findByPictureList.length / 2)
          ) {
            this.leftList.push({
              id: index + 1,
              pinyin: item[0].value,
              element: item[1].value
            })
          } else if (
            index >
            parseInt(this.componentProps.findByPictureList.length / 2) - 1
          ) {
            this.rightList.push({
              id: index + 1,
              pinyin: item[0].value,
              element: item[1].value
            })
          }
        })
      }
      this.totalStars = this.questionInfoList.length
      const element = this.rightList[0].element || ''
      const number = parseInt(element, 10)
      this.isNumberGame = !isNaN(number) && number.toString() === element
      this.wordSoundUrl = this.questionInfoList[0].sound
    },
    foundAnswer(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.gameSocket = {
          index,
          value: Math.random()
        }
      }
      let arr = [1, 2, 3, 4, 5, 6, 7, 8, 9]
      this.shuffle(arr)
      // console.log(arr)

      if (this.questionInfoList[parseInt(this.question)].id == index) {
        this.answersfound++
        playCorrectSound(true, false)
        if (this.question >= this.questionInfoList.length - 1) {
          playCorrectSound()
          // console.log('祝贺')
          startConfetti()
          this.isLastStep = true
          return
        } else {
          this.wordSoundUrl =
            this.questionInfoList[parseInt(this.question) + 1].sound
          this.question++
        }
      } else {
        playCorrectSound(false)
      }
    },
    playSoundWord(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.audioSocketInfo = {
          value: Math.random()
        }
      }
      this.isStartPlaying = Math.random()
      this.songUrl = this.wordSoundUrl
    },
    // 洗牌算法
    shuffle(arr) {
      let l = arr.length
      let index, temp
      while (l > 0) {
        index = Math.floor(Math.random() * l)
        temp = arr[l - 1]
        arr[l - 1] = arr[index]
        arr[index] = temp
        l--
      }
      return arr
    }
  }
}
</script>

<style lang="scss" scoped>
.game-find-picture {
  width: 100%;
  height: 100%;
  .game-find-pic-content {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 48px;
    position: relative;
    overflow: hidden;
    .title-area {
      z-index: 11;
      position: absolute;
      left: 0;
      top: 7%;
      width: 22%;
      height: 14%;
      background: #cd4c3f;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 22px;
      color: #fff;
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }
    }
    .game-body {
      text-align: -webkit-center;
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: url('../../../../assets/img/03-Backgrounds/background-practice.svg');
      border-radius: 48px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.backgroundBlue {
        background-image: url('../../../../assets/img/03-Backgrounds/background-factory.svg');
      }
      .game-box {
        box-sizing: border-box;
        position: relative;
        width: -moz-fit-content;
        background: #214e96;
        width: 75%;
        height: 75%;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5%;

        .game-left-box,
        .game-right-box {
          height: 100%;
          width: 20%;
          display: flex;
          align-items: center;
          .game-aside-content {
            width: 100%;
            height: 95%;
            display: flex;

            border-top-right-radius: 30px;
            border-top-left-radius: 30px;
            justify-content: space-around;
            align-items: center;
            flex-direction: column;
          }
        }

        .game-center-box {
          width: 60%;
          height: 85%;
          border-radius: 30px;
          background-color: #fff;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 80%;
          }
          .number-area {
            width: 50%;
            position: absolute;
            top: 32%;
            left: 18%;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            .pinyin,
            .hanzi {
              color: #224e96;
            }

            @media screen and (max-width: 1300px) {
              // external screen
              height: 28%;
            }
          }
          .speaker-wrapper {
            position: absolute;
            bottom: 5%;
            right: 10%;
            width: 50px;
            height: 34px;
            background-color: #224e96;
            border-radius: 50%;
            text-align: center;
            padding: 8px 0;
            &:hover {
              opacity: 0.8;
              cursor: pointer;
            }
            .speaker-img {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              margin: auto;
              height: 99%;
            }
          }
        }
      }
      .number-bg-color {
        background: #102c5a;
        box-sizing: border-box;
        border-top: 10px solid #233f6b;
        border-bottom: 10px solid #061329;
      }
      .item-content {
        box-sizing: border-box;
        width: 75%;
        z-index: 1;
        border-radius: 15px;
        background-color: #cd4c3f;
        border-bottom: 8px solid #ac3d32;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #fff;
        padding: 5%;
      }
      .item-content-number {
        width: 45%;
      }
      .item-content:hover {
        background-color: #912016;
        border-top: 8px solid #6e0c03;
        border-bottom: none;
      }
    }
  }
}
</style>
