var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-mirror-pick-words-container" }, [
    _c(
      "div",
      { staticClass: "game-content" },
      [
        _c("Star", {
          attrs: {
            totalStarNumber: _vm.mirrorList.length,
            currentIndex: _vm.starNo,
          },
        }),
        _c("div", { staticClass: "select-results" }, [
          _c("img", {
            staticClass: "bg-img",
            attrs: {
              src: _vm.bgImg
                ? _vm.bgImg
                : require("@/assets/img/16-GAMES/G03-mirror/mirror-background.svg"),
              alt: "",
            },
          }),
          _vm.rightGif === 1
            ? _c("div", { staticClass: "lesson-correct" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/05-Panda/panda-correct.gif"),
                    alt: "",
                  },
                }),
              ])
            : _vm._e(),
          _vm.rightGif === 0
            ? _c("div", { staticClass: "lesson-error" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/05-Panda/panda-wrong.gif"),
                    alt: "",
                  },
                }),
              ])
            : _vm._e(),
        ]),
        _vm._l(_vm.mirrorList, function (item, index) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentImgIndex === index,
                  expression: "currentImgIndex === index",
                },
              ],
              key: index + "mirror",
              staticClass: "content-area",
            },
            [
              _c("div", { staticClass: "word-content" }, [
                _c("div", { staticClass: "word-item-area" }, [
                  _c(
                    "div",
                    {
                      staticClass: "word-item-wo",
                      class: { hover1: item.firstOptionHanzi === _vm.hoverId },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleCheckClick(0)
                        },
                        mouseenter: function ($event) {
                          _vm.getWordsId(
                            item.firstOptionHanzi,
                            (_vm.isHover = true)
                          )
                        },
                        mouseleave: function ($event) {
                          _vm.getWordsId(
                            item.firstOptionHanzi,
                            (_vm.isHover = false)
                          )
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "pinyin font-pinyin-medium" }, [
                        _vm._v(_vm._s(item.firstOptionPinyin)),
                      ]),
                      _c("span", { staticClass: "font-hanzi-medium" }, [
                        _vm._v(_vm._s(item.firstOptionHanzi)),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "word-item-area" }, [
                  _c(
                    "div",
                    {
                      staticClass: "word-item-ni",
                      class: { hover: item.secondOptionHanzi === _vm.hoverId },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleCheckClick(1)
                        },
                        mouseenter: function ($event) {
                          _vm.getWordsId(
                            item.secondOptionHanzi,
                            (_vm.isHover = true)
                          )
                        },
                        mouseleave: function ($event) {
                          _vm.getWordsId(
                            item.secondOptionHanzi,
                            (_vm.isHover = false)
                          )
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "pinyin font-pinyin-medium" }, [
                        _vm._v(_vm._s(item.secondOptionPinyin)),
                      ]),
                      _c("span", { staticClass: "font-hanzi-medium" }, [
                        _vm._v(_vm._s(item.secondOptionHanzi)),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "mirror-img" }, [
                _c("img", { attrs: { src: item.imgSrc, alt: "" } }),
              ]),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }