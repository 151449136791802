var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-find-img-container" }, [
    _c(
      "div",
      { staticClass: "game-content" },
      [
        _c("Star", {
          attrs: {
            totalStarNumber: _vm.totalStars,
            currentIndex: _vm.answersfound,
          },
        }),
        _c("div", { staticClass: "game-background" }, [
          _c(
            "div",
            { staticClass: "body-content" },
            [
              _c("div", { staticClass: "game-left-box" }, [
                _c(
                  "div",
                  { staticClass: "game-aside-content" },
                  _vm._l(_vm.leftList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "item-content" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "audio-area",
                            on: {
                              click: function ($event) {
                                return _vm.playSoundWord(item.id)
                              },
                            },
                          },
                          [
                            _c("AudioClickPlayer", {
                              ref: "audioBox" + item.id,
                              refInFor: true,
                              attrs: {
                                audioUrl: item.sound,
                                title: item.element,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "word-area",
                            class: { "word-hover": item.id === _vm.hoverId },
                            on: {
                              click: function ($event) {
                                return _vm.foundAnswer(item.id)
                              },
                              mouseenter: function ($event) {
                                return _vm.getWordsId(
                                  item.id,
                                  (_vm.isHover = true),
                                  index
                                )
                              },
                              mouseleave: function ($event) {
                                return _vm.getWordsId(
                                  item.id,
                                  (_vm.isHover = false),
                                  index
                                )
                              },
                            },
                          },
                          [
                            item.pinyin
                              ? _c(
                                  "div",
                                  { staticClass: "font-pinyin-medium pinyin" },
                                  [_vm._v(" " + _vm._s(item.pinyin) + " ")]
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "font-hanzi-medium" }, [
                              _vm._v(_vm._s(item.element)),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _vm._l(_vm.questionInfoList, function (item, index) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.question === index,
                        expression: "question === index",
                      },
                    ],
                    key: index + "question",
                    staticClass: "game-center-box",
                  },
                  [
                    _c("img", {
                      staticClass: "objectDisplay fadeIn",
                      attrs: { src: item.bgImg },
                    }),
                  ]
                )
              }),
              _c("div", { staticClass: "game-right-box" }, [
                _c(
                  "div",
                  { staticClass: "game-aside-content" },
                  _vm._l(_vm.rightList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "item-content" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "audio-area",
                            on: {
                              click: function ($event) {
                                return _vm.playSoundWord(item.id)
                              },
                            },
                          },
                          [
                            _c("AudioClickPlayer", {
                              ref: "audioBox" + item.id,
                              refInFor: true,
                              attrs: {
                                audioUrl: item.sound,
                                title: item.element,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "word-area",
                            class: { "word-hover": item.id === _vm.hoverId },
                            on: {
                              click: function ($event) {
                                return _vm.foundAnswer(item.id)
                              },
                              mouseenter: function ($event) {
                                return _vm.getWordsId(
                                  item.id,
                                  (_vm.isHover = false),
                                  index
                                )
                              },
                              mouseleave: function ($event) {
                                return _vm.getWordsId(
                                  item.id,
                                  (_vm.isHover = false),
                                  index
                                )
                              },
                            },
                          },
                          [
                            item.pinyin
                              ? _c(
                                  "div",
                                  { staticClass: "font-pinyin-medium pinyin" },
                                  [_vm._v(" " + _vm._s(item.pinyin) + " ")]
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "font-hanzi-medium" }, [
                              _vm._v(_vm._s(item.element)),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ],
            2
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }