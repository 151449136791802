<template>
  <div class="game-turntable-container">
    <div class="turntable-content">
      <Star
        :totalStarNumber="optionsList.length"
        :currentIndex="showWordsIndex"
      />
      <div class="backGroundImg-area">
        <img :src="gameInfo.bgImg" alt="" />
      </div>
      <div class="title-area" v-if="gameInfo.titlePinyin">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ gameInfo.titlePinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ gameInfo.titleHanzi }}
          </div>
        </div>
      </div>

      <div class="turntable-area">
        <div class="turntable-box">
          <div class="turntable-img-area">
            <img
              :src="require('@/assets/img/FixedPicture/turntable.svg')"
              alt=""
              class="turntable-img"
            />
            <div class="needle-area">
              <img
                :src="require('@/assets/img/FixedPicture/needle.svg')"
                alt=""
                :class="'rotateAnimation-' + clickIndex"
                class="glow-red"
                @click="handlerClickNeedle"
              />
            </div>
            <div class="avator-area">
              <template v-for="(item, index) in optionsList">
                <img
                  :src="item.image"
                  :key="index + 'avator'"
                  alt=""
                  :class="'avator-' + item.index"
                />
              </template>
            </div>
            <div class="options-area" v-if="optionsList && optionsList.length">
              <template v-for="(item, index) in optionsList">
                <div
                  class="option-item"
                  :class="'option-' + item.index"
                  :key="index"
                  v-if="showWordsIndex > item.index"
                >
                  <span class="option-pinyin pinyin">{{ item.pinyin }}</span>
                  <span class="option-hanzi font-hanzi-medium">{{
                    item.hanzi
                  }}</span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Star from '@/components/Course/GamePage/Star'
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    Star
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          titlePy: '',
          titleZh: '',
          turnTableImg: '',
          backgroundImg: '',
          turnTableList: []
        }
      }
    }
  },
  watch: {
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20042,
          data: { value },
          text: 'SpeakingByTurntableGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  mounted() {
    // this.initData()
    this.$bus.$on('ClickTheNeedle', () => {
      this.handlerClickNeedle('socket', true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('ClickTheNeedle')
  },
  data() {
    return {
      localData: {
        gameInfo: {
          bgImg: require('@/assets/img/03-Backgrounds/level-1-chapter-2-background-blue.svg'),
          titleHanzi: '我爱……',
          titlePinyin: 'Wǒ ài……'
        },
        optionsList: [
          {
            pinyin: 'māma',
            hanzi: '妈妈',
            index: 2,
            image: require('@/assets/img/16-GAMES/profile-xiaoai.svg')
          },
          {
            pinyin: 'lǎoshī',
            hanzi: '老师',
            index: 1,
            image: require('@/assets/img/16-GAMES/profile-friend.svg')
          },
          {
            pinyin: 'nǐ',
            hanzi: '你',
            index: 0,
            image: require('@/assets/img/16-GAMES/profile-dongdong.svg')
          },
          {
            pinyin: 'bàba',
            hanzi: '爸爸',
            index: 3,
            image: require('@/assets/img/16-GAMES/profile-bb.svg')
          },
          {
            pinyin: 'wo',
            hanzi: '我',
            index: 4,
            image: require('@/assets/img/16-GAMES/title-xiaoai.svg')
          },
          {
            pinyin: 'ta',
            hanzi: '他',
            index: 5,
            image: require('@/assets/img/16-GAMES/profile-bb2.svg')
          }
        ],
        bottomCornerButtonList: {
          settings: {
            showNextOnLastStep: true
          },
          buttons: [
            {
              type: 'speak',
              startNumberRange: 0,
              endNumberRange: 100
            }
          ]
        },
        clickIndex: 0,
        isLastStep: false,
        showWordsIndex: 0,
        totalStarNumber: 6
      },
      gameInfo: {},
      optionsList: [],
      clickIndex: 0,
      isLastStep: false,
      clickSocketInfo: {},
      showWordsIndex: 0,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'speak',
            startNumberRange: 0,
            endNumberRange: 100
          }
        ]
      }
    }
  },
  methods: {
    initData() {
      this.optionsList = []
      this.gameInfo = {
        titleHanzi: this.componentProps.titleZh,
        titlePinyin: this.componentProps.titlePy,
        bgImg: this.componentProps.backgroundImg
      }
      if (
        this.componentProps.turnTableList &&
        this.componentProps.turnTableList.length
      ) {
        const list = []
        // this.avatarList = []
        this.componentProps.turnTableList.forEach((item, index) => {
          list.push({
            pinyin: item[1].value,
            hanzi: item[2].value,
            index,
            image: item[0].value
          })
          // this.avatarList.push({
          //   id: index+1,
          //   avator: item[0].value
          // })
        })
        if (list && list.length) {
          this.optionsList =
            list && list.length
              ? list.map((item, index) => {
                  switch (index) {
                    case 0:
                      item.index = 1
                      break
                    case 1:
                      item.index = 0
                      break
                    case 2:
                      item.index = 3
                      break
                    case 3:
                      item.index = 2
                      break
                  }
                  return item
                })
              : []
        }
        // console.log(this.optionsList,'optionList');
      }
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
      }
      return array
    },
    handlerClickNeedle(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickSocketInfo = {
          value: Math.random()
        }
      }
      this.clickIndex++

      setTimeout(() => {
        this.showWordsIndex++
        playCorrectSound(true, false)
      }, 1500)
      if (this.clickIndex === this.optionsList.length) {
        setTimeout(() => {
          this.isLastStep = true
          startConfetti()
          playCorrectSound()
        }, 1500)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.game-turntable-container {
  width: 100%;
  height: 100%;
  line-height: 1;
  .turntable-content {
    width: 100%;
    height: 100%;
    position: relative;
    .backGroundImg-area {
      width: 100%;
      height: 100%;
      img {
        border-radius: 48px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .title-area,
    .turntable-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .title-area {
      z-index: 9;
      position: absolute;
      top: 0;
      left: 0;
      top: 7%;
      width: auto;
      height: auto;
      padding: 20px;
      background: #cd4c3f;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      color: #fff;
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }
    }
    .turntable-area {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .turntable-box {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .turntable-img-area {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 45%;
          max-width: 600px;
          min-width: 360px;
          .turntable-img {
            width: 100%;
          }
          .needle-area {
            position: absolute;
            z-index: 100;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 15%;
              cursor: pointer;
              transform-origin: 50% 50%;
            }
          }
          .options-area {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            .option-item {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              position: absolute;
              background: #fff;
              border-radius: 15px;
              padding: 10px 20px;
              span {
                display: inline-block;
                color: #cd4c3f;
              }
            }
            .option-2 {
              // top: -30%;
              top: -19%;
              left: 50%;
              transform: translate(-50%);
            }
            .option-1 {
              right: -27%;
              top: 75%;
              transform: translateY(-50%);
            }
            .option-0 {
              bottom: -21%;
              left: 50%;
              transform: translate(-50%);
            }
            .option-3 {
              left: -24%;
              top: 80%;
              transform: translateY(-50%);
            }
            .option-4 {
              left: -30%;
              top: 24%;
              transform: translateY(-30%);
            }
            .option-5 {
              // right: -22%;
              right: -28%;
              top: 20%;
              transform: translateY(-30%);
            }
          }
        }
      }
    }
  }
  .avator-area {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    img {
      border-radius: 50%;
      width: 20%;
      // max-width: 55px;
      position: absolute;
    }
  }
  .avator-0 {
    left: 42%;
    bottom: 15%;
  }
  .avator-1 {
    right: 17%;
    top: 53%;
  }
  .avator-2 {
    top: 14%;
    left: 40%;
  }
  .avator-3 {
    left: 20%;
    top: 54%;
  }
  .avator-4 {
    left: 18%;
    top: 28%;
  }
  .avator-5 {
    right: 17%;
    top: 28%;
  }
  .rotateAnimation-1 {
    transform: rotate(135deg);
    animation: rotateAnimation1 1s ease-out;
  }
  .rotateAnimation-2 {
    transform: rotate(65deg);
    animation: rotateAnimation2 1s ease-out;
  }
  .rotateAnimation-3 {
    transform: rotate(-35deg);

    animation: rotateAnimation3 1s ease-out;
  }
  .rotateAnimation-4 {
    transform: rotate(205deg);
    animation: rotateAnimation4 1s ease-out;
  }
  .rotateAnimation-5 {
    transform: rotate(255deg);
    animation: rotateAnimation5 1s ease-out;
  }
  .rotateAnimation-6 {
    transform: rotate(15deg);
    animation: rotateAnimation6 1s ease-out;
  }

  @keyframes rotateAnimation1 {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(855deg);
    }
  }
  @keyframes rotateAnimation2 {
    0% {
      transform: rotate(135deg);
    }

    100% {
      // transform: rotate(615deg);
      transform: rotate(1125deg);
    }
  }
  @keyframes rotateAnimation3 {
    0% {
      transform: rotate(50deg);
    }

    100% {
      // transform: rotate(757.5deg);
      transform: rotate(1035deg);
    }
  }
  @keyframes rotateAnimation4 {
    0% {
      transform: rotate(-35deg);
    }

    100% {
      // transform: rotate(472.5deg);
      transform: rotate(845deg);
    }
  }
  @keyframes rotateAnimation5 {
    0% {
      transform: rotate(-45deg);
    }

    100% {
      // transform: rotate(472.5deg);
      transform: rotate(1445deg);
    }
  }
  @keyframes rotateAnimation6 {
    0% {
      transform: rotate(255deg);
    }
    100% {
      // transform: rotate(472.5deg);
      transform: rotate(1445deg);
    }
  }
}
</style>
