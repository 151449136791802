var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "phone-number-container" }, [
    _c(
      "div",
      { staticClass: "phone-number-content" },
      [
        _c("div", { staticClass: "background-content" }, [
          _c("div", { staticClass: "leftSide" }, [
            _c(
              "div",
              {
                staticClass: "phone-box",
                class: [
                  _vm.answersfound == 0
                    ? " isPhoneBackground"
                    : _vm.answersfound == 1
                    ? " isSafeBackground"
                    : [2, 3, 4].indexOf(_vm.answersfound) > -1
                    ? "isDoorBackground"
                    : "",
                  _vm.isLastStep ? "isHidden" : "",
                ],
              },
              [
                _c("div", { staticClass: "screen" }, [
                  _c("div", { staticClass: "numDisplay" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.answersfound != 0,
                            expression: "answersfound != 0",
                          },
                        ],
                        staticClass: "lock-wrapper",
                        class: {
                          unlock: _vm.isCalling,
                          isHidden: _vm.callList[_vm.steps].showLock === false,
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "lock-top",
                          attrs: {
                            src: require("@/assets/img/16-GAMES/G39-phone-numbers/lock-top-part.svg"),
                          },
                        }),
                        _c("img", {
                          staticClass: "lock-bottom",
                          attrs: {
                            src: require("@/assets/img/16-GAMES/G39-phone-numbers/lock-bottom-part.svg"),
                          },
                        }),
                      ]
                    ),
                    _c("div", { staticClass: "display" }, [
                      _c("div", { staticClass: "numbers" }, [
                        _vm._v(_vm._s(_vm.displayText)),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "deletebutton",
                        on: {
                          click: function ($event) {
                            return _vm.removeNumber()
                          },
                        },
                      },
                      [_c("img", { attrs: { src: _vm.buttonList[13].image } })]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "numPad" },
                    [
                      _vm._l(_vm.buttonList, function (item, index) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: index < 12,
                                expression: "index < 12",
                              },
                            ],
                            key: index,
                            staticClass: "buttons",
                            on: {
                              click: function ($event) {
                                return _vm.addNumber(item.text)
                              },
                              mouseenter: function ($event) {
                                _vm.buttonList[index].isHover = 1
                              },
                              mouseleave: function ($event) {
                                _vm.buttonList[index].isHover = 0
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: [
                                  item.isHover ? item.imageHover : item.image,
                                ],
                              },
                            }),
                          ]
                        )
                      }),
                      _c("div", { staticClass: "buttons" }),
                      _vm._l(_vm.buttonList, function (item, indexNum) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: indexNum == 12,
                                expression: "indexNum == 12",
                              },
                            ],
                            key: indexNum + "item",
                            staticClass: "buttons buttonsgreen",
                            style:
                              _vm.callList[_vm.steps].hasDialButton == false
                                ? "visibility:hidden"
                                : "",
                            on: {
                              click: function ($event) {
                                return _vm.callButton()
                              },
                              mouseenter: function ($event) {
                                _vm.buttonList[indexNum].isHover = 1
                              },
                              mouseleave: function ($event) {
                                _vm.buttonList[indexNum].isHover = 0
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "yestse",
                              attrs: {
                                src: [
                                  item.isHover ? item.imageHover : item.image,
                                ],
                              },
                            }),
                          ]
                        )
                      }),
                      _c("div", { staticClass: "buttons" }),
                    ],
                    2
                  ),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.answersfound == 0 && _vm.isCalling,
                        expression: "answersfound == 0 && isCalling",
                      },
                    ],
                    staticClass: "screen CallingScreen",
                  },
                  [
                    _c("div", { staticClass: "textWrap" }, [
                      _c("p", { staticClass: "numbers" }, [
                        _vm._v(_vm._s(_vm.displayText)),
                      ]),
                      _c("p", { staticClass: "calling" }, [
                        _vm._v("calling...."),
                      ]),
                    ]),
                    _c("div", { staticClass: "buttons buttonsred" }, [
                      _c("img", { attrs: { src: _vm.buttonList[14].image } }),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "rightSide" }, [
            _c("div", { staticClass: "mainCard" }, [
              _c("div", { staticClass: "cardText" }, [
                _vm.callList[_vm.steps].image
                  ? _c("div", { staticClass: "cardImage" }, [
                      _c("img", {
                        attrs: { src: _vm.callList[_vm.steps].image },
                      }),
                    ])
                  : _vm._e(),
                _vm.callList[_vm.steps].pinying
                  ? _c("div", { staticClass: "textWrap" }, [
                      _c("p", { staticClass: "pinyin font24" }, [
                        _vm._v(_vm._s(_vm.callList[_vm.steps].pinying)),
                      ]),
                      _c("p", { staticClass: "hanzi font41" }, [
                        _vm._v(_vm._s(_vm.callList[_vm.steps].hanzi)),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "span",
                {
                  staticClass:
                    "recognize_container_content_right_content_top volume-button",
                  class: { lessononehover: _vm.isClickAudio },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.activeClick.apply(null, arguments)
                    },
                    mouseenter: function ($event) {
                      return _vm.hoverImg(1, "hover")
                    },
                    mouseleave: function ($event) {
                      return _vm.hoverImg(1, "")
                    },
                  },
                },
                [
                  _c("AudioPlayer", {
                    attrs: {
                      isPlay: _vm.isStartPlaying,
                      audioUrl: _vm.songUrl,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c("Star", {
          attrs: {
            totalStarNumber: _vm.callList.length,
            currentIndex: _vm.answersfound,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }