<template>
  <div
    class="course-maps-container"
    :class="`lesson` + componentProps.lessonId"
  >
    <transition name="el-fade-in">
      <!-- v-if="componentProps.position === 0 && isFinishLastStep && maskshow" -->
      <div
        class="mask-book"
        v-if="
          componentProps.position === 1 && componentProps.lessonId % 8 === 0
        "
      >
        <div class="mask-book-content">
          <span class="font28">CHAPTER {{ componentProps.chapterId }}</span>
          <img
            :src="
              require(`@/assets/img/10-Level-progress/progress-level${componentProps.lessonId}.gif`)
            "
            class="glow"
            alt=""
          />
          <img
            src="@/assets/img/07-Stars/confetti.svg"
            alt=""
            class="confetti-img"
          />
        </div>
      </div>
    </transition>

    <div class="lesson_content">
      <!-- 书本显示 -->
      <div class="customlesson-breakthrough-part">
        <div class="demo">
          <div
            class="customlesson-breakthrough-part-book"
            v-for="(item, index) in 8"
            :key="index"
          >
            <span v-if="componentProps.chapterId < index + 1"
              ><img src="@/assets/img/10-Level-progress/book-cream.svg" alt=""
            /></span>
            <span v-if="componentProps.chapterId > index + 1"
              ><img src="@/assets/img/10-Level-progress/book-100.svg" alt=""
            /></span>
            <span v-if="componentProps.chapterId === index + 1"
              ><img
                :src="
                  require(`@/assets/img/10-Level-progress/lesson-${
                    componentProps.position == 1
                      ? componentProps.lessonId
                      : componentProps.lessonId - 1
                  }-complete.svg`)
                "
                :class="{
                  glow: componentProps.position == 1
                }"
                alt=""
            /></span>
          </div>
          <!-- </div> -->
        </div>
      </div>

      <!-- 背景图 -->
      <div class="customlesson-breakthrough-part-movement">
        <div class="picture" ref="pictureBox">
          <div class="picture-item">
            <img
              ref="content"
              class="background-image"
              :src="
                componentProps.bgImg ||
                require(`@/assets/img/09-Level-map/level-1/chapter-1/map (1).svg`)
              "
              alt=""
            />

            <img
              ref="content"
              class="background-image"
              :src="
                componentProps.positionImg ||
                require(`@/assets/img/09-Level-map/level-1/chapter-1/map (2).svg`)
              "
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          lessonId: null,
          chapterId: null,
          levelId: null,
          bgImg: '',
          position: null,
          autoScroll: false
        }
      }
    }
  },
  data() {
    return {
      steps:
        this.componentProps.position == 0
          ? this.componentProps.lessonId * 2 +
            (this.componentProps.lessonId - 3)
          : this.componentProps.lessonId * 2 +
            (this.componentProps.lessonId - 2),
      display: false,
      imageStep:
        this.componentProps.position == 0
          ? this.componentProps.lessonId - 1
          : this.componentProps.lessonId,
      maskshow: false,
      distance: 0,
      picWidth: 0,
      socketEvent: {},
      handleMouseScrollValue: false,
      isShowLastStep: false
    }
  },
  watch: {
    socketEvent: {
      handler(value) {
        const data = {
          clickType: 20016,
          data: { value },
          text: '测试rankMapPage组件内scroll事件socket'
        }
        this.$bus.$emit('kids_webSocket_sendInfo', data)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        this.mapScroll(value.autoScroll)
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.pictureBox.addEventListener(
        'mousewheel',
        this.handleMouseScroll
      )
    })

    this.$bus.$on('scrollMouse', (e) => {
      this.handleMouseScroll(e, true)
    })
    if (this.componentProps.autoScroll) {
      this.mapScroll(this.componentProps.autoScroll)
    }
  },
  beforeDestroy() {
    this.$bus.$off('scrollMouse')
    clearInterval(this.timer)
  },
  methods: {
    // 进入滚动
    mapScroll(autoScroll) {
      this.$refs.pictureBox.scrollLeft = autoScroll
        ? this.$refs.pictureBox.scrollWidth
        : 0
    },
    // 滚动实现
    handleMouseScroll(e, isFromSocket = false) {
      if (!isFromSocket) {
        this.socketEvent = { deltaY: e.deltaY, socketIndex: Math.random() }
      }
      const picWidth = this.$refs.pictureBox.getBoundingClientRect().width
      const speed = e.deltaY > 0 ? 40 : -40
      let dis
      this.distance += speed
      if (this.distance < 0) {
        dis = 0
        this.distance = 0
      } else if (this.distance > picWidth) {
        dis = picWidth
        this.distance = picWidth
      } else {
        dis = this.distance
      }
      this.$refs.pictureBox.scrollLeft = dis
    },

    pictureWidth() {
      let pictureWidth = document.getElementsByClassName('picture')[0]
      pictureWidth.style.width = String(this.$refs.content.width) + 'px'
    }
  }
}
</script>
<style scoped lang="scss">
.course-maps-container {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 56px;
  // z-index: 100;FG
  .mask-book {
    width: 100%;
    height: 100%;
    border-radius: 56px;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    .mask-book-content {
      width: 20%;
      height: 30%;
      background-color: #f4d737;
      border-bottom: 10px solid #d1b726;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      padding: 5%;
      position: relative;
      .font28 {
        color: #224e96;
        font-weight: 700;
        margin-bottom: 15%;
      }
      img {
        width: 70%;
      }
      .confetti-img {
        width: 80%;
        position: absolute;
        top: 0;
      }
    }
  }
  .lesson_content {
    width: 100%;
    height: 100%;
    position: relative;
    .customlesson-breakthrough-part {
      position: absolute;
      top: 0%;
      right: 5%;
      height: 40px;
      display: flex;
      padding: 10px 10px;
      background-color: #f4d737;
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
      border-bottom: 6px solid #d1b726;
      z-index: 2;
      .demo {
        display: flex;
        .customlesson-breakthrough-part-book {
          margin-left: 5px;
          img {
            width: 25px;
            height: 35px;
          }
        }
      }
    }
    .customlesson-breakthrough-part-movement {
      height: 100%;
      border-radius: 56px;
      background-color: #f7f4e0;

      .picture {
        height: 100%;
        position: relative;
        overflow-x: scroll;
        overflow-y: hidden;

        .picture-item {
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          img {
            height: 95%;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }
      ::-webkit-scrollbar-track {
        background: #efefef;
        border-radius: 2px;
      }
      ::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-corner {
        background: #179a16;
      }
      ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        display: none;
      }
    }
  }
}

//  fade in animation for transition
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadein {
  color: white;
  -webkit-animation: fadein 0.5s ease-in forwards;
  -moz-animation: fadein 0.5s ease-in forwards;
  animation: fadein 0.5s ease-in forwards;
}

@keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);
    -moz-transform: scale(1) rotate(0deg);
    position: absolute;
  }
  10% {
  }
  20% {
    -moz-transform: scale(3.5) rotate(90deg);
    transform: scale(1.5) rotate(60deg);
    position: absolute;
  }
  50% {
  }
  80% {
    opacity: 0.8;
    position: absolute;
  }
  100% {
    -moz-transform: scale(6.5) rotate(360deg);
    transform: scale(6.5) rotate(360deg);

    position: absolute;
    opacity: 0;
  }
}

.glow {
  animation: glow 1s;
}
@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px #2472b3;
  }
  to {
    box-shadow: 0 0 10px 10px #2472b3;
  }
}
</style>
