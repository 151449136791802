<template>
  <div class="game-mirror-container">
    <div class="practice-content">
      <!-- 切换按钮 -->
      <SceneSwitchThumbnails
        v-if="sceneSwitchShow"
        :buttons="buttonList"
        :numBers="numBers"
        @changeTheNumbers="changeTheNumbers"
      />

      <!-- 主要内容 -->
      <div class="practice-body">
        <!-- 失败和成功背景 -->
        <div
          class="checkGif"
          :class="{ checkGifWrong: !checkGif }"
          v-checkgif="checkGif"
          v-if="isShowCheckGif"
        ></div>
        <template v-for="(item, index) in practiceList">
          <div
            class="practice-page-content-item"
            v-if="numBers <= (index + 1) * 2 && numBers > index * 2"
            :key="index + 'choooseImg'"
          >
            <div
              class="img-content-item"
              v-for="(ele, indexSon) in item.imgList"
              :key="indexSon + 'img'"
              @click.stop="handleCheck(ele, indexSon, false, index)"
              @mouseenter="hoverIndex = indexSon"
              @mouseleave="hoverIndex = null"
              :class="{ imgHover: ele.bgImg === hoverId }"
            >
              <div class="img-area">
                <div
                  class="bg-img"
                  @mouseenter="getWordsId(ele, (isHover = true), index)"
                  @mouseleave="getWordsId(ele, (isHover = false), index)"
                >
                  <img :src="ele.bgImg" alt="" class="target-img" />
                </div>
                <!-- 图片 -->

                <!-- 字和拼音 -->
                <div
                  class="targetSymbolTip"
                  :class="{
                    cardSelected: curentClickIndex === indexSon
                  }"
                >
                  <div class="lesson-foseImgFree card-selection-text">
                    <span class="pinyin font-pinyin-medium">{{
                      item.pinyin
                    }}</span>
                    <span class="font-hanzi-medium">{{ item.hanzi }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    SceneSwitchThumbnails: () =>
      import('../GameComponent/SceneSwitchThumbnails.vue')
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          gameMirrorList: []
        }
      }
    }
  },
  mounted() {
    // this.dataInit()
    this.$bus.$on('clickGameSideBtn', ({ index }) => {
      this.changeTheNumbers(index, true)
    })
    this.$bus.$on('clickChooseImg', ({ item, index, practiceIndex }) => {
      this.handleCheck(item, index, true, practiceIndex)
    })
    this.$bus.$on(
      'hoverSyllabusPage',
      ({ ele, isHover }) => {
         if(isHover){
          this.hoverId = ele.bgImg
        } else {
          this.hoverId = null
        }
              
      }
    )
  },
  beforeDestroy() {
    this.$bus.$off('clickGameSideBtn')
    this.$bus.$off('clickChooseImg')
    this.$bus.$off('hoverSyllabusPage')
  },
  watch: {
    menuSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2000501,
          data: { value },
          text: '测试PracticePage组件内sideButtonClick事件socket'
        }
        this.$bus.$emit('kids_webSocket_sendInfo', data)
      },
      deep: true
    },
    selectSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2000502,
          data: { value },
          text: '测试PracticePage组件内selectClick事件socket'
        }
        this.$bus.$emit('kids_webSocket_sendInfo', data)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  data() {
    return {
      hoverId:null,
      sceneSwitchShow: true,
      checkGif: true,
      isShowCheckGif: false,
      practiceList: [],
      numBers: 1,
      hoverIndex: false,
      curentClickIndex: null,
      lock: false,
      selectSocketInfo: {},
      menuSocketInfo: {},
      buttonList: [],
      localData: {
        practiceList: [
          {
            hanzi: '好',
            pinyin: 'hǎo',
            status: 0,
            imgList: [
              {
                bgImg:
                  'http://test.nihaokids.com/img/card-eat-wrong.dacd144b.svg',
                isRight: false
              },
              {
                bgImg:
                  'http://test.nihaokids.com/img/card-eat-right.51b309d5.svg',
                isRight: true
              }
            ]
          },
          {
            hanzi: '好',
            pinyin: 'hǎo',
            status: 0,
            imgList: [
              {
                bgImg:
                  'http://test.nihaokids.com/img/card-play-right.db1cb8fa.svg',
                isRight: true
              },
              {
                bgImg:
                  'http://test.nihaokids.com/img/card-play-wrong.85038b01.svg',
                isRight: false
              }
            ]
          },
          {
            hanzi: '好',
            pinyin: 'hǎo',
            status: 0,
            imgList: [
              {
                bgImg:
                  'http://test.nihaokids.com/img/card-trash-right.7a293d78.svg',
                isRight: true
              },
              {
                bgImg:
                  'http://test.nihaokids.com/img/card-trash-wrong.88f690d8.svg',
                isRight: false
              }
            ]
          }
        ],
        buttonList: [
          {
            image: 'http://test.nihaokids.com/img/menu-food.82efa596.svg',
            startNumberRange: 1,
            endNumberRange: 2
          },
          {
            image: 'http://test.nihaokids.com/img/menu-football.9f62fc47.svg',
            startNumberRange: 3,
            endNumberRange: 4
          },
          {
            image: 'http://test.nihaokids.com/img/menu-trash.7b7bf3c0.svg',
            startNumberRange: 5,
            endNumberRange: 6
          }
        ]
      }
    }
  },
  methods: {
    initData() {
      // console.log(this.componentProps.gameMirrorList, '000')
      this.practiceList = []
      this.buttonList = []
      this.componentProps.gameMirrorList.forEach((item, index) => {
        if (item[0].fieldName === 'img') {
          this.buttonList.push({
            image: item[0].value,
            startNumberRange:
              this.buttonList && this.buttonList.length
                ? this.buttonList[this.buttonList.length - 1].endNumberRange + 1
                : 1,
            endNumberRange:
              this.buttonList && this.buttonList.length
                ? this.buttonList[this.buttonList.length - 1].endNumberRange + 2
                : 2
          })
        }
        const imgList = item[1].value.map((ele) => {
          return {
            bgImg: ele.value,
            isRight: item[4].value === ele.value
          }
        })
        this.practiceList.push({
          imgList,
          hanzi: item[2].value,
          pinyin: item[3].value,
          status: 0
        })
      })
      // console.log(this.practiceList, this.buttonList)
    },
    getWordsId(ele, isHover, index) {
      // 获取hover元素的id      
      const data = {
        clickType: 20004,
        data: {
          value: {
            ele,
            isHover,
            index
          }
        },
        text: '测试SyllabusPagePage组件内hover事件socket'
      }
      this.$bus.$emit('kids_webSocket_sendInfo', data)
    },
    handleCheck(item, index, isFromSocket = false, practiceIndex) {
      if (!isFromSocket) {
        this.selectSocketInfo = {
          item,
          index,
          value: Math.random(),
          practiceIndex
        }
      }
      if (this.lock) {
        return
      }
      this.curentClickIndex = index
      this.imgHover = null
      if (item.isRight) {
        this.numBers++
        this.lock = true
        this.isShowCheckGif = true
        this.checkGif = true
        this.practiceList[practiceIndex].status = 1

        setTimeout(() => {
          this.isShowCheckGif = false
        }, 500)
        // if (this.numBers == 2 * this.practiceList.length) {
        //   startConfetti()
        //   playCorrectSound()
        // } else {
        // playCorrectSound(true, false)
        // }
        // console.log(this.practiceList.every(ele => ele.status),'-----');

        if (this.practiceList.every((ele) => ele.status)) {
          startConfetti()
          playCorrectSound()
        } else {
          playCorrectSound(true, false)
        }
      } else {
        this.isShowCheckGif = true
        this.checkGif = false
        playCorrectSound(false)
        setTimeout(() => {
          this.isShowCheckGif = false
        }, 500)
      }
    },
    changeTheNumbers(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.menuSocketInfo = {
          index,
          value: Math.random()
        }
      }
      this.numBers = index
      this.curentClickIndex = null
      this.lock = false
      // if (this.numBers < this.practiceList.length * 2) {
      //   this.isLastStep = false;
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.game-mirror-container {
  height: 100%;
  width: 100%;
  .practice-content {
    width: 100%;
    height: 100%;
    background-image: url('../../../../assets/img/03-Backgrounds/background-practice.svg');
    background-color: #f7f4e0;
    border-radius: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .practice-body {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .targetSymbolTip {
        position: absolute;
        top: -75px;
        right: 10%;
        // width: 29%;
        // height: 27%;
        padding: 10px;
        border-radius: 20px;

        z-index: 20;
        // border-radius: 15px;
        background-color: #224e96;
        display: flex;
        justify-content: center;
        align-items: center;
        .lesson-foseImgFree {
          padding: 10px 20px;
          border-radius: 20px;
          // width: 60%;
          // height: 41%;
          z-index: 1;
          // border-radius: 15px;
          background-color: #cd4c3f;
          border-bottom: 8px solid #a53d32;
          border-top: 8px solid #d87065;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          // padding: 12% 10%;
          span {
            color: #fff;
            font-size: 32px;
            line-height: 32px;
            @media screen and (max-width: 1500px) {
              // laptop
              font-size: 30px;
            }
            @media screen and (max-width: 1300px) {
              // ipad
              font-size: 20px;
            }
          }
        }
      }
      .practice-page-content-item {
        // display: none;
        width: 80%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .img-content-item {
          display: flex;
          width: 40%;
          // height: 60%;

          .img-area {
            // height: 100%;
            width: 100%;
            position: relative;
            cursor: pointer;
            .result-bg-img {
              position: absolute;
              bottom: 29px;
              left: 0;
              border: 0;
              img {
                width: 50%;
              }
            }
            .bg-img {
              width: 100%;
              height: 100%;
              box-sizing: border-box;
              border-radius: 41px;

              .target-img {
                border-radius: 40px;
                width: 100%;
                border-bottom: 30px solid #cd4c3f;
                object-fit: cover;
              }
            }
          }
        }
      }
      .imgHover {
        img {
          border-color: #f4d737 !important;
        }
      }
      .cardSelected {
        background: #f4d737;
        opacity: 1 !important;
      }
    }
  }
}
</style>
