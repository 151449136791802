export const templatePreviewMixin = {
  mounted () {
    this.handlePreview()
    
  },
  methods: {
    /**
     * @description: 处理模板预览
     * @return {*}
     */
    handlePreview() {
      const { isTemplatePrview } = this.componentProps
      
      if (isTemplatePrview) {
        
        for (const key in this.localData) {
          
          this._data[key] = this.localData[key]
        }
        
      } else {
        this.initData()
      }
    }
  }
}
