var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "review-train-container", class: "lesson-" + _vm.lessonNb },
    [
      _c("div", { staticClass: "review-train-box" }, [
        _c(
          "div",
          {
            staticClass: "train-area",
            class: "step-" + _vm.stepNum + " origin-" + _vm.step,
          },
          [
            _c("img", {
              ref: "train",
              staticClass: "train-img",
              attrs: {
                src: require("@/assets/img/20-Train-review/review-train.svg"),
                alt: "",
              },
            }),
            _c(
              "div",
              {
                staticClass: "review-content-wrapper",
                style: { width: _vm.trainlWidth + "px", height: "78%" },
              },
              [
                _vm.stepNum >= 1
                  ? _c(
                      "div",
                      { staticClass: "review-content vocabulary fadeIn" },
                      [
                        _c(
                          "div",
                          { staticClass: "review-area" },
                          _vm._l(_vm.wordsList, function (ele, indexSon) {
                            return _c(
                              "div",
                              {
                                key: indexSon + "son",
                                staticClass: "train-item",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pinyinArea font-pinyin-medium pinyin",
                                  },
                                  [_vm._v(" " + _vm._s(ele.pinyin) + " ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "hanziArea font-hanzi-medium",
                                  },
                                  [_vm._v(_vm._s(ele.hanzi))]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }