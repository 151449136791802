<template>
  <div class="common-button-container">
    <div
      v-for="(item, index) in buttons"
      :key="index"
      class="btn-item"
      @mouseenter="item.isHover = true"
      @mouseleave="item.isHover = false"
    >
      <img
        :src="item.isHover ? item.imgHover : item.img"
        alt=""
        @click="handleBtnClick(item.type)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonButton',
  data() {
    return {
      buttons: [
        {
          type: 1,
          img: require('@/assets/img/04-Buttons/button-repeat.svg'),
          imgHover: require('@/assets/img/04-Buttons/button-repeat-hover.svg'),
          isHover: false
        }
      ]
    }
  },
  methods: {
    /**
     * @description: 按钮触发函数
     * @param {*} item
     * @return {*}
     */
    handleBtnClick(type) {
      console.log(type)

      // 1: 乱序
      switch (type) {
        case 1:
          this.$emit('shuffleClickEvent')
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.common-button-container {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 20%;
  max-height: 160px;
  .btn-item {
    height: 100%;
    img {
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
