<template>
  <div class="game-pinyin-summary">
    <div class="pinyin-summary-content">
      <div class="check-area">
        <img style="check-bgImg" :src="bgImg" alt="" />
        <template v-for="(item, index) in pinyinImgList">
          <img
            class="pinyin-image"
            :src="item"
            alt=""
            v-if="pinyinIndexArr.indexOf(index) > -1"
            :key="index + 'pinyin'"
          />
        </template>
        <div
          v-show="lesson !== 15"
          class="check-item"
          :class="'toneArea-' + index"
          v-for="(item, index) in 4"
          :key="index + 'check'"
          @click="handleClickTone(index)"
        ></div>
        <div :class="'check-item-pinyin-' + lesson">
          <div
            class="check-item"
            :class="'pinyinArea-' + index"
            v-for="(item, index) in pinyinImgList.length"
            :key="index + 'check1'"
            @click="handleClickPinyin(index)"
          ></div>
        </div>
      </div>
      <div
        class="tone-area"
        v-for="(item, index) in toneImgList"
        :key="index + 'tone'"
      >
        <img :src="item" alt="" v-if="toneIndexArr.indexOf(index) > -1" />
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          bgImg: require('@/assets/img/19-Book-review/lesson-23-background.svg'),
          pinyinString: [],
          lesson: 7,
          ExistingTemplate: '',
          pinyinChoose: [], //已选取的拼音
          pinyinType: 6, //拼音选取数量
          pinyinImg: [] //上传拼音数组
        }
      }
    }
  },
  data() {
    return {
      localData: {
        pinyinString: ['d', 't', 'n', 'l']
      },
      bgImg: require('@/assets/img/19-Book-review/lesson-23-background.svg'),
      pinyinString: [], //这里要变动
      lesson: 23,
      pinyinSocketInfo: {},
      toneSocketInfo: {},
      pinyinIndexArr: [],
      toneIndexArr: [],
      step: 0,
      // les: this.lesson,
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'look',
            startNumberRange: 1,
            endNumberRange: 1,
            clickGoToNextPage: true
          }
        ]
      }
    }
  },
  computed: {
    getpinyinList() {},
    pinyinImgList() {
      let imgArr = []
      for (let i = 1; i <= this.pinyinString.length; i++) {
        const element = require(`@/assets/img/19-Book-review/lesson-${
          this.lesson
        }-review-${this.pinyinString[i - 1]}.svg`)
        imgArr.push(element)
      }
      return imgArr
    },
    toneImgList() {
      let imgArr = []
      for (let i = 1; i <= 4; i++) {
        const element = require(`@/assets/img//12-Review/lesson-07-review-tone${i}.svg`)
        imgArr.push(element)
      }
      return imgArr
    }
  },
  mounted() {
    this.initData()
    this.$bus.$on('clickPinyinItem', ({ index }) => {
      this.handleClickPinyin(index, true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickPinyinItem')
  },
  watch: {
    lesson: 'updatePinyinString',
    step(value) {
      if (
        value === this.pinyinImgList.length &&
        this.toneIndexArr.length === 4
      ) {
        this.isLastStep = true
      }
    },
    pinyinSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002301,
          data: { value },
          text: 'pinyinSummary点击pinyin socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    toneSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002302,
          data: { value },
          text: 'pinyinSummary点击tone socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  methods: {
    initData() {
      this.bgImg = this.componentProps.ExistingTemplate
        ? require(`@/assets/img/19-Book-review/lesson-${this.componentProps.ExistingTemplate}-background.svg`)
        : require(`@/assets/img/19-Book-review/lesson-23-background.svg`)
      // console.log(this.bgImg, '000', this.componentProps.ExistingTemplate)

      this.lesson = this.componentProps.ExistingTemplate || this.lesson
    },
    controlScroll(step) {
      this.scrollMove(this.durationArr[step - 1])
    },
    scrollMove(num) {
      const speed = this.scrollSpeed
      this.isMoving = true
      let timer = setInterval(() => {
        this.distance += speed
        this.scrollDom.scrollLeft = this.distance
        if (this.distance / this.scrollWidth >= num) {
          clearInterval(timer)
          this.isMoving = false

          if (this.step === this.durationArr.length) {
            this.isLastStep = true
            playCorrectSound()
            startConfetti()
          } else {
            playCorrectSound(true, false)
          }
        }
      }, 10)
    },
    handleClickBgImg(socket, isFromSocket = false) {
      if (this.isMoving) {
        return
      }
      if (this.step >= this.durationArr.length) {
        return
      }
      if (!isFromSocket) {
        this.clickSocketInfo = {
          value: Math.random()
        }
      }
      if (this.step >= this.durationArr.length + 1) {
        return
      }
      this.step++
      if (this.step >= 1) {
        this.controlScroll(this.step)
      }
    },
    handleClickTone(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.toneSocketInfo = {
          index,
          value: Math.random()
        }
        this.handleClickToneValue
      }
      this.toneIndexArr.push(index)
      if (
        this.toneIndexArr.length === 4 &&
        this.pinyinIndexArr.length === this.pinyinImgList.length
      ) {
        this.isLastStep = true
        return
      }
    },
    handleClickPinyin(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.pinyinSocketInfo = {
          index,
          value: Math.random()
        }
      }
      this.pinyinIndexArr.push(index)
      this.step++
    },
    nextPage() {
      this.$bus.$emit('nextButton', true)
    },
    beforeDestroy() {
      this.$bus.$off('clickPinyinItem')
      this.$bus.$off('clickToneItem')
    },
    updatePinyinString() {
      switch (Number(this.lesson)) {
        case 7:
          this.pinyinString = ['a', 'o', 'e', 'i', 'u', 'ü']
          break
        case 15:
          this.pinyinString = ['b', 'p', 'm', 'f']
          break
        case 23:
          this.pinyinString = ['d', 't', 'n', 'l']
          break
        case 31:
          this.pinyinString = ['g', 'k', 'h', 'j', 'q', 'x']
          break
        case 39:
          this.pinyinString = ['z', 'c', 's', 'zh', 'ch', 'sh', 'r']
          break
        case 47:
          this.pinyinString = [
            'ai',
            'ei',
            'ao',
            'ou',
            'ie',
            'ue',
            'ui',
            'iu',
            'er'
          ]
          break
        case 55:
          this.pinyinString = [
            'an',
            'en',
            'un',
            'un1',
            'ang',
            'in',
            'ing',
            'eng',
            'ong'
          ]
          break
        case 63:
          this.pinyinString = [
            'zhi',
            'chi',
            'ri',
            'zi',
            'ci',
            'si',
            'wu',
            'yu',
            'ye',
            'yue',
            'yun',
            'yi',
            'yin',
            'yuan',
            'ying'
          ]
          break
        // 你可以在这里添加更多的 id 和对应的拼音数组
        default:
          this.pinyinString = ['未知 ID']
      }
    }
  }
}
</script>
<style scoped lang="scss">
.game-pinyin-summary {
  background-color: #f7f4e0;
  .pinyin-summary-content {
    position: relative;
    width: fit-content;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: auto;
    .bg-img {
      width: 100%;
      height: 100%;

      img {
        border-radius: 58px;
        object-fit: cover;
        width: 100%;
        height: 100%;
        pointer-events: none;
      }
    }
    .pinyin-area,
    .tone-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: -6%;
      left: 0;
      z-index: 101;
      pointer-events: none;
      //   object-fit: fill;
      img {
        border-radius: 58px;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .check-area {
      position: relative;
      height: 100%;
      z-index: 20;
      .pinyin-image {
        position: absolute;
        top: 0;
        left: 0;
      }
      .check-bgImg {
        width: 100%;
      }
      img {
        position: relative;
        max-width: 100%;
        height: 100%;
        text-align: -webkit-center;
        overflow: hidden;
      }
      .check-item {
        position: absolute;
        // background: #000;
        top: 76%;
        left: 20%;
        cursor: pointer;
      }

      .toneArea-0 {
        width: 53px;
        height: 24px;
        left: 26%;
        top: 71%;
        // background-color: blue;
      }
      .toneArea-1 {
        width: 36px;
        height: 25px;
        // background-color: blue;
        left: 37%;
        top: 71%;
      }
      .toneArea-2 {
        width: 49px;
        height: 28px;
        // background-color: blue;
        left: 55%;
        top: 71%;
      }
      .toneArea-3 {
        width: 36px;
        height: 27px;
        // background-color: blue;
        left: 65%;
        top: 71%;
      }
      .check-item-pinyin-7 {
        .pinyinArea-0 {
          width: 53px;
          height: 58px;
          top: 52%;
          left: 24%;
        }
        .pinyinArea-1 {
          width: 53px;
          height: 58px;
          top: 52%;
          left: 32%;
        }
        .pinyinArea-2 {
          width: 53px;
          height: 58px;
          top: 52%;
          left: 39%;
        }
        .pinyinArea-3 {
          width: 32px;
          height: 72px;
          top: 50%;
          left: 54%;
        }
        .pinyinArea-4 {
          width: 53px;
          height: 58px;
          top: 52%;
          left: 60%;
        }
        .pinyinArea-5 {
          width: 53px;
          height: 68px;
          top: 50%;
          left: 67%;
        }
      }
      .check-item-pinyin-15 {
        .pinyinArea-0 {
          width: 53px;
          height: 58px;
          top: 60%;
          left: 25%;
        }
        .pinyinArea-1 {
          width: 53px;
          height: 58px;
          top: 63%;
          left: 37%;
        }
        .pinyinArea-2 {
          width: 53px;
          height: 58px;
          top: 60%;
          left: 54%;
        }
        .pinyinArea-3 {
          width: 32px;
          height: 62px;
          top: 59%;
          left: 64%;
        }
      }
      .check-item-pinyin-23 {
        .pinyinArea-0 {
          width: 58px;
          height: 76px;
          top: 60%;
          left: 26%;
        }
        .pinyinArea-1 {
          width: 53px;
          height: 68px;
          top: 60%;
          left: 37%;
        }
        .pinyinArea-2 {
          width: 53px;
          height: 58px;
          top: 63%;
          left: 56%;
        }
        .pinyinArea-3 {
          width: 28px;
          height: 76px;
          top: 59%;
          left: 66.5%;
        }
      }
      .check-item-pinyin-31 {
        .pinyinArea-0 {
          width: 6%;
          height: 14%;
          top: 65%;
          left: 26%;
        }
        .pinyinArea-1 {
          width: 5%;
          height: 11%;
          top: 63%;
          left: 34%;
        }
        .pinyinArea-2 {
          width: 5%;
          height: 11%;
          top: 63%;
          left: 41%;
        }
        .pinyinArea-3 {
          width: 4%;
          height: 14%;
          top: 62%;
          left: 56%;
        }
        .pinyinArea-4 {
          width: 5%;
          height: 11%;
          top: 65%;
          left: 61%;
        }
        .pinyinArea-5 {
          width: 5%;
          height: 8%;
          top: 65%;
          left: 67%;
        }
      }
      .check-item-pinyin-39 {
        .pinyinArea-0 {
          width: 5%;
          height: 10%;
          top: 57%;
          left: 27%;
        }
        .pinyinArea-1 {
          width: 5%;
          height: 10%;
          top: 57%;
          left: 38%;
        }
        .pinyinArea-2 {
          width: 5%;
          height: 10%;
          top: 57%;
          left: 56%;
        }
        .pinyinArea-3 {
          width: 10%;
          height: 10%;
          top: 70%;
          left: 24%;
        }
        .pinyinArea-4 {
          width: 10%;
          height: 10%;
          top: 70%;
          left: 35.5%;
        }
        .pinyinArea-5 {
          width: 10%;
          height: 10%;
          top: 70%;
          left: 54%;
        }
        .pinyinArea-6 {
          width: 5%;
          height: 10%;
          top: 70%;
          left: 68%;
        }
      }
      .check-item-pinyin-47 {
        .pinyinArea-0 {
          width: 7%;
          height: 8%;
          top: 50%;
          left: 26%;
        }
        .pinyinArea-1 {
          width: 7%;
          height: 8%;
          top: 50%;
          left: 36%;
        }
        .pinyinArea-2 {
          width: 9%;
          height: 8%;
          top: 50%;
          left: 53%;
        }
        .pinyinArea-3 {
          width: 9%;
          height: 8%;
          top: 50%;
          left: 64%;
        }
        .pinyinArea-4 {
          width: 7%;
          height: 8%;
          top: 63%;
          left: 25%;
        }
        .pinyinArea-5 {
          width: 8%;
          height: 8%;
          top: 63%;
          left: 35%;
        }
        .pinyinArea-6 {
          width: 7%;
          height: 8%;
          top: 63%;
          left: 54%;
        }
        .pinyinArea-7 {
          width: 7%;
          height: 8%;
          top: 63%;
          left: 66%;
        }
        .pinyinArea-8 {
          width: 7%;
          height: 8%;
          top: 75%;
          left: 55%;
        }
      }
      .check-item-pinyin-55 {
        .pinyinArea-0 {
          width: 8%;
          height: 8%;
          top: 50%;
          left: 25%;
        }
        .pinyinArea-1 {
          width: 9%;
          height: 8%;
          top: 50%;
          left: 36%;
        }
        .pinyinArea-2 {
          width: 9%;
          height: 8%;
          top: 50%;
          left: 52%;
        }
        .pinyinArea-3 {
          width: 9%;
          height: 8%;
          top: 50%;
          left: 64%;
        }
        .pinyinArea-4 {
          width: 13%;
          height: 8%;
          top: 63%;
          left: 27%;
        }
        .pinyinArea-5 {
          width: 7%;
          height: 8%;
          top: 63%;
          left: 53%;
        }
        .pinyinArea-6 {
          width: 12%;
          height: 8%;
          top: 63%;
          left: 63%;
        }
        .pinyinArea-7 {
          width: 14%;
          height: 8%;
          top: 75%;
          left: 27%;
        }
        .pinyinArea-8 {
          width: 14%;
          height: 8%;
          top: 75%;
          left: 57%;
        }
      }
      .check-item-pinyin-63 {
        .pinyinArea-0 {
          width: 8%;
          height: 8%;
          top: 48%;
          left: 25%;
        }
        .pinyinArea-1 {
          width: 9%;
          height: 8%;
          top: 48%;
          left: 36%;
        }
        .pinyinArea-2 {
          width: 4%;
          height: 8%;
          top: 48%;
          left: 52%;
        }
        .pinyinArea-3 {
          width: 5%;
          height: 8%;
          top: 48%;
          left: 57%;
        }
        .pinyinArea-4 {
          width: 5%;
          height: 8%;
          top: 48%;
          left: 63%;
        }
        .pinyinArea-5 {
          width: 5%;
          height: 8%;
          top: 48%;
          left: 69%;
        }
        .pinyinArea-6 {
          width: 8%;
          height: 8%;
          top: 58%;
          left: 25%;
        }
        .pinyinArea-7 {
          width: 8%;
          height: 8%;
          top: 58%;
          left: 36%;
        }
        .pinyinArea-8 {
          width: 7%;
          height: 8%;
          top: 58%;
          left: 53%;
        }
        .pinyinArea-9 {
          width: 10%;
          height: 8%;
          top: 58%;
          left: 62%;
        }
        .pinyinArea-10 {
          width: 10%;
          height: 8%;
          top: 68%;
          left: 29%;
        }
        .pinyinArea-11 {
          width: 6%;
          height: 8%;
          top: 68%;
          left: 53%;
        }
        .pinyinArea-12 {
          width: 9%;
          height: 8%;
          top: 68%;
          left: 63%;
        }
        .pinyinArea-13 {
          width: 14%;
          height: 8%;
          top: 78%;
          left: 27%;
        }
        .pinyinArea-14 {
          width: 13%;
          height: 8%;
          top: 79%;
          left: 57%;
        }
      }
      .check-item-pinyin-7 {
        .check-item {
          top: 52%;
          width: 5%;
          height: 40px;
        }

        .pinyinArea-0 {
          left: 23%;
        }
        .pinyinArea-1 {
          left: 32%;
        }

        .pinyinArea-2 {
          left: 40%;
        }
        .pinyinArea-3 {
          left: 52%;
        }
        .pinyinArea-4 {
          left: 60%;
        }
        .pinyinArea-5 {
          left: 68%;
        }
        .pinyinArea-6 {
        }
      }
    }
  }
}
</style>
