var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-moving-spaceship" }, [
    _c("div", { staticClass: "move-game-box position-relative" }, [
      _c("div", { staticClass: "move-game-area" }, [
        _c(
          "div",
          {
            ref: "moveGameBox",
            staticClass: "move-game-content",
            on: { click: _vm.handleClickBgImg },
          },
          [
            _c("img", { attrs: { src: _vm.bgImg, alt: "" } }),
            _c(
              "div",
              {
                ref: "student",
                staticClass: "student-img",
                style: { width: _vm.scrollWidth + "px", height: "100%" },
              },
              [
                _c("div", { staticClass: "object-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "move-wrapper", class: "step-" + _vm.step },
                    [
                      _c("div", {
                        staticClass: "flame",
                        class: { flameIsVisible: _vm.isMoving },
                      }),
                      _vm.studentImg
                        ? _c("img", {
                            staticClass: "move-block",
                            attrs: { src: _vm.studentImg, alt: "" },
                          })
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        ),
        _c("div", { staticClass: "btn-page-corner" }, [
          _c("img", {
            staticClass: "btn-page-corner-img",
            attrs: { src: _vm.btnImg, alt: "" },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }