<template>
  <div class="game-recognition-pinyin">
    <div class="main-content">
      <div class="game-box">
        <Star :totalStarNumber="totalStarNum" :currentIndex="starNum" />
        <div class="game-body">
          <img class="bgImage" :src="imgFrogBg" />
          <img
            class="fixedIamge"
            v-if="optionList[currentIndex] && optionList[currentIndex].image"
            :class="{
              fadeOut: isFadeOut,
              fadeIn: isFadeIn
            }"
            :src="optionList[currentIndex].image"
          />
          <div
            class="speaker-wrapper"
            @click="playPinyinAudio"
            v-if="isHaveAudio"
          >
            <AudioPlayer :isPlay="isStartPlaying" :audioUrl="songUrl" />
          </div>
        </div>
        <div class="bottonCont">
          <div class="redBackground">
            <div class="bottonsWrapper">
              <div
                v-for="(item, index) in selectImgList"
                :key="index"
                class="content-words-two"
                @click="foundAnswer(item.id)"
              >
                <div class="image-container">
                  <img :src="imgFrog" alt="" />
                  <div class="centered-text pinyin" v-if="item.audioPy">
                    {{ item.audioPy }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Star from '@/components/Course/GamePage/Star'
import AudioPlayer from '@/components/Course/GamePage/AudioPlayer'
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    Star,
    AudioPlayer
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          buttonPyList: []
        }
      }
    }
  },
  watch: {
    socketInfo: {
      handler(value) {
        const data = {
          clickType: 20021,
          data: { value },
          text: 'RecognitionPinyinGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002101,
          data: { value },
          text: 'RecognitionPinyinGame点击小喇叭 socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
        this.updateImagePosition()
      },
      deep: true
    }
  },
  data() {
    return {
      selectImgList: [],
      imgFrog: '',
      imgFrogBg: '',
      // titleInfo: {},
      isFadeOut: false,
      isFadeIn: false,
      shuffleSocketValue: false,
      audioSocketInfo: {},
      isStartPlaying: null,
      songUrl: '',
      isHaveAudio: true,
      totalStarNum: 0,
      optionList: [],
      imgList: [],
      starNum: 0,
      currentIndex: 0,
      socketInfo: {},
      randomNumber: 0,
      localData: {
        totalStarNum: 3,
        imgFrog: require('@/assets/img/FixedPicture/frog.svg'),
        imgFrogBg: require('@/assets/img/FixedPicture/frog-background.svg'),
        optionList: [
          {
            id: 1,
            image: require('@/assets/img/FixedPicture/pinyin-a-lips.svg')
          },
          {
            id: 2,
            image: require('@/assets/img/FixedPicture/pinyin-o-lips.svg')
          },
          {
            id: 3,
            image: require('@/assets/img/FixedPicture/pinyin-e-lips.svg')
          }
        ],
        imgList: [
          {
            id: 1,
            nobgcolor: true,
            audioSrc: '',
            audioPy: 'a'
          },
          {
            id: 2,
            nobgcolor: true,
            audioSrc: '',
            audioPy: 'o'
          },
          {
            id: 3,
            nobgcolor: true,
            audioSrc: '',
            audioPy: 'e'
          }
        ]
      }
    }
  },
  mounted() {
    // this.initData()
    this.$bus.$on('RecognitionPinyinClick', ({ index }) => {
      this.foundAnswer(index, true)
    })
    this.$bus.$on('RecognitionPinyinAudio', () => {
      this.playPinyinAudio('socket', true)
    })
    // 首次加载时更新位置
    this.$nextTick(() => {
      this.updateImagePosition()
    })
    this.$bus.$on('shuffleArray', ({ imgList, selectImgList }) => {
      this.imgList = imgList
      this.selectImgList = selectImgList
    })
  },
  beforeDestroy() {
    this.$bus.$off('RecognitionPinyinClick')
    this.$bus.$off('RecognitionPinyinAudio')
    this.$bus.$off('shuffleArray')
  },
  methods: {
    initData() {
      this.imgFrog = this.localData.imgFrog
      this.imgFrogBg = this.localData.imgFrogBg
      this.optionList = []
      this.imgList = []
      if (
        this.componentProps.buttonPyList &&
        this.componentProps.buttonPyList.length
      ) {
        this.componentProps.buttonPyList.forEach((item, index) => {
          this.optionList.push({
            id: index + 1,
            image: item[0].value
          })
          this.imgList.push({
            audioSrc: item[1].value,
            nobgcolor: true,
            id: index + 1,
            audioPy: item[2].value
          })
        })
      }
      this.songUrl = this.imgList[0].audioSrc
      this.totalStarNum = this.optionList.length
      if (this.imgList && this.imgList.length > 3) {
        this.selectImgList = this.getRandomSelection()
      } else {
        this.selectImgList = this.shuffleArray(this.imgList)
      }

      this.sendRandom({
        imgList: this.imgList,
        selectImgList: this.selectImgList
      })
    },
    /**
     * @description: 数组乱序
     * @param {*} array
     * @return {*}
     */
    shuffleArray(array) {
      let currentIndex = array.length,
        temporaryValue,
        randomIndex

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1

        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
      }
      return array
    },
    /**
     * @description: 发送乱序事件
     * @param {*} value
     * @return {*}
     */
    sendRandom(value) {
      const data = {
        clickType: 2001004,
        data: { value },
        text: 'DragGameBtn shuffleSocketValue'
      }
      this.$bus.$emit('kids_webSocket_sendInfo', data)
    },

    /**
     * 监听屏幕大小变化，更新图片位置
     */
    updateImagePosition() {
      const fixedImage = document.querySelector('.fixedIamge')
      const gameBox = document.querySelector('.view-left')
      if (!(gameBox && fixedImage)) {
        if (fixedImage) {
          fixedImage.style.top = '24%'
        }
      }
    },
    playPinyinAudio(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.audioSocketInfo = {
          value: Math.random()
        }
      }
      this.isStartPlaying = Math.random()
    },
    foundAnswer(index, isFromSocket = false) {
      if (this.starNum >= this.optionList.length) {
        return
      }
      if (!isFromSocket) {
        this.socketInfo = {
          index,
          value: Math.random()
        }
      }
      if (this.optionList[this.currentIndex].id === index) {
        playCorrectSound(true, false)
        this.starNum++
        setTimeout(() => {
          if (this.currentIndex + 1 < this.optionList.length) {
            this.isFadeOut = true
            setTimeout(() => {
              this.currentIndex++
              this.selectImgList = this.getRandomSelection()
              this.sendRandom({
                imgList: this.imgList,
                selectImgList: this.selectImgList
              })
              this.isFadeIn = true
              this.isFadeOut = false
            }, 500)
            setTimeout(() => {
              this.isFadeIn = false
            }, 1000)
            this.songUrl = this.imgList[this.currentIndex + 1].audioSrc
          } else {
            playCorrectSound()
            startConfetti()
          }
        }, 1000)
      } else {
        playCorrectSound(false)
      }
    },
    /**
     * @description: 随机选择2个青蛙和答案 并打乱顺序
     * @param {*}
     * @return {*}
     **/
    getRandomSelection() {
      let selectedList = [this.imgList[this.currentIndex]]
      let remainingIndices = this.imgList
        .map((_, index) => index)
        .filter((index) => index !== this.currentIndex)

      while (selectedList.length < 3&& remainingIndices.length) {
        const randIndex = Math.floor(Math.random() * remainingIndices.length)
        const randomElement = this.imgList[remainingIndices[randIndex]]

        selectedList.push(randomElement)
        remainingIndices.splice(randIndex, 1)
      }

      return this.shuffleArray(selectedList)
    }
  }
}
</script>

<style lang="scss" scoped>
.game-recognition-pinyin {
  position: relative;
  width: 100%;
  height: 100%;
  .main-content {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 48px;
    position: relative;
    .game-box {
      text-align: -webkit-center;
      position: absolute;
      height: 100%;
      width: 100%;
      background: #f9f6e6;
      border-radius: 48px;
      .title-area {
        z-index: 9;
        position: absolute;
        top: 0;
        left: 0;
        top: 10%;
        width: 30%;
        height: 17%;
        background: #cd4c3f;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        color: #fff;
        .title {
          .title-pinyin,
          .title-hanzi {
            text-align: center;
          }
        }
      }
    }
    .game-body {
      width: -moz-fit-content;
      width: 100%;
      height: 100%;
      justify-content: center;
      z-index: 0;
      border-radius: 10%;
      display: flex;
      // max-height: 80%;

      .bgImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 48px;
        object-position: top;
        // position: relative;
        &.isCover {
          object-fit: cover !important;
        }
      }
      .fixedIamge {
        width: 130px;
        height: 130px;
        object-fit: cover;
        object-position: center;
        position: absolute;
        top: 20%;
      }
    }
    .bottonCont {
      bottom: 0;
      position: absolute;
      display: flex;
      justify-content: center;
      left: 15%;
      right: 15%;
      align-items: center; /* 垂直居中 */
      // width: 80%;
      // margin: 0 20%;

      .redBackground {
        width: 100%; /* 占满父容器的宽度 */
        height: 110px;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        padding: 0 50px;
        background-color: #d87065;
        display: flex;
        justify-content: center;
        align-items: end; /* 垂直居中 */

        .bottonsWrapper {
          display: flex;
          justify-content: space-around;
          align-items: center; /* 垂直居中 */
          width: 100%; /* 占满父容器宽度 */
          height: 130px; /* 占满父容器高度 */
          padding: 10px 30px;
          border-top-right-radius: 30px;
          border-top-left-radius: 30px;
          background-color: #ffffff;
        }
      }
    }

    .bgcolor {
      background-color: #ce524c;
      border-bottom: 10px solid #a53d32;
      border-top: 10px solid #d87065;
      padding: 10px 30px;
      box-sizing: border-box;
    }
    .bgcolor:hover {
      background-color: rgb(
        200,
        82,
        76
      ); // TODO : adjust hover color based on instructions (pending)
    }
    .content-words-two {
      min-width: 150px;
      min-height: 110px;
      max-height: 110px;
      max-width: 170px;
      z-index: 1;
      border-radius: 20px;

      display: flex;
      flex-flow: column;
      justify-content: end;

      align-items: center;
      margin: 0 10px;
      cursor: pointer;
      span {
        color: #fff;
        font-size: 25px;
      }
      span:nth-child(2) {
        color: #fff;
        font-size: 30px;
      }
      img {
        max-width: 80%;
      }
    }
    .objectDisplay {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 9999;
    }
    .soundIcon {
      position: absolute;
      width: 10%;
      top: 45%;
      left: 45%;
      cursor: pointer;
    }
    .speaker-wrapper {
      position: absolute;
      top: 55%;
      left: calc(50% - 35px);
      width: 70px;
      height: 70px;
      background-color: #cd4c3f;
      border-radius: 50%;
      text-align: center;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
      .speaker-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .toneImg {
      width: 20% !important;
      left: 40% !important;
      top: -3%;
    }
  }
  .image-container {
    position: relative;
    display: inline-block;
  }

  .image-container img {
    width: 100%;
    height: auto;
  }

  .centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #cd4c3f;
    text-align: center;
    margin-top: -4px;
    font-weight: 600;
    font-size: 26px;
  }
}
</style>
