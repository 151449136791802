const singData = {
  lesson1: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-1.mp3')
    },
    timeStampList: [8.2, 12.5, 16.9, 21, 25],

    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Nǐ hǎo! Nǐ hǎo! Wǒ shì Dōngdōng.',
          chineseWords: '你好！你好！我是冬冬。'
        },
        {
          id: 2,
          spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì Xiǎo'ài.",
          chineseWords: '你好！你好！我是小爱。'
        },
        {
          id: 3,
          spelling: 'Nǐ hǎo! Nǐ hǎo! Wǒ shì BB.',
          chineseWords: '你好！你好！我是BB。'
        },
        {
          id: 4,
          spelling: 'Nǐ hǎo! Nǐ hǎo! Wǒ shì ...',
          chineseWords: '你好！你好！我是……'
        }
      ]
    }
  },
  lesson3: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-3.mp3')
    },
    timeStampList: [4.5, 9.5, 14.2, 18.9, 25.5],

    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Yī, yī, yī èr yī.',
          chineseWords: '一、一,一二一,'
        },
        {
          id: 2,
          spelling: 'Yī, èr, sān, sì, wǔ, yī èr yī.',
          chineseWords: '一、二、三、四、五，一二一。'
        },
        {
          id: 3,
          spelling: 'Lǎoshī! Lǎoshī! Lǎoshī hǎo!',
          chineseWords: '老师！老师！老师好！'
        },
        {
          id: 4,
          spelling: 'Nǐ hǎo! Nǐ hǎo! Lǎoshī hǎo!',
          chineseWords: '你好！你好！老师好！'
        }
      ]
    }
  },
  lesson5: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-5.mp3')
    },
    timeStampList: [7.8, 9.8, 11.7, 13.6, 25.5, 27.7, 29.4, 31.7, 35.0],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Yī, èr, sān, sì, wǔ.',
          chineseWords: '一、二、三、四、五，'
        },
        {
          id: 2,
          spelling: 'Lǎoshī nǐ hǎo! Lǎoshī hǎo!',
          chineseWords: '老师你好！老师好！'
        },
        {
          id: 3,
          spelling: 'Liù, qī, bā, jiǔ, shí,',
          chineseWords: '六、七、八、九、十，'
        },
        {
          id: 4,
          spelling: 'Lǎoshī! Lǎoshī! Zàijiàn!',
          chineseWords: '老师！老师！再见！'
        },
        {
          id: 5,
          spelling: 'Yī, èr, sān, sì, wǔ, ',
          chineseWords: '一、二、三、四、五，'
        },
        {
          id: 6,
          spelling: 'Liù, qī, bā, jiǔ, shí.',
          chineseWords: '六、七、八、九、十。'
        },
        {
          id: 7,
          spelling: 'Lǎoshī nǐ hǎo! Lǎoshī hǎo!',
          chineseWords: '老师你好！老师好！'
        },

        {
          id: 8,
          spelling: 'Lǎoshī! Lǎoshī! Zàijiàn!',
          chineseWords: '老师！老师！再见！'
        }
      ]
    }
  },
  lesson7: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-7.mp3')
    },
    timeStampList: [10.4, 15.8, 20.8, 26.1, 41.7, 44.2, 46.9, 49.7, 52],
    singList: {
      lyricsArr: [
        {
          id: 1,
          chineseWords: 'a  o  e  i  u  ü'
        },
        {
          id: 2,
          chineseWords: 'ā á ǎ à, '
        },
        {
          id: 3,
          chineseWords: 'ō ó ǒ ò,'
        },
        {
          id: 4,
          chineseWords: 'ē é ě è,'
        },
        {
          id: 5,
          chineseWords: 'ī í ǐ ì,'
        },
        {
          id: 6,
          chineseWords: 'ū ú ǔ ù,'
        },
        {
          id: 7,
          chineseWords: ' ǖ ǘ ǚ ǜ,'
        },
        {
          id: 8,
          chineseWords: 'a  o  e  i  u  ü'
        }
      ]
    }
  },
  lesson9: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-9.mp3')
    },
    timeStampList: [10.1, 14.5, 19.5, 24.3, 28.9, 36.0],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Bàba, bàba, hǎo bàba,',
          chineseWords: '爸爸、爸爸、好爸爸，'
        },
        {
          id: 2,
          spelling: 'Wǒ ài hǎo bàba.',
          chineseWords: '我爱好爸爸。'
        },
        {
          id: 3,
          spelling: 'Māma, māma, hǎo māma,',
          chineseWords: '妈妈、妈妈，好妈妈，'
        },
        {
          id: 4,
          spelling: 'Wǒ ài hǎo māma.',
          chineseWords: '我爱好妈妈。'
        },
        {
          id: 5,
          spelling: 'Wǒ ài bàba māma.',
          chineseWords: '我爱爸爸妈妈。'
        }
      ]
    }
  },
  lesson11: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-11.mp3')
    },
    timeStampList: [8.0, 12.0, 15.8, 23.8, 28.1, 32.0, 38.5],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Gēge! Wǒ shì yí ge gēge.',
          chineseWords: '哥哥！我是一个哥哥。'
        },
        {
          id: 2,
          spelling: 'Mèimei! Wǒ yǒu yí ge mèimei.',
          chineseWords: '妹妹！我有一个妹妹。'
        },
        {
          id: 3,
          spelling: 'Lā... lā... Wǒ ài mèimei.',
          chineseWords: '啦……啦……我爱妹妹。'
        },
        {
          id: 4,
          spelling: 'Mèimei! Wǒ shì yí ge mèimei.',
          chineseWords: '妹妹！我是一个妹妹。'
        },
        {
          id: 5,
          spelling: 'Gēge! Wǒ yǒu yí ge gēge.',
          chineseWords: '哥哥！我有一个哥哥。'
        },
        {
          id: 6,
          spelling: 'Lā... lā ... Wǒ ài gēge.',
          chineseWords: '啦……啦……我爱哥哥。'
        }
      ]
    }
  },
  lesson13: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-13.mp3')
    },
    timeStampList: [9.4, 15.8, 21.9, 28.8, 38.0],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Bàba, māma, bàba hé māma, ',
          chineseWords: '爸爸、妈妈，爸爸和妈妈，'
        },
        {
          id: 2,
          spelling: 'gēge, jiějie, gēge hé jiějie, ',
          chineseWords: '哥哥、姐姐，哥哥和姐姐，'
        },
        {
          id: 3,
          spelling: 'dìdi, mèimei, dìdi hé mèimei.',
          chineseWords: '弟弟、妹妹，弟弟和妹妹。'
        },
        {
          id: 4,
          spelling: 'Wǒ jiā yǒu sì kǒu rén, bàba, māma, mèimei hé wǒ.',
          chineseWords: '我家有四口人，爸爸、妈妈、妹妹和我。'
        }
      ]
    }
  },
  lesson15: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-15.mp3')
    },
    timeStampList: [8.8, 12.8, 17.3, 21.7, 35.1, 43.3, 53],
    singList: {
      lyricsArr: [
        {
          id: 1,
          chineseWords: 'b, p, m, f'
        },
        {
          id: 2,
          chineseWords: 'bb, bbb, bàba（爸爸）'
        },
        {
          id: 3,
          chineseWords: 'pp, ppp, pópo（婆婆）'
        },
        {
          id: 4,
          chineseWords: 'mm, mmm, māma（妈妈）'
        },
        {
          id: 5,
          chineseWords: 'mm, mmm, ff, fff'
        },
        {
          id: 6,
          chineseWords: 'm-ó-mó,f-ǎ-fǎ,mófǎ（魔法）'
        }
      ]
    }
  },
  lesson17: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-17.mp3')
    },
    timeStampList: [8.1, 12.1, 16.1, 20.1, 24.3, 28.2, 33.0],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Nǐ hǎo! Nǐ shì shéi?',
          chineseWords: '你好！你是谁？'
        },
        {
          id: 2,
          spelling: 'Nǐ hǎo! Wǒ jiào Xiǎotiān.',
          chineseWords: '你好！我叫小天。'
        },
        {
          id: 3,
          spelling: 'Nǐ hǎo! Nǐ shì shéi?',
          chineseWords: '你好！你是谁？'
        },
        {
          id: 4,
          spelling: 'Nǐ hǎo! Wǒ jiào Dōngdōng.',
          chineseWords: '你好！我叫冬冬。'
        },
        {
          id: 5,
          spelling: 'Nǐ hǎo! Tā shì shéi?',
          chineseWords: '你好！她是谁？'
        },
        {
          id: 6,
          spelling: 'Nǐ hǎo! Tā shì Wǒ mèimei!',
          chineseWords: '你好！她是我妹妹！'
        }
      ]
    }
  },
  lesson19: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-19.mp3')
    },
    timeStampList: [4.9, 8.0, 14.8, 21.1, 28.0, 35.0],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Nǐ jiào shénme? Wǒ jiào Dōngdōng!',
          chineseWords: '你叫什么？ 我叫冬冬!'
        },
        {
          id: 2,
          spelling: 'Shénme? Shénme? Shénme? Dōngdōng, Dōngdōng, Dōngdōng.',
          chineseWords: '什么？什么？什么？冬冬,冬冬,冬冬。'
        },
        {
          id: 3,
          spelling: 'Nǐ shì nǎ guó rén? Wǒ shì Zhōngguó rén.',
          chineseWords: '你是哪国人？我是中国人。'
        },
        {
          id: 4,
          spelling: 'Nǐ shì nǎ guó rén? Wǒ shì Jiānádà rén.',
          chineseWords: '你是哪国人？我是加拿大人。'
        },
        {
          id: 5,
          spelling: 'Nǐ shì nǎ guó rén? Wǒ shì Měiguó rén.',
          chineseWords: '你是哪国人？我是美国人。'
        }
      ]
    }
  },
  lesson21: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-21.mp3')
    },
    timeStampList: [8.6, 12.8, 17.0, 21.5, 24.0, 27.0],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Nǐ jǐ suì? Nǐ duō dà?',
          chineseWords: '你几岁？你多大？'
        },
        {
          id: 2,
          spelling: 'Wǒ sì suì.',
          chineseWords: '我四岁。'
        },
        {
          id: 3,
          spelling: 'Nǐ gēge jǐ suì? Nǐ gēge duō dà?',
          chineseWords: '你哥哥几岁？你哥哥多大？'
        },
        {
          id: 4,
          spelling: 'Wǒ gēge liù suì.',
          chineseWords: '我哥哥六岁。'
        },
        {
          id: 5,
          spelling: 'Wǒ yě liù suì.',
          chineseWords: '我也六岁。'
        }
      ]
    }
  },
  lesson23: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-23.mp3')
    },
    timeStampList: [5, 14.1, 18, 22.5, 27, 31, 35.4, 39],
    singList: {
      lyricsArr: [
        {
          id: 1,
          chineseWords: 'd,t,n,l'
        },
        {
          id: 2,
          chineseWords: 'd-à-dà,dà,dà,dà,dà（大）'
        },
        {
          id: 3,
          chineseWords: 't-ī-tī,tī,tī,tī,tī（踢）'
        },
        {
          id: 4,
          chineseWords: 'd-ī-dī,dī,dī,dī,dī（滴）'
        },
        {
          id: 5,
          chineseWords: 'l-ù-lù,lù,lù,lù,lù（路）'
        },
        {
          id: 6,
          chineseWords: 'd-u-ō-duō,duō,duō,duō,duō（多）'
        },
        {
          id: 7,
          chineseWords: 'lālālā…（啦啦啦……）'
        }
      ]
    }
  },
  lesson25: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-25.mp3')
    },
    timeStampList: [4.6, 6.8, 11.5, 16.7, 21.5, 26.0],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Zhè, nà, zhè, nà, ',
          chineseWords: '这、那；这、那；'
        },
        {
          id: 2,
          spelling: 'Nǐ kàn! Nà shì shénme?',
          chineseWords: '你看！那是什么？'
        },
        {
          id: 3,
          spelling: 'Nà shì māo! Nà shì māo!',
          chineseWords: '那是猫！那是猫！'
        },
        {
          id: 4,
          spelling: 'Nǐ kàn! Zhè shì shénme?',
          chineseWords: '你看!这是什么？'
        },
        {
          id: 5,
          spelling: 'Zhè shì ...zhè shì ...māo!',
          chineseWords: '这是……这是……猫！'
        }
      ]
    }
  },
  lesson27: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-27.mp3')
    },
    timeStampList: [7.0, 14.2, 17.3, 21.6, 24.8, 27.6],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Gǒu, niǎo, yú, ',
          chineseWords: '狗、鸟、鱼,'
        },
        {
          id: 2,
          spelling: 'Zhè shì nǐ de gǒu.',
          chineseWords: '这是你的狗。'
        },
        {
          id: 3,
          spelling: 'Zhè shì wǒ de niǎo.',
          chineseWords: '这是我的鸟。'
        },
        {
          id: 4,
          spelling: 'Zhè shì shéi de yú?',
          chineseWords: '这是谁的鱼？'
        },
        {
          id: 5,
          spelling: 'Zhè bú shì nǐ de yú.',
          chineseWords: '这不是你的鱼。'
        }
      ]
    }
  },
  lesson29: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-29.mp3')
    },
    timeStampList: [8.1, 12.8, 25.9, 30.3, 35.8],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Nǐ zài nǎr? Wǒ zài zhèr!',
          chineseWords: '你在哪儿？我在这儿！'
        },
        {
          id: 2,
          spelling: 'Tā zài nǎr? Tā zài zhèr.',
          chineseWords: '他在哪儿？他在这儿。'
        },
        {
          id: 3,
          spelling: 'Gǒu zài nǎr? Gǒu zài nǎr?',
          chineseWords: '狗在哪儿？狗在哪儿？'
        },
        {
          id: 4,
          spelling: 'Gǒu zài nàr! Gǒu zài nàr!',
          chineseWords: '狗在那儿！狗在那儿！'
        }
      ]
    }
  },
  lesson31: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-31.mp3')
    },
    timeStampList: [8, 12, 16.3, 20.1, 24.3, 28, 32, 36, 39.6, 44, 49],
    singList: {
      lyricsArr: [
        {
          id: 1,
          chineseWords: 'g, k, h,'
        },
        {
          id: 2,
          chineseWords: 'j, q, x'
        },
        {
          id: 3,
          chineseWords: 'g - ē - gēge  (哥哥)'
        },
        {
          id: 4,
          chineseWords: 'k - ě - kě (渴)'
        },
        {
          id: 5,
          chineseWords: 'h - ē - hēhē（呵呵）'
        },
        {
          id: 6,
          chineseWords: 'j - ī - jī（鸡）'
        },
        {
          id: 7,
          chineseWords: 'q - ǚ - qǔ（曲）'
        },
        {
          id: 8,
          chineseWords: 'x - ü - xū（嘘）'
        },
        {
          id: 9,
          chineseWords: 'gēge, kě,'
        },
        {
          id: 10,
          chineseWords: 'hēhē, jī, qǔ, xū（哥哥、渴、呵呵、鸡、曲、嘘）'
        }
      ]
    }
  },
  lesson33: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-33.mp3')
    },
    timeStampList: [5.0, 7.5, 10.2, 15.5, 21.2, 26.2, 32.4],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Yǎnjing, yǎnjing, ěrduo, ěrduo,',
          chineseWords: '眼睛、眼睛，耳朵、耳朵，'
        },
        {
          id: 2,
          spelling: 'Zhè shì yǎnjing . Zhè shì ěrduo.',
          chineseWords: '这是眼睛。这是耳朵。'
        },
        {
          id: 3,
          spelling: 'Wǒ de yǎnjing dà. Dà, dà, dà.',
          chineseWords: '我的眼睛大。大、大、大。'
        },
        {
          id: 4,
          spelling: 'Wǒ de ěrduo xiǎo. Xiǎo, xiǎo, xiǎo.',
          chineseWords: '我的耳朵小。小、小、小。'
        },
        {
          id: 5,
          spelling: 'Gǒu de yǎnjing dà. Dà dà dà.',
          chineseWords: '狗的眼睛大。大、大、大。'
        },
        {
          id: 6,
          spelling: 'Māo de ěrduo xiǎo. Xiǎo xiǎo xiǎo.',
          chineseWords: '猫的耳朵小。小、小、小。'
        }
      ]
    }
  },
  lesson35: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-35.mp3')
    },
    timeStampList: [4.3, 8.8, 18.0, 22.6, 26.9],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Zhè shì bízi. Zhè shì yǎnjing.',
          chineseWords: '这是鼻子。这是眼睛。'
        },
        {
          id: 2,
          spelling: 'Wǒ de bízi xiǎo. Wǒ de yǎnjing dà.',
          chineseWords: '我的鼻子小。我的眼睛大。'
        },
        {
          id: 3,
          spelling: 'Wǒ de ěrduo dà. Wǒ de tóufa bù cháng.',
          chineseWords: '我的耳朵大。我的头发不长。'
        },
        {
          id: 4,
          spelling: 'Wǒ shì shéi? Wǒ shì Dòudou.',
          chineseWords: '我是谁？我是豆豆。'
        }
      ]
    }
  },
  lesson37: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-37.mp3')
    },
    timeStampList: [1.8, 5.5, 9.3, 13.1, 17.0, 21.0, 25.1, 30.4],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Shéi de gèzi gāo? Wǒ de gèzi gāo.',
          chineseWords: '谁的个子高？我的个子高。'
        },
        {
          id: 2,
          spelling: 'Gāo, gāo, gāo gāo gāo!',
          chineseWords: '高、高，高高高！'
        },
        {
          id: 3,
          spelling: 'Shéi de shǒu dà? Bàba de shǒu dà.',
          chineseWords: '谁的手大？爸爸的手大。'
        },
        {
          id: 4,
          spelling: 'Dà, dà, dà dà dà.',
          chineseWords: '大、大、大大大。'
        },
        {
          id: 5,
          spelling: 'Wǒ yǒu māo. Tā yǒu gǒu.',
          chineseWords: '我有猫。他有狗。'
        },
        {
          id: 6,
          spelling: 'Wǒ méiyǒu gǒu. Tā méiyǒu māo.',
          chineseWords: '我没有狗。他没有猫。'
        },
        {
          id: 7,
          spelling: 'Wǒ méiyǒu gǒu. Tā méiyǒu māo.',
          chineseWords: '我没有狗。他没有猫。'
        }
      ]
    }
  },
  lesson39: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-39.mp3')
    },
    timeStampList: [9.7, 11.5, 19, 21, 23.3, 28.7, 30.1, 34],
    singList: {
      lyricsArr: [
        {
          id: 1,
          chineseWords: 'z,c,s'
        },
        {
          id: 2,
          chineseWords: 'zh,ch,sh,r'
        },
        {
          id: 3,
          chineseWords: 'chá（茶）'
        },
        {
          id: 4,
          chineseWords: 'zhuō（桌）'
        },
        {
          id: 5,
          chineseWords: 'shū（书）'
        },
        {
          id: 6,
          chineseWords: 'z, c, s'
        },
        {
          id: 7,
          chineseWords: 'zh,ch,sh,r'
        }
      ]
    }
  },
  lesson41: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-41.mp3')
    },
    timeStampList: [8.5, 12.5, 16.9, 21, 25.5, 29],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Nǐ xǐhuan chī shénme?',
          chineseWords: '你喜欢吃什么？'
        },
        {
          id: 2,
          spelling: 'Wǒ xǐhuan chī píngguǒ.',
          chineseWords: '我喜欢吃苹果。'
        },
        {
          id: 3,
          spelling: 'Nǐ xǐhuan chī píngguǒ ma?',
          chineseWords: '你喜欢吃苹果吗？'
        },
        {
          id: 4,
          spelling: 'Wǒ xǐhuan! Wǒ xǐhuan!',
          chineseWords: '我喜欢！我喜欢！'
        },

        {
          id: 5,
          spelling: 'Wǒ hěn xǐhuan chī píngguǒ.',
          chineseWords: '我很喜欢吃苹果。'
        }
      ]
    }
  },
  lesson43: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-43.mp3')
    },
    timeStampList: [5, 7.5, 10.8, 21.8, 24, 27.5, 33],
    // 22
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Nǐ hē shénme? ',
          chineseWords: '你喝什么？'
        },
        {
          id: 2,
          spelling: 'Zhèr yǒu shuǐ, yě yǒu niúnǎi.',
          chineseWords: '这儿有水，也有牛奶。'
        },
        {
          id: 3,
          spelling: 'Wǒ hē niúnǎi. Xièxie nǐ!',
          chineseWords: '我喝牛奶。谢谢你！'
        },
        {
          id: 4,
          spelling: 'Nǐ chī shénme?',
          chineseWords: '你吃什么？'
        },
        {
          id: 5,
          spelling: 'Zhèr yǒu yú, yě yǒu píngguǒ.',
          chineseWords: '这儿有鱼，也有苹果。'
        },
        {
          id: 6,
          spelling: 'Wǒ chī píngguǒ. Xièxie nǐ.',
          chineseWords: '我吃苹果。谢谢你。'
        }
      ]
    }
  },
  lesson45: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-45.mp3')
    },
    timeStampList: [5.4, 7.5, 10.8, 13.3, 15.8, 22],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Bàba xǐhuan chī mǐfàn.',
          chineseWords: '爸爸喜欢吃米饭。'
        },
        {
          id: 2,
          spelling: 'Gēge xǐhuan chī miàntiáor',
          chineseWords: '哥哥喜欢吃面条儿。'
        },
        {
          id: 3,
          spelling: 'Nǐ xǐhuan chī shénme?',
          chineseWords: '你喜欢吃什么？'
        },
        {
          id: 4,
          spelling: 'Wǒ xǐhuan chī dàngāo.',
          chineseWords: '我喜欢吃蛋糕。'
        },
        {
          id: 5,
          spelling: 'Zhè ge dàngāo zhēn dà! Māma, nǐ zhēn hǎo!',
          chineseWords: '这个蛋糕真大！妈妈，你真好！'
        }
      ]
    }
  },
  lesson47: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-47.mp3')
    },
    timeStampList: [10.3, 13.4, 20.6, 23.4, 33.3, 36.2, 40, 41.3, 50],
    singList: {
      lyricsArr: [
        {
          id: 1,
          chineseWords: 'ai, ei,ui'
        },
        {
          id: 2,
          chineseWords: 'ài-wǒ ài nǐ, mèimei（妹妹）'
        },
        {
          id: 3,
          chineseWords: 'ao,ou,iu'
        },
        {
          id: 4,
          chineseWords: 'm, g, n, māo（猫）,gǒu（狗）, niú（牛）'
        },
        {
          id: 5,
          chineseWords: 'ie, üe, er'
        },
        {
          id: 6,
          chineseWords: 'Wǒ zài xuéxiào.（我在学校。）'
        },
        {
          id: 7,
          chineseWords: 'Xièxie nǐ.（谢谢你。）'
        },
        {
          id: 8,
          chineseWords: 'ai, ei, ui, ao, ou, iu, ie, üe, er'
        }
      ]
    }
  },
  lesson49: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-49.mp3')
    },
    timeStampList: [5.2, 10.6, 21.5, 24, 26, 29, 33],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Xuéxiào, shāngdiàn, chāoshì,',
          chineseWords: '学校、商店、超市；'
        },
        {
          id: 2,
          spelling: 'Xuéxiào, shāngdiàn, chāoshì,',
          chineseWords: '学校、商店、超市；'
        },
        {
          id: 3,
          spelling: 'Māma qù shāngdiàn.',
          chineseWords: '妈妈去商店。'
        },
        {
          id: 4,
          spelling: 'Bàba qù chāoshì.',
          chineseWords: '爸爸去超市。'
        },
        {
          id: 5,
          spelling: 'Nǐ qù nǎr?',
          chineseWords: '你去哪儿？'
        },
        {
          id: 6,
          spelling: 'Wǒ qù xuéxiào.',
          chineseWords: '我去学校。'
        }
      ]
    }
  },
  lesson51: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-51.mp3')
    },
    timeStampList: [5, 6.9, 9.6, 19, 23.9, 28.5],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Nǐ hǎo! Nǐ hǎo! Nǐmen hǎo!',
          chineseWords: '你好！你好！你们好！'
        },
        {
          id: 2,
          spelling: 'Nǐmen xǐhuan chī shénme?',
          chineseWords: '你们喜欢吃什么？'
        },
        {
          id: 3,
          spelling: 'Wǒmen xǐhuan chī Běijīng kǎoyā.',
          chineseWords: '我们喜欢吃北京烤鸭。'
        },
        {
          id: 4,
          spelling: 'Nǐmen xǐhuan zài nǎr chī Běijīng kǎoyā?',
          chineseWords: '你们喜欢在哪儿吃北京烤鸭？'
        },
        {
          id: 5,
          spelling: 'Wǒmen xǐhuan qù Běijīng Fàndiàn chī kǎoyā.',
          chineseWords: '我们喜欢去北京饭店吃烤鸭。'
        }
      ]
    }
  },
  lesson53: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-53.mp3')
    },
    timeStampList: [4.5, 6.5, 8.8, 13.2, 18.4, 21],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Wǒmen xǐhuan gōngyuán.',
          chineseWords: '我们喜欢公园。'
        },
        {
          id: 2,
          spelling: 'Wǒmen xiǎng qù gōngyuán.',
          chineseWords: '我们想去公园。'
        },
        {
          id: 3,
          spelling: 'Nàr yǒu hěn duō gǒu. Zhèr yǒu hěn duō yú.',
          chineseWords: '那儿有很多狗；这儿有很多鱼。'
        },
        {
          id: 4,
          spelling: 'Ā! Hěn gāoxìng rènshi nǐ.',
          chineseWords: '啊！很高兴认识你。'
        },
        {
          id: 5,
          spelling: 'Wǒ yě hěn gāoxìng rènshi nǐ.',
          chineseWords: '我也很高兴认识你。'
        }
      ]
    }
  },
  lesson55: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-55.mp3')
    },
    timeStampList: [6, 8, 10.4, 12.6, 13.7, 15.3, 19.1, 22, 25.7, 28, 29.5, 33],
    singList: {
      lyricsArr: [
        {
          id: 1,
          chineseWords: 'an, en, in'
        },
        {
          id: 2,
          chineseWords: 'un, ün'
        },
        {
          id: 3,
          chineseWords: 'ang, eng, ing, ong'
        },
        {
          id: 4,
          chineseWords: 'dàn, dàn（蛋）'
        },
        {
          id: 5,
          chineseWords: 'kǒnglóng, kǒnglóng（恐龙）'
        },
        {
          id: 6,
          chineseWords: 'sēnlín, sēnlín（森林）'
        },
        {
          id: 7,
          chineseWords: 'làng, làng（浪）'
        },
        {
          id: 8,
          chineseWords: 'làng tíng le.（浪停了）'
        },
        {
          id: 9,
          chineseWords: 'an, en, in'
        },
        {
          id: 10,
          chineseWords: 'un, ün'
        },
        {
          id: 11,
          chineseWords: 'ang, eng, ing, ong'
        }
      ]
    }
  },
  lesson57: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-57.mp3')
    },
    timeStampList: [6.1, 9.6, 12.5, 15.5, 19, 22.5],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Jīntiān shì jǐ yuè jǐ hào?',
          chineseWords: '今天是几月几号？'
        },
        {
          id: 2,
          spelling: 'Jīntiān shì shí yuè yī hào.',
          chineseWords: '今天是10月一号。'
        },
        {
          id: 3,
          spelling: 'Jīntiān shì shéi de shēngrì?',
          chineseWords: '今天是谁的生日？'
        },
        {
          id: 4,
          spelling: 'Jīntiān shì wǒ de shēngrì.',
          chineseWords: '今天是我的生日。'
        },
        {
          id: 5,
          spelling: 'Wǒ xǐhuan chī shēngrì dàngāo!',
          chineseWords: '我喜欢吃生日蛋糕！'
        }
      ]
    }
  },
  lesson59: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-59.mp3')
    },
    timeStampList: [3.3, 7.3, 10.6, 14.3, 22, 25.4, 31, 34],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Xiànzài bā diǎn! bā diǎn! bā diǎn!',
          chineseWords: '现在8点！8点！8点！'
        },
        {
          id: 2,
          spelling: 'Wǒ bā diǎn qù xuéxiào.',
          chineseWords: '我8点去学校。'
        },
        {
          id: 3,
          spelling: "Xiànzài shí'èr diǎn! shí'èr diǎn! shí'èr diǎn!",
          chineseWords: '现在12点！12点！12点！'
        },
        {
          id: 4,
          spelling: "Wǒ shí'èr diǎn chī fàn.",
          chineseWords: '我12点吃饭。'
        },
        {
          id: 5,
          spelling: 'Lǎoshī! Xiànzài jǐ diǎn?',
          chineseWords: '老师！现在几点？'
        },
        {
          id: 6,
          spelling: 'Xiànzài wǔ diǎn sānshí fēn.',
          chineseWords: '现在5点30分。'
        },
        {
          id: 7,
          spelling: 'Lǎoshī, zàijiàn!',
          chineseWords: '老师，再见！'
        }
      ]
    }
  },
  lesson61: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-61.mp3')
    },
    timeStampList: [7, 10.5, 14.2, 25, 28.7, 31],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'yī, èr, sān, sì, wǔ, liù, qī,',
          chineseWords: '一、二、三、四、五、六、七，'
        },
        {
          id: 2,
          spelling: ' Yí ge xīngqī yǒu qī tiān.',
          chineseWords: '一个星期有七天。'
        },
        {
          id: 3,
          spelling:
            "Xīngqīyī, Xīngqī'èr, Xīngqīsān, Xīngqīsì, Xīngqīwǔ, Xīngqīliù,",
          chineseWords: '星期一、星期二，星期三、星期四，星期五、星期六。'
        },
        {
          id: 4,
          spelling: 'Jīntiān Xīngqī jǐ?',
          chineseWords: '今天星期几？'
        },
        {
          id: 5,
          spelling: 'Jīntiān Xīngqītiān!',
          chineseWords: '今天星期天！'
        }
      ]
    }
  },
  lesson63: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-63.mp3')
    },
    timeStampList: [8.3, 12, 15.1, 17, 21.3, 23, 24],
    singList: {
      lyricsArr: [
        {
          id: 1,
          chineseWords: 'zhi, chi, shi, ri, zi, ci, si'
        },
        {
          id: 2,
          chineseWords: 'shīzi（狮子）, shīzi（狮子）'
        },
        {
          id: 3,
          chineseWords: 'y, w, yi, wu, yu, ye, yue, yuan'
        },
        {
          id: 4,
          chineseWords: 'yin, yun, ying'
        },
        {
          id: 5,
          chineseWords: 'yīngwǔ（鹦鹉）'
        },
        {
          id: 6,
          chineseWords: 'yīnyuè（音乐）'
        },
        {
          id: 7,
          chineseWords: 'yuèliang（月亮）'
        }
      ]
    }
  },
  lesson65: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-65.mp3')
    },
    timeStampList: [7.6, 14.4, 28.5, 35.5, 44],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'wǒmen kěyǐ zuò zhèr ma？',
          chineseWords: '我们可以坐这儿吗？'
        },
        {
          id: 2,
          spelling: 'Kěyǐ,kěyǐ,qǐng zuò！',
          chineseWords: '可以，可以，请坐！'
        },
        {
          id: 3,
          spelling: 'Zhè shì nǐmen de dà dàngāo.',
          chineseWords: '这是你们的大蛋糕。'
        },
        {
          id: 4,
          spelling: 'xièxie nǐ ！xièxie nǐ ！',
          chineseWords: '谢谢你！谢谢你！'
        },
        {
          id: 5,
          spelling: 'bú kèqi！bú kèqi！',
          chineseWords: '不客气，不客气！'
        }
      ]
    }
  },
  lesson67: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-67.mp3')
    },
    timeStampList: [7.0, 10.9, 14.4, 18.1, 21.4, 25.5, 29.1, 32.2],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Bāozi,bāozi,sān ge bāozi.',
          chineseWords: '包子，包子，三个包子。'
        },
        {
          id: 2,
          spelling: 'Wǒ yào chī sān ge bāozi.',
          chineseWords: '我要吃三个包子。'
        },
        {
          id: 3,
          spelling: 'Niúnǎi,niúnǎi,yì bēi niúnǎi. ',
          chineseWords: '牛奶，牛奶，一杯牛奶。'
        },
        {
          id: 4,
          spelling: 'Wǒ yào hē yì bēi niúnǎi.',
          chineseWords: '我要喝一杯牛奶。'
        },
        {
          id: 5,
          spelling: 'Qǐng chī bāozi,qǐng hē niúnǎi.',
          chineseWords: '请吃包子，请喝牛奶。'
        },
        {
          id: 6,
          spelling: 'Āiya!',
          chineseWords: '（哎呀）'
        },
        {
          id: 7,
          spelling: 'Duìbuqǐ,duìbuqǐ.',
          chineseWords: '对不起，对不起。'
        },
        {
          id: 8,
          spelling: 'Méi guānxi,méi guānxi.',
          chineseWords: '没关系，没关系。'
        }
      ]
    }
  },
  lesson69: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-69.mp3')
    },
    timeStampList: [4.1, 8.4, 13.0, 21.8, 24.6],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Bāozi,bāozi,zhēn hǎochī.',
          chineseWords: '包子，包子，真好吃。'
        },
        {
          id: 2,
          spelling: 'Niúnǎi,niúnǎi,zhēn hǎohē.',
          chineseWords: '牛奶，牛奶，真好喝。'
        },
        {
          id: 3,
          spelling: '    Xū,qǐng búyào shuōhuà！ ',
          chineseWords: '嘘，请不要说话！'
        },
        {
          id: 4,
          spelling: 'Ō!Duìbuqǐ,duìbuqǐ.',
          chineseWords: '噢! 对不起，对不起。 '
        },
        {
          id: 5,
          spelling: 'Méi guānxi,méiguānxi.',
          chineseWords: '没关系，没关系。'
        }
      ]
    }
  },
  lesson71: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-71.wav')
    },
    timeStampList: [17.8, 22.5, 29.3, 35.3, 43.3],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Bāozi,bāozi,jiǎozi,jiǎozi,',
          chineseWords: '包子、包子，饺子、饺子，'
        },
        {
          id: 2,
          spelling: 'Wǒ xǐhuan chī bāozi.Tā xǐhuan chī jiǎozi.',
          chineseWords: '我喜欢吃包子。他喜欢吃饺子。'
        },
        {
          id: 3,
          spelling: 'Dòujiāng yóutiáo, miàntiáor,miàntiáor,',
          chineseWords: '豆浆，油条，面条儿、面条儿，。'
        },
        {
          id: 4,
          spelling: 'Chī yóutiáo,hē dòujiāng.Shéi xǐhuan chī miàntiáor？',
          chineseWords: '吃油条，喝豆浆。谁喜欢吃面条儿？'
        },
        {
          id: 5,
          spelling: 'Bāozi,jiǎozi,dòujiāng,yóutiáo hé miàntiáor.',
          chineseWords: '包子、饺子、豆浆、油条和面条儿。'
        }
      ]
    }
  },
  lesson75: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-75.mp3')
    },
    timeStampList: [7.7, 11.7, 15.6, 19.1, 31.7],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'wán qiú,wán qiú,zǎoshang wán qiú,',
          chineseWords: '玩球，玩球，早上玩球'
        },
        {
          id: 2,
          spelling: 'wǒmen zǎoshang wán qiú,xiàwǔ ne ？',
          chineseWords: '我们早上玩球，下午呢？'
        },
        {
          id: 3,
          spelling: 'Xiàwǔ,xiàwǔ,xiàwǔ kàn diànshì.',
          chineseWords: '下午、下午，下午看电视。'
        },
        {
          id: 4,
          spelling: 'Wǒmen xiàwǔ kàn diànshì,wǎnshang ne？',
          chineseWords: '我们下午看电视，晚上呢？'
        },
        {
          id: 5,
          spelling: 'Wǎnshang,wǎnshang ,Wǒmen wǎnshang yào shuìjiào lā！',
          chineseWords: '晚上、晚上，我们晚上要睡觉啦！'
        }
      ]
    }
  },
  lesson77: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-77.wav')
    },
    timeStampList: [11.5, 14.6, 17.5, 24.0, 26.0, 28.6],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Nǐ zuótiān qù nǎr le？',
          chineseWords: '你昨天去哪儿了？ '
        },
        {
          id: 2,
          spelling: 'Wǒ zuótiān qù gōngyuán le. ',
          chineseWords: '我昨天去公园了。'
        },
        {
          id: 3,
          spelling: 'Wǒ qù gōngyuán qímǎ le,nǐ ne？',
          chineseWords: '我去公园骑马了，你呢？'
        },
        {
          id: 4,
          spelling: 'Wǒ méi qù gōngyuán,',
          chineseWords: '我没去公园，'
        },
        {
          id: 5,
          spelling: 'wǒ hé bàba māma zài jiā.',
          chineseWords: '我和爸爸妈妈在家。'
        },
        {
          id: 6,
          spelling: 'Wǒmen zài yìqǐ kàn diànshì.',
          chineseWords: '我们在一起看电视。'
        }
      ]
    }
  },
  lesson79: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-79.wav')
    },
    timeStampList: [16.6, 22.1, 32.7, 38.7, 48.6, 54.2],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Máobǐ ，máobǐ ，máobǐ zì,',
          chineseWords: '毛笔，毛笔，毛笔字 ,'
        },
        {
          id: 2,
          spelling: 'wǒ de máobǐ hěn dà,wǒ de máobǐ zì hěn piàoliang.',
          chineseWords: '我的毛笔很大，我的毛笔字很漂亮。'
        },
        {
          id: 3,
          spelling: 'Shànzi,shànzi,tiào guǎngchǎngwǔ, ',
          chineseWords: '扇子，扇子，跳广场舞,'
        },
        {
          id: 4,
          spelling: 'wǒ de shànzi hěn piàoliang,wǒ xǐhuan tiào guǎngchǎngwǔ.',
          chineseWords: '我的扇子很漂亮，我喜欢跳广场舞。'
        },
        {
          id: 5,
          spelling: 'zǎo shuì,zǎo qǐ,zǎo shuì zǎo qǐ,',
          chineseWords: '早睡，早起，早睡早起 ,'
        },
        {
          id: 6,
          spelling: 'zǎo shuì zǎo qǐ shēntǐ hǎo.',
          chineseWords: '早睡早起身体好。'
        }
      ]
    }
  },
  lesson73: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-73.mp3')
    },
    timeStampList: [4.6, 6.8, 9.1, 11.7, 23.7, 25.8, 28.3, 30.9],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Zǎoshang,zǎoshang,zǎoshang bā diǎn,',
          chineseWords: '早上，早上，早上8点'
        },
        {
          id: 2,
          spelling: 'wǒmen zǎoshang bā diǎn qǐchuáng.',
          chineseWords: '我们早上8点起床。'
        },
        {
          id: 3,
          spelling: 'Xiàwǔ,xiàwǔ,xiàwǔ sān diǎn,',
          chineseWords: '下午，下午，下午3点'
        },
        {
          id: 4,
          spelling: 'wǒmen xiàwǔ sān diǎn qù gōng yuán.',
          chineseWords: '我们下午3点 去公园。'
        },
        {
          id: 5,
          spelling: 'Wǎnshang,wǎnshang ,wǎnshang jǐ diǎn？',
          chineseWords: '晚上，晚上，晚上几点？'
        },
        {
          id: 6,
          spelling: 'Nǐmen wǎnshang jǐ diǎn shuìjiào ？',
          chineseWords: '你们晚上几点睡觉？'
        },
        {
          id: 7,
          spelling: 'Wǎnshang,wǎnshang ,wǎnshang jiǔ diǎn,',
          chineseWords: '晚上，晚上，晚上9点。'
        },
        {
          id: 8,
          spelling: 'Wǒmen wǎnshang jiǔ diǎn shuìjiào.',
          chineseWords: '我们晚上9点睡觉。'
        }
      ]
    }
  },
  lesson81: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-81.mp3')
    },
    timeStampList: [6.2, 9.3, 12.6, 15.7, 18.9, 22.1, 25.3, 28.7],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Nǐ hǎo! Nǐ hǎo! Wǒ shì Dōngdōng.',
          chineseWords: '铅笔，铅笔，'
        },
        {
          id: 2,
          spelling: 'Nǐ hǎo! Nǐ hǎo! Wǒ shì Xiǎo Ài.',
          chineseWords: '铅笔在床上吗？'
        },
        {
          id: 3,
          spelling: 'Nǐ hǎo! Nǐ hǎo! Wǒ shì BB',
          chineseWords: '铅笔，铅笔，'
        },
        {
          id: 4,
          spelling: 'Nǐ hǎo! Nǐ hǎo! Wǒ shì ...',
          chineseWords: '铅笔在桌子上。'
        },
        {
          id: 5,
          spelling: 'Nǐ hǎo! Nǐ hǎo! Wǒ shì ...',
          chineseWords: '眼镜，眼镜，'
        },
        {
          id: 6,
          spelling: 'Nǐ hǎo! Nǐ hǎo! Wǒ shì ...',
          chineseWords: '眼镜在桌子上吗？'
        },
        {
          id: 7,
          spelling: 'Nǐ hǎo! Nǐ hǎo! Wǒ shì ...',
          chineseWords: '眼镜，眼镜，'
        },
        {
          id: 8,
          spelling: 'Nǐ hǎo! Nǐ hǎo! Wǒ shì ...',
          chineseWords: '眼镜在鼻子上！'
        }
      ]
    }
  },
  lesson83: {
    songInfo: {
      songUrl: require('@/assets/audio/songs/lesson-83.mp3')
    },
    timeStampList: [6.2, 9.3, 12.6, 15.7, 18.9, 22.1, 25.3, 28.7],
    singList: {
      lyricsArr: [
        {
          id: 1,
          spelling: 'Nǐ hǎo! Nǐ hǎo! Wǒ shì Dōngdōng.',
          chineseWords: '我的猫在哪？'
        },
        {
          id: 2,
          spelling: 'Nǐ hǎo! Nǐ hǎo! Wǒ shì Xiǎo Ài.',
          chineseWords: '我的猫在哪？'
        },
        {
          id: 3,
          spelling: 'Nǐ hǎo! Nǐ hǎo! Wǒ shì BB',
          chineseWords: '不在桌子下边，'
        },
        {
          id: 4,
          spelling: 'Nǐ hǎo! Nǐ hǎo! Wǒ shì ...',
          chineseWords: '不在椅子下边，'
        },
        {
          id: 4,
          spelling: 'Nǐ hǎo! Nǐ hǎo! Wǒ shì ...',
          chineseWords: '也不在床下边。'
        },
        {
          id: 4,
          spelling: 'Nǐ hǎo! Nǐ hǎo! Wǒ shì ...',
          chineseWords: '看！'
        },
        {
          id: 7,
          spelling: 'Nǐ hǎo! Nǐ hǎo! Wǒ shì ...',
          chineseWords: '那本书下是什么？'
        },
        {
          id: 8,
          spelling: 'Nǐ hǎo! Nǐ hǎo! Wǒ shì ...',
          chineseWords: '那本书下是我的猫！'
        }
      ]
    }
  }
}

// 通过课程id来获取数据
function getSingData(lessonId) {
  return singData[lessonId] || null
}

export { getSingData }
