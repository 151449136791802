var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "word-container" }, [
    _c(
      "div",
      {
        staticClass: "word-box",
        class: {
          hover: _vm.isHover && _vm.wordInfo.wordPy,
          symbolItem: !_vm.wordInfo.wordPy,
        },
        on: {
          mouseenter: _vm.handleHover,
          mouseleave: _vm.handleNotHover,
          click: _vm.handlePlayWords,
        },
      },
      [
        _c("div", { staticClass: "pinyin font-pinyin-medium" }, [
          _vm._v(" " + _vm._s(_vm.wordInfo.wordPy) + " "),
        ]),
        _c("div", { staticClass: "hanzi font-hanzi-medium" }, [
          _vm._v(" " + _vm._s(_vm.wordInfo.wordZh) + " "),
        ]),
      ]
    ),
    false
      ? _c(
          "div",
          {
            staticClass: "img-box",
            class: { hover: _vm.isHover },
            on: {
              mouseenter: _vm.handleHover,
              mouseleave: _vm.handleNotHover,
              click: _vm.handlePlayWords,
            },
          },
          [
            _c("div", { staticClass: "stroke-img" }, [
              _c("img", { attrs: { src: _vm.wordInfo.imgUrl, alt: "" } }),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }