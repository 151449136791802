var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-choose-words-by-pic-container" }, [
    _c("div", { staticClass: "game-content" }, [
      _c("div", { staticClass: "title-area" }, [
        _c("div", { staticClass: "title-box" }, [
          _c("div", { staticClass: "title" }, [
            _c(
              "div",
              { staticClass: "title-pinyin pinyin font-pinyin-medium" },
              [_vm._v(" " + _vm._s(_vm.titlePinyin) + " ")]
            ),
            _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
              _vm._v(" " + _vm._s(_vm.titleHanZi) + " "),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "choose-hanzi-box" }, [
        _c("div", { staticClass: "answer-area" }, [
          _c("div", { staticClass: "answer-img" }, [
            _c("img", { attrs: { src: _vm.img, alt: "" } }),
          ]),
          !_vm.isShowAllWords
            ? _c(
                "div",
                { staticClass: "answer-hanzi-area" },
                [
                  _c("div", { staticClass: "hanzi-item hasInserted" }, [
                    _c("div", { staticClass: "pinyin font-pinyin-medium" }, [
                      _vm._v(_vm._s(_vm.tipPinyin)),
                    ]),
                    _c("div", { staticClass: "tipHanziSize" }, [
                      _vm._v(_vm._s(_vm.tipHanzi)),
                    ]),
                  ]),
                  _vm._l(_vm.hanziRightList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: item.id + "right",
                        staticClass: "hanzi-item",
                        class: {
                          active: _vm.insertIndex === index,
                          hasInserted:
                            _vm.rightHanziIndexArr.indexOf(index + 1) > -1,
                        },
                      },
                      [
                        _c("span", { staticClass: "insertedpinyin" }, [
                          _vm._v(_vm._s(item.pinyin)),
                        ]),
                        _c("span", { staticClass: "insertedWords" }, [
                          _vm._v(_vm._s(item.hanzi)),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              )
            : _c("div", { staticClass: "answer-right-hanzi-area" }, [
                _c("div", { staticClass: "right-hanzi-box" }, [
                  _c("span", { staticClass: "rightPinyin" }, [
                    _vm._v(_vm._s(_vm.correctPinyin)),
                  ]),
                  _c("span", { staticClass: "font-hanzi-medium" }, [
                    _vm._v(_vm._s(_vm.correctHanzi)),
                  ]),
                ]),
              ]),
        ]),
        _c(
          "div",
          { staticClass: "choose-hanzi-area" },
          _vm._l(_vm.hanziChooseList, function (item, index) {
            return _c(
              "div",
              {
                key: index + "choose",
                staticClass: "hanzi-item",
                class: { disappear: _vm.choosedHanziArr.indexOf(index) > -1 },
                on: {
                  click: function ($event) {
                    return _vm.handleChooseHanzi(item.id, index)
                  },
                },
              },
              [
                _c("span", { staticClass: "pinyin font-pinyin-medium" }, [
                  _vm._v(_vm._s(item.pinyin)),
                ]),
                _c("span", { staticClass: "font-hanzi-medium" }, [
                  _vm._v(_vm._s(item.hanzi)),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }