var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-recognition-card-container" }, [
    _c("div", { staticClass: "red-background-border" }),
    _c("div", { staticClass: "game-card-content" }, [
      _c("audio", { ref: "audioBox", attrs: { src: _vm.songUrl } }),
      _c("div", { staticClass: "recogniton-card-content" }, [
        _c("div", { staticClass: "recogniton-card-left" }, [
          _c("img", {
            staticClass: "santa-claus",
            attrs: { src: _vm.backgroundImage, alt: "" },
          }),
        ]),
        _c("div", { staticClass: "recogniton-card-right" }, [
          _c("div", { staticClass: "recogniton_card_right_border" }, [
            _c(
              "div",
              { staticClass: "recognize_container_content_right_content" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "recognize_container_content_right_content_top volume-button",
                    class: { "teacher-hover": _vm.isClickAudio },
                  },
                  [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.activeClick("socket")
                          },
                        },
                      },
                      [
                        _c("AudioPlayer", {
                          attrs: {
                            title: _vm.wordObj.chineseName,
                            isPlay: _vm.isStartPlaying,
                            audioUrl: _vm.songUrl,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm.wordObj.pinName != ""
                  ? _c("div", { staticClass: "redColor" }, [
                      _c("p", { staticClass: "pinyin font-pinyin-xlarge" }, [
                        _vm._v(" " + _vm._s(_vm.wordObj.pinName) + " "),
                      ]),
                      _c(
                        "p",
                        {
                          staticClass: "font-hanzi-xlarge",
                          attrs: { id: "face" },
                        },
                        [_vm._v(" " + _vm._s(_vm.wordObj.chineseName) + " ")]
                      ),
                    ])
                  : _vm._e(),
                _vm.wordObj.pinName != ""
                  ? _c("div", { staticClass: "englishName" }, [
                      _c("p", { staticClass: "'font-hanzi-medium'" }, [
                        _vm._v(" " + _vm._s(_vm.wordObj.englishName) + " "),
                      ]),
                    ])
                  : _vm._e(),
                _vm.wordObj.pinName == ""
                  ? _c("div", { staticClass: "englishName" }, [
                      _c("p", { staticClass: "font-hanzi-xlarge" }, [
                        _vm._v(" " + _vm._s(_vm.wordObj.englishName) + " "),
                      ]),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }