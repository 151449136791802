var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "mainCont" },
      [
        _c("Star", {
          attrs: {
            totalStarNumber: _vm.optionList.length,
            currentIndex: _vm.steps,
          },
        }),
        _c("div", { staticClass: "background" }, [
          _c("img", { staticClass: "bg-image", attrs: { src: _vm.bgImage } }),
          _c("div", { staticClass: "backgroundImg_n_DragArea" }, [
            _c(
              "div",
              {
                staticClass: "drop-area",
                class: { startDrop: _vm.isStartDrop },
              },
              _vm._l(_vm.optionList[_vm.steps], function (item, index) {
                return _c("img", {
                  key: index + "rain",
                  staticClass: "drop-item",
                  style: {
                    bottom: Math.random() * 40 + "%",
                    left: Math.random() * 10 + 20 * (index + 1) + "%",
                  },
                  attrs: { src: item.image },
                  on: {
                    click: function ($event) {
                      return _vm.foundAnswer(item, index)
                    },
                  },
                })
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "answer-area" }, [
            _c(
              "div",
              { staticClass: "answer-box" },
              _vm._l(_vm.answerList[_vm.steps], function (item, index) {
                return _c("div", { key: index, staticClass: "answer-item" }, [
                  item.isShow
                    ? _c("span", { staticClass: "pinyin font-pinyin-medium" }, [
                        _vm._v(_vm._s(item.answerPy)),
                      ])
                    : _vm._e(),
                  item.isShow
                    ? _c("span", { staticClass: "font-hanzi-medium" }, [
                        _vm._v(_vm._s(item.answerZh)),
                      ])
                    : _vm._e(),
                ])
              }),
              0
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }