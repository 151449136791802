<template>
  <div
    class="course-begin-container"
    :style="{ backgroundImage: 'url(\'' + bgImg + '\')' }"
  >
    <div class="begin-end-box">
      <div class="customlesson_one">
        <div class="customlesson_one_img">
          <img src="@/assets/img/05-Panda/panda-title-background.svg" alt="" />
          <span
            class="lesson_one"
            @click.stop="activeClick"
            @mouseenter="hoverImg(2, 'hover')"
            @mouseleave="hoverImg(2, '')"
            :class="{ lessononehovertwo: lessononehovertwo }"
          >
            <AudioPlayer :isPlay="isStartPlaying" :title="title" :audioUrl="audioSrc" />
          </span>
          <div class="customlesson_num">
            <div class="customlesson_two">
              <span class="font-face pinyin font-pinyin-large"
                >{{ titlePinyin }}
              </span>
            </div>
            <div class="customlesson_three">
              <span id="face" class="txt-hz font-hanzi-large"
                >{{ title }}
              </span>
            </div>
            <div class="customlesson_four">
              <span class="txt-en englishFont font-english-large"
                >{{ titleEnglish }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AudioPlayer from '@/components/Course/GamePage/AudioPlayer'
import { templatePreviewMixin } from './templatePreviewMixin.js'

export default {
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          title: '',
          titlePinyin: '',
          titleEnglish: '',
          audioSrc: '',
          bgImg: ''
        }
      }
    }
  },
  components: {
    AudioPlayer
  },
  data() {
    return {
      lessononehover: false,
      lessononehovertwo: false,
      hoverFontNum: 0,
      songUrl: null,
      clickSocketInfo: {},
      isStartPlaying: null,
      title: '',
      titlePinyin: '',
      titleEnglish: '',
      audioSrc: '',
      bgImg: '',
      localData: {
        title: '你好! 我是冬冬 (上)',
        titlePinyin: 'Nǐ hǎo! Wǒ shì Dōngdōng (shàng)',
        titleEnglish: "Hello! I'm Dong Dong (I)",
        audioSrc: 'http://test.nihaokids.com/media/lesson-01-title.5e90373c.mp3',
        bgImg:
          'http://test.nihaokids.com/img/level-1-background-color.b624b516.svg'
      },
    }
  },
  watch: {
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20000,
          data: { value },
          text: '测试StartStop组件内点击事件socket'
        }
        this.$bus.$emit('kids_webSocket_sendInfo', data)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        // console.log(value, '这是watch的数据')
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  mounted() {
    this.$bus.$on('clickStartStop', () => {
      this.activeClick('socket', true)
    })
    this.$bus.$on('hoverStartStop', (val) => {
      console.log('hover', val)
      this.hoverImg(val, true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickStartStop')
    this.$bus.$off('hoverStartStop')
  },
  methods: {
    initData(){
      this.title = this.componentProps.title
      this.titlePinyin = this.componentProps.titlePinyin
      this.titleEnglish = this.componentProps.titleEnglish
      this.audioSrc = this.componentProps.audioSrc
      this.bgImg = this.componentProps.bgImg
    },
    hoverImg(id, isFromSocket = false) {
      if (!isFromSocket) {
        this.hoverFontNum++
      }
      this.hoverFontId = id
      switch (id) {
        case 1:
          this.lessononehover = !this.lessononehover
          break
        case 2:
          this.lessononehovertwo = !this.lessononehovertwo
          break
      }
    },
    activeClick(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickSocketInfo = {
          value: Math.random()
        }
      }
      this.isStartPlaying = Math.random()
    }
  }
}
</script>
<style scoped lang="scss">
.course-begin-container {
  width: 100%;
  height: 100%;
  border-radius: 56px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  -moz-background-size: 100% 100%;
  background-color: #ffdbb8;
  .begin-end-box {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 56px;
    background-color: rgba(41, 40, 36, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    .customlesson_one {
      position: absolute;
      bottom: 0;
      width: 65%;
      .customlesson_one_img {
        width: 100%;
        position: relative;
        img {
          width: 100%;
          display: block;
        }
        .lesson_one {
          position: absolute;
          top: -25px;
          right: 15%;
          width: 60px;
          height: 60px;
          background-color: #224e96;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          text-align: center;
          .volume-button {
            cursor: pointer;
          }
          img {
            width: 100%;
          }
        }
      }
      .lessononehovertwo {
        background-color: #1b4589 !important;
      }
      // .active {
      //   background-color: #1b4589;
      // }
      .customlesson_num {
        position: absolute;
        top: 15%;
        text-align: center;
        width: 100%;

        @media screen and (max-width: 1300px) {
          .font-hanzi-large {
            font-size: 36px;
            line-height: 2;
          }
        }

        .customlesson_two {
          cursor: default;
          font-family: 'STHeitiSCPinyin-light';

          .pinyin {
            @media screen and (max-width: 1500px) {
              // font-size: 1.5em;
            }
          }

          span {
            color: #224e96;
          }
        }
        .customlesson_three {
          cursor: default;
          width: 100%;
          span {
            color: #224e96;
          }
        }
        .customlesson_four {
          margin-top: 5%;
          cursor: default;
          span {
            color: #000000;
          }
          .englishFont {
            @media screen and (max-width: 1500px) {
              // font-size: 1.5em !important;
            }
          }
        }
      }
      .customlesson_num7 {
        position: absolute;
        top: 28%;
        text-align: center;
        width: 100%;
        .customlesson_two {
          cursor: default;
          font-family: 'STHeitiSCPinyin-light';

          .pinyin {
            @media screen and (max-width: 1500px) {
              // font-size: 1.5em;
            }
          }

          span {
            color: #224e96;
          }
        }
        .customlesson_three {
          cursor: default;
          width: 100%;
          span {
            color: #224e96;
          }
        }
        .customlesson_four {
          margin-top: 5%;
          cursor: default;
          span {
            color: #000000;
          }
          .englishFont {
            @media screen and (max-width: 1500px) {
              // font-size: 1.5em !important;
            }
          }
        }
      }
    }
  }
}
</style>
