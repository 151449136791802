<template>
  <div class="course-syllabus-container">
    <div class="syllabus-box">
      <img
        class="bg-img"
        src="@/assets/img/03-Backgrounds/background-syllabus.svg"
        alt=""
      />
      <div class="syllabus-content" ref="syllabusBox">
        <div
          class="syllabus-item"
          v-for="(item, listIndex) in wordList"
          :key="listIndex + 'i-can'"
        >
          <div class="title font-lesson-subtitles">
            {{ item.syllabusTitle }}
          </div>
          <div class="content-common">
            <div class="word-line">
              <div
                class="word-list"
                v-for="(s, sIndex) in item.syllabusContentList"
                :key="sIndex + 'syllabusContentList'"
              >
                <div
                  class="word-item"
                  v-for="(ele, index) in s.syllabusContent"
                  :key="index + '0'"
                >
                  <Word
                    :wordInfo="ele"
                    @hoverTHisWords="
                      getWordsId(
                        ele.id,
                        ele.sortNo,
                        listIndex,
                        sIndex,
                        undefined,
                        true
                      )
                    "
                    :pageType="1"
                    @notHoverTHisWords="
                      getWordsId(
                        ele.id,
                        ele.sortNo,
                        listIndex,
                        sIndex,
                        undefined,
                        false
                      )
                    "
                  />
                  <!-- @clickToPlayWords="handlePlay(listIndex, index, undefined)" -->
                </div>
                <div class="img-list">
                  <img
                    :src="ele.value"
                    alt=""
                    v-for="(ele, index) in s.syllabusImg"
                    :key="index + 'img'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Word from "./CourseWrod.vue";
import { templatePreviewMixin } from "./templatePreviewMixin.js";

export default {
  name: "CourseSyllabus",
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          syllabusList: [],
        };
      },
    },
  },
  components: {
    Word,
  },
  data() {
    return {
      wordList: [],
      isWordHover: true,
      distance: 0,
      contentHeight: null,
      syllabusBox: null,
      localData: {
        wordList: [
          {
            syllabusTitle: "我会认",
            isUse: false,
            syllabusContentList: [
              {
                syllabusContent: [
                  {
                    audiourl:
                      "https://static.nihaocafe.com/audio/words/1_1658818164299.mp3",
                    coursewareType: 1,
                    hskLevel: "HSK1",
                    id: 1,
                    sortNo: 1,
                    wordEn: "you",
                    wordPy: "nǐ",
                    wordZh: "你",
                  },
                  {
                    audiourl:
                      "https://static.nihaocafe.com/audio/words/109498_1658916060560.mp3",
                    coursewareType: 1,
                    hskLevel: "",
                    id: 109498,
                    sortNo: 2,
                    wordEn:
                      "special comma placed between nouns in a list; series (顿号, dùnhào)",
                    wordPy: "",
                    wordZh: "、",
                  },
                  {
                    audiourl:
                      "https://static.nihaocafe.com/audio/words/2_1596523164575.mp3",
                    coursewareType: 1,
                    hskLevel: "HSK4",
                    id: 2,
                    sortNo: 3,
                    wordEn: "good",
                    wordPy: "hǎo",
                    wordZh: "好",
                  },
                  {
                    audiourl:
                      "https://static.nihaocafe.com/audio/words/109498_1658916060560.mp3",
                    coursewareType: 1,
                    hskLevel: "",
                    id: 109498,
                    sortNo: 4,
                    wordEn:
                      "special comma placed between nouns in a list; series (顿号, dùnhào)",
                    wordPy: "",
                    wordZh: "、",
                  },
                  {
                    audiourl:
                      "https://static.nihaocafe.com/audio/words/4_1596523149078.mp3",
                    coursewareType: 1,
                    hskLevel: "HSK1",
                    id: 4,
                    sortNo: 5,
                    wordEn: "I; me",
                    wordPy: "wǒ",
                    wordZh: "我",
                  },
                  {
                    audiourl:
                      "https://static.nihaocafe.com/audio/words/109498_1658916060560.mp3",
                    coursewareType: 1,
                    hskLevel: "",
                    id: 109498,
                    sortNo: 6,
                    wordEn:
                      "special comma placed between nouns in a list; series (顿号, dùnhào)",
                    wordPy: "",
                    wordZh: "、",
                  },
                  {
                    audiourl: "https://static.nihaocafe.com/audio/words/6.mp3",
                    coursewareType: 1,
                    hskLevel: "HSK1",
                    id: 6,
                    sortNo: 7,
                    wordEn: "to be (am; is; are)",
                    wordPy: "shì",
                    wordZh: "是",
                  },
                ],
                syllabusImg: [
                  {
                    label: "img1",
                    value:
                      "http://test.nihaokids.com/img/lesson-02-shu.05c9f358.svg",
                  },
                ],
              },
            ],
          },
          {
            syllabusTitle: "我会用",
            isUse: true,
            syllabusContentList: [
              {
                syllabusContent: [
                  {
                    audiourl:
                      "https://static.nihaocafe.com/audio/words/2856.mp3",
                    coursewareType: 1,
                    hskLevel: "HSK2",
                    id: 2856,
                    sortNo: 1,
                    wordEn: "sentence",
                    wordPy: "jùzi",
                    wordZh: "句子",
                  },
                  {
                    audiourl:
                      "https://static.nihaocafe.com/audio/words/109497_1658915931569.mp3",
                    coursewareType: 1,
                    hskLevel: "",
                    id: 109497,
                    sortNo: 2,
                    wordEn: "colon (冒号, màohào)",
                    wordPy: "",
                    wordZh: "：",
                  },
                  {
                    audiourl:
                      "https://static.nihaocafe.com/audio/words/109499_1658915799205.mp3",
                    coursewareType: 1,
                    hskLevel: "",
                    id: 109499,
                    sortNo: 3,
                    wordEn: "quotation mark (引号, yǐnhào)",
                    wordPy: "",
                    wordZh: "“",
                  },
                  {
                    audiourl:
                      "https://static.nihaocafe.com/audio/words/1_1658818164299.mp3",
                    coursewareType: 1,
                    hskLevel: "HSK1",
                    id: 1,
                    sortNo: 4,
                    wordEn: "you",
                    wordPy: "nǐ",
                    wordZh: "你",
                  },
                  {
                    audiourl:
                      "https://static.nihaocafe.com/audio/words/2_1596523164575.mp3",
                    coursewareType: 1,
                    hskLevel: "HSK4",
                    id: 2,
                    sortNo: 5,
                    wordEn: "good",
                    wordPy: "hǎo",
                    wordZh: "好",
                  },
                  {
                    audiourl:
                      "https://static.nihaocafe.com/audio/words/109503_1658915907791.mp3",
                    coursewareType: 1,
                    hskLevel: "",
                    id: 109503,
                    sortNo: 6,
                    wordEn: "exclamation mark (感叹号, gǎntànhào)",
                    wordPy: "",
                    wordZh: "！",
                  },
                  {
                    audiourl:
                      "https://static.nihaocafe.com/audio/words/109500.mp3",
                    coursewareType: 1,
                    hskLevel: "",
                    id: 109500,
                    sortNo: 7,
                    wordEn: "quotation mark (引号, yǐnhào)",
                    wordPy: "",
                    wordZh: "”",
                  },
                  {
                    audiourl: "https://static.nihaocafe.com/audio/words/17.mp3",
                    coursewareType: 1,
                    hskLevel: "HSK1",
                    id: 17,
                    sortNo: 8,
                    wordEn: "(possessive particle)",
                    wordPy: "de",
                    wordZh: "的",
                  },
                  {
                    audiourl:
                      "https://static.nihaocafe.com/audio/words/455.mp3",
                    coursewareType: 1,
                    hskLevel: "HSK2",
                    id: 455,
                    sortNo: 9,
                    wordEn: "to use",
                    wordPy: "shǐyòng",
                    wordZh: "使用",
                  },
                ],
              },
            ],
          },
          {
            syllabusTitle: "我会说",
            isUse: false,
            syllabusContentList: [
              {
                syllabusContent: [
                  {
                    audiourl:
                      "https://static.nihaocafe.com/audio/words/1802.mp3",
                    coursewareType: 1,
                    hskLevel: "HSK1",
                    id: 1802,
                    sortNo: 1,
                    wordEn: "to use",
                    wordPy: "yòng",
                    wordZh: "用",
                  },
                  {
                    audiourl:
                      "https://static.nihaocafe.com/audio/words/2011.mp3",
                    coursewareType: 1,
                    hskLevel: "HSK1",
                    id: 2011,
                    sortNo: 2,
                    wordEn:
                      "Chinese language (synonym to 汉语 Hànyǔ, often interchangeable but a bit more colloquial)",
                    wordPy: "Zhōngwén",
                    wordZh: "中文",
                  },
                  {
                    audiourl:
                      "https://static.nihaocafe.com/audio/words/1769.mp3",
                    coursewareType: 1,
                    hskLevel: "HSK1",
                    id: 1769,
                    sortNo: 3,
                    wordEn: "and; with",
                    wordPy: "hé",
                    wordZh: "和",
                  },
                  {
                    audiourl:
                      "https://static.nihaocafe.com/audio/words/1958.mp3",
                    coursewareType: 1,
                    hskLevel: "HSK1",
                    id: 1958,
                    sortNo: 4,
                    wordEn: "other people; others",
                    wordPy: "biérén",
                    wordZh: "别人",
                  },
                  {
                    audiourl:
                      "https://static.nihaocafe.com/audio/words/1127.mp3",
                    coursewareType: 1,
                    hskLevel: "HSK4",
                    id: 1127,
                    sortNo: 5,
                    wordEn: "to greet; say hi",
                    wordPy: "dǎ zhāohū",
                    wordZh: "打招呼",
                  },
                  {
                    audiourl:
                      "https://static.nihaocafe.com/audio/words/3456.mp3",
                    coursewareType: 1,
                    hskLevel: "HSK4",
                    id: 3456,
                    sortNo: 6,
                    wordEn: "and also…",
                    wordPy: "bìng",
                    wordZh: "并",
                  },
                  {
                    audiourl:
                      "https://static.nihaocafe.com/audio/words/2514.mp3",
                    coursewareType: 1,
                    hskLevel: "HSK1",
                    id: 2514,
                    sortNo: 7,
                    wordEn: "to introduce; introduction",
                    wordPy: "jièshào",
                    wordZh: "介绍",
                  },
                  {
                    audiourl:
                      "https://static.nihaocafe.com/audio/words/2131.mp3",
                    coursewareType: 1,
                    hskLevel: "HSK2",
                    id: 2131,
                    sortNo: 8,
                    wordEn: "oneself; one’s own",
                    wordPy: "zìjǐ",
                    wordZh: "自己",
                  },
                ],
              },
            ],
          },
        ],
      },
    };
  },
  watch: {
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value;
        if (isTemplatePrview) return;
        this.initData();
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.syllabusBox.addEventListener("wheel", (e) => {
        const data = {
          clickType: 20017,
          data: {
            value: {
              deltaY: e.deltaY,
            },
          },
          text: "测试SyllabusPage组件内scroll事件socket",
        };
        this.$bus.$emit("kids_webSocket_sendInfo", data);
      });
    });

    this.$bus.$on("scrollMouseX", (e) => {
      this.$nextTick(() => {
        this.handleMouseScroll(e);
      });
    });
    this.contentHeight = this.$refs.syllabusBox.getBoundingClientRect().height;
    this.syllabusBox = this.$refs.syllabusBox;
  },
  methods: {
    initData() {
      this.wordList = this.componentProps.syllabusList.map((item, topIndex) => {
        return {
          syllabusTitle: item[0].value,
          syllabusContentList:
            item[1].value && item[1].value.length
              ? item[1].value.map((ele) => {
                  ele[0].options = ele[0].options || {};

                  // 循环 options 的每个键值对并添加 levelKey
                  Object.entries(ele[0].options).forEach(([key, value]) => {
                    ele[0].options[key] = {
                      ...value,
                      levelKey: topIndex, // 为每个属性添加 levelKey
                    };
                  });
                  return {
                    syllabusContent: ele[0].options,
                    syllabusImg: ele[1].value,
                  };
                })
              : [],
        };
      });
    },

    getWordsId(id, sortNo, listIndex, index, useIndex, isHover) {
      // 获取hover元素的id
      const data = {
        clickType: 20004,
        data: {
          value: {
            id,
            sortNo,
            listIndex,
            index,
            useIndex,
            isHover,
            isStudent: true,
          },
        },
        text: "测试SyllabusPagePage组件内hover事件socket",
      };
      this.$bus.$emit("kids_webSocket_sendInfo", data);
    },
    // 滚动实现
    handleMouseScroll(e) {
      const speed = e.deltaY > 0 ? 40 : -40;
      let dis;
      this.distance += speed;
      if (this.distance < 0) {
        dis = 0;
        this.distance = 0;
      } else if (this.distance > this.contentHeight) {
        dis = this.contentHeight;
        this.distance = this.contentHeight;
      } else {
        dis = this.distance;
      }
      // setTimeout(() => {
      this.syllabusBox.scrollTop = dis;
      // }, 50);
    },
  },
};
</script>
<style scoped lang="scss">
.txt-py {
  // font-family: "STHEITISCPINYIN-LIGHT";
  text-align: center;
  font-size: 1.1em !important;
  line-height: 30px !important;
}

.txt-hz {
  margin-bottom: 25px;
  font-size: 2em !important;
  margin-right: 8px;
}

.course-syllabus-container {
  width: 100%;
  height: 100%;
  background-color: #e0e9f7;
  border-radius: 56px;
  .syllabus-box {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    // overflow: scroll;
    justify-content: center;
    align-items: center;
    .bg-img {
      height: 90%;
      // padding: 5% 0;
    }

    .vowels-to-learn {
      position: absolute;
      left: 0%;
      top: 10%;
      height: 70%;
      z-index: 1001;
      right: 0;
      display: flex;
      justify-content: center;
      font-family: "SourceHanSans";

      .vowels-content {
        border-radius: 40px;
        color: #fff;
        padding: 6px 30px;
        width: fit-content;
        font-size: 27px;
        display: inline-block;
        height: fit-content;
        background: #cd4c3f;
      }
    }

    .syllabus-content {
      // background: cornflowerblue;
      position: absolute;
      left: 5%;
      top: 20%;
      width: 80%;
      height: 70%;
      z-index: 1001;
      overflow-y: scroll;

      .syllabus-item {
        display: flex;
        margin-bottom: 20px;

        .title {
          margin-top: 6px;
          height: 60px;
          background-color: #cd4c3f;
          border-radius: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 20px;
          color: #fff;
          font-size: 1.5em;
        }
        // .font-lesson-subtitles {
        //   font-family: "SourceHanSans";
        // }

        .content-common {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: center;
          &.has-multiple-lines {
            flex-direction: column;
          }
          .is-own-line {
            width: fit-content;
          }
          .img-list {
            height: fit-content;
            display: flex;
            gap: 20px;
            align-items: center;
            img {
              max-width: 48px;
              margin-top: 10px;
            }
          }
        }
        .word-line,
        .word-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          // width: 100%;
          align-items: center;
        }
      }
      // ::v-deep .hanzi {
      //   font-family: 'SourceHanSans';
      // }
    }

    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      display: none;
    }
  }
}
</style>
