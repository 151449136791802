<template>
  <div class="game-speaking-two-sentence-container">
    <div class="game-content">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="currentIndex"
        @changeTheNumbers="changeTheNumbers"
        :key="key"
      >
      </SceneSwitchThumbnails>
      <div class="title-area" v-if="titlePinYin || titleHanZi">
        <div class="title">
          <div
            class="title-pinyin pinyin font-pinyin-medium"
            v-if="titlePinYin"
          >
            {{ titlePinYin }}
          </div>
          <div class="title-hanzi font-hanzi-medium" v-if="titleHanZi">
            {{ titleHanZi }}
          </div>
        </div>
      </div>
      <div class="bgImg-area">
        <template v-for="(item, index) in gameList">
          <div
            class="img-box"
            :key="index"
            v-if="index + 1 === currentIndex"
            @click="handleClickImg(item)"
          >
            <transition name="el-fade-in">
              <img :src="item.isFirst ? item.firstImg : item.bgImg" alt="" />
            </transition>
          </div>
        </template>
      </div>

      <div class="bottom-sentence-area">
        <div
          class="hanzi-item"
          v-for="(item, index) in gameList"
          :key="index"
          v-show="
            item.isShowSentence && (item.isFirst ? item.firstHanzi : item.hanzi)
          "
        >
          <div class="pinyin sentence-hanzi font-pinyin-medium">
            {{ item.isFirst ? item.firstPinyin : item.pinyin }}
          </div>
          <div class="sentence-hanzi font-hanzi-medium">
            {{ item.isFirst ? item.firstHanzi : item.hanzi }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import SceneSwitchThumbnails from '../GameComponent/SceneSwitchThumbnails.vue'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    SceneSwitchThumbnails
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          titleHanZi: '',
          titlePinYin: '',
          speakingTwoSentenceList: []
        }
      }
    }
  },
  mounted() {
    // this.initData()
    this.$bus.$on('speakingSentenceMenu', ({ index }) => {
      this.changeTheNumbers(index, true)
    })
    this.$bus.$on('clickSpeakingSentenceImg', ({ item }) => {
      this.handleClickImg(item, true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('speakingSentenceMenu')
    this.$bus.$off('clickSpeakingSentenceImg')
  },
  data() {
    return {
      currentIndex: 1,
      gameList: [],
      isLastStep: false,
      lock: false,
      isSecondImage: false,
      buttonList: [],
      key: 0,
      clickMenuSocketInfo: {},
      clickImgSocketInfo: {},
      titlePinYin: '',
      titleHanZi: '',
      localData: {
        currentIndex: 1,
        gameList: [
          {
            titlePy: 'Shéi?',
            titleZh: '谁?',
            firstImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-17-1/image-0.svg`),
            bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-17-1/image-1.svg`),
            firstPinyin: 'Shéi?',
            firstHanzi: '谁?',
            pinyin: 'Dōngdōng',
            hanzi: '冬冬',
            isShowSentence: false,
            isFirst: true
          },
          {
            titlePy: 'Shéi?',
            titleZh: '谁?',
            firstImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-17-1/image-0.svg`),
            bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-17-1/image-2.svg`),
            firstPinyin: 'Shéi?',
            firstHanzi: '谁?',
            pinyin: 'Xiàxia',
            hanzi: '夏夏',
            isShowSentence: false,
            isFirst: true
          },
          {
            titlePy: 'Shéi?',
            titleZh: '谁?',
            firstImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-17-1/image-0.svg`),
            bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-17-1/image-3.svg`),
            firstPinyin: 'Shéi?',
            firstHanzi: '谁?',
            pinyin: 'Bàba',
            hanzi: '爸爸',
            isShowSentence: false,
            isFirst: true
          },
          {
            titlePy: 'Shéi?',
            titleZh: '谁?',
            firstImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-17-1/image-0.svg`),
            bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-17-1/image-4.svg`),
            firstPinyin: 'Shéi?',
            firstHanzi: '谁?',
            pinyin: 'Māma',
            hanzi: '妈妈',
            isShowSentence: false,
            isFirst: true
          }
        ],
        buttonList: [
          {
            image: require('@/assets/img/01-Menu/menu-1.svg'),
            startNumberRange: 1,
            endNumberRange: 1
          },
          {
            image: require('@/assets/img/01-Menu/menu-2.svg'),
            startNumberRange: 2,
            endNumberRange: 2
          },
          {
            image: require('@/assets/img/01-Menu/menu-3.svg'),
            startNumberRange: 3,
            endNumberRange: 3
          },
          {
            image: require('@/assets/img/01-Menu/menu-4.svg'),
            startNumberRange: 4,
            endNumberRange: 4
          }
        ]
      }
    }
  },
  methods: {
    initData() {},

    /**
     * @description: 初始化游戏
     * @return {*}
     */
    initGame() {
      this.gameList.forEach((item) => {
        item.isShowSentence = false
        item.isFirst = true
      })
    },
    /**
     * @description: 切换场景
     * @return {*}
     */
    changeTheNumbers(index, isFromSocket = false) {
      // console.log(index)
      this.initGame()
      this.lock = false
      this.currentIndex = index
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random()
        }
      }
    },
    /**
     * @description: 点击图片
     * @return {*}
     */
    handleClickImg(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickImgSocketInfo = {
          value: Math.random()
        }
      }
      if (this.lock) {
        this.gameList[this.currentIndex - 1].isFirst = false
      }
      this.gameList[this.currentIndex - 1].isShowSentence = true
      if (
        this.currentIndex === this.gameList.length &&
        this.gameList[this.currentIndex - 1].isShowSentence === true &&
        this.lock
      ) {
        this.isLastStep = true
        playCorrectSound()
        startConfetti()
      } else if (
        this.gameList[this.currentIndex - 1].isFirst ||
        this.isSecondImage
      ) {
        if (this.isSecondImage) {
          this.isSecondImage = false
        }
        playCorrectSound(true, false)
      }
      if (this.gameList[this.currentIndex - 1].isFirst) {
        this.isSecondImage = true
      }
      this.lock = true
    }
  },
  watch: {
    clickMenuSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004601,
          data: { value },
          text: 'SpeakingSentence点击Menu socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    clickImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004602,
          data: { value },
          text: 'SpeakingSentence点击Img socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(newVal, oldVal) {
        const { isTemplatePrview } = newVal
        if (isTemplatePrview) return
        this.initData()
        // console.log(newVal)
        this.titleHanZi = newVal.titleHanZi
        this.titlePinYin = newVal.titlePinYin
        this.gameList = newVal.speakingTwoSentenceList.map((item) => {
          return {
            // titlePy: item[0].value,
            // titleZh: item[1].value,
            bgImg: item[1].value !== '' ? item[1].value : item[0].value,
            pinyin: item[5].value,
            hanzi: item[4].value,
            firstImg: item[0].value
              ? item[0].value
              : item[1].value
              ? item[1].value
              : require('@/assets/img/FixedPicture/background-practice.svg'),
            firstPinyin: item[3].value,
            firstHanzi: item[2].value,
            isShowSentence: false,
            isFirst: true
          }
        })
        // console.log(this.gameList, 'gameList')

        this.buttonList = newVal.speakingTwoSentenceList.map((item, index) => {
          return {
            image: require('@/assets/img/FixedPicture/blue-white-question.svg'),
            startNumberRange: index + 1,
            endNumberRange: index + 1
          }
        })
        this.key = Date.now()
        // console.log(this.buttonList, 'ces')
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.game-speaking-two-sentence-container {
  width: 100%;
  height: 100%;
  line-height: 1;
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    .bgImg-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .img-box {
        cursor: pointer;
        width: 100%;
        height: 100%;
        img {
          border-radius: 58px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .title-area {
      z-index: 9;
      position: absolute;
      top: 0;
      left: 0;
      top: 10%;
      // width: 18%;
      // height: 17%;
      background: #cd4c3f;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      color: #fff;
      padding: 2% 5%;
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }
    }
    .bottom-sentence-area {
      position: absolute;
      width: 100%;
      height: 20%;
      bottom: 4%;
      display: flex;
      justify-content: center;
      align-items: center;
      .hanzi-item {
        // width: 50%;
        margin: 0 auto;
        background: #cd4c3f;
        position: absolute;
        padding: 1% 15%;
        color: #fff;
        border-radius: 40px;
        .sentence-hanzi,
        .sentence-pinyin {
          text-align: center;
        }
      }
    }
  }
}
</style>
