<template>
  <div class="game-recognition-container">
    <div class="recognition-content">
      <!-- <audio :src="songUrl" ref="audioBox"></audio> -->

      <div class="recogniton-page-content layout-red-bgc">
        <!-- 左边图片 left image -->
        <div class="recognition-content-left" @click="ImgClick()">
          <img
            v-if="isChange"
            class="santa-claus"
            :src="backgroundImage"
            alt=""
          />
          <img v-else class="santa-claus" :src="changeBgImg" alt="" />
        </div>

        <!-- 右边文字 right words-->
        <div class="recogniton-content-right">
          <div class="recognize_container_content_right_border">
            <div class="recognize_container_content_right_content">
              <div class="right-img">
                <img :src="rightImg" alt="" />
              </div>
              <div class="englishName">
                <p class="pinyin font-pinyin-medium">
                  {{ wordObj.pinyin }}
                </p>
                <p class="hanzi font-hanzi-medium">
                  {{ wordObj.hanzi }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Star from '@/components/Course/GamePage/Star'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    Star
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          bgImg: '',
          defaultImg: '',
          pinyin: '',
          hanzi: '',
          structureImg: ''
        }
      }
    }
  },
  data() {
    return {
      changeBgImg: '',
      wordObj: {},
      backgroundImage: '',
      rightImg: '',
      audioSocketInfo: {},
      clickImgSocketInfo: {},
      songUrl: null,
      isClickAudio: false,
      clickNum: 0,
      isHover: false,
      isChange: true,
      // clickedVolumePlay: 0,
      isStartPlaying: null,
      isLastStep: true,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'next',
            startNumberRange: 1,
            endNumberRange: 1,
            clickGoToNextPage: true
          }
        ]
      },
      localData: {
        changeBgImg: require('@/assets/img/16-GAMES/G68-structure-card/lesson-02-zuoyou-2.svg'),
        backgroundImage: require('@/assets/img/16-GAMES/G68-structure-card/lesson-02-zuoyou-1.svg'),
        rightImg: require('@/assets/img/16-GAMES/G68-structure-card/lesson-02-zuoyou.svg'),
        wordObj: {
          pinyin: 'zuǒyòu jiégòu',
          hanzi: '左右结构',
          songUrl: ''
        }
      }
    }
  },
  mounted() {
    // this.initData()
    this.$bus.$on('hoverRecognitionPage', (val) => {
      this.isClickAudio = !this.isClickAudio
    })
    this.$bus.$on('clickSpeakingSentenceImg', () => {
      this.ImgClick('socket', true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickRecognitionPage')
    this.$bus.$off('clickSpeakingSentenceImg')
  },
  watch: {
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20002,
          data: { value },
          text: '测试RecognitionPage组件内点击事件socket'
        }
        this.$bus.$emit('kids_webSocket_sendInfo', data)
      },
      deep: true
    },
    clickImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004602,
          data: { value },
          text: 'SpeakingSentence点击Img socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        this.initData()
      },
      deep: true
    }
  },
  methods: {
    initData() {
      // console.log(this.componentProps, '----')
      this.changeBgImg = this.componentProps.defaultImg
      this.wordObj = {
        pinyin: this.componentProps.pinyin,
        hanzi: this.componentProps.hanzi
      }
      this.backgroundImage = this.componentProps.bgImg
      this.rightImg = this.componentProps.structureImg
    },
    ImgClick(socket, isFromSocket = false) {
      if (!this.changeBgImg) return
      if (!isFromSocket) {
        this.clickImgSocketInfo = {
          value: Math.random()
        }
      }
      this.isChange = !this.isChange
      // console.log(this.isChange)
    }
  }
}
</script>

<style lang="scss" scoped>
.game-recognition-container {
  width: 100%;
  height: 100%;
  .recognition-content {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 58px;
    z-index: 2;
    position: relative;
    .recogniton-page-content {
      background: #f7f4e0;
      border-radius: 58px;
      position: relative;
      z-index: 3;

      .recognition-content-left {
        width: 50%;
        height: 100%;
        position: absolute;
        img {
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 20%;
          width: 80%;
        }
        .santa-claus {
          top: 15%;
        }
      }
      .recogniton-content-right {
        position: absolute;
        width: 50%;
        height: 100%;
        right: 0;
        .recognize_container_content_right_border {
          margin-top: 10px;
          margin-left: 10px;
          width: calc(70% - 10px);
          height: calc(60% - 10px);
          position: absolute;
          left: 8%;
          top: 13%;
          background: #224e96;
          border-radius: 40px;
          padding: 20px 10px;
          .recognize_container_content_right_content {
            color: #000;
            text-align: center;
            position: absolute;
            width: 100%;
            height: 100%;
            background: #fff;
            border-radius: 40px;
            top: -10px;
            left: -10px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            cursor: default;
            .right-img {
              padding: 25px;
              img {
                width: 100%;
              }
            }

            .englishName {
              max-width: 70%;
              p {
                color: #cd4c3f;
              }
            }
            .font-hanzi-large {
              font-size: 2.5em;
              margin-bottom: 30px;
            }
            .tone_content {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              width: 50%;
              p {
                width: 50%;
                margin-bottom: 8px;
              }
            }
          }
        }
      }
    }
    .layout-red-bgc {
        width: 100% !important;
        height: 100% !important;
        margin: 0 0 0 0 !important;
      }
  }
}
</style>
