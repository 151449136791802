var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-recognition-pinyin" }, [
    _c("div", { staticClass: "main-content" }, [
      _c(
        "div",
        { staticClass: "game-box" },
        [
          _c("Star", {
            attrs: {
              totalStarNumber: _vm.totalStarNum,
              currentIndex: _vm.starNum,
            },
          }),
          _c("div", { staticClass: "game-body" }, [
            _c("img", {
              staticClass: "bgImage",
              attrs: { src: _vm.imgFrogBg },
            }),
            _vm.optionList[_vm.currentIndex] &&
            _vm.optionList[_vm.currentIndex].image
              ? _c("img", {
                  staticClass: "fixedIamge",
                  class: {
                    fadeOut: _vm.isFadeOut,
                    fadeIn: _vm.isFadeIn,
                  },
                  attrs: { src: _vm.optionList[_vm.currentIndex].image },
                })
              : _vm._e(),
            _vm.isHaveAudio
              ? _c(
                  "div",
                  {
                    staticClass: "speaker-wrapper",
                    on: { click: _vm.playPinyinAudio },
                  },
                  [
                    _c("AudioPlayer", {
                      attrs: {
                        isPlay: _vm.isStartPlaying,
                        audioUrl: _vm.songUrl,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "bottonCont" }, [
            _c("div", { staticClass: "redBackground" }, [
              _c(
                "div",
                { staticClass: "bottonsWrapper" },
                _vm._l(_vm.selectImgList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "content-words-two",
                      on: {
                        click: function ($event) {
                          return _vm.foundAnswer(item.id)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "image-container" }, [
                        _c("img", { attrs: { src: _vm.imgFrog, alt: "" } }),
                        item.audioPy
                          ? _c("div", { staticClass: "centered-text pinyin" }, [
                              _vm._v(" " + _vm._s(item.audioPy) + " "),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }