var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "game-summary-page-container",
      style: { backgroundImage: "url('" + _vm.bgImg + "')" },
    },
    [
      _c("div", { staticClass: "game-summary-page-background" }, [
        _c("div", { staticClass: "summary-container" }, [
          _c(
            "div",
            { staticClass: "summary-title" },
            _vm._l(_vm.summaryList, function (item, index) {
              return _c("div", { key: item.id, staticClass: "title-box" }, [
                _c("p", { staticClass: "pinyin font-pinyin-large" }, [
                  _vm._v(_vm._s(item.pinyin)),
                ]),
                _c("p", { staticClass: "font-hanzi-large" }, [
                  _vm._v(_vm._s(item.hanzi)),
                ]),
              ])
            }),
            0
          ),
          _c("div", { staticClass: "summary-word-area" }, [
            _c("div", { staticClass: "word-item redBgColor" }, [
              _c("div", {
                staticClass: "word-img",
                style: { backgroundImage: "url('" + _vm.redSummaryImg + "')" },
              }),
              _c(
                "div",
                { staticClass: "summary-content" },
                _vm._l(_vm.redSummaryList, function (item, index) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "summary-name" },
                    [
                      _c("p", { staticClass: "pinyin font-pinyin-medium" }, [
                        _vm._v(" " + _vm._s(item.pinyin) + " "),
                      ]),
                      _c("p", { staticClass: "font-hanzi-medium" }, [
                        _vm._v(_vm._s(item.hanzi)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "word-item yellowBgColor" }, [
              _c("div", {
                staticClass: "word-img",
                style: {
                  backgroundImage: "url('" + _vm.yellowSummaryImg + "')",
                },
              }),
              _c(
                "div",
                { staticClass: "summary-content" },
                _vm._l(_vm.yellowSummaryList, function (item, index) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "summary-name fontBlue" },
                    [
                      _c("p", { staticClass: "pinyin font-pinyin-medium" }, [
                        _vm._v(" " + _vm._s(item.pinyin) + " "),
                      ]),
                      _c("p", { staticClass: "font-hanzi-medium" }, [
                        _vm._v(_vm._s(item.hanzi)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "word-item blueBgColor" }, [
              _c("div", {
                staticClass: "word-img",
                style: { backgroundImage: "url('" + _vm.blueSummaryImg + "')" },
              }),
              _c(
                "div",
                { staticClass: "summary-content" },
                _vm._l(_vm.blueSummaryList, function (item, index) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "summary-name" },
                    [
                      _c("p", { staticClass: "pinyin font-pinyin-medium" }, [
                        _vm._v(" " + _vm._s(item.pinyin) + " "),
                      ]),
                      _c("p", { staticClass: "font-hanzi-medium" }, [
                        _vm._v(_vm._s(item.hanzi)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }