<template>
  <div class="drag-stroke-to-img-container">
    <div class="dragStroke-content">
      <img
        src="@/assets/img/03-Backgrounds/background-practice.svg"
        alt=""
        class="bg-img"
      />
      <Star
        :totalStarNumber="chineseList && chineseList.length"
        :currentIndex="answersfound"
      />
      <div class="dragStroke-box">
        <div class="background-main">
          <div class="game-area">
            <img
              ref="imgBox"
              class="puzzle-bg-img"
              id="puzzlebackground"
              :src="bgImg"
              :style="{ width: imgWidth + 'px' }"
            />
            <div
              class="map-area"
              :style="{
                width: imgWidth + 1 + 'px',
                height: imgWidth + 1 + 'px'
              }"
            >
              <div class="map-box">
                <div
                  class="col-item"
                  v-for="(item, index) in mapList"
                  :key="index + 'col'"
                >
                  <div
                    class="row-item"
                    v-for="(ele, indexSon) in item"
                    :key="indexSon + 'row'"
                    :class="{
                      noPointerEvents: ele.isAddStyle
                    }"
                    @drop.stop="
                      dropToImg(
                        $event,
                        JSON.parse(JSON.stringify(ele.type)),
                        ele.id
                      )
                    "
                    @dragover.stop="allowDrop($event)"
                  ></div>
                </div>
              </div>

              <div class="hanzi-img-area">
                <template v-for="(item, index) in chineseList">
                  <transition name="el-fade-in" :key="index">
                    <img
                      :src="item.imageStartURL"
                      alt=""
                      :key="index"
                      v-if="item.isShow"
                    />
                  </transition>
                </template>

                <template v-for="(item, index) in chineseList">
                  <transition-group name="el-fade-in" :key="index">
                    <img
                      v-for="(ele, indexSon) in item.strokeImgList"
                      alt=""
                      :key="indexSon"
                      :src="ele.strokeImg"
                      v-show="ele.isShow"
                    />
                  </transition-group>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="stroke-list">
          <div class="stroke-box">
            <div
              v-for="(item, index) in strokeList"
              :key="index"
              class="stroke-item"
            >
              <img
                class="strokeImage"
                :src="item.image"
                draggable="true"
                @dragstart.stop="dragStroke($event, item.type)"
                @drop.stop="Sdrop($event)"
                @dragend="dragendOver"
                :class="{ notShow: strokeType === item.type }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import Star from '@/components/Course/GamePage/Star'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    Star
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          imgChooseList: [],
          puzzleList: [],
          bgImg: ''
        }
      }
    }
  },
  data() {
    return {
      chineseList: [

      ],
      strokeList: [

      ],
      mapList: [
        
      ],
      bgImg: '',
      answersfound: 0,
      strokeType: null,
      isLastStep: false,
      isShowStroke: false,
      dispearIndexList: [],
      currentId: 0,

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'look',
            startNumberRange: 1,
            endNumberRange: 100
          }
        ]
      },

      imgDom: null,
      imgWidth: null,
      dropToImgSocketInfo: {},
      dragStrokeSocketInfo: {},
      dragoverStrokeSocketInfo: {},

      currentHanziStrokeTypeList: [],
      localData: {
        chineseList: [
        {
          id: 1,
          imageStartURL:
            'http://test.nihaokids.com/img/character-1.522e11ac.svg',
          strokeImgList: [
            {
              strokeImg:
                'http://test.nihaokids.com/img/character-1-stroke.44106884.svg',
              type: 0,
              isShow: false
            }
          ],
          strokeSum: 0,
          isShow: true
        },
        {
          id: 2,
          imageStartURL:
            'http://test.nihaokids.com/img/character-2.586186df.svg',
          strokeImgList: [
            {
              strokeImg:
                'http://test.nihaokids.com/img/character-2-stroke.49ed8dc5.svg',
              type: 0,
              isShow: false
            }
          ],
          strokeSum: 0,
          isShow: true
        },
        {
          id: 3,
          imageStartURL:
            'http://test.nihaokids.com/img/character-3.2fcd2ca1.svg',
          strokeImgList: [
            {
              strokeImg:
                'http://test.nihaokids.com/img/character-3-stroke.9dedf6a9.svg',
              type: 1,
              isShow: false
            }
          ],
          strokeSum: 0,
          isShow: true
        },
        {
          id: 4,
          imageStartURL:
            'http://test.nihaokids.com/img/character-4.083f16e1.svg',
          strokeImgList: [
            {
              strokeImg:
                'http://test.nihaokids.com/img/character-4-stroke.d570341b.svg',
              type: 1,
              isShow: false
            }
          ],
          strokeSum: 0,
          isShow: true
        },
        {
          id: 5,
          imageStartURL:
            'http://test.nihaokids.com/img/character-5.75939eaa.svg',
          strokeImgList: [
            {
              type: 0,
              strokeImg:
                '	http://test.nihaokids.com/img/character-5-stroke.6549a32e.svg',
              isShow: false
            }
          ],
          strokeSum: 0,
          isShow: true
        },
        {
          id: 6,
          imageStartURL:
            'http://test.nihaokids.com/img/character-6.dbd1fc51.svg',
          strokeImgList: [
            {
              type: 1,
              strokeImg:
                'http://test.nihaokids.com/img/character-6-stroke.7a258a76.svg',
              isShow: false
            }
          ],
          strokeSum: 0,
          isShow: true
        }
      ],
      strokeList: [
        {
          type: 0,
          image: 'http://test.nihaokids.com/img/button-red.913b19f8.svg'
        },
        {
          type: 1,
          image: 'http://test.nihaokids.com/img/button-blue.a8d6f840.svg'
        }
      ],
      mapList: [
        [
          { index: 1, id: 1, type: [0] },
          { index: 4, id: null, type: [] },
          { index: 7, id: 5, type: [0] }
        ],
        [
          { index: 2, id: 2, type: [0] },
          { index: 5, id: 3, type: [1] },
          { index: 8, id: null, type: [] }
        ],
        [
          { index: 3, id: null, type: [] },
          { index: 6, id: 4, type: [1] },
          { index: 9, id: 6, type: [1] }
        ]
      ],
      bgImg: 'http://test.nihaokids.com/img/background-rabbits.27376d87.svg',
      }
    }
  },
  watch: {
    dropToImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004501,
          data: { value },
          text: 'DragStrokeToImgGame drag socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    dragStrokeSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004502,
          data: { value },
          text: 'DragStrokeToImgGame drop socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    dragoverStrokeSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004503,
          data: { value },
          text: 'DragStrokeToImgGame dropover socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        this.initData()
      },
      deep: true
    }
  },
  mounted() {
    setTimeout(() => {
      this.imgDom = this.$refs.imgBox
      this.imgWidth = parseInt(
        Math.ceil(this.imgDom.getBoundingClientRect().width)
      )

      this.imgDom.getBoundingClientRect().width = 55
    }, 50)
    this.$bus.$on('dropStrokeEvent', ({ type, id }) => {
      this.handleDragToImg(type, id, true)
    })
    this.$bus.$on('dragToImgEvent', ({ type }) => {
      this.dragStroke('socket', type, true)
    })
    this.$bus.$on('dragoverImgEvent', () => {
      this.dragendOver('socket', true)
    })
    window.addEventListener('resize', this.updateElementPosition)
  },
  destroyed() {
    window.removeEventListener('resize', this.updateElementPosition)
  },
  beforeDestroy() {
    this.$bus.$off('dropStrokeEvent')
    this.$bus.$off('dragToImgEvent')
    this.$bus.$off('dragoverImgEvent')
  },
  methods: {
    initData() {
      // console.log(this.componentProps, '0000')
      this.bgImg = this.componentProps.bgImg
      this.strokeList = this.componentProps.imgChooseList.map((item, index) => {
        return {
          type: index,
          image: item.value
        }
      })
      this.chineseList = []
      this.mapList = []
      const leftList = []
      const middleList = []
      const rightList = []
      if (
        this.componentProps.puzzleList &&
        this.componentProps.puzzleList.length
      ) {
        this.componentProps.puzzleList.forEach((item, index) => {
          if (item[3].value === 1) {
            leftList.push({
              index: index + 1,
              id: item[2].value ? index + 1 : null,
              type: item[2].value
                ? [
                    this.strokeList.find((ele) => ele.image === item[2].value)
                      .type
                  ]
                : []
            })
          }
          if (item[3].value === 2) {
            middleList.push({
              index: index + 1,
              id: item[2].value ? index + 1 : null,
              type: item[2].value
                ? [
                    this.strokeList.find((ele) => ele.image === item[2].value)
                      .type
                  ]
                : []
            })
          }
          if (item[3].value === 3) {
            rightList.push({
              index: index + 1,
              id: item[2].value ? index + 1 : null,
              type: item[2].value
                ? [
                    this.strokeList.find((ele) => ele.image === item[2].value)
                      .type
                  ]
                : []
            })
          }
          if (item[2].value) {
            this.chineseList.push({
              id: index + 1,
              imageStartURL: item[1].value,
              strokeImgList: [
                {
                  strokeImg: item[0].value,
                  type: this.strokeList.find(
                    (ele) => ele.image === item[2].value
                  ).type,
                  isShow: false
                }
              ],
              strokeSum: 0,
              isShow: true
            })
          }
        })
        this.mapList = [leftList, middleList, rightList]

        // console.log(leftList, middleList, rightList, this.chineseList)
      }
    },
    updateElementPosition() {
      this.imgDom = this.$refs.imgBox
      this.imgWidth = parseInt(
        Math.ceil(this.imgDom.getBoundingClientRect().width)
      )
    },
    dragendOver(event, isFromSocket = false) {
      // setTimeout(() => {}, 1500);
      if (!isFromSocket) {
        this.dragoverStrokeSocketInfo = {
          value: Math.random()
        }
      }
      this.strokeType = null
    },
    handleDragToImg(type, id, isFromSocket = false) {
      if (!isFromSocket) {
        this.dropToImgSocketInfo = {
          type,
          id,
          value: Math.random()
        }
      }
      // console.log(this.strokeType, type, id)

      if (type.indexOf(this.strokeType) > -1) {
        this.currentId = id
        playCorrectSound(true, false)
        // this.$emit('handleDropEvent', id, this.strokeType)
        this.chineseList.forEach((item) => {
        if (item.id === id) {
          item.strokeImgList.forEach((ele) => {
            if (ele.type === this.strokeType && !item.flag) {
              if (ele.isShow) return;
              ele.isShow = true;
              item.flag = true;
              // console.log(ele.isShow, "+++++++++++++++++++");
              item.strokeSum++;
              this.mapList.forEach((j) => {
                j.forEach((k) => {
                  if (k.id === id) {
                    // console.log(k.type);
                    const typeIndex = k.type.findIndex(
                      (value) => value === this.strokeType
                    );
                    k.type.splice(typeIndex, 1);
                    if (!k.type.length) {
                      k.isAddStyle = true;
                      setTimeout(() => {
                        item.isShow = false;
                      }, 1500);
                      setTimeout(() => {
                        this.answersfound++;
                        if (this.answersfound === this.chineseList.length) {
                          startConfetti();
                          playCorrectSound();
                        }
                        item.strokeImgList.forEach((obj) => {
                          obj.isShow = false;
                        });
                      }, 3000);
                    }
                  }
                });
              });
            }
          });
          item.flag = false;
        }
      });
      } else {
        playCorrectSound(false)
      }
    },
    dragStroke(event, type, isFromSocket = false) {
      if (!isFromSocket) {
        this.dragStrokeSocketInfo = {
          type,
          value: Math.random()
        }
      }

      this.strokeType = type
      // console.log(type)
    },
    dropToImg(event, type, id) {
      this.handleDragToImg(type, id)
      event.preventDefault()
    },
    Sdrop(event) {
      event.preventDefault()
    },
    allowDrop(event) {
      event.preventDefault()
    }
  }
}
</script>

<style lang="scss" scoped>
.drag-stroke-to-img-container {
  width: 100%;
  height: 100%;
  .dragStroke-content {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .bg-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 58px;
    }
    .dragStroke-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      .background-main {
        flex: 6;
        display: flex;
        justify-content: center;
        align-items: center;
        .game-area {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          .puzzle-bg-img {
            width: 70%;
            min-width: 400px;
          }
          .map-area {
            position: absolute;
            margin: auto;
            display: flex;
            top: 0;

            .map-box {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              .col-item {
                z-index: 100;
                flex: 1;
                display: flex;
                flex-direction: column;
                .row-item {
                  flex: 1;
                }
                .noPointerEvents {
                  pointer-events: none;
                }
              }
            }

            .hanzi-img-area {
              width: 100%;
              height: 100%;
              img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
              }
            }
          }
        }
      }
      .stroke-list {
        flex: 3;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .stroke-box {
          height: 50%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .strokeImage {
            cursor: pointer;
            display: inline-block;
            width: 80%;
            -webkit-user-drag: auto;
          }
          .notShow {
            opacity: 0;
          }
        }
      }
    }
  }
}
</style>
