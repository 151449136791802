var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-recognition-container" }, [
    _c(
      "div",
      { staticClass: "game-recognition-content" },
      [
        _c("div", { staticClass: "game-background" }, [
          _c("div", { staticClass: "backgroundImg_n_DragArea" }, [
            _c("div", { staticClass: "imageWrapper" }, [
              _vm.ObjectList &&
              _vm.ObjectList[_vm.question] &&
              Object.keys(_vm.ObjectList[_vm.question]).length &&
              _vm.ObjectList[_vm.question].image
                ? _c("img", {
                    class: {
                      objectDisplay:
                        _vm.ObjectList[_vm.question].imgShape === 1,
                      objectCircle: _vm.ObjectList[_vm.question].imgShape === 2,
                    },
                    attrs: { src: _vm.ObjectList[_vm.question].image },
                  })
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "bottonsWrapper" },
              _vm._l(_vm.chineseList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "content-words-two",
                    on: {
                      click: function ($event) {
                        return _vm.foundAnswer(index)
                      },
                      mouseenter: function ($event) {
                        _vm.getWordsId(index, (_vm.isHover = true))
                      },
                      mouseleave: function ($event) {
                        _vm.getWordsId(index, (_vm.isHover = false))
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "font-pinyin-medium pinyin" }, [
                      _vm._v(_vm._s(item.pinying)),
                    ]),
                    _c("span", { staticClass: "font-hanzi-medium hanzi" }, [
                      _vm._v(_vm._s(item.hanzi)),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _c(
            "div",
            { staticClass: "strokesFoundBoxes" },
            _vm._l(_vm.chineseList, function (item, index) {
              return _c(
                "div",
                { key: index + "answer", staticClass: "outerBox" },
                [
                  _vm.answersfound > index
                    ? _c("img", {
                        staticClass: "strokesFoundimage",
                        attrs: { src: _vm.strockFoundImage },
                      })
                    : _c("img", {
                        staticClass: "strokesFoundimage",
                        attrs: { src: _vm.strockNotFoundImage },
                      }),
                ]
              )
            }),
            0
          ),
        ]),
        _vm.chineseList && _vm.chineseList.length
          ? _c("CommonButton", {
              on: { shuffleClickEvent: _vm.handleShuffleClickEvent },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }