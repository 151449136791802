var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "course-word-card-container" }, [
    _c("div", { staticClass: "red-background-border" }),
    _c("div", { staticClass: "recogniton-page-box" }, [
      _c("audio", { ref: "audioBox", attrs: { src: _vm.wrodAudio } }),
      _c("div", { staticClass: "recogniton-page-content layout-red-bgc" }, [
        _c("div", { staticClass: "recognition-content-left" }, [
          _c("img", {
            staticClass: "santa-claus",
            attrs: { src: _vm.wrodImg, alt: "" },
          }),
        ]),
        _c("div", { staticClass: "recogniton-content-right" }, [
          _c(
            "div",
            { staticClass: "recognize_container_content_right_border" },
            [
              _c(
                "div",
                { staticClass: "recognize_container_content_right_content" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "recognize_container_content_right_content_top volume-button",
                      class: { "teacher-hover": _vm.isClickAudio },
                    },
                    [
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.activeClick("socket")
                            },
                          },
                        },
                        [
                          _c("AudioPlayer", {
                            attrs: {
                              isPlay: _vm.isStartPlaying,
                              audioUrl: _vm.wrodAudio,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm.alphabets
                    ? _c("section", [
                        _c(
                          "div",
                          { staticClass: "alphabet-content" },
                          _vm._l(_vm.alphabets, function (item, index) {
                            return _c(
                              "p",
                              {
                                key: index,
                                staticClass: "pinyin font-hanzi-xlarge",
                              },
                              [_vm._v(" " + _vm._s(item) + " ")]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }