<template>
  <div class="game-pick-words-by-pic-container">
    <!-- <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
      @updateNumbers="updateNumbers($event)"
    ></PageButton> -->
    <!-- <Star :totalStarNumber="4" :currentIndex="currentIndex" /> -->
    <SceneSwitchThumbnails
      :buttons="buttonList"
      :numBers="currentIndex"
      @changeTheNumbers="changeTheNumbers"
      :key="key"
    />
    <div class="bg-img">
      <img src="@/assets/img/16-GAMES/G22-drag-abc/background.svg" alt="" />
    </div>
    <div class="pinyin-puzzle-game-box">
      <div class="pinyin-puzzle-game-content">
        <div
          class="drop-area"
          @drop.stop="drop()"
          @dragover.stop="allowDrop($event)"
        />
        <div class="top-area">
          <div class="play-audio" @click.stop="playAudio">
            <!-- 播放音频 -->
            <div>
              <AudioPlayer :isPlay="isStartPlaying" :audioUrl="songUrl" />
            </div>
          </div>
          <div class="puzzle-area" v-if="answerList[currentIndex].type === 1">
            <!-- <img
              class="part1"
              :src="answerList[currentIndex].firstImg"
              :class="{ notShow: !answerList[currentIndex].isCorrect }"
            /> -->
            <div class="part1">
              <img :src="require('@/assets/img/FixedPicture/puzzle-white.svg')" alt=""  :class="{ notShow: !answerList[currentIndex].isCorrect }">
              <span class="pinyin-puzzle" :class="{ notShow: !answerList[currentIndex].isCorrect }">{{ answerList[currentIndex].firstPy }}</span>
            </div>
            <div class="part2">
              <img :src="require('@/assets/img/FixedPicture/puzzle-white.svg')" alt="">
              <span class="pinyin-puzzle">{{ answerList[currentIndex].secondPy }}</span>
            </div>
            <!-- <img class="part2" :src="answerList[currentIndex].secondImg" /> -->
          </div>

          <div class="puzzle-area" v-else>
            <!-- <img class="part1" :src="answerList[currentIndex].firstImg" /> -->
             <div class="part1">
              <img :src="require('@/assets/img/FixedPicture/puzzle-white.svg')" alt="">
              <span class="pinyin-puzzle">{{ answerList[currentIndex].secondPy }}</span>
             </div>
             <div class="part2" v-show="answerList[currentIndex].isCorrect">
              <img :src="require('@/assets/img/FixedPicture/puzzle-white.svg')" alt="">
              <span class="pinyin-puzzle">{{ answerList[currentIndex].firstPy }}</span>
             </div>
            <!-- <img
              v-show="answerList[currentIndex].isCorrect"
              class="part2"
              :src="answerList[currentIndex].secondImg"
            /> -->
          </div>
        </div>
        <img class="objectDisplay" :src="answerList[currentIndex].fullImg" />
      </div>
    </div>

    <div class="option-area">
      <div
        class="puzzle-item"
        v-for="(item, index) in optionList[currentIndex]"
        :key="index"
        @dragstart="startDrag(item.image)"
        @drop.stop="Sdrop($event)"
        draggable="true"
        v-show="item.showObj == true"
      >
        <img
          class="puzzleImage"
          :src="require('@/assets/img/FixedPicture/puzzle-red.svg')"
        />
        <span>{{ item.image }}</span>
      </div>
      <img class="options-background" :src="objectListBackground" />
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'

// import Star from "../Star";
import PageButton from '@/components/Course/Interaction/Button/pageButton.vue'
import AudioPlayer from '@/components/Course/GamePage/AudioPlayer'
import SceneSwitchThumbnails from '../GameComponent/SceneSwitchThumbnails.vue'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    PageButton,
    // Star,
    AudioPlayer,
    SceneSwitchThumbnails
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          pickWordsByPicList: []
        }
      }
    }
  },
  data() {
    return {
      key: 0,
      optionList: [],
      answerList: [],
      buttonList: [],
      audioSocketInfo: {},
      dragSocketInfo: {},
      dropSocketInfo: {},
      clickMenuSocketInfo: {},

      isLastStep: false,

      volumeImgSrc: require('@/assets/img/06-Sound/sound-white.svg'),

      currentIndex: 0,
      currentDragPinyin: '0',

      objectListBackground: require('@/assets/img/16-GAMES/G22-drag-abc/shape.svg'),
      objectplate: require('@/assets/img/16-GAMES/G22-drag-abc/empty-plate.svg'),
      // objectDisplay: '',

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'look',
            startNumberRange: 0,
            endNumberRange: 0
          }
        ]
      },
      isStartPlaying: null,
      songUrl: null,
      imgList: [
        {
          id: 1,
          image: require('@/assets/img/FixedPicture/blue-green.svg')
        },
        {
          id: 2,
          image: require('@/assets/img/FixedPicture/blue-white.svg')
        },
        {
          id: 3,
          image: require('@/assets/img/FixedPicture/blue-yellow.svg')
        }
      ],
      //   buttonList: [
      //     {
      //       image: require('@/assets/img/01-Menu/menu-puzzle-1.svg'),
      //       startNumberRange: 0,
      //       endNumberRange: 0
      //     },
      //     {
      //       image: require('@/assets/img/01-Menu/menu-puzzle-2.svg'),
      //       startNumberRange: 1,
      //       endNumberRange: 1
      //     },
      //     {
      //       image: require('@/assets/img/01-Menu/menu-puzzle-3.svg'),
      //       startNumberRange: 2,
      //       endNumberRange: 2
      //     },
      //     {
      //       image: require('@/assets/img/01-Menu/menu-puzzle-1.svg'),
      //       startNumberRange: 3,
      //       endNumberRange: 3
      //     }
      //   ]
      localData: {
        optionList: [
          [
            {
              id: 1,
              image: 'b',
              showObj: true
            },
            {
              id: 2,
              image: 'p',
              showObj: true
            },
            {
              id: 3,
              image: 'm',
              showObj: true
            },
            {
              id: 4,
              image: 'f',
              showObj: true
            }
          ],
          [
            {
              id: 1,
              image: 'b',
              showObj: true
            },
            {
              id: 2,
              image: 'p',
              showObj: true
            },
            {
              id: 3,
              image: 'm',
              showObj: true
            },
            {
              id: 4,
              image: 'f',
              showObj: true
            }
          ],
          [
            {
              id: 1,
              image: 'b',
              showObj: true
            },
            {
              id: 2,
              image: 'p',
              showObj: true
            },
            {
              id: 3,
              image: 'm',
              showObj: true
            },
            {
              id: 4,
              image: 'f',
              showObj: true
            }
          ],
          [
            {
              id: 1,
              image: 'b',
              showObj: true
            },
            {
              id: 2,
              image: 'p',
              showObj: true
            },
            {
              id: 3,
              image: 'm',
              showObj: true
            },
            {
              id: 4,
              image: 'f',
              showObj: true
            }
          ]
        ],

        answerList: [
          {
            id: 1,
            type: 1,
            audioUrl: '',
            isCorrect: false,
            firstPy: 'b',
            secondPy: 'a',
            fullImg: require('@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-character-ba.svg')
          },
          {
            id: 3,
            type: 1,
            audioUrl: '',
            isCorrect: false,
            firstPy: 'm',
            secondPy: 'a',
            fullImg: require('@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-character-ma.svg')
          },
          {
            id: 2,
            type: 1,
            audioUrl: '',
            isCorrect: false,
            firstPy: 'p',
            secondPy: 'a',
            fullImg: require('@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-character-pa.svg')
          },
          {
            id: 4,
            type: 1,

            audioUrl: '',
            isCorrect: false,
            firstPy: 'f',
            secondPy: 'u',
            fullImg: require('@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-character-fu.svg')
          }
        ],
        buttonList: [
          {
            image: require('@/assets/img/FixedPicture/blue-green.svg'),
            startNumberRange: 0,
            endNumberRange: 0
          },
          {
            image:  require('@/assets/img/FixedPicture/blue-white.svg'),
            startNumberRange: 1,
            endNumberRange: 1
          },
          {
            image:  require('@/assets/img/FixedPicture/blue-yellow.svg'),
            startNumberRange: 2,
            endNumberRange: 2
          },
          {
            image:  require('@/assets/img/FixedPicture/blue-green.svg'),
            startNumberRange: 3,
            endNumberRange: 3
          }
        ]
      }
    }
  },
  mounted() {
    this.$bus.$on('puzzleGameDrag', ({ pinyin }) => {
      this.startDrag(pinyin, true)
    })
    this.$bus.$on('puzzleGameDrop', () => {
      this.drop('socket', true)
    })
    this.$bus.$on('puzzleGameAudio', () => {
      this.playAudio('socket', true)
    })
    this.$bus.$on('puzzleGameClickMenu', ({ index }) => {
      this.changeTheNumbers(index, true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('puzzleGameDrag')
    this.$bus.$off('puzzleGameDrop')
    this.$bus.$off('puzzleGameAudio')
    this.$bus.$off('puzzleGameClickMenu')
  },
  watch: {
    componentProps: {
      handler(newVal) {
        const { isTemplatePrview } = newVal
        if (isTemplatePrview) return
        this.initData()
        console.log(newVal, 'newVal')

        this.answerList = newVal.pickWordsByPicList.map((subArray, index) => {
          return {
            fullImg: subArray[0].value || '',
            id: index + 1,
            firstPy: subArray[5].value || '',
            secondPy: subArray[1].value || '',
            audioUrl: subArray[3].value || '',
            isCorrect: false,
            type: subArray[2].value || '',
          }
        })
        this.optionList = newVal.pickWordsByPicList.map(function (subArray) {
          // const options = [
          //   { image: subArray[6].value || '', showObj: true, id: 1 },
          //   { image: subArray[7].value || '', showObj: true, id: 2 },
          //   { image: subArray[8].value || '', showObj: true, id: 3 },
          //   { image: subArray[9].value || '', showObj: true, id: 4 }
          // ]
          const optionList = []
          if (subArray[4].value && subArray[4].value.length) {
            subArray[4].value.forEach((ele, eleIndex) => {
              optionList.push({
                id: eleIndex + 1,
                showObj: true,
                image: ele
              })
            })
          }
          return optionList
        })
        let startNumber = 0
        this.imgList
        this.buttonList = newVal.pickWordsByPicList.map((subArray, index) => {
          const list = {
            // image: subArray[5].value || '',
            image: this.imgList[index % this.imgList.length].image,
            startNumberRange: startNumber,
            endNumberRange: startNumber
          }
          startNumber++
          return list
        })
        console.log(this.buttonList, '000')

        this.key = Date.now()
      },
      deep: true,
      immediate: true
    },
    dragSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003601,
          data: { value },
          text: 'puzzlePinyinGame drag socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    dropSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003602,
          data: { value },
          text: 'puzzlePinyinGame drop socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003603,
          data: { value },
          text: 'puzzlePinyinGame audio socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    clickMenuSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003604,
          data: { value },
          text: 'puzzlePinyinGame menu click socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    }
  },
  methods: {
    initData() {},
    changeTheNumbers(index, isFromSocket = false) {
      // console.log(index);
      this.currentIndex = index
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random()
        }
      }
    },
    /**
     * @description: 根据拖拽的图片判断是否正确
     * @return {*}
     */
    foundAnswer() {
      if (this.answerList[this.currentIndex].firstPy == this.currentDragPinyin) {
        // this.objectDisplay = this.optionList[this.currentIndex].image
        // console.log(this.currentIndex,this.optionList[this.currentIndex]);
        
        this.optionList[this.currentIndex].showObj = false
        this.answerList[this.currentIndex].isCorrect = true
        playCorrectSound(true, false)
        if (this.currentIndex === this.answerList.length - 1) {
          this.isLastStep = true
          playCorrectSound()
          startConfetti()
        }
      } else {
        playCorrectSound(false)
      }
    },

    nextPage() {
      this.$bus.$emit('nextButton', true)
    },

    playAudio(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.audioSocketInfo = {
          value: Math.random()
        }
      }
      this.isStartPlaying = Math.random()
      this.songUrl = this.answerList[this.currentIndex].audioUrl
    },
    startDrag(pinyin, isFromSocket = false) {
      // console.log(pinyin,'99');
      
      if (!isFromSocket) {
        this.dragSocketInfo = {
          pinyin,
          value: Math.random()
        }
      }
      this.currentDragPinyin = pinyin
      console.log(this.currentDragPinyin)
    },
    drop(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.dropSocketInfo = {
          value: Math.random()
        }
      }
      this.foundAnswer()
      this.currentDragPinyin = ''
      //   event.preventDefault();
    },
    Sdrop(event) {
      event.preventDefault()
    },
    allowDrop(event) {
      event.preventDefault()
    }
  }
}
</script>
<style lang="scss" scoped>
.game-pick-words-by-pic-container {
  width: 100%;
  height: 100%;
  background: #fff;
}
.puzzle-area {
  width: 60%;
  height: 80%;
  display: flex;
  align-self: center;
  margin-left: 10%;

  .part1,.part2 {
    height: 100%;
    width: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
    .pinyin-puzzle {
      color: #cd4c3f;
      font-size: 30px;
      font-weight: 600;
      z-index: 2;
      position: relative;
      text-align: center;
    }
  }
  .part2 {
    margin-left: -10px;
  }
  .notShow {
    opacity: 0;
  }
}
.objectDisplay {
  position: absolute;
  width: 80%;
  bottom: 10%;
  left: 10%;
}
.plate {
  bottom: 10%;
  position: absolute;
  width: 60%;
  left: 20%;
}
.top-area {
  width: 100%;
  height: 35%;
  left: 0;
  position: absolute;
  background: #224e96;
  text-align: center;
  color: #fff;
  display: flex;
  .pinyin {
    margin-top: 20px;
    font-family: 'STHeitiSCPinyin-Light';
  }
}
.options-background {
  position: absolute;
  height: 100%;
}
.option-area {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 15%;
  height: 70%;
  top: 15%;
  left: 10%;
  align-items: center;
  justify-content: space-around;
  .puzzleImage {
    flex: 1;
    display: inline-block;
    width: 50%;
    -webkit-user-drag: auto;
    z-index: 10;
    cursor: pointer;
  }
}
.puzzle-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    // width: 50%;
  }
  span {
    color: #fff;
    position: relative;
    // left: 43%;
    z-index: 11;
    // top: 28%;
    font-size: 20px;
  }
}
.game-pick-words-by-pic-container {
  //  background: #F7F4E0;

  border-radius: 48px;
  position: relative;
  overflow: hidden;
  position: relative;
  .bg-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    img {
      border-radius: 56px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.pinyin-puzzle-game-box {
  text-align: -webkit-center;
  position: absolute;
  height: 70%;
  background: #f7f4e0;
  border-radius: 25px;
  top: 15%;
  width: 45%;
  display: flex;
  flex-direction: row;
  left: 30%;
  margin: auto;
  overflow: hidden;
  border-bottom: 10px solid #b5392c;
  .pinyin-puzzle-game-content {
    position: relative;
    width: -moz-fit-content;
    /* background-color: black; */
    width: 100%;
    height: 100%;
    /* left: 10%; */
    border-radius: 10%;
    display: flex;
  }
}

.play-audio {
  width: 8vh;
  height: 8vh;
  align-self: center;
  background: #ce4c3f;
  border-radius: 50%;
  padding: 0;
  margin-left: 10%;
  z-index: 100;
  cursor: pointer;

  div {
    width: 100%;
    height: 100%;
  }
  img {
    border-radius: 50%;
    background: #ce4c3f;
    width: 100%;
  }
}
.play-audio:hover {
  width: 8vh;
  height: 8vh;
  background: #b5392c;
  border-radius: 50%;
  // padding: 17px;
  img {
    border-radius: 50%;
    background: #b5392c;
    width: 100%;
  }
}
.drop-area {
  display: block;
  position: absolute;
  width: 100%;
  height: 40%;
  top: 0;
  left: 0;
  z-index: 99;
  &:hover {
    cursor: pointer;
  }
}
</style>
