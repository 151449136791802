<template>
  <div class="game-find-structure-container">
    <div class="game-structure-content">
      <Star :totalStarNumber="totalStars" :currentIndex="answersfound" />
      <div class="game-box">
        <div class="game-main-content">
          <div class="game-left-box">
            <div class="game-aside-content">
              <div
                v-for="item in leftList"
                :key="item.id"
                class="item-content"
                @click="foundAnswer(item.id)"
              >
                <!-- <span class="font-pinyin-medium pinyin" v-if="item.pinyin">{{
                  item.pinyin
                }}</span>
                <span class="font-hanzi-medium hanzi">{{ item.element }}</span> -->
                <img :src="item.image" />
              </div>
            </div>
          </div>

          <div
            class="game-center-box"
            v-for="(item, index) in questionInfoList"
            :key="index + 'question'"
            v-show="question === index"
          >
            <!-- 中间的背景图 -->
            <!-- <span class="font-pinyin-large pinyin">{{ item.pinyin }}</span> -->
            <!-- <img
              v-if="isShowImg"
              class="objectDisplay fadeIn"
              :src="item.bgImg2"
            /> -->
            <img class="objectDisplay fadeIn" :src="item.bgImg" />
            <!-- 中间的数字和pinyin -->
            <div class="number-area"></div>
            <!-- <div class="speaker-wrapper" @click="playSoundWord"> -->
            <!-- <img :src="volumeImgSrc" alt="" class="speaker-img" /> -->
            <!-- <AudioPlayer :isPlay="isStartPlaying" :audioUrl="songUrl" :showAudioElement="index > 0 ? false : true" /> -->
            <!-- </div> -->
          </div>

          <div class="game-right-box">
            <div class="game-aside-content">
              <div
                v-for="item in rightList"
                :key="item.id"
                class="item-content"
                @click="foundAnswer(item.id)"
              >
                <!-- <span class="font-pinyin-medium pinyin" v-if="item.pinyin">{{
                  item.pinyin
                }}</span>
                <span class="font-hanzi-medium">{{ item.element }}</span> -->
                <img :src="item.image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 失败和成功背景 -->
    <div
      class="checkGif"
      :class="{ checkGifWrong: !isShowImg }"
      v-checkgif="isShowImg"
      v-if="isShowCheckGif"
    ></div>
  </div>
</template>

<script>
import Star from '@/components/Course/GamePage/Star'
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    Star
  },
  mounted() {
    // this.initData()
    this.$bus.$on('chooseElementToCheck', ({ index }) => {
      this.foundAnswer(index, true)
    })
    this.$bus.$on('chooseElementAudio', () => {
      this.playSoundWord('socket', true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('chooseElementToCheck')
    this.$bus.$off('chooseElementAudio')
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          structurePicList: []
        }
      }
    }
  },
  watch: {
    gameSocket: {
      handler(value) {
        const data = {
          clickType: 20027,
          data: { value },
          text: 'NumberClickGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002701,
          data: { value },
          text: 'NumberClickGame点击喇叭socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  data() {
    return {
      localData: {
        totalStars: 6,
        leftList: [
          {
            id: 1,
            image: require('@/assets/img/16-GAMES/G74-structure-puzzle/1-shangxia.svg')
          },
          {
            id: 2,
            image: require('@/assets/img/16-GAMES/G74-structure-puzzle/2-zuoyou.svg')
          },
          {
            id: 3,
            image: require('@/assets/img/16-GAMES/G74-structure-puzzle/3-quanbao.svg')
          },
          {
            id: 4,
            image: require('@/assets/img/16-GAMES/G74-structure-puzzle/4-duti.svg')
          }
        ],
        rightList: [
          {
            id: 5,
            image: require('@/assets/img/16-GAMES/G74-structure-puzzle/5-zuoshang.svg')
          },
          {
            id: 6,
            image: require('@/assets/img/16-GAMES/G74-structure-puzzle/6-zuoxia.svg')
          },
          {
            id: 7,
            image: require('@/assets/img/16-GAMES/G74-structure-puzzle/7-zuosan.svg')
          },
          {
            id: 8,
            image: require('@/assets/img/16-GAMES/G74-structure-puzzle/8-xiasan.svg')
          }
        ],
        questionInfoList: [
          {
            id: 2,
            bgImg: require('@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-1-mei-1.svg'),
            bgImg2: require('@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-1-mei-2.svg'),
            sound: ''
          },
          {
            id: 2,
            bgImg: require('@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-2-mao-1.svg'),
            bgImg2: require('@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-1-mei-2.svg'),
            sound: ''
          },
          {
            id: 2,
            bgImg: require('@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-3-mei-1.svg'),
            bgImg2: require('@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-2-mao-2.svg'),
            sound: ''
          },
          {
            id: 2,
            bgImg: require('@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-4-ta-1.svg'),
            bgImg2: require('@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-3-mei-2.svg'),
            sound: ''
          },
          {
            id: 2,
            bgImg: require('@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-5-na-1.svg'),
            bgImg2: require('@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-4-ta-2.svg'),
            sound: ''
          },
          {
            id: 2,
            bgImg: require('@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-5-na-1.svg'),
            bgImg2: require('@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-5-na-2.svg'),
            sound: ''
          }
        ]
      },
      totalStars: null,
      leftList: [],
      rightList: [],
      questionInfoList: [],
      isShowImg: false,
      gameSocket: {},
      audioSocketInfo: {},
      isLastStep: false,
      answersfound: 0,
      draggedStroke: 0,
      question: 0,

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'look',
            startNumberRange: 0,
            endNumberRange: 100
          }
        ]
      },
      structureImgList: [
        {
          label: '左右',
          value:
            'https://static.nihaocafe.com/kb/cafb2e8c-eefb-49ea-b2c5-37ca2b10bd12.svg'
        },
        {
          label: '上下',
          value:
            'https://static.nihaocafe.com/kb/c01a0a46-add0-467b-bb01-83fe4cefa354.svg'
        },
        {
          label: '半包围左上',
          value:
            'https://static.nihaocafe.com/kb/c7811f64-52ea-4872-a6ca-342b64c422d7.svg'
        },
        {
          label: '半包围右上',
          value:
            'https://static.nihaocafe.com/kb/424aa05d-ef57-43e5-8154-3918b46b1f42.svg'
        },
        {
          label: '半包围左下',
          value:
            'https://static.nihaocafe.com/kb/ff57c8dc-ff2a-4b08-b1c3-1f8e8cfcd0da.svg'
        },
        {
          label: '上中下',
          value:
            'https://static.nihaocafe.com/kb/a4fc1dd1-5aaf-4ceb-b2cb-23d00eece606.svg'
        },
        {
          label: '左中右',
          value:
            'https://static.nihaocafe.com/kb/f7841c25-f70f-4e18-ac43-87026c576c8e.svg'
        },
        {
          label: '半包围上三',
          value:
            'https://static.nihaocafe.com/kb/c8112e42-161d-4a4f-b7a9-6bf840ae8205.svg'
        },
        {
          label: '半包围左三',
          value:
            'https://static.nihaocafe.com/kb/f9eaf063-7f93-44c2-b407-69dbd30b920e.svg'
        },
        {
          label: '半包围下三',
          value:
            'https://static.nihaocafe.com/kb/a19acc14-7e88-46b9-841d-170730847c81.svg'
        },
        {
          label: '全包围',
          value:
            'https://static.nihaocafe.com/kb/88f837aa-0eae-4846-8d44-c528bcf47b90.svg'
        },
        {
          label: '独体字',
          value:
            'https://static.nihaocafe.com/kb/86bbd42f-ecff-4326-b1d9-90a2702a6a32.svg'
        }
      ],
      isShowCheckGif: false
    }
  },
  methods: {
    initData() {
        console.log(this.componentProps, '--')
      this.leftList = []
      this.rightList = []
      const imgList = []
      this.questionInfoList = []
      if (this.structureImgList && this.structureImgList.length) {
        this.structureImgList.forEach((item, index) => {
          imgList.push({
            image: item.value,
            id: index + 1
          })
        })
      }
      const length = parseInt(imgList.length / 2)
      this.leftList = imgList.slice(0, length)
      this.rightList = imgList.slice(length, imgList.length)
      if (
        this.componentProps.structurePicList &&
        this.componentProps.structurePicList.length
      ) {
        
        this.componentProps.structurePicList.forEach((item, index) => {
          this.questionInfoList.push({
            id: item[0].value
              ? imgList.find((ele) => item[0].value === ele.image).id
              : null,
            bgImg: item[1].value
          })
        })
        this.totalStars = this.componentProps.structurePicList.length
      }
        console.log(this.questionInfoList, '--')
    },

    // 洗牌算法
    shuffle(arr) {
      let l = arr.length
      let index, temp
      while (l > 0) {
        index = Math.floor(Math.random() * l)
        temp = arr[l - 1]
        arr[l - 1] = arr[index]
        arr[index] = temp
        l--
      }
      return arr
    },
    foundAnswer(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.gameSocket = {
          index,
          value: Math.random()
        }
      }
      // let arr=[1,2,3,4,5,6,7,8,9]
      // this.shuffle(arr)
      // console.log(arr)

      if (this.questionInfoList[parseInt(this.question)].id == index) {
        this.answersfound++
        this.isShowImg = true
        this.isShowCheckGif = true

        setTimeout(() => {
          let sumArr = this.leftList.concat(this.rightList)
          this.shuffle(sumArr)
          const length = parseInt(sumArr.length / 2)
          this.leftList = sumArr.slice(0, length)
          this.rightList = sumArr.slice(length, sumArr.length)
          this.isShowCheckGif = false
        }, 2000)

        playCorrectSound(true, false)
        if (this.question >= this.questionInfoList.length - 1) {
          setTimeout(() => {
            playCorrectSound()
            // console.log('祝贺')
            startConfetti()
            this.isLastStep = true
            return
          }, 2000)
        } else {
          // this.wordSoundUrl =
          //   this.questionInfoList[parseInt(this.question) + 1].sound;
          this.question++
        }
      } else {
        playCorrectSound(false)
        this.isShowCheckGif = true
        this.isShowImg = false
        setTimeout(() => {
          this.isShowCheckGif = false
        }, 1000)
      }
    },
    playSoundWord(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.audioSocketInfo = {
          value: Math.random()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.game-find-structure-container {
  width: 100%;
  height: 100%;
  position: relative;
  .game-structure-content {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 48px;
    position: relative;
    overflow: hidden;
    .game-box {
      text-align: -webkit-center;
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: url('../../../../assets/img/16-GAMES/G74-structure-puzzle/background-structure.svg');
      border-radius: 48px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.backgroundBlue {
        background-image: url('../../../../assets/img/03-Backgrounds/background-factory.svg');
      }
      .game-main-content {
        box-sizing: border-box;
        position: relative;
        width: -moz-fit-content;
        background: #214e96;
        width: 75%;
        height: 75%;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .game-left-box,
        .game-right-box {
          height: 100%;
          width: 20%;
          display: flex;
          align-items: center;
          .game-aside-content {
            width: 100%;
            height: 95%;
            display: flex;
            border-top-right-radius: 30px;
            border-top-left-radius: 30px;
            justify-content: space-around;
            align-items: center;
            flex-direction: column;
          }
        }

        .game-center-box {
          width: 60%;
          height: 85%;
          border-radius: 30px;
          background-color: #fff;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: #cd4c3f;
          img {
            width: 60%;
          }
          .number-area {
            width: 50%;
            position: absolute;
            top: 32%;
            left: 18%;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            .pinyin,
            .hanzi {
              color: #224e96;
            }

            @media screen and (max-width: 1300px) {
              // external screen
              height: 28%;
            }
          }
          .speaker-wrapper {
            position: absolute;
            bottom: 5%;
            right: 10%;
            width: 50px;
            height: 34px;
            background-color: #224e96;
            border-radius: 50%;
            text-align: center;
            padding: 8px 0;
            &:hover {
              opacity: 0.8;
              cursor: pointer;
            }
            .speaker-img {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              margin: auto;
              height: 99%;
            }
          }
        }
      }

      .item-content {
        box-sizing: border-box;
        width: 65%;
        z-index: 1;
        border-radius: 15px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #fff;
        padding: 5%;
        img {
          width: 100%;
        }
      }
      .item-content:hover {
        background-color: #fff;
        border-bottom: none;
      }
    }
  }
}
</style>
