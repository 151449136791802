<template>
  <div class="star-container">
    <div class="outerBox" v-for="index in totalStarNumber" :key="index">
      <img
        v-if="currentIndex > index - 1"
        class="strokesFoundimage"
        :src="strockFoundImage"
      />
      <img v-else class="strokesFoundimage" :src="strockNotFoundImage" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Star",
  props: {
    totalStarNumber: {
      type: Number,
      require: true,
    },
    currentIndex: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      strockNotFoundImage: require("@/assets/img/16-GAMES/G21-puzzle/star.svg"),
      strockFoundImage: require("@/assets/img/16-GAMES/G21-puzzle/star-complete.svg"),
    };
  },
};
</script>
<style lang="scss" scoped>
.star-container {
  position: absolute;
  right: 11%;
  top: 0;
  height: -moz-fit-content;
  height: fit-content;
  padding: 5px 25px 15px 25px;
  display: flex;
  // flex-direction: row;
  background-color: #f4d737;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-bottom: 10px solid #d1b726;
  flex-flow: row;
  z-index: 100;
  .strokesFoundimage {
    /* height: 13%; */
    width: 27px;
    /* min-width: 20px; */
    margin-top: 20%;
  }
  .outerBox {
    width: 95%;
    height: 100%;
    padding: 0px 4px;
    /* margin-right: 10px; */
    position: relative;
    display: flex;

    .innerBox {
      width: 80%;
      height: 80%;
      margin: 10%;
      border-radius: 15px;
      // background-color: #2472B3;
    }

    .innerBoxFound {
      width: 80%;
      height: 80%;
      margin: 10%;
      border-radius: 15px;
      // background-color: #F3D438;

      .strokesFoundimage {
        height: 65%;
        margin-top: 30%;
        // margin-left: 40%;
        margin-right: 5%;
      }
    }
  }
}
</style>