<template>
  <div
    class="game-dialog-container"
    :style="{ backgroundImage: 'url(\'' + bgImg + '\')',backgroundSize: 'cover', }"
  >
    <div class="game-dialog-box">
      <!-- <img
        class="bg-img"
        :src="img"
        alt=""
      /> -->
      <div class="gameDialog-content" ref="dialogRefs">
        <div
          class="dialog-item"
          :class="{ rightActive: item.position }"
          v-for="(item, index) in dialogList"
          :key="index + 'dialog'"
        >
          <div class="dialog-avator">
            <img
              :src="
                item.dialogAvator
                  ? item.dialogAvator
                  : require('@/assets/img/18-Account/icon-camera.svg')
              "
              alt=""
            />
          </div>
          <div class="dialog-common" :class="{ redColor: !item.position, blueColor: item.position}">
            <p class="font-pinyin-medium pinyin">{{ item.dialogPy }}</p>
            <p class="font-hanzi-medium">{{ item.dialogValue }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  name: 'GameDialog',
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          dialogList: [],
          bgImg: ''
        }
      }
    }
  },
  watch: {
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  data() {
    return {
      dialogList: [],
      bgImg: '',
      localData: {
        bgImg: 'http://test.nihaokids.com/img/background-practice.4ccc904c.svg',
        dialogList: [
          {
            dialogAvator: 'http://test.nihaokids.com/img/menu-1.1afe3325.svg',
            dialogPy: 'ni hao ma?',
            dialogValue: '你好吗？',
            position: false
          },
          {
            dialogAvator: 'http://test.nihaokids.com/img/menu-3.604ccc52.svg',
            dialogPy: 'wo hen hao,ni ne？',
            dialogValue: '我很好，你呢?',
            position: true
          },
          {
            dialogAvator: 'http://test.nihaokids.com/img/menu-1.1afe3325.svg',
            dialogPy: 'wo ye hen hao',
            dialogValue: '我也很好',
            position: false
          }
        ]
      }
    }
  },
  mounted() {
    // this.dataInit()
  },
  methods: {
    initData() {
      this.bgImg = this.componentProps.bgImg
      this.dialogList = this.componentProps.dialogList.map((item) => {
        return {
          dialogAvator: item[0].value,
          dialogValue: item[1].value,
          position: item[3].value,
          dialogPy: item[2].value
        }
      })
      // console.log(this.dialogList, 'dialogList');
      
    }
  }
}
</script>

<style lang="scss" scoped>
.game-dialog-container {
  width: 100%;
  height: 100%;
  background-color: #e0e9f7;
  border-radius: 56px;
  .game-dialog-box {
    width: 100%;
    height: 100%;
    .gameDialog-content {
      padding: 40px 20px;
      width: calc(100% - 40px);
      height: calc(100% - 80px);
      overflow: auto;
      .dialog-item {
        margin-bottom: 20px;
        display: flex;
        width: 100%;
        align-items: center;
      }
      .rightActive {
        display: flex;
        justify-content: flex-end;
        position: relative;
        .dialog-avator {
          position: absolute;
          right: 0;
          img{
            margin: 0 10px;
          }
        }
        .dialog-common {
          margin-right: 85px;
          p {
            text-align: end;
          }
          .font-pinyin-medium{
            text-align: start;
          }
          
        }
      } 
      .dialog-common{
        color: #fff;
        padding: 8px 15px;
        border-radius: 15px;
      }
      .redColor {
        background-color: #cd4c3f;
      }
      .blueColor {
        background-color: #224e96;
      }
      .dialog-avator {
        // width: 70px;
        // height: 70px;
        margin-right: 10px;
        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        @media screen and (max-width: 2200px) {
          width: 80px;
          height: 80px;
        }
        @media screen and (max-width: 1500px) {
          width: 60px;
          height: 60px;
        }
        @media screen and (max-width: 1300px) {
          width: 45px;
          height: 45px;
        }
      }
    }
  }
}
</style>
