<template>
  <div class="game-link-up-container">
    <div class="game-link-up-content">
      <div class="game-picture-envelope">
        <div class="picture-switch">
          <div
            class="lesson-one"
            v-for="(item, index) in envelopeList"
            :key="index"
            :class="
              ({
                notShow: showIndex === item.key,
              },
              `envelope-${item.key}`)
            "
          >
            <img
              class="switchImg"
              :src="item.picture ? item.picture : envelopImg"
              alt=""
              draggable
              @dragstart.stop="drag($event, item)"
              @drop.stop="drop($event)"
              @dragend="handleDragend"
              :class="
                ({
                  dispear: item.isDispear,
                },
                'switchImg' + item.key)
              "
            />
            <!-- @dragover.prevent -->
          </div>
        </div>
      </div>
      <div class="game-picture-mailbox">
        <div class="mailbox-switch">
          <div
            class="lesson-one"
            v-for="(item, index) in mailboxList"
            :key="index"
            @drop.stop="drop($event, item)"
            @dragover.stop="allowDrop($event)"
          >
            <img
              src="@/assets/img/16-GAMES/G10-mailbox/mailbox.svg"
              alt=""
              draggable="true"
            />
            <div class="word" :class="'word' + item.value">
              <span class="pinyin font-pinyin">{{ item.pinyin }}</span>
              <span class="font-hanzi">{{ item.hanzi }}</span>
            </div>
          </div>
        </div>
      </div>
      <CommonButton
        @shuffleClickEvent="handleShuffleClickEvent"
        v-if="!finishNum"
      />
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import { templatePreviewMixin } from "./templatePreviewMixin.js";

import CommonButton from "../GameComponent/CommonButton.vue";
export default {
  name: "GameLinkUp",
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          gameLinkUpList: [],
        };
      },
    },
  },
  components: {
    CommonButton,
  },

  data() {
    return {
      showIndex: null,
      dragNum: null,
      dragSocketInfo: {},
      currentKey: null,
      currentDom: "",
      originDom: "",
      dragSocketInfoValue: false,
      checkDragValue: null,
      checkInfo: {},
      finishNum: 0,
      envelopeList: [],
      mailboxList: [],
      envelopImg:
        "https://static.nihaocafe.com/image/kids/2f07a3a0-72c3-4ac3-92a5-41078c8bb60d.svg",
      localData: {
        dragNum: 4,
        envelopeList: [
          {
            picture: require("@/assets/img/16-GAMES/G10-mailbox/lesson-25-envelope-zhe.svg"),
            isDispear: false,
            key: 1,
            index: 1,
          },
          {
            picture: require("@/assets/img/16-GAMES/G10-mailbox/lesson-25-envelope-na.svg"),
            isDispear: false,
            key: 2,
            index: 2,
          },
          {
            picture: require("@/assets/img/16-GAMES/G10-mailbox/lesson-25-envelope-kan.svg"),
            isDispear: false,
            key: 3,
            index: 3,
          },
          {
            picture: require("@/assets/img/16-GAMES/G10-mailbox/lesson-25-envelope-mao.svg"),
            isDispear: false,
            key: 4,
            index: 4,
          },
        ],
        mailboxList: [
          {
            picture: require("@/assets/img/16-GAMES/G10-mailbox/mailbox.svg"),
            pinyin: "zhè",
            hanzi: "这",
            value: 1,
          },
          {
            picture: require("@/assets/img/16-GAMES/G10-mailbox/mailbox.svg"),
            pinyin: "nà",
            hanzi: "那",
            value: 2,
          },
          {
            picture: require("@/assets/img/16-GAMES/G10-mailbox/mailbox.svg"),
            pinyin: "kàn",
            hanzi: "看",
            value: 3,
          },
          {
            picture: require("@/assets/img/16-GAMES/G10-mailbox/mailbox.svg"),
            pinyin: "māo",
            hanzi: "猫",
            value: 4,
          },
        ],
      },
      shuffleSocketInfo: {},
      shuffleSocketValue: null,
    };
  },
  watch: {
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value;
        if (isTemplatePrview) return;
        this.initData();
      },
      deep: true,
    },
    finishNum(value) {
      if (value === this.dragNum) {
        startConfetti();
        playCorrectSound();
      }
    },
    dragSocketInfo: {
      handler(value) {
        if (!this.dragSocketInfoValue) {
          const data = {
            clickType: 2001002,
            data: { value },
            text: "DragGameBtn drag点击socket",
          };
          this.sendSocketInfo = { ...data };
          this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
        }
      },
      deep: true,
    },
    checkInfo: {
      handler(value) {
        if (!this.checkDragValue) {
          const data = {
            clickType: 2001003,
            data: { value },
            text: "DragGameBtn check点击socket",
          };
          this.sendSocketInfo = { ...data };
          this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
        }
      },
      deep: true,
    },
    shuffleSocketInfo: {
      handler(value) {
        if (!this.shuffleSocketValue) {
          const data = {
            clickType: 2001004,
            data: { value },
            text: "DragGameBtn shuffleSocketValue",
          };
          console.log(data);
          this.$bus.$emit("kids_webSocket_sendInfo", data);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("dragEnvelope", ({ currentKey }) => {
      // console.log(currentKey);

      this.originDom = document.getElementsByClassName(
        `envelope-${currentKey}`
      )[0];
      this.currentDom = document.getElementsByClassName(
        `switchImg${currentKey}`
      )[0];
      this.currentKey = currentKey;
      this.dragSocketInfoValue = true;
    });
    this.$bus.$on("checkDrag", ({ item }) => {
      
      let event = {};
      event.target = document.getElementsByClassName(`word${item.value}`)[0];
      this.drop(event, item);
      this.checkDragValue = true;
    });

    this.$bus.$on("shuffleArray", ({ ramdomList }) => {
      this.envelopeList = [];
      this.envelopeList = ramdomList;

      this.shuffleSocketValue = true;
    });
  },
  beforeDestroy() {
    this.$bus.$off("dragEnvelope");
    this.$bus.$off("checkDrag");
    this.$bus.$off("shuffleArray");
  },
  methods: {
    /**
     * @description: 乱序回调
     * @return {*}
     */
    handleShuffleClickEvent() {
      console.log("乱序");
      const orderList = this.shuffleArray(this.envelopeList);
      this.envelopeList = [];
      this.envelopeList = orderList;

      this.shuffleSocketValue = false;

      this.shuffleSocketInfo = {
        ramdomList: this.envelopeList,
        ramdomIndex: Math.random(),
      };
    },
    initData() {
      this.dragNum = this.componentProps.gameLinkUpList.length;
      this.envelopeList = this.componentProps.gameLinkUpList.map(
        (item, index) => {
          return {
            key: index + 1,
            isDispear: false,
            index: index + 1,
            picture: item[2].value,
          };
        }
      );
      this.mailboxList = this.componentProps.gameLinkUpList.map(
        (item, index) => {
          return {
            value: index + 1,
            pinyin: item[1].value,
            hanzi: item[0].value,
          };
        }
      );
    },
    /**
     * @description: 数组乱序
     * @param {*} array
     * @return {*}
     */
    shuffleArray(array) {
      let currentIndex = array.length,
        temporaryValue,
        randomIndex;

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      // console.log(array);

      return array;
    },
    drag(event, item) {
      // console.log(event, item, '111')
      this.showIndex = item.key;
      this.dragSocketInfoValue = false;
      this.originDom = event.currentTarget.parentNode;
      this.currentDom = event.currentTarget;
      this.currentKey = item.key;
      this.dragSocketInfo = {
        currentKey: this.currentKey,
        ramdomIndex: Math.random(),
      };
    },
    drop(event, item) {
      // console.log(event, item, '2222')
      this.checkDragValue = false;
      this.checkInfo = {
        event: {
          target: event.target,
        },
        ramdomIndex: Math.random(),
        item,
      };
      event.target.appendChild(this.currentDom); // 传入信封
      // console.log(this.currentKey, 'this.currentKey')

      if (this.currentKey === item.value) {
        this.finishNum++;
        playCorrectSound(true, false);
      } else {
        const timer = setTimeout(() => {
          event.target.removeChild(this.currentDom); // 传入信封
          
          this.originDom.appendChild(this.currentDom);
          event.target.parentElement.className += " shake";
          playCorrectSound(false);
          const innerTimer = setTimeout(() => {
            event.target.parentElement.className =
              event.target.parentElement.className.replace("shake", "");
            clearTimeout(innerTimer);
            clearTimeout(timer);
          }, 1000);
        }, 200);
      }
    },
    handleDragend() {
      this.showIndex = null;
    },
    allowDrop(event) {
      event.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
.switchImg {
  cursor: pointer;
  -webkit-user-drag: auto !important;
}
.game-link-up-container {
  width: 100%;
  height: 100%;
  position: relative;
  line-height: 1;
  .game-link-up-content {
    width: 100%;
    height: 100%;
    background-image: url("../../../../assets/img/03-Backgrounds/background-practice.svg");
    background-color: #f7f4e0;
    border-radius: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    position: relative;
    .game-picture-envelope {
      width: 90%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .picture-switch {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .lesson-one {
          width: 45%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            display: inline-block;
            height: 100%;
            width: 50%;
          }
        }
        .notshow {
          opacity: 0;
        }
      }
    }
    .game-picture-mailbox {
      width: 90%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .mailbox-switch {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .lesson-one {
          width: 45%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          .lesson-one-img {
            width: 50%;
            height: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -20%;
            z-index: 1002;
            img {
              width: 80%;
            }
          }
          img {
            width: 80%;
          }
          .word {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            position: absolute;
            top: 6%;
            span {
              color: #fff;
            }
            .font-hanzi {
              font-size: 18px;
            }
            .font-pinyin {
              font-size: 11px;
            }
            .switchImg {
              height: 30%;
              position: absolute;
              top: 4%;
            }
          }
        }
      }
    }
    .common-button-container {
    position: absolute;
    bottom: 2px;
    right: 0;
    height: 20%;
    max-height: 160px;
    }
  }
}
</style>
