<template>
  <div class="game-find-img-container">
    <div class="game-content">
      <Star :totalStarNumber="totalStars" :currentIndex="answersfound" />
      <div class="game-background">
        <div class="body-content">
          <div class="game-left-box">
            <div class="game-aside-content">
              <div
                v-for="(item, index) in leftList"
                :key="index"
                class="item-content"
              >
                <div class="audio-area" @click="playSoundWord(item.id)">
                  <AudioClickPlayer
                    :audioUrl="item.sound"
                    :ref="'audioBox' + item.id"
                    :title="item.element"
                  />
                </div>
                <div
                  class="word-area"
                  @click="foundAnswer(item.id)"
                  :class="{ 'word-hover': item.id === hoverId}"
                  @mouseenter="
                    getWordsId(
                      item.id,
                      isHover = true,
                      index
                    )
                  "
                  @mouseleave="
                    getWordsId(
                      item.id,
                      isHover = false,
                      index
                    )
                  "
                >
                  <div class="font-pinyin-medium pinyin" v-if="item.pinyin">
                    {{ item.pinyin }}
                  </div>
                  <div class="font-hanzi-medium">{{ item.element }}</div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="game-center-box"
            v-for="(item, index) in questionInfoList"
            :key="index + 'question'"
            v-show="question === index"
          >
            <!-- 中间的背景图 -->
            <img class="objectDisplay fadeIn" :src="item.bgImg" />
          </div>

          <div class="game-right-box">
            <div class="game-aside-content">
              <div
                v-for="(item, index) in rightList"
                :key="index"
                class="item-content"
              >
                <div class="audio-area" @click="playSoundWord(item.id)">
                  <AudioClickPlayer
                    :audioUrl="item.sound"
                    :ref="'audioBox' + item.id"
                    :title="item.element"
                  />
                </div>
                <div class="word-area" @click="foundAnswer(item.id)"
                :class="{ 'word-hover': item.id === hoverId}"
                @mouseenter="
                    getWordsId(
                      item.id,
                      isHover = false,
                      index
                    )
                  "
                  @mouseleave="
                    getWordsId(
                      item.id,
                      isHover = false,
                      index
                    )
                  ">
                  <div class="font-pinyin-medium pinyin" v-if="item.pinyin">
                    {{ item.pinyin }}
                  </div>
                  <div class="font-hanzi-medium">{{ item.element }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Star from '@/components/Course/GamePage/Star'
import AudioClickPlayer from '@/components/Course/GamePage/AudioClickPlayer'
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    Star,
    AudioClickPlayer
  },
  mounted() {
    // this.initData()
    this.$bus.$on('chooseElementToCheck', ({ index }) => {
      this.foundAnswer(index, true)
    })
    this.$bus.$on('chooseElementAudio', ({ id }) => {
      this.playSoundWord(id, true)
    })
    this.$bus.$on('shuffleArray', ({ questionInfoList }) => {
      this.questionInfoList = questionInfoList
    })
    this.$bus.$on(
      'hoverSyllabusPage',
      ({ id, isHover, index }) => {
            if (isHover) {
              this.hoverId = id
            } else {
              this.hoverId = null
            }
      }
    )
  },
  beforeDestroy() {
    this.$bus.$off('chooseElementToCheck')
    this.$bus.$off('chooseElementAudio')
    this.$bus.$off('shuffleArray')
    this.$bus.$off('hoverSyllabusPage')
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          questionInfoList: []
        }
      }
    }
  },
  watch: {
    gameSocket: {
      handler(value) {
        const data = {
          clickType: 20027,
          data: { value },
          text: 'NumberClickGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002701,
          data: { value },
          text: 'NumberClickGame点击喇叭socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  data() {
    return {
      localData: {
        leftList: [
          {
            id: 5,
            element: '爸爸',
            pinyin: 'bàba',
            sound: ''
          },
          {
            id: 1,
            element: '妈妈',
            pinyin: 'māma',
            sound: ''
          },
          {
            id: 4,
            element: '哥哥',
            pinyin: 'gēge',
            sound: ''
          }
        ],
        questionInfoList: [
          {
            id: 1,
            bgImg: require('@/assets/img/12-Vocabulary/lesson-09-mama.svg')
          },
          {
            id: 2,
            bgImg: require('@/assets/img/12-Vocabulary/lesson-13-jiejie.svg')
          },
          {
            id: 3,
            bgImg: require('@/assets/img/12-Vocabulary/lesson-13-didi.svg')
          },
          {
            id: 4,
            bgImg: require('@/assets/img/12-Vocabulary/lesson-11-gege.svg')
          },
          {
            id: 5,
            bgImg: require('@/assets/img/12-Vocabulary/lesson-09-baba.svg')
          },
          {
            id: 6,
            bgImg: require('@/assets/img/12-Vocabulary/lesson-11-meimei.svg')
          }
        ],
        rightList: [
          {
            id: 2,
            element: '姐姐',
            pinyin: 'jiějie',
            sound: ''
          },
          {
            id: 3,
            element: '弟弟',
            pinyin: 'dìdi',
            sound: ''
          },
          {
            id: 6,
            element: '妹妹',
            pinyin: 'mèimei',
            sound: ''
          }
        ],
        totalStars: 6
      },
      leftList: [],
      questionInfoList: [],
      rightList: [],
      totalStars: null,
      gameSocket: {},
      audioSocketInfo: {},
      isLastStep: false,
      answersfound: 0,
      draggedStroke: 0,
      question: 0,
      isHover: false,
      hoverId:null,

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'look',
            startNumberRange: 0,
            endNumberRange: 100
          }
        ]
      },
      position: null,
      currentIndex: null,
      audioImg: require('@/assets/img/06-Sound/sound-white.svg')
    }
  },
  methods: {
    initData() {
      // console.log(this.componentProps,'--');
      this.rightList = []
      this.leftList = []
      this.questionInfoList = []
      if (
        this.componentProps.questionInfoList &&
        this.componentProps.questionInfoList.length
      ) {
        this.componentProps.questionInfoList.forEach((item, index) => {
          item = item || {}

          // 循环 options 的每个键值对并添加 levelKey
          Object.entries(item).forEach(([key, value]) => {
            item[key] = {
              ...value,
              levelKey: index // 为每个属性添加 levelKey
            }
          })
          this.questionInfoList.push({
            id: index + 1,
            bgImg: item[4].value
          })
          if (item[3].value) {
            this.rightList.push({
              id: index + 1,
              element: item[1].value,
              pinyin: item[0].value,
              sound: item[2].value
            })
          } else if (item[3].value === 0) {
            this.leftList.push({
              id: index + 1,
              element: item[1].value,
              pinyin: item[0].value,
              sound: item[2].value
            })
          }
        })
        this.totalStars = this.componentProps.questionInfoList.length
        this.questionInfoList = this.shuffleArray(this.questionInfoList)
        this.sendRandom({
          questionInfoList: this.questionInfoList
        })
      }
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
      }
      return array
    },
    getWordsId(id, isHover, index) {
      // 获取hover元素的id      
      const data = {
        clickType: 20004,
        data: {
          value: {
            id,
            isHover,
            index
          }
        },
        text: '测试SyllabusPagePage组件内hover事件socket'
      }
      this.$bus.$emit('kids_webSocket_sendInfo', data)
    },
    /**
     * @description: 发送乱序事件
     * @param {*} value
     * @return {*}
     */ sendRandom(value) {
      const data = {
        clickType: 2001004,
        data: { value },
        text: 'DragGameBtn shuffleSocketValue'
      }
      this.$bus.$emit('kids_webSocket_sendInfo', data)
    },
    foundAnswer(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.gameSocket = {
          index,
          value: Math.random()
        }
      }

      if (this.questionInfoList[parseInt(this.question)].id == index) {
        this.answersfound++
        playCorrectSound(true, false)
        if (this.question >= this.questionInfoList.length - 1) {
          playCorrectSound()
          // console.log('祝贺')
          startConfetti()
          this.isLastStep = true
          return
        } else {
          this.wordSoundUrl =
            this.questionInfoList[parseInt(this.question) + 1].sound
          this.question++
        }
      } else {
        playCorrectSound(false)
      }
    },
    playSoundWord(id, isFromSocket = false) {
      if (!isFromSocket) {
        this.audioSocketInfo = {
          id,
          value: Math.random()
        }
      }
      // console.log(this.$refs["audioBox" + id]);
      this.$refs['audioBox' + id][0].play()
    }
  }
}
</script>

<style lang="scss" scoped>
.game-find-img-container {
  width: 100%;
  height: 100%;
  .game-content {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 48px;
    position: relative;
    overflow: hidden;
    .game-background {
      text-align: -webkit-center;
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: url('../../../../assets/img/03-Backgrounds/background-practice.svg');
      border-radius: 48px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.backgroundBlue {
        background-image: url('../../../../assets/img/03-Backgrounds/background-factory.svg');
      }
    }
    .body-content {
      box-sizing: border-box;
      position: relative;
      width: -moz-fit-content;
      background: #214e96;
      width: 75%;
      height: 75%;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .game-left-box,
      .game-right-box {
        height: 100%;
        width: 25%;
        display: flex;
        align-items: center;
        .game-aside-content {
          width: 100%;
          height: 95%;
          display: flex;

          border-top-right-radius: 30px;
          border-top-left-radius: 30px;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
        }
      }
      .game-left-box {
        margin-left: 10px;
      }
      .game-right-box {
        margin-right: 10px;
      }

      .game-center-box {
        width: 50%;
        height: 85%;
        border-radius: 30px;
        background-color: #fff;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 80%;
        }
        .number-area {
          width: 50%;
          position: absolute;
          top: 32%;
          left: 18%;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          .pinyin,
          .hanzi {
            color: #224e96;
          }

          @media screen and (max-width: 1300px) {
            height: 28%;
          }
        }
        .speaker-wrapper {
          position: absolute;
          bottom: 5%;
          right: 10%;
          width: 50px;
          height: 34px;
          background-color: #224e96;
          border-radius: 50%;
          text-align: center;
          padding: 8px 0;
          &:hover {
            opacity: 0.8;
            cursor: pointer;
          }
          .speaker-img {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            height: 99%;
          }
        }
      }
    }
    .number-bg-color {
      background: #102c5a;
      box-sizing: border-box;
      border-top: 10px solid #233f6b;
      border-bottom: 10px solid #061329;
    }
    .item-content {
      box-sizing: border-box;
      z-index: 1;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      color: #fff;
      .audio-area {
        max-height: 35px;
        border-radius: 50%;
        background-color: #cd4c3f;
        margin-bottom: 10px;
        .audio-player-container {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
          }
        }
      }
      .word-area {
        width: 65%;
        border-radius: 15px;
        border-bottom: 8px solid #ac3d32;
        background-color: #cd4c3f;
        padding: 5px 10px;
      }
      .word-area:hover {
        background-color: #912016;
        border-top: 8px solid #6e0c03;
        border-bottom: none;
      }
      .word-hover {
        background-color: #912016;
        border-top: 8px solid #6e0c03;
        border-bottom: none;
      }
    }
  }
}
</style>
