var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drag-correct-conteiner" }, [
    _c(
      "div",
      { staticClass: "drag-correct-content" },
      [
        _c("Star", {
          attrs: { totalStarNumber: _vm.starNum, currentIndex: _vm.steps },
        }),
        _c("div", { staticClass: "drag-background" }, [
          _c("div", { staticClass: "drag-correct-pic-game-content" }, [
            _c("div", { staticClass: "game-top-box" }, [
              _c(
                "div",
                {
                  staticClass: "game-aside-content",
                  class: "type-" + _vm.imgNo,
                },
                _vm._l(_vm.optionsList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "item-content",
                      class: {
                        yellowBg: item.done,
                        shake: _vm.isClicked && index === _vm.dropIndex - 1,
                      },
                      attrs: { draggable: "true" },
                      on: {
                        drop: function ($event) {
                          $event.stopPropagation()
                          return _vm.drop(item)
                        },
                        dragover: function ($event) {
                          $event.stopPropagation()
                          return _vm.allowDrop($event)
                        },
                      },
                    },
                    [
                      item.done && item.bgImg
                        ? _c("img", {
                            staticClass: "bg-img",
                            attrs: { src: item.bgImg },
                          })
                        : _vm._e(),
                      !item.done && item.pinyin
                        ? _c("div", { staticClass: "btn-area" }, [
                            _c(
                              "div",
                              { staticClass: "font-pinyin-medium pinyin" },
                              [_vm._v(" " + _vm._s(item.pinyin) + " ")]
                            ),
                            _c("div", { staticClass: "font-hanzi-medium" }, [
                              _vm._v(_vm._s(item.hanzi)),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("div", { staticClass: "answer-area" }, [
            _c("div", { staticClass: "redBackground" }),
            _c(
              "div",
              { staticClass: "bottonsWrapper" },
              [
                _vm._l(
                  _vm.answerList.slice(0, _vm.imgNo),
                  function (item, index) {
                    return _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.answerList.length,
                            expression: "answerList.length",
                          },
                        ],
                        key: index,
                        staticClass: "content-words-two",
                        attrs: { draggable: "true" },
                        on: {
                          dragstart: function ($event) {
                            $event.stopPropagation()
                            return _vm.drag(item)
                          },
                          dragend: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleDropBg.apply(null, arguments)
                          },
                        },
                      },
                      [
                        item.image
                          ? _c("img", { attrs: { src: item.image, alt: "" } })
                          : _vm._e(),
                      ]
                    )
                  }
                ),
                !_vm.answerList.length
                  ? _c("div", { staticClass: "content-words-two" }, [
                      _c("img", {
                        style: { opacity: 0 },
                        attrs: { src: _vm.fakerImg, alt: "" },
                      }),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }