<template>
  <div class="game-choose-words-by-pic-container">
    <div class="game-content">
      <!-- <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="currentIndex"
        @changeTheNumbers="changeTheNumbers"
        v-if="hasMenu"
      /> -->
      <div class="title-area">
        <div class="title-box">
          <div class="title">
            <div class="title-pinyin pinyin font-pinyin-medium">
              {{ titlePinyin }}
            </div>
            <div class="title-hanzi font-hanzi-medium">
              {{ titleHanZi }}
            </div>
          </div>
        </div>
      </div>
      <!-- page  chooose hanzi -->
      <div class="choose-hanzi-box">
        <div class="answer-area">
          <div class="answer-img">
            <img :src="img" alt="" />
          </div>
          <div class="answer-hanzi-area" v-if="!isShowAllWords">
            <div class="hanzi-item hasInserted">
              <div class="pinyin font-pinyin-medium">{{ tipPinyin }}</div>
              <div class="tipHanziSize">{{ tipHanzi }}</div>
            </div>
            <div
              class="hanzi-item"
              v-for="(item, index) in hanziRightList"
              :key="item.id + 'right'"
              :class="{
                active: insertIndex === index,
                hasInserted: rightHanziIndexArr.indexOf(index + 1) > -1
              }"
            >
              <span class="insertedpinyin">{{ item.pinyin }}</span>
              <span class="insertedWords">{{ item.hanzi }}</span>
            </div>
          </div>

          <div class="answer-right-hanzi-area" v-else>
            <div class="right-hanzi-box">
              <span class="rightPinyin">{{ correctPinyin }}</span>
              <span class="font-hanzi-medium">{{ correctHanzi }}</span>
            </div>
          </div>
        </div>
        <div class="choose-hanzi-area">
          <div
            class="hanzi-item"
            v-for="(item, index) in hanziChooseList"
            :key="index + 'choose'"
            :class="{ disappear: choosedHanziArr.indexOf(index) > -1 }"
            @click="handleChooseHanzi(item.id, index)"
          >
            <span class="pinyin font-pinyin-medium">{{ item.pinyin }}</span>
            <span class="font-hanzi-medium">{{ item.hanzi }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPinyinByZh } from '@/assets/js/axios/kidsApi'
import { startConfetti, playCorrectSound } from '@/utils/tools.js'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          titleHanZi: '',
          titlePinyin: '',
          img: '',
          hanziList: [],
          tipPinyin: '',
          tipHanzi: ''
        }
      }
    }
  },
  data() {
    return {
      hanziSocketInfo: {},
      clickMenuSocketInfo: {},
      tipHanzi: '',
      tipPinyin: '',
      chooseHanziList: [],
      isShowAllWords: false,
      currentIndex: 0,
      choosedHanziArr: [],
      hanziRightList: [],
      hanziChooseList: [],
      insertIndex: 0,
      rightHanziIndexArr: [],
      lockIndex: 0,
      currentStarIndex: 0,
      isLastStep: false,
      correctHanzi: '',
      correctPinyin: '',
      titleHanZi: '',
      img: '',
      titlePinyin: '',
      localData: {
        tipPinyin: 'wo',
        tipHanzi: '我',
        lockIndex: 0,
        currentStarIndex: 0,
        img: 'http://test.nihaokids.com/img/lesson-11-corgie-family.eb31793a.svg',
        titlePinyin: 'Xiǎng yi xiǎng: Dòudou xiǎng shuō shénme?',
        titleHanZi: '想一想：豆豆想说什么？',
        correctPinyin: 'Wǒ yǒu wǔ ge mèimei.',
        correctHanzi: '我有五个妹妹。',
        hanziRightList: [
          {
            pinyin: 'yǒu',
            hanzi: '有',
            id: 3
          },
          {
            pinyin: 'wǔ',
            hanzi: '五',
            id: 2
          },
          {
            pinyin: 'gè',
            hanzi: '个',
            id: 4
          },
          {
            pinyin: 'mèimei',
            hanzi: '妹妹',
            id: 1
          }
        ],

        hanziChooseList: [
          {
            pinyin: 'mèimei',
            hanzi: '妹妹',
            id: 1
          },
          {
            pinyin: 'wǔ',
            hanzi: '五',
            id: 2
          },
          {
            pinyin: 'yǒu',
            hanzi: '有',
            id: 3
          },
          {
            pinyin: 'gè',
            hanzi: '个',
            id: 4
          }
        ]
      }
    }
  },
  methods: {
    /**
     * @description: 选择汉字后判断是否正确
     * @param {*} id
     * @param {*} index
     * @return {*}
     */
    handleChooseHanzi(id, index, isFromSocket = false) {
      if (!isFromSocket) {
        this.hanziSocketInfo = {
          id,
          index,
          value: Math.random()
        }
      }
      if (id === this.hanziRightList[this.lockIndex].id) {
        this.choosedHanziArr.push(index)
        setTimeout(() => {
          this.lockIndex++
          this.insertIndex++
          this.rightHanziIndexArr.push(this.lockIndex)
          this.checkToNext()
        }, 10)
      } else {
        playCorrectSound(false)
      }
    },
    /**
     * @description: 进入下一关
     * @return {*}
     */
    checkToNext() {
      // console.log(this.lockIndex,'----');

      if (this.lockIndex >= this.hanziRightList.length) {
        this.isShowAllWords = true
        playCorrectSound(true, false)
        startConfetti()
      }
    },
    async initData() {
      this.tipHanzi = this.componentProps.tipHanzi
      this.tipPinyin = this.componentProps.tipPinyin
      this.titlePinyin = this.componentProps.titlePinyin
      this.titleHanZi = this.componentProps.titleHanZi
      this.img = this.componentProps.img
      let handleHanziList = []
      for (let arr of this.componentProps.hanziList) {
        let item = arr[0].value
        if (item && item !== ' ') {
          const res = await getPinyinByZh({ content: item })
          if (res.code === 200) {
            const data = JSON.parse(res.data)
            //   console.log(data, '测试')
            let allPinyin = ''
            let allHanzi = ''
            data.forEach((element) => {
              allPinyin += element.wordPy
              allHanzi += element.wordZh
            })
            const obj = {
              hanzi: allHanzi,
              pinyin: allPinyin,
              id: `${allHanzi}-${allPinyin}`
            }
            handleHanziList.push(obj)
          }
        }
      }
      this.chooseHanziList = JSON.parse(JSON.stringify(handleHanziList))
      this.hanziRightList = JSON.parse(JSON.stringify(handleHanziList))
      this.correctHanzi = this.tipHanzi
      this.correctPinyin = this.tipPinyin

      this.hanziRightList.forEach((ele) => {
        this.correctHanzi += ele.hanzi
        this.correctPinyin += ele.pinyin
      })

      for (let i = handleHanziList.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[handleHanziList[i], handleHanziList[j]] = [
          handleHanziList[j],
          handleHanziList[i]
        ] // 交换元素
      }
      this.hanziChooseList = JSON.parse(JSON.stringify(handleHanziList))
    }
  },
  mounted() {
    this.$bus.$on('clickToChooseWords', ({ id, index }) => {
      console.log(id, index);
      
      this.handleChooseHanzi(id, index, true)
    })
    this.$bus.$on('chooseWordsByPicGameClickMenu', ({ index }) => {
      this.changeTheNumbers(index, true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickToChooseWords')
    this.$bus.$off('chooseWordsByPicGameClickMenu')
  },
  watch: {
    hanziSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20043,
          data: { value },
          text: 'ChooseWordsByPicGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    // clickMenuSocketInfo: {
    //   handler(value) {
    //     const data = {
    //       clickType: 2004301,
    //       data: { value },
    //       text: 'ChooseWordsByPicGame点击 menu socket'
    //     }
    //     this.sendSocketInfo = { ...data }
    //     this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
    //   },
    //   deep: true
    // },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      // immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.game-choose-words-by-pic-container {
  width: 100%;
  height: 100%;
  background: #f7f4e0;
  border-radius: 58px;
  .game-content {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .title-area {
    width: 100%;
    .title-box {
      margin-top: 5%;
      padding: 10px;
      width: 45%;
      background: #cd4c3f;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      color: #fff;
      width: fit-content;
      padding: 10px 25px;
      .title {
        // margin-left: 5%;
        .title-pinyin {
          // margin-left: 10px;
        }
      }
    }
  }
  .choose-hanzi-box {
    width: 100%;
    height: 100%;
    .answer-area {
      height: 70%;
      display: flex;
      align-items: center;
      .answer-img {
        margin-top: 2%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 2;
        img {
          width: 70%;
          //   border: 10px solid #fff;
          //   box-sizing: border-box;
          transform: rotate(-10deg);
        }
      }
      .lesson-11 {
        flex: 1.5;
      }
      .answer-hanzi-area {
        flex: 3;
        height: 40%;
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        .hanzi-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 20px;
          border: 5px dashed #cfc8b0;
          border-radius: 20px;
          color: #f7f4e0;
          background: transparent;
          color: #fff;
          display: flex;
          flex-direction: column;
          width: 50px;
          height: 65px;
          padding: 7px 7px;
          justify-content: center;
          span {
            visibility: hidden;
            display: inline-block;
          }
          .tipHanziSize {
            font-size: 25px !important;
          }
        }
        .lesson-11 {
          height: 45%;
        }
        .active {
          border: 5px dashed #ce4c3f;
        }
        .hasInserted {
          border: none;
          background: #224e96;
          color: #fff;
          display: flex;
          flex-direction: column;
          padding: 20px 20px;
          span {
            visibility: visible;
          }
          .insertedWords {
            font-size: 20px;
          }
          .insertedpinyin {
            font-size: 13px;
          }
        }
      }
      .lesson-11 {
        .hanzi-item {
          height: 45%;
        }
      }
      .answer-right-hanzi-area {
        height: 100%;
        flex: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        .right-hanzi-box {
          border-radius: 30px;
          background: #224e96;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 130px;
          padding: 20px 70px;
          span {
            display: inline-block;
            color: #fff;
          }
          .font-pinyin-medium {
            font-size: 20px;
            font-family: 'STHeitiSCPinyin-Light';
            display: inline-block;
          }
          .rightPinyin {
            font-size: 25px;
            font-family: 'STHeitiSCPinyin-Light';
            display: inline-block;
          }
        }
      }
    }
    .choose-hanzi-area {
      height: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      .hanzi-item {
        box-sizing: border-box;
        padding: 10px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 20px;

        background-color: #ce524c;
        color: #fff;

        border-bottom: 12px solid #a53d32;
        border-top: 12px solid #d87065;

        border-radius: 30px;
        cursor: pointer;
        span {
          display: inline-block;
        }
      }
      .disappear {
        opacity: 0;
      }
      .hanzi-item:hover {
        background-color: #912016;
        border-bottom: 12px solid #d87065;
        border-top: 12px solid #a53d32;
      }
    }
  }
  .lesson-11 {
    .choose-hanzi-area {
      height: 35%;
      align-items: flex-start;
    }
  }
}
</style>
