var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "star-container" },
    _vm._l(_vm.totalStarNumber, function (index) {
      return _c("div", { key: index, staticClass: "outerBox" }, [
        _vm.currentIndex > index - 1
          ? _c("img", {
              staticClass: "strokesFoundimage",
              attrs: { src: _vm.strockFoundImage },
            })
          : _c("img", {
              staticClass: "strokesFoundimage",
              attrs: { src: _vm.strockNotFoundImage },
            }),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }