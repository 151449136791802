var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-find-structure-container" }, [
    _c(
      "div",
      { staticClass: "game-structure-content" },
      [
        _c("Star", {
          attrs: {
            totalStarNumber: _vm.totalStars,
            currentIndex: _vm.answersfound,
          },
        }),
        _c("div", { staticClass: "game-box" }, [
          _c(
            "div",
            { staticClass: "game-main-content" },
            [
              _c("div", { staticClass: "game-left-box" }, [
                _c(
                  "div",
                  { staticClass: "game-aside-content" },
                  _vm._l(_vm.leftList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "item-content",
                        on: {
                          click: function ($event) {
                            return _vm.foundAnswer(item.id)
                          },
                        },
                      },
                      [_c("img", { attrs: { src: item.image } })]
                    )
                  }),
                  0
                ),
              ]),
              _vm._l(_vm.questionInfoList, function (item, index) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.question === index,
                        expression: "question === index",
                      },
                    ],
                    key: index + "question",
                    staticClass: "game-center-box",
                  },
                  [
                    _c("img", {
                      staticClass: "objectDisplay fadeIn",
                      attrs: { src: item.bgImg },
                    }),
                    _c("div", { staticClass: "number-area" }),
                  ]
                )
              }),
              _c("div", { staticClass: "game-right-box" }, [
                _c(
                  "div",
                  { staticClass: "game-aside-content" },
                  _vm._l(_vm.rightList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "item-content",
                        on: {
                          click: function ($event) {
                            return _vm.foundAnswer(item.id)
                          },
                        },
                      },
                      [_c("img", { attrs: { src: item.image } })]
                    )
                  }),
                  0
                ),
              ]),
            ],
            2
          ),
        ]),
      ],
      1
    ),
    _vm.isShowCheckGif
      ? _c("div", {
          directives: [
            {
              name: "checkgif",
              rawName: "v-checkgif",
              value: _vm.isShowImg,
              expression: "isShowImg",
            },
          ],
          staticClass: "checkGif",
          class: { checkGifWrong: !_vm.isShowImg },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }