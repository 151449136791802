<template>
  <!-- 

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,   true | false  shows the button "next" when the property isLastStep = true
        },

        buttons: [
          {
          type: "next",               speak | listen | next | play (mandatory)
          startNumberRange: 1,        (mandatory)
          endNumberRange: 1,          (mandatory)
          numBerValueAfterClick: 6    (optional)
          clickGoToNextPage: true     (optional)
          showOnLastStep:             (optional) sometimes, the last step isn't a different "numBers". In this case, we need to trigger the last step directly in this file
          },
        ],
      },
        bottomCornerButtonList: [
        {
        },
      ],
 -->
  <div>
    <div
      v-for="(item, index) in bottomCornerButtonList.buttons"
      :key="index"
      @mouseover="mouseover(bottomCornerButtonList.buttons[index].type)"
      @mouseleave="mouseleave(bottomCornerButtonList.buttons[index].type)"
      @click="
        bottomCornerButtonList.settings.showNextOnLastStep == true &&
        isLastStep == true
          ? goToNextPage()
          : bottomCornerButtonList.buttons[index].numBerValueAfterClick !==
              undefined &&
            bottomCornerButtonList.buttons[index].numBerValueAfterClick !== null
          ? clickHandle(
              bottomCornerButtonList.buttons[index].numBerValueAfterClick
            )
          : bottomCornerButtonList.buttons[index].repeatBtn
          ? randomClick()
          : null
      "
    >
      <!-- on click, either increment numBers (if "numBerValueAfterClick" defined), or call parent method nextPage (if clickGoToNextPage == true)  -->
      <div
        v-if="
          !(
            bottomCornerButtonList.settings.showNextOnLastStep == true &&
            isLastStep
          )
        "
        class="pageBottomCornerButton"
        :class="
          bottomCornerButtonList.settings.showNextOnLastStep == true &&
          isLastStep == true
            ? typeImages['next'].classname
            : typeImages[bottomCornerButtonList.buttons[index].type].classname
        "
      >
        <img
          class="btn-page-corner"
          :class="{
            isHidden: !(
              ((numBers >=
                bottomCornerButtonList.buttons[index].startNumberRange &&
                numBers <=
                  bottomCornerButtonList.buttons[index].endNumberRange) ||
                numBers == undefined) &&
              typeImages[bottomCornerButtonList.buttons[index].type].isHover ==
                false
            ),
          }"
          :src="typeImages[bottomCornerButtonList.buttons[index].type].image"
        />
        <img
          class="btn-page-corner-hover"
          :class="{
            isHidden: !(
              ((numBers >=
                bottomCornerButtonList.buttons[index].startNumberRange &&
                numBers <=
                  bottomCornerButtonList.buttons[index].endNumberRange) ||
                numBers == undefined) &&
              typeImages[bottomCornerButtonList.buttons[index].type].isHover ==
                true
            ),
          }"
          :src="
            typeImages[bottomCornerButtonList.buttons[index].type].imageHover
          "
          alt=""
        />
      </div>
      <!-- if "showNextOnLastStep == true", SHOW Next button when "isLastStep == true" -->
      <div
        :class="[
          !(
            bottomCornerButtonList.settings.showNextOnLastStep == true &&
            isLastStep
          )
            ? 'isHidden'
            : null,
          typeImages['next'].classname,
        ]"
        class="pageBottomCornerButton"
      >
        <img
          class="btn-page-corner"
          :class="{
            isHidden: !(
              isLastStep == true &&
              typeImages[bottomCornerButtonList.buttons[index].type].isHover ==
                false
            ),
          }"
          :src="typeImages['next'].image"
        />
        <img
          class="btn-page-corner"
          :class="{
            isHidden: !(
              isLastStep == true &&
              typeImages[bottomCornerButtonList.buttons[index].type].isHover ==
                true
            ),
          }"
          :src="typeImages['next'].imageHover"
        />
      </div>
    </div>

    <!-- if no button is specified in the list and we only want to show "next" when showNextOnLastStep == true -->
    <div
      v-if="
        (bottomCornerButtonList.buttons.length == 0 ||
          bottomCornerButtonList.buttons == undefined) &&
        bottomCornerButtonList.settings.showNextOnLastStep == true &&
        isLastStep == true
      "
      :class="[
        !(
          bottomCornerButtonList.settings.showNextOnLastStep == true &&
          isLastStep
        )
          ? 'isHidden'
          : null,
        typeImages['next'].classname,
      ]"
      class="pageBottomCornerButton"
    >
      <div class="img-container">
        <img class="btn-page-corner" :src="typeImages['next'].image" />
      </div>
    </div>
    <!-- {{ typeImages[myvar].name }}
	   {{ typeImages.speak.name }} -->
  </div>
</template>

<script>
export default {
  props: ["type", "numBers", "bottomCornerButtonList", "isLastStep"],

  data() {
    return {
      myvar: "speak",
      numBersLocal: this.numBers,
      typeImages: {
        speak: {
          name: "speak",
          image: require("@/assets/img/05-Panda/panda-speak.svg"),
          imageHover: require("@/assets/img/05-Panda/panda-speak.svg"),
          classname: "buttonSpeak",
          isHover: false,
        },
        listen: {
          name: "listen",
          image: require("@/assets/img/05-Panda/panda-listen.svg"),
          imageHover: require("@/assets/img/05-Panda/panda-listen.svg"),
          classname: "buttonListen",
          isHover: false,
        },
        look: {
          name: "look",
          image: require("@/assets/img/05-Panda/panda-look.svg"),
          imageHover: require("@/assets/img/05-Panda/panda-look.svg"),
          classname: "buttonLook",
          isHover: false,
        },
        next: {
          name: "next",
          image: require("@/assets/img/04-Buttons/button-next.svg"),
          imageHover: require("@/assets/img/04-Buttons/button-next-hover.svg"),
          classname: "buttonNext",
          isHover: false,
        },
        play: {
          name: "play",
          image: require("@/assets/img/04-Buttons/button-play.svg"),
          imageHover: require("@/assets/img/04-Buttons/button-play-hover.svg"),
          classname: "buttonPlay",
          isHover: false,
        },
        repeat: {
          name: "repeat",
          image: require("@/assets/img/04-Buttons/button-repeat.svg"),
          imageHover: require("@/assets/img/04-Buttons/button-repeat-hover.svg"),
          classname: "buttonRepeat",
          isHover: false,
        },
        draw: {
          name: "draw",
          image: require("@/assets/img/04-Buttons/button-draw.svg"),
          imageHover: require("@/assets/img/04-Buttons/button-draw-hover.svg"),
          classname: "buttonDraw",
          isHover: false,
        },
      },
    };
  },
  methods: {
    clickHandle(index) {
      this.numBersLocal = index;

      this.updateParent(this.numBersLocal);
    },
    mouseover(index) {
      this.typeImages[index].isHover = true;
    },
    mouseleave(index) {
      this.typeImages[index].isHover = false;
    },
    updateParent(value) {
      this.$emit("updateNumbers", value);
    },

    goToNextPage() {
      this.$emit("clickNext");
    },
    randomClick() {
      console.log("random");
      this.$emit("randomClick");
    },
  },
};
</script>

<style scoped lang="scss">
.pageBottomCornerButton {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  &.buttonSpeak {
    position: absolute;
    z-index: 50;
    // bottom: -2%;
    // right: -0.5%;
    bottom: 0px;
    right: 10px;
    width: 18%;
    // background-color: #fff;
    border-top-left-radius: 58px;
    // border-bottom-left-radius: 58px;
    text-align: center;
    // cursor: pointer;
    img {
      width: 90%;
    }

    // very large screen

    @media screen and (max-width: 2200px) {
      // external screen
    }
    @media screen and (max-width: 1500px) {
      // laptop
    }
    @media screen and (max-width: 1300px) {
      // ipad
      // bottom: -1%;
    }
  }
  &.buttonLook {
    position: absolute;
    z-index: 50;
    bottom: 30px;
    right: 10px;
    width: 18%;
    // background-color: #fff;
    border-top-left-radius: 58px;
    // border-bottom-left-radius: 58px;
    text-align: center;
    // cursor: pointer;
    img {
      width: 90%;
    }
  }
  &.buttonNext {
    position: absolute;
    z-index: 50;
    bottom: 30px;
    right: 10px;
    width: 24%;
    border-top-left-radius: 58px;
    text-align: center;
    cursor: pointer;
    img {
      width: 100%;
      cursor: pointer;
      // margin-bottom: -8px;
    }
  }
  &.buttonPlay {
    position: absolute;
    z-index: 50;
    // bottom: 2.5%;
    bottom: 30px;
    right: 10px;
    width: 22%;
    border-top-left-radius: 58px;
    text-align: center;
    img {
      width: 100%;
      cursor: pointer;
    }
  }
  &.buttonRepeat {
    position: absolute;
    z-index: 50;
    // bottom: 2.5%;
    // right: 0;
    bottom: 30px;
    right: 10px;
    width: 18%;
    border-top-left-radius: 58px;
    text-align: center;
    img {
      width: 100%;
      cursor: pointer;
    }
  }
  &.buttonDraw {
    position: absolute;
    z-index: 50;
    // bottom: 2.5%;
    bottom: 30px;
    right: 10px;
    width: 22%;
    border-top-left-radius: 58px;
    text-align: center;
    img {
      width: 100%;
      cursor: pointer;
    }
  }
}

// some page have a different position relative style around the inner wrapper. We need to adjust the style for those pages
.position-relative {
  .buttonNext {
    bottom: 0px;
    right: 0px;
  }
  .buttonLook {
    bottom: 0px;
    right: 0px;
  }
  .buttonSpeak {
    // bottom: -40px;
    // right: -10px;
    bottom: -30px;
    right: 0px;
  }
}

.write-stroke-page-container {
  .buttonNext {
    .img-container {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
}
</style>
