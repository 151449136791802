<template>
  <div class="game-choose-img">
    <div class="game-choose-content">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="numBers"
        @changeTheNumbers="changeTheNumbers"
      >
      </SceneSwitchThumbnails>

      <div class="choose-img-area">
        <div class="img-area">
          <div class="wenzi">
            <div
              class="trumpet-area-pinyin"
              v-for="(item, index) in allImgList"
              :key="index"
              v-show="currentIndex === index"
            >
              <div>
                <div class="title-pinyin pinyin font-pinyin-medium">
                  {{ item.pinyin }}
                </div>
                <div class="title-hanzi font-hanzi-medium">
                  {{ item.hanzi }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="checkGif"
            :class="{ checkGifWrong: !checkGif }"
            v-checkgif="checkGif"
            v-if="isShowCheckGif"
          ></div>
          <div
            class="img-content"
            v-if="
              allImgList[currentIndex] &&
              allImgList[currentIndex].imgList &&
              allImgList[currentIndex].imgList.length
            "
          >
            <img
              v-for="(item, index) in allImgList[currentIndex].imgList"
              :src="item.bgImg"
              alt=""
              :key="index + 'img'"
              class="img-item"
              :class="{
                isCorrect:
                  item.id === answerList[currentIndex] &&
                  index === chooseImgIndex
              }"
              @click="handleChooseImg(item.id, index)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools.js'
import SceneSwitchThumbnails from '../GameComponent/SceneSwitchThumbnails.vue'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    SceneSwitchThumbnails
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          ChooseImgList: []
        }
      }
    }
  },
  mounted() {
    this.$bus.$on('clickChoosedImg', ({ id, index }) => {
      this.handleChooseImg(id, index, true)
    })
    this.$bus.$on('clickIntroAside', ({ index }) => {
      this.changeTheNumbers(index, true)
    })
    // this.initData()
  },
  beforeDestroy() {
    this.$bus.$off('clickChoosedImg')
    this.$bus.$off('clickIntroAside')
  },
  watch: {
    //
    imgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003001,
          data: { value },
          text: 'ListenThenChooseWordsGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  data() {
    return {
      localData: {
        buttonList: [
          {
            image: require('@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/menu-1.svg'),
            audioSrc: '',
            isCircle: true,
            startNumberRange: 1,
            endNumberRange: 1
          },
          {
            image: require('@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/menu-2.svg'),
            audioSrc: '',
            isCircle: true,
            startNumberRange: 2,
            endNumberRange: 2
          },
          {
            image: require('@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/menu-3.svg'),
            audioSrc: '',
            isCircle: true,
            startNumberRange: 3,
            endNumberRange: 3
          }
        ],
        allImgList: [
          {
            pinyin: 'Māma,qǐng hē shuǐ!',
            hanzi: '妈妈，请喝水！',
            imgList: [
              {
                bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/image-1-1.svg`),
                id: 1,
                color: '#fff'
              },
              {
                bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/image-1-2.svg`),
                id: 2,
                color: '#fff'
              },
              {
                bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/image-1-3.svg`),
                id: 3,
                color: '#fff'
              }
            ]
          },
          {
            pinyin: '-Xièxie nǐ! -Bàba,bú kèqi.',
            hanzi: '-谢谢你！-爸爸，不客气。',
            imgList: [
              {
                bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/image-2-1.svg`),
                id: 4,
                color: '#fff'
              },
              {
                bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/image-2-2.svg`),
                id: 5,
                color: '#fff'
              },
              {
                bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/image-2-3.svg`),
                id: 6,
                color: '#fff'
              }
            ]
          },
          {
            pinyin: 'Wǒ kěyǐ chī dàngāo ma？ ',
            hanzi: '我可以吃蛋糕吗？',
            imgList: [
              {
                bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/image-3-1.svg`),
                id: 7,
                color: '#fff'
              },
              {
                bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/image-3-2.svg`),
                id: 8,
                color: '#fff'
              },
              {
                bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/image-3-3.svg`),
                id: 9,
                color: '#fff'
              }
            ]
          }
        ],
        answerList: [2, 5, 9],
        numBers: 0
      },
      buttonList: [],
      allImgList: [],
      answerList: [],
      checkGif: true,
      isShowCheckGif: false,
      isStartPlaying: null,
      // lockIndex: 0,
      // insertIndex: 0,
      chooseImgIndex: null,
      numBers: 0,
      imgSocketInfo: {},
      // songUrl: this.buttonList[0].audioSrc,
      currentIndex: 0,
      currentStarIndex: 0,
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'look',
            startNumberRange: 0,
            endNumberRange: 100
          }
        ]
      }
    }
  },
  methods: {
    initData() {
      this.allImgList = []
      const imgList = []
      this.buttonList = []
      this.answerList = []
      if (
        this.componentProps.ChooseImgList &&
        this.componentProps.ChooseImgList.length
      ) {
        this.componentProps.ChooseImgList.forEach((item, index) => {
          imgList.push(...item[2].value)
          this.allImgList.push({
            pinyin: item[0].value,
            hanzi: item[1].value,
            imgList:
              item[2].value && item[2].value.length
                ? item[2].value.map((ele, eleIndex) => {
                    return {
                      bgImg: ele.value,
                      id: eleIndex + 1,
                      color: '#fff'
                    }
                  })
                : [],
            answerImg: item[3].value
          })
          this.buttonList.push({
            image: item[4].value,
            isCircle: true,
            startNumberRange: index + 1,
            endNumberRange: index + 1
          })
        })
        this.allImgList.forEach((item) => {
          if (item.imgList && item.imgList.length) {
            item.imgList.forEach((ele) => {
              ele.id = imgList.findIndex((i) => i.value === ele.bgImg) + 1
            })
          }
        })
        this.answerList = this.allImgList.map(
          (item) => imgList.findIndex((i) => i.value === item.answerImg) + 1
        )
      }
    },
    changeTheNumbers(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random()
        }
      }
      this.numBers = index
      this.currentIndex = index - 1
      this.chooseImgIndex = null
    },
    handleChooseImg(id, index, isFromSocket = false) {
      if (!isFromSocket) {
        this.imgSocketInfo = {
          id,
          index,
          value: Math.random()
        }
      }
      this.chooseImgIndex = index
      if (id === this.answerList[this.currentIndex]) {
        this.isShowCheckGif = true
        this.checkGif = true
        playCorrectSound(true, false)
        setTimeout(() => {
          this.isShowCheckGif = false
        }, 500)
        if (this.currentIndex === this.buttonList.length - 1) {
          startConfetti()
          playCorrectSound()
          setTimeout(() => {
            this.isLastStep = true
          }, 500)
        }
      } else {
        this.isShowCheckGif = true
        this.checkGif = false
        playCorrectSound(false)
        setTimeout(() => {
          this.isShowCheckGif = false
        }, 500)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.game-choose-img {
  width: 100%;
  height: 100%;
  .game-choose-content {
    width: 100%;
    height: 100%;
    position: relative;
    background: url('../../../../assets/img/16-GAMES/G65-stories-3-cards/level1-background.svg');
    border-radius: 58px;
    .choose-img-area {
      height: 80%;
      display: flex;
      justify-content: center;
      align-items: center;

      .img-area {
        margin-top: 10%;
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #f7f4e0;
        border-radius: 58px;

        .wenzi {
          background: #224e96;
          border-radius: 58px 58px 0 0;
          width: 100%;
          height: 160px;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          // position: relative;
          .trumpet-area {
            width: 100px;
            height: 100px;
            background-color: #cd4c3f;
            border-radius: 50%;
            &:hover {
              opacity: 0.9;
              cursor: pointer;
            }
          }
          .trumpet-area-pinyin {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            margin-left: 8%;
          }
          .wenzi-content {
            margin-left: 70px;
            .hanzi {
              font-size: 32px;
            }
          }
        }
        .img-content {
          width: 70%;
          margin-top: 8%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          .img-item {
            width: 32%;
            height: 40%;
            min-height: 125px;
            object-fit: cover;
            margin-right: 2%;
            margin-bottom: 2%;
            display: inline-block;
            box-sizing: border-box;
            border: 10px solid #fff;
            cursor: pointer;
            &:active {
              border-color: #cd4c3f;
            }
          }
          .isCorrect {
            border-color: yellow;
          }
        }
      }
    }
  }
}
</style>
