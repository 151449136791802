var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "write-stroke-page-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: {
          clickNext: _vm.nextPage,
          updateNumbers: function ($event) {
            return _vm.updateNumbers($event)
          },
        },
        nativeOn: {
          click: function ($event) {
            return _vm.startDrawStroke.apply(null, arguments)
          },
        },
      }),
      _c("div", { staticClass: "stroke-page-box" }, [
        _vm.isShowEye
          ? _c(
              "div",
              {
                staticClass: "write-stroke-page-eye-btn",
                on: {
                  mouseover: function ($event) {
                    _vm.eyeIsHover = true
                  },
                  mouseleave: function ($event) {
                    _vm.eyeIsHover = false
                  },
                  click: _vm.handleClickEyeButton,
                },
              },
              [
                !_vm.eyeIsHover
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/16-GAMES/G19-writing-strokes/button-eye-on.svg"),
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@/assets/img/16-GAMES/G19-writing-strokes/button-eye-on-hover.svg"),
                        alt: "",
                      },
                    }),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "write-stroke-content" },
          [
            _c("transition", { attrs: { name: "el-fade-in" } }, [
              _vm.isTipPage
                ? _c("div", { staticClass: "stroke-gif-area" }, [
                    _c("div", { staticClass: "write-stroke-content-left" }, [
                      _c("div", { staticClass: "write-stroke-left-content" }, [
                        _c("div", { staticClass: "left-content-border" }),
                        _c("div", { staticClass: "left-content" }, [
                          _c(
                            "span",
                            { staticClass: "pinyin font-pinyin-large" },
                            [_vm._v(_vm._s(_vm.flagImageObj.pinyin))]
                          ),
                          _c("div", { staticClass: "stroke-area" }, [
                            _c("div", { staticClass: "hanzi-img" }, [
                              _c("img", {
                                attrs: {
                                  src: _vm.flagImageObj.tipImg,
                                  alt: "",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c("transition", { attrs: { name: "el-fade-in" } }, [
              !_vm.isTipPage
                ? _c("div", { staticClass: "write-stroke-area" }, [
                    _c("div", { staticClass: "write-stroke-content-left" }, [
                      _c("div", { staticClass: "write-stroke-left-content" }, [
                        _c("div", { staticClass: "left-content-border" }),
                        _c("div", { staticClass: "left-content" }, [
                          _c(
                            "div",
                            { staticClass: "left-content-top" },
                            _vm._l(3, function (item, index) {
                              return _c("img", {
                                key: index + "flag",
                                attrs: {
                                  src:
                                    _vm.steps <= index
                                      ? _vm.flagImageObj.img
                                      : _vm.flagImageObj.imgActive,
                                  alt: "",
                                },
                              })
                            }),
                            0
                          ),
                          _c(
                            "span",
                            { staticClass: "pinyin font-pinyin-large" },
                            [_vm._v(_vm._s(_vm.flagImageObj.pinyin))]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "stroke-area",
                              on: {
                                mousedown: function ($event) {
                                  return _vm.startDrawing()
                                },
                                mouseup: function ($event) {
                                  return _vm.endDrawing($event)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "hanzi-img" },
                                [
                                  _c("KidsWhiteBoard", { ref: "drawArea" }),
                                  _c("div", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.drawing,
                                        expression: "drawing",
                                      },
                                    ],
                                    staticClass: "WritingStrokeArea",
                                    on: { click: _vm.draggedOutOfStroke },
                                  }),
                                  _c("div", { staticClass: "square" }, [
                                    _c("img", {
                                      staticClass: "stroke-square-img",
                                      attrs: {
                                        src: require("@/assets/img/15-Hanzi-strokes/background.svg"),
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "hengIcon",
                                      class: {
                                        iconLarge:
                                          _vm.lesson == 12 || _vm.lesson == 14,
                                      },
                                    },
                                    [
                                      !_vm.showStroke
                                        ? _c("img", {
                                            staticClass: "stroke-square-img",
                                            class: {
                                              shu: _vm.strokePinyin === "shu",
                                            },
                                            attrs: {
                                              src: _vm.strokeImage,
                                              alt: "",
                                            },
                                          })
                                        : _c("img", {
                                            staticClass: "stroke-square-img",
                                            class: {
                                              shu: _vm.strokePinyin === "shu",
                                            },
                                            attrs: {
                                              src: _vm.strokeImgFill,
                                              alt: "",
                                            },
                                          }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "write-stroke-content-right" }, [
                      _c(
                        "div",
                        { staticClass: "write-stroke-content-right_img" },
                        [
                          _vm._l(
                            _vm.backgroundImageList,
                            function (item, index) {
                              return _c("div", {
                                key: index + "bg",
                                staticClass: "item",
                              })
                            }
                          ),
                          _c(
                            "div",
                            { staticClass: "steps-img-box" },
                            [
                              _vm._l(
                                _vm.backgroundImageList,
                                function (item, index) {
                                  return [
                                    _c("img", {
                                      key: index,
                                      staticClass: "cloud",
                                      class: {
                                        fadeOut:
                                          _vm.isFadeOut && index !== _vm.steps,
                                        fadeIn:
                                          _vm.isFadeIn && index === _vm.steps,
                                        isHidden: index > _vm.steps,
                                      },
                                      attrs: { src: item.img },
                                    }),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        2
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }