<template>
  <div class="game-card-container">
    <div class="card-content">
      <audio :src="songUrl" ref="audioBox"></audio>

      <div class="recogniton-page-content">
        <Title :titleInfo="titleInfo" />

        <!-- 左边图片 left image -->
        <div class="recognition-content-left" @click="changeImg()">
          <img
            v-if="!showImg"
            class="santa-claus"
            :src="backgroundImage"
            alt=""
          />
          <img v-else class="santa-claus" :src="changeBgImg" alt="" />
        </div>
        <!-- 右边文字 right words-->
        <div class="recogniton-content-right">
          <div class="recognize_container_content_right_border">
            <div class="recognize_container_content_right_content">
              <div
                class="recognize_container_content_right_content_top volume-button"
                :class="{ 'teacher-hover': isClickAudio }"
              >
                <div @click.stop="activeClick('socket')">
                  <AudioPlayer :isPlay="isStartPlaying" :audioUrl="songUrl" />
                </div>
              </div>
              <div
                class="redColor"
                v-if="wordObj.pinName != '' && !wordObj.toneContent"
              >
                <p class="pinyin font-pinyin-xlarge">
                  {{ wordObj.pinName }}
                </p>
                <p id="face" class="font-hanzi-xlarge">
                  {{ wordObj.chineseName }}
                </p>
              </div>

              <div
                v-if="wordObj.pinName == '' && !wordObj.toneContent"
                class="englishName"
              ></div>
              <div class="btn" v-if="wordObj.pinyin != '' || wordObj.hanzi != ''" @click="btnClick()">
                <p class="btnPinyin">{{ wordObj.pinyin }}</p>
                <p class="btnHanzi">{{ wordObj.hanzi }}</p>
              </div>
              <div
                v-if="wordObj.toneContent && wordObj.toneContent.length > 0"
                class="tone_content"
              >
                <p
                  class="pinyin"
                  :class="[
                    pySize == 'xlarge'
                      ? 'font-hanzi-xlarge'
                      : 'font-hanzi-large'
                  ]"
                  v-for="(item, index) in wordObj.toneContent"
                  :key="index"
                >
                  {{ item }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-show="isShowMask"
        @click="mask"
        :class="[isMask ? 'mask-area fadeIn' : 'mask-area fadeOut']"
      >
        <div class="mask-item">
          <div class="mask-img">
            <img :src="maskImg" alt="" v-show="maskImg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/Course/CoursePage/Title'
import AudioPlayer from '@/components/Course/GamePage/AudioPlayer'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    Title,
    AudioPlayer
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          changeBgImg: '',
          bgImg: '',
          titlePy: '',
          titleZh: '',
          cardPy: '',
          cardZh: '',
          cardAudio: '',
          wordPy: '',
          wordZh: '',
          wordImg: ''
        }
      }
    }
  },
  data() {
    return {
      titleInfo: {},
      changeBgImg: require('@/assets/img/16-GAMES/G67-hanzi-card/lesson-02-zuo-image-2.svg'),
      wordObj: {},
      maskImg: require('@/assets/img/16-GAMES/G67-hanzi-card/lesson-02-zuo-qingzuo.svg'),
      backgroundImage: require('@/assets/img/16-GAMES/G67-hanzi-card/lesson-02-zuo-image-1.svg'),
      audioSocketInfo: {},
      clickImgSocketInfo: {},
      clickImgSocketInfo2: {},
      clickImgSocketInfo3: {},
      songUrl: null,
      isClickAudio: false,
      clickNum: 0,
      showImg: false,
      isHover: false,
      isShowMask: false,
      // clickedVolumePlay: 0,
      isStartPlaying: null,
      isLastStep: true,
      isMask: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'next',
            startNumberRange: 1,
            endNumberRange: 1,
            clickGoToNextPage: true
          }
        ]
      },
      localData: {
        titleInfo: {
          pinyin: 'Yǒuqù de Hànzì',
          hanzi: '有趣的汉字',
          bgColor: 'redColor'
        },
        wordObj: {
          pinName: 'zuò',
          chineseName: '坐',
          pinyin: 'zuòdiàn',
          hanzi: '坐垫',
          songUrl: ''
        }
      }
    }
  },
  watch: {
    // 发送
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20002,
          data: { value },
          text: '测试RecognitionPage组件内点击事件socket'
        }
        this.$bus.$emit('kids_webSocket_sendInfo', data)
      },
      deep: true
    },
    clickImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004602,
          data: { value },
          text: 'SpeakingSentence点击Img socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    clickImgSocketInfo2: {
      handler(value) {
        const data = {
          clickType: 20044,
          data: { value },
          text: 'SpeakingSentence点击Img socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    clickImgSocketInfo3: {
      handler(value) {
        const data = {
          clickType: 2005101,
          data: { value },
          text: 'SpeakingSentence点击Img socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        this.initData()
      },
      deep: true
    }
  },
  mounted() {
    // this.initData()
    this.$bus.$on('clickRecognitionPage', () => {
      this.activeClick('socket', true)
    })
    this.$bus.$on('hoverRecognitionPage', (val) => {
      this.isClickAudio = !this.isClickAudio
    })
    this.$bus.$on('clickSpeakingSentenceImg', () => {
      this.changeImg('socket', true)
    })
    this.$bus.$on('ClickThePeople', () => {
      this.btnClick('socket', true)
    })
    this.$bus.$on('ClickTheScreen', () => {
      this.mask('socket', true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickRecognitionPage')
    this.$bus.$off('clickSpeakingSentenceImg')
    this.$bus.$off('ClickThePeople')
    this.$bus.$off('ClickTheScreen')
  },
  methods: {
    initData() {
      // console.log(this.componentProps, '---------')
      this.backgroundImage = this.componentProps.bgImg
      this.changeBgImg = this.componentProps.changeBgImg
      this.titleInfo = {
        pinyin: this.componentProps.titlePy,
        hanzi: this.componentProps.titleZh
      }
      this.wordObj = {
        pinName: this.componentProps.cardPy,
        chineseName: this.componentProps.cardZh,
        pinyin: this.componentProps.wordPy,
        hanzi: this.componentProps.wordZh,
        songUrl: this.componentProps.cardAudio
      }
      this.maskImg = this.componentProps.wordImg
    },
    mask(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickImgSocketInfo3 = {
          value: Math.random()
        }
      }
      this.isMask = false
      setTimeout(() => {
        this.isShowMask = false
      }, 1000)
    },
    changeImg(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickImgSocketInfo = {
          value: Math.random()
        }
      }
      this.showImg = !this.showImg
    },
    syncPlayAudio(arr) {
      function tone1() {
        return new Promise((resolve) => {
          setTimeout(() => {
            const audio = new Audio(arr[0])
            audio.play()
            resolve()
          }, 500)
        })
      }

      function tone2() {
        return new Promise((resolve) => {
          setTimeout(() => {
            const audio = new Audio(arr[1])
            audio.play()
            resolve()
          }, 1000)
        })
      }
      function tone3() {
        return new Promise((resolve) => {
          setTimeout(() => {
            const audio = new Audio(arr[2])
            audio.play()
            resolve()
          }, 1200)
        })
      }

      function tone4() {
        return new Promise((resolve) => {
          setTimeout(() => {
            const audio = new Audio(arr[3])
            audio.play()
            resolve()
          }, 1300)
        })
      }
      async function allTasks() {
        await tone1()
        await tone2()
        await tone3()
        await tone4()
      }
      allTasks()
    },
    btnClick(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickImgSocketInfo2 = {
          value: Math.random()
        }
      }
      this.isShowMask = true
      this.isMask = true
    },
    activeClick(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.audioSocketInfo = {
          value: Math.random()
        }
      }
      this.isStartPlaying = Math.random()

      if (this.isTonePage) {
        // console.log("+++++++++++");
        this.syncPlayAudio(this.wordObj.songUrlList)
      } else {
        this.songUrl = this.wordObj.songUrl
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.game-card-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .card-content {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 58px;
    z-index: 2;
    position: relative;
    .recogniton-page-content {
      background: #f7f4e0;
      border-radius: 58px;
      position: relative;
      z-index: 3;
      width: 100%;
      height: 100%;

      .recognition-content-left {
        width: 50%;
        height: 100%;
        position: absolute;
        img {
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 20%;
          width: 80%;
        }
        .santa-claus {
          // top: 23%;
          top: 32%;
        }
      }
      .recogniton-content-right {
        position: absolute;
        width: 50%;
        height: 100%;
        right: 0;
        .recognize_container_content_right_border {
          margin-top: 10px;
          margin-left: 10px;
          width: calc(70% - 10px);
          height: calc(60% - 10px);
          position: absolute;
          left: 8%;
          top: 13%;
          background: #ce4c3f;
          border-radius: 40px;
          padding: 20px 10px;
          .recognize_container_content_right_content {
            color: #000;
            text-align: center;
            position: absolute;
            width: 100%;
            height: 100%;
            background: #fff;
            border-radius: 40px;
            top: -10px;
            left: -10px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            cursor: default;

            .englishName {
              max-width: 70%;
            }
            .btn {
              height: 80px;
              width: 120px;
              background-color: #224e96;
              border-radius: 12px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              color: white;
              cursor: pointer;

              .btnHanzi {
                font-size: 1.5em;
              }
              .btnPinyin {
                font-size: 0.9em;
              }
            }
            .btn:hover {
              opacity: 0.9;
            }
            .font-hanzi-large {
              font-size: 2.5em;
              margin-bottom: 30px;
            }
            .recognize_container_content_right_content_top {
              width: 50px;
              height: 50px;
              background: #ce4c3f;
              border-radius: 50%;

              &.volume-button {
                cursor: pointer;
              }
              div {
                width: 100%;
                height: 100%;
              }
            }
            .recognize_container_content_right_content_top:hover {
              width: 50px;
              height: 50px;
              background: #b5392c;
              border-radius: 50%;
              img {
                border-radius: 50%;
                background: #b5392c;
                width: 100%;
              }
            }
            .tone_content {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              width: 50%;
              p {
                width: 50%;
                margin-bottom: 8px;
              }
            }
          }
        }
      }
    }
    .mask-area {
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 48px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .mask-item {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        .mask-img {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
            border-radius: 58px;
          }
        }
      }
    }
  }
}
</style>
