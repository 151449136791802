<template>
  <div class="find-stroke-conteiner">
    <div class="right-text" v-if="titleInfo && Object.keys(titleInfo).length">
      <span class="font-text txt-py font-pinyin-medium">{{
        titleInfo.pinyin
      }}</span>
      <span class="txt-hz font-hanzi-medium">{{ titleInfo.hanzi }}</span>
    </div>

    <div class="background-stroke" :class="'game-' + gameIndex">
      <div class="backgroundImg_n_Clicks">
        <div
          style="position: relative"
          :style="[backgroundImgIsFullWidth == false ? { height: '100%' } : '']"
        >
          <div class="strokesFoundBoxes">
            <div
              class="outerBox"
              v-for="(item, index) in strokeImgList.length"
              :key="index + 'box'"
            >
              <div
                v-if="answersfound > index && strockNotFoundImage"
                class="innerBoxFound"
              >
                <img class="strokesFoundimage" :src="strockFoundImage" />
                <div class="star" v-if="index === 0">
                  <img
                    :class="{ animatedstar: answersfound }"
                    src="@/assets/img/07-Stars/star-big.svg"
                    alt=""
                  />
                </div>
              </div>
              <div
                v-if="answersfound <= index && strockNotFoundImage"
                class="innerBox"
              >
                <img class="strokesFoundimage" :src="strockNotFoundImage" />
              </div>
            </div>
          </div>
          <img id="puzzlebackground" :src="bgImg" />

          <div
            v-for="(item, index) in strokeImgList.length"
            :key="index + 'ref'"
            :ref="'S' + item"
            class="stroke"
            :class="'stroke' + item"
            @click="foundAnswer(`S${item}`)"
          />
          <div
            class="images"
            v-for="(item, index) in storkeNotFindList"
            :key="index + 'stroke'"
          >
            <img :src="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          titlePy: '',
          titleZh: null,
          strokeImgList: [],
          bgImg: '',
          inittrokeImg: '',
          strokeChooseImg: '',
          modelType: null
        }
      }
    }
  },
  computed: {
    storkeNotFindList() {
      return this.strokeImgList.map((item) => item.notFind)
    }
  },
  data() {
    return {
      localData: {
        bgImg: require('@/assets/img/16-GAMES/G15-find-strokes/lesson-02-1/living-room.svg'),
        strockFoundImage:
          'http://test.nihaokids.com/img/stroke-heng-found.41661d2e.svg',
        strockNotFoundImage:
          'http://test.nihaokids.com/img/stroke-heng.20a50ea2.svg',
        strokeImgList: [
          {
            notFind: require('@/assets/img/16-GAMES/G15-find-strokes/lesson-02-1/stroke-sofa.svg'),
            find: require('@/assets/img/16-GAMES/G15-find-strokes/lesson-02-1/sofa-stroke-found.svg')
          },
          {
            notFind: require('@/assets/img/16-GAMES/G15-find-strokes/lesson-02-1/stroke-table.svg'),
            find: require('@/assets/img/16-GAMES/G15-find-strokes/lesson-02-1/stroke-table-found.svg')
          },
          {
            notFind: require('@/assets/img/16-GAMES/G15-find-strokes/lesson-02-1/stroke-plant.svg'),
            find: require('@/assets/img/16-GAMES/G15-find-strokes/lesson-02-1/stroke-plant-found.svg')
          },
          {
            notFind: require('@/assets/img/16-GAMES/G15-find-strokes/lesson-02-1/stroke-picture.svg'),
            find: require('@/assets/img/16-GAMES/G15-find-strokes/lesson-02-1/stroke-picture-found.svg')
          },
          {
            notFind: require('@/assets/img/16-GAMES/G15-find-strokes/lesson-02-1/stroke-frame.svg'),
            find: require('@/assets/img/16-GAMES/G15-find-strokes/lesson-02-1/stroke-frame-found.svg')
          }
        ]
      },
      gameIndex: 1,
      titleInfo: {},
      bgImg: '',
      strockFoundImage: '',
      strockNotFoundImage: '',
      strokeImgList: [],
      fnum: 0,
      fRef: '',
      answersfound: 0,

      findStrokeInfo: {},
      success_audio_sound: require('@/assets/audio/correct_answer.mp3'),
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'look',
            startNumberRange: 1,
            endNumberRange: 1
          }
        ]
      },
      backgroundImgIsFullWidth: false
    }
  },
  watch: {
    fnum: {
      handler(value) {
        const data = {
          clickType: 20012,
          data: { value: this.findStrokeInfo },
          text: 'FindStrokeGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  mounted() {
    this.$bus.$on('clickPracticePageOne', (ref) => {
      this.foundAnswer(ref, 1)
    })
    this.$bus.$on('clickRightStroke', ({ ref, type }) => {
      this.foundAnswer(ref, 1, type)
    })
    window.addEventListener('resize', this.updateElementPosition)
  },
  destroyed() {
    window.removeEventListener('resize', this.updateElementPosition)
  },
  beforeDestroy() {
    this.$bus.$off('clickPracticePageOne')
    this.$bus.$off('clickRightStroke')
  },
  methods: {
    initData() {
      // console.log(this.componentProps,'0000')
      this.strockNotFoundImage = this.componentProps.inittrokeImg
      this.strockFoundImage = this.componentProps.strokeChooseImg
      this.bgImg = this.componentProps.bgImg
      this.titleInfo = {
        pinyin: this.componentProps.titlePy,
        hanzi: this.componentProps.titleZh
      }
      this.gameIndex = this.componentProps.modelType
      this.strokeImgList = this.componentProps.strokeImgList.map((item) => {
        return {
          notFind: item[0].value,
          find: item[1].value
        }
      })
    },
    foundAnswer(ref, type, stype) {
      if (type != 1) {
        this.fnum++
      }
      this.fRef = ref
      this.answersfound = this.answersfound + 1
      const index = parseInt(ref.split('')[1]) - 1

      this.storkeNotFindList[index] = this.strokeImgList[index].find
      this.$refs[ref][0].style.pointerEvents = 'none'
      if (this.answersfound == this.strokeImgList.length) {
        startConfetti()
        playCorrectSound()
        this.isLastStep = true
      } else {
        playCorrectSound(true, false)
      }
      this.findStrokeInfo = { ref, stype }
    },
    updateElementPosition() {
      // check if the background image width = parent container width
      let pictureWidth = document
        .getElementById('puzzlebackground')
        .getBoundingClientRect().width
      let parentContainerWidth = document
        .getElementsByClassName('background')[0]
        .getBoundingClientRect().width

      let pictureHeight = document
        .getElementById('puzzlebackground')
        .getBoundingClientRect().height
      let parentContainerHeight = document
        .getElementsByClassName('background')[0]
        .getBoundingClientRect().height

      if (pictureHeight > parentContainerHeight) {
        this.backgroundImgIsFullWidth = false
      } else if (pictureWidth < parentContainerWidth) {
        this.backgroundImgIsFullWidth = false
      } else {
        this.backgroundImgIsFullWidth = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.find-stroke-conteiner {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 58px;
  position: relative;
  overflow: hidden;
  .right-text {
    background-color: #cd4c3f;
    border-top-right-radius: 29px;
    border-bottom-right-radius: 29px;
    position: absolute;
    top: 6%;
    left: 0;
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    flex-flow: column;
    z-index: 9;
    width: auto;
    height: auto;
    padding: 3%;
  }
  .background-stroke {
    text-align: -webkit-center;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #f7f4e0;
    border-radius: 58px;
    background: #f7f4e0;
    border-radius: 58px;
    margin: auto;
    overflow: hidden;
    .backgroundImg_n_Clicks {
      position: relative;
      width: fit-content;
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      img {
        position: relative;
        max-width: 100%;

        height: 100%;
        text-align: -webkit-center;
        overflow: hidden;
      }
    }
    .images {
      position: absolute;
      height: 100%;
      top: 0;
      width: 100%;
      border-radius: 10%;
      img {
        position: relative;
        max-width: 100%;

        // height: 100%;
        text-align: -webkit-center;
      }
    }

    .strokesFoundBoxes {
      position: absolute;
      bottom: 5%;
      height: 100px;
      width: 100%;
      margin-left: 0;
      //background-color: yellow;
      display: flex;
      flex-direction: row;
      z-index: 2;
      justify-content: center;
      .outerBox {
        margin: 0 5px;
        .innerBox,
        .innerBoxFound {
          width: 80%;
          height: 86%;
          /* margin: 5%; */
          border-radius: 24px;
          background-color: rgb(47, 94, 168);
          display: flex;
          justify-content: center;
          align-items: center;
          border: 8px solid #fff;

          .strokesFoundimage {
            width: 80%;
            padding: 0 8px;
          }
        }

        .innerBoxFound {
          background-color: #f3d438;
        }
      }
    }
    .stroke {
      display: block;
      position: absolute;
      z-index: 5;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.stroke {
  display: block;
  position: absolute;
  // background-color: black;
  z-index: 5;
  &:hover {
    cursor: pointer;
  }
  background-color: rgba(241, 241, 240, 0.5);
}
.game-1 {
  .stroke1 {
    width: 15%;
    height: 25%;
    top: 58%;
    left: 35%;
  }
  .stroke2 {
    width: 15%;
    height: 25%;
    top: 63%;
    left: 9%;
  }

  .stroke3 {
    width: 15%;
    height: 25%;
    top: 60%;
    left: 71%;
  }
  .stroke4 {
    width: 15%;
    height: 25%;
    top: 23%;
    left: 13%;
  }
  .stroke5 {
    width: 15%;
    height: 25%;
    top: 14%;
    left: 73%;
  }
}
.game-2 {
  .stroke1 {
    width: 15%;
    height: 25%;
    top: 60%;
    left: 58%;
  }
  .stroke2 {
    width: 15%;
    height: 25%;
    top: 47%;
    left: 40%;
  }

  .stroke3 {
    width: 15%;
    height: 25%;
    top: 4%;
    left: 74%;
  }
  .stroke4 {
    width: 15%;
    height: 25%;
    top: 13%;
    left: 7%;
  }
  .stroke5 {
    width: 15%;
    height: 25%;
    top: 13%;
    left: 90%;
  }
}
.game-3 {
  .stroke1 {
    width: 15%;
    height: 25%;
    top: 46%;
    left: 34%;
  }
  .stroke2 {
    width: 15%;
    height: 25%;
    top: 22%;
    left: 87%;
  }

  .stroke3 {
    width: 15%;
    height: 25%;
    top: 59%;
    left: 3%;
  }
  .stroke4 {
    width: 15%;
    height: 25%;
    top: 38%;
    left: 65%;
  }
  .stroke5 {
    width: 15%;
    height: 25%;
    top: 4%;
    left: 61%;
  }
}
// .game-4 {
//   .stroke5 {
//     width: 8%;
//     height: 12%;
//     top: 37%;
//     left: 25%;
//   }
//   .stroke1 {
//     width: 12%;
//     height: 11%;
//     top: 22%;
//     left: 81%;
//   }

//   .stroke2 {
//     width: 9%;
//     height: 14%;
//     top: 43%;
//     left: 11%;
//   }
//   .stroke4 {
//     width: 7%;
//     height: 9%;
//     top: 57%;
//     left: 49%;
//   }
//   .stroke3 {
//     width: 17%;
//     height: 28%;
//     top: 0%;
//     left: 1%;
//   }
// }
// .game-5 {
//   .stroke1 {
//     // background: #000;
//     width: 2%;
//     height: 10%;
//     top: 25%;
//     left: 90.5%;
//     transform: rotate(-35deg);
//   }
//   .stroke2 {
//     // background: #000;
//     width: 2%;
//     height: 13%;
//     top: 19%;
//     left: 23%;
//     transform: rotate(-35deg);
//   }

//   .stroke3 {
//     // background: #000;
//     width: 3%;
//     height: 19%;
//     top: 2%;
//     left: 48%;
//     transform: rotate(-35deg);
//   }
//   .stroke4 {
//     // background: #000;
//     width: 3%;
//     height: 17%;
//     top: 66%;
//     left: 79%;
//     transform: rotate(-35deg);
//   }
//   .stroke5 {
//     // background: #000;
//     width: 2%;
//     height: 11%;
//     top: 59%;
//     left: 7%;
//     transform: rotate(-35deg);
//   }
// }
// .game-6 {
//   .stroke1 {
//     width: 2%;
//     height: 6%;
//     top: 52%;
//     left: 5%;
//     transform: rotate(-22deg);
//   }
//   .stroke2 {
//     width: 5%;
//     height: 12%;
//     top: 55%;
//     left: 23%;
//     transform: rotate(-51deg);
//   }

//   .stroke3 {
//     width: 2%;
//     height: 4%;
//     top: 57%;
//     left: 51%;
//     transform: rotate(-53deg);
//   }
//   .stroke4 {
//     width: 2%;
//     height: 4%;
//     top: 59%;
//     left: 13%;
//     transform: rotate(-35deg);
//   }
//   .stroke5 {
//     width: 2.5%;
//     height: 7%;
//     top: 14%;
//     left: 91.5%;
//     transform: rotate(-35deg);
//   }
// }
// .game-7 {
//   .stroke1 {
//     width: 3%;
//     height: 6%;
//     top: 32%;
//     left: 86%;
//     transform: rotate(40deg);
//   }
//   .stroke2 {
//     width: 7%;
//     height: 18%;
//     top: 52%;
//     left: 75%;
//     transform: rotate(23deg);
//   }

//   .stroke3 {
//     width: 4%;
//     height: 12%;
//     top: 32%;
//     left: 49%;
//     transform: rotate(32deg);
//   }
//   .stroke4 {
//     width: 3%;
//     height: 9%;
//     top: 61%;
//     left: 27%;
//     transform: rotate(59deg);
//   }
//   .stroke5 {
//     width: 4%;
//     height: 11%;
//     top: 44%;
//     left: 9%;
//     transform: rotate(45deg);
//   }
// }
// .game-8 {
//   .stroke1 {
//     width: 3%;
//     height: 6%;
//     top: 67%;
//     left: 39%;
//   }
//   .stroke2 {
//     width: 7%;
//     height: 16%;
//     top: 26%;
//     left: 21%;
//   }

//   .stroke3 {
//     width: 5%;
//     height: 9%;
//     top: 60%;
//     left: 82%;
//   }
//   .stroke4 {
//     width: 7%;
//     height: 18%;
//     top: 6%;
//     left: 52%;
//   }
//   .stroke5 {
//     width: 3%;
//     height: 7%;
//     top: 45%;
//     left: 88%;
//   }
// }
// .game-9 {
//   .stroke1 {
//     width: 3%;
//     height: 6%;
//     top: 43%;
//     left: 47%;
//   }
//   .stroke2 {
//     width: 6%;
//     height: 13%;
//     top: 30%;
//     left: 13%;
//   }

//   .stroke3 {
//     width: 8%;
//     height: 17%;
//     top: 56%;
//     left: 65%;
//   }
//   .stroke4 {
//     width: 4%;
//     height: 10%;
//     top: 13%;
//     left: 26%;
//   }
//   .stroke5 {
//     width: 8%;
//     height: 18%;
//     top: 24%;
//     left: 73%;
//   }
// }
// .game-10 {
//   .stroke1 {
//     width: 5%;
//     height: 10%;
//     top: 65%;
//     left: 85%;
//   }
//   .stroke2 {
//     width: 6%;
//     height: 13%;
//     top: 63%;
//     left: 14%;
//   }

//   .stroke3 {
//     width: 5%;
//     height: 10%;
//     top: 60%;
//     left: 67%;
//   }
//   .stroke4 {
//     width: 5%;
//     height: 10%;
//     top: 13%;
//     left: 12%;
//   }
//   .stroke5 {
//     width: 11%;
//     height: 11%;
//     top: 65%;
//     left: 47%;
//   }
// }
// .game-11 {
//   .stroke1 {
//     width: 6%;
//     height: 6%;
//     top: 52%;
//     left: 29%;
//   }
//   .stroke2 {
//     width: 9%;
//     height: 10%;
//     top: 45%;
//     left: 47%;
//   }

//   .stroke3 {
//     width: 7%;
//     height: 9%;
//     top: 40%;
//     left: 87%;
//   }
//   .stroke4 {
//     width: 8%;
//     height: 11%;
//     top: 23%;
//     left: 5%;
//   }
//   .stroke5 {
//     width: 8%;
//     height: 10%;
//     top: 51%;
//     left: 7%;
//   }
// }
// .game-12 {
//   .stroke1 {
//     width: 20%;
//     height: 9%;
//     top: 66%;
//     left: 22%;
//   }
//   .stroke2 {
//     width: 9%;
//     height: 5%;
//     top: 14%;
//     left: 44%;
//   }

//   .stroke3 {
//     width: 13%;
//     height: 8%;
//     top: 43%;
//     left: 32%;
//   }
//   .stroke4 {
//     width: 12%;
//     height: 8%;
//     top: 60%;
//     left: 4%;
//   }
//   .stroke5 {
//     width: 12%;
//     height: 8%;
//     top: 56%;
//     left: 81%;
//   }
// }
// .game-25 {
//   .stroke1 {
//     width: 17%;
//     height: 9%;
//     top: 65%;
//     left: 62%;
//   }
//   .stroke2 {
//     width: 15%;
//     height: 18%;
//     top: 59%;
//     left: 10%;
//   }

//   .stroke3 {
//     width: 8%;
//     height: 6%;
//     top: 34%;
//     left: 10%;
//   }
//   .stroke4 {
//     width: 12%;
//     height: 12%;
//     top: 51%;
//     left: 38%;
//   }
//   .stroke5 {
//     width: 11%;
//     height: 8%;
//     top: 47%;
//     left: 61%;
//   }
// }
// .game-26 {
//   .stroke1 {
//     width: 5%;
//     height: 8%;
//     top: 40%;
//     left: 28%;
//   }
//   .stroke2 {
//     width: 5%;
//     height: 7%;
//     top: 66%;
//     left: 82%;
//   }

//   .stroke3 {
//     width: 5%;
//     height: 8%;
//     top: 62%;
//     left: 34%;
//   }
//   .stroke4 {
//     width: 5%;
//     height: 8%;
//     top: 48%;
//     left: 52%;
//   }
//   .stroke5 {
//     width: 5%;
//     height: 8%;
//     top: 10%;
//     left: 94%;
//   }
// }
// .game-27 {
//   .stroke1 {
//     width: 6%;
//     height: 13%;
//     top: 60%;
//     left: 21%;
//     // background:blue;
//     // opacity: 0.5;
//   }
//   .stroke2 {
//     width: 8%;
//     height: 7%;
//     top: 55%;
//     left: 35%;
//     // background:blue;
//     // opacity: 0.5;
//   }

//   .stroke3 {
//     width: 8%;
//     height: 7%;
//     top: 52%;
//     left: 54%;
//     // background:blue;
//     // opacity: 0.5;
//   }
//   .stroke4 {
//     width: 18%;
//     height: 9%;
//     top: 64%;
//     left: 44%;
//     // background:blue;
//     // opacity: 0.5;
//   }
//   .stroke5 {
//     width: 7%;
//     height: 7%;
//     top: 47%;
//     left: 76%;
//     // background:blue;
//     // opacity: 0.5;
//   }
// }
// .game-272 {
//   .stroke1 {
//     width: 28%;
//     height: 25%;
//     top: 45%;
//     left: 11%;
//     // background:blue;
//     // opacity: 0.5;
//   }
//   .stroke2 {
//     width: 8%;
//     height: 10%;
//     top: 50%;
//     left: 51%;
//     // background:blue;
//     // opacity: 0.5;
//   }

//   .stroke3 {
//     width: 8%;
//     height: 8%;
//     top: 48%;
//     left: 86%;
//     // background:blue;
//     // opacity: 0.5;
//   }
//   .stroke4 {
//     width: 7%;
//     height: 10%;
//     top: 11%;
//     left: 70%;
//     // background:blue;
//     // opacity: 0.5;
//   }
//   .stroke5 {
//     width: 7%;
//     height: 8%;
//     top: 64%;
//     left: 57%;
//     // background:blue;
//     // opacity: 0.5;
//   }
// }
// .game-28 {
//   .stroke1 {
//     width: 5%;
//     height: 8%;
//     top: 57%;
//     left: 29%;
//   }
//   .stroke2 {
//     width: 5%;
//     height: 7%;
//     top: 57%;
//     left: 16%;
//   }

//   .stroke3 {
//     width: 11%;
//     height: 15%;
//     top: 20%;
//     left: 4%;
//   }
//   .stroke4 {
//     width: 10%;
//     height: 15%;
//     top: 48%;
//     left: 87%;
//   }
//   .stroke5 {
//     width: 6%;
//     height: 8%;
//     top: 35%;
//     left: 77%;
//   }
// }
// .game-29 {
//   .stroke1 {
//     width: 7%;
//     height: 9%;
//     top: 81%;
//     left: 36%;
//     // background:blue;
//     // opacity: 0.5;
//   }
//   .stroke2 {
//     width: 8%;
//     height: 11%;
//     top: 60%;
//     left: 87%;
//     // background:blue;
//     // opacity: 0.5;
//   }

//   .stroke3 {
//     width: 8%;
//     height: 16%;
//     top: 52%;
//     left: 60%;
//     // background:blue;
//     // opacity: 0.5;
//   }
//   .stroke4 {
//     width: 7%;
//     height: 6%;
//     top: 81%;
//     left: 16%;
//     // background:blue;
//     // opacity: 0.5;
//   }
// }
// .game-292 {
//   .stroke1 {
//     width: 8%;
//     height: 10%;
//     top: 49%;
//     left: 78%;
//   }
//   .stroke2 {
//     width: 13%;
//     height: 10%;
//     top: 65%;
//     left: 18%;
//   }

//   .stroke3 {
//     width: 8%;
//     height: 12%;
//     top: 52%;
//     left: 16%;
//   }
//   .stroke4 {
//     width: 7%;
//     height: 6%;
//     top: 36%;
//     left: 39%;
//   }
// }
// .game-30 {
//   .stroke1 {
//     width: 6%;
//     height: 12%;
//     top: 50%;
//     left: 52%;
//   }
//   .stroke2 {
//     width: 5%;
//     height: 8%;
//     top: 30%;
//     left: 6%;
//   }

//   .stroke3 {
//     width: 11%;
//     height: 16%;
//     top: 28%;
//     left: 21%;
//   }
//   .stroke4 {
//     width: 6%;
//     height: 9%;
//     top: 60%;
//     left: 13%;
//   }
//   .stroke5 {
//     width: 6%;
//     height: 10%;
//     top: 59%;
//     left: 71%;
//   }
// }
// .game-34 {
//   .stroke1 {
//     width: 12%;
//     height: 11%;
//     top: 59%;
//     left: 40%;
//   }
//   .stroke2 {
//     width: 25%;
//     height: 27%;
//     top: 20%;
//     left: 74%;
//   }

//   .stroke3 {
//     width: 7%;
//     height: 7%;
//     top: 10%;
//     left: 17%;
//   }
//   .stroke4 {
//     width: 18%;
//     height: 18%;
//     top: 44%;
//     left: 59%;
//   }
//   .stroke5 {
//     width: 8%;
//     height: 10%;
//     top: 62%;
//     left: 5%;
//   }
// }
// .game-36 {
//   .stroke1 {
//     width: 5%;
//     height: 20%;
//     top: 49%;
//     left: 7%;
//   }
//   .stroke2 {
//     width: 6%;
//     height: 28%;
//     top: 43%;
//     left: 89%;
//     transform: rotate(-35deg);
//   }

//   .stroke3 {
//     width: 5%;
//     height: 20%;
//     top: 5%;
//     left: 88%;
//   }
//   .stroke4 {
//     width: 6%;
//     height: 30%;
//     top: 40%;
//     left: 55%;
//   }
//   .stroke5 {
//     width: 4%;
//     height: 18%;
//     top: 15%;
//     left: 20%;
//   }
// }
// .game-38 {
//   .stroke1 {
//     // background: #000;
//     width: 3%;
//     height: 15%;
//     top: 22%;
//     left: 74%;
//     transform: rotate(49deg);
//   }
//   .stroke2 {
//     // background: #000;
//     width: 4%;
//     height: 16%;
//     top: 41%;
//     left: 53.5%;
//     transform: rotate(47deg);
//   }

//   .stroke3 {
//     // background: #000;
//     width: 3%;
//     height: 15%;
//     top: 25%;
//     left: 52%;
//     transform: rotate(47deg);
//   }
//   .stroke4 {
//     // background: #000;
//     width: 5%;
//     height: 16%;
//     top: 0%;
//     left: 14%;
//     transform: rotate(47deg);
//   }
//   .stroke5 {
//     // background: #000;
//     width: 2%;
//     height: 8%;
//     top: 17.5%;
//     left: 76%;
//     transform: rotate(49deg);
//   }
// }
// .game-42 {
//   .stroke1 {
//     width: 9%;
//     height: 5%;
//     top: 59%;
//     left: 39%;
//   }
//   .stroke2 {
//     width: 9%;
//     height: 5%;
//     top: 8%;
//     left: 78%;
//   }

//   .stroke3 {
//     width: 9%;
//     height: 5%;
//     top: 2%;
//     left: 14%;
//   }
//   .stroke4 {
//     width: 9%;
//     height: 5%;
//     top: 55%;
//     left: 18%;
//   }
//   .stroke5 {
//     width: 14%;
//     height: 6%;
//     top: 26%;
//     left: 63%;
//   }
// }
// .game-44 {
//   .stroke1 {
//     width: 5%;
//     height: 20%;
//     top: 52%;
//     left: 1%;
//   }
//   .stroke2 {
//     width: 5%;
//     height: 17%;
//     top: 60.5%;
//     left: 39%;
//   }

//   .stroke3 {
//     width: 5%;
//     height: 17%;
//     top: 13%;
//     left: 11%;
//   }
//   .stroke4 {
//     width: 5%;
//     height: 17%;
//     top: 56%;
//     left: 94%;
//   }
//   .stroke5 {
//     width: 5%;
//     height: 17%;
//     top: 54%;
//     left: 60%;
//   }
// }
// .game-46 {
//   .stroke1 {
//     width: 5%;
//     height: 16%;
//     top: 50%;
//     left: 37%;
//   }
//   .stroke2 {
//     width: 5%;
//     height: 16%;
//     top: 37%;
//     left: 80%;
//   }

//   .stroke3 {
//     width: 6%;
//     height: 20%;
//     top: 11%;
//     left: 13%;
//   }
//   .stroke4 {
//     width: 7%;
//     height: 26%;
//     top: 47%;
//     left: 19%;
//   }
//   .stroke5 {
//     width: 8%;
//     height: 28%;
//     top: 45%;
//     left: 57%;
//   }
// }
// .game-50 {
//   .stroke1 {
//     width: 6%;
//     height: 5%;
//     top: 36%;
//     left: 85%;
//   }
//   .stroke2 {
//     width: 11%;
//     height: 7%;
//     top: 26%;
//     left: 88%;
//   }

//   .stroke3 {
//     width: 26%;
//     height: 15%;
//     top: 50%;
//     left: 55%;
//   }
//   .stroke4 {
//     width: 14%;
//     height: 9%;
//     top: 60%;
//     left: 4%;
//   }
//   .stroke5 {
//     width: 16%;
//     height: 10%;
//     top: 13%;
//     left: 22%;
//   }
// }
// .game-52 {
//   .stroke1 {
//     width: 10%;
//     height: 5%;
//     top: 45%;
//     left: -2%;
//     transform: rotate(45deg);
//   }
//   .stroke2 {
//     width: 13%;
//     height: 7%;
//     top: 20%;
//     left: 27%;
//     transform: rotate(55deg);
//   }

//   .stroke3 {
//     width: 10%;
//     height: 5%;
//     top: 50%;
//     left: 62%;
//     transform: rotate(45deg);
//   }
//   .stroke4 {
//     width: 14%;
//     height: 7%;
//     top: 3%;
//     left: 8%;
//     transform: rotate(55deg);
//   }
//   .stroke5 {
//     width: 20%;
//     height: 8%;
//     top: 27%;
//     left: 77%;
//     transform: rotate(55deg);
//   }
// }
// .game-54 {
//   .stroke1 {
//     width: 12%;
//     height: 20%;
//     top: 6%;
//     left: 4%;
//   }
//   .stroke2 {
//     width: 7%;
//     height: 12%;
//     top: 42%;
//     left: 21%;
//   }

//   .stroke3 {
//     width: 10%;
//     height: 18%;
//     top: 31%;
//     left: 56%;
//   }
//   .stroke4 {
//     width: 10%;
//     height: 20%;
//     top: 52%;
//     left: 64%;
//   }
//   .stroke5 {
//     width: 12%;
//     height: 20%;
//     top: 13%;
//     left: 84%;
//   }
// }
// .game-58 {
//   .stroke1 {
//     width: 8%;
//     height: 10%;
//     top: 50%;
//     left: 14%;
//   }
//   .stroke2 {
//     width: 7%;
//     height: 9%;
//     top: 32%;
//     left: 13%;
//   }

//   .stroke3 {
//     width: 11%;
//     height: 12%;
//     top: 48%;
//     left: 81%;
//   }
//   .stroke4 {
//     width: 11%;
//     height: 13%;
//     top: 65%;
//     left: 39%;
//   }
//   .stroke5 {
//     width: 8%;
//     height: 10%;
//     top: 22%;
//     left: 45%;
//   }
// }
// .game-60 {
//   .stroke1 {
//     width: 8%;
//     height: 8%;
//     top: 57%;
//     left: 55%;
//   }
//   .stroke2 {
//     width: 17%;
//     height: 17%;
//     top: 57%;
//     left: 3%;
//   }

//   .stroke3 {
//     width: 9%;
//     height: 9%;
//     top: 15%;
//     left: 10%;
//   }
//   .stroke4 {
//     width: 7%;
//     height: 9%;
//     top: 48%;
//     left: 33%;
//   }
//   .stroke5 {
//     width: 9%;
//     height: 11%;
//     top: 24%;
//     left: 86%;
//   }
// }
// .game-62 {
//   .stroke1 {
//     width: 5%;
//     height: 9%;
//     top: 50%;
//     left: 55%;
//   }
//   .stroke2 {
//     width: 4%;
//     height: 6%;
//     top: 17%;
//     left: 35%;
//   }

//   .stroke3 {
//     width: 3%;
//     height: 5%;
//     top: 60.5%;
//     left: 40.5%;
//   }
//   .stroke4 {
//     width: 4%;
//     height: 8%;
//     top: 25.5%;
//     left: 23%;
//   }
//   .stroke5 {
//     width: 5%;
//     height: 9%;
//     top: 36%;
//     left: 71%;
//   }
// }
</style>
