var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-speaking-two-sentence-container" }, [
    _c(
      "div",
      { staticClass: "game-content" },
      [
        _c("SceneSwitchThumbnails", {
          key: _vm.key,
          attrs: { buttons: _vm.buttonList, numBers: _vm.currentIndex },
          on: { changeTheNumbers: _vm.changeTheNumbers },
        }),
        _vm.titlePinYin || _vm.titleHanZi
          ? _c("div", { staticClass: "title-area" }, [
              _c("div", { staticClass: "title" }, [
                _vm.titlePinYin
                  ? _c(
                      "div",
                      { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                      [_vm._v(" " + _vm._s(_vm.titlePinYin) + " ")]
                    )
                  : _vm._e(),
                _vm.titleHanZi
                  ? _c(
                      "div",
                      { staticClass: "title-hanzi font-hanzi-medium" },
                      [_vm._v(" " + _vm._s(_vm.titleHanZi) + " ")]
                    )
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "bgImg-area" },
          [
            _vm._l(_vm.gameList, function (item, index) {
              return [
                index + 1 === _vm.currentIndex
                  ? _c(
                      "div",
                      {
                        key: index,
                        staticClass: "img-box",
                        on: {
                          click: function ($event) {
                            return _vm.handleClickImg(item)
                          },
                        },
                      },
                      [
                        _c("transition", { attrs: { name: "el-fade-in" } }, [
                          _c("img", {
                            attrs: {
                              src: item.isFirst ? item.firstImg : item.bgImg,
                              alt: "",
                            },
                          }),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "bottom-sentence-area" },
          _vm._l(_vm.gameList, function (item, index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      item.isShowSentence &&
                      (item.isFirst ? item.firstHanzi : item.hanzi),
                    expression:
                      "\n          item.isShowSentence && (item.isFirst ? item.firstHanzi : item.hanzi)\n        ",
                  },
                ],
                key: index,
                staticClass: "hanzi-item",
              },
              [
                _c(
                  "div",
                  { staticClass: "pinyin sentence-hanzi font-pinyin-medium" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(item.isFirst ? item.firstPinyin : item.pinyin) +
                        " "
                    ),
                  ]
                ),
                _c("div", { staticClass: "sentence-hanzi font-hanzi-medium" }, [
                  _vm._v(
                    " " +
                      _vm._s(item.isFirst ? item.firstHanzi : item.hanzi) +
                      " "
                  ),
                ]),
              ]
            )
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }