var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-recognition-container" }, [
    _c("div", { staticClass: "recognition-content" }, [
      _c("div", { staticClass: "recogniton-page-content layout-red-bgc" }, [
        _c(
          "div",
          {
            staticClass: "recognition-content-left",
            on: {
              click: function ($event) {
                return _vm.ImgClick()
              },
            },
          },
          [
            _vm.isChange
              ? _c("img", {
                  staticClass: "santa-claus",
                  attrs: { src: _vm.backgroundImage, alt: "" },
                })
              : _c("img", {
                  staticClass: "santa-claus",
                  attrs: { src: _vm.changeBgImg, alt: "" },
                }),
          ]
        ),
        _c("div", { staticClass: "recogniton-content-right" }, [
          _c(
            "div",
            { staticClass: "recognize_container_content_right_border" },
            [
              _c(
                "div",
                { staticClass: "recognize_container_content_right_content" },
                [
                  _c("div", { staticClass: "right-img" }, [
                    _c("img", { attrs: { src: _vm.rightImg, alt: "" } }),
                  ]),
                  _c("div", { staticClass: "englishName" }, [
                    _c("p", { staticClass: "pinyin font-pinyin-medium" }, [
                      _vm._v(" " + _vm._s(_vm.wordObj.pinyin) + " "),
                    ]),
                    _c("p", { staticClass: "hanzi font-hanzi-medium" }, [
                      _vm._v(" " + _vm._s(_vm.wordObj.hanzi) + " "),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }