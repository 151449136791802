<template>
  <div class="game-train-words-and-py-container" :class="`lesson-${lessonNb}`">
    <div class="review-train-box">
      <div class="train-area" :class="`step-${stepNum} origin-${step}`">
        <!--  -->
        <img
          class="train-img"
          ref="train"
          src="@/assets/img/20-Train-review/review-train.svg"
          alt=""
        />
        <div
          class="review-content-wrapper"
          :style="{ width: trainlWidth + 'px', height: '78%' }"
        >
          <div class="review-content vocabulary fadeIn" v-if="stepNum >= 1">
            <div class="review-area">
              <!-- <div
                  class="train-item-col"
                  v-for="(item, index) in [1,2]"
                  :key="index + 'review'"
                >
                </div> -->
              <div
                class="train-item"
                v-for="(ele, indexSon) in wordsLists"
                :key="indexSon + 'son'"
              >
                <div class="pinyinArea font-pinyin-medium pinyin">
                  {{ ele.pinyin }}
                </div>
                <div class="hanziArea font-hanzi-medium">{{ ele.hanzi }}</div>
              </div>
            </div>
          </div>
          <div class="review-content knowledge fadeIn" v-if="stepNum >= 2">
            <div class="review-area">
              <div
                class="train-item"
                v-for="(ele, indexSon) in sentenceList"
                :key="indexSon + 'son'"
              >
                <div class="pinyinArea font-pinyin-medium pinyin">
                  {{ ele.pinyin }}
                </div>
                <div class="hanziArea font-hanzi-medium">{{ ele.hanzi }}</div>
              </div>
            </div>
          </div>
          <div class="review-content pinyinWord fadeIn" v-if="stepNum >= 3">
            <div class="review-area">
              <div
                class="train-item"
                v-for="(ele, indexSon) in pyList"
                :key="indexSon + 'son'"
              >
                <div class="vocabularyArea font-pinyin-large">
                  {{ ele.pinyin }}
                </div>
              </div>
            </div>
          </div>
          <div class="review-content strokes fadeIn" v-if="stepNum >= 4">
            <div class="review-area">
              <div
                class="stroke-img"
                v-for="(ele, indexSon) in bhList"
                :key="indexSon + 'son'"
              >
                <img :src="ele.strokeImg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          wordsLists: [],
          sentenceList: [],
          pyList: [],
          bhList: []
        }
      }
    },
    //   vocabularyReviewList: {
    //     type: Array,
    //     require: true,
    //   },
    //   knowledgeReviewList: {
    //     type: Array,
    //     require: true,
    //   },
    //   pinyinWordReviewList: {
    //     type: Array,
    //     require: true,
    //   },
    //   strokesReviewList: {
    //     type: Array,
    //     require: true,
    //   },
    lessonNb: {
      type: Number,
      require: false
    }
  },
  data() {
    return {
      wordsLists: [],
      sentenceList: [],
      pyList: [],
      bhList: [],
      trainlDom: null,
      trainlWidth: null,
      isLastStep: false,
      stepNum: null,
      step: null,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'look',
            startNumberRange: 1,
            endNumberRange: 1,
            clickGoToNextPage: true
          }
        ]
      },
      localData: {
        step:4,
        wordsLists: [
          {
            pinyin: 'xuéxiào',
            hanzi: '学校'
          },
          {
            pinyin: 'xuéxiào',
            hanzi: '学校'
          },
          {
            pinyin: 'xuéxiào',
            hanzi: '学校'
          },
          {
            pinyin: 'xuéxiào',
            hanzi: '学校'
          },
          {
            pinyin: 'xuéxiào',
            hanzi: '学校'
          },
          {
            pinyin: 'xuéxiào',
            hanzi: '学校'
          },
          {
            pinyin: 'xuéxiào',
            hanzi: '学校'
          },
          {
            pinyin: 'xuéxiào',
            hanzi: '学校'
          },
          {
            pinyin: 'xuéxiào',
            hanzi: '学校'
          }
        ],
        sentenceList: [
          {
            pinyin: 'yiqishangxue',
            hanzi: '一起上学'
          },
          {
            pinyin: 'yiqifangxue',
            hanzi: '一起放学'
          }
        ],
        pyList: [
          {
            pinyin: 'a'
          },
          {
            pinyin: 'a'
          },
          {
            pinyin: 'a'
          },
          {
            pinyin: 'a'
          },
          {
            pinyin: 'a'
          },
          {
            pinyin: 'a'
          },
          {
            pinyin: 'a'
          },
          {
            pinyin: 'a'
          }
        ],
        bhList: [
          {
            strokeImg: require('@/assets/img/18-Blue-review/lesson-06-pie-yellow.svg')
          },
          {
            strokeImg: require('@/assets/img/18-Blue-review/lesson-06-na-yellow.svg')
          },
          {
            strokeImg: require('@/assets/img/18-Blue-review/lesson-04-hengzhe-yellow.svg')
          }
        ],
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.stepNum = this.step
      this.$nextTick(() => {
        this.trainlDom = this.$refs.train
        this.trainlWidth = this.trainlDom.getBoundingClientRect().width
      })
    }, 1000)
  },
  watch: {
    componentProps: {
      handler(value) {
        // console.log('watch的数据', value)
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  methods: {
    initData() {
      this.wordsLists = this.componentProps.wordsLists.map((item) => {
        return {
          pinyin: item[0].value,
          hanzi: item[1].value
        }
      })
      // console.log(this.wordsList,'111');

      this.sentenceList = this.componentProps.sentenceList.map((item) => {
        return {
          pinyin: item[0].value,
          hanzi: item[1].value
        }
      })

      this.pyList = this.componentProps.pyList.map((item) => {
        return {
          pinyin: item[0].value
        }
      })
      // console.log(this.pyList, '111')

      this.bhList = this.componentProps.bhList.map((item, index) => {
        return {
          strokeImg: item.value
        }
      })
      //  console.log(this.bhList, '111')
      setTimeout(() => {
      if(this.wordsLists[0].pinyin){
        this.stepNum = 1
        this.step = 1
      }
      if(this.sentenceList[0].pinyin){
        this.stepNum = 2
        this.step = 2
      }
      if(this.pyList[0].pinyin){
        this.stepNum = 3
        this.step = 3
      }
      if(this.bhList.length && this.bhList[0].strokeImg){
        this.stepNum = 4
        this.step = 4
      }
      this.$nextTick(() => {
        this.trainlDom = this.$refs.train
        this.trainlWidth = this.trainlDom.getBoundingClientRect().width
      })
    }, 1000)
    },
    nextPage() {
      this.$bus.$emit('nextButton', true)
    },
    scrollMove(num) {
      const speed = this.scrollSpeed
      this.isMoving = true
      let timer = setInterval(() => {
        this.distance += speed
        this.scrollDom.scrollLeft = this.distance
        if (this.distance / this.scrollWidth >= num) {
          clearInterval(timer)
          this.isMoving = false

          if (this.step === this.durationArr.length) {
            this.isLastStep = true
            playCorrectSound()
            startConfetti()
          } else {
            playCorrectSound(true, false)
          }
        }
      }, 10)
    }
  }
}
</script>
<style scoped lang="scss">
.game-train-words-and-py-container {
  width: 100%;
  height: 100%;
  .review-train-box {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-color: #f5f9ff;
    background-image: url('../../../../assets/img/20-Train-review/background-clouds.svg');
    background-size: cover;
    border-radius: 58px;
    position: relative;
    overflow-x: scroll;
    position: relative;
    .train-area {
      position: absolute;
      bottom: 0;
      left: -175%;
      height: 100%;
      width: 100%;
      .train-img {
        height: 40%;
        position: absolute;
        bottom: 0;
      }
      .review-content-wrapper {
        position: absolute;
        left: 0;
        bottom: 22%;
        .review-content {
          position: absolute;
          bottom: 0%;
          height: 100%;
          // width: 31.5%;
          z-index: 10;
          display: inline-block;
          .review-area {
            width: 100%;
            height: 100%;
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: flex-end;
            .train-item {
              background: rgb(34, 78, 150);
              color: #fff;
              border-radius: 6px;
              margin-top: 1px;
              margin-right: 1px;
              padding: 5px;
            }
            .stroke-img {
              height: 20%;
              background: #224e96;
              border-radius: 6px;
              margin-top: 1px;
              margin-right: 1px;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 100%;
                height: 50%;
              }
            }
            .pinyinArea,
            .hanziArea {
              text-align: center;
              border-radius: 5px;
              padding: 5px 0 2px 0;
              line-height: 1;
            }
            .hanziArea {
              padding: 2px 0 5px 0;
            }
          }
        }
      }

      .vocabulary {
        width: 20%;
        right: 20%;
      }
      .knowledge {
        width: 20%;
        right: 43.5%;

        &.isFullWidth {
          height: auto;
          .review-area {
            flex-direction: column;
          }
        }
      }
      .pinyinWord {
        width: 12%;
        right: 68%;
      }
      .strokes {
        width: 15%;
        right: 85%;

        .stroke-img {
          height: 20%;
          img {
            height: 70%;
          }
        }
      }
    }
    .origin-2 {
      left: -75%;
    }
    .origin-3 {
      left: -30%;
    }
    .origin-4 {
      left: 0%;
    }
    .step-0 {
      left: -100%;
      transition: left 5s;
    }
    .step-1 {
      left: -75%;
      transition: left 5s;
    }
    .step-2 {
      left: -30%;
      transition: left 5s;
    }
    .step-3 {
      left: 0%;
      transition: left 5s;
    }
    .step-4 {
      left: 20%;
      transition: left 5s;
    }
  }
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  &.lesson-24 .strokes .stroke-img {
    // height: auto !important;
    .train-item-col:first-of-type {
      flex-grow: 2 !important;
    }
  }
}

.vocabularyArea {
  padding: 10px 0;
  text-align: center;
}

.deepBlue {
  background: rgb(27, 69, 137) !important;
}
.deepBlueTest {
  background: rgb(27, 69, 137) !important;
}
.Blue {
  background: rgb(34, 78, 150);
}
</style>
