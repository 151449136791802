<template>
  <div class="game-choose-img-by-listening-container">
    <div class="game-content">
      <Star
        :totalStarNumber="answerList.length"
        :currentIndex="currentStarIndex"
      />
      <!-- page  select img-->
      <div class="choose-img-area">
        <div class="img-area">
          <div class="img-content">
            <img
              v-for="(item, index) in allImgList[currentIndex]"
              :src="item.bgImg"
              alt=""
              :key="index + 'img'"
              class="img-item"
              :class="{
                active: index === chooseImgIndex,
                isCorrect:
                  item.id === answerList[currentIndex] &&
                  index === chooseImgIndex,
              }"
              v-show="item.bgImg !== ''"
              @click="handleChooseImg(item.id, index)"
            />
          </div>
        </div>
        <div class="trumpet-area-right">
          <span
            class="trumpet-area"
            @click.stop="handlePlayPinyinAudio"
            @mouseenter="hoverImg(1, 'hover')"
            @mouseleave="hoverImg(1, '')"
            :class="{ lessononehover: isClickAudio }"
          >
            <AudioPlayer :isPlay="isStartPlaying" :audioUrl="songUrl" />
          </span>
        </div>
      </div>
      <CommonButton @shuffleClickEvent="handleShuffleClickEvent" />
    </div>
  </div>
</template>

<script>
import Star from "@/components/Course/GamePage/Star";
import AudioPlayer from "@/components/Course/GamePage/AudioPlayer";
import { startConfetti, playCorrectSound } from "@/utils/tools.js";
import { templatePreviewMixin } from "./templatePreviewMixin.js";
import CommonButton from "../GameComponent/CommonButton.vue";
export default {
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          chooseImgByListeningList: [],
        };
      },
    },
  },
  components: {
    Star,
    AudioPlayer,
    CommonButton
  },

  data() {
    return {
      imgSocketInfo: {},
      answerList: [],
      allImgList: [],
      chooseHanziList: [],
      trumpetImg: "",
      isClickAudio: false,
      isStartPlaying: null,
      songUrl: null,
      lockIndex: 0,
      insertIndex: 0,
      chooseImgIndex: null,
      currentIndex: 0,
      currentStarIndex: 0,
      isLastStep: false,
      localData: {
        answerList: [2, 7, 12, 16, 17, 24, 27, 31],
        trumpetImg: require("@/assets/img/16-GAMES/G50-stories/trumpter.svg"),
        chooseHanziList: [
          {
            bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-2.svg`),
            audioSrc: "",
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-7.svg`),
            audioSrc: "",
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-12.svg`),
            audioSrc: "",
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-16.svg`),
            audioSrc: "",
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-20.svg`),
            audioSrc: "",
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-23.svg`),
            audioSrc: "",
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-25.svg`),
            audioSrc: "",
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-29.svg`),
            audioSrc: "",
          },
        ],
        allImgList: [
          [
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-1.svg`),
              id: 1,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-2.svg`),
              id: 2,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-3.svg`),
              id: 3,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-4.svg`),
              id: 4,
            },
          ],
          [
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-5.svg`),
              id: 5,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-6.svg`),
              id: 6,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-7.svg`),
              id: 7,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-8.svg`),
              id: 8,
            },
          ],
          [
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-9.svg`),
              id: 9,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-10.svg`),
              id: 10,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-11.svg`),
              id: 11,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-12.svg`),
              id: 12,
            },
          ],
          [
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-13.svg`),
              id: 13,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-14.svg`),
              id: 14,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-15.svg`),
              id: 15,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-16.svg`),
              id: 16,
            },
          ],
          [
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-17.svg`),
              id: 17,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-18.svg`),
              id: 18,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-19.svg`),
              id: 19,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-20.svg`),
              id: 20,
            },
          ],
          [
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-21.svg`),
              id: 21,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-22.svg`),
              id: 22,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-23.svg`),
              id: 23,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-24.svg`),
              id: 24,
            },
          ],
          [
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-25.svg`),
              id: 25,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-26.svg`),
              id: 26,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-27.svg`),
              id: 27,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-28.svg`),
              id: 28,
            },
          ],
          [
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-29.svg`),
              id: 29,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-30.svg`),
              id: 30,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-31.svg`),
              id: 31,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-32.svg`),
              id: 32,
            },
          ],
        ],
      },
      shuffleSocketInfo: {},
      shuffleSocketValue: null,
    };
  },
  mounted() {
    // this.initData()
    this.$bus.$on("clickChoosedImg", ({ id, index }) => {
      this.handleChooseImg(id, index, true);
    }),
      this.$bus.$on("shuffleArray", ({ ramdomList }) => {
        this.allImgList[this.currentIndex] = [];
        this.allImgList[this.currentIndex] = ramdomList;
        this.$set(this.allImgList, this.currentIndex, ramdomList);

        this.shuffleSocketValue = true;
      });
  },
  beforeDestroy() {
    this.$bus.$off("clickChoosedImg");
    this.$bus.$off("shuffleArray");
  },
  watch: {
    imgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003001,
          data: { value },
          text: "ListenThenChooseWordsGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    componentProps: {
      handler(newVal) {
        const { isTemplatePrview } = newVal;
        if (isTemplatePrview) return;
        this.initData();
        this.chooseHanziList = newVal.chooseImgByListeningList.map(
          (subArray) => ({
            audioSrc: subArray[0].value,
          })
        );
        let idCounter = 1;
        this.allImgList = newVal.chooseImgByListeningList.map((subArray) => {
          const imgList = subArray.slice(1,-1).map((item) => {
            return {
              bgImg: item.value,
              id: idCounter++,
            };
          });
          return imgList;
        });
        this.answerList = this.allImgList.map((subArray) => subArray[0].id);
        // this.allImgList.forEach((subArray) => {
        //   for (let i = subArray.length - 1; i > 0; i--) {
        //     const j = Math.floor(Math.random() * (i + 1))
        //     ;[subArray[i], subArray[j]] = [subArray[j], subArray[i]]
        //   }
        // })
      },
      deep: true,
      immediate: true,
    },
    shuffleSocketInfo: {
      handler(value) {
        if (!this.shuffleSocketValue) {
          const data = {
            clickType: 2001004,
            data: { value },
            text: "DragGameBtn shuffleSocketValue",
          };
          console.log(data);
          this.$bus.$emit("kids_webSocket_sendInfo", data);
        }
      },
      deep: true,
    },
  },
  methods: {
    /**
     * @description: 乱序回调
     * @return {*}
     */
    handleShuffleClickEvent() {
      // console.log("乱序");
      const orderList = this.shuffleArray(this.allImgList[this.currentIndex]);
      // console.log(orderList,'order')
      this.allImgList[this.currentIndex] = [];
      this.allImgList[this.currentIndex] = orderList;

      this.shuffleSocketValue = false;

      this.shuffleSocketInfo = {
        ramdomList: this.allImgList[this.currentIndex],
        ramdomIndex: Math.random(),
      };
      this.$set(this.allImgList, this.currentIndex, orderList);
    },
    /**
     * @description: 数组乱序
     * @param {*} array
     * @return {*}
     */
    shuffleArray(array) {
      let currentIndex = array.length,
        temporaryValue,
        randomIndex;

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      // console.log(array);

      return array;
    },
    initData() {},
    /**
     * @description: 播放按钮的hover效果
     * @param {*} id
     * @return {*}
     */
    hoverImg(id) {
      this.isClickAudio = !this.isClickAudio;
    },
    /**
     * @description: 音频播放
     * @return {*}
     */
    handlePlayPinyinAudio() {
      this.isStartPlaying = Math.random();
      this.songUrl = this.chooseHanziList[this.currentIndex].audioSrc;
    },
    /**
     * @description: 选择图片判断是否正确
     * @param {*} id
     * @param {*} index
     * @return {*}
     */
    handleChooseImg(id, index, isFromSocket = false) {
      if (!isFromSocket) {
        this.imgSocketInfo = {
          id,
          index,
          value: Math.random(),
        };
      }
      this.chooseImgIndex = index;
      if (id === this.answerList[this.currentIndex]) {
        playCorrectSound(true, false);
        setTimeout(() => {
          this.currentIndex++;
          this.lockIndex++;
          this.currentStarIndex++;
          this.checkToNext();
          this.chooseImgIndex = null;
        }, 1000);
      } else {
        playCorrectSound(false);
      }
    },
    checkToNext() {
      if (this.lockIndex >= this.allImgList.length) {
        this.nextPart();
      }
    },
    nextPart() {
      setTimeout(() => {
        if (this.currentIndex >= this.allImgList.length) {
          this.currentIndex = this.allImgList.length - 1;
          startConfetti();
          playCorrectSound();
        } else {
          playCorrectSound(true, false);
        }
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.game-choose-img-by-listening-container {
  width: 100%;
  height: 100%;
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    background: #f7f4e0;
    border-radius: 58px;

    .choose-img-area {
      height: 100%;
      display: flex;
      align-items: center;
      .img-area {
        margin-left: 22%;
        flex: 6;
        display: flex;
        justify-content: center;
        align-items: center;
        .img-content {
          width: 80%;
          height: 70%;
          display: flex;
          flex-wrap: wrap;
          .img-item {
            width: 48%;
            height: 150px;
            object-fit: cover;
            margin-right: 2%;
            margin-bottom: 2%;
            display: inline-block;
            box-sizing: border-box;
            border: 10px solid #fff;
            cursor: pointer;
          }
          // .active {
          //   border-color: #ce4c3f;
          // }
          .isCorrect {
            border-color: #214e96;
          }
        }
      }
      .trumpet-area-right {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        .trumpet-area {
          width: 100px;
          height: 100px;
          background-color: #cd4c3f;
          border-radius: 50%;
          img {
            width: 30%;
            &:hover {
              opacity: 0.8;
              cursor: pointer;
            }
          }
        }
        .lessononehover {
          width: 100px;
          height: 100px;
          background-color: #d1564b;
          border-radius: 50%;
          img {
            width: 30%;
            &:hover {
              opacity: 0.8;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .title-area {
    z-index: 11;
    position: absolute;
    left: 0;
    top: 10%;
    width: auto;
    height: auto;
    padding: 20px;
    background: #cd4c3f;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
    color: #fff;
    .title {
      .title-pinyin,
      .title-hanzi {
        text-align: center;
      }
    }
  }
}
</style>
