<template>
  <div class="teitter-game-container" @click="handleClickScreen">
    <div class="game-content">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="numBers"
        @changeTheNumbers="changeTheNumbers"
        :key="key"
      >
      </SceneSwitchThumbnails>
      <!-- <Star :totalStarNumber="starNum" :currentIndex="curretnStarIndex" /> -->
      <div class="back-ground-img-area">
        <img src="@/assets/img/03-Backgrounds/background-practice.svg" alt="" />
        <img
          class="phone"
          src="@/assets/img/16-GAMES/G60-phone/phone.svg"
          alt=""
        />
        <div class="heart" @click="isShowHeart = true"></div>
        <img
          v-if="isShowHeart"
          class="heart"
          src="@/assets/img/16-GAMES/G60-phone/heart.svg"
          alt=""
        />
      </div>
      <div class="twitter-area">
        <div class="twitter-img-area">
          <template v-for="(item, index) in gameList">
            <div class="img-item" :key="index" v-if="index === currentIndex">
              <transition name="el-fade-in">
                <!-- <img
                  v-if="currentStepIndex === 0"
                  :src="item.originImg"
                  alt=""
                /> -->
                <img
                  v-if="[1, 2, 3].indexOf(currentStepIndex) > -1"
                  :src="item.clickImg"
                  alt=""
                />
              </transition>
              <div class="page-title">
                <span class="font-text txt-py font-pinyin-medium">{{
                  item.titlePy
                }}</span>
                <span class="txt-hz font-hanzi-medium">{{ item.titleZh }}</span>
              </div>
              <div class="text" v-if="[2, 3].indexOf(currentStepIndex) > -1">
                <span class="font-text txt-py font-pinyin-medium1">{{
                  item.pinyin
                }}</span>
                <span class="font-hanzi-medium1">{{ item.hanzi }}</span>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="click-area">
        <!-- <div class="screen-shape" @click="handleClickScreen"></div> -->
        <div class="heart-shape" @click="handleClickHeart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
// import Star from "../Star";
import SceneSwitchThumbnails from '../GameComponent/SceneSwitchThumbnails.vue'
import PageButton from '@/components/Course/Interaction/Button/pageButton.vue'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  name: 'TwitterGame',
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          TwitterGameList: []
        }
      }
    }
  },
  data() {
    return {
      clickHeartSocketInfo: {},
      clickScreenSocketInfo: {},
      isShowHeart: false,
      gameList: [],
      buttonList: [],
      title: {
        hanzi: '',
        pinyin: ''
      },
      currentIndex: 0,
      isLastStep: false,
      currentStepIndex: 0,
      curretnIndex: 0,
      curretnStarIndex: 0,
      numBers: 1,
      key: 0,

      localData: {
        gameList: [
          {
            originImg: require('@/assets/img/16-GAMES/G60-phone/phone-1-1.svg'),
            clickImg: require('@/assets/img/16-GAMES/G60-phone/phone-1-2.svg'),
            wordsImg: require('@/assets/img/16-GAMES/G60-phone/phone-1-3.svg'),
            heartImg: require('@/assets/img/16-GAMES/G60-phone/phone-1-4.svg'),
            pinyin: 'kàn',
            hanzi: '看'
          },
          {
            originImg: require('@/assets/img/16-GAMES/G60-phone/phone-2-1.svg'),
            clickImg: require('@/assets/img/16-GAMES/G60-phone/phone-2-2.svg'),
            wordsImg: require('@/assets/img/16-GAMES/G60-phone/phone-2-3.svg'),
            heartImg: require('@/assets/img/16-GAMES/G60-phone/phone-2-4.svg'),
            pinyin: 'yīng',
            hanzi: '鹰'
          },
          {
            originImg: require('@/assets/img/16-GAMES/G60-phone/phone-3-1.svg'),
            clickImg: require('@/assets/img/16-GAMES/G60-phone/phone-3-2.svg'),
            wordsImg: require('@/assets/img/16-GAMES/G60-phone/phone-3-3.svg'),
            heartImg: require('@/assets/img/16-GAMES/G60-phone/phone-3-4.svg'),
            pinyin: 'yíng',
            hanzi: '翼'
          }
        ],
        buttonList: [
          {
            image: require('@/assets/img/16-GAMES/G60-phone/menu-1.svg'),
            startNumberRange: 1,
            endNumberRange: 1
          },
          {
            image: require('@/assets/img/16-GAMES/G60-phone/menu-2.svg'),
            startNumberRange: 2,
            endNumberRange: 2
          },
          {
            image: require('@/assets/img/16-GAMES/G60-phone/menu-3.svg'),
            startNumberRange: 3,
            endNumberRange: 3
          }
        ]
      }
    }
  },
  components: {
    PageButton,
    SceneSwitchThumbnails
    // Star,
  },

  methods: {
    initData() {},

    /**
     * @description: 切换场景
     * @param {*} index
     * @return {*}
     */
    changeTheNumbers(index, isFromSocket = false) {
      // console.log(index)
      this.currentIndex = index - 1
      this.currentStepIndex = 0
      // this.waitAppear()
      // console.log(this.currentIndex)
      if (!isFromSocket) {
        this.clickAsideSocketInfo = {
          index,
          value: Math.random()
        }
      }
      this.numBers = index
      this.isShowHeart = false
      if (index < 8) {
        this.isLastStep = false
      }
    },
    /**
     * @description: 点击屏幕
     * @param {*}
     * @return {*}
     */
    handleClickScreen(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickScreenSocketInfo = {
          value: Math.random()
        }
      }
      if (this.currentStepIndex >= 3 && this.currentIndex < 2) {
        return
      }
      this.currentStepIndex++
      // console.log(this.currentIndex, '---')
      if (
        this.currentIndex === this.gameList.length - 1 &&
        this.currentStepIndex >= 3 &&
        this.isShowHeart === true
      ) {
        this.isLastStep = true
        startConfetti()
        playCorrectSound()
      }
      playCorrectSound(true, false)

      // console.log(this.currentStepIndex, `第${this.currentStepIndex}张`)
    },
    handleClickHeart(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickHeartSocketInfo = {
          value: Math.random()
        }
      }
      if (this.currentStepIndex === 2) {
        this.currentStepIndex++
        // console.log(this.currentStepIndex)
        playCorrectSound(true, false)
        const that = this
        setTimeout(() => {
          that.toNextPart()
        }, 2000)
      }
    },
    // waitAppear() {
    //   if (this.currentStepIndex === 0) {
    //     setTimeout(() => {
    //       this.currentStepIndex = 1
    //       // console.log(this.currentIndex, '1111111111')
    //     }, 1000)
    //   }
    // },
    toNextPart() {
      this.currentIndex++
      // this.curretnStarIndex++;
      this.currentStepIndex = 0
      if (this.currentIndex >= this.gameList.length) {
        this.currentIndex = this.gameList.length - 1
        this.isLastStep = true
        startConfetti()
        playCorrectSound()
      }
    }
  },
  mounted() {
    // this.initData()
    this.$bus.$on('clickSpeakingPageOneAside', () => {
      this.changeTheNumbers('socket', true)
    })
    this.$bus.$on('ClickTheScreen', ({ item }) => {
      this.handleClickScreen(item, true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('ClickTheScreen')
    this.$bus.$off('clickSpeakingPageOneAside')
  },
  watch: {
    // clickHeartSocketInfo: {
    //   handler(value) {
    //     const data = {
    //       clickType: 2005102,
    //       data: { value },
    //       text: "TwitterGame点击爱心socket",
    //     };
    //     this.sendSocketInfo = { ...data };
    //     this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
    //   },
    //   deep: true,
    // },
    clickScreenSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2005101,
          data: { value },
          text: 'TwitterGame点击屏幕socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    clickAsideSocketInfo: {
      handler(value) {
        const data = {
          clickType: 3000601,
          data: { value },
          text: 'speakingPageOne页面menu按钮socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(newVal, oldVal) {
        const { isTemplatePrview } = newVal
        if (isTemplatePrview) return
        this.initData()
        this.gameList = newVal.TwitterGameList.map((item) => {
          return {
            // originImg: item[0].value,
            titlePy: item[0].value,
            titleZh: item[1].value,
            clickImg: item[2].value,
            pinyin: item[3].value,
            hanzi: item[4].value
            // heartImg: item[3].value
          }
        })
        this.buttonList = newVal.TwitterGameList.map((item, index) => {
          return {
            image: item[5].value,
            startNumberRange: index + 1,
            endNumberRange: index + 1
          }
        })
        this.key = Date.now()
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.teitter-game-container {
  width: 100%;
  height: 100%;
  line-height: 1;
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    .back-ground-img-area {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        border-radius: 48px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .phone {
        position: absolute;
        width: 50%;
        height: 67%;
        top: 16%;
      }
      .heart {
        width: 3%;
        height: 4%;
        z-index: 30 !important;
        position: absolute;
        top: 64%;
        left: 38%;
      }
    }
    .click-area,
    .twitter-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .twitter-area {
      display: flex;
      justify-content: center;
      align-items: center;
      .twitter-img-area {
        width: 80%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .img-item {
          width: 50%;
          height: 80%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 20;
          .text {
            width: 21%;
            height: 12%;
            top: 68%;
            position: absolute;
            display: flex;
            text-align: center;
            flex-direction: column;
            padding-top: 2%;
          }
          .page-title {
            background-color: #cd4c3f;
            border-top-right-radius: 29px;
            border-bottom-right-radius: 29px;
            position: absolute;
            top: 6%;
            left: 0;
            color: rgb(255, 255, 255);
            display: flex;
            justify-content: center;
            flex-flow: column;
            z-index: 9;
            width: auto;
            height: auto;
            padding: 3%;
          }
          .font-hanzi-medium1 {
            font-size: 16px !important;
          }
          .font-pinyin-medium1 {
            font-size: 16px !important;
          }
          img {
            cursor: pointer;
            width: 26%;
            top: 32%;
            position: absolute;
            height: 31%;
          }
        }
      }
    }
    .click-area {
      z-index: 4;
      .screen-shape,
      .heart-shape {
        position: absolute;
        // background: #000;
        border-radius: 10px;
        width: 228px;
        height: 226px;
        top: 27.5%;
        left: 37.5%;
        cursor: pointer;
      }
      .heart-shape {
        width: 30px;
        height: 20px;
        top: 64.5%;
        left: 37.5%;
      }
    }
  }
}
</style>
