<template>
  <div
    class="course-cover-container"
    :style="{ backgroundImage: 'url(\'' + bgImg + '\')' }"
  >
    <!-- lesson -->
    <div class="lesson-box">
      <span
        class="lesson_one"
        @click.stop="activeClick"
        @mouseenter="hoverImg(1, 'hover')"
        @mouseleave="hoverImg(1, '')"
        :class="{ lessononehover: lessononehover }"
      >
        <AudioPlayer :isPlay="isStartPlaying" :title="title" :audioUrl="audioSrc" />
      </span>
      <div class="customlesson_num">
        <div class="customlesson_two">
          <span class="pinyin pinyin font-pinyin-large">{{ titlePinyin }}</span>
        </div>
        <div class="customlesson_three">
          <span id="face" class="txt-hz font-hanzi-large">{{ title }}</span>
        </div>
        <div class="customlesson_four">
          <span class="englishFont font-english-large">{{ titleEnglish }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AudioPlayer from '@/components/Course/GamePage/AudioPlayer'
import { templatePreviewMixin } from './templatePreviewMixin.js'

export default {
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          title: '',
          titlePinyin: '',
          titleEnglish: '',
          audioSrc: '',
          bgImg: ''
        }
      }
    }
  },
  components: {
    AudioPlayer
  },
  created() {},
  data() {
    return {
      localData: {
        title: '我的学校',
        titlePinyin: 'Wǒ de xuéxiào',
        titleEnglish: 'My School',
        audioSrc: '',
        bgImg:
          'http://test.nihaokids.com/img/level-1-background-color.b624b516.svg'
      },
      lessononehover: false,
      lessononehovertwo: false,
      hoverFontNum: 0,
      clickSocketInfo: {},
      isStartPlaying: null,

      title: '',
      titlePinyin: '',
      titleEnglish: '',
      audioSrc: '',
      bgImg: ''
    }
  },
  watch: {
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20000,
          data: { value },
          text: '测试StartStop组件内点击事件socket'
        }
        this.$bus.$emit('kids_webSocket_sendInfo', data)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  mounted() {
    this.$bus.$on('clickStartStop', () => {
      this.activeClick('socket', true)
    })
    this.$bus.$on('hoverStartStop', (val) => {
      // console.log('hover', val)
      this.hoverImg(val, true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickStartStop')
    this.$bus.$off('hoverStartStop')
  },
  methods: {
    initData() {
      this.title = this.componentProps.title
      this.titlePinyin = this.componentProps.titlePinyin
      this.titleEnglish = this.componentProps.titleEnglish
      this.audioSrc = this.componentProps.audioSrc
      this.bgImg = this.componentProps.bgImg
    },
    hoverImg(id, isFromSocket = false) {
      if (!isFromSocket) {
        this.hoverFontNum++
      }
      this.hoverFontId = id
      switch (id) {
        case 1:
          this.lessononehover = !this.lessononehover
          break
        case 2:
          this.lessononehovertwo = !this.lessononehovertwo
          break
      }
    },
    activeClick(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickSocketInfo = {
          value: Math.random()
        }
      }
      this.isStartPlaying = Math.random()
    }
  }
}
</script>
<style scoped lang="scss">
.course-cover-container {
  width: 100%;
  height: 100%;
  border-radius: 56px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  -moz-background-size: 100% 100%;
  background-color: #ffdbb8;
  .lesson-box {
    padding: 30px;
    border-radius: 2em 0 0 2em;
    width: 35%;
    height: 36%;
    background-color: #cd4c3f;
    border-top-left-radius: 58px;
    border-bottom-left-radius: 58px;
    position: absolute;
    right: 0;
    top: 96px;
    display: flex;
    align-items: center;
    .lesson_one {
      position: absolute;
      top: -25px;
      left: 44%;
      width: 50px;
      height: 50px;
      background-color: #224e96;
      border-radius: 50%;
      text-align: center;
      .volume-button {
        cursor: pointer;
      }
      img {
        // width: 30px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        height: 99%;
      }
      audio {
      }
    }
    .lessononehover {
      background-color: #1b4589;
    }
    .lessononehovertwo {
      background-color: #1b4589 !important;
    }
    // .active{
    //   background-color:#1B4589;
    // }
    .customlesson_num {
      margin: auto;
      text-align: center;
      .customlesson_two {
        cursor: default;
        span {
          color: #ffffff;
        }

        .pinyin {
          font-family: 'STHEITISCPINYIN-LIGHT';
        }
      }
      .customlesson_three {
        cursor: default;
        span {
          color: #ffffff;
        }

        @media screen and (max-width: 1300px) {
          .font-hanzi-large {
            font-size: 36px;
            line-height: 2;
          }
        }
      }
      .customlesson_four {
        // margin-top: 8%;
        cursor: default;

        span {
          color: #ffffff;
        }
      }
    }
  }
}
</style>
