var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "speaking-sentence-container" }, [
    _c("div", { staticClass: "speaking-sentence-content" }, [
      Object.keys(_vm.titleInfo).length
        ? _c("div", { staticClass: "title-area" }, [
            _c("div", { staticClass: "title" }, [
              _c(
                "div",
                { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                [_vm._v(" " + _vm._s(_vm.titleInfo.pinyin) + " ")]
              ),
              _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
                _vm._v(" " + _vm._s(_vm.titleInfo.hanzi) + " "),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "bgImg-area" },
        [
          _vm._l(_vm.gameList, function (item, index) {
            return [
              index + 1 === _vm.currentIndex
                ? _c(
                    "div",
                    {
                      key: index,
                      staticClass: "img-box",
                      on: {
                        click: function ($event) {
                          return _vm.handleClickImg(index)
                        },
                      },
                    },
                    [
                      _c("transition", { attrs: { name: "el-fade-in" } }, [
                        _c("img", { attrs: { src: item.bgImg, alt: "" } }),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }