var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-prcatice-container" }, [
    _c(
      "div",
      { staticClass: "game-content" },
      [
        _c(
          "div",
          { staticClass: "game-background" },
          [
            _vm.totalStars
              ? _c("Star", {
                  attrs: {
                    totalStarNumber: _vm.totalStars,
                    currentIndex: _vm.answersfound,
                  },
                })
              : _vm._e(),
            _c("div", { staticClass: "backgroundImg_n_DragArea" }, [
              _vm.ObjectList[_vm.question] && _vm.ObjectList[_vm.question].image
                ? _c("div", { staticClass: "imageWrapper" }, [
                    _c("img", {
                      staticClass: "objectDisplay",
                      attrs: { src: _vm.ObjectList[_vm.question].image },
                    }),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "bottonsWrapper" },
                _vm._l(_vm.chineseList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "content-words-two",
                      on: {
                        click: function ($event) {
                          return _vm.foundAnswer(index)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "font-pinyin-medium pinyin" }, [
                        _vm._v(_vm._s(item.pinying)),
                      ]),
                      _c("span", { staticClass: "font-hanzi-medium" }, [
                        _vm._v(_vm._s(item.hanzi)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ],
          1
        ),
        _vm.answersfound === 0
          ? _c("CommonButton", {
              on: { shuffleClickEvent: _vm.handleShuffleClickEvent },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }