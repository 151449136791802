<template>
  <div class="drag-fruits-game-container">
    <!-- <audio :src="pinyinAudioSrc" ref="audioBox"></audio> -->
    <div class="game-content">
      <Star :totalStarNumber="optionList.length" :currentIndex="starNum" />
      <div class="drag-area">
        <img
          class="drag-item"
          v-for="(item, index) in optionList[currentIndex]"
          :key="index"
          :src="item.image"
          draggable="true"
          @dragstart="startDrag($event, item)"
          @drop.stop="Sdrop($event, index)"
          @dragend="endDrag()"
          :class="{ notShow: item.id === dragId, notDisplay: item.isNotShow }"
        />
      </div>

      <div class="right-box">
        <div class="right-content">
          <div
            class="drop-area"
            @drop.stop="dropToImg($event)"
            @dragover.stop="allowDrop($event)"
          />
          <div class="sentence-area">
            <p class="pinyin font-pinyin-medium font-pinyin-medium1">
              {{
                gameList && gameList.length ? gameList[currentIndex].pinyin : ''
              }}
            </p>
            <p class="font-hanzi-medium font-hanzi-medium1">
              {{
                gameList && gameList.length ? gameList[currentIndex].hanzi : ''
              }}
            </p>
          </div>
          <div class="correct-img">
            <img
              :src="
                gameList && gameList.length
                  ? gameList[currentIndex].correctImg
                  : ''
              "
              :class="{ showImg: isShowCorrectImg }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import Star from '@/components/Course/GamePage/Star'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  name: 'DragFruitsGame',
  components: {
    Star
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          questionLists: []
        }
      }
    },
    hasAudio: {
      type: Boolean,
      require: false,
      default: true
    }
  },
  data() {
    return {
      questionLists: [],
      gameList: [],
      optionList: [],
      correctAnswer: [],
      correctIndex: [],

      isShowCorrectImg: false,
      isCorrect: false,
      currentIndex: 0,
      isLastStep: false,
      starNum: 0,
      dragId: null,
      dropToImgSocketInfo: {},
      dragImgStrokeSocketInfo: {},
      audioSocketInfo: {},
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },

        buttons: [
          {
            type: 'look',
            startNumberRange: 0,
            endNumberRange: 100
          }
        ]
      },
      localData: {
        // 测试数据
        gameList: [
          {
            hanzi: '我很小。',
            pinyin: 'Wǒ hěn xiǎo.',
            correctImg: require('@/assets/img/16-GAMES/G22-drag-abc/lesson-40/1-ant-correct.svg')
          },
          {
            hanzi: '我的头发很长。',
            pinyin: 'Wǒ de tóufa hěn cháng.',
            correctImg: require('@/assets/img/16-GAMES/G22-drag-abc/lesson-40/2-man-correct.svg')
          },
          {
            hanzi: '我的个子很高。',
            pinyin: 'Wǒ de gèzi hěn gāo.',
            correctImg: require('@/assets/img/16-GAMES/G22-drag-abc/lesson-40/3-flamingo-correct.svg')
          },
          {
            hanzi: '我的眼睛很大。',
            pinyin: 'Wǒ de yǎnjing hěn dà.',
            correctImg: require('@/assets/img/16-GAMES/G22-drag-abc/lesson-40/4-owl-correct.svg')
          },
          {
            hanzi: '我的耳朵很长，眼睛很小。',
            pinyin: 'Wǒ de ěrduo hěn cháng, yǎnjing hěn xiǎo.',
            correctImg: require('@/assets/img/16-GAMES/G22-drag-abc/lesson-40/5-rabbit-correct.svg')
          }
        ],
        optionList: [
          [
            {
              id: 1,
              image: require('@/assets/img/16-GAMES/G22-drag-abc/lesson-40/1-ant.svg'),
              isCorrect: true
            },
            {
              id: 2,
              image: require('@/assets/img/16-GAMES/G22-drag-abc/lesson-40/1-hippo.svg')
            },
            {
              id: 3,
              image: require('@/assets/img/16-GAMES/G22-drag-abc/lesson-40/1-lion.svg')
            }
          ],
          [
            {
              id: 1,
              image: require('@/assets/img/16-GAMES/G22-drag-abc/lesson-40/2-cat.svg')
            },
            {
              id: 2,
              image: require('@/assets/img/16-GAMES/G22-drag-abc/lesson-40/2-mammoth.svg')
            },
            {
              id: 3,
              image: require('@/assets/img/16-GAMES/G22-drag-abc/lesson-40/2-man.svg'),
              isCorrect: true
            }
          ],
          [
            {
              id: 1,
              image: require('@/assets/img/16-GAMES/G22-drag-abc/lesson-40/3-chick.svg')
            },
            {
              id: 2,
              image: require('@/assets/img/16-GAMES/G22-drag-abc/lesson-40/3-swan.svg')
            },
            {
              id: 3,
              image: require('@/assets/img/16-GAMES/G22-drag-abc/lesson-40/3-flamingo.svg'),
              isCorrect: true
            }
          ],
          [
            {
              id: 1,
              image: require('@/assets/img/16-GAMES/G22-drag-abc/lesson-40/4-owl.svg'),
              isCorrect: true
            },
            {
              id: 2,
              image: require('@/assets/img/16-GAMES/G22-drag-abc/lesson-40/4-dog.svg')
            },
            {
              id: 3,
              image: require('@/assets/img/16-GAMES/G22-drag-abc/lesson-40/4-koala.svg')
            }
          ],
          [
            {
              id: 1,
              image: require('@/assets/img/16-GAMES/G22-drag-abc/lesson-40/6-elephant.svg')
            },
            {
              id: 2,
              image: require('@/assets/img/16-GAMES/G22-drag-abc/lesson-40/5-rabbit.svg'),
              isCorrect: true
            },
            {
              id: 3,
              image: require('@/assets/img/16-GAMES/G22-drag-abc/lesson-40/6-squirrel.svg')
            }
          ]
        ]
      },
      dragEndInfo: {}
    }
  },
  watch: {
    dropToImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2006201,
          data: { value },
          text: 'DragFruitsGame drag socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    dragImgStrokeSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2006202,
          data: { value },
          text: 'DragFruitsGame drag socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        // console.log('watch的数据', value)
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  mounted() {
    this.$bus.$on('dropFruitsStrokeEvent', () => {
      this.dropToImg('socket', true)
    })
    this.$bus.$on('dragToFruitsImgEvent', ({ item }) => {
      this.startDrag('socket', item, true)
    })
    this.$bus.$on("backOrigin", () => {
      this.endDrag(true)
    });
    this.initData()
  },
  beforeDestroy() {
    this.$bus.$off('dropFruitsStrokeEvent')
    this.$bus.$off('dragToFruitsImgEvent')
    this.$bus.$off('backOrigin')
  },
  methods: {
    initData() {
      if (
        this.componentProps.questionLists &&
        this.componentProps.questionLists.length
      ) {
        (this.gameList = []),
          (this.gameList = this.componentProps.questionLists.map((item) => {
            return {
              pinyin: item[0].value,
              hanzi: item[1].value,
              correctImg: item[3].value
            }
          }))
        // console.log(this.gameList, '111')

        this.correctAnswer = []
        this.componentProps.questionLists.forEach((item) => {
          if (item[3].value) {
            this.correctIndex = item[3].options.map((ele, index) => {
              if (item[3].value === ele.value) {
                this.correctAnswer.push(index)
                return index
              }
            })
          }
        })
        // console.log(this.correctAnswer)
        this.optionList = []
        this.optionList = this.componentProps.questionLists.map((item) => {
          return item[2].value.map((ele, index) => {
            return {
              index,
              image: ele.value,
              isCorrect: index === this.correctAnswer[this.starNum]
            }
          })
        })
        //  console.log(this.optionList,'222');
      }
    },
    nextPage() {
      this.$bus.$emit('nextButton', true)
    },
    startDrag(event, item, isFromSocket = false) {
      if (!isFromSocket) {
        this.dragImgStrokeSocketInfo = {
          item,
          value: Math.random()
        }
      }
      this.dragId = item.id
      this.isCorrect = item.isCorrect
    },
    dropToImg(event, isFromSocket = false) {
      if (!isFromSocket) {
        this.dropToImgSocketInfo = {
          value: Math.random()
        }
      }
      // event.preventDefault();
      if (this.isCorrect) {
        playCorrectSound(true, false)
        this.isShowCorrectImg = true

        setTimeout(() => {
          this.isShowCorrectImg = false
          this.starNum++
          this.currentIndex++
          this.initData()
          this.dragId = null
          if (this.currentIndex === this.gameList.length) {
            this.isLastStep = true
            playCorrectSound()
            startConfetti()
            this.currentIndex = this.gameList.length - 1
            this.isShowCorrectImg = true
          }
        }, 1500)
      } else {
        playCorrectSound(false)
      }
    },
    endDrag(isFromSocket = false) {
      console.log(8888);
      this.dragId = null
      if(!isFromSocket){
        const data = {
            clickType: 2000303,
            data: { value: Math.random() },
            text: 'DragGameEnd drag socket'
          }
          this.sendSocketInfo = { ...data }
          this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      }

    },
    Sdrop(event) {
      
      event.preventDefault()
    },
    allowDrop(event) {
      event.preventDefault()
    }
  }
}
</script>
<style lang="scss" scoped>
.drag-fruits-game-container {
  width: 100%;
  height: 100%;
  background: #fff;
}
.objectDisplay {
  position: absolute;
  width: 50%;
  bottom: 16%;
  left: 25%;
}
.plate {
  bottom: 10%;
  position: absolute;
  width: 60%;
  left: 20%;
}

.drag-fruits-game-container {
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    background-image: url('../../../../assets/img/16-GAMES/G22-drag-abc/background.svg');
    border-radius: 48px;
    display: flex;
    justify-content: center;
    align-items: center;

    .drag-area {
      border: white solid 3px;
      border-radius: 25px;
      background-color: #f7f4e0;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 15%;
      margin-right: 5%;
      .drag-item {
        width: 85%;
        -webkit-user-drag: auto;
        z-index: 5;
        cursor: pointer;
        margin-right: 5%;
      }
      .notShow {
        opacity: 0;
      }
      .notDisplay {
        visibility: hidden;
      }
    }
    .right-box {
      height: 70%;
      background: #f7f4e0;
      border-radius: 25px;
      width: 45%;
      border-bottom: 10px solid #b5392c;
      .right-content {
        width: 100%;
        height: 100%;
        border-radius: 10%;
        display: flex;
        flex-direction: column;
        position: relative;

        .drop-area {
          // background: #000;
          position: absolute;
          width: 71%;
          height: 55%;
          top: 32%;
          left: 16%;
          z-index: 6;
        }
        .sentence-area {
          width: 100%;
          flex: 1;
          background: #224e96;
          text-align: center;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-top-left-radius: 25px;
          border-top-right-radius: 25px;
          .font-hanzi-medium1 {
            font-size: 20px !important;
          }
          .font-pinyin-medium1 {
            font-size: 14px !important;
          }
        }
        .correct-img {
          width: 100%;
          flex: 2;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0;
          }
          .showImg {
            opacity: 1;
          }
        }
      }
    }
  }
}

img {
  &.animatedstar {
    display: block;
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    -webkit-animation-iteration-count: 1;
  }
}
</style>
