var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "game-dialog-container",
      style: {
        backgroundImage: "url('" + _vm.bgImg + "')",
        backgroundSize: "cover",
      },
    },
    [
      _c("div", { staticClass: "game-dialog-box" }, [
        _c(
          "div",
          { ref: "dialogRefs", staticClass: "gameDialog-content" },
          _vm._l(_vm.dialogList, function (item, index) {
            return _c(
              "div",
              {
                key: index + "dialog",
                staticClass: "dialog-item",
                class: { rightActive: item.position },
              },
              [
                _c("div", { staticClass: "dialog-avator" }, [
                  _c("img", {
                    attrs: {
                      src: item.dialogAvator
                        ? item.dialogAvator
                        : require("@/assets/img/18-Account/icon-camera.svg"),
                      alt: "",
                    },
                  }),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "dialog-common",
                    class: {
                      redColor: !item.position,
                      blueColor: item.position,
                    },
                  },
                  [
                    _c("p", { staticClass: "font-pinyin-medium pinyin" }, [
                      _vm._v(_vm._s(item.dialogPy)),
                    ]),
                    _c("p", { staticClass: "font-hanzi-medium" }, [
                      _vm._v(_vm._s(item.dialogValue)),
                    ]),
                  ]
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }