var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-find-picture" }, [
    _c(
      "div",
      { staticClass: "game-find-pic-content" },
      [
        _c("Star", {
          attrs: {
            totalStarNumber: _vm.totalStars,
            currentIndex: _vm.answersfound,
          },
        }),
        _c("div", { staticClass: "game-body" }, [
          _c(
            "div",
            {
              staticClass: "game-box",
              class: { "number-bg-color": !_vm.isNumberGame },
            },
            [
              _c("div", { staticClass: "game-left-box" }, [
                _c(
                  "div",
                  { staticClass: "game-aside-content" },
                  _vm._l(_vm.leftList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "item-content",
                        class: { "item-content-number": _vm.isNumberGame },
                        on: {
                          click: function ($event) {
                            return _vm.foundAnswer(item.id)
                          },
                        },
                      },
                      [
                        item.pinyin
                          ? _c(
                              "span",
                              { staticClass: "font-pinyin-medium pinyin" },
                              [_vm._v(_vm._s(item.pinyin))]
                            )
                          : _vm._e(),
                        _c("span", { staticClass: "font-hanzi-medium hanzi" }, [
                          _vm._v(_vm._s(item.element)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _vm._l(_vm.questionInfoList, function (item, index) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.question === index,
                        expression: "question === index",
                      },
                    ],
                    key: index + "question",
                    staticClass: "game-center-box",
                  },
                  [
                    item.bgImg
                      ? _c("img", {
                          staticClass: "objectDisplay fadeIn",
                          attrs: { src: item.bgImg },
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "number-area" }, [
                      _c("span", { staticClass: "font-pinyin-large pinyin" }, [
                        _vm._v(_vm._s(item.pinyin)),
                      ]),
                      _c("span", { staticClass: "font-hanzi-large hanzi" }, [
                        _vm._v(_vm._s(item.hanzi)),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "speaker-wrapper",
                        on: { click: _vm.playSoundWord },
                      },
                      [
                        _c("AudioPlayer", {
                          attrs: {
                            isPlay: _vm.isStartPlaying,
                            audioUrl: _vm.songUrl,
                            showAudioElement: index > 0 ? false : true,
                            title: item.hanzi,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              _c("div", { staticClass: "game-right-box" }, [
                _c(
                  "div",
                  { staticClass: "game-aside-content" },
                  _vm._l(_vm.rightList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "item-content",
                        class: { "item-content-number": _vm.isNumberGame },
                        on: {
                          click: function ($event) {
                            return _vm.foundAnswer(item.id)
                          },
                        },
                      },
                      [
                        item.pinyin
                          ? _c(
                              "span",
                              { staticClass: "font-pinyin-medium pinyin" },
                              [_vm._v(_vm._s(item.pinyin))]
                            )
                          : _vm._e(),
                        _c("span", { staticClass: "font-hanzi-medium" }, [
                          _vm._v(_vm._s(item.element)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ],
            2
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }