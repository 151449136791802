<template>
  <div class="game-mirror-pick-words-container">
    <div class="game-content">
      <Star :totalStarNumber="mirrorList.length" :currentIndex="starNo" />
      <div class="select-results">
        <img
          class="bg-img"
          :src=" bgImg ? bgImg : require('@/assets/img/16-GAMES/G03-mirror/mirror-background.svg')"
          alt=""
        />
        <div class="lesson-correct" v-if="rightGif === 1">
          <img src="@/assets/img/05-Panda/panda-correct.gif" alt="" />
        </div>
        <div class="lesson-error" v-if="rightGif === 0">
          <img src="@/assets/img/05-Panda/panda-wrong.gif" alt="" />
        </div>
      </div>
      <div
        class="content-area"
        v-for="(item, index) in mirrorList"
        v-show="currentImgIndex === index"
        :key="index + 'mirror'"
      >
        <!-- 主体内容 -->
        <div class="word-content">
          <div class="word-item-area">
            <div
              class="word-item-wo"
              :class="{hover1: item.firstOptionHanzi === hoverId}"
              @click.stop="handleCheckClick(0)"
              @mouseenter="getWordsId(item.firstOptionHanzi, (isHover = true))"
              @mouseleave="getWordsId(item.firstOptionHanzi, (isHover = false))"
            >
              <span class="pinyin font-pinyin-medium">{{
                item.firstOptionPinyin
              }}</span>
              <span class="font-hanzi-medium">{{ item.firstOptionHanzi }}</span>
            </div>
          </div>
          <div class="word-item-area">
            <div
              class="word-item-ni"
              :class="{hover: item.secondOptionHanzi === hoverId}"
              @click.stop="handleCheckClick(1)"
              @mouseenter="getWordsId(item.secondOptionHanzi, (isHover = true))"
              @mouseleave="getWordsId(item.secondOptionHanzi, (isHover = false))"
            >
              <span class="pinyin font-pinyin-medium">{{
                item.secondOptionPinyin
              }}</span>
              <span class="font-hanzi-medium">{{
                item.secondOptionHanzi
              }}</span>
            </div>
          </div>
        </div>
        <div class="mirror-img">
          <img :src="item.imgSrc" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import Star from '@/components/Course/GamePage/Star'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    Star
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          // firstOptionPinyin: '',
          // firstOptionHanzi: '',
          // secondOptionPinyin: '',
          // secondOptionHanzi: '',
          bgImg: '',
          mirrorPictureList: []
        }
      }
    }
  },
  mounted() {
    // this.initData()
    this.$bus.$on('changeMirrorImgSocket', ({ item }) => {
      this.handleCheckClick(item, true)
    })
    this.$bus.$on(
      'hoverSyllabusPage',
      ({ item, isHover }) => {
        
           if (isHover) {
            this.hoverId = item
          } else {
            this.hoverId = null
          }
               
      }
    )
  },
  beforeDestroy() {
    this.$bus.$off('changeMirrorImgSocket')
    this.$bus.$off('hoverSyllabusPage')
  },
  data() {
    return {
      firstOptionHanzi: '',
      firstOptionPinyin: '',
      secondOptionHanzi: '',
      secondOptionPinyin: '',
      checkSocketInfo: {},
      mirrorList: [],
      currentImgIndex: 0,
      rightGif: null,
      isLastStep: false,
      starNo: 0,
      hoverId: null,
      localData: {
        firstOptionHanzi: '我',
        firstOptionPinyin: 'wǒ',
        secondOptionHanzi: '你',
        secondOptionPinyin: 'nǐ',
        mirrorList: [
          {
            imgSrc: require('@/assets/img/16-GAMES/G03-mirror/mirror-1-ni.svg'),
            status: 1
          },
          {
            imgSrc: require('@/assets/img/16-GAMES/G03-mirror/mirror-2-wo.svg'),
            status: 0
          },
          {
            imgSrc: require('@/assets/img/16-GAMES/G03-mirror/mirror-3-ni.svg'),
            status: 1
          },
          {
            imgSrc: require('@/assets/img/16-GAMES/G03-mirror/mirror-4-wo.svg'),
            status: 0
          },
          {
            imgSrc: require('@/assets/img/16-GAMES/G03-mirror/mirror-5-ni.svg'),
            status: 1
          },
          {
            imgSrc: require('@/assets/img/16-GAMES/G03-mirror/mirror-6-wo.svg'),
            status: 0
          },
          {
            imgSrc: require('@/assets/img/16-GAMES/G03-mirror/mirror-7-wo.svg'),
            status: 0
          },
          {
            imgSrc: require('@/assets/img/16-GAMES/G03-mirror/mirror-8-ni.svg'),
            status: 1
          },
          {
            imgSrc: require('@/assets/img/16-GAMES/G03-mirror/mirror-9-wo.svg'),
            status: 0
          }
        ]
      },
      bgImg: ''
    }
  },
  methods: {
    initData() {},
    getWordsId(item, isHover) {
      // 获取hover元素的id      
      const data = {
        clickType: 20004,
        data: {
          value: {
            item,
            isHover
          }
        },
        text: '测试SyllabusPagePage组件内hover事件socket'
      }
      this.$bus.$emit('kids_webSocket_sendInfo', data)
    },
    /**
     * @description: 判断选择是否正确
     * @param {*} item
     * @return {*}
     */
    handleCheckClick(item, isFromSocket = false) {
      if (!isFromSocket) {
        this.checkSocketInfo = {
          value: Math.random(),
          item
        }
      }
      if (this.mirrorList[this.currentImgIndex].status === item) {
        playCorrectSound(true, false)
        this.rightGif = 1
        setTimeout(() => {
          this.currentImgIndex++
          this.starNo++
          if (this.currentImgIndex >= this.mirrorList.length) {
            this.isLastStep = true
            this.currentImgIndex = this.mirrorList.length - 1
            playCorrectSound()
            startConfetti()
          }
        }, 1000)
      } else {
        playCorrectSound(false)
        this.rightGif = 0
      }
      setTimeout(() => {
        this.rightGif = null
      }, 1000)
    }
  },
  watch: {
    checkSocketInfo: {
      handler(value) {
        const data = {
          clickType: 3000401,
          data: { value },
          text: 'practicePageThree页面按钮socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(newVal) {
        // console.log('componentProps', newVal)

        const { isTemplatePrview } = newVal
        if (isTemplatePrview) return
        this.initData()
        // this.firstOptionHanzi = newVal.firstOptionHanzi
        // this.firstOptionPinyin = newVal.firstOptionPinyin
        // this.secondOptionHanzi = newVal.secondOptionHanzi
        this.bgImg = newVal.bgImg
        // this.secondOptionPinyin = newVal.secondOptionPinyin
        const mirrorList = newVal.mirrorPictureList.map((item) => {
          return {
            imgSrc: item[0].value,
            firstOptionPinyin: item[1].value,
            firstOptionHanzi: item[2].value,
            secondOptionPinyin: item[3].value,
            secondOptionHanzi: item[4].value,
            status: item[5].value
          }
        })
        this.mirrorList = mirrorList
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.game-mirror-pick-words-container {
  width: 100%;
  height: 100%;
  .game-content {
    width: 100%;
    height: 100%;
    background-color: #f7f4e0;
    border-radius: 58px;
    overflow: hidden;
    position: relative;
    .customlesson-breakthrough-part {
      position: absolute;
      top: 0%;
      right: 10%;
      height: 40px;
      display: flex;
      padding: 10px 30px;
      background-color: #f4d737;
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
      border-bottom: 6px solid #d1b726;
      z-index: 1001;
      .demo {
        display: flex;
        .customlesson-breakthrough-part-book {
          margin-left: 5px;
          img {
            width: 25px;
            height: 35px;
          }
        }
      }
    }
    .word-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .word-item-area {
        width: 50%;
        height: 90%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: 27%;
        .word-item-ni {
          min-width: 110px;
          min-height: 120px;
          z-index: 1;
          border-radius: 15px;
          background-color: #224e96;
          border-bottom: 8px solid #143160;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          span {
            color: #fff;
            font-size: 25px;
          }
          span:nth-child(2) {
            color: #fff;
            font-size: 30px;
          }
        }
        .hover {
          background-color: #173d79;
          border-top: 8px solid #143160;
          border-bottom: 0px;
        }
        .word-item-ni:hover {
          z-index: 1;
          border-radius: 15px;
          background-color: #173d79;
          border-top: 8px solid #143160;
          border-bottom: 0px;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          span {
            color: #fff;
            font-size: 25px;
          }
          span:nth-child(2) {
            color: #fff;
            font-size: 30px;
          }
        }
        .word-item-wo {
          min-width: 110px;
          min-height: 120px;
          z-index: 1;
          border-radius: 15px;
          background-color: #cd4c3f;
          border-bottom: 8px solid #ac3d32;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          span {
            color: #fff;
            font-size: 25px;
          }
          span:nth-child(2) {
            color: #fff;
            font-size: 30px;
          }
        }
        .hover1 {
          background-color: #912016;
          border-top: 8px solid #6e0c03;
          border-bottom: 0px;
        }
        .word-item-wo:hover {
          border-radius: 15px;
          background-color: #912016;
          border-top: 8px solid #6e0c03;
          border-bottom: 0px;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          span {
            color: #fff;
            font-size: 25px;
          }
          span:nth-child(2) {
            color: #fff;
            font-size: 30px;
          }
        }
      }
    }

    .select-results {
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .bg-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .lesson-error {
        width: 35%;
        position: absolute;
        bottom: -1%;
        left: 10%;
        z-index: 5;
        img {
          width: 100%;
        }
      }
      .lesson-correct {
        width: 30%;
        position: absolute;
        bottom: -1%;
        left: 10%;
        z-index: 5;
        img {
          width: 100%;
        }
      }
    }
    .content-area {
      position: relative;

      .mirror-img {
        width: 25%;
        // height: 30%;
        position: absolute;
        top: 24%;
        left: 50%;
        transform: translate(-50%, 0);

        img {
          width: 100%;
          border-radius: 50%;
          border: 10px solid #d34343;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
