<template>
  <div class="game-choose-structure">
    <div class="game-main">
      <Star :totalStarNumber="optionList.length" :currentIndex="starNum" />
      <div class="title-area" v-if="titleInfo && Object.keys(titleInfo).length">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ titleInfo.hanzi }}
          </div>
        </div>
      </div>
      <div class="background-img-area">
        <div class="img-container">
          <div
            class="img-area"
            v-for="(item, index) in optionList"
            :key="index"
          >
            <!-- <img
              :src="item.image"
              alt=""
              v-if="currentArr.indexOf(item.id) != -1"
            /> -->
            <div class="img-all">
              <span
                v-if="currentArr.indexOf(item.id) != -1"
                class="img-answer title-hanzi"
                >{{ item.answer }}</span
              >
              <img class="bg-img" :src="item.bgIamge" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="bottonCont">
        <div class="redBackground"></div>
        <div class="bottonsWrapper">
          <div
            v-for="(item, index) in imgList[setp]"
            :key="index + 'step'"
            class="content-words-two"
            :class="{ bgcolor: !item.nobgcolor }"
            @click="foundAnswer(item.id)"
          >
            <!-- v-throttle -->
            <span class="font-pinyin-medium pinyin">{{ item.pinying }}</span>
            <span class="font-hanzi-medium">{{ item.hanzi }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 失败和成功背景 -->
    <div
      class="checkGif"
      :class="{ checkGifWrong: !checkGif }"
      v-checkgif="checkGif"
      v-if="isShowCheckGif"
    ></div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import Star from '@/components/Course/GamePage/Star'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    Star
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          StructureList: [],
          titleZh: '',
          titlePy: '',
          structureImg: ''
        }
      }
    }
  },
  mounted() {
    this.$bus.$on('RecognitionPinyinClick', ({ index }) => {
      this.foundAnswer(index, true)
    })
    this.$bus.$on('RecognitionPinyinAudio', () => {
      this.playPinyinAudio('socket', true)
    })
    // this.initData()
  },
  beforeDestroy() {
    this.$bus.$off('RecognitionPinyinClick')
    this.$bus.$off('RecognitionPinyinAudio')
  },
  data() {
    return {
      localData: {
        optionList: [
          {
            id: 1,
            image: require('@/assets/img/16-GAMES/G75-structure-choose-5/l2-lesson-02/hanzi-ni.svg')
          },
          {
            id: 2,
            image: require('@/assets/img/16-GAMES/G75-structure-choose-5/l2-lesson-02/hanzi-hao.svg')
          },
          {
            id: 3,
            image: require('@/assets/img/16-GAMES/G75-structure-choose-5/l2-lesson-02/hanzi-ma.svg')
          },
          {
            id: 4,
            image: require('@/assets/img/16-GAMES/G75-structure-choose-5/l2-lesson-02/hanzi-gou.svg')
          },
          {
            id: 5,
            image: require('@/assets/img/16-GAMES/G75-structure-choose-5/l2-lesson-02/hanzi-hen.svg')
          }
        ],
        imgList: [
          [
            {
              pinying: 'bù  ',
              hanzi: '不'
            },
            {
              pinying: 'qǐ ',
              hanzi: '起'
            },
            {
              id: 1,
              pinying: 'nǐ',
              hanzi: '你'
            }
          ],
          [
            {
              id: 2,
              pinying: 'hǎo ',
              hanzi: '好'
            },
            {
              pinying: 'qì  ',
              hanzi: '气'
            },
            {
              pinying: 'zuò',
              hanzi: '坐'
            }
          ],
          [
            {
              pinying: 'jǐ  ',
              hanzi: '几'
            },
            {
              id: 3,
              pinying: 'mā',
              hanzi: '妈'
            },
            {
              pinying: 'xīng',
              hanzi: '星'
            }
          ],
          [
            {
              id: 4,
              pinying: 'gǒu',
              hanzi: '狗'
            },
            {
              pinying: 'jiàn ',
              hanzi: '见'
            },
            {
              pinying: 'tiān',
              hanzi: '天'
            }
          ],
          [
            {
              id: 5,
              pinying: 'hěn  ',
              hanzi: '很'
            },
            {
              pinying: 'shuǐ ',
              hanzi: '水'
            },
            {
              pinying: 'yuè',
              hanzi: '月'
            }
          ]
        ],
        structureBgimg: require('@/assets/img/16-GAMES/G75-structure-choose-5/l2-lesson-02/hanzi-empty.svg'),
        titleInfo: {
          pinyin: 'zuǒyòu jiégòu',
          hanzi: '左右结构'
        }
      },
      optionList: [],
      imgList: [],
      steps: 0,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'speak',
            startNumberRange: 1,
            endNumberRange: 100
          }
        ]
      },
      structureBgimg: '',
      isLastStep: false,
      starNum: 0,
      currentIndex: 0,
      currentArr: [],
      socketInfo: {},
      audioSocketInfo: {},
      isStartPlaying: null,
      //   songUrl: this.imgList[0].audioSrc,
      isFadeOut: false,
      isFadeIn: false,
      checkGif: true,
      isShowCheckGif: false,
      setp: 0,
      titleInfo: {}
    }
  },
  watch: {
    socketInfo: {
      handler(value) {
        const data = {
          clickType: 20021,
          data: { value },
          text: 'RecognitionPinyinGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002101,
          data: { value },
          text: 'RecognitionPinyinGame点击小喇叭 socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  methods: {
    initData() {
      console.log(this.componentProps, '0000')
      if (this.componentProps.titlePy || this.componentProps.titleZh) {
        this.titleInfo = {
          hanzi: this.componentProps.titleZh,
          pinyin: this.componentProps.titlePy
        }
      }
      // this.structureBgimg = this.componentProps.structureImg
      this.optionList = []
      this.imgList = []
      if (
        this.componentProps.StructureList &&
        this.componentProps.StructureList.length
      ) {
        this.componentProps.StructureList.forEach((item, index) => {
          this.optionList.push({
            id: index + 1,
            // image: item[0].value,
            answer: item[7].value,
            bgIamge: item[0].value
          })
          this.imgList.push([
            {
              pinying: item[1].value,
              hanzi: item[2].value,
              id: item[7].value === item[2].value ? index + 1 : null
            },
            {
              pinying: item[3].value,
              hanzi: item[4].value,
              id: item[7].value === item[4].value ? index + 1 : null
            },
            {
              pinying: item[5].value,
              hanzi: item[6].value,
              id: item[7].value === item[6].value ? index + 1 : null
            }
          ])
        })
      }
      // console.log(this.imgList,'imgList');
    },
    foundAnswer(index, isFromSocket = false) {
      if (this.starNum >= this.optionList.length) {
        return
      }
      if (!isFromSocket) {
        this.socketInfo = {
          index,
          value: Math.random()
        }
      }
      if (this.optionList[this.currentIndex].id === index) {
        this.currentArr.push(index)
        this.currentArr = [...new Set(this.currentArr)]
        this.isShowCheckGif = true
        this.checkGif = true
        playCorrectSound(true, false)
        this.starNum++
        setTimeout(() => {
          this.isShowCheckGif = false
          this.setp++
        }, 1000)
        if (this.currentIndex + 1 < this.optionList.length) {
          this.currentIndex++
        } else {
          playCorrectSound()
          startConfetti()
          this.isLastStep = true
        }
      } else {
        playCorrectSound(false)
        this.isShowCheckGif = true
        this.checkGif = false
        setTimeout(() => {
          this.isShowCheckGif = false
        }, 1000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.game-choose-structure {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 48px;
  position: relative;
  .game-main {
    text-align: -webkit-center;
    position: absolute;
    height: 100%;
    width: 100%;
    background: url('../../../../assets/img/structure/background-structure.svg')
      no-repeat;
    background-size: cover;
    border-radius: 48px;
    .title-area {
      z-index: 9;
      position: absolute;
      top: 0;
      left: 0;
      top: 10%;
      padding: 20px 30px;
      width: auto;
      height: auto;
      background: #cd4c3f;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 28px;
      border-bottom-right-radius: 28px;
      color: #fff;
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }
    }
    .background-img-area {
      position: relative;
      width: -moz-fit-content;
      width: 100%;
      height: 100%;
      // top:30%;
      // margin-top: 30%;
      justify-content: center;
      align-items: center;
      z-index: 0;
      // border-radius: 10%;
      display: flex;
      .img-container {
        margin-top: -10%;
        background: url('../../../../assets/img/structure/background.svg')
          no-repeat;
        background-size: 100% 100%;
        width: 70%;
        height: 25%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 10px;
        .img-area {
          width: 10%;
          height: 50%;
          .img-all {
            position: relative;
            width: 100%;
            height: 100%;
            .img-answer {
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: 2;
              font-size: 40px;
              transform: translate(-50%, -50%);
            }
            img {
              width: 100%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            @media screen and (min-width: 1200px) {
              .img-answer {
                font-size: 50px;
              }
            }
            @media screen and (min-width: 1500px) {
              .img-answer {
                font-size: 60px;
              }
            }
            @media screen and (min-width: 1800px) {
              .img-answer {
                font-size: 70px;
              }
            }
          }
        }
      }
      .bgIamge {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 48px;

        &.isCover {
          object-fit: cover !important;
        }
      }
    }
    .bottonCont {
      bottom: 0;
      width: 80%;
      height: 150px;
      position: absolute;
      display: flex;
      justify-content: center;
      margin-left: 11%;

      .redBackground {
        width: 70%;
        height: 70%;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        background-color: #d87065;
        position: absolute;
        bottom: 0;
      }
      .bottonsWrapper {
        min-width: 300px;
        display: flex;
        bottom: 0;
        height: -moz-fit-content;
        justify-content: space-around;
        height: 100%;
        padding: 20px;
        position: absolute;
        padding: 10px 30px;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        background-color: #ffffff;
        align-items: center;
        .content-words-two {
          min-width: 100px;
          min-height: 100px;
          z-index: 1;
          border-radius: 15px;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          margin: 0 10px;
          cursor: pointer;
          span {
            color: #0a2550;
            font-size: 25px;
          }
          img {
            max-width: 80%;
          }
        }
      }
      .bgcolor {
        background-color: #f7f4e0;
        border-bottom: 10px solid #cfc8b0;
        border-top: 10px solid #fdfcf0;
        padding: 10px 10px;
        box-sizing: border-box;
        // max-width: 150px;
      }
      .bgcolor:hover {
        background-color: #f4d737;
        border-bottom: 10px solid #d1b726;
        border-top: 10px solid #fced9b;
      }
    }
  }
}
</style>
