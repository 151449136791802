var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "course-syllabus-container" }, [
    _c("div", { staticClass: "syllabus-box" }, [
      _c("img", {
        staticClass: "bg-img",
        attrs: {
          src: require("@/assets/img/03-Backgrounds/background-syllabus.svg"),
          alt: "",
        },
      }),
      _c(
        "div",
        { ref: "syllabusBox", staticClass: "syllabus-content" },
        _vm._l(_vm.wordList, function (item, listIndex) {
          return _c(
            "div",
            { key: listIndex + "i-can", staticClass: "syllabus-item" },
            [
              _c("div", { staticClass: "title font-lesson-subtitles" }, [
                _vm._v(" " + _vm._s(item.syllabusTitle) + " "),
              ]),
              _c("div", { staticClass: "content-common" }, [
                _c(
                  "div",
                  { staticClass: "word-line" },
                  _vm._l(item.syllabusContentList, function (s, sIndex) {
                    return _c(
                      "div",
                      {
                        key: sIndex + "syllabusContentList",
                        staticClass: "word-list",
                      },
                      [
                        _vm._l(s.syllabusContent, function (ele, index) {
                          return _c(
                            "div",
                            { key: index + "0", staticClass: "word-item" },
                            [
                              _c("Word", {
                                attrs: { wordInfo: ele, pageType: 1 },
                                on: {
                                  hoverTHisWords: function ($event) {
                                    return _vm.getWordsId(
                                      ele.id,
                                      ele.sortNo,
                                      listIndex,
                                      sIndex,
                                      undefined,
                                      true
                                    )
                                  },
                                  notHoverTHisWords: function ($event) {
                                    return _vm.getWordsId(
                                      ele.id,
                                      ele.sortNo,
                                      listIndex,
                                      sIndex,
                                      undefined,
                                      false
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        _c(
                          "div",
                          { staticClass: "img-list" },
                          _vm._l(s.syllabusImg, function (ele, index) {
                            return _c("img", {
                              key: index + "img",
                              attrs: { src: ele.value, alt: "" },
                            })
                          }),
                          0
                        ),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }