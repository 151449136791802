<template>
  <div class="game-recognition-container">
    <div class="game-recognition-content">
      <div class="game-background">
        <div class="backgroundImg_n_DragArea">
          <div class="imageWrapper">
            <img
              :class="{ objectDisplay: ObjectList[question].imgShape === 1 , objectCircle: ObjectList[question].imgShape === 2}"
              :src="ObjectList[question].image"
              v-if="
                ObjectList &&
                ObjectList[question] &&
                Object.keys(ObjectList[question]).length &&
                ObjectList[question].image
              "
            />
          </div>
          <div class="bottonsWrapper">
            <div
              v-for="(item, index) in chineseList"
              :key="index"
              class="content-words-two"
              @click="foundAnswer(index)"
              @mouseenter="getWordsId(index, (isHover = true))"
              @mouseleave="getWordsId(index, (isHover = false))"
            >
              <span class="font-pinyin-medium pinyin">{{ item.pinying }}</span>
              <span class="font-hanzi-medium hanzi">{{ item.hanzi }}</span>
            </div>
          </div>
        </div>

        <div class="strokesFoundBoxes">
          <div
            class="outerBox"
            v-for="(item, index) in chineseList"
            :key="index + 'answer'"
          >
            <img
              v-if="answersfound > index"
              class="strokesFoundimage"
              :src="strockFoundImage"
            />
            <img v-else class="strokesFoundimage" :src="strockNotFoundImage" />
          </div>
        </div>
      </div>
      <CommonButton
        @shuffleClickEvent="handleShuffleClickEvent"
        v-if="chineseList && chineseList.length"
      />
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
import CommonButton from "../GameComponent/CommonButton.vue";
export default {
  mixins: [templatePreviewMixin],
  components: {
    CommonButton
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          gameRecognitionList: []
        }
      }
    }
  },
  data() {
    return {
      chineseList: [],
      ObjectList: [],
      question: 0,
      foundAnswerValue: 0,
      answersfound: 0,
      success_audio_sound: require('@/assets/audio/correct_answer.mp3'),
      wrong_audio_sound: require('@/assets/audio/wrong_answer.mp3'),
      strockNotFoundImage: require('@/assets/img/16-GAMES/G21-puzzle/star.svg'),
      strockFoundImage: require('@/assets/img/16-GAMES/G21-puzzle/star-complete.svg'),
      objectDisplay: '',
      socketInfo: {},
      localData: {
        chineseList: [
          {
            id: 1,
            pinying: 'sì',
            number: 4,
            hanzi: '四'
          },
          {
            id: 5,
            pinying: 'sān',
            number: 3,
            hanzi: '三'
          },
          {
            id: 3,
            pinying: 'yī',
            number: 1,
            hanzi: '一'
          },
          {
            id: 2,
            pinying: 'wǔ',
            number: 5,
            hanzi: '五'
          },
          {
            id: 4,
            pinying: 'èr',
            number: 2,
            hanzi: '二'
          }
        ],
        ObjectList: [
          {
            id: 4,
            image: require('@/assets/img/16-GAMES/G41-choose-abcde/game-four.svg'),
            number: 4,
            showObj: true
          },
          {
            id: 2,
            image: require('@/assets/img/16-GAMES/G41-choose-abcde/game-two.svg'),
            number: 2,
            showObj: true
          },
          {
            id: 3,
            image: require('@/assets/img/16-GAMES/G41-choose-abcde/game-three.svg'),
            number: 3,
            showObj: true
          },
          {
            id: 1,
            image: require('@/assets/img/16-GAMES/G41-choose-abcde/game-one.svg'),
            number: 1,
            showObj: true
          },
          {
            id: 5,
            image: require('@/assets/img/16-GAMES/G41-choose-abcde/game-five.svg'),
            number: 5,
            showObj: true
          }
        ]
      },
      shuffleSocketInfo: {},
      shuffleSocketValue: null,
    }
  },
  mounted() {
    this.$bus.$on('RecognitionNumberClick', ({ index }) => {
      this.foundAnswer(index, true)
    }),
    this.$bus.$on("shuffleArray", ({ ramdomList,orderList }) => {
      this.chineseList = [];
      this.chineseList = ramdomList;
      this.ObjectList = []
      this.ObjectList = orderList

      this.shuffleSocketValue = true;
    });
    this.$bus.$on(
      'hoverSyllabusPage',
      ({ index, isHover }) => {
            this.isHover = isHover
               
      }
    )
  },
  beforeDestroy() {
    this.$bus.$off('RecognitionNumberClick')
    this.$bus.$off('shuffleArray')
    this.$bus.$off('hoverSyllabusPage')
  },
  watch: {
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    },
    shuffleSocketInfo: {
      handler(value) {
        if (!this.shuffleSocketValue) {
          const data = {
            clickType: 2001004,
            data: { value },
            text: "DragGameBtn shuffleSocketValue",
          };
          console.log(data);
          this.$bus.$emit("kids_webSocket_sendInfo", data);
        }
      },
      deep: true,
    },
    socketInfo: {
      handler(value) {
        const data = {
          clickType: 20019,
          data: { value},
          text: "RecognitionNumberGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    
    /**
     * @description: 乱序回调
     * @return {*}
     */
     handleShuffleClickEvent() {
      console.log("乱序");
      const orderList = this.shuffleArray(this.chineseList);
      const list = this.shuffleArray(this.ObjectList)
      this.chineseList = [];
      this.chineseList = orderList;
      this.ObjectList = []
      this.ObjectList = list

      this.shuffleSocketValue = false;

      this.shuffleSocketInfo = {
        ramdomList: this.chineseList,
        orderList: this.ObjectList,
        ramdomIndex: Math.random(),
      };
    },
    getWordsId(index, isHover) {
      // 获取hover元素的id      
      const data = {
        clickType: 20004,
        data: {
          value: {
            index,
            isHover
          }
        },
        text: '测试SyllabusPagePage组件内hover事件socket'
      }
      this.$bus.$emit('kids_webSocket_sendInfo', data)
    },
        /**
     * @description: 数组乱序
     * @param {*} array
     * @return {*}
     */
    shuffleArray(array) {
      let currentIndex = array.length,
        temporaryValue,
        randomIndex;

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      console.log(array);

      return array;
    },
    initData() {
      this.chineseList = []
      this.ObjectList = []
      //   console.log(this.componentProps.gameRecognitionList, '----------')
      this.componentProps.gameRecognitionList.forEach((item, index) => {
        this.chineseList.push({
          id: index + 1,
          pinying: item[3].value,
          hanzi: item[2].value,
          number: index + 1
        })
        this.ObjectList.push({
          id: index + 1,
          image: item[0].value,
          showObj: true,
          number: index + 1,
          imgShape: item[1].value ? item[1].value : 1
        })
      })
      // this.chineseList = this.shuffleArray(this.chineseList)
      // this.ObjectList = this.shuffleArray(this.ObjectList)

      // console.log(this.chineseList, this.ObjectList, '--*****-----')
    },
    foundAnswer(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.socketInfo = {
          index,
          value: Math.random()
        }
        this.foundAnswerValue++
      }

      if (
        this.chineseList[index].number == this.ObjectList[this.question].number
      ) {
        //  this.objectDisplay = this.ObjectList[this.question].image
        // this.ObjectList[this.question].showObj = false;
        this.answersfound++
        this.timer = setTimeout(() => {
          if (this.question + 1 < this.ObjectList.length) {
            this.question++
            this.objectDisplay = ''
          }
          //   else {
          //     this.isLastStep = true
          //   }
        }, 1000)
        if (this.answersfound == this.chineseList.length) {
          //   this.isLastStep = true
          playCorrectSound()
          startConfetti()
        } else {
          playCorrectSound(true, false)
        }
      } else {
        playCorrectSound(false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.game-recognition-container {
  width: 100%;
  height: 100%;
  .game-recognition-content {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 48px;
    position: relative;
    overflow: hidden;
    .game-background {
      text-align: -webkit-center;
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../../../../assets/img/03-Backgrounds/background-practice.svg');
      border-radius: 48px;
      margin: auto;
      overflow: hidden;
    }
    .backgroundImg_n_DragArea {
      position: relative;
      width: -moz-fit-content;
      background-color: #214e96;
      width: 70%;
      height: 75%;
      top: 12%;
      /* left: 10%; */
      flex-direction: column;
      border-radius: 30px;
      display: flex;

      .imageWrapper {
        position: absolute;
        width: 90%;
        height: 60%;
        background: #e6e6e6;
        top: 5%;
        left: 5%;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        .objectDisplay {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 20px;
        }
        .objectCircle {
          height: 100%;
          border-radius: 20px;
        }
      }
      .bottonsWrapper {
        display: flex;
        bottom: 0;
        height: -moz-fit-content;
        justify-content: center;
        height: 30%;
        width: 80%;
        position: absolute;
        left: 10%;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        justify-content: space-evenly;
        align-items: center;
      }
      .content-words-two {
        height: 10vh;
        width: 10vh;
        z-index: 1;
        border-radius: 15px;
        background-color: #cd4c3f;
        border-bottom: 8px solid #ac3d32;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin: 0 4px;
        span {
          color: #fff;
          font-size: 25px;
        }
        span:nth-child(2) {
          color: #fff;
          font-size: 30px;
        }
        .pinyin {
          font-size: 11px;
        }
        .hanzi {
          font-size: 20px;
        }
      }
      .hover {
        background-color: #912016;
        border-top: 8px solid #6e0c03;
      }
      .content-words-two:active {
        transform: scale(0.95);
      }
      .content-words-two:hover {
        transform: scale(1.1);
        border-radius: 15px;
        background-color: #912016;
        border-top: 8px solid #6e0c03;
        border-bottom: 0px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        span {
          color: #fff;
          font-size: 25px;
        }
        span:nth-child(2) {
          color: #fff;
          font-size: 30px;
        }
      }
    }
    .strokesFoundBoxes {
      position: absolute;
      right: 11%;
      top: 0;
      height: fit-content;
      padding: 5px 25px 15px 25px;
      display: flex;
      flex-direction: row;
      background-color: #f4d737;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      border-bottom: 10px solid #d1b726;
      .strokesFoundimage {
        height: 100%;
        width: 27px;
        margin-top: 20%;
      }
      .outerBox {
        width: 100%;
        height: 100%;
        padding: 0px 4px;
        position: relative;
        display: flex;

        .innerBox {
          width: 80%;
          height: 80%;
          margin: 10%;
          border-radius: 15px;
        }

        .innerBoxFound {
          width: 80%;
          height: 80%;
          margin: 10%;
          border-radius: 15px;

          .strokesFoundimage {
            height: 65%;
            margin-top: 30%;
            margin-right: 5%;
          }
        }
      }
    }
  }
}
</style>
