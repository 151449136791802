var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-pinyin-summary" }, [
    _c(
      "div",
      { staticClass: "pinyin-summary-content" },
      [
        _c(
          "div",
          { staticClass: "check-area" },
          [
            _c("img", { staticStyle: {}, attrs: { src: _vm.bgImg, alt: "" } }),
            _vm._l(_vm.pinyinImgList, function (item, index) {
              return [
                _vm.pinyinIndexArr.indexOf(index) > -1
                  ? _c("img", {
                      key: index + "pinyin",
                      staticClass: "pinyin-image",
                      attrs: { src: item, alt: "" },
                    })
                  : _vm._e(),
              ]
            }),
            _vm._l(4, function (item, index) {
              return _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.lesson !== 15,
                    expression: "lesson !== 15",
                  },
                ],
                key: index + "check",
                staticClass: "check-item",
                class: "toneArea-" + index,
                on: {
                  click: function ($event) {
                    return _vm.handleClickTone(index)
                  },
                },
              })
            }),
            _c(
              "div",
              { class: "check-item-pinyin-" + _vm.lesson },
              _vm._l(_vm.pinyinImgList.length, function (item, index) {
                return _c("div", {
                  key: index + "check1",
                  staticClass: "check-item",
                  class: "pinyinArea-" + index,
                  on: {
                    click: function ($event) {
                      return _vm.handleClickPinyin(index)
                    },
                  },
                })
              }),
              0
            ),
          ],
          2
        ),
        _vm._l(_vm.toneImgList, function (item, index) {
          return _c("div", { key: index + "tone", staticClass: "tone-area" }, [
            _vm.toneIndexArr.indexOf(index) > -1
              ? _c("img", { attrs: { src: item, alt: "" } })
              : _vm._e(),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }