<template>
  <div class="game-find-element-container">
    <div class="game-find-element-content" :class="'lesson-' + gameType">
      <div class="title-area" v-if="Object.keys(titleInfo).length">
        <div class="title">
          <div class="title-pinyin pinyin">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi">
            {{ titleInfo.hanzi }}
          </div>
        </div>
      </div>
      <img :src="bgImg" class="bg-img" />
      <div
        class="img-area"
        v-for="(item, index) in imgList"
        :key="index"
        v-show="findIdList.indexOf(item.id) !== -1"
      >
        <img :src="item.image" alt="" />
      </div>

      <div class="click-area">
        <div
          class="click-item"
          v-for="(item, index) in imgList"
          :class="'click-' + item.id"
          :key="index + 'click'"
          @click="handleClick(item.id)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          titleHanzi: '',
          titlePy: '',
          bgImg: '',
          clickImgList: [],
          gameType: null
        }
      }
    }
  },
  data() {
    return {
      imgList: [      
      ],
      bgImg: 'http://test.nihaokids.com/img/background.2c7cd841.svg',
      titleInfo: {
      },
      gameType: 59,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
        ]
      },
      findIdList: [],
      clickSocketInfo: {},
      localData: {
        imgList: [
        {
          id: 1,
          image: 'http://test.nihaokids.com/img/number-45.6c96cfe1.svg'
        },
        {
          id: 2,
          image: 'http://test.nihaokids.com/img/number-30.48341706.svg'
        },
        {
          id: 3,
          image: 'http://test.nihaokids.com/img/number-12.e5783992.svg'
        },
        {
          id: 4,
          image: 'http://test.nihaokids.com/img/number-7.5387a72c.svg'
        },
        {
          id: 5,
          image: 'http://test.nihaokids.com/img/number-3.6bf02a92.svg'
        }
      ],
      titleInfo: {
        pinyin: '',
        hanzi: '3、7、12、30、45'
      },
      buttons: [
          {
            type: 'look',
            startNumberRange: 1,
            endNumberRange: 100
          }
        ]
      }
    }
  },
  watch: {
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20064,
          data: { value },
          text: 'FamilyTreeGame drop socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        this.initData()
      },
      deep: true
    }
  },
  mounted() {
    this.$bus.$on('clickToFindElement', ({ id }) => {
      this.handleClick(id, true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickToFindElement')
  },
  methods: {
    initData() {
      //   console.log('initData')
      this.titleInfo = {
        pinyin: this.componentProps.titlePy,
        hanzi: this.componentProps.titleHanzi
      }
      this.gameType = this.componentProps.gameType
      this.bgImg = this.componentProps.bgImg
      this.imgList = this.componentProps.clickImgList.map((item, index) => {
        return {
          id: index + 1,
          image: item.value
        }
      })
    },
    handleClick(id, isFromSocket) {
      if (!isFromSocket) {
        this.clickSocketInfo = {
          id,
          value: Math.random()
        }
      }
      if (this.findIdList.indexOf(id) !== -1) {
        return
      }
      this.findIdList.push(id)
      // this.findIdList = Array.from(new Set(this.findIdList));
      playCorrectSound(true, false)
      if (this.findIdList.length === this.imgList.length) {
        playCorrectSound()
        startConfetti()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.game-find-element-container {
  width: 100%;
  height: 100%;
  line-height: 0;
  .game-find-element-content {
    width: 100%;
    height: 100%;
    position: relative;
    .title-area {
      z-index: 9;
      position: absolute;
      top: 0;
      left: 0;
      top: 10%;
      width: auto;
      height: auto;
      padding: 20px;
      background: #cd4c3f;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      color: #fff;
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
          font-size: 18px;
        }
      }
    }
    .bg-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 58px;
      z-index: 2;
    }
    .img-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: 4;
      }
    }
    .click-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      .click-item {
        position: absolute;
        width: 20%;
        height: 10%;
        left: 0;
        top: 0;
        cursor: pointer;
      }
    }
  }
  .lesson-30 {
    .click-1 {
        width: 6% !important;
    height: 24% !important;
    left: 75% !important;
    top: 30% !important;
    }
    .click-2 {
        width: 7% !important;
    height: 30% !important;
    left: 85% !important;
    top: 22% !important;
    }
    .click-3 {
      width: 5% !important;
      height: 24% !important;
      left: 15% !important;
      top: 47% !important;
    }
    .click-4 {
      width: 10% !important;
      height: 10% !important;
      left: 47% !important;
      top: 70% !important;
    }
    .click-5 {
      width: 11% !important;
      height: 10% !important;
      left: 39% !important;
      top: 47% !important;
    }
    .click-6 {
      width: 4% !important;
      height: 10% !important;
      left: 40% !important;
      top: 14% !important;
    }
  }

  .lesson-35 {
    .click-1 {
      width: 4% !important;
      height: 5% !important;
      left: 25% !important;
      top: 16% !important;
    }
    .click-2 {
      width: 12% !important;
      height: 5% !important;
      left: 46% !important;
      top: 15% !important;
    }
    .click-3 {
      width: 6% !important;
      height: 5% !important;
      left: 70% !important;
      top: 23% !important;
    }
    .click-4 {
      width: 6% !important;
      height: 6% !important;
      left: 31% !important;
      top: 60% !important;
    }
    .click-5 {
      width: 6% !important;
      height: 6% !important;
      left: 58% !important;
      top: 64% !important;
    }
  }
  .lesson-51 {
    .click-1 {
      width: 12% !important;
      height: 24% !important;
      left: 29% !important;
      top: 36% !important;
    }
    .click-2 {
      width: 12% !important;
      height: 24% !important;
      left: 44% !important;
      top: 36% !important;
    }
    .click-3 {
      width: 12% !important;
      height: 24% !important;
      left: 59% !important;
      top: 36% !important;
    }
    .click-4 {
      width: 12% !important;
      height: 24% !important;
      left: 29% !important;
      top: 69% !important;
    }
    .click-5 {
      width: 12% !important;
      height: 24% !important;
      left: 44% !important;
      top: 69% !important;
    }
    .click-6 {
      width: 12% !important;
      height: 24% !important;
      left: 59% !important;
      top: 69% !important;
    }
  }
  .lesson-57 {
    .click-1 {
      width: 25% !important;
      height: 24% !important;
      left: 22% !important;
      top: 24% !important;
    }
    .click-2 {
      width: 25% !important;
      height: 24% !important;
      left: 58% !important;
      top: 24% !important;
    }
    .click-3 {
      width: 25% !important;
      height: 24% !important;
      left: 22% !important;
      top: 55% !important;
    }
    .click-4 {
      width: 25% !important;
      height: 24% !important;
      left: 58% !important;
      top: 55% !important;
    }
  }
  .lesson-59 {
    .click-1 {
      width: 18% !important;
      height: 20% !important;
      left: 67% !important;
      top: 25% !important;
    }
    .click-2 {
      width: 13% !important;
      height: 20% !important;
      left: 11% !important;
      top: 60% !important;
    }
    .click-3 {
      width: 11% !important;
      height: 16% !important;
      left: 34% !important;
      top: 0% !important;
    }
    .click-4 {
      width: 13% !important;
      height: 13% !important;
      left: 11% !important;
      top: 71% !important;
    }
    .click-5 {
      width: 11% !important;
      height: 16% !important;
      left: 42% !important;
      top: 5% !important;
    }
  }
  .lesson-61 {
    .click-1 {
      width: 6% !important;
      height: 9% !important;
      left: 23% !important;
      top: 35% !important;
    }
    .click-2 {
      width: 6% !important;
      height: 9% !important;
      left: 40% !important;
      top: 35% !important;
    }
    .click-3 {
      width: 6% !important;
      height: 9% !important;
      left: 32% !important;
      top: 44% !important;
    }
    .click-4 {
      width: 6% !important;
      height: 9% !important;
      left: 65% !important;
      top: 54% !important;
    }
    .click-5 {
      width: 6% !important;
      height: 9% !important;
      left: 40% !important;
      top: 72% !important;
    }
  }
}
</style>
