var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rank-map-page-container", class: "lesson" + _vm.lesson },
    [
      _c("transition", { attrs: { name: "el-fade-in" } }, [
        _vm.position === 1 && _vm.maskShow
          ? _c("div", { staticClass: "mask-book" }, [
              _c("div", { staticClass: "mask-book-content" }, [
                _c("span", { staticClass: "font28" }, [
                  _vm._v("CHAPTER " + _vm._s(_vm.chapterNum)),
                ]),
                _c("img", {
                  class: { glow: _vm.position == 1 },
                  attrs: {
                    src: require("@/assets/img/10-Level-progress/progress-level" +
                      _vm.lesson +
                      ".gif"),
                    alt: "",
                  },
                }),
                _c("img", {
                  staticClass: "confetti-img",
                  attrs: {
                    src: require("@/assets/img/07-Stars/confetti.svg"),
                    alt: "",
                  },
                }),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "lesson_content" }, [
        _c("div", { staticClass: "customlesson-breakthrough-part" }, [
          _c(
            "div",
            { staticClass: "demo" },
            _vm._l(8, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "customlesson-breakthrough-part-book",
                },
                [
                  _vm.chapterNum < index + 1
                    ? _c("span", [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/10-Level-progress/book-cream.svg"),
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.chapterNum > index + 1
                    ? _c("span", [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/10-Level-progress/book-100.svg"),
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.chapterNum === index + 1
                    ? _c("span", [
                        _c("img", {
                          class: {
                            glow: _vm.position == 1 && _vm.chapterNum === 8,
                          },
                          attrs: {
                            src: require("@/assets/img/10-Level-progress/lesson-" +
                              (_vm.position == 1
                                ? _vm.lesson
                                : _vm.lesson - 1) +
                              "-complete.svg"),
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ]),
        _c(
          "div",
          { staticClass: "customlesson-breakthrough-part-movement" },
          [
            _c("transition", { attrs: { name: "el-fade-in" } }, [
              _c("div", { ref: "pictureBox", staticClass: "picture" }, [
                _c(
                  "div",
                  {
                    staticClass: "star-area",
                    style: { width: _vm.starWidth + "px" },
                  },
                  [
                    _vm.position === 1 && _vm.imageStep === 2
                      ? _c("img", {
                          staticClass: "animatedstar",
                          class:
                            "chapterStar-" +
                            (_vm.chapterNum > 4
                              ? _vm.chapterNum - 4
                              : _vm.chapterNum),
                          attrs: {
                            src: require("@/assets/img/07-Stars/star-big.svg"),
                            alt: "",
                          },
                        })
                      : _vm._e(),
                  ]
                ),
                _c("div", { staticClass: "picture-item" }, [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.imageStep === 1,
                        expression: "imageStep === 1",
                      },
                    ],
                    staticClass: "background-image",
                    attrs: { src: _vm.firstImg, alt: "" },
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.imageStep === 2,
                        expression: "imageStep === 2",
                      },
                    ],
                    ref: "imgContent",
                    staticClass: "background-image",
                    attrs: { src: _vm.lastImg, alt: "" },
                    on: { load: _vm.handleImageLoaded },
                  }),
                ]),
                _c(
                  "div",
                  { class: "level" + _vm.levelNum },
                  _vm._l(8, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index + "img",
                        staticClass: "image-item",
                        class:
                          "chapter-" +
                          (_vm.chapterNum > 4
                            ? _vm.chapterNum - 4
                            : _vm.chapterNum),
                      },
                      [
                        index + 1 === _vm.lesson
                          ? _c("div", { class: "step steps-" + (index + 1) }, [
                              _c("div", { staticClass: "img-one" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/09-Level-map/bubble-lesson.svg"),
                                    alt: "",
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "img-two" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/12-Vocabulary/lesson-01-wo.svg"),
                                    alt: "",
                                  },
                                }),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }