<template>
  <div class="speaking-by-turntable-game-container"
  :style="{ backgroundImage: 'url(\'' + bgImg + '\')' }"
  >
    <div
      class="game-content"
    >
      <Star
        :totalStarNumber="correctIdLisdt.length"
        :currentIndex="starNo"
        v-if="correctIdLisdt.length"
      />

      <div class="overturn-puzzle-area">
        <div class="game-area">
          <img
            ref="imgBox"
            v-if="puzzleBgImg"
            class="puzzle-bg-img"
            :src="puzzleBgImg"
            @load="getDomHeight"
          />
          <div
            class="map-area"
            :style="{ width: imgHeight + 'px', height: imgHeight + 'px' }"
          >
            <div class="map-box">
              <div
                class="col-item"
                v-for="(item, index) in mapList"
                :key="index + 'col'"
              >
                <div
                  class="row-item"
                  v-for="(ele, indexSon) in item"
                  :ref="'S' + ele.index"
                  :key="indexSon + 'row'"
                  :class="{
                    noPointerEvents: dispearIdList.indexOf(ele.id) > -1
                  }"
                  @click="handleOverturn(ele.id)"
                ></div>
              </div>
            </div>

            <div class="hanzi-img-area">
              <template v-for="(item, index) in imgLists">
                <transition name="el-fade-in" :key="index">
                  <img
                    :src="item.img"
                    alt=""
                    :key="index"
                    v-show="dispearIdList.indexOf(index + 1) === -1"
                  />
                </transition>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import Star from '@/components/Course/GamePage/Star'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  name: 'OverturnPuzzleGame',
  mixins: [templatePreviewMixin],
  components: {
    Star
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          bgImg: '',
          puzzleBgImg: '',
          puzzleImgList: []
        }
      }
    }
  },
  watch: {
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20047,
          data: { value },
          text: 'OverturnPuzzleGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },

  data() {
    return {
      bgImg: '',
      // imgList: [],
      imgLists: [],
      puzzleBgImg: '',
      localData: {
        correctIdLisdt: [2, 3, 4, 6, 7, 8],
        bgImg: require('@/assets/img/03-Backgrounds/level-1-chapter-2-background-blue.svg'),
        puzzleBgImg: require('@/assets/img/16-GAMES/G58-overturn-puzzle/lesson-37/puzzle-base.svg'),
        imgLists: [
          {
            img: require('@/assets/img/16-GAMES/G58-overturn-puzzle/lesson-37/puzzle-1.svg'),
            id: 1
          },
          {
            img: require('@/assets/img/16-GAMES/G58-overturn-puzzle/lesson-37/puzzle-2.svg'),
            id: 2
          },
          {
            img: require('@/assets/img/16-GAMES/G58-overturn-puzzle/lesson-37/puzzle-3.svg'),
            id: 3
          },
          {
            img: require('@/assets/img/16-GAMES/G58-overturn-puzzle/lesson-37/puzzle-4.svg'),
            id: 4
          },
          {
            img: require('@/assets/img/16-GAMES/G58-overturn-puzzle/lesson-37/puzzle-5.svg'),
            id: 5
          },
          {
            img: require('@/assets/img/16-GAMES/G58-overturn-puzzle/lesson-37/puzzle-6.svg'),
            id: 6
          },
          {
            img: require('@/assets/img/16-GAMES/G58-overturn-puzzle/lesson-37/puzzle-7.svg'),
            id: 7
          },
          {
            img: require('@/assets/img/16-GAMES/G58-overturn-puzzle/lesson-37/puzzle-8.svg'),
            id: 8
          },
          {
            img: require('@/assets/img/16-GAMES/G58-overturn-puzzle/lesson-37/puzzle-9.svg'),
            id: 9
          }
        ]
      },
      mapList: [
        [
          { index: 1, id: 1 },
          { index: 4, id: 4 },
          { index: 7, id: 7 }
        ],
        [
          { index: 2, id: 2 },
          { index: 5, id: 5 },
          { index: 8, id: 8 }
        ],
        [
          { index: 3, id: 3 },
          { index: 6, id: 6 },
          { index: 9, id: 9 }
        ]
      ],
      correctIdLisdt: [],
      notClickIdList: [],
      clickIndex: 0,
      isLastStep: false,
      clickSocketInfo: {},
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'look',
            startNumberRange: 0,
            endNumberRange: 100
          }
        ]
      },
      imgDom: null,
      imgHeight: null,
      starNo: 0,
      isFinishLoad: false,
      dispearIdList: []
    }
  },
  created() {
    this.dispearIdList = JSON.parse(JSON.stringify(this.notClickIdList))
  },
  mounted() {
    this.$bus.$on('ClickOverturnImg', ({ id }) => {
      this.handleOverturn(id, true)
      // console.log(id)
    })
    // this.initData()
  },
  beforeDestroy() {
    this.$bus.$off('ClickOverturnImg')
  },
  methods: {
    initData() {
      this.imgLists = []
      this.bgImg = this.componentProps.bgImg
      this.puzzleBgImg = this.componentProps.puzzleBgImg
      this.componentProps.puzzleImgList.forEach((item, index) => {
        if (item[0].value) {
          this.imgLists.push({
            img: item[0].value,
            isCorrect: item[1].value
          })
          // console.log(this.componentProps.puzzleImgList, '111')
        }
      })
      this.correctIdLisdt = this.componentProps.puzzleImgList
        .map((item, index) => {
          return {
            ...item[1],
            index
          }
        })
        .filter((item) => !item.value)
        .map((item) => item.index + 1)
    },
    getDomHeight() {
      this.imgDom = this.$refs.imgBox
      this.imgHeight = this.imgDom.getBoundingClientRect().height
    },
    nextPage() {
      this.$bus.$emit('nextButton', true)
    },
    handleOverturn(id, isFromSocket = false) {
      // console.log(id)
      if (!isFromSocket) {
        this.clickSocketInfo = {
          id,
          value: Math.random()
        }
      }
      this.dispearIdList.push(id)
      if (!this.correctIdLisdt.length) {
        playCorrectSound(true, false)
        if (this.dispearIdList.length === this.imgLists.length) {
          this.isLastStep = true
          startConfetti()
          playCorrectSound()
        }
      } else {
        if (this.correctIdLisdt.indexOf(id) > -1) {
          playCorrectSound(true, false)
          this.starNo++
          if (this.starNo === this.correctIdLisdt.length) {
            this.isLastStep = true
            startConfetti()
            playCorrectSound()
          }
        } else {
          playCorrectSound(false)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.speaking-by-turntable-game-container {
  width: 100%;
  height: 100%;
  line-height: 1;
  // line-height: 0;
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 48px;
    .puzzle-area {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .overturn-img-area {
        width: 60%;
        height: 50%;
        position: relative;
        .puzzle-bg-img {
          width: 50%;
        }
        .notShow {
          opacity: 0;
        }
        .img-item {
          position: absolute;
          top: 0;
          left: 0;
          img {
            width: 100%;
          }
        }
      }
    }

    .overturn-puzzle-area {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .game-area {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .puzzle-bg-img {
          width: 65%;
          height: 65%;
          object-fit: cover;
          min-width: 400px;

          // border-bottom: 30px solid #cd4c3f;
          // border-bottom-left-radius: 30px;
          // border-bottom-right-radius: 30px;
        }
        .map-area {
          position: absolute;
          margin: auto;
          display: flex;

          .map-box {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            .col-item {
              z-index: 100;
              flex: 1;
              display: flex;
              flex-direction: column;
              .row-item {
                flex: 1;
                // background: greenyellow;
                cursor: pointer;
              }
              .noPointerEvents {
                pointer-events: none;
              }
            }
          }

          .hanzi-img-area {
            width: 100%;
            height: 100%;
            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
</style>
