var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "course-maps-container",
      class: "lesson" + _vm.componentProps.lessonId,
    },
    [
      _c("transition", { attrs: { name: "el-fade-in" } }, [
        _vm.componentProps.position === 1 &&
        _vm.componentProps.lessonId % 8 === 0
          ? _c("div", { staticClass: "mask-book" }, [
              _c("div", { staticClass: "mask-book-content" }, [
                _c("span", { staticClass: "font28" }, [
                  _vm._v("CHAPTER " + _vm._s(_vm.componentProps.chapterId)),
                ]),
                _c("img", {
                  staticClass: "glow",
                  attrs: {
                    src: require("@/assets/img/10-Level-progress/progress-level" +
                      _vm.componentProps.lessonId +
                      ".gif"),
                    alt: "",
                  },
                }),
                _c("img", {
                  staticClass: "confetti-img",
                  attrs: {
                    src: require("@/assets/img/07-Stars/confetti.svg"),
                    alt: "",
                  },
                }),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "lesson_content" }, [
        _c("div", { staticClass: "customlesson-breakthrough-part" }, [
          _c(
            "div",
            { staticClass: "demo" },
            _vm._l(8, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "customlesson-breakthrough-part-book",
                },
                [
                  _vm.componentProps.chapterId < index + 1
                    ? _c("span", [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/10-Level-progress/book-cream.svg"),
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.componentProps.chapterId > index + 1
                    ? _c("span", [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/10-Level-progress/book-100.svg"),
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.componentProps.chapterId === index + 1
                    ? _c("span", [
                        _c("img", {
                          class: {
                            glow: _vm.componentProps.position == 1,
                          },
                          attrs: {
                            src: require("@/assets/img/10-Level-progress/lesson-" +
                              (_vm.componentProps.position == 1
                                ? _vm.componentProps.lessonId
                                : _vm.componentProps.lessonId - 1) +
                              "-complete.svg"),
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "customlesson-breakthrough-part-movement" }, [
          _c("div", { ref: "pictureBox", staticClass: "picture" }, [
            _c("div", { staticClass: "picture-item" }, [
              _c("img", {
                ref: "content",
                staticClass: "background-image",
                attrs: {
                  src:
                    _vm.componentProps.bgImg ||
                    require("@/assets/img/09-Level-map/level-1/chapter-1/map (1).svg"),
                  alt: "",
                },
              }),
              _c("img", {
                ref: "content",
                staticClass: "background-image",
                attrs: {
                  src:
                    _vm.componentProps.positionImg ||
                    require("@/assets/img/09-Level-map/level-1/chapter-1/map (2).svg"),
                  alt: "",
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }