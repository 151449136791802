<template>
  <div class="audio-player-container">
    <audio v-if="showAudioElement" :src="audioUrl" ref="audioBox"></audio>
    <img :src="audioImg" />
  </div>
</template>

<script>
import { textToAudio } from '@/assets/js/axios/api'
export default {
  props: {
    isPlay: {
      type: Number,
      require: true
    },
    audioUrl: {
      type: String,
      require: true
    },
    showAudioElement: {
      type: Boolean,
      require: false,
      default: true
    },
    title: {
      type: String,
      require: true,
      default: ''
    }
  },
  watch: {
    isPlay(value) {
      if (value) {
        if (this.audioUrl) {
          this.play()
        } else if (this.title) {
          this.getAudioSound()
        }
      }
    }
  },
  data() {
    return {
      audioImg: require('@/assets/img/06-Sound/sound-white.svg')
    }
  },
  methods: {
    play() {
      if (!this.showAudioElement) return
      const audio = new Audio(this.audioUrl)
      setTimeout(() => {
        audio.play()
      }, 150)
      this.audioImg = require('@/assets/img/06-Sound/sound-white-big.gif')
      setTimeout(() => {
        this.audioImg = require('@/assets/img/06-Sound/sound-white.svg')
      }, 2000)
    },
    /**
     * @description: 文本转音频
     * @param {*} data
     * @return {*}
     */
    /**
     * @description: 文本转音频
     * @param {*} data
     * @return {*}
     */
    async getAudioSound() {
      // const typeList = ['擎苍2.0', '王新月']
      // const randomIndex = Math.floor(Math.random() * typeList.length)
      // const randomType = typeList[randomIndex]
      const res = await textToAudio({
        voice_type: '天才童声',
        text: this.title
      })
      const response = await res.json()
      if (response.code && response.code === 200) {
        const tts = response.data.tts
        const blob = await this.base64ToBlob(tts, 'audio/mpeg')
        const file = new File([blob], 'audio.mp3', { type: blob.type })
        const url = URL.createObjectURL(file)
        this.audioSound = url
        this.play()
      } else {
        this.$message({
          type: 'error',
          message: response.message
        })
      }
    },
    /**
     * @description: base 64转blob
     * @param {*} data
     * @return {*}
     */
    base64ToBlob(base64, contentType) {
      const byteCharacters = atob(base64)
      const byteArrays = []

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
      }

      return new Blob(byteArrays, { type: contentType })
    }
  }
}
</script>

<style lang="scss" scoped>
.audio-player-container {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
}
</style>
