<template>
  <div class="game-erase-hanzi-container">
    <div class="learn-review-box">
      <div class="learn-review-content layout-red-bgc">
        <div class="resizer">
          <div class="review-content">
            <div class="content-characters">
              <div
                v-for="(item, index) in wordsList"
                :key="index"
                class="content-characters-words"
                :class="{ showBox: !item.show }"
                @drop.stop="dropChalk($event, item)"
                @dragover.stop="allowDrop($event)"
              >
                <span class="pinyin font-pinyin-medium">{{ item.name }}</span>
                <span class="characterWriting font-hanzi-medium">{{
                  item.namePin
                }}</span>
              </div>
            </div>
            <!-- <div class="content-characters" v-else>
                <div
                  v-for="(item, index) in wordsList"
                  :key="index"
                  class="content-characters-words-20"
                  :class="{ showBox: !item.show }"
                  @drop.stop="dropChalk($event, item)"
                  @dragover.stop="allowDrop($event)"
                >
                  <span class="pinyin font-pinyin-medium">{{ item.name }}</span>
                  <span class="characterWriting font-hanzi-medium">{{
                    item.namePin
                  }}</span>
                </div>
              </div> -->
            <!-- <div class="content-characters">
              <div
                v-for="(item, index) in sentenceList"
                :key="index"
                class="content-characters-sentence"
                :class="{ showBox: !item.show }"
                @drop.stop="dropChalk($event, item)"
                @dragover.stop="allowDrop($event)"
              >
                <span class="pinyin font-pinyin-medium">{{ item.name }}</span>
                <span class="characterWriting font-hanzi-medium">{{
                  item.namePin
                }}</span>
              </div>
            </div> -->
            <div class="content-characters-bottom">
              <div
                v-for="(item, index) in imgList"
                :key="index"
                class="content-characters-stroke"
                :class="{ showBox: !item.show }"
                @drop.stop="dropChalk($event, item)"
                @dragover.stop="allowDrop($event)"
              >
                <img :src="item.imgUrl" alt="" />
              </div>
            </div>
          </div>

          <!-- 铅笔和黑板擦 -->
          <img class="chalkImg" src="@/assets/img/12-Review/chalk.svg" alt="" />
          <img
            class="removerImg glow"
            src="@/assets/img/12-Review/remove.svg"
            draggable="true"
            @dragstart.stop="drag($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from '@/utils/tools.js'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          eraseWordList: [],
          eraseImgList: []
        }
      }
    }
  },
  computed: {
    dispearNo() {
      return (
        // this.sentenceList.length +
        this.imgList.length + this.wordsList.length
      )
    }
  },
  data() {
    return {
      wordsList: [],
      imgList: [],
      localData: {
        imgList: [
          {
            id: 1,
            imgUrl: require('@/assets/img/18-Blue-review/lesson-44-shugou-yellow.svg'),
            show: true,
            type: 3
          },
          {
            id: 2,
            imgUrl: require('@/assets/img/18-Blue-review/lesson-44-shui-white.svg'),
            show: true,
            type: 3
          },
          {
            id: 3,
            imgUrl: require('@/assets/img/18-Blue-review/lesson-44-niu-white.svg'),
            show: true,
            type: 3
          }
        ],
        wordsList: [
          {
            id: 1,
            name: 'hē',
            namePin: '喝',
            show: true,
            type: 1
          },
          {
            id: 2,
            name: 'shuǐ',
            namePin: '水',
            show: true,
            type: 1
          },
          {
            id: 3,
            name: 'niúnǎi',
            namePin: '牛奶',
            show: true,
            type: 1
          },
          {
            id: 4,
            name: 'xièxie',
            namePin: '谢谢',
            show: true,
            type: 1
          }
        ],
        sentenceList: [
          {
            id: 1,
            name: 'Xièxie nǐ.',
            namePin: '谢谢你。',
            show: true,
            type: 2
          }
        ]
      },

      currentDispearNo: 0,
      dispearSocketInfo: {}
    }
  },
  watch: {
    /**
     * @description: 根据右侧编辑的内容实时更新左侧展示区
     * @return {*}
     */
    componentProps: {
      handler(newVal) {
        const { isTemplatePrview } = newVal
        if (isTemplatePrview) return
        // console.log(newVal, '-----')
        this.initData()
        this.wordsList = newVal.eraseWordList.map((subArray, index) => {
          return {
            id: index + 1,
            namePin: subArray[0].value,
            name: subArray[1].value,
            show: true,
            type: 1
          }
        })
        this.imgList = newVal.eraseImgList.map((subArray, index) => {
          return {
            id: index + 1,
            imgUrl: subArray.value,
            show: true,
            type: 3
          }
        })
        // console.log(this.wordsList, '测试')
      },
      deep: true,
      immediate: true
    },

    dispearSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2000801,
          data: { value },
          text: 'wordCardsPage文字点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    }
  },
  mounted() {
    this.$bus.$on('deleteWordsScoket', ({ item }) => {
      this.dropChalk('event', item, true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('deleteWordsScoket')
  },
  methods: {
    initData() {},
    /**
     * @description: 擦除图片的方法
     * @param {*} type
     * @param {*} id
     * @return {*}
     */
    handleDeleteWords({ type, id }) {
      if (type === 1) {
        this.wordsList[id - 1].show = false
      } else if (type === 2) {
        this.sentenceList[id - 1].show = false
      } else {
        this.imgList[id - 1].show = false
      }
    },

    /**
     * @description: 拖拽的方法
     * @param {*} event
     * @param {*} item
     * @param {*} isFromSocket
     * @return {*}
     */
    dropChalk(event, item, isFromSocket = false) {
      if (!isFromSocket) {
        this.dispearSocketInfo = {
          value: Math.random(),
          item
        }
      }
      // console.log(item)
      this.handleDeleteWords(item)
      // event.preventDefault();
      playCorrectSound(true, false)
      this.currentDispearNo++
      // console.log(this.currentDispearNo, this.dispearNo)
      if (this.currentDispearNo === this.dispearNo) {
        playCorrectSound()
        startConfetti()
      }
    },
    /**
     * @description: 阻止默认的拖放行为
     * @param {*} event
     * @return {*}
     */
    allowDrop(event) {
      event.preventDefault()
    }
  }
}
</script>
<style lang="scss" scoped>
.pinyingWring {
  font-size: 1.6em !important;
  font-family: 'STHeitiSCPinyin-light';
  line-height: 35px;
}
.chalkImg {
  position: absolute;
  bottom: 13%;
  height: 6%;
  left: 19%;
}
.removerImg {
  position: absolute;
  bottom: 13%;
  height: 10%;
  right: 19%;
  -webkit-user-drag: auto !important;
  cursor: pointer;
}
.game-erase-hanzi-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .learn-review-box {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background: #fff;
    border-radius: 58px;
    //   position: absolute;
    z-index: 2;
    .learn-review-content {
      background: #f7f4e0;
      border-radius: 58px;
      position: relative;
      display: flex;
      flex-flow: column;
      z-index: 3;
      // align-items:center ;
      .resizer {
        border-radius: 58px;
        background: url('../../../../assets/img/12-Review/background-summary.svg')
          no-repeat center;
        background-size: cover;
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .review-content {
          width: 75%;
          height: 80%;
          position: absolute;
          top: 10%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .content-characters {
            width: 100%;
            z-index: 1999;
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            .content-characters-words {
              //   width: 10%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-flow: column;
              background-color: #3860a0;
              margin-right: 3%;
              padding: 20px 1%;
              border-radius: 18px;
              margin-bottom: 3%;
              span {
                color: #fff;
              }
            }
            .content-characters-words-20 {
              width: 20%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-flow: column;
              background-color: #3860a0;
              margin-right: 3%;
              padding: 20px 1%;
              border-radius: 18px;
              margin-bottom: 3%;
              span {
                color: #fff;
              }
            }
            .content-characters-sentence {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-flow: column;
              background-color: #3860a0;
              margin-right: 3%;
              padding: 25px;
              border-radius: 18px;
              margin-bottom: 3%;
              span {
                color: #fff;
              }
            }
          }
          .content-characters-bottom {
            width: 100%;
            height: 15%;
            z-index: 1999;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .content-characters-stroke {
              // height: 40%;
              width: 8%;
              display: flex;
              margin-right: 20px;
              justify-content: center;
              align-items: center;
              flex-flow: column;
              background-color: #3860a0;
              padding: 2%;
              border-radius: 20px;

              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
.font25 {
  font-size: 25px;
  line-height: 25px;
}
.font60 {
  font-size: 60px;
  line-height: 80px;
}
.redColor {
  color: #ce524c;
}
.showBox {
  opacity: 0;
  animation: dispear 0.5s linear;
}
@keyframes dispear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.glow {
  animation: glow 1s infinite alternate;
}
@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px #2472b3;
  }
  to {
    box-shadow: 0 0 10px 10px #2472b3, 0 0 10px 10px #2472b3 inset;
  }
}
</style>
