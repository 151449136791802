export const templateTestList = [
  {
    templateId: 1,
    tempalteName: '封面模板',
    templateField: '1_CourseCover',
    description:
      '当前课程的封面需要上传一个背景图片，并输入封面的标题、拼音、英文以及音频。',
    templateImg:
      'https://static.nihaocafe.com/kb/e1067dd6-ea85-443b-b8d7-ddcb61e89e37.png',
    contents: [
      {
        label: '标题拼音',
        fieldName: 'titlePinyin',
        value: '',
        type: 1
      },
      {
        label: '标题',
        fieldName: 'title',
        value: '',
        type: 1
      },
      {
        label: '标题英文',
        fieldName: 'titleEnglish',
        value: '',
        type: 1
      },
      {
        label: '音频',
        fieldName: 'audioSrc',
        value: '',
        type: 2
      },
      {
        label: '背景图',
        fieldName: 'bgImg',
        value: '',
        type: 3
      }
    ]
  },
  {
    templateId: 2,
    tempalteName: '开始页模板',
    templateField: '2_CourseBegin',
    description:
      '当前课程开始页模板要求老师上传一个背景图片，并添加标题的拼音、中文、英文以及标题音频。',
    templateImg:
      'https://static.nihaocafe.com/kb/5893b76c-bb58-472b-9a7b-42d422432602.png',
    contents: [
      {
        label: '标题拼音',
        fieldName: 'titlePinyin',
        value: '',
        type: 1
      },
      {
        label: '标题',
        fieldName: 'title',
        value: '',
        type: 1
      },
      {
        label: '标题英文',
        fieldName: 'titleEnglish',
        value: '',
        type: 1
      },
      {
        label: '音频',
        fieldName: 'audioSrc',
        value: '',
        type: 2
      },
      {
        label: '背景图',
        fieldName: 'bgImg',
        value: '',
        type: 3
      }
    ]
  },
  {
    templateId: 3,
    tempalteName: '再见页模板',
    templateField: '3_CourseEnd',
    description: '当前再见页模板要求老师新增标题拼音、标题汉字以及上传背景图。',
    templateImg:
      'https://static.nihaocafe.com/kb/a51c2451-5dc8-43f1-bd45-5f3935d5793e.png',
    contents: [
      {
        label: '标题拼音',
        fieldName: 'titlePinyin',
        value: '',
        type: 1
      },
      {
        label: '标题',
        fieldName: 'title',
        value: '',
        type: 1
      },
      {
        label: '背景图',
        fieldName: 'bgImg',
        value: '',
        type: 3
      }
    ]
  },
  // {
  //   templateId: 4,
  //   tempalteName: '闯关图模板',
  //   templateField: '4_CourseMaps',
  //   description:
  //     '当前闯关图模板要求老师上传一张背景图片和一张图片背景位置变换后的图片，选择当前课件在课程开始还是结束位置以及进入课件后是否滚动页面（因页面无法展示全部内容需滚动才能看到靠后 lesson 的图片内容）。老师添加完所需内容后，进入该页面首先展示背景图片，然后自动切换到位置图片，有动图效果。若在课程结束位置，会弹出一个弹窗展示一本书，显示当前 chapter，并在页面右上角黄色框中填入一颗星星以显示当前 lesson 已完成学习。',
  //   templateImg:
  //     '@/assets/img/03-Backgrounds/level-1-chapter-2-background-color.svg',
  //   contents: [
  //     {
  //       label: '背景图片',
  //       fieldName: 'bgImg',
  //       value: '',
  //       type: 3
  //     },
  //     {
  //       label: '位置图片',
  //       fieldName: 'positionImg',
  //       value: '',
  //       type: 3
  //     },
  //     {
  //       label: '位置',
  //       fieldName: 'position',
  //       value: 0,
  //       type: 4,
  //       options: [
  //         {
  //           label: '课程开始',
  //           value: 0
  //         },
  //         {
  //           label: '课程结束',
  //           value: 1
  //         }
  //       ]
  //     },
  //     {
  //       label: '进入页面滚动',
  //       fieldName: 'autoScroll',
  //       value: false,
  //       type: 4,
  //       options: [
  //         {
  //           label: '不滚动',
  //           value: false
  //         },
  //         {
  //           label: '滚动',
  //           value: true
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    templateId: 4,
    tempalteName: '课程目标模板',
    templateField: '4_CourseSyllabus',
    description:
      '当前课程目标模板要求老师先输入标题，每个标题对应一段内容。输入后在内容输入框中添加多个词语或句子，点击 “分词” 按钮可将其分解成词语并自动加上拼音，还能添加图片以完成一段课程目标。若要添加多个课程目标，可通过右上角加号按钮重复上述步骤。若要删除某段内容，将鼠标移至该段内容最上方右侧的叉号按钮并点击即可删除当前标题行。',
    templateImg:
      'https://static.nihaocafe.com/kb/feb0e3d7-5d0a-4c66-8143-25edf08dae44.png',
    contents: [
      {
        label: 'Syllabus',
        fieldName: 'syllabusList',
        value: [
          [
            {
              label: '标题',
              fieldName: 'syllabusTitle',
              value: '',
              type: 1
            },
            {
              label: '内容',
              fieldName: 'syllabusContentList',
              value: [
                [
                  {
                    label: '文字',
                    fieldName: 'syllabusContent',
                    value: '',
                    type: 7,
                    options: []
                  },
                  {
                    label: '图片',
                    fieldName: 'syllabusImg',
                    value: [],
                    type: 8
                  }
                ]
              ],
              type: 5
            }
          ]
        ],
        type: 5
      }
    ]
  },
  {
    templateId: 5,
    tempalteName: '词卡模板',
    templateField: '5_CourseWordCard',
    description:
      '当前词卡模板要求老师输入词卡的拼音字母，同时还需上传词卡左侧的图片以及当前内容的音频。',
    templateImg:
      'https://static.nihaocafe.com/kb/848b293b-e1d2-4f6a-9f87-a096718c1941.png',
    contents: [
      {
        label: '拼音字母-第一声',
        fieldName: 'alphabet1',
        value: '',
        type: 1
      },
      {
        label: '拼音字母-第二声',
        fieldName: 'alphabet2',
        value: '',
        type: 1
      },
      {
        label: '拼音字母-第三声',
        fieldName: 'alphabet3',
        value: '',
        type: 1
      },
      {
        label: '拼音字母-第四声',
        fieldName: 'alphabet4',
        value: '',
        type: 1
      },
      {
        label: '词卡配图',
        fieldName: 'wrodImg',
        value: '',
        type: 3
      },
      {
        label: '词卡音频',
        fieldName: 'wrodAudio',
        value: '',
        type: 2
      }
    ]
  },

  //新增一组赋值头像options 06模板 似乎没有生效 打印正常
  // wr
  {
    templateId: 6,
    tempalteName: '对话模板',
    templateField: '6_GameDialog',
    description:
      '当前对话模板要求老师上传对话背景图以及对话对象的头像列表（人数与头像数量对应）。每段对话需输入对话内容、对话内容的拼音、选择对话头像以及确定对话位置。若要添加对话，可点击右上角加号按钮重复上述步骤。若要删除当前对话，可点击对话数据最上面一行右侧的叉号按钮。',
    templateImg:
      'https://static.nihaocafe.com/kb/e44c6ba0-6030-4106-8868-a75a85af2458.png',
    contents: [
      {
        label: '背景图',
        fieldName: 'bgImg',
        value: '',
        type: 3
      },
      {
        label: '头像列表',
        fieldName: 'avatarList',
        value: [],
        type: 8
      },
      {
        label: 'GameDialog',
        fieldName: 'dialogList',
        value: [
          [
            {
              label: '头像',
              fieldName: 'avatar',
              value: '',
              type: 60,
              options: []
            },
            {
              label: '内容',
              fieldName: 'dialogue',
              value: '',
              type: 1
            },
            {
              label: '拼音',
              fieldName: 'py',
              value: '',
              type: 1
            },
            {
              label: '位置',
              fieldName: 'position',
              value: null,
              type: 4,
              options: [
                {
                  label: '左',
                  value: false
                },
                {
                  label: '右',
                  value: true
                }
              ]
            }
          ]
        ],
        type: 9
      }
    ]
  },
  // {
  //   templateId: 8,
  //   tempalteName: '按词选图模板',
  //   templateField: '7_GameRecognition',
  //   templateImg:
  //     '@/assets/img/03-Backgrounds/level-1-chapter-2-background-color.svg',
  //   contents: []
  // },
  // wr 语法练习
  {
    templateId: 7,
    tempalteName: '按图选词模板',
    templateField: '7_GameMirror',
    description:
      '当前按图选词模板先上传一个按钮图片，再上传两张练习内容图片（建议一组只上传两张图片）并添加汉字和拼音，此为一条练习数据。若要添加多条，可点击右上角加号按钮重复操作。若要删除练习数据，可点击当前练习数据的最上方右侧的叉号按钮。练习列表中的按钮图片显示在页面右上角，点击可切换练习内容。汉字和拼音显示在图片上方成为按钮，用于选择更符合汉字含义的图片。选择正确出现欢呼的熊猫，错误则出现流泪的熊猫，全部练习成功完成出现烟花效果，游戏结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/152c295b-6fb5-47ad-b010-3320bce91d8d.png',
    contents: [
      {
        label: 'GameMirror',
        fieldName: 'gameMirrorList',
        value: [
          [
            {
              label: '按钮图片',
              fieldName: 'img',
              value: '',
              type: 3
            },
            {
              label: '图片列表',
              fieldName: 'imgList',
              value: [],
              type: 8
            },
            {
              label: '汉字',
              fieldName: 'hanzi',
              value: '好',
              type: 1
            },
            {
              label: '拼音',
              fieldName: 'py',
              value: 'hǎo',
              type: 1
            },
            {
              label: '正确选项',
              fieldName: 'correctOption',
              value: '',
              type: 4,
              options: []
            }
          ]
        ],
        type: 9
      }
    ]
  },
  // {
  //   templateId: 8,
  //   tempalteName: '造句游戏模板',
  //   templateField: '8_GameSentenceMaking',
  //   templateImg:
  //     '@/assets/img/03-Backgrounds/level-1-chapter-2-background-color.svg',
  //   contents: []
  // },
  // wr
  {
    templateId: 8,
    tempalteName: '火箭游戏模板',
    templateField: '8_GameRocket',
    description:
      '当前火箭游戏模板中，老师需要上传一张背景图（需要一张长图，图片宽高推荐931 x（4832～6484） 像素）。点击操作杆5次，发射火箭到达终点，游戏结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/ac67337d-82d0-4473-9c93-a88445279c9b.png',
    contents: [
      {
        label: '背景图',
        fieldName: 'bgImg',
        value: '',
        type: 3
      }
    ]
  },
  // wr
  {
    templateId: 9,
    tempalteName: '邮箱连连看模板',
    templateField: '9_GameLinkUp',
    description:
      '当前邮箱连连看模板中，老师需添加汉字、拼音以及图片，组成一对邮箱和信件的数据。若要添加多对数据，可通过右上角加号按钮重复操作。若要删除当前邮箱数据，可点击当前邮箱数据最上面最右侧的叉号按钮。文字和拼音显示在邮箱上，图片显示在信件上。玩家根据邮箱上的文字内容选择正确的信件图片并拖拽信封，错误时信封回到原位，正确时信封固定在邮箱上方，全部拖拽正确后游戏结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/8f2ab4c3-a29c-4447-9a84-77efb14be6ac.png',
    contents: [
      {
        label: 'GameLinkUp',
        fieldName: 'gameLinkUpList',
        value: [
          [
            {
              label: '汉字',
              fieldName: 'hanzi',
              value: '',
              type: 1
            },
            {
              label: '拼音',
              fieldName: 'pinyin',
              value: '',
              type: 1
            },
            {
              label: '图片',
              fieldName: 'img',
              value: '',
              type: 3
            }
          ]
        ],
        type: 9
      }
    ]
  },
  // {
  //   templateId: 13,
  //   tempalteName: '模板',
  //   templateField: '9_Game',
  //   templateImg:
  //     '@/assets/img/03-Backgrounds/level-1-chapter-2-background-color.svg',
  //   contents: []
  // },

  // wr 功能正常，游戏结束时机不明确
  {
    templateId: 10,
    tempalteName: '按图选数字模板',
    templateField: '10_GameRecognitionNumber',
    description:
      '当前按图选数字模板中，老师需上传一张图片，添加汉字、拼音并选择按钮背景色，此为一个选择练习数据。若要增加多条练习，可点击右上角加号按钮，每点击一次增加一条练习数据并重复上述操作。若要删除当前练习数据，可点击该数据最上面一行最右侧的叉号按钮。页面下方按钮由所添加汉字内容组成选项列，右上角星星数量代表练习数。玩家根据页面图片内容选择相符合的汉字选项内容，选择正确后星星变蓝且图片切换至下一题，直至所有练习完成、右上角星星全部变蓝，游戏结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/5c1a336c-2458-4f42-9dcc-0be91d0146fb.png',
    contents: [
      {
        label: 'GameRecognitionNumber',
        fieldName: 'gameRecognitionList',
        value: [
          [
            {
              label: '图片',
              fieldName: 'img',
              value: '',
              type: 3
            },
            {
              label: '汉字',
              fieldName: 'hanzi',
              value: '',
              type: 1
            },
            {
              label: '拼音',
              fieldName: 'py',
              value: '',
              type: 1
            }
          ]
        ],
        type: 9
      }
    ]
  },
  // wr 功能没有问题，游戏结束时机不明确，例如添加了3张全部翻开没有结束撒花。
  {
    templateId: 11,
    tempalteName: '翻牌模板',
    templateField: '11_GameFlipCard',
    description:
      '当前翻牌模板要求老师上传图片、添加汉字、拼音、英文以及卡牌背景色。若需多张卡牌，可点击右上角加号按钮添加，每点击一次增加一张卡牌。如需删除当前卡牌，可点击当前卡牌最上面一行右侧的叉号按钮。这是一个翻牌游戏，玩家需翻开所有卡片，卡片正面是图片，翻开后背面显示图片对应的中文、拼音以及英文。',
    templateImg:
      'https://static.nihaocafe.com/kb/5be4be24-4eb1-4a6a-993e-c7c15541a197.png',
    contents: [
      {
        label: 'GmaeFlipCard',
        fieldName: 'gameFlipList',
        value: [
          [
            {
              label: '图片',
              fieldName: 'img',
              value: '',
              type: 3
            },
            {
              label: '汉字',
              fieldName: 'hanzi',
              value: '',
              type: 1
            },
            {
              label: '拼音',
              fieldName: 'py',
              value: '',
              type: 1
            },
            {
              label: '英文',
              fieldName: 'english',
              value: '',
              type: 1
            },
            {
              label: '背景色',
              fieldName: 'correctOption',
              value: '',
              type: 4,
              options: [
                {
                  label: '红色',
                  value: 'redColor'
                },
                {
                  label: '黄色',
                  value: 'yellowColor'
                }
              ]
            }
          ]
        ],
        type: 9
      }
    ]
  },
  // wr 拖动可以放置在邮箱右侧。信封高度300px似乎也有点问题。
  {
    templateId: 12,
    tempalteName: '词卡模板',
    templateField: '12_GameRecognitionCard',
    description:
      '该模板可输入一个词的中文、英文、拼音、音频以及相关图片，进而生成一个词卡，词卡展示这些输入内容，以帮助学生更好地理解所输入的词语。',
    templateImg:
      'https://static.nihaocafe.com/kb/7f811e79-862b-4b03-9772-a8758e61f883.png',
    contents: [
      {
        label: '图片',
        fieldName: 'img',
        type: 3,
        value: ''
      },
      {
        label: '音频',
        fieldName: 'audio',
        type: 2,
        value: ''
      },
      {
        label: '拼音',
        fieldName: 'pinyin',
        type: 1,
        value: ''
      },
      {
        label: '汉字',
        fieldName: 'hanzi',
        type: 1,
        value: ''
      },
      {
        label: '英文',
        fieldName: 'english',
        type: 1,
        value: ''
      }
    ]
  },
  {
    // JiHui   title:  '总结页'
    templateId: 13,
    tempalteName: '总结页',
    templateField: '13_GameSummaryPage',
    description:
      '老师需要上传一张背景图片，接着可以添加多条标题，每一条标题中包含标题的拼音和汉字。然后需要上传红色、黄色、蓝色三种颜色的白板故事图，之后在下方可以为每个白板添加多组内容，每一组需要输入汉字和拼音',

    templateImg:
      'https://static.nihaocafe.com/kb/839bb0a8-78ec-4f1f-8bb5-296a038d08c3.png',
    contents: [
      {
        label: '背景图片',
        fieldName: 'bgImg',
        value: '',
        type: 3
      },
      {
        label: '标题',
        fieldName: 'summaryTitle',
        value: [
          [
            {
              label: '标题拼音',
              fieldName: 'titlePinYin',
              value: '',
              type: 1
            },
            {
              label: '标题汉字',
              fieldName: 'titleHanZi',
              value: '',
              type: 1
            }
          ]
        ],
        type: 9
      },
      {
        label: '红色白板故事图',
        fieldName: 'redSummaryImg',
        value: '',
        type: 3
      },
      {
        label: '黄色白板故事图',
        fieldName: 'yellowSummaryImg',
        value: '',
        type: 3
      },
      {
        label: '蓝色白板故事图',
        fieldName: 'blueSummaryImg',
        value: '',
        type: 3
      },
      {
        label: '红色白板',
        fieldName: 'redSummaryList',
        value: [
          [
            {
              label: '汉字',
              fieldName: 'redHanZi',
              value: '',
              type: 1
            },
            {
              label: '拼音',
              fieldName: 'redPinYin',
              value: '',
              type: 1
            }
          ]
        ],
        type: 9
      },
      {
        label: '黄色白板',
        fieldName: 'yellowSummaryList',
        value: [
          [
            {
              label: '汉字',
              fieldName: 'yellowHanZi',
              value: '',
              type: 1
            },
            {
              label: '拼音',
              fieldName: 'yellowPinYin',
              value: '',
              type: 1
            }
          ]
        ],
        type: 9
      },
      {
        label: '蓝色白板',
        fieldName: 'blueSummaryList',
        value: [
          [
            {
              label: '汉字',
              fieldName: 'blueHanZi',
              value: '',
              type: 1
            },
            {
              label: '拼音',
              fieldName: 'bluePinYin',
              value: '',
              type: 1
            }
          ]
        ],
        type: 9
      }
    ]
  },
  // wr 未完成可以删除，jihui那里有
  // {
  //   templateId: 18,
  //   tempalteName: '拨号游戏',
  //   templateField: '13_GamePhoneNumber',
  //   templateImg:
  //     '@/assets/img/03-Backgrounds/level-1-chapter-2-background-color.svg',
  //   contents: [
  //     {
  //       label: 'PhoneNumberGame',
  //       fieldName: 'phoneNumberList',
  //       value: [
  //         [
  //           {
  //             label: '音频',
  //             type: 2,
  //             fieldName: 'audio',
  //             value: ''
  //           },
  //           {
  //             label: '数字',
  //             type: 1,
  //             fieldName: 'number',
  //             value: '112'
  //           },
  //           {
  //             label: '汉字',
  //             fieldName: 'hanzi',
  //             value: '一一二',
  //             type: 1
  //           },
  //           {
  //             label: '拼音',
  //             fieldName: 'pinyin',
  //             value: 'yī yī èr',
  //             type: 1
  //           }
  //         ],
  //         [
  //           {
  //             label: '音频',
  //             type: 2,
  //             fieldName: 'audio',
  //             value: ''
  //           },
  //           {
  //             label: '数字',
  //             type: 1,
  //             fieldName: 'number',
  //             value: '345'
  //           },
  //           {
  //             label: '汉字',
  //             fieldName: 'hanzi',
  //             value: '三四五',
  //             type: 1
  //           },
  //           {
  //             label: '拼音',
  //             fieldName: 'pinyin',
  //             value: 'sān sì wǔ',
  //             type: 1
  //           }
  //         ]
  //       ],
  //       type: 9
  //     }
  //   ]
  // },
  // wr 要限制按钮图片的大小
  {
    templateId: 14,
    tempalteName: '按图选词游戏',
    templateField: '14_GameFindByPicture',
    description:
      '当前按图选词模板中，老师需输入按钮拼音和汉字，上传一张图片、按钮内容的音频以及拼音和汉字，此为一条练习数据。若要多条练习数据，可点击右上角加号按钮添加，点击后新增一条练习数据。若要删除练习数据，可点击想要删除的练习数据右上方的叉号按钮，点击后当前练习数据被删除。玩家依据页面中的图片或图片下的音频内容，在页面上选择与之对应的按钮选项，选择正确后右上角的星星按顺序变成蓝色，页面上的图片 / 文字内容切换，展示下一条练习数据，直至所有练习题都完成选择且正确，游戏结束，右上角星星全部变成蓝色。（建议双数，不要超过10条练习数据）',
    templateImg:
      'https://static.nihaocafe.com/kb/234a3d91-136c-41e0-9ae3-9d61f662476c.png',
    contents: [
      {
        label: 'FindByPictureGame',
        fieldName: 'findByPictureList',
        value: [
          [
            {
              label: '按钮拼音',
              type: 1,
              fieldName: 'buttonPy',
              value: ''
            },
            {
              label: '按钮汉字',
              type: 1,
              fieldName: 'buttonZh',
              value: ''
            },
            {
              label: '图片',
              type: 3,
              fieldName: 'img',
              value: ''
            },
            {
              label: '音频',
              type: 2,
              fieldName: 'audio',
              value: ''
            },
            {
              label: '拼音',
              fieldName: 'pinyin',
              value: '',
              type: 1
            },
            {
              label: '汉字',
              fieldName: 'hanzi',
              value: '',
              type: 1
            }
          ]
        ],
        type: 9
      }
    ]
  },
  // wr
  {
    templateId: 15,
    tempalteName: '看图说话模板',
    templateField: '15_CourseSpeakingSentence',
    description:
      '当前看图说话模板要求老师添加标题内容以及标题拼音，同时上传多张图片。学生可通过点击页面切换图片，并根据图片上的标题内容和图片内容进行描述。',
    templateImg:
      'https://static.nihaocafe.com/kb/d8d3abdf-e19a-43be-ad78-03088bcba853.png',
    contents: [
      {
        label: '标题汉字',
        fieldName: 'titleZh',
        value: '',
        type: 1
      },
      {
        label: '标题拼音',
        fieldName: 'titlePy',
        value: '',
        type: 1
      },
      {
        label: '图片列表',
        fieldName: 'imgList',
        value: [],
        type: 8
      }
    ]
  },
  // wr  词汇语法练习（8种选择）
  {
    templateId: 16,
    tempalteName: '按图选词游戏（拼音）I',
    templateField: '16_GameRecognitionPinyin',
    description:
      '当前按图选词模板要求老师添加标题内容和标题拼音，接着添加按钮汉字和拼音并根据按钮内容上传对应的图片（数量不限）。此为一个按钮选项信息，可通过右上角加号按钮添加多个按钮。若要删除当前按钮，可点击当前按钮第一行最右侧的叉号图标。玩家看到页面下方多个按钮后，需根据当前页面图片内容选择相应按钮，选项正确后出现下一张图片继续选择（按钮内容不变），同时右上角的星星会按顺序变成蓝色，答对一题就有一个星星变蓝。题目数量为右上角星星数量，直至星星全部变蓝，游戏结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/f8353e27-a8e0-4517-95f8-b32acfbb5542.png',
    contents: [
      {
        label: '标题汉字',
        fieldName: 'titleZh',
        value: '',
        type: 1
      },
      {
        label: '标题拼音',
        fieldName: 'titlePy',
        value: '',
        type: 1
      },
      {
        label: '按钮列表',
        fieldName: 'buttonList',
        value: [
          [
            {
              label: '汉字',
              fieldName: 'hanzi',
              value: '',
              type: 1
            },
            {
              label: '拼音',
              fieldName: 'pinyin',
              value: '',
              type: 1
            },
            {
              label: '图片列表',
              fieldName: 'imgList',
              value: [],
              type: 8
            }
          ]
        ],
        type: 9
      }
    ]
  },
  // wr 词汇语法练习（8种选择）
  {
    templateId: 17,
    tempalteName: '按图选词游戏（拼音）II',
    templateField: '17_GameRecognitionPinyin',
    description:
      '当前按图选词模板中，老师需上传标题汉字、标题拼音（标题内容可不填），然后上传练习图片、音频以及选项图片，此为一条练习数据内容。若要多条，可点击按钮列表右侧的加号按钮进行新增操作。若要删除当前练习数据，可点击每条练习数据右上方的叉号图标。玩家在该模板中能看到下方有多张图片作为题目的选项，需根据页面上方的图片内容和音频内容选择下面对应的图片。右上角星星数量为练习题目数量，玩家选择正确后，上面会换成新的图片和音频，且右上角的星星会从头开始有一个变成蓝色，一直重复此过程，直至所有图片和音频都选完且选对，右上角星星全部变成蓝色，游戏结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/89dc687f-d18c-4534-8050-665723ba2782.png',
    contents: [
      // {
      //   label: '标题汉字',
      //   fieldName: 'titleZh',
      //   value: '',
      //   type: 1
      // },
      // {
      //   label: '标题拼音',
      //   fieldName: 'titlePy',
      //   value: '',
      //   type: 1
      // },
      {
        label: '按钮列表',
        fieldName: 'buttonPyList',
        type: 9,
        value: [
          [
            {
              label: '图片',
              fieldName: 'img',
              value: '',
              type: 3
            },
            {
              label: '音频',
              fieldName: 'audio',
              value: '',
              type: 2
            },
            {
              label: '音频内容',
              fieldName: 'audioPy',
              value: '',
              type: 1
            }
          ]
        ]
      }
    ]
  },
  {
    // JiHui 复习巩固(八种选择)
    templateId: 18,
    tempalteName: '看图选词游戏',
    templateField: '18_GameChooseWords',
    description:
      '当前模板中，老师首先需上传一张背景图。接着在汉字选项输入框输入一段文字（最好不超过九个字），系统将其拆分成单个汉字作为选项显示在页面上，并从输入的文字中选出正确答案填写在正确答案输入框。此为一项选词练习，若要添加多项可点击添加关卡右侧的加号图标以新增练习数据；若要删除一项，可点击故事图片行右侧的叉号图标删除当前练习数据。学生根据图片和音频，按正确顺序选择汉字，答案为老师在正确答案输入框输入的文字，系统会自动判断学生所选选项与正确答案是否一致。',
    templateImg:
      'https://static.nihaocafe.com/kb/28249a74-8503-4d0d-9121-f9f98fafe65c.png',
    contents: [
      {
        label: '背景图',
        fieldName: 'bgImg',
        value: '',
        type: 3
      },
      {
        label: '添加关卡',
        fieldName: 'addLevelList',
        type: 9,
        value: [
          [
            {
              label: '故事图片',
              fieldName: 'img',
              value: '',
              type: 3
            },
            {
              label: '汉字选项',
              fieldName: 'optionList',
              value: '',
              type: 1
            },
            {
              label: '正确答案',
              fieldName: 'answer',
              value: '',
              type: 1
            },
            {
              label: '音频',
              fieldName: 'audioSrc',
              value: '',
              type: 2
            }
          ]
        ]
      }
    ]
  },
  // wr 词汇语法练习（8种选择）
  {
    templateId: 19,
    tempalteName: '按词选图游戏',
    templateField: '19_GameChoosePicByWord',
    description:
      '标题中的汉字和拼音可进行自定义编辑。在图片列表里，老师需要上传一组相关联的图片，且图片两两一组进行匹配。点击右侧加号可以新增一组，按顺序点击一组中的两个模板即可配对成功，每增加一组就会多一颗星。游戏展示图片时会打乱顺序，玩家的任务是挑出有关联的一对图片。选对后，这两张图片会单独弹出显示正确，直至所有成对图片都被选完，游戏宣告结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/24948c60-f6ea-4442-9c5f-d0976b69b30a.png',
    contents: [
      {
        label: '标题汉字',
        fieldName: 'titleZh',
        value: '',
        type: 1
      },
      {
        label: '标题拼音',
        fieldName: 'titlePy',
        value: '',
        type: 1
      },
      {
        label: '图片列表',
        fieldName: 'choosePicList',
        type: 9,
        value: [
          [
            {
              label: '匹配的图片',
              fieldName: 'matchImg',
              type: 8,
              value: []
            }
          ]
        ]
      }
    ]
  },
  // wr 词汇语法练习（8种选择）
  {
    templateId: 20,
    tempalteName: '擦除图片游戏',
    templateField: '20_GameScratchPic',
    description:
      '老师能够自定义标题的汉字与拼音，还可上传多组图，制作猜图游戏。背景图片为需要猜测的图片，按钮图片则是右上角可供点击的按钮。游戏规则如下：每组猜图的规则相同，页面会显示一张被有颜色涂层遮挡的照片，当鼠标悬浮在涂层上时，会出现橡皮擦样式，利用橡皮擦擦除涂层就能看到真实的图片。需要注意的是，遮挡图片的范围仅在正中的 211×270 像素。',
    templateImg:
      'https://static.nihaocafe.com/kb/5d13bcf2-f70e-47c2-a69b-cdedd3b28157.png',
    contents: [
      {
        label: '标题汉字',
        fieldName: 'titleZh',
        value: '谁？',
        type: 1
      },
      {
        label: '标题拼音',
        fieldName: 'titlePy',
        value: 'shei',
        type: 1
      },
      {
        label: '图片按钮列表',
        fieldName: 'scratchPicList',
        type: 9,
        value: [
          [
            {
              label: '相框颜色',
              fieldName: 'frameColor',
              value: false,
              type: 4,
              options: [
                {
                  label: '红色相框',
                  value: false
                },
                {
                  label: '黄色相框',
                  value: true
                }
              ]
            },
            {
              label: '相框内照片',
              type: 3,
              value: '',
              fieldName: 'photoImg'
            },
            {
              label: '答案',
              type: 1,
              value: '',
              fieldName: 'hanzi'
            },
            {
              label: '答案拼音',
              type: 1,
              value: '',
              fieldName: 'pinyin'
            }
          ]
        ]
      }
    ]
  },
  {
    // Lihao title '词卡-认读'
    templateId: 21,
    tempalteName: '拼图词卡游戏',
    templateField: '21_GamePuzzle',
    description:
      '老师需要上传一张背景图，一张3x3拼图背景（正方形宽高再500-770像素范围内），9张（500-700宽高像素的挖空后的拼图），需要按照顺序从第一行第一列以此上传。设置图片是否正确，点击全部正确图片之后，所有星星点亮，游戏结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/c5baaf6f-a4ac-423e-a4fb-026fea8bf5ca.png',
    contents: [
      {
        label: '背景图片',
        fieldName: 'bgImg',
        value: '',
        type: 3
      },
      {
        label: '底图',
        fieldName: 'puzzleBgImg',
        value: '',
        type: 3
      },
      {
        label: '图片列表',
        type: 9,
        fieldName: 'puzzleImgList',
        value: [
          [
            {
              label: '图片',
              fieldName: 'img',
              value: '',
              type: 3
            },
            {
              label: '是否正确',
              fieldName: 'isCorrect',
              value: 0,
              type: 4,
              options: [
                {
                  label: '正确',
                  value: 0
                },
                {
                  label: '错误',
                  value: 1
                }
              ]
            }
          ]
        ]
      }
    ]
  },
  // wr 词汇语法练习（8种选择）
  {
    templateId: 22,
    tempalteName: '选择图片游戏',
    templateField: '22_GameBingo',
    description:
      '一组问题中，每组的问题拼音和汉字均可自定义，答案图片占据一列（最好每组问答上传 3 张图片），需从每列的 img01 - img03 中选取一张当作问题的答案。每创建一组便会多增添一颗星星。比如，正好创建了 3 组问题，那就依据问题的先后顺序在九宫格图片里挑选一张正确的图片，选对后就会出现下一个问题，一直到所有问题都解答完毕，游戏结束',
    templateImg:
      'https://static.nihaocafe.com/kb/b5492b7d-e10d-42f6-b5c7-9c596ab8534b.png',
    contents: [
      {
        label: '问题列表',
        fieldName: 'questionList',
        value: [
          [
            {
              label: '问题拼音',
              fieldName: 'questionPy',
              value: '',
              type: 1
            },
            {
              label: '问题汉字',
              fieldName: 'questionZh',
              value: '',
              type: 1
            },
            {
              label: '图片列表',
              fieldName: 'imgList',
              value: [],
              type: 8
            },
            {
              label: '答案图片',
              fieldName: 'answerImg',
              type: 60,
              value: '',
              options: []
            }
          ]
        ],
        type: 9
      }
    ]
  },
  // wr 词汇语法练习（8种选择）
  {
    templateId: 23,
    tempalteName: '根据描述选择图片游戏',
    templateField: '23_GameChooseImg',
    description:
      '根据描述选择图片，老师可自定义描述内容和拼音，上传多张图片（建议三张），选择某张上传图片作为答案，上传一个按钮图片（正方形图片效果最佳），至此一组描述问答完成。可以创建多组问答，点击选择图片列表右侧的+按钮，可添加一组新的问答。点击红框中的图片可进行题目切换，点击图片正确则有正确动画，错误则展示错误动画。答对所有问题即通关，游戏结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/771977ab-689b-47e5-891f-3b4dde4c12e9.png',
    contents: [
      {
        label: '选择图片列表',
        fieldName: 'ChooseImgList',
        value: [
          [
            {
              label: '描述拼音',
              fieldName: 'contentPy',
              value: '',
              type: 1
            },
            {
              label: '描述汉字',
              fieldName: 'contentZh',
              value: '',
              type: 1
            },
            {
              label: '图片',
              fieldName: 'imgList',
              value: [],
              type: 8
            },
            {
              label: '答案图片',
              fieldName: 'answerImg',
              value: '',
              type: 60,
              options: []
            },
            {
              label: '按钮图片',
              fieldName: 'btnImg',
              type: 3,
              value: ''
            }
          ]
        ],
        type: 9
      }
    ]
  },
  // wr 词汇语法练习（8种选择）
  {
    templateId: 24,
    tempalteName: '根据结构选择图片游戏',
    templateField: '24_GameChooseStructure',
    description:
      '根据图片背景的结构，选择对应结构的文字。标题文字和标题拼音可自定义填写。选择正确的文字图片需要上传，对应一组图片文字拼音的答案。通过点击+号可创建新的一组问答。建议每组问答设置3个汉字。选择正确后，对应上传的文字图片会出现在结构图片中，替换原有的背景图，进入下一组问答。直至所有结构图片都被文字替换，游戏结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/2cdcbee8-649e-4bf5-b8c1-061865bb56e7.png',
    contents: [
      {
        label: '标题汉字',
        fieldName: 'titleZh',
        value: '谁？',
        type: 1
      },
      {
        label: '标题拼音',
        fieldName: 'titlePy',
        value: 'shei',
        type: 1
      },
      {
        label: '结构图片',
        fieldName: 'structureImg',
        value: '',
        type: 3
      },
      {
        label: '题目列表',
        fieldName: 'StructureList',
        value: [
          [
            {
              label: '答案图片',
              fieldName: 'answerImg',
              value: '',
              type: 3
            },
            {
              label: '按钮拼音I',
              fieldName: 'btnPyI',
              value: '',
              type: 1
            },
            {
              label: '按钮汉字I',
              fieldName: 'btnZhI',
              value: '',
              type: 1
            },
            {
              label: '按钮拼音II',
              fieldName: 'btnPyII',
              value: '',
              type: 1
            },
            {
              label: '按钮汉字II',
              fieldName: 'btnZhII',
              value: '',
              type: 1
            },
            {
              label: '按钮拼音III',
              fieldName: 'btnPyIII',
              value: '',
              type: 1
            },
            {
              label: '按钮汉字III',
              fieldName: 'btnZhIII',
              value: '',
              type: 1
            },
            {
              label: '正确答案',
              fieldName: 'correctAnswer',
              value: '',
              type: 60,
              options: []
            }
          ]
        ],
        type: 9
      }
    ]
  },
  // wr 词汇语法练习（8种选择）
  {
    templateId: 25,
    tempalteName: '火车游戏',
    description:
      '老师能够自定义编辑标题的汉字和拼音，根据背景图与遮挡图下拉框来选择背景长图与遮挡图。在图片列表中上传多张图片。图片就是当前要展示的内容。第一张图片不会被遮挡，点击未被遮挡的图片时，会清除下一张图片的遮挡并向右移动。最后一张图片清除遮挡后还需点击一次，点亮全部星星后游戏结束。',
    templateField: '25_GameClickTrain',
    templateImg:
      'https://static.nihaocafe.com/kb/2eec01a3-41e5-46ba-bf2b-b4ecee292c1b.png',
    contents: [
      {
        label: '标题拼音',
        fieldName: 'titlePy',
        value: '',
        type: 1
      },
      {
        label: '标题汉字',
        fieldName: 'titleZh',
        value: '',
        type: 1
      },
      {
        label: '背景图与遮挡图',
        fieldName: 'bgImgAndShadowImg',
        type: 60,
        options: [
          {
            value: '1',
            label: '火车＋云'
          },
          {
            value: '2',
            label: '刺猬＋樱桃'
          }
        ],
        value: ''
      },
      {
        label: '图片列表',
        fieldName: 'detailImg',
        value: [],
        type: 8
      }
    ]
  },
  // wr 词卡总结页
  {
    templateId: 26,
    tempalteName: '拖拽图片游戏',
    templateField: '26_GameDragSameCorrectPic',
    description:
      '游戏玩法：根据页面下方的图片内容选择页面上方对应的按钮，然后将图片拖拽到正确按钮上。若拖拽正确，按钮文字被图片替代，点亮一颗星星；若错误，图片回到原位。持续操作，直到页面下方的图片都被拖转到页面上方按钮中，游戏结束。一组按钮和答案图片的关系一一对应，老师可以输入按钮拼音和汉字生成按钮并上传一张对应的答案图片。点击右侧+号可创建多组按钮和答案图片的关系。',
    templateImg:
      'https://static.nihaocafe.com/kb/33d285ba-39cc-4eeb-a006-3aa6618cf384.png',
    contents: [
      {
        label: '图片列表',
        fieldName: 'dragPicList',
        value: [
          [
            {
              label: '按钮拼音',
              fieldName: 'btnPy',
              value: '',
              type: 1
            },
            {
              label: '按钮汉字',
              fieldName: 'btnZh',
              value: '',
              type: 1
            },
            {
              label: '答案图片',
              fieldName: 'answerPic',
              value: '',
              type: 3
            }
          ]
        ],
        type: 9
      }
    ]
  },
  {
    // Lihao title '词卡-认读'
    //删除雨滴图片似乎有问题
    templateId: 27,
    tempalteName: '雨滴游戏',
    templateField: '27_GameRainDrop',
    description:
      '老师预设词语，选一字考察，点击正确字组成词语，完成所有组合则游戏结束。如 “雨滴”，需正方形背景图，上传 3 个选项（含 “滴” 字及两个混淆字），“滴” 字图为正确答案（如 img01），填答案拼音汉字，只展示 “雨” 字。一组完成后点右侧 “+” 号创建新组，完成一组点亮一星，全部完成后游戏结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/86628d89-74d2-4e5a-bb03-e1b55ec44656.png',
    contents: [
      {
        label: '背景图片',
        fieldName: 'bgImage',
        value: '',
        type: 3
      },

      {
        label: '选项列表',
        fieldName: 'optionList',
        type: 9,
        value: [
          [
            {
              label: '选项图片',
              fieldName: 'optionImg',
              value: [],
              type: 8
            },
            {
              label: '正确答案',
              fieldName: 'correctAnswer',
              value: '',
              type: 60,
              options: []
            },
            {
              label: '答案列表',
              fieldName: 'answerList',
              value: [
                [
                  {
                    label: '答案拼音',
                    fieldName: 'anwserPy',
                    value: '',
                    type: 1
                  },
                  {
                    label: '答案汉字',
                    fieldName: 'answerZh',
                    value: '',
                    type: 1
                  },
                  {
                    label: '是否展示',
                    fieldName: 'isShow',
                    value: true,
                    type: 4,
                    options: [
                      {
                        label: '是',
                        value: true
                      },
                      {
                        label: '否',
                        value: false
                      }
                    ]
                  }
                ]
              ],
              type: 9
            }
          ]
        ]
      }
    ]
  },

  {
    // JiHui 复习巩固(八种选择)
    templateId: 28,
    tempalteName: '看图描绘游戏',
    templateField: '28_GameWritePage',
    description:
      '老师上传一张动态图，这张图要演示怎样描绘笔画。接着在添加汉字的输入框中输入相应拼音。之后再上传一张需要描绘的图片，学生就在这张图片上进行描绘，等学生完成后，页面会展示出老师上传的已经描绘好的那张图片。老师还需要上传四张故事图片、一张待激活图片和一张激活完成图片，每一次描绘完成后，右侧的故事图片都会替换成下一张，并且上方的待激活图片会变成激活完成图片。',
    templateImg:
      'https://static.nihaocafe.com/kb/18899221-ed8a-434d-96c0-e47015d141b9.png',
    contents: [
      {
        label: '背景图',
        fieldName: 'bgImg',
        value: '',
        type: 3
      },
      {
        label: '添加演示图片',
        fieldName: 'demoImg',
        value: '',
        type: 3
      },
      {
        label: '添加拼音',
        fieldName: 'pinyin',
        value: '',
        type: 1
      },
      {
        label: '添加待描绘图片',
        fieldName: 'strokeImg',
        value: '',
        type: 3
      },
      {
        label: '添加描绘完成图片',
        fieldName: 'finishImg',
        value: '',
        type: 3
      },
      {
        label: '添加故事图片',
        fieldName: 'storyImgList',
        value: [],
        type: 8
      },
      {
        label: '添加待激活图片',
        fieldName: 'activeImg',
        value: '',
        type: 3
      },
      {
        label: '添加激活完成图片',
        fieldName: 'activeFinishImg',
        value: '',
        type: 3
      }
    ]
  },
  // wr 复习巩固总 （2种选择）
  {
    templateId: 29,
    tempalteName: '按图选词游戏II',
    templateField: '29_GameFindByPictureII',
    description:
      '对于老师来说，当前模板需上传一个选项列表。每个选项包含按钮的拼音、按钮文字、按钮音频、按钮位置（左侧或右侧）以及上传页面中间的展示图片。若要新增选项，可点击最上行右侧的 “+” 号，新增后可继续添加相应内容。选项数量决定课件的练习条数，且与页面右上角的星星数量相对应。对于学生而言，要根据页面中的图片选择图片两侧的按钮，每个按钮上的音频可播放按钮中的文字读音。若选择正确，页面图片会自动切换至下一题，同时右上角的星星自动变蓝，直至所有星星变蓝，游戏结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/252e6816-102e-46e3-9a61-ceb2de6043a6.png',
    contents: [
      {
        label: '选项列表',
        fieldName: 'questionInfoList',
        value: [
          [
            {
              label: '按钮拼音',
              fieldName: 'btnPy',
              value: '',
              type: 1
            },
            {
              label: '按钮汉字',
              fieldName: 'btnZh',
              value: '',
              type: 1
            },
            {
              label: '按钮音频',
              fieldName: 'btnAudio',
              value: '',
              type: 2
            },
            {
              label: '按钮位置',
              fieldName: 'position',
              value: '',
              type: 4,
              options: [
                {
                  label: 'left',
                  value: 0
                },
                {
                  label: 'right',
                  value: 1
                }
              ]
            },
            {
              label: '图片',
              fieldName: 'detailImg',
              value: '',
              type: 3
            }
          ]
        ],
        type: 9
      }
    ]
  },
  // wr 复习巩固总 （2种选择）
  {
    templateId: 30,
    tempalteName: '根据图片选择结构游戏',
    templateField: '30_GameFindStructureByPic',
    description:
      '老师操作部分：老师需要上传多张字体结构的图片。在问题列表中，老师先为每个问题选择正确结构，接着添加问题的文字图片，最后上传选择正确后所要展示的图片。老师可通过 “问题列表” 最右侧的 “+” 号添加新问题，也可通过每个问题右上角的 “X” 号删除问题。问题数量与右上角的星星数量一致。学生操作部分：学生根据当前页面中间展示的文字图片去选择图片两侧的结构图片按钮。选择正确的图片结构后，页面中的文字图片会展示为老师上传的确认图片。一个题目选择正确后，右上角的一颗星星会变成蓝色，接着页面切换到下一题，直到所有星星都变成蓝色，游戏结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/efc5a277-324a-4b00-8949-28d3a69fd13d.png',
    contents: [
      {
        label: '结构图片列表',
        fieldName: 'structureImgList',
        value: [],
        type: 8
      },
      {
        label: '问题列表',
        fieldName: 'structurePicList',
        value: [
          [
            {
              label: '结构图片',
              fieldName: 'structurePic',
              value: '',
              type: 60,
              options: []
            },
            {
              label: '问题图片',
              fieldName: 'questionPic',
              value: '',
              type: 3
            },
            {
              label: '确认图片',
              fieldName: 'answerPic',
              value: '',
              type: 3
            }
          ]
        ],
        type: 9
      }
    ]
  },
  {
    // JiHui 复习巩固(八种选择)
    templateId: 31,
    tempalteName: '按图选词造句',
    templateField: '31_GameChooseWordsByPic',
    description:
      '老师先输入一个提示词（最好不超过两个字），然后要在汉字或词语的输入框里输入一个汉字或者词语，点右侧加号能添加多个。这些汉字或词语按顺序组合起来就是正确答案。系统会自动打乱它们在页面上的顺序，接着老师再上传一张故事图片。学生需要根据图片提示按顺序选择正确的汉字或词语，当所有选择都正确时，页面就会展示出正确答案。',
    templateImg:
      'https://static.nihaocafe.com/kb/5aaf7254-1f7e-4774-acd0-5e6d16c9447e.png',
    contents: [
      {
        label: '标题汉字',
        fieldName: 'titleHanZi',
        value: '',
        type: 1
      },
      {
        label: '标题拼音',
        fieldName: 'titlePinyin',
        value: '',
        type: 1
      },
      {
        label: '故事图片',
        fieldName: 'img',
        value: '',
        type: 3
      },
      {
        label: '提示汉字',
        fieldName: 'tipHanzi',
        value: '',
        type: 1
      },
      {
        label: '提示拼音',
        fieldName: 'tipPinyin',
        value: '',
        type: 1
      },
      {
        label: '添加汉字或词语',
        fieldName: 'hanziList',
        type: 9,
        value: [
          [
            {
              label: '汉字/词语',
              filedName: 'hanzi',
              value: '',
              type: 1
            }
          ]
        ]
      }
    ]
  },
  // wr 复习巩固总 （2种选择）
  {
    templateId: 32,
    tempalteName: '根据音频选择图片游戏',
    templateField: '32_GameListenThenChoosePic',
    description:
      '老师需要上传图片选项列表，然后在答案图片的下拉框内选择正确的答案图片，上传音频，学生需要在音频播放完毕后，根据图片选项列表选择正确的答案图片。然后填写答案拼音和汉字，并且添加多组汉字列表，每一组汉字和拼音都是一个选项，在下面单选框内勾选是否正确，如果勾选了是，它就是正确的选项，学生需要根据图片按顺序选择正确的选项。选完后页面上会呈现答案拼音和汉字',
    templateImg:
      'https://static.nihaocafe.com/kb/d0403833-af21-46c1-ab1d-431fff7524ec.png',
    contents: [
      {
        label: '音频选项列表',
        fieldName: 'listenChoosePicList',
        value: [
          [
            {
              label: '图片选项列表',
              fieldName: 'picChooseList',
              value: [],
              type: 8
            },
            {
              label: '答案图片',
              fieldName: 'answerPic',
              value: '',
              options: [],
              type: 60
            },
            {
              label: '音频',
              fieldName: 'audioSrc',
              value: '',
              type: 2
            },
            {
              label: '答案拼音',
              fieldName: 'pyDetail',
              value: '',
              type: 1
            },
            {
              label: '答案汉字',
              fieldName: 'hanziDetail',
              value: '',
              type: 1
            },
            {
              label: '汉字列表',
              fieldName: 'zhList',
              value: [
                [
                  {
                    label: '汉字',
                    fieldName: 'zh',
                    value: '',
                    type: 1
                  },
                  {
                    label: '拼音',
                    fieldName: 'py',
                    value: '',
                    type: 1
                  },
                  {
                    label: '是否正确',
                    fieldName: 'isRight',
                    value: '',
                    type: 4,
                    options: [
                      {
                        label: '是',
                        value: 0
                      },
                      {
                        label: '否',
                        value: 1
                      }
                    ]
                  }
                ]
              ],
              type: 9
            }
          ]
        ],
        type: 9
      }
    ]
  },
  // wr 复习巩固总 （2种选择）
  {
    templateId: 33,
    tempalteName: '拍照游戏',
    templateField: '33_GamePhoto',
    description:
      '老师需上传一张背景图片与一张正确图片，且有左右两个选项可决定这两张图片在页面上的位置。另外还要上传一张对应页面右上角位置的按钮图片。当点击屏幕上的手指图片时，背景图片会变为正确图片。这个游戏可以点击图片列表右侧的加号上传多组内容，然后通过点击页面右上角的按钮图片进行切换。',
    templateImg:
      'https://static.nihaocafe.com/kb/11cf023c-fccf-48f6-ac7c-c8708e740bd3.png',
    contents: [
      {
        label: '标题汉字',
        fieldName: 'titleZh',
        value: '谁？',
        type: 1
      },
      {
        label: '标题拼音',
        fieldName: 'titlePy',
        value: 'shei',
        type: 1
      },
      {
        label: '图片列表',
        fieldName: 'photoList',
        value: [
          [
            {
              label: '背景图片',
              fieldName: 'bgImg',
              value: '',
              type: 3
            },
            {
              label: '正确图片',
              fieldName: 'answerImg',
              value: '',
              type: 3
            },
            {
              label: '位置',
              fieldName: 'position',
              value: '',
              type: 4,
              options: [
                {
                  label: 'left',
                  value: 0
                },
                {
                  label: 'right',
                  value: 1
                }
              ]
            },
            {
              label: '按钮图片',
              fieldName: 'btnImg',
              value: '',
              type: 3
            }
          ]
        ],
        type: 9
      }
    ]
  },
  {
    // JiHui 复习巩固(八种选择)
    templateId: 34,
    tempalteName: '看图选择游戏',
    templateField: '34_GameMirrorPickWords',
    description:
      '首先得上传一张关卡图片，接着，要给每个关卡图片挑选一个正确答案。当学生选择了正确答案时就能进入下一关。能够通过点击添加关卡右侧的加号来增添多个关卡，只有选出所有关卡的正确答案，游戏才会结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/9b4a870e-3b74-42d7-b979-686eb1f090d5.png',
    contents: [
      {
        label: '选项1拼音',
        fieldName: 'firstOptionPinyin',
        type: 1,
        value: ''
      },
      {
        label: '选项1汉字',
        fieldName: 'firstOptionHanzi',
        type: 1,
        value: ''
      },
      {
        label: '选项2拼音',
        fieldName: 'secondOptionPinyin',
        type: 1,
        value: ''
      },
      {
        label: '选项2汉字',
        fieldName: 'secondOptionHanzi',
        type: 1,
        value: ''
      },
      {
        label: '添加关卡',
        fieldName: 'mirrorPictureList',
        type: 9,
        value: [
          [
            {
              label: '关卡图片',
              fieldName: 'mirrorPicture',
              value: '',
              type: 3
            },
            {
              label: '关卡答案',
              fieldName: 'mirrorAnswer',
              value: '',
              type: 4,
              options: [
                {
                  label: '选项1',
                  value: 0
                },
                {
                  label: '选项2',
                  value: 1
                }
              ]
            }
          ]
        ]
      }
    ]
  },
  {
    // JiHui 复习巩固(八种选择)
    templateId: 35,
    tempalteName: '看图说话模板II',
    templateField: '35_GameSpeakingTwoSentence',
    description:
      '首先，右上方会展示序号图片，点击序号图片可以切换关卡。每一关还需要有对应的背景图和关卡图片，如果没有关卡图片的话默认是背景图，然后输入对应的标题拼音和标题汉字和问题答案和问题拼音以及相关的答案汉字和答案拼音。当你点击屏幕时，会展示问题拼音和汉字，再次点击后会显示刚刚上传的关卡图片及其对应的答案汉字和答案拼音。你需要将所有关卡点击一遍，游戏才会结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/f50d9261-e0c3-47ad-894a-e7c705967c82.png',
    contents: [
      {
        label: '添加关卡',
        fieldName: 'speakingTwoSentenceList',
        type: 9,
        value: [
          [
            {
              label: '标题拼音',
              fieldName: 'titlePinYin',
              value: '',
              type: 1
            },
            {
              label: '标题汉字',
              fieldName: 'titleHanZi',
              value: '',
              type: 1
            },
            {
              label: '背景图片',
              fieldName: 'bgPic',
              value: '',
              type: 3
            },
            {
              label: '关卡图片',
              fieldName: 'bgImg',
              value: '',
              type: 3
            },
            {
              label: '问题汉字',
              fieldName: 'questionHanZi',
              value: '',
              type: 1
            },
            {
              label: '问题拼音',
              fieldName: 'questionPinYin',
              value: '',
              type: 1
            },
            {
              label: '答案汉字',
              fieldName: 'answerHanzi',
              value: '',
              type: 1
            },
            {
              label: '答案拼音',
              fieldName: 'answerPinyin',
              value: '',
              type: 1
            }
          ]
        ]
      }
    ]
  },
  // wr 复习巩固总 （2种选择）
  {
    templateId: 36,
    tempalteName: '根据图片选择汉字',
    templateField: '36_GamePracticeByWord',
    description:
      '老师在拼音与汉字的输入框里输入相应内容后，这些内容会在页面上构成一个按钮。随后，下方图片列表里的图片会逐个在页面上展示出来。答案就是按钮上的内容，点击按钮就能过关。可以点击练习列表右侧的加号来添加多组按钮及其对应的答案图片。开始游戏时，每一组图片列表中的所有图片会依次呈现，此时需要在下方选择正确的按钮并点击，这样就能通过。',
    templateImg:
      'https://static.nihaocafe.com/kb/e0627d57-34f9-4075-8a63-e5e46e1fb8d7.png',
    contents: [
      {
        label: '练习列表',
        fieldName: 'practiceList',
        type: 9,
        value: [
          [
            {
              label: '拼音',
              fieldName: 'pinyin',
              type: 1,
              value: ''
            },
            {
              label: '汉字',
              fieldName: 'hanzi',
              type: 1,
              value: ''
            },
            {
              label: '图片列表',
              fieldName: 'imageList',
              type: 8,
              value: []
            }
          ]
        ]
      }
    ]
  },
  {
    // JiHui 复习巩固(八种选择)
    templateId: 37,
    tempalteName: '根据图片回答问题',
    templateField: '37_GameSpeakByWatchingPhone',
    description:
    '老师需要上传一些问题图片和输入标题拼音和汉字以及答案拼音和汉字，页面原本有一个手机图片，点击页面时，会展示问题图片。问题图片是在手机中间有一张几个人物的合照，学生得根据这张图片回答有多少个人物；再次点击屏幕会展示答案拼音和汉字；最后点击问题图片下方点赞的爱心图标会变红。关卡序号是位于页面右上角每一关的图片，点击它可以切换关卡。',
    templateImg:
      'https://static.nihaocafe.com/kb/1c4b19e6-7332-43f3-9971-bfeafe2b8c49.png',
    contents: [
      {
        label: '添加关卡',
        fieldName: 'TwitterGameList',
        type: 9,
        value: [
          [
            {
              label: '标题拼音',
              fieldName: 'titlePy',
              value: '',
              type: 1
            },
            {
              label: '标题汉字',
              fieldName: 'titleZh',
              value: '',
              type: 1
            },
            {
              label: '问题图片',
              fieldName: 'Img1',
              value: '',
              type: 3
            },
            {
              label: '答案拼音',
              fieldName: 'answerPy',
              value: '',
              type: 1
            },
            {
              label: '答案汉字',
              fieldName: 'answerZh',
              value: '',
              type: 1
            },
            {
              label: '关卡序号',
              fieldName: 'menuNum',
              value: 0,
              type: 3
            }
          ]
        ]
      }
    ]
  },
  // wr 说一说
  {
    templateId: 38,
    tempalteName: '转盘游戏',
    templateField: '38_GameSpeakByTurnTable',
    description:
      '老师首先要输入标题的拼音和汉字,接着上传背景图片、转盘图片以及箭头图片。之后在转盘内容列表里输入对应的拼音与汉字,点击右侧加号可以进行添加,总共需要四组。其中第一组的序号为 0 ,其余三组的序号按顺序依次加 1,转盘上的内容必须严格与之对应，即上方对应序号 3,右方对应序号 0,下方对应序号 1,左方对应序号 2。点击页面的时候,转盘图片会开始旋转,当旋转到特定位置后,对应的汉字和拼音就会在页面上显示出来。',
    templateImg:
      'https://static.nihaocafe.com/kb/9c6b8bbf-067b-48c6-8112-b65d30d747cb.png',
    contents: [
      {
        label: '标题拼音',
        fieldName: 'titlePy',
        value: '',
        type: 1
      },
      {
        label: '标题汉字',
        fieldName: 'titleZh',
        value: '',
        type: 1
      },
      {
        label: '背景图片',
        fieldName: 'backgroundImg',
        value: '',
        type: 3
      },
      // {
      //   label: '转盘图片',
      //   fieldName: 'turnTableImg',
      //   value: '',
      //   type: 3
      // },

      {
        label: '转盘内容列表',
        fieldName: 'turnTableList',
        value: [
          [
            {
              label: '头像',
              fieldName: 'avatar',
              value: '',
              type: 3
            },
            {
              label: '拼音',
              fieldName: 'pinyin',
              value: '',
              type: 1
            },
            {
              label: '汉字',
              fieldName: 'hanzi',
              value: '',
              type: 1
            }
          ]
        ],
        type: 9
      }
    ]
  },
  {
    // Lihao title '词卡-认读'
    templateId: 39,
    tempalteName: '看字识图游戏',
    templateField: '39_GameChoosePicByWords',
    description:
      '点击问题列表右侧的加号可以添加多个关卡，每一关老师需要先输入标题拼音和汉字，然后在图片列表位置上传多张图片，它们会在左侧显示，并在正确答案下拉框中选择正确的图片序号，老师还需要上传一张正确图片，根据标题的描述，学生需要从左侧的几张图片中选择正确的图片进行拖拽。当学生将正确的图片拖拽到右侧并匹配成功后右侧会显示上传的那张正确图片，游戏会进入到下一关。继续进行这个过程，直到完成所有关卡，游戏才会结束',
    templateImg:
      'https://static.nihaocafe.com/kb/78967aa9-8c72-4b8d-97e4-658cb63a324a.png',
    contents: [
      {
        label: '问题列表',
        fieldName: 'questionLists',
        type: 9,
        value: [
          [
            {
              label: '标题拼音',
              fieldName: 'titlePy',
              type: 1,
              value: ''
            },
            {
              label: '标题汉字',
              fieldName: 'titleZh',
              type: 1,
              value: ''
            },
            {
              label: '图片列表',
              fieldName: 'imageList',
              type: 8,
              value: []
            },
            {
              label: '正确答案',
              fieldName: 'correctPic',
              value: '',
              type: 60,
              options: []
            },
            {
              label: '正确图片',
              fieldName: 'correctImg',
              value: '',
              type: 3
            }
          ]
        ]
      }
    ]
  },
  // wr 说一说
  {
    templateId: 40,
    tempalteName: '根据图片选择汉字II',
    templateField: '40_GamePracticeByWordII',
    description:
      '老师要上传图片，接着填写答案的汉字与拼音。在汉字列表里，可以点击右侧的加号添加多组内容，每一组都作为一个选项。选项的正确顺序就是汉字列表中每一组从上到下的顺序。学生需要根据图片按顺序选择选项，选完后答案的汉字和拼音会在页面上显示出来。老师可以点击图片练习列表右侧的加号添加多个关卡，通过上传的按钮图片在右上角进行关卡切换。',
    templateImg:
      'https://static.nihaocafe.com/kb/e7f06841-30d0-4f32-ba16-e8a6e3fd9f48.png',
    contents: [
      {
        label: '图片练习列表',
        fieldName: 'practiceImgList',
        type: 9,
        value: [
          [
            {
              label: '图片',
              fieldName: 'img',
              value: '',
              type: 3
            },
            {
              label: '答案拼音',
              fieldName: 'answerPy',
              value: '',
              type: 1
            },
            {
              label: '答案汉字',
              fieldName: 'answerZh',
              value: '',
              type: 1
            },
            {
              label: '汉字列表',
              fieldName: 'wordList',
              type: 9,
              value: [
                [
                  {
                    label: '拼音',
                    fieldName: 'pinyin',
                    value: '',
                    type: 1
                  },
                  {
                    label: '汉字',
                    fieldName: 'hanzi',
                    value: '',
                    type: 1
                  }
                ]
              ]
            }
          ]
        ]
      }
    ]
  },
  // wr 说一说
  {
    templateId: 41,
    tempalteName: '图卡游戏',
    templateField: '41_GameWordCard',
    description:
      '老师需要在标题拼音和汉字的输入框中输入相应的内容，接着填写图片的拼音和汉字。上传到图片正面的图片是默认会在页面上显示的，当点击这张图片的时候，它会被替换成上传到图片反面的图片，而且下方会显示图片的汉字和拼音。可以点击卡片列表右侧的加号来添加多组这样的内容。',
    templateImg:
      'https://static.nihaocafe.com/kb/97e10fe4-b79f-4dae-8475-42546a509484.png',
    contents: [
      {
        label: '标题拼音',
        fieldName: 'titlePy',
        value: '',
        type: 1
      },
      {
        label: '标题汉字',
        fieldName: 'titleZh',
        value: '',
        type: 1
      },
      {
        label: '卡片列表',
        fieldName: 'cardList',
        value: [
          [
            {
              label: '图片拼音',
              value: '',
              fieldName: 'pinyin',
              type: 1
            },
            {
              label: '图片汉字',
              value: '',
              fieldName: 'hanzi',
              type: 1
            },
            {
              label: '图片正面',
              value: '',
              fieldName: 'image',
              type: 3
            },
            {
              label: '图片反面',
              value: '',
              fieldName: 'backImage',
              type: 3
            }
          ]
        ],
        type: 9
      }
    ]
  },
  // wr 说一说
  {
    templateId: 42,
    tempalteName: '篮球游戏',
    templateField: '42_GameBasketball',
    description:
      '你要在选项图片列表里上传若干张不同的篮球图片。能够点击问题列表右侧的加号来添加多个关卡，每个关卡都要上传音频，并且从选项图片列表中选定一张图片作为答案。之后，学生要依据音频里的提示，在几张篮球图片里选出正确的那张。每当选中正确的图片，就表示通过了当前关卡，此时右上角会增加一颗小星星，这意味着你获得了分数。',
    templateImg:
      'https://static.nihaocafe.com/kb/d5603f05-c155-4b54-b4bb-dbb6f941703d.png',
    contents: [
      // {
      //   label: '选项图片列表',
      //   fieldName: 'imgOptionList',
      //   value: [],
      //   type: 8
      // },
      {
        label: '问题列表',
        fieldName: 'basketBallList',
        value: [
          [
            {
              label: '音频',
              fieldName: 'audioSrc',
              value: '',
              type: 2
            },
            {
              label: '音频内容',
              fieldName: 'audioPy',
              value: '',
              type: 1
            }
          ]
        ],
        type: 9
      }
    ]
  },
  {
    // JiHui 复习巩固(八种选择)
    templateId: 43,
    tempalteName: '根据音频选择图片游戏II',
    templateField: '43_GameChooseImgByListening',
    description:
      '点击添加关卡右侧的加号可以添加多个关卡，每一关需要上传一个音频和四张图片，四张图片包含一张正确的图片和三张选项图片，它们在页面上的顺序会被自动打乱。学生需要根据音频的提示，选择出正确的图片。当你点击正确的图片时，将进入到下一关。完成所有关卡后，游戏才会结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/bf6f1029-328d-405a-86db-1206b40365f2.png',
    contents: [
      {
        label: '添加关卡',
        fieldName: 'chooseImgByListeningList',
        type: 9,
        value: [
          [
            {
              label: '音频',
              fieldName: 'audioSrc',
              value: '',
              type: 2
            },
            {
              label: '正确图片',
              fieldName: 'correctImg',
              value: '',
              type: 3
            },
            {
              label: '图片1',
              fieldName: 'Img1',
              value: '',
              type: 3
            },
            {
              label: '图片2',
              fieldName: 'Img2',
              value: '',
              type: 3
            },
            {
              label: '图片3',
              fieldName: 'Img3',
              value: '',
              type: 3
            }
          ]
        ]
      }
    ]
  },
  // wr 说一说
  {
    templateId: 44,
    tempalteName: '篮球游戏II',
    templateField: '44_GameBasketballII',
    description:
      '老师可以点击问题列表右侧的加号添加多个关卡，每个关卡需要上传一个音频，还要在选项列表上传多组选项。每一组选项包含一个输入框和单选框，单选框判断该输入框中的内容是否为正确选项，学生需要根据音频的内容点击正确的选项，成功后会加上一颗小星星，并且进入到下一关，直到学生通过所有关卡，游戏才会结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/d5603f05-c155-4b54-b4bb-dbb6f941703d.png',
    contents: [
      {
        label: '问题列表',
        fieldName: 'basketBallPyList',
        value: [
          [
            {
              label: '音频',
              fieldName: 'audioSrc',
              value: '',
              type: 2
            },
            {
              label: '选项列表',
              fieldName: 'optionList1',
              value: [
                [
                  {
                    label: '选项内容',
                    fieldName: 'optionContent',
                    value: '',
                    type: 1
                  },
                  {
                    label: '是否正确答案',
                    fieldName: 'isCorrectAnswer',
                    value: true,
                    type: 4,
                    options: [
                      {
                        label: '是',
                        value: true
                      },
                      {
                        label: '否',
                        value: false
                      }
                    ]
                  }
                ]
              ],
              type: 9
            }
          ]
        ],
        type: 9
      }
    ]
  },
  {
    // Lihao title '词卡-认读'
    templateId: 45,
    tempalteName: '根据问题选择图片游戏',
    templateField: '45_GameChoosePicByQuestion',
    description:
      '你可以设置标题拼音和汉字，点击选项图片列表右侧的加号可以添加多个关卡，每一关需要上传按钮图片，它会显示在页面右上角，点击可以切换关卡，还需要上传卡片正面图片和反面图片，可以点击卡片列表右侧加号添加多组，页面上会呈现每一组的卡片正面图片，点击它后会替换成卡片反面图片，在游戏中，根据标题的提示，依次点击每一张卡片进行翻转。当所有关卡中的所有卡片都翻转完成后，游戏将结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/8d989b16-8c56-438a-9c0a-dac9baa366f8.png',
    contents: [
      {
        label: '标题拼音',
        fieldName: 'titlePy',
        value: '',
        type: 1
      },
      {
        label: '标题汉字',
        fieldName: 'titleZh',
        value: '',
        type: 1
      },
      {
        label: '选项图片列表',
        fieldName: 'optionLists',
        value: [
          [
            {
              label: '按钮图片',
              value: '',
              fieldName: 'buttonImg',
              type: 3
            },
            {
              label: '卡片列表',
              value: [
                [
                  {
                    label: '卡片正面',
                    value: '',
                    fieldName: 'front',
                    type: 3
                  },
                  {
                    label: '卡片反面',
                    value: '',
                    fieldName: 'back',
                    type: 3
                  }
                ]
              ],
              fieldName: 'cardLists',
              type: 9
            }
          ]
        ],
        type: 9
      }
    ]
  },
  {
    // JiHui 复习巩固(八种选择)
    templateId: 46,
    tempalteName: '根据图片选择拼图游戏',
    templateField: '46_GamePickWordsByPic',
    description:
      '点击添加关卡右侧的加号来添加关卡，每一关需要添加关卡序号图片，序号图片会在右上方展示，点击它可以切换关卡。每一关需要上传一个问题图片、输入问题内容的一部分拼音内容和一个音频以及选择的拼音列表，并且选择列表中的正确答案。学生需要根据问题图片上的内容，结合音频然后从左侧的图片列表中选择正确的图片进行拖拽。当学生将正确拼音图片拖拽到右侧第二张拼图之前，上传的第一张拼图就会展示出来，它和页面上已经显示的第二张拼图组合在一起就是问题图片上汉字的拼音。然后点击右上方序号图片进入到下一关，所有关卡完成后游戏结束',
    templateImg:
      'https://static.nihaocafe.com/kb/5cb3bfa9-01b3-4908-837e-b65909714814.png',
    contents: [
      {
        label: '添加关卡',
        fieldName: 'pickWordsByPicList',
        type: 9,
        value: [
          [
            {
              label: '问题图片',
              fieldName: 'fullImg',
              value: '',
              type: 3
            },
            {
              label: '显示拼音',
              fieldName: 'pinyin',
              value: '',
              type: 1
            },
            {
              label: '音频',
              fieldName: 'audioUrl',
              value: '',
              type: 2
            },
            {
              label: '拼音选项',
              fieldName: 'pinyinList',
              value: [],
              type: 61,
              options: []
            },
            {
              label: '答案',
              fieldName: 'answerId',
              value: '',
              type: 60,
              options: []
            }
          ]
        ]
      }
    ]
  },
  // wr
  {
    templateId: 47,
    tempalteName: '根据句子选择图片',
    templateField: '47_GameChoosePicBySentence',
    description:
      '首先，你需要上传一个背景图和一张灰色图片以及一张选中图片。点击图片选项列表后面的加号可以添加多组背景图和灰色图片以及选中图片，这些灰色图片会显示在右侧。当你点击任意一张灰色图片时，它会变成选中的状态，灰色图片会切换为选中图片，模板中央会显示相应的背景图。你需要点击所有的灰色图片一次，游戏就会结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/d4959f65-d47b-49fe-8ec8-12234ef0fa60.png',
    contents: [
      {
        label: '图片选项列表',
        fieldName: 'picMenuList',
        value: [
          [
            {
              label: '背景图片',
              fieldName: 'bgImg',
              value: '',
              type: 3
            },
            {
              label: '灰色图片',
              fieldName: 'grayImg',
              value: '',
              type: 3
            },
            {
              label: '选中图片',
              fieldName: 'menuImg',
              value: '',
              type: 3
            }
          ]
        ],
        type: 9
      }
    ]
  },
  // wr 说一说
  {
    templateId: 48,
    tempalteName: '根据句子选择图片II',
    templateField: '48_GameChoosePicBySentenceII',
    description:
      '首先，你需要输入标题拼音和汉字。接着，上传一系列图片选项列表，图片选项列表包括选中图片以及句子拼音和汉字，点击图片选项列表后面的加号可以添加图片选项列表，灰色图片会显示在右侧。当你点击其中一张灰色图片时，灰色图片会切换为选中图片，同时模板中心也会显示对应的背景图。再次点击背景图下方会展示对应的句子拼音和汉字。你需要点击所有的灰色图片和背景图，游戏就会结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/19b9e508-f651-4a49-b8ee-b23755f16fe1.png',
    contents: [
      {
        label: '标题拼音',
        fieldName: 'titlePy',
        value: '',
        type: 1
      },
      {
        label: '标题汉字',
        fieldName: 'titleZh',
        value: '',
        type: 1
      },
      {
        label: '背景图片',
        fieldName: 'bgImg',
        value: '',
        type: 3
      },
      {
        label: '图片选项列表',
        fieldName: 'picMenuChooseList',
        value: [
          [
            {
              label: '选中图片',
              fieldName: 'menuImg',
              value: '',
              type: 3
            },
            {
              label: '句子拼音',
              fieldName: 'sentencePy',
              value: '',
              type: 1
            },
            {
              label: '句子汉字',
              fieldName: 'sentenceZh',
              value: '',
              type: 1
            }
          ]
        ],
        type: 9
      }
    ]
  },
  //   // wr 有趣的汉字
  {
    templateId: 49,
    tempalteName: '汉字卡片',
    templateField: '49_GameHanziCard',
    description:
      '首先，输入标题拼音和汉字以及卡片拼音和汉字。卡片拼音和汉字会呈现在页面右侧卡片中，接着，上传一段音频。然后，根据卡片和音频的内容，上传两张背景图片和一张卡片组词图片，并且输入对应的卡片组词拼音和汉字，卡片组词拼音和汉字会呈现在右侧蓝色方框内。当你点击背景图片时，左侧的两张背景图片会轮流展示。点击右侧蓝色方框时，页面会展示上传的卡片组词图片',
    templateImg:
      'https://static.nihaocafe.com/kb/075b165c-def8-4b68-931b-08d1b81205a0.png',
    contents: [
      {
        label: '背景图片',
        fieldName: 'bgImg',
        value: '',
        type: 3
      },
      {
        label: '背景图片2',
        fieldName: 'changeBgImg',
        value: '',
        type: 3
      },
      {
        label: '标题拼音',
        fieldName: 'titlePy',
        value: '',
        type: 1
      },
      {
        label: '标题汉字',
        fieldName: 'titleZh',
        value: '',
        type: 1
      },
      {
        label: '卡片拼音',
        fieldName: 'cardPy',
        value: '',
        type: 1
      },
      {
        label: '卡片汉字',
        fieldName: 'cardZh',
        value: '',
        type: 1
      },
      {
        label: '卡片音频',
        fieldName: 'cardAudio',
        value: '',
        type: 2
      },
      {
        label: '卡片组词拼音',
        fieldName: 'wordPy',
        value: '',
        type: 1
      },
      {
        label: '卡片组词汉字',
        fieldName: 'wordZh',
        value: '',
        type: 1
      },
      {
        label: '卡片组词图片',
        fieldName: 'wordImg',
        value: '',
        type: 3
      }
    ]
  },
  // wr 课程目标
  {
    templateId: 50,
    tempalteName: '根据title选择图片',
    templateField: '50_GameFindElement',
    description:
      '首先，玩家需要选择游戏类型。根据所选的游戏类型，输入相应的标题拼音和汉字，并上传对应的背景图片和点击图片列表。背景图片上会固定的多个可点击区域，玩家点击这些区域后，会显示相关的点击图片。游戏的目标是确保所有点击区域都被点击过一次，完成后游戏将结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/e5815203-36dd-4876-bd91-342459d20ea0.png',
    contents: [
      {
        label: '标题拼音',
        fieldName: 'titlePy',
        value: '',
        type: 1
      },
      {
        label: '标题汉字',
        fieldName: 'titleHanzi',
        value: '',
        type: 1
      },
      {
        label: '背景图片',
        fieldName: 'bgImg',
        value: '',
        type: 3
      },
      {
        label: '点击图片列表',
        fieldName: 'clickImgList',
        value: [],
        type: 8
      },
      {
        label: '选择游戏',
        fieldName: 'gameType',
        value: '',
        type: 60,
        options: [
          {
            label: '捉迷藏',
            value: 30
          },
          {
            label: '找鼻子',
            value: 35
          },
          {
            label: '菜单',
            value: 51
          },
          {
            label: '时间',
            value: 57
          },
          {
            label: '钟表时间',
            value: 59
          },
          {
            label: '日历',
            value: 61
          }
        ]
      }
    ]
  },
  // wr 语法练习
  {
    templateId: 51,
    tempalteName: '认结构',
    templateField: '51_GameRecognitionImg',
    description:
      '首先，您需要上传与汉字结构图片和输入汉字结构的汉字及拼音。接下来，根据汉字的结构，上传两张背景图片：一张是汉字结构分开的图片，另一张是正常合并的汉字图片。当您点击分开的图片时，页面会切换为正常的汉字图片。',
    templateImg:
      'https://static.nihaocafe.com/kb/664ed323-4644-4f3e-915d-58ab0e131c02.png',
    contents: [
      {
        label: '背景图片',
        fieldName: 'bgImg',
        value: '',
        type: 3
      },
      {
        label: '点击后的背景图片',
        fieldName: 'defaultImg',
        value: '',
        type: 3
      },
      {
        label: '结构拼音',
        fieldName: 'pinyin',
        value: '',
        type: 1
      },
      {
        label: '结构中文',
        fieldName: 'hanzi',
        value: '',
        type: 1
      },
      {
        label: '结构图片',
        fieldName: 'structureImg',
        value: '',
        type: 3
      }
    ]
  },
  {
    // JiHui 复习巩固(八种选择)
    templateId: 52,
    tempalteName: '选词排序过河游戏',
    templateField: '52_GameWordSortingCrossRiver',
    description:
      '每一关老师需要输入汉字和拼音，并且上传一个音频，一共四组，这四组从上到下就是正确答案的顺序，它们在页面上会被系统自动打乱，每一组是这座桥的一部分，学生需要根据每一组的内容将它们拖动到正确的位置，组合成一句通顺的话。可以添加多个关卡，当成功完成后，将进入到下一关。当所有关卡都完成后，游戏结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/d2ba212e-9c82-4b14-9156-b2354d965a7e.png',
    contents: [
      {
        label: '添加关卡',
        fieldName: 'wordSortingCrossRiverList',
        type: 9,
        value: [
          [
            {
              label: '汉字',
              fieldName: 'firstHanZi',
              value: '',
              type: 1
            },
            {
              label: '拼音',
              fieldName: 'firstPinYin',
              value: '',
              type: 1
            },
            {
              label: '音频',
              fieldName: 'firstAudioUrl',
              value: '',
              type: 2
            },
            {
              label: '汉字',
              fieldName: 'secondHanZi',
              value: '',
              type: 1
            },
            {
              label: '拼音',
              fieldName: 'secondPinYin',
              value: '',
              type: 1
            },
            {
              label: '音频',
              fieldName: 'secondAudioUrl',
              value: '',
              type: 2
            },
            {
              label: '汉字',
              fieldName: 'thirdHanZi',
              value: '',
              type: 1
            },
            {
              label: '拼音',
              fieldName: 'thirdPinYin',
              value: '',
              type: 1
            },
            {
              label: '音频',
              fieldName: 'thirdAudioUrl',
              value: '',
              type: 2
            },
            {
              label: '汉字',
              fieldName: 'fourthHanZi',
              value: '',
              type: 1
            },
            {
              label: '拼音',
              fieldName: 'fourthPinYin',
              value: '',
              type: 1
            },
            {
              label: '音频',
              fieldName: 'fourthAudioUrl',
              value: '',
              type: 2
            }
          ]
        ]
      }
    ]
  },
  // wr 结构/偏旁（练一练）（3种类型）
  {
    templateId: 53,
    tempalteName: '拖拽笔画游戏',
    templateField: '53_GameDragStrokeToImg',
    description:
      '首先上传一张背景图和两个笔画选项图片，背景图需要固定分为左、中、右三部分。接着，上传翻转图片将翻转图片覆盖在背景图上，并根据位置下拉框左、中、右来确定每个翻转图片的位置。根据笔画选项下拉框来确定该翻转图片的笔画选项，每个翻转图片都有对应的笔画选项和笔画图片。玩家需要拖拽正确的笔画选项到翻转图片上。一旦正确放置，笔画图片会先显示，然后翻转图片和笔画图片会一起消失，露出下面的背景图。这个过程会重复进行，直到所有翻转图片都消失，最终完整的背景图展示出来，游戏结束。（翻转图片和笔画图片为固定位置有小图周围带有空白的图片）',
    templateImg:
      'https://static.nihaocafe.com/kb/08c534d5-8a95-49b0-99aa-4a43d46ee9d3.png',
    contents: [
      {
        label: '背景图片',
        fieldName: 'bgImg',
        value: '',
        type: 3
      },
      {
        label: '选项图片',
        fieldName: 'imgChooseList',
        value: [],
        type: 8
      },
      {
        label: '拼图列表',
        fieldName: 'puzzleList',
        value: [
          [
            {
              label: '笔画图片',
              fieldName: 'strokeImg',
              value: '',
              type: 3
            },
            {
              label: '翻转图片',
              filedName: 'flipImg',
              value: '',
              type: 3
            },
            {
              label: '笔画选项',
              fieldName: 'strokeValue',
              value: '',
              options: [],
              type: 60
            },
            {
              label: '位置',
              fieldName: 'position',
              value: '',
              type: 60,
              options: [
                {
                  label: 'left',
                  value: 1
                },
                {
                  label: 'middle',
                  value: 2
                },
                {
                  label: 'right',
                  value: 3
                }
              ]
            }
          ]
        ],
        type: 9
      }
    ]
  },
  //Aimer title:'单元通关'
  {
    templateId: 54,
    tempalteName: '点击移动飞船游戏',
    templateField: '54_GameMovingSpaceship',
    description:
      '首先，上传一张较长的图片作为背景图。背景图被分为多个部分，默认情况下只展示第一部分。接着，上传一个飞船图片。玩家点击背景图时，飞船的位置保持不变，并且背景图会切换至下一部分。重复点击背景图，直到展示最后一部分为止，游戏结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/2e379632-7742-4691-85eb-0b27f6e75a15.png',
    contents: [
      {
        label: '背景图片',
        fieldName: 'bgImg',
        value: '',
        type: 3
      },
      {
        label: '移动飞船图片',
        fieldName: 'studentImg',
        value: '',
        type: 3
      },
      {
        label: '替换熊猫图片',
        fieldName: 'btnImg',
        value: '',
        type: 3
      }
      // {
      //   label: '移动飞船图片2',
      //   fieldName: 'frontImg',
      //   value: '',
      //   type: 3
      // }
    ]
  },
  {
    // JiHui 复习巩固(八种选择)
    templateId: 56,
    tempalteName: '擦除汉字游戏',
    templateField: '56_GameEraseHanzi',
    description:
      '老师可以输入汉字和拼音组成单个的字或者词语甚至是句子,点击添加字词右侧的加号可以添加多组，也可以同时上传多张图片,这些会呈现在黑板上,学生需要拖动右下角的黑板擦擦除黑板上的所有内容',
    templateImg:
      'https://static.nihaocafe.com/kb/18d0f02c-45ac-49f8-b78f-4f3e471cbeda.png',
    contents: [
      {
        label: '添加字词',
        fieldName: 'eraseWordList',
        type: 9,
        value: [
          [
            {
              label: '汉字',
              fieldName: 'hanzi',
              value: '',
              type: 1
            },
            {
              label: '拼音',
              fieldName: 'pinyin',
              value: '',
              type: 1
            }
          ]
        ]
      },
      {
        label: '添加图片',
        fieldName: 'eraseImgList',
        type: 8,
        value: []
      }
    ]
  },
  {
    // Lihao title '词卡-认读'
    templateId: 57,
    tempalteName: '猜卡片游戏',
    templateField: '57_GameGuessCards',
    description:
      '老师需要填写拼音和汉字。上传到卡片正面的图片是默认会在页面上显示的，当点击这张图片的时候，它会被替换成上传到卡片反面的图片，而且下方会显示汉字和拼音。可以点击总卡片右侧的加号来添加多组这样的内容。',
    templateImg:
      'https://static.nihaocafe.com/kb/5f95b282-b754-4f4a-85db-432aff897734.png',
    contents: [
      {
        label: '总卡片',
        fieldName: 'allCards',
        type: 9,
        value: [
          [
            {
              label: '卡片正面',
              fieldName: 'front',
              value: '',
              type: 3
            },
            {
              label: '卡片反面',
              fieldName: 'back',
              value: '',
              type: 3
            },
            {
              label: '汉字',
              fieldName: 'hanzi',
              value: '',
              type: 1
            },
            {
              label: '拼音',
              fieldName: 'pinyin',
              value: '',
              type: 1
            }
          ]
        ]
      }
    ]
  },
  {
    // Lihao title '词卡-认读'
    templateId: 58,
    tempalteName: '找图片及句子游戏',
    templateField: '58_GameFindPicAndWords',
    description:
      '老师需要先上传一张背景图，接着上传选项图片，这些选项图片会在页面上显示出来。当点击选项图片后，页面会展示出上传的句子图片，再次点击句子图片后，就会显示在汉字和拼音输入框中输入的内容。可以点击总卡片右侧的加号来添加多组这样的内容。',
    templateImg:
      'https://static.nihaocafe.com/kb/dd8e0b56-a156-48c8-9a2b-ad2242464c5c.png',
    contents: [
      {
        label: '背景图',
        fieldName: 'bgImg',
        value: '',
        type: 3
      },
      {
        label: '句子图片列表',
        fieldName: 'allImgs',
        type: 9,
        value: [
          [
            {
              label: '选项图片',
              fieldName: 'optionPic',
              value: '',
              type: 3
            },
            {
              label: '句子图片',
              fieldName: 'img',
              value: '',
              type: 3
            },
            {
              label: '汉字',
              fieldName: 'hanzi',
              value: '',
              type: 1
            },
            {
              label: '拼音',
              fieldName: 'pinyin',
              value: '',
              type: 1
            }
          ]
        ]
      }
    ]
  },
  //Aimer title:'总结页'
  {
    templateId: 59,
    tempalteName: '拼音总结游戏',
    templateField: '59_GamePinyinSummary',
    description:
      '选择现有模板下拉框中的选项，可以切换不同的拼音总结模板。在拼音总结游戏中，点击灰色的拼音，它将变为红色。点亮所有拼音后，游戏结束。',
    templateImg:
      'https://static.nihaocafe.com/kb/d15d6b0e-49c4-4684-a1f8-8c50b52b5e00.png',
    contents: [
      {
        label: '现有课程模板',
        fieldName: 'ExistingTemplate',
        type: 60,
        options: [
          {
            value: '23',
            label: '["d","t","n","l"]'
          },
          {
            value: '31',
            label: '["g", "k", "h", "j", "q", "x"]'
          },
          {
            value: '39',
            label: '["z", "c", "s", "zh", "ch", "sh", "r"]'
          },
          {
            value: '47',
            label: '["ai", "ei", "ao", "ou", "ie", "ue", "ui", "iu", "er"]'
          },
          {
            value: '55',
            label: '["an", "en", "un", "un1", "ang", "in", "ing", "eng", "ong"]'
          },
          {
            value: '63',
            label:
              '["zhi", "chi", "ri", "zi", "ci", "si", "wu", "yu", "ye", "yue", "yun", "yi", "yin", "yuan", "ying"]'
          }
        ],
        value: ''
      }
    ]
  },
  {
    // JiHui 复习巩固(八种选择)
    templateId: 60,
    tempalteName: '看图识字游戏',
    templateField: '60_GameSpeakByWatchingPic',
    description:
      '老师需要输入拼音、汉字以及英文,并且上传一张图片,一共三组,当点击每张图片时页面上会呈现出刚刚输入的拼音、汉字和英文',
    templateImg:
      'https://static.nihaocafe.com/kb/d40aba2d-f4f1-42af-a9f1-6559e4fd9195.png',
    contents: [
      {
        label: '第一张图片拼音',
        fieldName: 'firstPinYin',
        value: '',
        type: 1
      },
      {
        label: '第一张图片汉字',
        fieldName: 'firstHanzi',
        value: '',
        type: 1
      },
      {
        label: '第一张图片英文',
        fieldName: 'firstEnglish',
        value: '',
        type: 1
      },
      {
        label: '第一张图片',
        fieldName: 'firstImg',
        value: '',
        type: 3
      },
      {
        label: '第二张图片拼音',
        fieldName: 'secondPinYin',
        value: '',
        type: 1
      },
      {
        label: '第二张图片汉字',
        fieldName: 'secondHanzi',
        value: '',
        type: 1
      },
      {
        label: '第二张图片英文',
        fieldName: 'secondEnglish',
        value: '',
        type: 1
      },
      {
        label: '第二张图片',
        fieldName: 'secondImg',
        value: '',
        type: 3
      },
      {
        label: '第三张图片拼音',
        fieldName: 'thirdPinYin',
        value: '',
        type: 1
      },
      {
        label: '第三张图片汉字',
        fieldName: 'thirdHanzi',
        value: '',
        type: 1
      },
      {
        label: '第三张图片英文',
        fieldName: 'thirdEnglish',
        value: '',
        type: 1
      },
      {
        label: '第三张图片',
        fieldName: 'thirdImg',
        value: '',
        type: 3
      }
    ]
  },
  {
    // JiHui 复习巩固(八种选择)
    templateId: 61,
    tempalteName: '看图说话模板III',
    templateField: '61_GameSpeakByPic',
    description:
      '老师需要上传一张图片,学生根据这张图片回答问题,点击图片后页面会呈现老师输入的汉字和拼音,点击添加关卡右侧的加号可以添加多个关卡，关卡序号是位于页面右上角每一关的图片，点击它可以切换关卡。',
    templateImg:
      'https://static.nihaocafe.com/kb/2d748e5f-9a14-415e-98cb-2baa685e5d6d.png',
    contents: [
      {
        label: '添加关卡',
        fieldName: 'gameSpeakByPicList',
        type: 9,
        value: [
          [
            {
              label: '图片',
              fieldName: 'bgImg',
              value: '',
              type: 3
            },
            {
              label: '汉字',
              fieldName: 'hanZi',
              value: '',
              type: 1
            },
            {
              label: '拼音',
              fieldName: 'pinYin',
              value: '',
              type: 1
            },
            {
              label: '关卡序号图片',
              fieldName: 'menuImg',
              value: '',
              type: 3
            }
          ]
        ]
      }
    ]
  },
  {
    templateId: 62,
    tempalteName: '根据汉字选择笔画游戏',
    templateField: '62_GameChooseStrokeByHanzi',
    description:
      '老师要上传一张九宫格图片，每个格子里都有一个汉字。每张笔画图片都是右侧的一个笔画选项，而笔画总数就是它在九宫格上的数量。接着老师需要依次上传每个汉字所具有的笔画（要严格对应位置），并填写其在右侧笔画选项中对应的序号（从 1 开始）,可以添加多个。学生要根据九宫格中每个汉字的笔画，把右侧的笔画选项拖动到对应位置，若正确，就会显示出老师在该汉字上上传的笔画图片。',
    templateImg:
      'https://static.nihaocafe.com/kb/43227e1e-f0a0-48a0-bb24-722f7c99b1f5.png',
    contents: [
      {
        label: '九宫格图片',
        fieldName: 'bgImg',
        value: '',
        type: 3
      },
      {
        label: '添加笔画',
        fieldName: 'strokeList',
        value: [
          [
            {
              label: '笔画图片',
              fieldName: 'strokeImg',
              value: '',
              type: 3
            },
            {
              label: '笔画总数',
              fieldName: 'strokeNum',
              value: '',
              type: 1
            }
          ]
        ],
        type: 9
      },
      {
        label: '第一个汉字',
        fieldName: 'firstHanziStrokeImgList',
        type: 9,
        value: [
          [
            {
              label: '包含笔画图片',
              fieldName: 'firstHanziStrokeImg',
              value: '',
              type: 3
            },
            {
              label: '对应右侧笔画序号',
              fieldName: 'firstHanziStrokeType',
              value: '',
              type: 1
            }
          ]
        ]
      },
      {
        label: '第二个汉字',
        fieldName: 'secondHanziStrokeImgList',
        type: 9,
        value: [
          [
            {
              label: '包含笔画图片',
              fieldName: 'secondHanziStrokeImg',
              value: '',
              type: 3
            },
            {
              label: '对应右侧笔画序号',
              fieldName: 'secondHanziStrokeType',
              value: '',
              type: 1
            }
          ]
        ]
      },
      {
        label: '第三个汉字',
        fieldName: 'thirdHanziStrokeImgList',
        type: 9,
        value: [
          [
            {
              label: '包含笔画图片',
              fieldName: 'thirdHanziStrokeImg',
              value: '',
              type: 3
            },
            {
              label: '对应右侧笔画序号',
              fieldName: 'thirdHanziStrokeType',
              value: '',
              type: 1
            }
          ]
        ]
      },
      {
        label: '第四个汉字',
        fieldName: 'fourthHanziStrokeImgList',
        type: 9,
        value: [
          [
            {
              label: '包含笔画图片',
              fieldName: 'fourthHanziStrokeImg',
              value: '',
              type: 3
            },
            {
              label: '对应右侧笔画序号',
              fieldName: 'fourthHanziStrokeType',
              value: '',
              type: 1
            }
          ]
        ]
      },
      {
        label: '第五个汉字',
        fieldName: 'fifthHanziStrokeImgList',
        type: 9,
        value: [
          [
            {
              label: '包含笔画图片',
              fieldName: 'fifthHanziStrokeImg',
              value: '',
              type: 3
            },
            {
              label: '对应右侧笔画序号',
              fieldName: 'fifthHanziStrokeType',
              value: '',
              type: 1
            }
          ]
        ]
      },
      {
        label: '第六个汉字',
        fieldName: 'sixthHanziStrokeImgList',
        type: 9,
        value: [
          [
            {
              label: '包含笔画图片',
              fieldName: 'sixthHanziStrokeImg',
              value: '',
              type: 3
            },
            {
              label: '对应右侧笔画序号',
              fieldName: 'sixthHanziStrokeType',
              value: '',
              type: 1
            }
          ]
        ]
      },
      {
        label: '第七个汉字',
        fieldName: 'seventhHanziStrokeImgList',
        type: 9,
        value: [
          [
            {
              label: '包含笔画图片',
              fieldName: 'seventhHanziStrokeImg',
              value: '',
              type: 3
            },
            {
              label: '对应右侧笔画序号',
              fieldName: 'seventhHanziStrokeType',
              value: '',
              type: 1
            }
          ]
        ]
      },
      {
        label: '第八个汉字',
        fieldName: 'eighthHanziStrokeImgList',
        type: 9,
        value: [
          [
            {
              label: '包含笔画图片',
              fieldName: 'eighthHanziStrokeImg',
              value: '',
              type: 3
            },
            {
              label: '对应右侧笔画序号',
              fieldName: 'eighthHanziStrokeType',
              value: '',
              type: 1
            }
          ]
        ]
      },
      {
        label: '第九个汉字',
        fieldName: 'ninthHanziStrokeImgList',
        type: 9,
        value: [
          [
            {
              label: '包含笔画图片',
              fieldName: 'ninthHanziStrokeImg',
              value: '',
              type: 3
            },
            {
              label: '对应右侧笔画序号',
              fieldName: 'ninthHanziStrokeType',
              value: '',
              type: 1
            }
          ]
        ]
      }
    ]
  },
  {
    templateId: 63,
    tempalteName: '地图模板',
    templateField: '63_GameMap',
    description:
      ' 在课程开始或结束时会出现地图，通过该地图可查看当前课程（lesson）的进度，老师可以上传地图背景初始地图和地图背景最终地图，如果勾选了地图类型为开始，进入页面后会显示初始地图，然后过一会儿显示最终地图；如果勾选了地图类型为结束，进入页面后会显示初始地图，然后过关后显示最终地图，总共有五种模版类型可供选择，每一种的箭头位置和星星位置都不相同。',
    templateImg:
      'https://static.nihaocafe.com/kb/1117ca07-5fbe-4d68-b516-48a4f31a9da8.png',
    contents: [
      {
        label: '初始背景图片',
        fieldName: 'bgImg',
        value: '',
        type: 3
      },
      {
        label: '结束背景图片',
        fieldName: 'bgImgEnd',
        value: '',
        type: 3
      },
      {
        label: '课件位置',
        fieldName: 'position',
        value: '',
        options: [
          {
            label: '开始页',
            value: 0
          },
          {
            label: '结束页',
            value: 1
          }
        ],
        type: 4
      },
      {
        label: '进入页面滚动',
        fieldName: 'isScroll',
        value: '',
        options: [
          {
            label: 'true',
            value: 1
          },
          {
            label: 'false',
            value: 0
          }
        ],
        type: 4
      },
      {
        label: '模板类型',
        fieldName: 'modelType',
        value: '',
        options: [
          {
            label: '模板一',
            value: 1
          },
          {
            label: '模板二',
            value: 2
          },
          {
            label: '模板三',
            value: 3
          },
          {
            label: '模板四',
            value: 4
          },
          {
            label: '模板五',
            value: 5
          }
        ],
        type: 60
      }
    ]
  },
  {
    // Lihao title '词卡-认读'
    templateId: 64,
    tempalteName: '小火车词汇+拼音',
    templateField: '64_GameTrainWordsAndPy',
    description:
      '在词汇列表输入拼字和汉字就可以往小火车上添加词汇方块，在拼音列表输入拼音添加拼音方块',
    templateImg:
      'https://static.nihaocafe.com/kb/bda228e2-1e3e-4b4f-883e-082b84f27dea.png',
    contents: [
      {
        label: '词汇列表',
        fieldName: 'wordsLists',
        value: [
          [
            {
              label: '词汇拼音',
              fieldName: 'wordsPy',
              value: '',
              type: 1
            },
            {
              label: '词汇汉字',
              fieldName: 'wordsZh',
              value: '',
              type: 1
            }
          ]
        ],
        type: 9
      },
      {
        label: '句子列表',
        fieldName: 'sentenceList',
        value: [
          [
            {
              label: '句子拼音',
              fieldName: 'sentencePy',
              value: '',
              type: 1
            },
            {
              label: '句子汉字',
              fieldName: 'sentenceZh',
              value: '',
              type: 1
            }
          ]
        ],
        type: 9
      },
      {
        label: '拼音列表',
        fieldName: 'pyList',
        value: [
          [
            {
              label: '拼音',
              fieldName: 'pinyin',
              value: '',
              type: 1
            }
          ]
        ],
        type: 9
      },
      {
        label: '笔画列表',
        fieldName: 'bhList',
        value: [],
        type: 8
      }
    ]
  },
  // wr  写一写
  {
    templateId: 65,
    tempalteName: '找笔画游戏模板',
    templateField: '65_GameFindStroke',
    description:
      '老师首先要填写标题的拼音和汉字，接着上传一张背景图片。其中，笔画初始状态图片是默认状态下的图片，当找到相应笔画后，它就会变成已上传的笔画选中时的图片。在笔画列表里可以添加多组内容（但总数不能超过五组），每一组都要上传未找到时的图片和找到笔画后的图片，而且它们的位置必须与背景图上笔画的位置严格对应。背景图上默认显示未找到时的图片，点击后会替换成找到笔画后的图片。',
    templateImg:
      'https://static.nihaocafe.com/kb/d40aba2d-f4f1-42af-a9f1-6559e4fd9195.png',
    contents: [
      {
        label: '标题拼音',
        fieldName: 'titlePy',
        value: '',
        type: 1
      },
      {
        label: '标题汉字',
        fieldName: 'titleZh',
        value: '',
        type: 1
      },
      {
        label: '背景图片',
        fieldName: 'bgImg',
        value: '',
        type: 3
      },
      {
        label: '笔画初始状态图片',
        fieldName: 'inittrokeImg',
        value: '',
        type: 3
      },
      {
        label: '笔画选中时图片',
        fieldName: 'strokeChooseImg',
        value: '',
        type: 3
      },
      {
        label: '模板位置',
        fieldName: 'modelType',
        value: '',
        options: [
          {
            label: '模板一',
            value: 1
          },
          {
            label: '模板二',
            value: 2
          },
          {
            label: '模板三',
            value: 3
          }
        ],
        type: 60
      },
      {
        label: '笔画列表',
        fieldName: 'strokeImgList',
        value: [
          [
            {
              label: '未找到时的图片',
              fieldName: 'strokeNotFondImg',
              value: '',
              type: 3
            },
            {
              label: '找到笔画后的图片',
              fieldName: 'strokeFoundImg',
              value: '',
              type: 3
            }
          ]
        ],
        type: 9
      }
    ]
  },
  // Lihao  导入（四种选择）  title '音乐模板'
  {
    templateId: 67,
    tempalteName: '音乐模板',
    templateField: '67_MusicPage',
    templateImg:
      'https://static.nihaocafe.com/kb/fb44caff-9a41-481c-8ae0-dd38dbeb8ba8.png',
    contents: []
  },
  {
    templateId: 68,
    tempalteName: '看图描绘汉字游戏',
    description:
      '老师首先要输入汉字的拼音，接着在汉字背景图片的位置上传一张田字格的灰色汉字图片，然后在汉字步骤提示图片的位置上传一张该汉字按顺序书写每一个笔画的图片。在右侧图片列表中，需要上传多张图片，每次会在右侧展示一张，每写完一个笔画就会切换成下一张。最后，在笔画描绘动图列表的位置上传多张图片，这些图片是该汉字按顺序书写每一个笔画的动图。',
    templateField: '68_GameDrawByImg',
    templateImg:
      'https://static.nihaocafe.com/kb/ac5f4aac-6269-4197-bca2-73ff20fcb9bd.png',
    contents: [
      {
        label: '拼音',
        fieldName: 'strokePinYin',
        value: '',
        type: 1
      },
      {
        label: '汉字背景图片',
        fieldName: 'strokeHanZiBgImg',
        value: '',
        type: 3
      },
      {
        label: '汉字步骤提示图片',
        fieldName: 'strokeStepImg',
        value: '',
        type: 3
      },
      {
        label: '右侧图片列表',
        fieldName: 'rightImgList',
        value: [],
        type: 8
      },
      {
        label: '笔画描绘步骤动图列表',
        fieldName: 'strokeStepImgList',
        value: [],
        type: 8
      }
    ]
  },
  {
    templateId: 69,
    tempalteName: '看图说话',
    templateField: '69_SpeakingPage',
    templateImg:
      '@/assets/img/03-Backgrounds/level-1-chapter-2-background-color.svg',
    description:
      '老师首先要输入当前课件的标题和拼音，然后需要上传一张背景图片。接着就是输入需要让学生练习的对话内容，每个输入框输入一句，多个对话可以通过右上角的“+”号来进行新增。',
    contents: [
      {
        label: '标题汉字',
        fieldName: 'titleZh',
        value: '',
        type: 1
      },
      {
        label: '标题拼音',
        fieldName: 'titlePy',
        value: '',
        type: 1
      },
      {
        label: '背景图片',
        fieldName: 'bgImg',
        value: '',
        type: 3
      },
      {
        label: '文字列表',
        fieldName: 'textList',
        value: [
          [
            {
              label: '文字',
              fieldName: 'speakContent',
              value: '',
              type: 7,
              options: []
            }
          ]
        ],
        type: 9
      }
    ]
  }
]

export const lesssonFieldList = [
  {
    label: 'level',
    fieldName: 'levelId',
    value: 1,
    type: null
  },
  {
    label: 'chapter',
    fieldName: 'chapterId',
    value: 1,
    type: null
  },
  {
    label: 'lesson',
    fieldName: 'lessonId',
    value: 1,
    type: null
  }
]
