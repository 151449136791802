var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "game-pick-words-by-pic-container" },
    [
      _c("SceneSwitchThumbnails", {
        key: _vm.key,
        attrs: { buttons: _vm.buttonList, numBers: _vm.currentIndex },
        on: { changeTheNumbers: _vm.changeTheNumbers },
      }),
      _vm._m(0),
      _c("div", { staticClass: "pinyin-puzzle-game-box" }, [
        _c("div", { staticClass: "pinyin-puzzle-game-content" }, [
          _c("div", {
            staticClass: "drop-area",
            on: {
              drop: function ($event) {
                $event.stopPropagation()
                return _vm.drop()
              },
              dragover: function ($event) {
                $event.stopPropagation()
                return _vm.allowDrop($event)
              },
            },
          }),
          _c("div", { staticClass: "top-area" }, [
            _c(
              "div",
              {
                staticClass: "play-audio",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.playAudio.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  [
                    _c("AudioPlayer", {
                      attrs: {
                        isPlay: _vm.isStartPlaying,
                        audioUrl: _vm.songUrl,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm.answerList[_vm.currentIndex].type === 1
              ? _c("div", { staticClass: "puzzle-area" }, [
                  _c("div", { staticClass: "part1" }, [
                    _c("img", {
                      class: {
                        notShow: !_vm.answerList[_vm.currentIndex].isCorrect,
                      },
                      attrs: {
                        src: require("@/assets/img/FixedPicture/puzzle-white.svg"),
                        alt: "",
                      },
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "pinyin-puzzle",
                        class: {
                          notShow: !_vm.answerList[_vm.currentIndex].isCorrect,
                        },
                      },
                      [_vm._v(_vm._s(_vm.answerList[_vm.currentIndex].firstPy))]
                    ),
                  ]),
                  _c("div", { staticClass: "part2" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/FixedPicture/puzzle-white.svg"),
                        alt: "",
                      },
                    }),
                    _c("span", { staticClass: "pinyin-puzzle" }, [
                      _vm._v(_vm._s(_vm.answerList[_vm.currentIndex].secondPy)),
                    ]),
                  ]),
                ])
              : _c("div", { staticClass: "puzzle-area" }, [
                  _c("div", { staticClass: "part1" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/FixedPicture/puzzle-white.svg"),
                        alt: "",
                      },
                    }),
                    _c("span", { staticClass: "pinyin-puzzle" }, [
                      _vm._v(_vm._s(_vm.answerList[_vm.currentIndex].secondPy)),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.answerList[_vm.currentIndex].isCorrect,
                          expression: "answerList[currentIndex].isCorrect",
                        },
                      ],
                      staticClass: "part2",
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/FixedPicture/puzzle-white.svg"),
                          alt: "",
                        },
                      }),
                      _c("span", { staticClass: "pinyin-puzzle" }, [
                        _vm._v(
                          _vm._s(_vm.answerList[_vm.currentIndex].firstPy)
                        ),
                      ]),
                    ]
                  ),
                ]),
          ]),
          _c("img", {
            staticClass: "objectDisplay",
            attrs: { src: _vm.answerList[_vm.currentIndex].fullImg },
          }),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "option-area" },
        [
          _vm._l(_vm.optionList[_vm.currentIndex], function (item, index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: item.showObj == true,
                    expression: "item.showObj == true",
                  },
                ],
                key: index,
                staticClass: "puzzle-item",
                attrs: { draggable: "true" },
                on: {
                  dragstart: function ($event) {
                    return _vm.startDrag(item.image)
                  },
                  drop: function ($event) {
                    $event.stopPropagation()
                    return _vm.Sdrop($event)
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "puzzleImage",
                  attrs: {
                    src: require("@/assets/img/FixedPicture/puzzle-red.svg"),
                  },
                }),
                _c("span", [_vm._v(_vm._s(item.image))]),
              ]
            )
          }),
          _c("img", {
            staticClass: "options-background",
            attrs: { src: _vm.objectListBackground },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bg-img" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/img/16-GAMES/G22-drag-abc/background.svg"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }