<template>
  <div
    class="course-end-container"
    :style="{ backgroundImage: 'url(\'' + bgImg + '\')' }"
  >
    <div class="begin-end-box">
      <div class="customlesson_twoimg">
        <img src="@/assets/img/05-Panda/panda-zaijian-background.svg" alt="" />
        <div class="customlesson_num">
          <div class="customlesson_two">
            <span class="font-pinyin-xlarge pinyin">{{ titlePinyin }}</span>
          </div>
          <div class="customlesson_three">
            <span class="font-hanzi-xlarge">{{ title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AudioPlayer from '@/components/Course/GamePage/AudioPlayer'
import { templatePreviewMixin } from './templatePreviewMixin.js'

export default {
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          title: '',
          titlePinyin: '',
          bgImg: ''
        }
      }
    }
  },
  components: {
    AudioPlayer
  },
  data() {
    return {
      lessononehover: false,
      lessononehovertwo: false,
      hoverFontNum: 0,
      clickSocketInfo: {},
      isStartPlaying: null,
      title: '',
      titlePinyin: '',
      bgImg: '',
      localData: {
        title: '再见',
        titlePinyin: 'Zàijiàn',
        bgImg:
          'http://test.nihaokids.com/img/level-1-background-color.b624b516.svg'
      }
    }
  },
  watch: {
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20000,
          data: { value },
          text: '测试StartStop组件内点击事件socket'
        }
        this.$bus.$emit('kids_webSocket_sendInfo', data)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        // console.log(value, '这是watch的数据')
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  mounted() {
    this.$bus.$on('clickStartStop', () => {
      this.activeClick('socket', true)
    })
    this.$bus.$on('hoverStartStop', (val) => {
      console.log('hover', val)
      this.hoverImg(val, true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickStartStop')
    this.$bus.$off('hoverStartStop')
  },
  methods: {
    initData() {
      this.title = this.componentProps.title
      this.titlePinyin = this.componentProps.titlePinyin
      this.bgImg = this.componentProps.bgImg
    },
    hoverImg(id, isFromSocket = false) {
      if (!isFromSocket) {
        this.hoverFontNum++
      }
      this.hoverFontId = id
      switch (id) {
        case 1:
          this.lessononehover = !this.lessononehover
          break
        case 2:
          this.lessononehovertwo = !this.lessononehovertwo
          break
      }
    },
    activeClick(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickSocketInfo = {
          value: Math.random()
        }
      }
      this.isStartPlaying = Math.random()
    }
  }
}
</script>
<style scoped lang="scss">
.course-end-container {
  width: 100%;
  height: 100%;
  border-radius: 56px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  -moz-background-size: 100% 100%;
  background-color: #ffdbb8;
  .begin-end-box {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 56px;
    background-color: rgba(41, 40, 36, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    .customlesson_twoimg {
      width: 50%;
      height: 40%;
      position: relative;
      img {
        width: 100%;
      }
      .customlesson_num {
        position: absolute;
        left: 17%;
        width: 100%;
        top: 25%;

        @media screen and (max-width: 2200px) {
          // external screeneft: 22%;
          top: 15%;
        }

        .customlesson_two {
          cursor: default;
          font-family: 'STHeitiSCPinyin-light';
          span {
            color: #224e96;
          }
        }
        .customlesson_three {
          width: 100%;
          cursor: default;
          span {
            color: #224e96;
          }
          .txt-hz {
            font-size: 6em;
            line-height: 1.5em;

            @media screen and (max-width: 1500px) {
              // laptop
              line-height: 1.5em;
              font-size: 4em;
            }
            @media screen and (max-width: 1300px) {
              // ipad
              line-height: 1em;
            }
          }
        }
      }
    }
  }
}
</style>
