var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "game-speak-by-watching-pic-container",
      class: {
        noBorder: _vm.isGuidePage,
      },
    },
    [
      _c("div", { staticClass: "guide-page-box" }, [
        _c(
          "div",
          { staticClass: "guide-page-content" },
          _vm._l(_vm.cardList, function (item, index) {
            return _c("div", { key: index, staticClass: "card-item" }, [
              index !== 1
                ? _c("div", { staticClass: "card-up" }, [
                    _c(
                      "div",
                      {
                        staticClass: "img-box",
                        on: {
                          mouseenter: function ($event) {
                            return _vm.handleMouseEnter(index)
                          },
                          mouseout: _vm.handleMouseOut,
                          click: function ($event) {
                            return _vm.handleClickCard(index)
                          },
                        },
                      },
                      [
                        _c("img", {
                          class: [
                            _vm.scaleimageanimation
                              ? "scaleToAppear"
                              : "isHidden",
                          ],
                          attrs: {
                            src: [
                              _vm.isHover && _vm.hoverIndex === index
                                ? item.hoverImg
                                : item.bgImg,
                            ],
                          },
                        }),
                      ]
                    ),
                  ])
                : _vm._e(),
              index !== 1
                ? _c("div", { staticClass: "card-dowm" }, [
                    item.isShowAnswer
                      ? _c("div", { staticClass: "card-dowm-content-box" }, [
                          _c("div", { staticClass: "card-dowm-content" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "pinyin card-pinyin font-pinyin-medium",
                              },
                              [_vm._v(_vm._s(item.pinyin))]
                            ),
                            _c(
                              "span",
                              { staticClass: "card-pinyin font-hanzi-medium" },
                              [_vm._v(_vm._s(item.hanzi))]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "card-english font-english-medium",
                              },
                              [_vm._v(_vm._s(item.english))]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              index === 1
                ? _c("div", { staticClass: "card-dowm secondAnswerItem" }, [
                    item.isShowAnswer
                      ? _c(
                          "div",
                          {
                            staticClass: "card-dowm-content-box",
                            class: { redBg: item.bgColorType === 1 },
                          },
                          [
                            _c("div", { staticClass: "card-dowm-content" }, [
                              _c(
                                "span",
                                { staticClass: "pinyin font-pinyin-medium" },
                                [_vm._v(_vm._s(item.pinyin))]
                              ),
                              _c("span", { staticClass: "font-hanzi-medium" }, [
                                _vm._v(_vm._s(item.hanzi)),
                              ]),
                              _c(
                                "span",
                                { staticClass: "font-english-medium" },
                                [_vm._v(_vm._s(item.english))]
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              index === 1
                ? _c("div", { staticClass: "card-up secondItem" }, [
                    _c(
                      "div",
                      {
                        staticClass: "img-box",
                        on: {
                          mouseenter: function ($event) {
                            return _vm.handleMouseEnter(index)
                          },
                          mouseout: _vm.handleMouseOut,
                          click: function ($event) {
                            return _vm.handleClickCard(index)
                          },
                        },
                      },
                      [
                        _c("img", {
                          class: [
                            _vm.scaleimageanimation
                              ? "scaleToAppear"
                              : "isHidden",
                          ],
                          attrs: {
                            src: [
                              _vm.isHover && _vm.hoverIndex === index
                                ? item.hoverImg
                                : item.bgImg,
                            ],
                          },
                        }),
                      ]
                    ),
                  ])
                : _vm._e(),
            ])
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }