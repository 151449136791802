var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-choose-img" }, [
    _c(
      "div",
      { staticClass: "game-choose-content" },
      [
        _c("SceneSwitchThumbnails", {
          attrs: { buttons: _vm.buttonList, numBers: _vm.numBers },
          on: { changeTheNumbers: _vm.changeTheNumbers },
        }),
        _c("div", { staticClass: "choose-img-area" }, [
          _c("div", { staticClass: "img-area" }, [
            _c(
              "div",
              { staticClass: "wenzi" },
              _vm._l(_vm.allImgList, function (item, index) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.currentIndex === index,
                        expression: "currentIndex === index",
                      },
                    ],
                    key: index,
                    staticClass: "trumpet-area-pinyin",
                  },
                  [
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "title-pinyin pinyin font-pinyin-medium",
                        },
                        [_vm._v(" " + _vm._s(item.pinyin) + " ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "title-hanzi font-hanzi-medium" },
                        [_vm._v(" " + _vm._s(item.hanzi) + " ")]
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
            _vm.isShowCheckGif
              ? _c("div", {
                  directives: [
                    {
                      name: "checkgif",
                      rawName: "v-checkgif",
                      value: _vm.checkGif,
                      expression: "checkGif",
                    },
                  ],
                  staticClass: "checkGif",
                  class: { checkGifWrong: !_vm.checkGif },
                })
              : _vm._e(),
            _vm.allImgList[_vm.currentIndex] &&
            _vm.allImgList[_vm.currentIndex].imgList &&
            _vm.allImgList[_vm.currentIndex].imgList.length
              ? _c(
                  "div",
                  { staticClass: "img-content" },
                  _vm._l(
                    _vm.allImgList[_vm.currentIndex].imgList,
                    function (item, index) {
                      return _c("img", {
                        key: index + "img",
                        staticClass: "img-item",
                        class: {
                          isCorrect:
                            item.id === _vm.answerList[_vm.currentIndex] &&
                            index === _vm.chooseImgIndex,
                        },
                        attrs: { src: item.bgImg, alt: "" },
                        on: {
                          click: function ($event) {
                            return _vm.handleChooseImg(item.id, index)
                          },
                        },
                      })
                    }
                  ),
                  0
                )
              : _vm._e(),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }