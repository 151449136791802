var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "recognition-pinyin-container" }, [
    _c(
      "div",
      { staticClass: "main-content" },
      [
        _c(
          "div",
          { staticClass: "game-box" },
          [
            _vm.totalStarNum
              ? _c("Star", {
                  attrs: {
                    totalStarNumber: _vm.totalStarNum,
                    currentIndex: _vm.starNum,
                  },
                })
              : _vm._e(),
            Object.keys(_vm.titleInfo).length
              ? _c("div", { staticClass: "title-area" }, [
                  _c("div", { staticClass: "title" }, [
                    _c(
                      "div",
                      { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                      [_vm._v(" " + _vm._s(_vm.titleInfo.pinyin) + " ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "title-hanzi font-hanzi-medium" },
                      [_vm._v(" " + _vm._s(_vm.titleInfo.hanzi) + " ")]
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "game-body" }, [
              _vm.optionList[_vm.currentIndex] &&
              _vm.optionList[_vm.currentIndex].image
                ? _c("img", {
                    staticClass: "bgIamge",
                    class: {
                      fadeOut: _vm.isFadeOut,
                      fadeIn: _vm.isFadeIn,
                    },
                    attrs: { src: _vm.optionList[_vm.currentIndex].image },
                  })
                : _vm._e(),
            ]),
            _c("div", { staticClass: "bottonCont" }, [
              _c("div", { staticClass: "redBackground" }),
              _c(
                "div",
                { staticClass: "bottonsWrapper" },
                _vm._l(_vm.imgList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "content-words-two",
                      class: {
                        bgcolor: !item.nobgcolor,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.foundAnswer(item.id)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "font-pinyin-medium pinyin" }, [
                        _vm._v(_vm._s(item.pinying)),
                      ]),
                      _c("span", { staticClass: "font-hanzi-medium" }, [
                        _vm._v(_vm._s(item.hanzi)),
                      ]),
                      item.bottomImg
                        ? _c("img", { attrs: { src: item.bottomImg, alt: "" } })
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
            ]),
          ],
          1
        ),
        _c("CommonButton", {
          on: { shuffleClickEvent: _vm.handleShuffleClickEvent },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }