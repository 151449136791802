<template>
  <div class="course-word-card-container">
    <div class="red-background-border"></div>
    <div class="recogniton-page-box">
      <audio :src="wrodAudio" ref="audioBox"></audio>

      <div class="recogniton-page-content layout-red-bgc">
        <!-- 左边图片 left image -->
        <div class="recognition-content-left">
          <img class="santa-claus" :src="wrodImg" alt="" />
        </div>

        <!-- 右边文字 right words-->
        <div class="recogniton-content-right">
          <div class="recognize_container_content_right_border">
            <div class="recognize_container_content_right_content">
              <div
                class="recognize_container_content_right_content_top volume-button"
                :class="{ 'teacher-hover': isClickAudio }"
              >
                <div @click.stop="activeClick('socket')">
                  <AudioPlayer :isPlay="isStartPlaying" :audioUrl="wrodAudio" />
                </div>
              </div>
              <section v-if="alphabets">
                <div class="alphabet-content">
                  <p
                    class="pinyin font-hanzi-xlarge"
                    v-for="(item, index) in alphabets"
                    :key="index"
                  >
                    {{ item }}
                  </p>
                </div>

                <!-- <div class="tones-content">
                  <p
                    class="pinyin font-pinyin-large"
                    v-for="(item, index) in alphabetTones"
                    :key="index"
                  >
                    {{ item }}
                  </p>
                </div> -->
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudioPlayer from '@/components/Course/GamePage/AudioPlayer'
import { templatePreviewMixin } from './templatePreviewMixin.js'

export default {
  mixins: [templatePreviewMixin],
  components: {
    AudioPlayer
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          wrodAudio: '',
          wrodImg: '',
          alphabet: ''
          // alphabetTones: []
        }
      }
    }
  },
  data() {
    return {
      audioSocketInfo: {},
      songUrl: null,
      isClickAudio: false,
      clickNum: 0,
      isHover: false,
      // clickedVolumePlay: 0,
      isStartPlaying: null,
      isLastStep: true,
      wrodAudio: '',
      wrodImg: '',
      alphabet: '',
      alphabets: [],
      // alphabetTones: [],
      localData: {
        wrodAudio: '',
        wrodImg: 'http://test.nihaokids.com/img/lesson-01-nihao.095aa4dc.svg',
        // alphabet: 'ā,á,ǎ,à',
        alphabets: [' ā ', ' á ', ' ǎ ', ' à ']
      }
    }
  },
  watch: {
    // 发送
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20002,
          data: { value },
          text: '测试RecognitionPage组件内点击事件socket'
        }
        this.$bus.$emit('kids_webSocket_sendInfo', data)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  mounted() {
    this.$bus.$on('clickRecognitionPage', () => {
      this.activeClick('socket', true)
    })
    this.$bus.$on('hoverRecognitionPage', (val) => {
      this.isClickAudio = !this.isClickAudio
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickRecognitionPage')
  },
  methods: {
    initData() {
      this.wrodAudio = this.componentProps.wrodAudio
      this.wrodImg = this.componentProps.wrodImg
      this.alphabets = []
      this.alphabets.push(
        this.componentProps.alphabet1,
        this.componentProps.alphabet2,
        this.componentProps.alphabet3,
        this.componentProps.alphabet4
      )
      // this.alphabetTones = this.componentProps.alphabetTones
    },
    syncPlayAudio(arr) {
      function tone1() {
        return new Promise((resolve) => {
          setTimeout(() => {
            const audio = new Audio(arr[0])
            audio.play()
            resolve()
          }, 500)
        })
      }

      function tone2() {
        return new Promise((resolve) => {
          setTimeout(() => {
            const audio = new Audio(arr[1])
            audio.play()
            resolve()
          }, 1000)
        })
      }
      function tone3() {
        return new Promise((resolve) => {
          setTimeout(() => {
            const audio = new Audio(arr[2])
            audio.play()
            resolve()
          }, 1200)
        })
      }

      function tone4() {
        return new Promise((resolve) => {
          setTimeout(() => {
            const audio = new Audio(arr[3])
            audio.play()
            resolve()
          }, 1300)
        })
      }
      async function allTasks() {
        await tone1()
        await tone2()
        await tone3()
        await tone4()
      }
      allTasks()
    },
    activeClick(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.audioSocketInfo = {
          value: Math.random()
        }
      }
      this.isStartPlaying = Math.random()

      if (this.isTonePage) {
        console.log('+++++++++++')
        this.syncPlayAudio(this.wordObj.songUrlList)
      } else {
        this.songUrl = this.wordObj.songUrl
      }
    },
    nextPage() {
      this.$bus.$emit('nextButton', true)
    },
    updateNumbers(value) {
      this.numBers = value
    }
  }
}
</script>

<style lang="scss" scoped>
.course-word-card-container {
  width: 100%;
  height: 100%;
  display: flex;
  // justify-content: flex-start;
  // align-items: flex-start;
  position: relative;
  .recogniton-page-box {
    // display: none;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background: #fff;
    border-radius: 58px;
    z-index: 2;
    position: relative;
    .recogniton-page-content {
      background: #f7f4e0;
      border-radius: 58px;
      position: relative;
      z-index: 3;

      .recognition-content-left {
        width: 50%;
        height: 100%;
        position: absolute;
        img {
          position: absolute;
          right: 0;
          top: 20%;
          width: 80%;
        }
        .santa-claus {
          top: 15%;
        }
      }
      .recogniton-content-right {
        position: absolute;
        width: 50%;
        height: 100%;
        right: 0;
        .recognize_container_content_right_border {
          margin-top: 10px;
          margin-left: 10px;
          width: calc(70% - 10px);
          height: calc(60% - 10px);
          position: absolute;
          left: 8%;
          top: 13%;
          background: #224e96;
          border-radius: 40px;
          padding: 20px 10px;
          .recognize_container_content_right_content {
            color: #000;
            text-align: center;
            position: absolute;
            width: 100%;
            height: 100%;
            background: #fff;
            border-radius: 40px;
            top: -10px;
            left: -10px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            cursor: default;

            .recognize_container_content_right_content_top {
              width: 50px;
              height: 50px;
              background: #ce4c3f;
              border-radius: 50%;

              &.volume-button {
                cursor: pointer;
              }
            }
            .tones-content {
              margin-top: 10px;
              display: flex;
              gap: 20px;
              justify-content: center;
            }
            .alphabet-content {
              display: flex;
              gap: 10px;
              flex-wrap: wrap;
              justify-content: center;
              width: 88%;
              left: 12%;
              position: relative;
              .font-hanzi-xlarge {
                flex: 0 1 38%;
                margin: 4px;
                text-align: center;
                font-size: 60px !important;
              }
            }
          }
        }
      }
    }
  }
  .redColor {
    color: #ce524c;
  }
  .teacher-hover {
    img {
      background: #b5392c !important;
      border-radius: 50%;
    }
  }
}
</style>
