<template>
  <div class="game-basketball-container">
    <div class="basketball-box">
      <div class="basketball-game-area">
        <img class="bgIamge" />
        <div class="basketball-game-content">
          <div class="trumpter" @click="playAudio">
            <!-- <img class="volume-button" :src="volumeImgSrc" @ /> -->
            <AudioPlayer :isPlay="isStartPlaying" :audioUrl="songUrl" />
          </div>
          <img class="objectDisplay" :src="board" />

          <div v-if="playAnimation" class="ballDrop">
            <div class="image-container ball">
              <img class="img_basketball" :src="basketball" alt="" />
              <div class="centered-text pinyin" v-if="audioPy">
                {{ audioPy }}
              </div>
            </div>
          </div>
          <img class="basketDisplay" :src="basket" />
        </div>

        <div class="bottonCont">
          <div class="redBackground"></div>
          <div class="bottonsWrapper">
            <div
              v-for="(item, index) in optionList[question]"
              :key="index"
              class="option-item"
              :class="{
                shake:
                  item.id !== answerList[question].id &&
                  isClicked &&
                  index === chooseIndex
              }"
              @click="foundAnswer(index)"
            >
            <div v-if="item.showObj" class="image-container">
                <!-- <img  :src="item.audioPy" alt="" /> -->
                <img class="img_basketball" :src="basketball" alt="" />
                <div class="centered-text pinyin" v-if="item.audioPy">
                  {{ item.audioPy }}
                </div>
              </div>

              <img
                v-else
                class="img_basketball"
                :src="basketball_gray"
                alt=""
              />
            </div>
          </div>
        </div>

        <Star :totalStarNumber="startNum" :currentIndex="stepsIndex" />
      </div>
    </div>
  </div>
</template>

<script>
import Star from '@/components/Course/GamePage/Star'
import AudioPlayer from '@/components/Course/GamePage/AudioPlayer'
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    Star,
    AudioPlayer
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          basketBallPyList: []
        }
      }
    }
  },
  data() {
    return {
      answerList: [],
      optionList: [],
      audioList: [],
      audioPy: null,
      basketball: require('@/assets/img/FixedPicture/backetball.svg'),
      basketball_gray: require('@/assets/img/FixedPicture/basketball-gray.svg'),
      basket: require('@/assets/img/16-GAMES/G45-basketball/basket.svg'),
      board: require('@/assets/img/16-GAMES/G45-basketball/board.svg'),
      basketball_transparent: require('@/assets/img/16-GAMES/G45-basketball/basketball-transparent.svg'),
      shootball: require('@/assets/img/16-GAMES/G45-basketball/basketball-transparent.svg'),
      volumeImgSrc: require('@/assets/img/06-Sound/sound-white.svg'),
      playAnimation: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'speak',
            startNumberRange: 0,
            endNumberRange: 0
          }
        ]
      },
      isLastStep: false,
      stepsIndex: 0,
      question: 0,

      basketballSocketInfo: {},
      audioSocketInfo: {},
      isClicked: false,
      chooseIndex: null,
      isStartPlaying: null,
      songUrl: null,
      startNum: null,
      localData: {
        answerList: [
          {
            id: 1,
            sound: '',
            showObj: true
          },
          {
            id: 2,
            sound: '',
            showObj: true
          },
        ],
        optionList: [
          [
            {
              id: 1,
              audioPy: 'a',
              showObj: true,
              isAnswer: true  
            },
            {
              id: 2,
              audioPy: 'o',
              showObj: true,
              isAnswer: false
            },
            {
              id: 3,
              audioPy: 'e',
              showObj: true,
              isAnswer: false
            },
            {
              id: 4,
              audioPy: 'i',
              showObj: true,
              isAnswer: false
            }
          ],
          [
            {
              id: 1,
              audioPy: '1',
              showObj: true,
              isAnswer: false
            },
            {
              id: 2,
              audioPy: '2',
              showObj: true,
              isAnswer: true
            },
            {
              id: 3,
              audioPy: '3',
              showObj: true,
              isAnswer: false
            },
            {
              id: 4,
              audioPy: '4',
              showObj: true,
              isAnswer: false
            }
          ],
        ],
        audioList: ['', ''],
        startNum: 2
      }
    }
  },
  mounted() {
    // this.initData()
    this.$bus.$on('clickBasketballGame', ({ index }) => {
      this.foundAnswer(index, true)
    })
    this.$bus.$on('clickBasketballGameAudio', () => {
      this.playAudio('socket', true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickBasketballGame')
    this.$bus.$off('clickBasketballGameAudio')
  },
  watch: {
    basketballSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20035,
          data: { value },
          text: 'BasketballGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003501,
          data: { value },
          text: 'BasketballGame点击音频socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        this.initData()
      },
      deep: true
    }
  },
  methods: {
    initData() {
      // console.log(this.componentProps, '00000000')
      this.optionList = []
      this.answerList = []
      this.audioList = []
      let list = []
      if (
        this.componentProps.basketBallPyList &&
        this.componentProps.basketBallPyList.length
      ) {
        this.componentProps.basketBallPyList.forEach((item, index) => {
          this.audioList.push(item[0].value)
          list =
            item[1].value && item[1].value.length
              ? item[1].value.map((ele, eleIndex) => {
                  return {
                    id: eleIndex + 1,
                    audioPy: ele[0].value,
                    showObj: true,
                    isAnswer: ele[1].value,
                  }
                })
              : []
          // console.log(list, '111');
          
          this.answerList.push({
            id: item[1].value
              ? list.find((ele) => ele.isAnswer === true).id
              : null,
            sound: '',
            showObj: true
          })  
          this.optionList.push(list)
        })
      }
      //   console.log('optionList', this.optionList)
      this.startNum = this.audioList.length
    },
    playAudio(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.audioSocketInfo = {
          value: Math.random()
        }
      }
      this.isStartPlaying = Math.random()
      this.songUrl = this.audioList[this.stepsIndex]
    },
    foundAnswer(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.basketballSocketInfo = {
          index,
          value: Math.random()
        }
      }
      this.chooseIndex = index
      if (
        this.answerList[this.question].id ===
        this.optionList[this.question][index].id
      ) {
        this.shootball = this.optionList[this.question][index].audioPy
        this.playAnimation = true
        playCorrectSound(true, false)
        this.stepsIndex++
        this.optionList[this.question][index].showObj = false
        this.timer = setTimeout(() => {
          this.optionList[this.question][index].showObj = true

          this.playAnimation = false
          if (this.question + 1 < this.answerList.length) {
            this.question++
          } else {
            this.isLastStep = true
            playCorrectSound()
            startConfetti()
          }
          this.playAudio('socket')
        }, 2500)
      } else {
        this.isClicked = true
        playCorrectSound(false)
        setTimeout(() => {
          this.isClicked = false
        }, 1000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.game-basketball-container {
  width: 100%;
  height: 100%;
  .basketball-box {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 48px;
    position: relative;
    overflow: hidden;
    .basketball-game-area {
      text-align: -webkit-center;
      position: absolute;
      height: 100%;
      width: 100%;
      background: #f9f6e6;
      border-radius: 48px;
      margin: auto;
      overflow: hidden;
      .bgIamge {
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      .basketball-game-content {
        position: relative;
        width: -moz-fit-content;
        width: 100%;
        top: 17%;
        height: calc(80% - 150px);
        justify-content: center;
        z-index: 0;
        border-radius: 10%;
        display: flex;
        .trumpter {
          position: absolute;
          left: 45%;
          top: 6%;
          width: 7vh;
          height: 7vh;
          z-index: 1000;
          background-color: #224e96;
          border-radius: 50%;
          text-align: center;
          .volume-button {
            cursor: pointer;
          }
          img {
            width: 5vh;
            height: 5vh;
          }
        }
        .trumpter:hover {
          background-color: #1b4589;
        }
        .objectDisplay {
          position: absolute;
          height: 70%;
          width: 100%;
          z-index: 300;
        }
        .ballDrop {
          height: 100%;
          // width: 13%;
          z-index: 100;

          .ball {
            width: 100%;
            animation: ballAnim 3s 1;
            animation-direction: normal;
          }

          @keyframes ballAnim {
            0% {
              margin-top: 0;
            }

            100% {
              margin-top: 500%;
            }
          }
        }
        .basketDisplay {
          position: absolute;
          top: 55%;
          height: 50%;
          width: 100%;
          z-index: 200;
        }
      }
      .bottonCont {
        bottom: 0;
        width: 80%;
        /* margin-top: 45%; */
        height: 150px;
        position: absolute;
        display: flex;
        justify-content: center;
        left: 10%;
        right: 10%;
        .redBackground {
          width: 100%;
          height: 50%;
          border-top-right-radius: 30px;
          border-top-left-radius: 30px;
          background-color: #d87065;
          position: absolute;
          bottom: 0;
        }
        .bottonsWrapper {
          display: flex;
          bottom: 0;
          height: -moz-fit-content;
          justify-content: space-between;
          height: 100%;
          width: 78%;
          position: absolute;

          border-top-right-radius: 30px;
          border-top-left-radius: 30px;
          background-color: #ffffff;
          justify-content: space-evenly;
          align-items: center;
          padding: 10px 20px;
          .option-item {
            max-width: 27%;
            min-width: 100px;
            min-height: 100px;
            z-index: 1;
            border-radius: 15px;

            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            // span {
            //   color: #fff;
            //   font-size: 25px;
            // }
            // span:nth-child(2) {
            //   color: #fff;
            //   font-size: 30px;
            // }
            // img {
            //   max-width: 80%;
            // }
          }
          .option-item:active {
            transform: scale(0.95);
          }
          .option-item:hover {
            min-width: 100px;
            min-height: 100px;
            border-radius: 15px;
            border-bottom: 0px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            span {
              color: #fff;
              font-size: 25px;
            }
            span:nth-child(2) {
              color: #fff;
              font-size: 30px;
            }
          }
        }
      }
    }
    .image-container {
    position: relative;
    display: inline-block;
    .img_basketball {
      width: 100px !important;
      height: 100px !important;
    }
    .img_basketball_grgy {
      width: 100px !important;
      height: 100px !important;
    }
  }
  .centered-text {
    position: absolute;
    top: 48%;
    left: 48%;
    transform: translate(-50%, -50%);
    color: white; /* 根据图片背景调整文字颜色 */
    font-size: 42px; /* 根据需要调整字体大小 */
    text-align: center;
    // margin-top: -4px;
    font-weight: 600;
  }
  }
}
</style>
