<template>
  <div class="rank-map-page-container" :class="`lesson` + lesson">
    <transition name="el-fade-in">
      <div class="mask-book" v-if="position === 1 && maskShow">
        <div class="mask-book-content">
          <span class="font28">CHAPTER {{ chapterNum }}</span>
          <img
            :src="
              require(`@/assets/img/10-Level-progress/progress-level${lesson}.gif`)
            "
            :class="{ glow: position == 1 }"
            alt=""
          />
          <img
            src="@/assets/img/07-Stars/confetti.svg"
            alt=""
            class="confetti-img"
          />
        </div>
      </div>
    </transition>

    <div class="lesson_content">
      <!-- 书本显示 -->
      <div class="customlesson-breakthrough-part">
        <div class="demo">
          <div
            class="customlesson-breakthrough-part-book"
            v-for="(item, index) in 8"
            :key="index"
          >
            <span v-if="chapterNum < index + 1"
              ><img src="@/assets/img/10-Level-progress/book-cream.svg" alt=""
            /></span>
            <span v-if="chapterNum > index + 1"
              ><img src="@/assets/img/10-Level-progress/book-100.svg" alt=""
            /></span>
            <span v-if="chapterNum === index + 1"
              ><img
                :src="
                  require(`@/assets/img/10-Level-progress/lesson-${
                    position == 1 ? lesson : lesson - 1
                  }-complete.svg`)
                "
                :class="{ glow: position == 1 && chapterNum === 8 }"
                alt=""
            /></span>
          </div>
          <!-- </div> -->
        </div>
      </div>

      <!-- 背景图 -->
      <div class="customlesson-breakthrough-part-movement">
        <transition name="el-fade-in">
          <div class="picture" ref="pictureBox">
            <!-- 星星 -->
            <div class="star-area" :style="{ width: `${starWidth}px` }">
              <img
                class="animatedstar"
                :class="`chapterStar-${
                  chapterNum > 4 ? chapterNum - 4 : chapterNum
                }`"
                src="@/assets/img/07-Stars/star-big.svg"
                alt=""
                v-if="position === 1 && imageStep === 2"
              />
            </div>

            <div class="picture-item">
              <img
                v-show="imageStep === 1"
                class="background-image"
                :src="firstImg"
                alt=""
              />
              <img
                v-show="imageStep === 2"
                ref="imgContent"
                class="background-image"
                :src="lastImg"
                alt=""
                @load="handleImageLoaded"
              />
            </div>

            <!-- 跳动的头像 jump avatar-->
            <div :class="`level${levelNum}`">
              <div
                class="image-item"
                :class="`chapter-${
                  chapterNum > 4 ? chapterNum - 4 : chapterNum
                }`"
                v-for="(item, index) in 8"
                :key="index + 'img'"
              >
                <div
                  :class="`step steps-${index + 1}`"
                  v-if="index + 1 === lesson"
                >
                  <div class="img-one">
                    <img
                      src="@/assets/img/09-Level-map/bubble-lesson.svg"
                      alt=""
                    />
                  </div>
                  <div class="img-two">
                    <img
                      src="@/assets/img/12-Vocabulary/lesson-01-wo.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import { getKidsCourseDataByEnumId } from '@/assets/js/axios/coursewareEditor.js'

export default {
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          chapterId: null,
          lessonId: null,
          isScroll: null,
          position: null,
          modelType: null,
          bgImg: '',
          bgImgEnd: ''
        }
      }
    }
  },
  data() {
    return {
      imageStep: 1,
      maskShow: false,
      distance: 0,

      socketEvent: {},

      levelNum: 1,
      chapterNum: 2,
      lesson: 2,
      position: 0,
      firstImg: '',
      lastImg: '',
      starWidth: 0,

      localData: {
        levelNum: 1,
        chapterNum: 1,
        lesson: 1,

        position: 1,
        firstImg:
          'https://static.nihaocafe.com/image/kids/fa08ad8a-7232-440a-bfdc-ba208452ecef.svg',
        lastImg:
          'https://static.nihaocafe.com/image/kids/3f8fb16e-2fcc-4eba-bf45-ca0f1e800d43.svg'
      }
    }
  },
  watch: {
    socketEvent: {
      handler(value) {
        const data = {
          clickType: 20016,
          data: { value },
          text: '测试rankMapPage组件内scroll事件socket'
        }
        this.$bus.$emit('kids_webSocket_sendInfo', data)
      },
      deep: true
    },
    componentProps: {
      handler() {
        this.initData()
      },
      deep: true
    }
  },
  mounted() {
    this.initData()

    this.$nextTick(() => {
      this.$refs.pictureBox.addEventListener(
        'mousewheel',
        this.handleMouseScroll
      )
    })

    this.$bus.$on('scrollMouse', (e) => {
      this.handleMouseScroll(e, true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('scrollMouse')
    clearInterval(this.timer)
    clearInterval(this.masker)
    clearInterval(this.scrollTimer)
  },
  computed: {
    chapterString() {
      return String(this.chapterNum)
    }
  },
  methods: {
    /**
     * @description: 数据初始化
     * @return {*}
     */
    async initData() {
      clearTimeout(this.timer)
      clearTimeout(this.masker)
      clearInterval(this.scrollTimer)
      this.imageStep = 1
      this.maskShow = false

      // console.log(this.componentProps, '00000')
      const { isTemplatePrview } = this.componentProps
      if (isTemplatePrview) {
        for (const key in this.localData) {
          this[key] = this.localData[key]
        }
        return
      }
      const { bgImg, bgImgEnd, modelType, lessonId, isScroll } =
        this.componentProps
      // const id = this.$route.params.lessonId || lessonId

      console.log(this.componentProps)

      const id = lessonId || this.$route.params.lessonId
      if (id) {
        const res = await getKidsCourseDataByEnumId({
          enumId: 292,
          id
        })
        if (res.code === 200) {
          this.lesson = res.data[0].value % 8 === 0 ? 8 : res.data[0].value % 8
          this.chapterNum =
            res.data[0].value / 8 > 8
              ? Math.ceil(Math.ceil(res.data[0].value / 8) % 8)
              : Math.ceil(res.data[0].value / 8)
          this.levelNum = Math.ceil(res.data[0].value / 64)
          if ([2].indexOf(this.chapterNum) > -1) {
            this.position = [1, 2, 7, 8].indexOf(this.lesson) > -1 ? 0 : 1
          } else if ([1, 3, 4].indexOf(this.chapterNum) > -1) {
            this.position = [1, 2, 3, 4, 5].indexOf(this.lesson) > -1 ? 0 : 1
          }
        }
      }
      // this.position = position

      // console.log(typeof this.chapterNum);

      // console.log(
      //   this.levelNum,
      //   this.chapterNum,
      //   this.chapterString,
      //   this.lesson,

      //   bgImg,
      //   bgImgEnd,
      //   isScroll,
      //   position
      // )

      this.firstImg = bgImg
      this.lastImg = bgImgEnd

      if (isScroll) {
        this.scrollTimer = setTimeout(() => {
          this.$refs.pictureBox.scrollTo(this.$refs.pictureBox.scrollWidth, 0)
        }, 800)
      }
      console.log(this.imageStep)

      this.timer = setTimeout(() => {
        this.imageStep++
      }, 1500)

      this.masker = setTimeout(() => {
        this.maskShow = true
      }, 2100)
    },

    handleImageLoaded() {
      setTimeout(() => {
        console.log(
          this.$refs,
          this.$refs.imgContent.offsetWidth,
          this.$refs.imgContent.width,
          this.$refs.imgContent.clientWidth
        )
        this.starWidth = this.$refs.imgContent.offsetWidth
      }, 1500)
    },

    // 滚动实现
    handleMouseScroll(e, isFromSocket = false) {
      if (!isFromSocket) {
        this.socketEvent = { deltaY: e.deltaY, socketIndex: Math.random() }
      }
      const picWidth = this.$refs.pictureBox.getBoundingClientRect().width
      const speed = e.deltaY > 0 ? 40 : -40
      let dis
      this.distance += speed
      if (this.distance < 0) {
        dis = 0
        this.distance = 0
      } else if (this.distance > picWidth) {
        dis = picWidth
        this.distance = picWidth
      } else {
        dis = this.distance
      }
      this.$refs.pictureBox.scrollLeft = dis
    }
  }
}
</script>
<style scoped lang="scss">
.rank-map-page-container {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 56px;
  // z-index: 100;
  .mask-book {
    width: 100%;
    height: 100%;
    border-radius: 56px;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    .mask-book-content {
      width: 20%;
      height: 30%;
      background-color: #f4d737;
      border-bottom: 10px solid #d1b726;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      padding: 5%;
      position: relative;
      .font28 {
        color: #224e96;
        font-weight: 700;
        margin-bottom: 15%;
      }
      img {
        width: 70%;
      }
      .confetti-img {
        width: 80%;
        position: absolute;
        top: 0;
      }
    }
  }
  .lesson_content {
    width: 100%;
    height: 100%;
    position: relative;
    .customlesson-breakthrough-part {
      position: absolute;
      top: 0%;
      right: 5%;
      height: 40px;
      display: flex;
      padding: 10px 10px;
      background-color: #f4d737;
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
      border-bottom: 6px solid #d1b726;
      z-index: 2;
      .demo {
        display: flex;
        .customlesson-breakthrough-part-book {
          margin-left: 5px;
          img {
            width: 25px;
            height: 35px;
          }
        }
      }
    }
    .customlesson-breakthrough-part-movement {
      height: 100%;
      border-radius: 56px;
      background-color: #f7f4e0;

      .picture {
        height: 100%;
        position: relative;
        overflow: auto;
        scroll-behavior: smooth;

        .image-item {
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          img {
            height: 85%;
            position: absolute;
            bottom: 0;
          }
          .step {
            position: absolute;
            top: 25vh;
            left: 32vh;
            z-index: 5;
          }

          .img-one {
            // width: 100px;
            width: 100px;
            height: 100px;
            z-index: 6;
            img {
              width: 100%;
            }
          }
          .img-two {
            position: absolute;
            top: 5%;
            left: 19%;
            height: 70px;
            img {
              width: 60px;
              height: 60px;
            }
          }
        }

        .chapter-1 {
          .steps-1 {
            top: 22vh;
            left: 34vh;
          }
          .steps-2 {
            top: 3vh;
            left: 44vh;
          }
          .steps-3 {
            top: 12vh;
            left: 85vh;
          }
          .steps-4 {
            top: 37vh;
            left: 62vh;
          }
          .steps-5 {
            top: 49vh;
            left: 41vh;
          }
          .steps-6 {
            top: 48vh;
            left: 100vh;
          }
          .steps-7 {
            top: 33vh;
            left: 112vh;
          }
          .steps-8 {
            top: 6vh;
            left: 113vh;
          }
        }
        .chapter-2 {
          .steps-1 {
            top: 39vh;
            left: 42vh;
          }
          .steps-2 {
            top: 56vh;
            left: 80vh;
          }
          .steps-3 {
            top: 37vh;
            left: 123vh;
          }
          .steps-4 {
            top: 58vh;
            left: 152vh;
          }
          .steps-5 {
            top: 2vh;
            left: 153vh;
          }
          .steps-6 {
            top: 8vh;
            left: 101vh;
          }
          .steps-7 {
            top: 27vh;
            left: 76vh;
          }
          .steps-8 {
            top: 3vh;
            left: 42vh;
          }
        }
        .chapter-3 {
          .steps-1 {
            top: 22vh;
            left: 42vh;
          }
          .steps-2 {
            top: 12vh;
            left: 78vh;
          }
          .steps-3 {
            top: 36vh;
            left: 78vh;
          }
          .steps-4 {
            top: 49vh;
            left: 43vh;
          }
          .steps-5 {
            top: 60vh;
            left: 96vh;
          }
          .steps-6 {
            top: 52vh;
            left: 112vh;
          }
          .steps-7 {
            top: 34vh;
            left: 121vh;
          }
          .steps-8 {
            top: 9vh;
            left: 139vh;
          }
        }
        .chapter-4 {
          .steps-1 {
            top: 22vh;
            left: 42vh;
          }
          .steps-2 {
            top: 58vh;
            left: 50vh;
          }
          .steps-3 {
            top: 27vh;
            left: 85vh;
          }
          .steps-4 {
            top: 14vh;
            left: 72vh;
          }
          .steps-5 {
            top: 11vh;
            left: 113vh;
          }
          .steps-6 {
            top: 27vh;
            left: 140vh;
          }
          .steps-7 {
            top: 36vh;
            left: 102vh;
          }
          .steps-8 {
            top: 59vh;
            left: 109vh;
          }
        }
        .chapter-5 {
          .steps-1 {
            top: 9vh;
            left: 42vh;
          }
          .steps-2 {
            top: 32vh;
            left: 48vh;
          }
          .steps-3 {
            top: 53vh;
            left: 50vh;
          }
          .steps-4 {
            top: 55vh;
            left: 107vh;
          }
          .steps-5 {
            top: 48vh;
            left: 137vh;
          }
          .steps-6 {
            top: 34vh;
            left: 154vh;
          }
          .steps-7 {
            top: 10vh;
            left: 131vh;
          }
          .steps-8 {
            top: 26vh;
            left: 86vh;
          }
        }
        .chapter-6 {
          .steps-1 {
            top: 13vh;
            left: 34vh;
          }
          .steps-2 {
            top: 38vh;
            left: 48vh;
          }
          .steps-3 {
            top: 18vh;
            left: 86vh;
          }
          .steps-4 {
            top: 33vh;
            left: 107vh;
          }
          .steps-5 {
            top: 55vh;
            left: 68vh;
          }
          .steps-6 {
            top: 60vh;
            left: 146vh;
          }
          .steps-7 {
            top: 31vh;
            left: 160vh;
          }
          .steps-8 {
            top: 13vh;
            left: 162vh;
          }
        }
        .chapter-7 {
          .steps-1 {
            top: 11vh;
            left: 39vh;
          }
          .steps-2 {
            top: 24vh;
            left: 67vh;
          }
          .steps-3 {
            top: 55vh;
            left: 40vh;
          }
          .steps-4 {
            top: 51vh;
            left: 74vh;
          }
          .steps-5 {
            top: 50vh;
            left: 123vh;
          }
          .steps-6 {
            top: 60vh;
            left: 146vh;
          }
          .steps-7 {
            top: 21vh;
            left: 150vh;
          }
          .steps-8 {
            top: 13vh;
            left: 95vh;
          }
        }
        .chapter-8 {
          .steps-1 {
            top: 14vh;
            left: 40vh;
          }
          .steps-2 {
            top: 26vh;
            left: 55vh;
          }
          .steps-3 {
            top: 57vh;
            left: 42vh;
          }
          .steps-4 {
            top: 47vh;
            left: 71vh;
          }
          .steps-5 {
            top: 17vh;
            left: 78vh;
          }
          .steps-6 {
            top: 9vh;
            left: 120vh;
          }
          .steps-7 {
            top: 32vh;
            left: 127vh;
          }
          .steps-8 {
            top: 46vh;
            left: 138vh;
          }
        }
        .picture-item {
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          .background-image {
            height: 95%;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }
      ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        display: none;
      }
    }
  }
}
.star-area {
  position: absolute;
  // width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.animatedstar {
  position: absolute;
  width: 3%;
  z-index: 6;
  -webkit-animation: spin 0.8s linear infinite;
  -moz-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
  animation-direction: reverse;
  -webkit-animation-iteration-count: 1;
}
.lesson1 {
  .chapterStar-1 {
    top: 52.5% !important;
    left: 19% !important;
  }
  .chapterStar-2 {
    top: 74% !important;
    left: 31% !important;
  }
  .chapterStar-3 {
    top: 40.7% !important;
    left: 43.5% !important;
  }
  .chapterStar-4 {
    top: 48% !important;
    left: 31% !important;
  }
}

.lesson2 {
  .chapterStar-1 {
    top: 28.5% !important;
    left: 25.5% !important;
  }
  .chapterStar-2 {
    top: 87% !important;
    left: 46% !important;
  }
  .chapterStar-3 {
    top: 29% !important;
    left: 63% !important;
  }
  .chapterStar-4 {
    top: 86% !important;
    left: 48% !important;
  }
}

.lesson3 {
  .chapterStar-1 {
    top: 38.5% !important;
    left: 39.8% !important;
  }
  .chapterStar-2 {
    top: 64% !important;
    left: 55% !important;
  }
  .chapterStar-3 {
    top: 57.5% !important;
    left: 62.8% !important;
  }
  .chapterStar-4 {
    top: 48% !important;
    left: 43% !important;
  }
}

.lesson4 {
  .chapterStar-1 {
    top: 55% !important;
    left: 46% !important;
  }
  .chapterStar-2 {
    top: 90.5% !important;
    left: 59.5% !important;
  }
  .chapterStar-3 {
    top: 72% !important;
    left: 16% !important;
  }
  .chapterStar-4 {
    top: 33.1% !important;
    left: 53.2% !important;
  }
}
.lesson5 {
  .chapterStar-1 {
    top: 76% !important;
    left: 24% !important;
  }
  .chapterStar-2 {
    top: 20% !important;
    left: 73% !important;
  }
  .chapterStar-3 {
    top: 85.5% !important;
    left: 68.5% !important;
  }
  .chapterStar-4 {
    top: 30.2% !important;
    left: 79.8% !important;
  }
}
.lesson6 {
  .chapterStar-1 {
    top: 82% !important;
    left: 83% !important;
  }
  .chapterStar-2 {
    top: 30% !important;
    left: 49% !important;
  }
  .chapterStar-3 {
    top: 75.7% !important;
    left: 93.5% !important;
  }
  .chapterStar-4 {
    top: 48.5% !important;
    left: 79% !important;
  }
}
.lesson7 {
  .chapterStar-1 {
    top: 51.5% !important;
    left: 68% !important;
  }
  .chapterStar-2 {
    top: 56.5% !important;
    left: 51% !important;
  }
  .chapterStar-3 {
    top: 54% !important;
    left: 68% !important;
  }
  .chapterStar-4 {
    top: 60% !important;
    left: 53% !important;
  }
}
.lesson8 {
  .chapterStar-1 {
    top: 35% !important;
    left: 72% !important;
  }
  .chapterStar-2 {
    top: 30% !important;
    left: 30% !important;
  }
  .chapterStar-3 {
    top: 38% !important;
    left: 76% !important;
  }
  .chapterStar-4 {
    top: 86% !important;
    left: 53% !important;
  }
}

//  fade in animation for transition
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadein {
  color: white;
  -webkit-animation: fadein 0.5s ease-in forwards;
  -moz-animation: fadein 0.5s ease-in forwards;
  animation: fadein 0.5s ease-in forwards;
}

@keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);
    -moz-transform: scale(1) rotate(0deg);
    position: absolute;
  }
  10% {
  }
  20% {
    -moz-transform: scale(3.5) rotate(90deg);
    transform: scale(1.5) rotate(60deg);
    position: absolute;
  }
  50% {
  }
  80% {
    opacity: 0.8;
    position: absolute;
  }
  100% {
    -moz-transform: scale(6.5) rotate(360deg);
    transform: scale(6.5) rotate(360deg);

    position: absolute;
    opacity: 0;
  }
}

.glow {
  animation: glow 1s;
}
@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px #2472b3;
  }
  to {
    box-shadow: 0 0 10px 10px #2472b3;
  }
}
</style>
