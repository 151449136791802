<template>
  <div class="game-recognition-card-container">
    <div class="red-background-border"></div>
    <div class="game-card-content">
      <audio :src="songUrl" ref="audioBox"></audio>

      <div class="recogniton-card-content">
        <div class="recogniton-card-left">
          <img class="santa-claus" :src="backgroundImage" alt="" />
        </div>
        <div class="recogniton-card-right">
          <div class="recogniton_card_right_border">
            <div class="recognize_container_content_right_content">
              <div
                class="recognize_container_content_right_content_top volume-button"
                :class="{ 'teacher-hover': isClickAudio }"
              >
                <div @click.stop="activeClick('socket')">
                  <AudioPlayer :title="wordObj.chineseName" :isPlay="isStartPlaying" :audioUrl="songUrl" />
                </div>
              </div>
              <div class="redColor" v-if="wordObj.pinName != ''">
                <p class="pinyin font-pinyin-xlarge">
                  {{ wordObj.pinName }}
                </p>
                <p id="face" class="font-hanzi-xlarge">
                  {{ wordObj.chineseName }}
                </p>
              </div>
              <div v-if="wordObj.pinName != ''" class="englishName">
                <p class="'font-hanzi-medium'">
                  {{ wordObj.englishName }}
                </p>
              </div>
              <div v-if="wordObj.pinName == ''" class="englishName">
                <p class="font-hanzi-xlarge">
                  {{ wordObj.englishName }}
                </p>
              </div>
              <!-- <div
                v-if="wordObj.toneContent && wordObj.toneContent.length > 0"
                class="tone_content"
              >
                <p
                  class="pinyin font-hanzi-large"
                  v-for="(item, index) in wordObj.toneContent"
                  :key="index"
                >
                  {{ item }}
                </p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudioPlayer from '@/components/Course/GamePage/AudioPlayer'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    AudioPlayer
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          img: '',
          audio: '',
          pinyin: '',
          hanzi: '',
          english: ''
        }
      }
    }
  },
  data() {
    return {
      showImg: false,
      changeBgImg: '',
      audioSocketInfo: {},
      isClickAudio: false,
      isStartPlaying: null,
      songUrl: '',
      wordObj: {

      },
      backgroundImage: require("@/assets/img/12-Vocabulary/lesson-09-baba.svg"),
      localData: {
        wordObj: {
        pinName: "bàba",
        chineseName: "爸爸",
        englishName: "father",
        songUrl: '',
        }
      }
    }
  },
  mounted() {
    this.$bus.$on('clickRecognitionPage', () => {
      this.activeClick('socket', true)
    })
    this.$bus.$on('hoverRecognitionPage', (val) => {
      this.isClickAudio = !this.isClickAudio
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickRecognitionPage')
  },
  watch: {
    // 发送
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20002,
          data: { value },
          text: '测试RecognitionPage组件内点击事件socket'
        }
        this.$bus.$emit('kids_webSocket_sendInfo', data)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    },

  },
  methods: {
    initData(){
      this.wordObj = {
        pinName: this.componentProps.pinyin,
        chineseName: this.componentProps.hanzi,
        englishName: this.componentProps.english,
        songUrl: this.componentProps.audio,
      }
      this.backgroundImage = this.componentProps.img
    },
    activeClick(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.audioSocketInfo = {
          value: Math.random()
        }
      }
      this.isStartPlaying = Math.random()
      this.songUrl = this.wordObj.songUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.game-recognition-card-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  .game-card-content {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background: #fff;
    border-radius: 58px;
    z-index: 2;
    position: relative;
    .recogniton-card-content {
      width: 100%;
      height: 100%;
      background: #f7f4e0;
      border-radius: 58px;
      position: relative;
      z-index: 3;
      .recogniton-card-left {
        width: 50%;
        height: 100%;
        position: absolute;
        img {
          position: absolute;
          right: 0;
          top: 20%;
          width: 80%;
        }
        .santa-claus {
          top: 15%;
        }
      }
      .recogniton-card-right {
        position: absolute;
        width: 50%;
        height: 100%;
        right: 0;
        .recogniton_card_right_border {
          margin-top: 10px;
          margin-left: 10px;
          width: calc(70% - 10px);
          height: calc(60% - 10px);
          position: absolute;
          left: 8%;
          top: 13%;
          background: #224e96;
          border-radius: 40px;
          padding: 20px 10px;
          .recognize_container_content_right_content {
            color: #000;
            text-align: center;
            position: absolute;
            width: 100%;
            height: 100%;
            background: #fff;
            border-radius: 40px;
            top: -10px;
            left: -10px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            cursor: default;

            .englishName {
              max-width: 70%;
            }
            .longPinyin {
              p {
                font-size: 60px !important;
              }
            }
            .font-hanzi-large {
              font-size: 2.5em;
              margin-bottom: 30px;
            }
            .recognize_container_content_right_content_top {
              width: 50px;
              height: 50px;
              background: #ce4c3f;
              border-radius: 50%;

              &.volume-button {
                cursor: pointer;
              }
              div {
                width: 100%;
                height: 100%;
              }
            }
            .recognize_container_content_right_content_top:hover {
              width: 50px;
              height: 50px;
              background: #b5392c;
              border-radius: 50%;
              img {
                border-radius: 50%;
                background: #b5392c;
                width: 100%;
              }
            }
            .tone_content {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              width: 50%;
              p {
                width: 50%;
                margin-bottom: 8px;
              }
            }
          }
        }
      }
    }
  }
  .redColor {
    color: #ce524c;
  }
  .teacher-hover {
    img {
      background: #b5392c !important;
      border-radius: 50%;
    }
  }
}
</style>
