var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "words-card-container" }, [
    _c(
      "div",
      { staticClass: "words-card-page-box" },
      [
        _c("SceneSwitchThumbnails", {
          attrs: { buttons: _vm.buttonList, numBers: _vm.currentIndex },
          on: { changeTheNumbers: _vm.changeTheNumbers },
        }),
        _c(
          "div",
          {
            staticClass: "word-cards-content",
            class: "lesson-" + _vm.lessonNum,
          },
          [
            _c("div", { staticClass: "title-area" }, [
              _c("div", { staticClass: "title" }, [
                _c(
                  "div",
                  { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                  [_vm._v(" " + _vm._s(_vm.titlePy) + " ")]
                ),
                _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
                  _vm._v(" " + _vm._s(_vm.titleZh) + " "),
                ]),
              ]),
            ]),
            _vm._l(_vm.wordCardList[_vm.currentIndex], function (item, index) {
              return _c(
                "div",
                {
                  key: index + "cards",
                  staticClass: "word-card-item",
                  class: "lesson-" + _vm.lessonNum,
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "contray-content",
                      class: {
                        blueColor: item.Showing,
                        redColor: !item.Showing,
                        "card-1": index === 0,
                        "card-2": index === 1,
                        "card-3": index === 2,
                        "card-4": index === 3,
                        "card-5": index === 4,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.clickImage(index)
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: item.Showing ? item.imgShow : item.image,
                          alt: "",
                        },
                      }),
                    ]
                  ),
                  false
                    ? _c(
                        "div",
                        { staticClass: "words-box" },
                        _vm._l(
                          _vm.wordCardList[_vm.currentIndex],
                          function (item, index) {
                            return _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showHnaziIndex === index,
                                    expression: "showHnaziIndex === index",
                                  },
                                ],
                                key: index,
                                staticClass: "hanzi-item",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pinyin sentence-hanzi font-pinyin-medium",
                                  },
                                  [_vm._v(" " + _vm._s(item.pinyin) + " ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "sentence-hanzi font-hanzi-medium",
                                  },
                                  [_vm._v(" " + _vm._s(item.hanzi) + " ")]
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ]
              )
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }