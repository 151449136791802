<template>
    <div class="title-area"
    :class="{
      redColor:titleInfo.bgColor==='redColor',
      blueColor:titleInfo.bgColor==='blueColor',
      }"
    >
          <div class="title">
            <div class="title-pinyin pinyin font-pinyin-medium">
              {{ titleInfo.pinyin }}
            </div>
            <div class="title-hanzi font-hanzi-medium">
              {{ titleInfo.hanzi }}
            </div>
          </div>
    </div>
</template>
<script>
export default {
  name: "Title",
  props: {
    titleInfo: {
      type: Object,
      default: () => {},
    },
  },

};
</script>
<style lang="scss" scoped>

  .title-area {
    z-index: 9;
    position: absolute;
    left: 0;
    top: 10%;
    width: auto;
    height: auto;
    padding:20px;
    background: #214e96;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 22px;
    .title {
    color: #fff;
      .title-pinyin,
      .title-hanzi {
        text-align: center;
      }
    }
  }
  .redColor{
    background: #ce4c3f;
  }
  .blueColor{
    background: #214e96;
  }
</style>
