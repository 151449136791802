<template>
  <div class="drag-correct-conteiner">
    <div class="drag-correct-content">
      <Star :totalStarNumber="starNum" :currentIndex="steps" />
      <div class="drag-background">
        <div class="drag-correct-pic-game-content">
          <div class="game-top-box">
            <div class="game-aside-content" :class="'type-' + imgNo">
              <div
                v-for="(item, index) in optionsList"
                :key="index"
                class="item-content"
                :class="{
                  yellowBg: item.done,
                  shake: isClicked && index === dropIndex - 1
                }"
                draggable="true"
                @drop.stop="drop(item)"
                @dragover.stop="allowDrop($event)"
              >
                <img
                  v-if="item.done && item.bgImg"
                  :src="item.bgImg"
                  class="bg-img"
                />
                <div class="btn-area" v-if="!item.done && item.pinyin">
                  <div class="font-pinyin-medium pinyin">
                    {{ item.pinyin }}
                  </div>
                  <div class="font-hanzi-medium">{{ item.hanzi }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="answer-area">
          <div class="redBackground"></div>
          <div class="bottonsWrapper">
            <div
              v-for="(item, index) in answerList.slice(0, imgNo)"
              :key="index"
              class="content-words-two"
              draggable="true"
              @dragstart.stop="drag(item)"
              @dragend.stop="handleDropBg"
              v-show="answerList.length"
            >
              <img v-if="item.image" :src="item.image" alt="" />
            </div>
            <div class="content-words-two" v-if="!answerList.length">
              <img :src="fakerImg" alt="" :style="{ opacity: 0 }" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Star from '@/components/Course/GamePage/Star'
import { templatePreviewMixin } from './templatePreviewMixin.js'
import { startConfetti, playCorrectSound } from '@/utils/tools'
export default {
  mixins: [templatePreviewMixin],
  components: {
    Star
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          dragPicList: []
        }
      }
    }
  },
  mounted() {
    // this.initData()
    // this.fakerImg = this.answerList[0].image
    this.$bus.$on('dragCorrectPicGame', ({ item }) => {
      this.drag(item, true)
    })
    this.$bus.$on('dropCorrectPicGame', ({ item }) => {
      this.drop(item, true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('dragCorrectPicGame')
    this.$bus.$off('dropCorrectPicGame')
  },
  watch: {
    dragSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003701,
          data: { value },
          text: 'DragCorrectPicGame drag socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    dropSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003702,
          data: { value },
          text: 'dragCorrectPicGame drop socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  data() {
    return {
      localData: {
        fakerImg: require('@/assets/img/16-GAMES/G49-10-numbers/lesson-48/1-nihao.svg'),
        originList: [
          {
            id: 3,
            index: 1,
            image: require('@/assets/img/16-GAMES/G49-10-numbers/lesson-48/1-nihao.svg')
          },
          {
            index: 2,
            id: 1,
            image: require('@/assets/img/16-GAMES/G49-10-numbers/lesson-48/2-xiexie.svg')
          },
          {
            id: 2,
            index: 3,
            image: require('@/assets/img/16-GAMES/G49-10-numbers/lesson-48/3-zaijian.svg')
          },
          {
            id: 3,
            index: 4,
            image: require('@/assets/img/16-GAMES/G49-10-numbers/lesson-48/4-nihao.svg')
          },
          {
            id: 1,
            index: 5,
            image: require('@/assets/img/16-GAMES/G49-10-numbers/lesson-48/5-xiexie.svg')
          },
          {
            id: 2,
            index: 6,
            image: require('@/assets/img/16-GAMES/G49-10-numbers/lesson-48/6-zaijian.svg')
          },
          {
            id: 1,
            index: 7,
            image: require('@/assets/img/16-GAMES/G49-10-numbers/lesson-48/7-xiexie.svg')
          }
        ],
        answerList: [
          {
            id: 3,
            index: 1,
            image: require('@/assets/img/16-GAMES/G49-10-numbers/lesson-48/1-nihao.svg')
          },
          {
            index: 2,
            id: 1,
            image: require('@/assets/img/16-GAMES/G49-10-numbers/lesson-48/2-xiexie.svg')
          },
          {
            id: 2,
            index: 3,
            image: require('@/assets/img/16-GAMES/G49-10-numbers/lesson-48/3-zaijian.svg')
          },
          {
            id: 3,
            index: 4,
            image: require('@/assets/img/16-GAMES/G49-10-numbers/lesson-48/4-nihao.svg')
          },
          {
            id: 1,
            index: 5,
            image: require('@/assets/img/16-GAMES/G49-10-numbers/lesson-48/5-xiexie.svg')
          },
          {
            id: 2,
            index: 6,
            image: require('@/assets/img/16-GAMES/G49-10-numbers/lesson-48/6-zaijian.svg')
          },
          {
            id: 1,
            index: 7,
            image: require('@/assets/img/16-GAMES/G49-10-numbers/lesson-48/7-xiexie.svg')
          }
        ],
        optionsList: [
          {
            id: 1,
            index: 1,
            done: false,
            // bgImg: require('@/assets/img/16-GAMES/G49-10-numbers/lesson-48/button-xiexie.svg')
            pinyin: 'xiexie',
            hanzi: '谢谢'
          },
          {
            id: 2,
            index: 2,
            done: false,
            // bgImg: require('@/assets/img/16-GAMES/G49-10-numbers/lesson-48/button-zaijian.svg')
            pinyin: 'zaijian',
            hanzi: '再见'
          },
          {
            id: 3,
            index: 3,
            done: false,
            // bgImg: require('@/assets/img/16-GAMES/G49-10-numbers/lesson-48/button-nihao.svg')
            pinyin: 'niahao',
            hanzi: '你好'
          },
          {
            id: 1,
            index: 4,
            done: false,
            // bgImg: require('@/assets/img/16-GAMES/G49-10-numbers/lesson-48/button-xiexie.svg')
            pinyin: 'xiexie',
            hanzi: '谢谢'
          },
          {
            id: 3,
            index: 5,
            done: false,
            // bgImg: require('@/assets/img/16-GAMES/G49-10-numbers/lesson-48/button-nihao.svg')
            pinyin: 'nihao',
            hanzi: '你好'
          },
          {
            id: 2,
            index: 6,
            done: false,
            // bgImg: require('@/assets/img/16-GAMES/G49-10-numbers/lesson-48/button-zaijian.svg')
            pinyin: 'zaijian',
            hanzi: '再见'
          },
          {
            id: 1,
            index: 7,
            done: false,
            // bgImg: require('@/assets/img/16-GAMES/G49-10-numbers/lesson-48/button-xiexie.svg')
            pinyin: 'xiexie',
            hanzi: '谢谢'
          }
        ],
        starNum: 7,

        imgNo: 3,
        steps: 0
        // originList: JSON.parse(JSON.stringify(this.localData.answerList))
      },
      answerList: [],
      optionsList: [],
      imgNo: null,
      starNum: null,
      steps: 0,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'speak',
            startNumberRange: 0,
            endNumberRange: 100
          }
        ]
      },
      isLastStep: false,
      dropIndex: null,
      isClicked: false,

      dragSocketInfo: {},
      dropSocketInfo: {},
      currentKey: 0,
      currentIndex: 0,
      fakerImg: null,
      curIndex: null,
      originList: []
    }
  },
  methods: {
    initData() {
      // console.log(this.componentProps, '---------')
      this.answerList = []
      this.optionsList = []
      this.imgNo = 3
      if (
        this.componentProps.dragPicList &&
        this.componentProps.dragPicList.length
      ) {
        const listlength = this.componentProps.dragPicList.length
        const list = Array.from({ length: listlength }, (v, k) => k + 1)
        const shuffledIndices = list
        const hanziList = this.componentProps.dragPicList.map((item, index) => {
          return {
            id: index + 1,
            value: item[1].value
          }
        })
        // console.log(hanziList, 'hanziList')
        const seenValues = new Set()
        const uniqueData = hanziList.filter((item) => {
          if (!seenValues.has(item.value)) {
            seenValues.add(item.value)
            return true
          }
          return false
        })

        this.componentProps.dragPicList.forEach((item, index) => {
          this.answerList.push({
            id:  uniqueData && uniqueData.length
                ? uniqueData.find((i) => i.value === item[1].value).id
                : index + 1,
            image: item[2].value,
            index: shuffledIndices[index]
          })
          this.optionsList.push({
            id: uniqueData && uniqueData.length
                ? uniqueData.find((i) => i.value === item[1].value).id
                : index + 1,
            pinyin: item[0].value,
            hanzi: item[1].value,
            done: false,
            index: index + 1
          })
        })
        this.starNum = this.optionsList.length
        this.answerList = this.answerList.sort((a, b) => a.index - b.index)
        // console.log(this.answerList, this.optionsList, '0000')
        this.originList = JSON.parse(JSON.stringify(this.answerList))
      }
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
      }
      return array
    },
    foundAnswer(item) {
      // console.log(item.id)
      this.dropIndex = item.index
      // console.log(this.dropIndex)
      if (item.id === this.currentKey) {
        this.answersfound++
        this.steps++
        playCorrectSound(true, false)
        // this.$emit('changeAnswerListStatus', this.currentIndex)
        this.answerList.forEach((item, curIndex) => {
          if (item.index === this.currentIndex) {
            this.curIndex = curIndex
          }
        })
        // console.log(index, this.curIndex);
        this.answerList.splice(this.curIndex, 1)
        this.optionsList.forEach((ele) => {
          if (ele.index === item.index) {
            ele.done = true
            ele.bgImg = this.originList[this.currentIndex - 1].image
          }
        })
        if (this.steps === this.optionsList.length) {
          this.isLastStep = true
          playCorrectSound()
          startConfetti()
        }
      } else {
        playCorrectSound(false)
        this.isClicked = true
        setTimeout(() => {
          this.isClicked = false
          this.currentKey = 0
        }, 1000)
      }
    },
    drag(item, isFromSocket = false) {
      this.currentKey = item.id
      this.currentIndex = item.index
      if (!isFromSocket) {
        this.dragSocketInfo = {
          item,
          value: Math.random()
        }
      }
    },
    drop(item, isFromSocket = false) {
      if (!isFromSocket) {
        this.dropSocketInfo = {
          item,
          value: Math.random()
        }
      }
      this.foundAnswer(item)
    },
    handleDropBg() {
      this.currentKey = 0
    },
    allowDrop(event) {
      event.preventDefault()
    }
  }
}
</script>

<style lang="scss" scoped>
.drag-correct-conteiner {
  width: 100%;
  height: 100%;
  .drag-correct-content {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 48px;
    position: relative;
    overflow: hidden;
    .drag-background {
      text-align: -webkit-center;
      position: absolute;
      height: 100%;
      width: 100%;
      background: #f9f6e6;
      border-radius: 48px;
      margin: auto;
      overflow: hidden;
      .bgIamge {
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      .drag-correct-pic-game-content {
        position: relative;
        width: -moz-fit-content;
        width: 100%;
        height: 80%;
        justify-content: center;
        z-index: 0;
        border-radius: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .game-top-box {
          height: 80%;
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: center;
          .game-aside-content {
            width: 90%;
            height: 70%;
            display: flex;
            flex-wrap: wrap;
            border-top-right-radius: 30px;
            border-top-left-radius: 30px;
            justify-content: space-around;
            align-items: flex-end;
          }
          .yellowBg {
            background-color: #f4d737;
            border-radius: 20px;
            pointer-events: none;
          }

          .item-content {
            box-sizing: border-box;
            width: 18%;
            height: 40%;
            z-index: 1;
            cursor: pointer;
            color: #fff;
            .bg-img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
            .btn-area {
              width: 80%;
              height: 64%;
              border-radius: 15px;
              border-bottom: 8px solid #ac3d32;
              background-color: #cd4c3f;
              padding: 5px 10px;
            }
          }
        }
      }
      .answer-area {
        bottom: 0;
        width: 65%;
        height: 170px;
        position: absolute;
        display: flex;
        justify-content: center;
        // left: 25%;
        right: 15%;
        padding: 10px;
        .redBackground {
          width: 120%;
          height: 50%;
          border-top-right-radius: 30px;
          border-top-left-radius: 30px;
          background-color: #d87065;
          position: absolute;
          bottom: 0;
        }
        .bottonsWrapper {
          display: flex;
          bottom: 0;
          height: -moz-fit-content;
          justify-content: space-between;
          // height: 100%;
          width: 100%;
          position: absolute;
          padding: 20px;
          border-top-right-radius: 30px;
          border-top-left-radius: 30px;
          background-color: #ffffff;
          justify-content: space-evenly;
          align-items: center;
          overflow: hidden;
        }
      }
      .content-words-two {
        max-width: 16%;
        min-width: 21%;
        min-height: 100px;
        z-index: 1;
        border-radius: 15px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        span {
          color: #fff;
          font-size: 25px;
        }
        span:nth-child(2) {
          color: #fff;
          font-size: 30px;
        }
        img {
          max-width: 100%;
          -webkit-user-drag: auto !important;
        }
      }
      .content-words-two:active {
        transform: scale(0.95);
      }
      .content-words-two:hover {
        opacity: 0.8;
        border-radius: 15px;
        border-bottom: 0px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        span {
          color: #fff;
          font-size: 25px;
        }
        span:nth-child(2) {
          color: #fff;
          font-size: 30px;
        }
      }
    }
  }
}
</style>
