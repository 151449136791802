<template>
  <div class="write-stroke-page-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
      @updateNumbers="updateNumbers($event)"
      @click.native="startDrawStroke"
    ></PageButton>
    <div class="stroke-page-box">
      <!-- writing button -->

      <!-- 眼睛按钮 eye-->
      <div
        v-if="isShowEye"
        class="write-stroke-page-eye-btn"
        @mouseover="eyeIsHover = true"
        @mouseleave="eyeIsHover = false"
        @click="handleClickEyeButton"
      >
        <img
          v-if="!eyeIsHover"
          src="@/assets/img/16-GAMES/G19-writing-strokes/button-eye-on.svg"
          alt=""
        />
        <img
          v-else
          src="@/assets/img/16-GAMES/G19-writing-strokes/button-eye-on-hover.svg"
          alt=""
        />
      </div>

      <div class="write-stroke-content">
        <transition name="el-fade-in">
          <div class="stroke-gif-area" v-if="isTipPage">
            <div class="write-stroke-content-left">
              <div class="write-stroke-left-content">
                <div class="left-content-border"></div>
                <div class="left-content">
                  <!-- default 默认左边背景 -->
                  <!-- 拼音 -->
                  <span class="pinyin font-pinyin-large">{{
                    flagImageObj.pinyin
                  }}</span>
                  <div class="stroke-area">
                    <div class="hanzi-img">
                      <img :src="flagImageObj.tipImg" alt="" />
                    </div>
                  </div>
                  <!-- <img
                    class="hand"
                    src="@/assets/img/17-Tutorials/hand.svg"
                    alt=""
                    @click="startDrawStroke"
                  /> -->
                </div>
              </div>
            </div>
          </div>
        </transition>
        <transition name="el-fade-in">
          <div class="write-stroke-area" v-if="!isTipPage">
            <!-- left 左边 -->
            <div class="write-stroke-content-left">
              <div class="write-stroke-left-content">
                <div class="left-content-border"></div>
                <div class="left-content">
                  <div class="left-content-top">
                    <img
                      v-for="(item, index) in 3"
                      :key="index + 'flag'"
                      :src="
                        steps <= index
                          ? flagImageObj.img
                          : flagImageObj.imgActive
                      "
                      alt=""
                    />
                  </div>
                  <span class="pinyin font-pinyin-large">{{
                    flagImageObj.pinyin
                  }}</span>

                  <!-- default 默认左边背景 -->
                  <div
                    class="stroke-area"
                    @mousedown="startDrawing()"
                    @mouseup="endDrawing($event)"
                  >
                    <div class="hanzi-img">
                      <KidsWhiteBoard ref="drawArea" />
                      <div
                        v-show="drawing"
                        class="WritingStrokeArea"
                        @click="draggedOutOfStroke"
                      ></div>
                      <div class="square">
                        <img
                          class="stroke-square-img"
                          src="@/assets/img/15-Hanzi-strokes/background.svg"
                          alt=""
                        />
                      </div>

                      <div
                        class="hengIcon"
                        :class="{ iconLarge: lesson == 12 || lesson == 14 }"
                      >
                        <img
                          v-if="!showStroke"
                          class="stroke-square-img"
                          :src="strokeImage"
                          alt=""
                          :class="{ shu: strokePinyin === 'shu' }"
                        />
                        <img
                          v-else
                          class="stroke-square-img"
                          :src="strokeImgFill"
                          alt=""
                          :class="{ shu: strokePinyin === 'shu' }"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- right 右边 -->
            <div class="write-stroke-content-right">
              <div class="write-stroke-content-right_img">
                <div
                  class="item"
                  v-for="(item, index) in backgroundImageList"
                  :key="index + 'bg'"
                >
                  <!-- <img :key="index" class="cloud bgImg" :src="item.img" :class="{ fadeOut: isFadeOut && index !== steps,fadeIn: isFadeIn && index === steps, isHidden: index > steps }" /> -->
                </div>
                <div class="steps-img-box">
                  <template v-for="(item, index) in backgroundImageList">
                      <img :key="index" class="cloud" :src="item.img" :class="{ fadeOut: isFadeOut && index !== steps,fadeIn: isFadeIn && index === steps, isHidden: index > steps }" />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import KidsWhiteBoard from "@/components/Course/WhiteBoard/WhiteBoard.vue"; // 白板组件
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";

export default {
  props: {
    strokeImage: {
      type: String,
      require: true,
    },
    strokeImgFill: {
      type: String,
      require: true,
    },
    backgroundImageList: {
      type: Array,
      require: true,
    },
    flagImageObj: {
      type: Object,
      require: true,
    },
    strokePinyin: {
      type: String,
      require: true,
    },
    lesson: {
      type: Number,
      require: false,
    },
  },
  data() {
    return {
      characterCheckpoints: [
        {
          id: 1,
          hitCheckpoint: false,
          finishedCheckpoint: false,
        },
        {
          id: 2,
          hitCheckpoint: false,
          finishedCheckpoint: false,
        },
        {
          id: 3,
          hitCheckpoint: false,
          finishedCheckpoint: false,
        },
      ],
      isTipPage: true,
      isShowEye: false,
      isDrawHover: false,
      isShowDrawButton: true,
      success_audio_sound: require("@/assets/audio/correct_answer.mp3"),
      eyeIsHover: false,
      isFinishWriting: false,
      isShowHand: false,
      drawing: false,
      steps: 0,
      showStroke: false,

      isLastStep: false,
      handleStepsValue: 0,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "draw",
            startNumberRange: 1,
            endNumberRange: 3,
          },
        ],
      },
      startDrawStrokeValue: false,
      isFadeOut: false,
      isFadeIn: false,
    };
  },
  components: {
    KidsWhiteBoard,
    PageButton,
  },
  mounted() {
    this.$bus.$on("startDrawStroke", (val) => {
      if (!val) {
        this.startDrawStroke(1);
      } else {
        this.handleClickEyeButton(1);
      }
      this.startDrawStrokeValue = true;
    });
    this.$bus.$on("drawSingleStroke", () => {
      this.handleSteps(1);
    });
  },
  beforeDestroy() {
    this.$bus.$off("startDrawStroke");
    this.$bus.$off("drawSingleStroke");
    this.$bus.$off("backOrigin");
    this.$bus.$emit("startStrokePage", false);
  },
  watch: {
    isShowEye(value) {
      this.$bus.$emit("startStrokePage", value);
    },
    isTipPage(value) {
      if (!this.startDrawStrokeValue) {
        const data = {
          clickType: 2000301,
          data: { value },
          text: "测试writeStrokePage组件内drawButton事件socket",
        };
        this.$bus.$emit("kids_webSocket_sendInfo", data);
      }
    },
    handleStepsValue() {
      if (this.isShowEye) {
        const data = {
          clickType: 2000302,
          data: { value: this.steps },
          text: "测试writeStrokePage组件内steps的socket",
        };
        this.$bus.$emit("kids_webSocket_sendInfo", data);
      }
    },
  },
  methods: {
    startDrawStroke(type) {
      if (type != 1) {
        this.startDrawStrokeValue = false;
      } else {
        this.startDrawStrokeValue = true;
      }
      this.isTipPage = false;
      this.startWritingStroke();
    },

    startDrawing() {},
    endDrawing() {
      this.finishedStroke();
    },

    finishedStroke() {
      this.showStroke = true;
      this.handleSteps();
    },
    draggedOutOfStroke() {
      console.log("dragged out");
    },
    checkPoint(point) {
      console.log("check point: ", point);
    },

    // 开始写笔画
    startWritingStroke() {
      setTimeout(() => {
        this.isShowDrawButton = false;
        this.isShowEye = true;
        // this.steps = 0;
      }, 1000);
    },
    // 返回最原始page
    handleClickEyeButton(type) {
      if (type != 1) {
        this.startDrawStrokeValue = false;
      } else {
        this.startDrawStrokeValue = true;
      }
      this.isTipPage = true;
      // this.isLastStep = false;
      this.isShowEye = false;
      this.$bus.$emit("startStrokePage", this.isShowEye);
      this.showStroke = false;
      // this.steps = 0;
    },
    // 下一页
    nextPage() {
      this.$bus.$emit("startStrokePage", false);
      this.$bus.$emit("nextButton", true);
    },
    // 步数
    handleSteps(type) {
      if (type != 1) {
        this.handleStepsValue++;
      }


              this.isFadeIn = true;
      this.steps++;
            setTimeout(()=>{
              this.isFadeOut = true;
              },100);
              setTimeout(()=>{
                // this.isFadeOut = false;
                this.isFadeIn = false;
              },500)


      if (this.steps === 3) {
        this.isFinishWriting = true;
        this.isLastStep = true;
        this.isShowHand = false;
        startConfetti();
        playCorrectSound();
      } else {
        playCorrectSound(true, false);
        setTimeout(() => {
          this.$bus.$emit("controlCanvas", "clear");
        }, 2000);
        setTimeout(() => {
          this.showStroke = false;
        }, 2000);
        // this.hitRedArea();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.write-stroke-page-container {
  .stroke-page-box {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 58px;
    position: relative;
    .write-stroke-content {
      background: url("../../../../assets/img/03-Backgrounds/background-practice.svg")
        no-repeat;
      // padding: 10px 10px 30px 10px;
      background-size: cover;
      width: 100%;
      height: 100%;
      border-radius: 58px;
      // display: flex;
      position: relative;
      .stroke-gif-area {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        .write-stroke-content-left {
          // position: absolute;
          position: relative;
          display: flex;
          width: 45%;
          height: 70%;
          justify-content: center;
          align-items: center;
          .write-stroke-left-content {
            position: relative;
            width: 100%;
            height: 100%;
            .left-content-border {
              width: calc(100% - 10px);
              height: calc(100% - 10px);
              position: absolute;
              right: 0;
              bottom: 0;
              background: #224e96;
              border-radius: 30px;
            }
            .left-content {
              color: #000;
              text-align: center;
              position: absolute;
              width: calc(100% - 10px);
              height: calc(100% - 10px);
              background: #fff;
              border-radius: 30px;
              top: 0;
              left: 0;
              display: flex;
              flex-direction: column;
              align-items: center;
              cursor: default;
              .pinyin {
                color: #cd4c3f;
                margin: 3% 0;
                flex: 1;
              }
              .hand {
                position: absolute;
                top: 64%;
                right: 17%;
                width: 30%;
                // cursor: pointer;
              }
              .stroke-area {
                flex: 9;
                width: 80%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                .hanzi-img {
                  position: relative;
                  width: 100%;
                  .hengIcon {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    img {
                      width: 66%;
                      max-height: 66%;
                      position: absolute;
                      top: 0;
                      left: 0;
                      bottom: 0;
                      right: 0;
                      margin: auto;
                      -khtml-user-select: none;
                      -o-user-select: none;
                      -moz-user-select: none;
                      -webkit-user-select: none;
                      user-select: none;
                      -webkit-user-drag: none;
                    }
                  }
                }
                .stroke-square-img {
                  cursor: pointer;
                  -khtml-user-select: none;
                  -o-user-select: none;
                  -moz-user-select: none;
                  -webkit-user-select: none;
                  user-select: none;
                  -webkit-user-drag: none;
                }
                div {
                  width: 100%;
                  height: 0;
                  padding-bottom: 100%;
                  position: absolute;
                }
                img {
                  width: 100%;
                  -khtml-user-select: none;
                  -o-user-select: none;
                  -moz-user-select: none;
                  -webkit-user-select: none;
                  user-select: none;
                  -webkit-user-drag: none;
                }
              }
            }
          }
        }
      }
      .write-stroke-area {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        .write-stroke-content-left {
          // position: absolute;
          position: relative;
          display: flex;
          width: 50%;
          height: 100%;
          justify-content: flex-end;
          align-items: center;
          .write-stroke-left-content {
            position: relative;
            width: 80%;
            height: 75%;
            .left-content-border {
              width: calc(100% - 10px);
              height: calc(100% - 10px);
              position: absolute;
              right: 0;
              bottom: 0;
              background: #224e96;
              border-radius: 30px;
            }
            .left-content {
              color: #000;
              text-align: center;
              position: absolute;
              width: calc(100% - 10px);
              height: calc(100% - 10px);
              background: #fff;
              border-radius: 30px;
              top: 0;
              left: 0;
              display: flex;
              flex-direction: column;
              align-items: center;
              cursor: default;
              .left-content-top {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                // height: 15%;
                flex: 1.5;
                width: 100%;
                margin-right: 10%;
                margin-top: 3%;
                img {
                  // height: 100%;
                  width: 13%;
                  margin-left: 8px;
                  -khtml-user-select: none;
                  -o-user-select: none;
                  -moz-user-select: none;
                  -webkit-user-select: none;
                  user-select: none;
                  -webkit-user-drag: none;
                }
              }
              .pinyin {
                color: #cd4c3f;
              }
              .stroke-area {
                width: 80%;
                // margin-top: 10%;
                display: flex;
                justify-content: center;
                height: 60%;
                flex: 6.5;
                align-items: flex-start;
                .hanzi-img {
                  position: relative;
                  width: 100%;
                  .hengIcon {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    img {
                      width: 66%;
                      max-height: 66%;
                      position: absolute;
                      top: 0;
                      left: 0;
                      bottom: 0;
                      right: 0;
                      margin: auto;
                      -khtml-user-select: none;
                      -o-user-select: none;
                      -moz-user-select: none;
                      -webkit-user-select: none;
                      user-select: none;
                      -webkit-user-drag: none;
                    }
                    &.iconLarge {
                      img {
                        width: 90%;
                      }
                    }
                  }
                }
                .stroke-square-img {
                  cursor: pointer;
                  -khtml-user-select: none;
                  -o-user-select: none;
                  -moz-user-select: none;
                  -webkit-user-select: none;
                  user-select: none;
                  -webkit-user-drag: none;
                }
                div {
                  width: 100%;
                  height: 0;
                  padding-bottom: 100%;
                  position: absolute;
                }
                img {
                  width: 100%;
                  -khtml-user-select: none;
                  -o-user-select: none;
                  -moz-user-select: none;
                  -webkit-user-select: none;
                  user-select: none;
                  -webkit-user-drag: none;
                }
              }
            }
          }
        }
        .write-stroke-content-right {
          width: 50%;
          height: 100%;
          // position: absolute;
          left: 50%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .write-stroke-content-right_img {

            width: 100%;
            height: 100%;
            flex: 1;
            position: relative;

            .steps-img-box {
              width: 85%;
              height: 80%;
              display: flex;
              position: relative;
              // align-items: center;
              // justify-content: flex-start;
              .img-item {
                width: 80%;
                display: flex;
              }

              .cloud {
                // width: 100%;
                position: absolute;
                top: 20%;
                left: 10%;
                right: 0;
                max-height: 100%;
                max-width: 100%;
              }
            }
            .item {
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                display: block;
                width: 90%;
                max-height: 350px;
                // &:first-child {
                //   width: 30%;
                //   // left: 12%;
                //   // top: 20%;
                //   z-index: 1001;
                //   max-height: 150px;
                // }
              }
            }
          }
        }
      }
    }
    // button
    .write-stroke-page-eye-btn {
      position: absolute;
      right: 14px;
      bottom: 26%;
      width: 18%;
      cursor: pointer;
      z-index: 1001;
      text-align: right;
      padding-right: 3%;
      img {
        width: 42%;
        // position: absolute;
        // top: 0;
        // bottom: 0;
        // left: 0;
        // right: 0;
        // margin: auto;
      }
    }
    .button-area {
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 18%;
      cursor: pointer;
      z-index: 1001;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
      }
    }
  }
  .shu {
    height: 80%;
  }
}
</style>
