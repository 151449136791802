var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-word-sorting-cross-river-container" }, [
    _c(
      "div",
      { ref: "moveGameBox", staticClass: "drag-game-content" },
      [
        _vm.buttonList.length
          ? _c("SceneSwitchThumbnails", {
              key: _vm.key,
              attrs: { buttons: _vm.buttonList, numBers: _vm.numBers },
              on: { changeTheNumbers: _vm.changeTheNumbers },
            })
          : _vm._e(),
        _c("div", { staticClass: "contentImg" }, [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.step !== 2,
                expression: "step !== 2",
              },
            ],
            attrs: {
              src: require("@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/mask.svg"),
              alt: "",
            },
          }),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.step === 2,
                expression: "step === 2",
              },
            ],
            attrs: {
              src: require("@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/background.svg"),
              alt: "",
            },
          }),
        ]),
        _c("div", { staticClass: "dogImg", class: "step-" + _vm.step }, [
          _vm.step === 1 || _vm.step === 0
            ? _c("img", {
                attrs: {
                  src: require("@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/dog.svg"),
                  alt: "",
                },
              })
            : _vm._e(),
          _vm.step === 2
            ? _c("img", {
                attrs: {
                  src: require("@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/dog-01.svg"),
                  alt: "",
                },
              })
            : _vm._e(),
        ]),
        _vm._l(_vm.allImgList, function (itemBox, indexBox) {
          return _c("div", { key: indexBox + "allImgList" }, [
            _vm.numBers === indexBox + 1
              ? _c("div", { staticClass: "picture-envelope" }, [
                  _c(
                    "div",
                    { staticClass: "content-picture-switching" },
                    _vm._l(itemBox.imgList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index + "imgList",
                          class: "select-item selectItem-" + (index + 1),
                          attrs: {
                            id: "selectItem-" + indexBox + "-" + item.id,
                            draggable: "true",
                          },
                          on: {
                            dragstart: function ($event) {
                              $event.stopPropagation()
                              return _vm.drag($event, item)
                            },
                            drop: function ($event) {
                              $event.stopPropagation()
                              return _vm.drop($event)
                            },
                            dragend: _vm.handleDragend,
                          },
                        },
                        [
                          _c("div", { staticClass: "select-content" }, [
                            _c("img", {
                              staticClass: "switchImg",
                              class:
                                ({
                                  dispear: item.isDispear,
                                  "drag-img-envelope-4": _vm.dragNum === 4,
                                },
                                "switchImg" + item.key),
                              attrs: { src: item.bgImg, alt: "" },
                            }),
                            _c("div", { staticClass: "pinYin" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "title-pinyin pinyin font-pinyin-medium",
                                },
                                [_vm._v(_vm._s(item.pinYin))]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "title-hanzi river-hanzi-medium",
                                },
                                [_vm._v(_vm._s(item.hanZi))]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "audioUrl",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.playPinyinAudio(item)
                                  },
                                },
                              },
                              [
                                _c("AudioPlayer", {
                                  staticClass: "audioUrl_img",
                                  attrs: {
                                    isPlay: item.isStartPlaying,
                                    audioUrl: _vm.songUrl,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ])
        }),
        _c("div", { staticClass: "picture-mailbox" }, [
          _c(
            "div",
            { staticClass: "content-mailbox" },
            _vm._l(_vm.mailboxList, function (item, index) {
              return _c("div", {
                key: index,
                staticClass: "box-item",
                attrs: { draggable: "true" },
                on: {
                  drop: function ($event) {
                    $event.stopPropagation()
                    return _vm.drop($event, item)
                  },
                  dragover: function ($event) {
                    $event.stopPropagation()
                    return _vm.allowDrop($event)
                  },
                },
              })
            }),
            0
          ),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }