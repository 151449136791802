<template>
  <div
    class="review-train-container"
    :class="`lesson-${lessonNb}`"
  >
    <div class="review-train-box">
      <div class="train-area" :class="`step-${stepNum} origin-${step}`">
        <!--  -->
        <img
          class="train-img"
          ref="train"
          src="@/assets/img/20-Train-review/review-train.svg"
          alt=""
        />
        <div
          class="review-content-wrapper"
          :style="{ width: trainlWidth + 'px', height: '78%' }"
        >
          <div class="review-content vocabulary fadeIn" v-if="stepNum >= 1">
            <div class="review-area">
              <!-- <div
                  class="train-item-col"
                  v-for="(item, index) in [1,2]"
                  :key="index + 'review'"
                >
                
                </div> -->
              <div
                class="train-item"
                v-for="(ele, indexSon) in wordsList"
                :key="indexSon + 'son'"
              >
                <div class="pinyinArea font-pinyin-medium pinyin">
                  {{ ele.pinyin }}
                </div>
                <div class="hanziArea font-hanzi-medium">{{ ele.hanzi }}</div>
              </div>
            </div>
          </div>
          <!-- <div class="review-content knowledge fadeIn"  :class="{isFullWidth: knowledgeReviewList[0][0].isFullWidth}" v-if="stepNum >= 2" >
              <div class="review-area">
                <div
                  class="train-item-col"
                  v-for="(item, index) in knowledgeReviewList"
                  :key="index + 'review'"
                >
                  <div
                    class="train-item"
                    v-for="(ele, indexSon) in item"
                    :key="indexSon + 'son'"
                   
                  >
                    <div class="pinyinArea font-pinyin-medium pinyin">
                      {{ ele.pinyin }}
                    </div>
                    <div class="hanziArea font-hanzi-medium">{{ ele.hanzi }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="review-content pinyinWord fadeIn" v-if="stepNum >= 3">
              <div class="review-area">
                <div
                  class="train-item-col"
                  v-for="(item, index) in pinyinWordReviewList"
                  :key="index + 'review'"
                >
                  <div
                    class="train-item"
                    v-for="(ele, indexSon) in item"
                    :key="indexSon + 'son'"
                   
                  >
                    <div class="vocabularyArea font-pinyin-large">
                      {{ ele.vocabulary }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="review-content strokes fadeIn" v-if="stepNum >= 4">
              <div class="review-area">
                <div
                  class="train-item-col"
                  v-for="(item, index) in strokesReviewList"
                  :key="index + 'review'"
                >
                  <div
                    class="stroke-img"
                    v-for="(ele, indexSon) in item"
                    :key="indexSon + 'son'"
                  >
                    <img :src="ele.strokeImg" alt="" v-if="ele.strokeImg"/>
                    <div class="train-item" v-else>
                      <div class="vocabularyArea font-pinyin-medium">{{ele.character}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          wordsList: []
        }
      }
    },
    //   vocabularyReviewList: {
    //     type: Array,
    //     require: true,
    //   },
    //   knowledgeReviewList: {
    //     type: Array,
    //     require: true,
    //   },
    //   pinyinWordReviewList: {
    //     type: Array,
    //     require: true,
    //   },
    //   strokesReviewList: {
    //     type: Array,
    //     require: true,
    //   },
    lessonNb: {
      type: Number,
      require: false
    }
  },
  data() {
    return {
      wordsList: [],
      localData: {
        wordsList: [
          {
            pinyin: 'xuéxiào',
            hanzi: '学校'
          },
          {
            pinyin: 'xuéxiào',
            hanzi: '学校'
          },
          {
            pinyin: 'xuéxiào',
            hanzi: '学校'
          },
          {
            pinyin: 'xuéxiào',
            hanzi: '学校'
          },
          {
            pinyin: 'xuéxiào',
            hanzi: '学校'
          },
          {
            pinyin: 'xuéxiào',
            hanzi: '学校'
          },
          {
            pinyin: 'xuéxiào',
            hanzi: '学校'
          },
          {
            pinyin: 'xuéxiào',
            hanzi: '学校'
          },
          {
            pinyin: 'xuéxiào',
            hanzi: '学校'
          }
        ]
      },

      trainlDom: null,
      trainlWidth: null,
      isLastStep: false,
      stepNum: null,
      step: 1,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'look',
            startNumberRange: 1,
            endNumberRange: 1,
            clickGoToNextPage: true
          }
        ]
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.stepNum = this.step
      this.$nextTick(() => {
        this.trainlDom = this.$refs.train
        this.trainlWidth = this.trainlDom.getBoundingClientRect().width
      })
    }, 1000)
  },
  watch: {
    componentProps: {
      handler(value) {
        // console.log('watch的数据', value)
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  methods: {
    initData() {
      this.wordsList = this.componentProps.wordsList.map((item) => {
        return {
          pinyin: item[0].value,
          hanzi: item[1].value
        }
      })
      // console.log(this.wordsList,'111');
    },
    nextPage() {
      this.$bus.$emit('nextButton', true)
    },
    scrollMove(num) {
      const speed = this.scrollSpeed
      this.isMoving = true
      let timer = setInterval(() => {
        this.distance += speed
        this.scrollDom.scrollLeft = this.distance
        if (this.distance / this.scrollWidth >= num) {
          clearInterval(timer)
          this.isMoving = false

          if (this.step === this.durationArr.length) {
            this.isLastStep = true
            playCorrectSound()
            startConfetti()
          } else {
            playCorrectSound(true, false)
          }
        }
      }, 10)
    }
  }
}
</script>
<style scoped lang="scss">
.review-train-container {
  width: 100%;
  height: 100%;
  .review-train-box {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-color: #f5f9ff;
    background-image: url('../../../../assets/img/20-Train-review/background-clouds.svg');
    background-size: cover;
    border-radius: 58px;
    position: relative;
    overflow-x: scroll;
    position: relative;
    .train-area {
      position: absolute;
      bottom: 0;
      left: -175%;
      height: 100%;
      width: 100%;
      .train-img {
        height: 40%;
        position: absolute;
        bottom: 0;
      }
      .review-content-wrapper {
        position: absolute;
        left: 0;
        bottom: 22%;
        .review-content {
          position: absolute;
          bottom: 0%;
          height: 100%;
          // width: 31.5%;
          z-index: 10;
          display: inline-block;
          .review-area {
            width: 100%;
            height: 100%;
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: flex-end;
            .train-item {
              background: rgb(34, 78, 150);
              color: #fff;
              border-radius: 6px;
              margin-top: 1px;
              margin-right: 1px;
              padding: 5px;
            }
            .pinyinArea,
            .hanziArea {
              text-align: center;
              border-radius: 5px;
              padding: 5px 0 2px 0;
              line-height: 1;
            }
            .hanziArea {
              padding: 2px 0 5px 0;
            }
          }
        }
      }

      .vocabulary {
        width: 20%;
        right: 20%;
      }
      .knowledge {
        width: 20%;
        right: 43.5%;

        &.isFullWidth {
          height: auto;
          .review-area {
            flex-direction: column;
          }
        }
      }
      .pinyinWord {
        width: 12%;
        right: 68%;
      }
      .strokes {
        width: 15%;
        right: 85%;

        .stroke-img {
          height: 30%;
          img {
            height: 70%;
          }
        }
      }
    }
    .origin-2 {
      left: -75%;
    }
    .origin-3 {
      left: -30%;
    }
    .origin-4 {
      left: 0%;
    }
    .step-0 {
      left: -100%;
      transition: left 5s;
    }
    .step-1 {
      left: -75%;
      transition: left 5s;
    }
    .step-2 {
      left: -30%;
      transition: left 5s;
    }
    .step-3 {
      left: 0%;
      transition: left 5s;
    }
    .step-4 {
      left: 20%;
      transition: left 5s;
    }
  }
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  &.lesson-24 .strokes .stroke-img {
    // height: auto !important;
    .train-item-col:first-of-type {
      flex-grow: 2 !important;
    }
  }
}

.vocabularyArea {
  padding: 10px 0;
  text-align: center;
}
.stroke-img {
  width: 99%;
  height: 20%;
  background: #224e96;
  border-radius: 6px;
  margin-top: 1px;
  margin-right: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 50%;
  }
}
.deepBlue {
  background: rgb(27, 69, 137) !important;
}
.deepBlueTest {
  background: rgb(27, 69, 137) !important;
}
.Blue {
  background: rgb(34, 78, 150);
}
</style>
