var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-choose-img-by-listening-container" }, [
    _c(
      "div",
      { staticClass: "game-content" },
      [
        _c("Star", {
          attrs: {
            totalStarNumber: _vm.answerList.length,
            currentIndex: _vm.currentStarIndex,
          },
        }),
        _c("div", { staticClass: "choose-img-area" }, [
          _c("div", { staticClass: "img-area" }, [
            _c(
              "div",
              { staticClass: "img-content" },
              _vm._l(_vm.allImgList[_vm.currentIndex], function (item, index) {
                return _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: item.bgImg !== "",
                      expression: "item.bgImg !== ''",
                    },
                  ],
                  key: index + "img",
                  staticClass: "img-item",
                  class: {
                    active: index === _vm.chooseImgIndex,
                    isCorrect:
                      item.id === _vm.answerList[_vm.currentIndex] &&
                      index === _vm.chooseImgIndex,
                  },
                  attrs: { src: item.bgImg, alt: "" },
                  on: {
                    click: function ($event) {
                      return _vm.handleChooseImg(item.id, index)
                    },
                  },
                })
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "trumpet-area-right" }, [
            _c(
              "span",
              {
                staticClass: "trumpet-area",
                class: { lessononehover: _vm.isClickAudio },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handlePlayPinyinAudio.apply(null, arguments)
                  },
                  mouseenter: function ($event) {
                    return _vm.hoverImg(1, "hover")
                  },
                  mouseleave: function ($event) {
                    return _vm.hoverImg(1, "")
                  },
                },
              },
              [
                _c("AudioPlayer", {
                  attrs: { isPlay: _vm.isStartPlaying, audioUrl: _vm.songUrl },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("CommonButton", {
          on: { shuffleClickEvent: _vm.handleShuffleClickEvent },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }