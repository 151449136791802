var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-scratch-pic" }, [
    _c(
      "div",
      { staticClass: "scratch-content" },
      [
        _c("SceneSwitchThumbnails", {
          attrs: { buttons: _vm.buttonList, numBers: _vm.currentIndex },
          on: { changeTheNumbers: _vm.changeTheNumbers },
        }),
        Object.keys(_vm.gameInfo).length
          ? _c("div", { staticClass: "title-area" }, [
              _c("div", { staticClass: "title" }, [
                _c(
                  "div",
                  { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                  [_vm._v(" " + _vm._s(_vm.gameInfo.titlePinyin) + " ")]
                ),
                _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
                  _vm._v(" " + _vm._s(_vm.gameInfo.titleHanzi) + " "),
                ]),
              ]),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "scratch-area", on: { click: _vm.handleShowAnswer } },
          [
            _c(
              "div",
              { staticClass: "scratch-img-area" },
              [
                _vm._l(_vm.gameList, function (item, index) {
                  return [
                    index === _vm.currentIndex - 1
                      ? _c(
                          "div",
                          { key: index, staticClass: "img-item" },
                          [
                            _c(
                              "transition",
                              { attrs: { name: "el-fade-in" } },
                              [
                                _c("img", {
                                  attrs: {
                                    src: item.frame
                                      ? _vm.yellowFrame
                                      : _vm.redFrame,
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]
        ),
        _c("div", { staticClass: "erase-area" }, [
          _c(
            "div",
            {
              class: _vm.position[_vm.currentIndex - 1].frame
                ? "erase-shape-right"
                : "erase-shape-left",
            },
            [
              _c("KidsWhiteBoard", {
                staticClass: "white-board",
                attrs: {
                  displayColorIndex: _vm.currentColorIndex,
                  cursorStyle: true,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              class: _vm.position[_vm.currentIndex - 1].frame
                ? "erase-answer-right"
                : "erase-answer-left",
            },
            [
              !_vm.isShowAnswer
                ? _c("div", { on: { click: _vm.handleShowAnswer } }, [
                    _c("img", {
                      staticClass: "show-answer-icon",
                      attrs: {
                        src: this.imgList[_vm.currentColorIndex - 1].image,
                        alt: "",
                      },
                    }),
                  ])
                : _c("div", [
                    _c("div", { staticClass: "answer-pinyin pinyin" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.position[_vm.currentIndex - 1].pinyin) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "answer-hanzi YuantiBold" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.position[_vm.currentIndex - 1].hanzi) +
                          " "
                      ),
                    ]),
                  ]),
              _vm.position[_vm.currentIndex - 1].photo
                ? _c(
                    "div",
                    {
                      class: _vm.position[_vm.currentIndex - 1].frame
                        ? "answer-photo-right"
                        : "answer-photo-left",
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.position[_vm.currentIndex - 1].photo,
                          alt: "",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }