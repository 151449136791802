var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-basketball-container" }, [
    _c("div", { staticClass: "basketball-box" }, [
      _c(
        "div",
        { staticClass: "basketball-game-area" },
        [
          _c("img", { staticClass: "bgIamge" }),
          _c("div", { staticClass: "basketball-game-content" }, [
            _c(
              "div",
              { staticClass: "trumpter", on: { click: _vm.playAudio } },
              [
                _c("AudioPlayer", {
                  attrs: { isPlay: _vm.isStartPlaying, audioUrl: _vm.songUrl },
                }),
              ],
              1
            ),
            _c("img", {
              staticClass: "objectDisplay",
              attrs: { src: _vm.board },
            }),
            _vm.playAnimation
              ? _c("div", { staticClass: "ballDrop" }, [
                  _c("div", { staticClass: "image-container ball" }, [
                    _c("img", {
                      staticClass: "img_basketball",
                      attrs: { src: _vm.basketball, alt: "" },
                    }),
                    _vm.audioPy
                      ? _c("div", { staticClass: "centered-text pinyin" }, [
                          _vm._v(" " + _vm._s(_vm.audioPy) + " "),
                        ])
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
            _c("img", {
              staticClass: "basketDisplay",
              attrs: { src: _vm.basket },
            }),
          ]),
          _c("div", { staticClass: "bottonCont" }, [
            _c("div", { staticClass: "redBackground" }),
            _c(
              "div",
              { staticClass: "bottonsWrapper" },
              _vm._l(_vm.chooseList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "option-item",
                    class: {
                      shake:
                        item.id !== _vm.answerList[_vm.stepsIndex] &&
                        _vm.isClicked &&
                        index === _vm.chooseIndex,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.foundAnswer(index)
                      },
                    },
                  },
                  [
                    item.showObj
                      ? _c("div", { staticClass: "image-container" }, [
                          _c("img", {
                            staticClass: "img_basketball",
                            attrs: { src: _vm.basketball, alt: "" },
                          }),
                          item.audioPy
                            ? _c(
                                "div",
                                { staticClass: "centered-text pinyin" },
                                [_vm._v(" " + _vm._s(item.audioPy) + " ")]
                              )
                            : _vm._e(),
                        ])
                      : _c("img", {
                          staticClass: "img_basketball",
                          attrs: { src: _vm.basketball_gray, alt: "" },
                        }),
                  ]
                )
              }),
              0
            ),
          ]),
          _c("Star", {
            attrs: {
              totalStarNumber: _vm.startNum,
              currentIndex: _vm.stepsIndex,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }