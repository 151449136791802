<template>
  <div class="game-speak-by-pic-container">
    <div class="game-content">
      <div class="menu" v-if="hasMenu">
        <SceneSwitchThumbnails
          :buttons="buttonList"
          :numBers="currentIndex"
          @changeTheNumbers="changeTheNumbers"
          :key="key"
          v-if="buttonList.length"
        />
      </div>
      <div class="bgImg-area">
        <PageButton
          :bottomCornerButtonList="bottomCornerButtonList"
          :isLastStep="isLastStep"
          @clickNext="nextPage"
        />
        <template v-for="(item, index) in gameList">
          <div
            class="img-box"
            :key="index"
            v-if="index + 1 === currentIndex"
            @click="handleClickImg(index)"
          >
            <transition name="el-fade-in">
              <img :src="item.bgImg" alt="" />
            </transition>
          </div>
        </template>
      </div>

      <div class="bottom-sentence-area" v-if="hasSentence">
        <div
          class="hanzi-item"
          v-for="(item, index) in gameList"
          :key="index"
          v-show="item.isShowSentence"
        >
          <div class="pinyin sentence-hanzi font-pinyin-medium">
            {{ item.pinyin }}
          </div>
          <div class="sentence-hanzi font-hanzi-medium">{{ item.hanzi }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import PageButton from '@/components/Course/Interaction/Button/pageButton.vue'
import SceneSwitchThumbnails from '../GameComponent/SceneSwitchThumbnails.vue'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          gameSpeakByPicList: []
        }
      }
    }
  },
  data() {
    return {
      localData: {
        gameList: [
          {
            bgImg: require(`@/assets/img/16-GAMES/G59-click-speaking/robot-dream-1.svg`),
            pinyin: 'Wǒ yǒu yí ge bàba.',
            hanzi: '我有一个爸爸。',
            isShowSentence: false
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G59-click-speaking/robot-dream-2.svg`),
            pinyin: 'Wǒ yǒu sān ge māma.',
            hanzi: '我有三个妈妈。',
            isShowSentence: false
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G59-click-speaking/robot-dream-3.svg`),
            pinyin: 'Wǒ yǒu wǔ ge mèimei.',
            hanzi: '我有五个妹妹。',
            isShowSentence: false
          }
        ],
        buttonList: [
          {
            image: require('@/assets/img/16-GAMES/G59-click-speaking/menu-robot-1.svg'),
            startNumberRange: 0,
            endNumberRange: 0
          },
          {
            image: require('@/assets/img/16-GAMES/G59-click-speaking/menu-robot-2.svg'),
            startNumberRange: 1,
            endNumberRange: 1
          },
          {
            image: require('@/assets/img/16-GAMES/G59-click-speaking/menu-robot-3.svg'),
            startNumberRange: 2,
            endNumberRange: 2
          }
        ]
      },
      gameList: [],
      buttonList: [],
      key: 1,
      hasMenu: true,
      hasSentence: true,
      isClick: true,
      isClickToShowSentence: false,
      isDrawingConer: false,
      currentIndex: 1,
      clickIndex: 1,
      isLastStep: false,
      clickMenuSocketInfo: {},
      clickImgSocketInfo: {},
      lock: false,
      isFirstClick: true,

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: this.isDrawingConer ? 'draw' : 'speak',
            startNumberRange: 0,
            endNumberRange: 100
          }
        ]
      }
    }
  },
  components: {
    PageButton,
    SceneSwitchThumbnails
  },
  mounted() {
    this.$bus.$on('speakingSentenceMenu', ({ index }) => {
      this.changeTheNumbers(index, true)
    })
    this.$bus.$on('clickSpeakingSentenceImg', ({ index }) => {
      this.handleClickImg(index, 'socket', true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('speakingSentenceMenu')
    this.$bus.$off('clickSpeakingSentenceImg')
  },
  watch: {
    /**
     * @description: 根据右侧编辑的内容实时更新左侧展示区
     * @return {*}
     */
    componentProps: {
      handler(newVal) {
        const { isTemplatePrview } = newVal
        if (isTemplatePrview) return
        this.initData()
        this.gameList = newVal.gameSpeakByPicList.map((arr) => {
          return {
            bgImg: arr[0].value,
            hanzi: arr[1].value,
            isShowSentence: false,
            pinyin: arr[2].value
          }
        })
        let startNumber = 0
        this.buttonList = newVal.gameSpeakByPicList.map((subArray) => {
          const list = {
            image: subArray[3].value,
            startNumberRange: startNumber,
            endNumberRange: startNumber
          }
          startNumber++
          return list
        })
        this.key = Date.now()
      },
      deep: true,
      immediate: true
    },
    clickMenuSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004601,
          data: { value },
          text: 'SpeakingSentence点击Menu socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    clickImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004602,
          data: { value },
          text: 'SpeakingSentence点击Img socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    }
  },

  methods: {
    initData() {},
    /**
     * @description: 初始化游戏
     * @return {*}
     */

    initGame() {
      this.gameList.forEach((item) => {
        item.isShowSentence = false
      })
    },

    /**
     * @description: 点击图片时展示文字
     * @param {*} index
     * @return {*}
     */

    showSentence(index) {
      this.gameList[index].isShowSentence = true
    },

    /**
     * @description: 切换序号时重置游戏
     * @param {*} index
     * @param {*} isFromSocket
     * @return {*}
     */

    changeTheNumbers(index, isFromSocket = false) {
      // console.log(index, 'index')

      this.initGame()
      this.lock = false
      this.clickIndex = 1
      this.currentIndex = index + 1
      this.isFirstClick = true
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random()
        }
      }
      if (!this.isClick && index === this.gameList.length) {
        this.isLastStep = true
      } else {
        this.isLastStep = false
      }
    },

    /**
     * @description: 切换到下一页
     * @return {*}
     */
    nextPage() {
      this.$bus.$emit('nextButton', true)
    },

    /**
     * @description: 点击图片时触发
     * @param {*} index
     * @return {*}
     */
    handleClickImg(index, socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickImgSocketInfo = {
          value: Math.random(),
          index
        }
      }
      // if (!this.hasMenu) {
      //   this.lock = false
      //   if (this.currentIndex == this.gameList.length) {
      //     return
      //   }
      //   this.currentIndex++
      // }
      // if (!this.isClick) {
      //   return
      // }
      // console.log(this.lock)
      if (!this.isClickToShowSentence) {
        this.showSentence(index)
      } else {
        if (!this.isFirstClick) {
          this.showSentence(index)
        } else {
          this.isFirstClick = false
        }
      }
      if (this.lock) {
        return
      }
      playCorrectSound(true, false)
      // console.log('diercidianji+++++++')
      if (this.currentIndex === this.gameList.length) {
        if (!this.isClickToShowSentence) {
          this.isLastStep = true
          playCorrectSound()
          startConfetti()
        } else {
          setTimeout(() => {
            if (this.gameList[index].isShowSentence) {
              this.isLastStep = true
              playCorrectSound()
              startConfetti()
            }
          }, 1000)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.game-speak-by-pic-container {
  width: 100%;
  height: 100%;
  line-height: 1;
  .game-content {
    width: 100%;
    height: 100%;
    line-height: 1;
    position: relative;
    .bgImg-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .img-box {
        cursor: pointer;
        width: 100%;
        height: 100%;
        img {
          border-radius: 58px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .bottom-sentence-area {
      position: absolute;
      width: 100%;
      height: 20%;
      bottom: 4%;
      display: flex;
      justify-content: center;
      align-items: center;
      .hanzi-item {
        margin: 0 auto;
        background: #cd4c3f;
        position: absolute;
        padding: 1% 15%;
        color: #fff;
        border-radius: 40px;
        .sentence-hanzi,
        .sentence-pinyin {
          text-align: center;
        }
      }
    }
  }
}
</style>
