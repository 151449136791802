<template>
    <div class="find-element-game-container layout-border">
      <div class="game-content" :class="'lesson-' + 57">
        <div class="title-area" v-if="titleInfo">
          <div class="title">
            <div class="title-pinyin pinyin font-pinyin-medium">
              {{ titleInfo.pinyin }}
            </div>
            <div class="title-hanzi font-hanzi-medium">
              {{ titleInfo.hanzi }}
            </div>
          </div>
        </div>
        <img :src="bgPic" class="bg-img" />
        <div
          class="img-area"
          v-for="(item, index) in imgList"
          :key="index"
          v-show="findIdList.indexOf(item.id) !== -1"
        >
          <img :src="item.image" alt="" />
        </div>
        <div class="click-area">
          <div
            class="click-item"
            v-for="(item, index) in imgList"
            :class="'click-' + item.id"
            :key="index + 'click'"
            @click="handleClick(item.id)"
          ></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { startConfetti, playCorrectSound } from "@/utils/tools";
  
  export default {
    name: "FindElementGame",
    props: {
      titleInfo: {
        type: Object,
        require: false,
      },
      componentProps: {
      type: Object,
      default: () => {
        return {
          bgPic:'',
          timeList: []
        }
      }
    },
    },
    components: {
    },
    data() {
      return {
        bgPic:'',
        imgList:[],
        // 测试数据
      //   bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-57-1/background-time.svg"),
      // imgList: [
      //   {
      //     id: 1,
      //     image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-57-1/time-08.svg"),
      //   },
      //   {
      //     id: 2,
      //     image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-57-1/time-10.svg"),
      //   },
      //   {
      //     id: 3,
      //     image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-57-1/time-20.svg"),
      //   },
      //   {
      //     id: 4,
      //     image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-57-1/time-31.svg"),
      //   },
     
      // ],
        isLastStep: false,
        bottomCornerButtonList: {
          settings: {
            showNextOnLastStep: true,
          },
          buttons: [
            {
              type: "look",
              startNumberRange: 1,
              endNumberRange: 100,
            },
          ],
        },
        findIdList: [],
        clickSocketInfo: {},
      };
    },
    watch: {
      clickSocketInfo: {
        handler(value) {
          const data = {
            clickType: 20064,
            data: { value },
            text: "FamilyTreeGame drop socket",
          };
          this.sendSocketInfo = { ...data };
          this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
        },
        deep: true,
      },
      componentProps: {
      handler(value) {
        // console.log('watch的数据', value)
        this.initData()
      },
      deep: true
    }
    },
    mounted() {
      this.$bus.$on("clickToFindElement", ({ id }) => {
        this.handleClick(id, true);
      });
    },
    beforeDestroy() {
      this.$bus.$off("clickToFindElement");
    },
    methods: {
      initData(){
           this.bgPic = this.componentProps.bgPic
           this.imgList = this.componentProps.timeList.map((item,index) => {
            return {
                image: item.value,
                id: index + 1
            }
           })
        // console.log(this.imgList);
           
      }, 
      nextPage() {
        this.$bus.$emit("nextButton", true);
      },
      handleClick(id, isFromSocket) {
        if (!isFromSocket) {
          this.clickSocketInfo = {
            id,
            value: Math.random(),
          };
        }
        if (this.findIdList.indexOf(id) !== -1) {
          return;
        }
        this.findIdList.push(id);
        // this.findIdList = Array.from(new Set(this.findIdList));
        playCorrectSound(true, false);
        if (this.findIdList.length === this.imgList.length) {
          playCorrectSound();
          startConfetti();
          this.isLastStep = true;
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .find-element-game-container {
    .game-content {
      width: 100%;
      height: 100%;
      position: relative;
      .title-area {
        z-index: 9;
        position: absolute;
        top: 0;
        left: 0;
        top: 10%;
        width: auto;
        height: auto;
        padding:20px;
        background: #CD4C3F;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        color: #fff;
        .title {
          .title-pinyin,
          .title-hanzi {
            text-align: center;
          }
        }
      }
      .bg-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 58px;
        z-index: 2;
      }
      .img-area {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        img {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          z-index: 4;
        }
      }
      .click-area {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        .click-item {
          position: absolute;
          // background: #000;
          width: 20%;
          height: 10%;
          left: 0;
          top: 0;
          cursor: pointer;
        }
      }
    }
  }
  
  .lesson-30 {
    .click-1 {
      width: 6% !important;
      height: 24% !important;
      left: 75% !important;
      top: 30% !important;
    }
    .click-2 {
      width: 7% !important;
      height: 30% !important;
      left: 85% !important;
      top: 22% !important;
    }
    .click-3 {
      width: 5% !important;
      height: 24% !important;
      left: 15% !important;
      top: 47% !important;
    }
    .click-4 {
      width: 10% !important;
      height: 10% !important;
      left: 47% !important;
      top: 70% !important;
    }
    .click-5 {
      width: 11% !important;
      height: 10% !important;
      left: 39% !important;
      top: 47% !important;
    }
    .click-6 {
      width: 4% !important;
      height: 10% !important;
      left: 40% !important;
      top: 14% !important;
    }
  }
  
  .lesson-35 {
    .click-1 {
      width: 4% !important;
      height: 5% !important;
      left: 25% !important;
      top: 16% !important;
    }
    .click-2 {
      width: 12% !important;
      height: 5% !important;
      left: 46% !important;
      top: 15% !important;
    }
    .click-3 {
      width: 6% !important;
      height: 5% !important;
      left: 70% !important;
      top: 23% !important;
    }
    .click-4 {
      width: 6% !important;
      height: 6% !important;
      left: 31% !important;
      top: 60% !important;
    }
    .click-5 {
      width: 6% !important;
      height: 6% !important;
      left: 58% !important;
      top: 64% !important;
    }
  }
  .lesson-51 {
    .click-1 {
      // background-color: blue;
      // opacity: 0.5;
      width: 12% !important;
      height: 24% !important;
      left: 29% !important;
      top: 36% !important;
    }
    .click-2 {
      // background-color: blue;
      // opacity: 0.5;
      width: 12% !important;
      height: 24% !important;
      left: 44% !important;
      top: 36% !important;
    }
    .click-3 {
      // background-color: blue;
      // opacity: 0.5;
      width: 12% !important;
      height: 24% !important;
      left: 59% !important;
      top: 36% !important;
    }
    .click-4 {
      // background-color: blue;
      // opacity: 0.5;
     width: 12% !important;
      height: 24% !important;
      left: 29% !important;
      top: 69% !important;
    }
    .click-5 {
      // background-color: blue;
      // opacity: 0.5;
      width: 12% !important;
      height: 24% !important;
      left: 44% !important;
      top: 69% !important;
    }
    .click-6 {
      // background-color: blue;
      // opacity: 0.5;
      width: 12% !important;
      height: 24% !important;
      left: 59% !important;
      top: 69% !important;
    }
  }
  .lesson-57 {
    .click-1 {
      // background-color: blue;
      // opacity: 0.5;
      width: 25% !important;
      height: 24% !important;
      left: 22% !important;
      top: 24% !important;
    }
    .click-2 {
      // background-color: blue;
      // opacity: 0.5;
      width: 25% !important;
      height: 24% !important;
      left: 58% !important;
      top: 24% !important;
    }
    .click-3 {
      // background-color: blue;
      // opacity: 0.5;
      width: 25% !important;
      height: 24% !important;
      left: 22% !important;
      top: 55% !important;
    }
    .click-4 {
      // background-color: blue;
      // opacity: 0.5;
      width: 25% !important;
      height: 24% !important;
      left: 58% !important;
      top: 55% !important;
    }
  }
  .lesson-59 {
    .click-1 {
      // background-color: blue;
      // opacity: 0.5;
      width: 18% !important;
      height: 20% !important;
      left: 67% !important;
      top: 25% !important;
    }
    .click-2 {
      // background-color: blue;
      // opacity: 0.5;
      width: 13% !important;
      height: 20% !important;
      left: 11% !important;
      top: 60% !important;
    }
    .click-3 {
      // background-color: blue;
      // opacity: 0.5;
      width: 19% !important;
      height: 28% !important;
      left: 34% !important;
      top: 0% !important;
    }
  }
  .lesson-61 {
    .click-1 {
      // background-color: blue;
      // opacity: 0.5;
      width: 6% !important;
      height: 9% !important;
      left: 23% !important;
      top: 35% !important;
    }
    .click-2 {
      // background-color: blue;
      // opacity: 0.5;
      width: 6% !important;
      height: 9% !important;
      left: 40% !important;
      top: 35% !important;
    }
    .click-3 {
      // background-color: blue;
      // opacity: 0.5;
      width: 6% !important;
      height: 9% !important;
      left: 32% !important;
      top: 44% !important;
    }
    .click-4 {
      // background-color: blue;
      // opacity: 0.5;
      width: 6% !important;
      height: 9% !important;
      left: 65% !important;
      top: 54% !important;
    }
    .click-5 {
      // background-color: blue;
      // opacity: 0.5;
      width: 6% !important;
      height: 9% !important;
      left: 40% !important;
      top: 72% !important;
    }
  }
  </style>
  