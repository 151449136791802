<template>
  <div class="game-moving-spaceship">
    <div class="move-game-box position-relative">
      <div class="move-game-area">
        <div
          class="move-game-content"
          @click="handleClickBgImg"
          ref="moveGameBox"
        >
          <!-- 背景图 -->
          <img :src="bgImg" alt="" />
          <!-- <img :src="frontImg" alt="" class="second-img" v-if="frontImg" /> -->

          <div
            class="student-img"
            ref="student"
            :style="{ width: scrollWidth + 'px', height: '100%' }"
          >
            <div class="object-wrapper">
              <div class="move-wrapper" :class="'step-' + step">
                <div class="flame" :class="{ flameIsVisible: isMoving }"></div>
                <img
                  :src="studentImg"
                  alt=""
                  class="move-block"
                  v-if="studentImg"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="btn-page-corner">
          <img :src="btnImg" class="btn-page-corner-img" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          bgImg: '',
          studentImg: '',
          // frontImg: '',
          btnImg: ''
        }
      }
    }
  },

  data() {
    return {
      success_audio_sound: '',
      isLastStep: false,
      bottomCornerButtonList: {
      
      },

      step: 0,
      scrollDom: null,
      scrollWidth: null,
      distance: 0,
      scrollSpeed: 5,
      clickSocketInfo: {},
      isMoving: false,
      flag: false,
      bgImg: '',
      // frontImg: '',
      durationArr: [0.1, 0.28, 0.45, 0.65],
      studentImg:'',
      btnImg: '',
      localData: {
        success_audio_sound: require('@/assets/audio/correct_answer.mp3'),
        bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'speak',
            startNumberRange: 0,
            endNumberRange: 100
          }
        ]
      },
      bgImg: 'http://test.nihaokids.com/img/lesson-15-map-galaxy.ea57e2ca.svg',
      studentImg:'http://test.nihaokids.com/img/spaceship-transgalaxy-noflame.175f30e4.svg',
      btnImg: 'http://test.nihaokids.com/img/panda-speak.05a450c9.svg',
      }
    }
  },

  mounted() {
    this.initData()
    setTimeout(() => {
      this.$nextTick(() => {
        this.scrollDom = this.$refs.moveGameBox
        this.scrollWidth = this.scrollDom.scrollWidth
      })
    }, 100)
    this.$bus.$on('clicPinyinkMoveGame', () => {
      this.handleClickBgImg('socket', true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('clicPinyinkMoveGame')
  },
  watch: {
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20039,
          data: { value },
          text: 'PinyinMoveGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        this.initData()
      },
      deep: true
    }
  },
  methods: {
    initData() {
      this.bgImg = this.componentProps.bgImg || this.bgImg
      this.studentImg = this.componentProps.studentImg || this.studentImg
      // this.frontImg = this.componentProps.frontImg || this.frontImg
      this.btnImg = this.componentProps.btnImg || this.btnImg
    },
    controlScroll(step) {
      this.scrollMove(this.durationArr[step - 1])
    },
    scrollMove(num) {
      const speed = this.scrollSpeed
      this.isMoving = true
      let timer = setInterval(() => {
        this.distance += speed
        this.scrollDom.scrollLeft = this.distance
        if (this.distance / this.scrollWidth >= num) {
          clearInterval(timer)
          this.isMoving = false

          if (this.step === this.durationArr.length) {
            this.isLastStep = true
            playCorrectSound()
            startConfetti()
          } else {
            playCorrectSound(true, false)
          }
        }
      }, 10)
    },
    handleClickBgImg(socket, isFromSocket = false) {
      if (this.isMoving) {
        return
      }
      if (this.step >= this.durationArr.length) {
        return
      }
      if (!isFromSocket) {
        this.clickSocketInfo = {
          value: Math.random()
        }
      }
      if (this.step >= this.durationArr.length + 1) {
        return
      }
      this.step++
      if (this.step >= 1) {
        this.controlScroll(this.step)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.game-moving-spaceship {
  width: 100%;
  height: 100%;
  position: relative;
  .move-game-box {
    width: 100%;
    height: 100%;
    background-color: #f7f4e0;
    border-radius: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    position: relative;
    .btn-page-corner {
      position: absolute;
      right: 0;
      bottom: -42px;
    }
    .move-game-area {
      width: 100%;
      height: 100%;
      white-space: nowrap;
      border-radius: 58px;
      .move-game-content {
        height: 100%;
        position: relative;
        border-radius: 58px;

        :hover {
          cursor: pointer;
        }
        overflow-x: scroll;
        img {
          // width: 100%;
          height: 100%;
        }
        .second-img {
          position: absolute;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 7;
        }
        .student-img {
          position: absolute;
          left: 0%;
          top: 0;

          .object-wrapper {
            z-index: 5;
            height: 100%;
            .move-wrapper {
              display: inline-block;

              position: absolute;
              transition: all 2.5s;
              bottom: 25%;
              left: 0%;
              .move-block {
                width: 60%;
                max-width: 300px;
              }
            }
          }
        }
      }
    }
    ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
}
.move-block {
  transition: all 1.5s !important;
}
.move-wrapper {
  left: 5% !important;
}
.step-1 {
  bottom: 22% !important;
  left: 13% !important;
}

.step-2 {
  bottom: 22% !important;
  left: 32% !important;
}

.step-3 {
  bottom: 22% !important;
  left: 49% !important;
}

.step-4 {
  bottom: 22% !important;
  left: 70% !important;
}

.game-container {
  width: 100%;
  height: 100%;
}

/deep/ .student-img .object-wrapper > img {
  position: relative;
  z-index: 999;
}

/deep/ .flame {
  bottom: -5% !important;
  left: -40% !important;
  position: absolute;

  width: 64%;
  height: 90%;
  // background: linear-gradient(-45deg, red, orange);
  background: transparent;
  border-radius: 15px 15px 0px 15px;
  transform: rotate(166deg);
  // animation: 0.1s flame infinite;
  filter: blur(10px);
  box-shadow: 17px 20px 90px #700;
  // border:45px solid black;
  border-left-width: 25px;
  border-top-width: 25px;
  transform: rotate(69deg) scale(0.5);
  opacity: 0;
  transition: opacity 0.5s;
  &:after,
  &:before {
    content: '';
    width: 20%;
    height: 20%;
    display: block;
    position: absolute;
    background: linear-gradient(92deg, red, orange);
    animation: 0.2s flame infinite;
    transform: scale(0.8) rotate(20deg);
    border-radius: 10px 10px 0px 10px;
    top: 20px;
  }
  &:after {
    background: linear-gradient(299deg, red, orange) !important;
  }
  &:before {
    // top: 0;
    animation-duration: 0.09s;
    transform: scale(0.9) rotate(-220deg) translate(10px, 0px);
  }
  &.flameIsVisible {
    opacity: 1;
  }
}

@keyframes flame {
  0% {
    // height: 75px;
    // height: 75px;
    width: 100%;
    width: 100%;
  }
  50% {
    height: 99%;
    width: 99%;
  }
  100% {
    height: 100%;
    width: 100%;
  }
}
</style>
