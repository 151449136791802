var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-rocket-container" }, [
    _c("audio", { ref: "audioBox", attrs: { src: _vm.audioUrl } }),
    _c("div", { staticClass: "customlesson-content-five position-relative" }, [
      _c("div", { ref: "pictureOne", staticClass: "game-rocket-content" }, [
        _c("img", { ref: "content", attrs: { src: _vm.bgImg, alt: "" } }),
      ]),
      _c(
        "div",
        {
          ref: "rocketTop",
          staticClass: "picture-content-rocket",
          class: { fadeOutRocket: _vm.fadeOutRocket },
        },
        [
          _c("img", {
            staticClass: "rocket",
            attrs: { src: _vm.getRocketImg, alt: "" },
          }),
          _c("img", {
            staticClass: "rocket-flame",
            class: {
              rocketFlameAnimated: _vm.rocketFlameAnimated,
              isHidden: !_vm.showFlames,
            },
            attrs: {
              src: require("@/assets/img/16-GAMES/G09-rocket/rocket-flame.svg"),
              alt: "",
            },
          }),
        ]
      ),
      _c("div", { staticClass: "picture-bottom" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/img/16-GAMES/G09-rocket/control-table.svg"),
            alt: "",
          },
        }),
        _c(
          "div",
          {
            ref: "buttonTop",
            staticClass: "picture-bottom-control-button-top glow",
            on: { click: _vm.controlClick },
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/img/16-GAMES/G09-rocket/control.svg"),
                alt: "",
              },
            }),
          ]
        ),
      ]),
    ]),
    _vm.control == 0
      ? _c("div", { staticClass: "customlesson-finger" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/img/16-GAMES/G09-rocket/hand.svg"),
              alt: "",
            },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }