var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "course-end-container",
      style: { backgroundImage: "url('" + _vm.bgImg + "')" },
    },
    [
      _c("div", { staticClass: "begin-end-box" }, [
        _c("div", { staticClass: "customlesson_twoimg" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/img/05-Panda/panda-zaijian-background.svg"),
              alt: "",
            },
          }),
          _c("div", { staticClass: "customlesson_num" }, [
            _c("div", { staticClass: "customlesson_two" }, [
              _c("span", { staticClass: "font-pinyin-xlarge pinyin" }, [
                _vm._v(_vm._s(_vm.titlePinyin)),
              ]),
            ]),
            _c("div", { staticClass: "customlesson_three" }, [
              _c("span", { staticClass: "font-hanzi-xlarge" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }