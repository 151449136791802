var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-flip-card-container" }, [
    _c(
      "div",
      { staticClass: "game-flip-card-content" },
      [
        _c(
          "div",
          { staticClass: "word-card-area" },
          _vm._l(_vm.wordCardList, function (item, index) {
            return _c(
              "div",
              {
                key: index + "cards",
                staticClass: "word-card-item",
                class: "lesson-" + _vm.lessonNum,
                style: { flex: "0 0 calc(" + 80 / _vm.containerStyle + "%)" },
              },
              [
                !item.isShowImg && (item.pinyin || item.hanzi || item.english)
                  ? _c(
                      "div",
                      {
                        staticClass: "frontage-content",
                        class: {
                          hover: item.currentNum == _vm.hoverId,
                          redCoverColor: item.bgColor == "redColor",
                          yellowCoverColor: item.bgColor == "yellowColor",
                          black: item.bgColor == "yellowColor",
                          "teacher-hover": item.currentNum === 0,
                          one: item.order == "one",
                          one2: item.order == "one2",
                          two: item.order == "two",
                          three: item.order == "three",
                          four: item.order == "four",
                          five: item.order == "five",
                          five2: item.order == "five2",
                          six: item.order == "six",
                          blue: item.blue,
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.clickImage(index, !item.isShowImg)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "frontage-area",
                            class: {
                              "long-text": item.longtext,
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "p",
                                {
                                  staticClass: "pinyin",
                                  class: [
                                    item.pinyinClass
                                      ? item.pinyinClass
                                      : "font-pinyin",
                                  ],
                                },
                                [_vm._v(" " + _vm._s(item.pinyin) + " ")]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass: "secp",
                                  class: [
                                    item.hanziClass
                                      ? item.hanziClass
                                      : "font-hanzi",
                                    item.pinyinWrap ? "wrapText" : "",
                                  ],
                                },
                                [_vm._v(" " + _vm._s(item.hanzi) + " ")]
                              ),
                              _c(
                                "p",
                                {
                                  class: [
                                    item.bgColor == "yellowColor" ? "secp" : "",
                                    item.englishClass
                                      ? item.englishClass
                                      : "font-english",
                                  ],
                                },
                                [_vm._v(" " + _vm._s(item.english) + " ")]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                item.isShowImg && (item.pinyin || item.hanzi || item.english)
                  ? _c(
                      "div",
                      {
                        staticClass: "contray-content one",
                        class: {
                          hover: item.currentNum == _vm.hoverId,
                          redColor: item.bgColor == "redColor",
                          yellowColor: item.bgColor == "yellowColor",
                          "teacher-hover": item.currentNum === 0,
                          one: item.order == "one",
                          one2: item.order == "one2",
                          two: item.order == "two",
                          three: item.order == "three",
                          four: item.order == "four",
                          five: item.order == "five",
                          five2: item.order == "five2",
                          six: item.order == "six",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.clickImage(index, !item.isShowImg)
                          },
                          mouseenter: function ($event) {
                            _vm.getWordsId(item, (_vm.isHover = true), index)
                          },
                          mouseleave: function ($event) {
                            _vm.getWordsId(item, (_vm.isHover = false), index)
                          },
                        },
                      },
                      [
                        !item.isShowImg
                          ? _c("img", { attrs: { src: item.image, alt: "" } })
                          : _c("img", { attrs: { src: item.image, alt: "" } }),
                      ]
                    )
                  : _vm._e(),
              ]
            )
          }),
          0
        ),
        _vm.wordCardList && _vm.wordCardList.length
          ? _c("CommonButton", {
              on: { shuffleClickEvent: _vm.handleShuffleClickEvent },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }