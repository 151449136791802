var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drag-fruits-game-container" }, [
    _c(
      "div",
      { staticClass: "game-content" },
      [
        _c("Star", {
          attrs: {
            totalStarNumber: _vm.optionList.length,
            currentIndex: _vm.starNum,
          },
        }),
        _c(
          "div",
          { staticClass: "drag-area" },
          _vm._l(_vm.optionList[_vm.currentIndex], function (item, index) {
            return _c("img", {
              key: index,
              staticClass: "drag-item",
              class: {
                notShow: item.id === _vm.dragId,
                notDisplay: item.isNotShow,
              },
              attrs: { src: item.image, draggable: "true" },
              on: {
                dragstart: function ($event) {
                  return _vm.startDrag($event, item)
                },
                drop: function ($event) {
                  $event.stopPropagation()
                  return _vm.Sdrop($event, index)
                },
                dragend: function ($event) {
                  return _vm.endDrag()
                },
              },
            })
          }),
          0
        ),
        _c("div", { staticClass: "right-box" }, [
          _c("div", { staticClass: "right-content" }, [
            _c("div", {
              staticClass: "drop-area",
              on: {
                drop: function ($event) {
                  $event.stopPropagation()
                  return _vm.dropToImg($event)
                },
                dragover: function ($event) {
                  $event.stopPropagation()
                  return _vm.allowDrop($event)
                },
              },
            }),
            _c("div", { staticClass: "sentence-area" }, [
              _c(
                "p",
                {
                  staticClass: "pinyin font-pinyin-medium font-pinyin-medium1",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.gameList && _vm.gameList.length
                          ? _vm.gameList[_vm.currentIndex].pinyin
                          : ""
                      ) +
                      " "
                  ),
                ]
              ),
              _c("p", { staticClass: "font-hanzi-medium font-hanzi-medium1" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.gameList && _vm.gameList.length
                        ? _vm.gameList[_vm.currentIndex].hanzi
                        : ""
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "correct-img" }, [
              _c("img", {
                class: { showImg: _vm.isShowCorrectImg },
                attrs: {
                  src:
                    _vm.gameList && _vm.gameList.length
                      ? _vm.gameList[_vm.currentIndex].correctImg
                      : "",
                },
              }),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }