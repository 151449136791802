var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "game-practice-word-container" },
    [
      _c(
        "div",
        { staticClass: "word-content" },
        [
          _c("SceneSwitchThumbnails", {
            attrs: { buttons: _vm.buttonList, numBers: _vm.currentIndex },
            on: { changeTheNumbers: _vm.changeTheNumbers },
          }),
          _c("div", { staticClass: "choose-hanzi-box" }, [
            _vm.chooseHanziList && _vm.chooseHanziList.length
              ? _c("div", { staticClass: "answer-area" }, [
                  _vm.chooseHanziList && _vm.chooseHanziList.length
                    ? _c("div", { staticClass: "answer-img" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.chooseHanziList[_vm.currentIndex].bgImg,
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.isShowAllWords
                    ? _c(
                        "div",
                        { staticClass: "answer-hanzi-area" },
                        _vm._l(
                          _vm.chooseHanziList[_vm.currentIndex].hanziRightList,
                          function (item, index) {
                            return _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.chooseHanziList &&
                                      _vm.chooseHanziList[_vm.currentIndex]
                                        .hanziRightList &&
                                      _vm.chooseHanziList[_vm.currentIndex]
                                        .hanziRightList.length,
                                    expression:
                                      "\n              chooseHanziList &&\n              chooseHanziList[currentIndex].hanziRightList &&\n              chooseHanziList[currentIndex].hanziRightList.length\n            ",
                                  },
                                ],
                                key: index + "right",
                                staticClass: "hanzi-item",
                                class: {
                                  active: _vm.insertIndex === index,
                                  hasInserted:
                                    _vm.rightHanziIndexArr.indexOf(index + 1) >
                                    -1,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "pinyin font-pinyin-medium" },
                                  [_vm._v(_vm._s(item.pinyin))]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "font-hanzi-medium" },
                                  [_vm._v(_vm._s(item.hanzi))]
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _c("div", { staticClass: "answer-right-hanzi-area" }, [
                        _c("div", { staticClass: "right-hanzi-box" }, [
                          _c(
                            "span",
                            { staticClass: "pinyin font-pinyin-medium" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.chooseHanziList[_vm.currentIndex]
                                    .wordsPinyin
                                )
                              ),
                            ]
                          ),
                          _c("span", { staticClass: "font-hanzi-medium" }, [
                            _vm._v(
                              _vm._s(
                                _vm.chooseHanziList[_vm.currentIndex].wordsHanzi
                              )
                            ),
                          ]),
                        ]),
                      ]),
                ])
              : _vm._e(),
            _vm.chooseHanziList && _vm.chooseHanziList.length
              ? _c(
                  "div",
                  { staticClass: "choose-hanzi-area" },
                  _vm._l(
                    _vm.chooseHanziList[_vm.currentIndex].hanziChooseList,
                    function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index + "choose",
                          staticClass: "hanzi-item",
                          class: {
                            dispear: _vm.choosedHanziArr.indexOf(index) > -1,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleChooseHanzi(item.id, index)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "pinyin font-pinyin-medium" },
                            [_vm._v(_vm._s(item.pinyin))]
                          ),
                          _c("span", { staticClass: "font-hanzi-medium" }, [
                            _vm._v(_vm._s(item.hanzi)),
                          ]),
                        ]
                      )
                    }
                  ),
                  0
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c("CommonButton", {
        on: { shuffleClickEvent: _vm.handleShuffleClickEvent },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }