<template>
  <div class="game-choose-stroke-by-hanzi-container">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @click.native="nextPage"
    ></PageButton>
    <div class="game-content">
      <img
        src="@/assets/img/03-Backgrounds/background-practice.svg"
        alt=""
        class="bg-img"
      />
      <div class="game-box">
        <div class="background">
          <div class="game-area">
            <img
              ref="imgBox"
              class="puzzle-bg-img"
              id="puzzlebackground"
              :src="bgImg"
              :style="{ width: imgWidth + 'px' }"
            />
            <div
              class="map-area"
              :style="{
                width: imgWidth + 1 + 'px',
                height: imgWidth + 1 + 'px'
              }"
            >
              <div class="map-box">
                <div
                  class="col-item"
                  v-for="(item, index) in mapList"
                  :key="index + 'col'"
                >
                  <div
                    class="row-item"
                    v-for="(ele, indexSon) in item"
                    :key="indexSon + 'row'"
                    :class="{
                      noPointerEvents: ele.isAddStyle
                    }"
                    @drop.stop="
                      dropToImg(
                        $event,
                        JSON.parse(JSON.stringify(ele.type)),
                        ele.id
                      )
                    "
                    @dragover.stop="allowDrop($event)"
                  ></div>
                </div>
              </div>

              <div class="hanzi-img-area">
                <template v-for="(item, index) in chineseList">
                  <transition name="el-fade-in" :key="index">
                    <img
                      :src="item.imageStartURL"
                      alt=""
                      :key="index"
                      v-if="item.isShow && !isReviewGame"
                    />
                  </transition>
                </template>

                <template v-for="(item, index) in chineseList">
                  <transition-group name="el-fade-in" :key="index">
                    <img
                      v-for="(ele, indexSon) in item.strokeImgList"
                      alt=""
                      :key="indexSon"
                      :src="ele.strokeImg"
                      v-show="ele.isShow"
                    />
                  </transition-group>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="strokeList">
          <div class="stroke-box" :class="{ reviewGame: isReviewGame }">
            <div
              v-for="(item, index) in strokeList"
              :key="index"
              class="stroke-item"
            >
              <img
                class="strokeImage"
                :src="item.image"
                draggable="true"
                @dragstart.stop="dragStroke($event, item.type)"
                @drop.stop="Sdrop($event)"
                @dragend="dragendOver"
                :class="{ notShow: strokeType === item.type }"
              />
              <div v-if="isReviewGame" class="percent">
                <span> {{ item.currentSum }} / {{ item.total }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageButton from '@/components/Course/Interaction/Button/pageButton.vue'
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  name: 'DragStrokeToImgGame',
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          bgImg: '',
          strokeList: [],
          firstHanziStrokeImgList: [],
          secondHanziStrokeImgList: [],
          thirdHanziStrokeImgList: [],
          fourthHanziStrokeImgList: [],
          fifthHanziStrokeImgList: [],
          sixthHanziStrokeImgList: [],
          seventhHanziStrokeImgList: [],
          eighthHanziStrokeImgList: [],
          ninthHanziStrokeImgList: []
        }
      }
    }
  },
  components: {
    PageButton
  },
  data() {
    return {
      localData: {
        bgImg: require('@/assets/img/16-GAMES/G55-bingo-strokes/lesson-24-bingo/background.svg'),
        mapList: [
          [
            { index: 1, id: 1, type: [0, 0, 1] },
            { index: 4, id: 4, type: [0, 0] },
            { index: 7, id: 7, type: [2] }
          ],
          [
            { index: 2, id: 2, type: [0, 0, 0] },
            { index: 5, id: 5, type: [0, 1] },
            { index: 8, id: 8, type: [1, 2] }
          ],
          [
            { index: 3, id: 3, type: [0, 1] },
            { index: 6, id: 6, type: [2] },
            { index: 9, id: 9, type: [0] }
          ]
        ],
        strokeList: [
          {
            type: 0,
            image: require('@/assets/img/16-GAMES/G55-bingo-strokes/lesson-24-bingo/blue.svg'),
            total: 10,
            currentSum: 0
          },
          {
            type: 1,
            image: require('@/assets/img/16-GAMES/G55-bingo-strokes/lesson-24-bingo/red.svg'),
            total: 4,
            currentSum: 0
          },
          {
            type: 2,
            image: require('@/assets/img/16-GAMES/G55-bingo-strokes/lesson-24-bingo/yellow.svg'),
            total: 3,
            currentSum: 0
          }
        ],
        chineseList: [
          {
            id: 1,
            strokeImgList: [
              {
                strokeImg: require('@/assets/img/16-GAMES/G55-bingo-strokes/lesson-24-bingo/blue-1.svg'),
                type: 0,
                isShow: false
              },
              {
                strokeImg: require('@/assets/img/16-GAMES/G55-bingo-strokes/lesson-24-bingo/blue-2.svg'),
                type: 0,
                isShow: false
              },
              {
                strokeImg: require('@/assets/img/16-GAMES/G55-bingo-strokes/lesson-24-bingo/red-1.svg'),
                type: 1,
                isShow: false
              }
            ],
            strokeSum: 0,
            isShow: true
          },
          {
            id: 2,
            strokeImgList: [
              {
                strokeImg: require('@/assets/img/16-GAMES/G55-bingo-strokes/lesson-24-bingo/blue-3.svg'),
                type: 0,
                isShow: false
              },
              {
                strokeImg: require('@/assets/img/16-GAMES/G55-bingo-strokes/lesson-24-bingo/blue-4.svg'),
                type: 0,
                isShow: false
              },
              {
                strokeImg: require('@/assets/img/16-GAMES/G55-bingo-strokes/lesson-24-bingo/blue-5.svg'),
                type: 0,
                isShow: false
              }
            ],
            strokeSum: 0,
            isShow: true
          },
          {
            id: 3,
            strokeImgList: [
              {
                strokeImg: require('@/assets/img/16-GAMES/G55-bingo-strokes/lesson-24-bingo/blue-6.svg'),
                type: 0,
                isShow: false
              },
              {
                strokeImg: require('@/assets/img/16-GAMES/G55-bingo-strokes/lesson-24-bingo/red-2.svg'),
                type: 1,
                isShow: false
              }
            ],
            strokeSum: 0,
            isShow: true
          },
          {
            id: 4,
            strokeImgList: [
              {
                strokeImg: require('@/assets/img/16-GAMES/G55-bingo-strokes/lesson-24-bingo/blue-7.svg'),
                type: 0,
                isShow: false
              },
              {
                strokeImg: require('@/assets/img/16-GAMES/G55-bingo-strokes/lesson-24-bingo/blue-8.svg'),
                type: 0,
                isShow: false
              }
            ],
            strokeSum: 0,
            isShow: true
          },
          {
            id: 5,
            strokeImgList: [
              {
                strokeImg: require('@/assets/img/16-GAMES/G55-bingo-strokes/lesson-24-bingo/blue-9.svg'),
                type: 0,
                isShow: false
              },
              {
                strokeImg: require('@/assets/img/16-GAMES/G55-bingo-strokes/lesson-24-bingo/red-3.svg'),
                type: 1,
                isShow: false
              }
            ],
            strokeSum: 0,
            isShow: true
          },
          {
            id: 6,
            strokeImgList: [
              {
                strokeImg: require('@/assets/img/16-GAMES/G55-bingo-strokes/lesson-24-bingo/yellow-1.svg'),
                type: 2,
                isShow: false
              }
            ],
            strokeSum: 0,
            isShow: true
          },
          {
            id: 7,
            strokeImgList: [
              {
                strokeImg: require('@/assets/img/16-GAMES/G55-bingo-strokes/lesson-24-bingo/yellow-2.svg'),
                type: 2,
                isShow: false
              }
            ],
            strokeSum: 0,
            isShow: true
          },
          {
            id: 8,
            strokeImgList: [
              {
                strokeImg: require('@/assets/img/16-GAMES/G55-bingo-strokes/lesson-24-bingo/yellow-3.svg'),
                type: 2,
                isShow: false
              },
              {
                strokeImg: require('@/assets/img/16-GAMES/G55-bingo-strokes/lesson-24-bingo/red-4.svg'),
                type: 1,
                isShow: false
              }
            ],
            strokeSum: 0,
            isShow: true
          },
          {
            id: 9,
            strokeImgList: [
              {
                strokeImg: require('@/assets/img/16-GAMES/G55-bingo-strokes/lesson-24-bingo/blue-10.svg'),
                type: 0,
                isShow: false
              }
            ],
            strokeSum: 0,
            isShow: true
          }
        ],
        totalLevel: 3
      },
      answersFoundSum: 0,
      bgImg: '',
      isReviewGame: true,
      mapList: [],
      strokeList: [],
      chineseList: [],
      strokeType: null,
      isLastStep: false,
      isShowStroke: false,
      dispearIndexList: [],
      currentId: 0,

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'look',
            startNumberRange: 1,
            endNumberRange: 100
          }
        ]
      },

      imgDom: null,
      imgWidth: null,
      dropToImgSocketInfo: {},
      dragStrokeSocketInfo: {},
      dragoverStrokeSocketInfo: {},
      currentHanziStrokeTypeList: [],
      totalLevel: 0,
      currentLevel: 0
    }
  },
  watch: {
    /**
     * @description: 监听componentProps属性的变化
     * @return {*}
     */
    componentProps: {
      handler(newVal) {
        const { isTemplatePrview } = newVal
        if (isTemplatePrview) return
        this.initData()
        this.bgImg = newVal.bgImg
        this.totalLevel = 0
        this.currentLevel = 0
        this.strokeList = newVal.strokeList.map((item, index) => {
          return {
            type: index,
            image: item[0].value,
            total: Number(item[1].value),
            currentSum: 0
          }
        })
        // console.log(this.strokeList, 'newVal')
        this.totalLevel = this.strokeList.length
        this.chineseList = []
        // 只处理特定的九个属性
        const relevantKeys = [
          'firstHanziStrokeImgList',
          'secondHanziStrokeImgList',
          'thirdHanziStrokeImgList',
          'fourthHanziStrokeImgList',
          'fifthHanziStrokeImgList',
          'sixthHanziStrokeImgList',
          'seventhHanziStrokeImgList',
          'eighthHanziStrokeImgList',
          'ninthHanziStrokeImgList'
        ]
        relevantKeys.forEach((key, index) => {
          const largeArray = newVal[key]
          if (Array.isArray(largeArray)) {
            const hanziObject = {
              id: index + 1,
              strokeSum: 0,
              isShow: true,
              strokeImgList: largeArray.map((subArray) => {
                return {
                  strokeImg: subArray[0].value,
                  type: Number(subArray[1].value) - 1,
                  isShow: false
                }
              })
            }
            this.chineseList.push(hanziObject)
          }
          // console.log(this.chineseList, 'this.chineseList')
          const desiredOrder = [
            [1, 4, 7],
            [2, 5, 8],
            [3, 6, 9]
          ]
          this.mapList = []
          desiredOrder.forEach((group, groupIndex) => {
            this.mapList[groupIndex] = []
            group.forEach((id) => {
              // 从 chineseList 中找到对应 id 的对象
              const chineseObj = this.chineseList.find((item) => item.id === id)

              if (chineseObj) {
                // 获取 strokeImgList 中的 type 数组
                const typeArray = chineseObj.strokeImgList.map(
                  (item) => item.type
                )
                // 创建对象，index 和 id 相同，type 为对应的 type 数组
                const mapObj = {
                  index: chineseObj.id,
                  id: chineseObj.id,
                  type: typeArray
                }
                // 将对象添加到 mapList 对应的 group 中
                this.mapList[groupIndex].push(mapObj)
              }
            })
          })
          // console.log(this.mapList)
        })
      },
      immediate: true,
      deep: true
    },
    dropToImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004501,
          data: { value },
          text: 'DragStrokeToImgGame drag socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    dragStrokeSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004502,
          data: { value },
          text: 'DragStrokeToImgGame drop socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    dragoverStrokeSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004503,
          data: { value },
          text: 'DragStrokeToImgGame dropover socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    }
  },
  mounted() {
    setTimeout(() => {
      this.imgDom = this.$refs.imgBox
      this.imgWidth = parseInt(
        Math.ceil(this.imgDom.getBoundingClientRect().width)
      )

      this.imgDom.getBoundingClientRect().width = 55
    }, 50)
    this.$bus.$on('dropStrokeEvent', ({ type, id }) => {
      this.handleDragToImg(type, id, true)
    })
    this.$bus.$on('dragToImgEvent', ({ type }) => {
      this.dragStroke('socket', type, true)
    })
    this.$bus.$on('dragoverImgEvent', () => {
      this.dragendOver('socket', true)
    })
    window.addEventListener('resize', this.updateElementPosition)
  },
  destroyed() {
    window.removeEventListener('resize', this.updateElementPosition)
  },
  beforeDestroy() {
    this.$bus.$off('dropStrokeEvent')
    this.$bus.$off('dragToImgEvent')
    this.$bus.$off('dragoverImgEvent')
  },
  methods: {
    initData() {},
    /**
     * @description: 监听窗口大小变化，更新imgBox的宽度
     * @return {*}
     */
    updateElementPosition() {
      this.imgDom = this.$refs.imgBox
      this.imgWidth = parseInt(
        Math.ceil(this.imgDom.getBoundingClientRect().width)
      )
    },
    /**
     * @description: 拖拽结束时，触发的事件
     * @return {*}
     */
    dragendOver(event, isFromSocket = false) {
      // setTimeout(() => {}, 1500);
      if (!isFromSocket) {
        this.dragoverStrokeSocketInfo = {
          value: Math.random()
        }
      }
      this.strokeType = null
    },
    /**
     * @description: 进入下一关
     * @param {*} id
     * @param {*} type
     * @return {*}
     */
    nextPage() {
      this.$bus.$emit('nextButton', true)
    },
    /**
     * @description: 拖拽到图片上时，比较当前的笔画类型和拖放的笔画类型是否相同，如果相同，则触发handleDropEvent事件
     * @param {*} type
     * @param {*} id
     * @param {*} isFromSocket
     * @return {*}
     */
    handleDragToImg(type, id, isFromSocket = false) {
      if (!isFromSocket) {
        this.dropToImgSocketInfo = {
          type,
          id,
          value: Math.random()
        }
      }
      // console.log(this.strokeType, type, id)
      if (type.indexOf(this.strokeType) > -1) {
        this.currentId = id
        playCorrectSound(true, false)
        this.handleDropEvent(id, this.strokeType)
      } else {
        playCorrectSound(false)
      }
    },
    /**
     * @description: 拖拽开始时，触发的事件,把当前的笔画类型赋值给this.strokeType
     * @param {*} event
     * @param {*} type
     * @param {*} isFromSocket
     * @return {*}
     */
    dragStroke(event, type, isFromSocket = false) {
      if (!isFromSocket) {
        this.dragStrokeSocketInfo = {
          type,
          value: Math.random()
        }
      }
      this.strokeType = type
      // console.log(type)
    },
    /**
     * @description: 将拖放事件处理逻辑应用到图像上
     * @param {*} event
     * @param {*} type
     * @param {*} id
     * @return {*}
     */
    dropToImg(event, type, id) {
      this.handleDragToImg(type, id)
      event.preventDefault()
    },
    /**
     * @description: 拖拽开始时阻止拖放事件的默认动作
     * @param {*} event
     * @param {*} type
     * @return {*}
     */
    Sdrop(event) {
      event.preventDefault()
    },
    /**
     * @description: 拖拽结束时阻止拖放事件的默认动作
     * @param {*} event
     * @return {*}
     */
    allowDrop(event) {
      event.preventDefault()
    },
    /**
     * @description: 处理拖放事件,如果type匹配,让对应图片显示
     * @param {*} id
     * @param {*} type
     * @return {*}
     */
    handleDropEvent(id, type) {
      // console.log(id, type)
      this.chineseList.forEach((item) => {
        if (item.id === id) {
          item.strokeImgList.forEach((ele) => {
            if (ele.type === type && !item.flag) {
              if (ele.isShow) return
              ele.isShow = true
              item.flag = true
              item.strokeSum++
              // console.log(item.strokeSum)
              this.handleDefaultEvent(id, type)
              this.handleEndSingleStroke(type)
            }
          })
          item.flag = false
        }
      })
    },
    /**
     * @description: 如果type匹配,将mapList数组中对应的type元素删除
     * @param {*} id
     * @param {*} type
     * @return {*}
     */

    handleDefaultEvent(id, type) {
      this.mapList.forEach((j) => {
        j.forEach((k) => {
          if (k.id === id) {
            // console.log(k.type)
            const typeIndex = k.type.findIndex((value) => value === type)
            k.type.splice(typeIndex, 1)
            if (!k.type.length) {
              k.isAddStyle = true
            }
          }
        })
      })
    },
    /**
     * @description: 拖拽结束后比较当前笔画的数量是否达到要求, 达到要求则播放音效
     * @param {*} type
     * @return {*}
     */
    handleEndSingleStroke(type) {
      this.strokeList.forEach((item) => {
        if (item.type === type) {
          item.currentSum++
          if (item.currentSum === item.total) {
            this.currentLevel += 1
            startConfetti()
            playCorrectSound()
          }
        }
      })
      if (this.totalLevel === this.currentLevel) {
        this.isLastStep = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.game-choose-stroke-by-hanzi-container {
  border-radius: 58px;
  position: relative;
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .bg-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 58px;
    }
    .game-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      .background {
        flex: 6;
        display: flex;
        justify-content: center;
        align-items: center;
        .game-area {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          .puzzle-bg-img {
            width: 70%;
            // height: 65%;
            // object-fit: cover;
            // border-radius: 58px;
            min-width: 400px;

            // border-bottom: 30px solid #cd4c3f;
            // border-bottom-left-radius: 30px;
            // border-bottom-right-radius: 30px;
          }
          .map-area {
            position: absolute;
            margin: auto;
            display: flex;
            top: 0;

            .map-box {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              .col-item {
                z-index: 100;
                flex: 1;
                display: flex;
                flex-direction: column;
                .row-item {
                  flex: 1;
                  // background: greenyellow;
                }
                .noPointerEvents {
                  pointer-events: none;
                }
              }
            }

            .hanzi-img-area {
              width: 100%;
              height: 100%;
              img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
              }
            }
          }
        }
      }
      .strokeList {
        flex: 3;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .stroke-box {
          height: 50%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .strokeImage {
            cursor: pointer;
            display: inline-block;
            width: 80%;
            -webkit-user-drag: auto;
          }
          .notShow {
            opacity: 0;
          }
        }
        .reviewGame {
          height: 60%;
          display: flex;
          justify-content: space-around;
          .stroke-item {
            width: 80%;
            background: #fff;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .strokeImage {
            width: 50%;
            flex: 1;
          }
          .percent {
            flex: 1;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #224e96;
            font-size: 4vh;
          }
        }
      }
    }
  }
}
</style>
