var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "train-game-container" }, [
    _c(
      "div",
      { staticClass: "train-game-content" },
      [
        _c("Star", {
          attrs: {
            totalStarNumber: _vm.detailImg.length,
            currentIndex: _vm.currentStep,
          },
        }),
        _vm.titleInfo && Object.keys(_vm.titleInfo).length
          ? _c("div", { staticClass: "right-gules" }, [
              _c(
                "div",
                { staticClass: "pinyin font-pinyin-medium stroke-pinyin" },
                [_vm._v(" " + _vm._s(_vm.titleInfo.strokePinyin) + " ")]
              ),
              _c("div", { staticClass: "target-stroke-hanzi" }, [
                _vm._v(" " + _vm._s(_vm.titleInfo.strokeHanzi) + " "),
              ]),
            ])
          : _vm._e(),
        _c("div", { ref: "trainBox", staticClass: "header-img-box" }, [
          _c("div", { staticClass: "header-img-content" }, [
            _c("img", { attrs: { src: _vm.headerBgImg, alt: "" } }),
            _c(
              "div",
              { ref: "strokeTarinContent", staticClass: "hanzi-tarin-content" },
              _vm._l(_vm.detailImg, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index + "hanziStroke",
                    staticClass: "typeface-picture",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "block",
                        on: {
                          click: function ($event) {
                            return _vm.clickedStroke(item.index, index)
                          },
                        },
                      },
                      [_c("img", { attrs: { src: item.img, alt: "" } })]
                    ),
                    Number(_vm.componentProps.bgImgAndShadowImg) === 1
                      ? _c("div", [
                          index !== 0 && _vm.shadowImg
                            ? _c(
                                "div",
                                {
                                  staticClass: "cloud",
                                  class: {
                                    fadeout: _vm.currentCloudIndex >= index,
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: { src: _vm.shadowImg, alt: "" },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _c("div", [
                          index !== 0 && _vm.shadowImg
                            ? _c(
                                "div",
                                {
                                  staticClass: "cloud1",
                                  class: {
                                    fadeout: _vm.currentCloudIndex >= index,
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: { src: _vm.shadowImg, alt: "" },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }