<template>
  <div class="choose-pic-container">
    <div class="choose-content">
      <div class="back-ground-img-area">
        <img :src="bgImg" alt="" />
      </div>
      <div class="pic-words-area" v-if="isShowshadow">
        <div class="pic-box">
          <div
            class="pic-item"
            v-for="(item, index) in sentenceList"
            :key="index"
          >
            <img :src="item.bgImg" alt="" v-if="index === currentId - 1" />
          </div>
        </div>
      </div>
      <div class="game-area">
        <div class="pic-area" @click="handleClickPic(null)"></div>
        <div class="side-menu-area">
          <div class="menu-item" v-for="(item, index) in picList" :key="index">
            <img
              :src="
                showIdList.indexOf(item.id) > -1
                  ? item.menuImg
                  : item.menuGrayImg
              "
              alt=""
              @click="handleClickPic(item.id)"
              :class="{ hasClick: showIdList.indexOf(item.id) > -1 }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          picMenuList: [],
        }
      }
    }
  },
  data() {
    return {
      bgImg: '',
      sentenceList: [

      ],
      picList: [
    
      ],
      showIdList: [],
      lock: true,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'speak',
            startNumberRange: 0,
            endNumberRange: 100
          }
        ]
      },
      dragId: 0,
      step: 0,
      showHnaziId: 0,
      dropAvatarSocketInfo: {},
      clickSocketInfo: {},
      currentId: 0,
      isShowshadow: false,
      currentIndex: 0,
      localData: {
        bgImg:
        require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/background-board.svg"),
        sentenceList: [
        {
            id: 1,
            bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/image-1.svg"),
          },
          {
            id: 2,
            bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/image-2.svg"),
          },
          {
            id: 3,
            bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/image-3.svg"),
          },
          {
            id: 4,
            bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/image-4.svg"),
          },
      ],
      picList: [
        {
            id: 1,
            menuImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/menu-image-1.svg"),
            menuGrayImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/menu-image-1-bw.svg"),
          },
          {
            id: 2,
            menuImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/menu-image-2.svg"),
            menuGrayImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/menu-image-2-bw.svg"),
          },
          {
            id: 3,
            menuImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/menu-image-3.svg"),
            menuGrayImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/menu-image-3-bw.svg"),
          },
          {
            id: 4,
            menuImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/menu-image-4.svg"),
            menuGrayImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-12/menu-image-4-bw.svg"),
          },
      ],
      }
    }
  },
  watch: {
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20049,
          data: { value },
          text: 'ChoosePicBySentenceGame 点击menu socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        this.initData()
      },
      deep: true
    }
  },
  mounted() {
    // this.initData()
    this.$bus.$on('ClickSentenceMenu', ({ id }) => {
      this.handleClickPic(id, true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('ClickSentenceMenu')
  },
  methods: {
    initData() {
        // console.log(this.componentProps, '---------')
      this.sentenceList = []
      this.picList = []
      if (
        this.componentProps.picMenuList &&
        this.componentProps.picMenuList.length
      ) {
        this.componentProps.picMenuList.forEach((item, index) => {
          this.sentenceList.push({
            id: index + 1,
            bgImg: item[0].value
          })
          this.picList.push({
            id: index + 1,
            menuImg: item[2].value,
            menuGrayImg: item[1].value
          })
        })
      }
    },
    handleClickPic(id, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickSocketInfo = {
          id,
          value: Math.random()
        }
      }
      if (id) {
        this.lock = true
        // console.log(id)
        this.currentId = id
        this.isShowshadow = true
        playCorrectSound(true, false)
        if (this.showIdList.indexOf(id) === -1) {
          this.showIdList.push(id)

          this.currentIndex++
        }
      } else {
        this.lock = false
        if (this.currentIndex === this.sentenceList.length) {
          startConfetti()
          playCorrectSound()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.choose-pic-container {
  width: 100%;
  height: 100%;
  line-height: 1;
  .choose-content {
    width: 100%;
    height: 100%;
    position: relative;
    background: #f7f3df;
    border-radius: 48px;
    .back-ground-img-area {
      width: 100%;
      height: 100%;
      img {
        border-radius: 48px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .game-area,
    .pic-words-area {
      position: absolute;
      top: 0;
    }
    .pic-words-area {
      width: 100%;
      height: 100%;
      .pic-box,
      .words-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .pic-box {
        background-color: rgba(159, 150, 150, 0.4);
        border-radius: 48px;
        .pic-item {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          border-radius: 58px;
          img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 48px;
          }
        }
      }
    }
    .game-area {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;

      .pic-area {
        width: 100%;
        height: 100%;
        position: relative;
        flex: 4;
        cursor: pointer;
      }
      .side-menu-area {
        height: 70%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .menu-item {
          width: 80%;
          height: 22%;
          margin-top: 5%;

          img {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            border: 10px solid #cd4c3f;
            border-radius: 20px;
            object-fit: cover;

            cursor: pointer;
          }
          .hasClick {
            border: 10px solid #f4d737;
          }
        }
      }
    }
  }
}
</style>
