var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-choose-pic-container" }, [
    _c(
      "div",
      { staticClass: "game-card-content" },
      [
        Object.keys(_vm.titleInfo).length
          ? _c("div", { staticClass: "title-area" }, [
              _c("div", { staticClass: "title" }, [
                _c(
                  "div",
                  { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                  [_vm._v(" " + _vm._s(_vm.titleInfo.pinyin) + " ")]
                ),
                _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
                  _vm._v(" " + _vm._s(_vm.titleInfo.hanzi) + " "),
                ]),
              ]),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "cardCont" },
          _vm._l(_vm.imgList, function (item, index) {
            return _c("div", { key: index, staticClass: "objects-card" }, [
              _c(
                "div",
                {
                  staticClass: "card",
                  class: {
                    isFinishMatchOne: item.type === 1,
                    isFinishMatchTwo: item.type === 2 || item.type === 4,
                    isFinishMatchThree: item.type === 3,
                    cardIsActive: item.cardActive == true,
                    style1: index % 2 == 0,
                    style2: index % 2 != 0,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.foundAnswer(index, item)
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "objectImage",
                    attrs: { src: item.image },
                  }),
                ]
              ),
            ])
          }),
          0
        ),
        _c("Star", {
          attrs: {
            totalStarNumber: _vm.starNum,
            currentIndex: _vm.answersfound,
          },
        }),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isMatchDisplay === true,
                expression: "isMatchDisplay === true",
              },
            ],
            staticClass: "matchDisplay",
          },
          [
            _c("div", { staticClass: "objectDisplaycont" }, [
              _c("div", { staticClass: "objects" }, [
                _c("div", { staticClass: "card style1" }, [
                  _c("img", {
                    staticClass: "objectImage",
                    attrs: { src: _vm.objectDisplayFirst },
                  }),
                ]),
                _c("div", { staticClass: "card style2" }, [
                  _c("img", {
                    staticClass: "objectImage",
                    attrs: { src: _vm.objectDisplaySecond },
                  }),
                ]),
              ]),
            ]),
          ]
        ),
        _vm.answersfound === 0
          ? _c("CommonButton", {
              on: { shuffleClickEvent: _vm.handleShuffleClickEvent },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }