import { render, staticRenderFns } from "./67_MusicPage.vue?vue&type=template&id=259e390c&scoped=true&"
import script from "./67_MusicPage.vue?vue&type=script&lang=js&"
export * from "./67_MusicPage.vue?vue&type=script&lang=js&"
import style0 from "./67_MusicPage.vue?vue&type=style&index=0&id=259e390c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "259e390c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/usr/dev/staging/nihaokidsNew-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('259e390c')) {
      api.createRecord('259e390c', component.options)
    } else {
      api.reload('259e390c', component.options)
    }
    module.hot.accept("./67_MusicPage.vue?vue&type=template&id=259e390c&scoped=true&", function () {
      api.rerender('259e390c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/CoursewareEditor/components/GameAndPage/67_MusicPage.vue"
export default component.exports