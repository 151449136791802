<template>
  <div class="game-flip-card-container">
    <div class="game-flip-card-content">
      <!-- <div
        class="title-area"
        v-if="Object.keys(titleInfo).length"
        :class="'lesson-' + titleInfo.currentNum"
      >
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ titleInfo.hanzi }}
          </div>
        </div>
      </div> -->
      <div class="word-card-area">
        <div
          class="word-card-item"
          :class="'lesson-' + lessonNum"
          v-for="(item, index) in wordCardList"
          :key="index + 'cards'"
          :style="{ flex: `0 0 calc(${80 / containerStyle}%)` }"
        >
          <!-- 无图片 -->
          <div
            class="frontage-content"
            v-if="
              !item.isShowImg && (item.pinyin || item.hanzi || item.english)
            "
            :class="{
              hover: item.currentNum == hoverId,
              redCoverColor: item.bgColor == 'redColor',
              yellowCoverColor: item.bgColor == 'yellowColor',
              black: item.bgColor == 'yellowColor',
              'teacher-hover': item.currentNum === 0,
              one: item.order == 'one',
              one2: item.order == 'one2',
              two: item.order == 'two',
              three: item.order == 'three',
              four: item.order == 'four',
              five: item.order == 'five',
              five2: item.order == 'five2',
              six: item.order == 'six',
              blue: item.blue
            }"
            @click.stop="clickImage(index, !item.isShowImg)"
          >
            <div
              class="frontage-area"
              :class="{
                'long-text': item.longtext
              }"
            >
              <div>
                <p
                  class="pinyin"
                  :class="[item.pinyinClass ? item.pinyinClass : 'font-pinyin']"
                >
                  {{ item.pinyin }}
                </p>
                <p
                  class="secp"
                  :class="[
                    item.hanziClass ? item.hanziClass : 'font-hanzi',
                    item.pinyinWrap ? 'wrapText' : ''
                  ]"
                >
                  {{ item.hanzi }}
                </p>
                <p
                  :class="[
                    item.bgColor == 'yellowColor' ? 'secp' : '',
                    item.englishClass ? item.englishClass : 'font-english'
                  ]"
                >
                  {{ item.english }}
                </p>
              </div>
            </div>
          </div>
          <!-- 有图片 -->
          <div
            class="contray-content one"
            v-if="item.isShowImg && (item.pinyin || item.hanzi || item.english)"
            :class="{
              hover: item.currentNum == hoverId,
              redColor: item.bgColor == 'redColor',
              yellowColor: item.bgColor == 'yellowColor',
              'teacher-hover': item.currentNum === 0,
              one: item.order == 'one',
              one2: item.order == 'one2',
              two: item.order == 'two',
              three: item.order == 'three',
              four: item.order == 'four',
              five: item.order == 'five',
              five2: item.order == 'five2',
              six: item.order == 'six'
            }"
            @click="clickImage(index, !item.isShowImg)"
            @mouseenter="getWordsId(item, (isHover = true), index)"
            @mouseleave="getWordsId(item, (isHover = false), index)"
          >
            <img :src="item.image" alt="" v-if="!item.isShowImg" />
            <img :src="item.image" alt="" v-else />
          </div>
        </div>
      </div>
      <CommonButton
        @shuffleClickEvent="handleShuffleClickEvent"
        v-if="wordCardList && wordCardList.length"
      />
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
import CommonButton from '../GameComponent/CommonButton.vue'
export default {
  mixins: [templatePreviewMixin],
  components: {
    CommonButton
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          gameFlipList: []
        }
      }
    }
  },

  watch: {
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    },
    currentCardInfo: {
      handler(value) {
        if (!this.clickImageValue) {
          const data = {
            clickType: 20007,
            data: { value },
            text: 'wordCardsPage点击socket'
          }
          this.sendSocketInfo = { ...data }
          this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
        }
      },
      deep: true
    },
    shuffleSocketInfo: {
      handler(value) {
        if (!this.shuffleSocketValue) {
          const data = {
            clickType: 2001004,
            data: { value },
            text: 'DragGameBtn shuffleSocketValue'
          }
          this.$bus.$emit('kids_webSocket_sendInfo', data)
        }
      },
      deep: true
    }
  },
  mounted() {
    // this.dataInit()
    this.$bus.$on('clickSingleCard', ({ index, isShowImg }) => {
      this.clickImage(index, isShowImg, true)
    })
    this.$bus.$on('shuffleArray', ({ ramdomList }) => {
      this.wordCardList = []
      this.wordCardList = ramdomList

      this.shuffleSocketValue = true
    })
    this.$bus.$on('hoverSyllabusPage', ({ item, isHover }) => {
      if (isHover) {
        this.hoverId = item.currentNum
      } else {
        this.hoverId = null
      }
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickSingleCard')
    this.$bus.$off('shuffleArray')
    this.$bus.$off('hoverSyllabusPage')
  },
  computed: {
    containerStyle() {
      const numCards = this.wordCardList.length

      let columns = numCards

      if (numCards >= 9) {
        columns = 5
      } else if (numCards >= 7) {
        columns = 4
      } else if (numCards >= 5) {
        columns = 3
      } else if (numCards >= 4) {
        columns = 2
      }

      return columns
    }
  },
  data() {
    return {
      hoverId: null,
      wordCardList: [],
      steps: 0,
      lessonNum: null,
      clickImageValue: false,
      titleInfo: {},
      isLastStep: false,
      currentCardInfo: {
        index: null,
        isShowImg: null
      },
      localData: {
        wordCardList: [
          {
            bgColor: 'redColor',
            order: 'one',
            isShowImg: true,
            currentNum: 1,
            pinyin: 'xǐhuan',
            hanzi: '喜欢',
            english: 'to like',
            image:
              'http://test.nihaokids.com/img/lesson-41-xihuan-bubble.014207ce.svg'
          },
          {
            bgColor: 'redColor',
            order: 'three',
            isShowImg: true,
            currentNum: 3,
            pinyin: 'chī',
            hanzi: '吃',
            english: 'to eat',
            image:
              'http://test.nihaokids.com/img/lesson-41-chi-bubble.94c3c049.svg'
          },
          {
            bgColor: 'redColor',
            order: 'five',
            isShowImg: true,
            currentNum: 5,
            pinyin: 'ma',
            hanzi: '吗',
            english: '(question particle)',
            image:
              'http://test.nihaokids.com/img/lesson-41-ma-bubble.1a03f1a4.svg'
          },
          {
            bgColor: 'redColor',
            order: 'four',
            isShowImg: true,
            currentNum: 4,
            pinyin: 'píngguǒ',
            hanzi: '苹果',
            english: 'apple',
            image:
              'http://test.nihaokids.com/img/lesson-41-pingguo-bubble.da09c144.svg'
          },
          {
            bgColor: 'redColor',
            order: 'two',
            isShowImg: true,
            currentNum: 2,
            pinyin: '……吗？',
            hanzi: '... ma ?',
            english: 'a question particle',
            image:
              'http://test.nihaokids.com/img/lesson-41-card-ma.94d08049.svg',
            blue: true,
            longtext: true
          }
        ]
      },
      shuffleSocketInfo: {},
      shuffleSocketValue: null
    }
  },
  methods: {
    /**
     * @description: 乱序回调
     * @return {*}
     */
    handleShuffleClickEvent() {
      console.log('乱序')
      const orderList = this.shuffleArray(this.wordCardList)
      this.wordCardList = []
      this.wordCardList = orderList

      this.shuffleSocketValue = false

      this.shuffleSocketInfo = {
        ramdomList: this.wordCardList,
        ramdomIndex: Math.random()
      }
    },
    /**
     * @description: 数组乱序
     * @param {*} array
     * @return {*}
     */
    shuffleArray(array) {
      let currentIndex = array.length,
        temporaryValue,
        randomIndex

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1

        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
      }
      console.log(array)

      return array
    },
    initData() {
      this.wordCardList = []
      // console.log(this.componentProps.gameFlipList, '----------');
      if (
        this.componentProps.gameFlipList &&
        this.componentProps.gameFlipList.length
      ) {
        this.componentProps.gameFlipList.forEach((item, index) => {
          this.wordCardList.push({
            bgColor: item[4].value,
            isShowImg: true,
            pinyin: item[2].value,
            hanzi: item[1].value,
            english: item[3].value,
            image: item[0].value,
            currentNum: index + 1,
            order: this.getOrder()
          })
        })
      }
    },
    getWordsId(item, isHover, index) {
      // 获取hover元素的id
      const data = {
        clickType: 20004,
        data: {
          value: {
            item,
            isHover,
            index
          }
        },
        text: '测试SyllabusPagePage组件内hover事件socket'
      }
      this.$bus.$emit('kids_webSocket_sendInfo', data)
    },
    getOrder() {
      const index = Math.floor(Math.random() * 6)
      const list = ['one', 'two', 'three', 'four', 'five', 'six']
      return list[index]
    },
    clickImage(index, isShowImg, isFromSocket = false) {
      // console.log(index,isShowImg,isFromSocket);
      if (!isFromSocket) {
        this.clickImageValue = false
      } else {
        this.clickImageValue = true
      }
      if (!isShowImg) {
        playCorrectSound(true, false)
        ++this.steps
      } else {
        --this.steps
      }
      this.currentCardInfo.index = index
      this.currentCardInfo.isShowImg = isShowImg
      // this.$emit('changeDisplay', index, isShowImg)
      this.wordCardList[index].isShowImg = isShowImg
      this.isLastStep = this.steps === this.wordCardList.length
      if (this.isLastStep == true) {
        playCorrectSound()
        startConfetti()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.game-flip-card-container {
  height: 100%;
  width: 100%;
  line-height: 0;
  .game-flip-card-content {
    background: url('../../../../assets/img/03-Backgrounds/background-practice.svg')
      no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .title-area {
      z-index: 9;
      position: absolute;
      top: 0;
      left: 10px;
      top: 10%;
      width: 18%;
      height: 17%;
      background: #224e96;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      color: #fff;
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }
    }
    .word-card-area {
      width: 80%;
      height: 80%;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // flex-wrap: wrap;
      display: flex;
      flex-wrap: wrap;

      .word-card-item {
        // width: 29%;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        margin: 0 2%;
        .frontage-area {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          height: 70%;
          &.long-text {
            width: 100%;
            .secp {
              //to do : smaller font size
              font-size: 35px;
            }

            .wrapText {
              white-space: normal;
              line-height: 37px;
            }
          }
          .font-pinyin {
            font-size: 18px;
          }
          .font-hanzi {
            font-size: 28px;
          }
          .font-english-medium {
            line-height: 20px;
          }
          .font-english {
            font-size: 17px;
          }
        }
        .secp {
          line-height: 54px;
          white-space: nowrap;

          // very large screen
          font-size: 80px;
          @media screen and (max-width: 2200px) {
            // external screen
            // font-size: 60px;
            font-size: 48px;
          }
          @media screen and (max-width: 1500px) {
            // laptop
            font-size: 42px;
          }
          @media screen and (max-width: 1300px) {
            // ipad
            font-size: 38px;
          }
        }
        p {
          line-height: 30px;
        }
        .frontage-content {
          width: 100%;
          position: relative;
          height: 0;
          padding-bottom: 100%;
          background: #214e96;
          border-radius: 18px;
          text-align: center;
          color: #fff;
          margin-bottom: 10%;
          border-bottom: 15px solid #a53d32;
          cursor: pointer;
          &:hover {
            border-bottom: 15px solid #f6c752;
          }
        }
        .redCoverColor {
          background: #214e96;
          border-bottom-color: #a53d32;
        }
        .redColor {
          background: #ce4c3f;
          border-bottom-color: #a53d32;
        }
        .yellowCoverColor {
          background: #dec32c;
          border-bottom-color: #f4d737;
        }
        .yellowColor {
          background: #f4d737;
          border-bottom-color: #dec32c;
        }
        .contray-content {
          width: 100%;
          height: 0;
          padding-bottom: 100%;
          margin-bottom: 10%;
          // background: #ce4c3f;
          position: relative;
          border-radius: 18px;
          // border-bottom: 15px solid #a53d32;
          border-bottom-width: 15px;
          border-bottom-style: solid;
          vertical-align: middle;
          cursor: pointer;

          img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            margin-left: 20%;
            width: 60%;
          }
        }
        .hover {
          border-bottom: 15px solid #f6c752;
        }
        .one {
          transform: rotate(6deg);
        }
        .one2 {
          transform: rotate(-6deg);
          margin-top: 20%;
        }
        .two {
          transform: rotate(-2deg);
        }
        .three {
          transform: rotate(0deg);
        }
        .four {
          transform: rotate(7deg);
        }
        .five {
          transform: rotate(6deg);
        }
        .five2 {
          transform: rotate(6deg);
          margin-top: 20%;
        }
        .six {
          transform: rotate(-5deg);
        }
        .blue {
          background: #fff !important;
          color: #214e96 !important;
        }
        .black {
          background: #dec32c !important;
          color: #000 !important;
          font-size: 100px;
        }
      }
    }
  }
}
</style>
