<template>
  <div class="words-card-container">
    <div class="words-card-page-box">
      <Star :totalStarNumber="wordCardList.length" :currentIndex="starNum" />
      <div class="title-area" v-if="componentProps.titlePy || componentProps.titlehanzi">
        <div class="title">
          <div
            class="title-pinyin pinyin font-pinyin-medium"
          >
            {{ componentProps.titlePy }}
          </div>
          <div
            class="title-hanzi font-hanzi-medium"
          >
            {{ componentProps.titlehanzi }}
          </div>
        </div>
      </div>
      <div class="word-cards-content">
        <div
          class="word-card-item"
          :class="'lesson-' + 11"
          v-for="(item, index) in wordCardList"
          :key="index + 'cards'"
        >
          <div
            class="contray-content"
            :class="{
              redColor: item.Showing,
              blueColor: !item.Showing,
              'card-1': index === 0,
              'card-2': index === 1,
              'card-3': index === 2,
              'card-4': index === 3,
              'card-5': index === 4
            }"
            @click.once="clickImage(index)"
          >
            <img :src="item.Showing ? item.imgShow : item.image" alt="" />
          </div>

          <div class="words-box">
            <div
              class="hanzi-item"
              v-for="(item, index) in wordCardList"
              :key="index"
              v-show="showHnaziIndex === index"
            >
              <div class="pinyin sentence-hanzi font-pinyin-medium">
                {{ item.pinyin }}
              </div>
              <div class="sentence-hanzi font-hanzi-medium">
                {{ item.hanzi }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Star from '@/components/Course/GamePage/Star'
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    Star
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          allCards: []
        }
      }
    }
  },
  data() {
    return {
      wordCardList: [],
      localData: {
        wordCardList: [
          {
            pinyin: 'Zhè shì jiějie ma?',
            hanzi: '这是姐姐吗?',
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-41/card-3-jiejie.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-41/card-3.svg`),
            Showing: true
          },
          {
            pinyin: 'Zhè shì dìdi ma?',
            hanzi: '这是弟弟吗?',
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-41/card-4-didi.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-41/card-4.svg`),
            Showing: true
          },
          {
            pinyin: 'Zhè shì bàba ma?',
            hanzi: '这是爸爸吗?',
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-41/card-1-baba.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-41/card-1.svg`),
            Showing: true
          },
          {
            pinyin: 'Zhè shì māma ma?',
            hanzi: '这是妈妈吗?',
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-41/card-2-mama.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-41/card-2.svg`),
            Showing: true
          }
        ]
      },

      starNum: 0,
      showHnaziIndex: false,
      isLastStep: false,
      selecteArr: [],
      clickImgSocketInfo: {},
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'speak',
            startNumberRange: 1,
            endNumberRange: 100
          }
        ]
      }
    }
  },
  watch: {
    clickImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20007,
          data: { value },
          text: 'wordCardsPage点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        // console.log('watch的数据', value)
        this.initData()
      },
      deep: true
    }
  },
  mounted() {
    this.$bus.$on('clickSingleCard', ({ index }) => {
      this.clickImage(index, true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickSingleCard')
  },
  methods: {
    initData() {
      this.wordCardList = this.componentProps.allCards.map((item) => {
        return {
          pinyin: item[3].value,
          hanzi: item[2].value,
          image: item[1].value,
          imgShow: item[0].value,
          Showing: true
        }
      })
         console.log(this.componentProps,'111');
    },
    nextPage() {
      this.$bus.$emit('nextButton', true)
    },
    handleChangeDisplay(index) {
      this.wordCardList[index].Showing = !this.wordCardList[index].Showing
    },
    clickImage(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickImgSocketInfo = {
          index,
          value: Math.random()
        }
      }
      this.handleChangeDisplay(index), this.starNum++

      playCorrectSound(true, false)
      if (this.selecteArr.indexOf(index) === -1) {
        this.selecteArr.push(index)
      }
      this.showHnaziIndex = index
      if (this.selecteArr.length === this.wordCardList.length) {
        this.isLastStep = true
        playCorrectSound()
        startConfetti()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.words-card-container {
  width: 100%;
  height: 100%;
}

.words-card-page-box {
  background: url('../../../../assets/img/03-Backgrounds/background-practice.svg')
    no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .hanzi-item {
    width: 50%;
    margin: 0 auto;
    background: #214e96;
    position: absolute;
    bottom: 6%;
    left: 25%;
    padding: 1% 1%;
    color: #fff;
    border-radius: 40px;
    .sentence-hanzi,
    .sentence-pinyin {
      text-align: center;
    }
  }

  .title-area {
    z-index: 9;
    position: absolute;
    left: 0;
    top: 10%;
    // width: 23%;
    // height: 12%;
    padding: 2% 5%;
    background: #214e96;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 22px;
    color: #fff;
    .title {
      .title-pinyin{
        text-align: center;
      }
      .title-hanzi {
        text-align: center;
        font-size: 25px !important;
      }
    }
  }
  .word-cards-content {
    // margin-top: -15%;
    // margin-right: -10%;
    width: 60%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap-reverse;

    .word-card-item {
      width: 29%;
      display: flex;
      flex-direction: column;
      margin: 0 2%;
      .frontage-area {
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 70%;
      }
      p {
        line-height: 40px;
      }
      .redColor {
        background: #ce4c3f;
        border-bottom-color: #a53d32;
      }
      .blueColor {
        background: #224e96;
        border-bottom-color: #173b74;
      }
      .contray-content {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        margin-bottom: 10%;
        // background: #ce4c3f;
        position: relative;
        border-radius: 18px;
        // border-bottom: 15px solid #a53d32;
        border-bottom-width: 15px;
        border-bottom-style: solid;
        vertical-align: middle;
        cursor: pointer;
        &:hover {
          border-bottom: 15px solid #f6c752;
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          margin-left: 20%;
          width: 60%;
        }
      }
      .card-1 {
        transform: rotate(6deg);
      }
      .card-2 {
        transform: rotate(-2deg);
      }
      .card-3 {
        transform: rotate(0deg);
      }
      .card-4 {
        transform: rotate(7deg);
      }
      .card-5 {
        transform: rotate(6deg);
      }
    }
    .lesson-11 {
      width: 30%;
    }
  }
}
</style>
