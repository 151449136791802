var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-bingo-container" }, [
    _c("div", { staticClass: "game-content" }, [
      _c(
        "div",
        { staticClass: "game-box" },
        [
          _c("Star", {
            attrs: {
              totalStarNumber: _vm.totalStars,
              currentIndex: _vm.currentIndex,
            },
          }),
          _c(
            "div",
            { staticClass: "game-main" },
            [
              _vm._l(_vm.asideElementList, function (item, index) {
                return _c(
                  "div",
                  { key: index + "asideElementList", staticClass: "image-box" },
                  [
                    _c(
                      "div",
                      { staticClass: "image-side-content" },
                      _vm._l(item, function (ele, eleIndex) {
                        return _c(
                          "div",
                          {
                            key: eleIndex,
                            staticClass: "card-item",
                            on: {
                              click: function ($event) {
                                return _vm.foundAnswer(ele)
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "card",
                              class: {
                                yellowBorder:
                                  _vm.clickIndexList.indexOf(ele.id) > -1,
                                redBorder:
                                  _vm.bingoIndexList.indexOf(ele.id) > -1 &&
                                  _vm.currentIndex ===
                                    _vm.questionInfoList.length,
                              },
                              attrs: { src: ele.bgImg, alt: "" },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                )
              }),
              _vm._l(_vm.questionInfoList, function (item, index) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.question === index,
                        expression: "question === index",
                      },
                    ],
                    key: index + "question",
                    staticClass: "game-center-box",
                    style: {
                      backgroundImage:
                        "url(" +
                        require("@/assets/img/16-GAMES/G56-bingo-images/background-card-panda.svg") +
                        ")",
                    },
                  },
                  [
                    _c("div", { staticClass: "number-area" }, [
                      _c("div", { staticClass: "pinyin font-pinyin-medium" }, [
                        _vm._v(_vm._s(item.pinyin)),
                      ]),
                      _c("div", { staticClass: "font-hanzi-medium" }, [
                        _vm._v(_vm._s(item.hanzi)),
                      ]),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }