var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "course-begin-container",
      style: { backgroundImage: "url('" + _vm.bgImg + "')" },
    },
    [
      _c("div", { staticClass: "begin-end-box" }, [
        _c("div", { staticClass: "customlesson_one" }, [
          _c("div", { staticClass: "customlesson_one_img" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/img/05-Panda/panda-title-background.svg"),
                alt: "",
              },
            }),
            _c(
              "span",
              {
                staticClass: "lesson_one",
                class: { lessononehovertwo: _vm.lessononehovertwo },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.activeClick.apply(null, arguments)
                  },
                  mouseenter: function ($event) {
                    return _vm.hoverImg(2, "hover")
                  },
                  mouseleave: function ($event) {
                    return _vm.hoverImg(2, "")
                  },
                },
              },
              [
                _c("AudioPlayer", {
                  attrs: {
                    isPlay: _vm.isStartPlaying,
                    title: _vm.title,
                    audioUrl: _vm.audioSrc,
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "customlesson_num" }, [
              _c("div", { staticClass: "customlesson_two" }, [
                _c(
                  "span",
                  { staticClass: "font-face pinyin font-pinyin-large" },
                  [_vm._v(_vm._s(_vm.titlePinyin) + " ")]
                ),
              ]),
              _c("div", { staticClass: "customlesson_three" }, [
                _c(
                  "span",
                  {
                    staticClass: "txt-hz font-hanzi-large",
                    attrs: { id: "face" },
                  },
                  [_vm._v(_vm._s(_vm.title) + " ")]
                ),
              ]),
              _c("div", { staticClass: "customlesson_four" }, [
                _c(
                  "span",
                  { staticClass: "txt-en englishFont font-english-large" },
                  [_vm._v(_vm._s(_vm.titleEnglish) + " ")]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }