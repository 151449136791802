<template>
  <div class="game-bingo-container">
    <div class="game-content">
      <div class="game-box">
        <Star :totalStarNumber="totalStars" :currentIndex="currentIndex" />
        <div class="game-main">
          <div
            class="image-box"
            v-for="(item, index) in asideElementList"
            :key="index + 'asideElementList'"
          >
            <div class="image-side-content">
              <div
                class="card-item"
                v-for="(ele, eleIndex) in item"
                :key="eleIndex"
                @click="foundAnswer(ele)"
              >
                <img
                  :src="ele.bgImg"
                  alt=""
                  class="card"
                  :class="{
                    yellowBorder: clickIndexList.indexOf(ele.id) > -1,
                    redBorder:
                      bingoIndexList.indexOf(ele.id) > -1 &&
                      currentIndex === questionInfoList.length
                  }"
                />
              </div>
            </div>
          </div>

          <div
            class="game-center-box"
            :style="{
              backgroundImage: `url(${require('@/assets/img/16-GAMES/G56-bingo-images/background-card-panda.svg')})`
            }"
            v-for="(item, index) in questionInfoList"
            :key="index + 'question'"
            v-show="question === index"
          >
            <!-- 中间的背景图 -->
            <!-- <img class="objectDisplay" :src="item.bgImg" v-if="!lessonType" /> -->
            <!-- 中间的数字和pinyin -->
            <div class="number-area">
              <div class="pinyin font-pinyin-medium">{{ item.pinyin }}</div>
              <div class="font-hanzi-medium">{{ item.hanzi }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Star from '@/components/Course/GamePage/Star'
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    Star
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          questionList: []
        }
      }
    }
  },
  data() {
    return {
      totalStars: 0,
      gameSocket: {},
      isLastStep: false,
      currentIndex: 0,
      question: 0,
      clickIndexList: [],
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'look',
            startNumberRange: 0,
            endNumberRange: 100
          }
        ]
      },
      asideElementList: [],
      questionInfoList: [],
      bingoIndexList: [],

      localData: {
        asideElementList: [
          [
            {
              id: 1,
              done: false,
              bgImg: require('@/assets/img/16-GAMES/G56-bingo-images/lesson-19/image-1.svg')
            },
            {
              id: 4,
              done: false,
              bgImg: require('@/assets/img/16-GAMES/G56-bingo-images/lesson-19/image-4.svg')
            },
            {
              id: 7,
              done: false,
              bgImg: require('@/assets/img/16-GAMES/G56-bingo-images/lesson-19/image-7.svg')
            }
          ],
          [
            {
              id: 2,
              done: false,
              bgImg: require('@/assets/img/16-GAMES/G56-bingo-images/lesson-19/image-2.svg')
            },
            {
              id: 5,
              done: false,
              bgImg: require('@/assets/img/16-GAMES/G56-bingo-images/lesson-19/image-5.svg')
            },
            {
              id: 8,
              done: false,
              bgImg: require('@/assets/img/16-GAMES/G56-bingo-images/lesson-19/image-8.svg')
            }
          ],
          [
            {
              id: 3,
              done: false,
              bgImg: require('@/assets/img/16-GAMES/G56-bingo-images/lesson-19/image-3.svg')
            },
            {
              id: 6,
              done: false,
              bgImg: require('@/assets/img/16-GAMES/G56-bingo-images/lesson-19/image-6.svg')
            },
            {
              id: 9,
              done: false,
              bgImg: require('@/assets/img/16-GAMES/G56-bingo-images/lesson-19/image-9.svg')
            }
          ]
        ],
        questionInfoList: [
          {
            id: 3,
            pinyin: 'Nǎ ge shì baba?',
            hanzi: '哪个是爸爸？'
          },

          {
            id: 5,
            pinyin: 'Nǎ ge shì māma?',
            hanzi: '哪个是妈妈？'
          },

          {
            id: 7,
            pinyin: 'Nǎ ge shì lǎoshī?',
            hanzi: '哪个是老师？'
          }
        ],
        totalStars: 3
      }
    }
  },
  mounted() {
    this.$bus.$on('clicBingoGame', ({ index }) => {
      this.foundAnswer(index, true)
    })
  },
  watch: {
    gameSocket: {
      handler(value) {
        const data = {
          clickType: 20040,
          data: { value },
          text: 'clicBingoGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        // console.log(value, '这是watch的数据')
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  beforeDestroy() {
    this.$bus.$off('clicBingoGame')
  },
  methods: {
    initData() {
      // console.log(this.componentProps, 'this.componentProps')

      this.questionInfoList = []
      const imgList = []
      this.asideElementList = []
      this.bingoIndexList = []
      if (
        this.componentProps.questionList &&
        this.componentProps.questionList.length
      ) {
        this.componentProps.questionList.forEach((item) => {
          imgList.push(...item[2].value)
          this.questionInfoList.push({
            id: null,
            hanzi: item[1].value,
            pinyin: item[0].value,
            imgSrc: item[3].value
          })
          this.asideElementList.push([...item[2].value])
        })
        this.questionInfoList.forEach((item) => {
          item.id = imgList.findIndex((ele) => ele.value === item.imgSrc) + 1
        })
        this.asideElementList.forEach((item) => {
          if (item && item.length) {
            item.forEach((ele) => {
              ele.id = imgList.findIndex((i) => i.value === ele.value) + 1
              ele.done = false
              ele.bgImg = ele.value
            })
          }
        })
        this.bingoIndexList = this.questionInfoList.map((item) => item.id)
        this.totalStars = this.questionInfoList.length
        // console.log(imgList,'imgList',this.asideElementList);
      }
    },
    foundAnswer(index, isFromSocket = false) {
      // console.log(index,'index');
      if (!isFromSocket) {
        this.gameSocket = {
          index,
          value: Math.random()
        }
      }
      if (this.questionInfoList[parseInt(this.question)].id === index.id) {
        this.clickIndexList.push(index.id)
        playCorrectSound(true, false)
        this.$emit('changeStatus', index)
        this.currentIndex++
        if (this.question >= this.questionInfoList.length - 1) {
          this.isLastStep = true
          playCorrectSound()

          startConfetti()
          return
        } else {
          this.question++
        }
      } else {
        playCorrectSound(false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.game-bingo-container {
  width: 100%;
  height: 100%;
  .game-content {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 48px;
    position: relative;
    overflow: hidden;
    .game-box {
      text-align: -webkit-center;
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: url('../../../../assets/img/03-Backgrounds/background-practice.svg');
      border-radius: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .game-main {
      box-sizing: border-box;
      position: relative;
      width: 80%;
      height: 77%;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      .image-box {
        height: 100%;
        width: 20%;
        display: flex;
        align-items: center;
        .image-side-content {
          width: 100%;
          height: 100%;
          display: flex;

          border-top-right-radius: 30px;
          border-top-left-radius: 30px;
          justify-content: space-around;
          align-items: center;
          flex-direction: column;
          .card-item {
            box-sizing: border-box;
            width: 90%;
            height: 25%;
            z-index: 1;
            border-radius: 15px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .card {
              box-sizing: border-box;
              width: 100%;
              height: 100%;
              object-fit: cover;
              border: 10px solid #fff;
              // border-bottom: 20px solid #fff;
              border-radius: 20px;
            }
            .yellowBorder {
              border-color: #f4d737;
            }
            .redBorder {
              border-color: #cd4c3f;
            }
          }
        }
      }
      .game-center-box {
        width: 40%;
        right: -2%;
        // background-repeat: round;
        background-size: cover;
        // height: 63%;
        // top: 10%;
        height: 90%;
        top: 0%;
        height: 63%;
        border-radius: 30px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-top: -53%;
          /* height: 100%; */
          width: 100%;
          top: -5%;
        }
        .number-area {
          width: 80%;
          top: 32%;
          left: 18%;
          padding-top: 40%;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
</style>
