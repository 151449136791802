<template>
  <div class="speaking-page-container">
    <div class="speaking-page-content">
      <!-- 失败和成功背景 -->
      <div class="video-area" @click.stop="closeRecorder('socket')">
        <!-- src="https://static.nihaocafe.com/video/df3a3a6a-bc71-43f3-b523-de0cecffc8e3.mp4" -->
        <video
          class="video-player"
          ref="videoBox"
          :autoplay="false"
          @canplay="getDuration"
          @timeupdate="toRecordPage"
          :poster="pageInfo.bgImg"
        ></video>
        <!-- <img :src="pageInfo.bgImg" class="video-player" alt="" /> -->
      </div>
      <div class="title-area" v-if="!isVideoPlay">
        <div class="title-content">
          <div class="words pinyin font-pinyin-medium">
            {{ pageInfo.titlePinyin }}
          </div>
          <div class="words font-hanzi-medium">
            {{ pageInfo.titleHanzi }}
          </div>
        </div>
      </div>

      <div class="content-area">
        <div
          class="content-line"
          v-for="(item, index) in wordsInfoList"
          :key="index + 'word'"
        >
          <div
            class="word-item"
            v-for="(word, wordIndex) in item.options"
            :key="wordIndex + 'worditem'"
          >
            <span class="words pinyin">{{ word.pinyin }}</span>
            <span class="words hanzi">{{ word.hanzi }}</span>
          </div>
        </div>
      </div>

      <div class="recorder-area" v-if="isRecordPageOpen">
        <div
          class="checkGif"
          :class="{ checkGifWrong: !checkGif }"
          v-checkgif="checkGif"
          v-if="isShowCheckGif"
        ></div>
        <div class="red-background-border"></div>
        <div class="recorder-content">
          <!-- <div class="trumpet-area">
            <div class="trumpet-content">
              <img
                src="@/assets/img/06-Sound/sound-white.svg"
                alt=""
                @click="playVoice('socket')"
              />
            </div>
          </div> -->

          <div class="words-area">
            <div class="words-content">
              <!-- v-for="(item, index) in currentWordsInfo.singleWordList"
                :key="index" -->
              <div
                class="word-item"
                :class="{
                  watingStatus:
                    isFinishRecording && currentWordsInfo.status === 2,
                  correctColor:
                    currentWordsInfo.status != 0 && isFinishRecording,
                  wrongColor: currentWordsInfo.status === 0 && isFinishRecording
                }"
              >
                <div class="words pinyin font-pinyin-medium">
                  {{ currentWordsInfo.pinyin }}
                </div>
                <div class="words font-hanzi-medium">
                  {{ currentWordsInfo.hanzi }}
                </div>
              </div>
            </div>
          </div>
          <!-- 录音 -->
          <!-- <div class="recorder-button" v-if="!isFinishRecording">
            <div class="button-img" @click="startRecorder('socket')">
              <img
                src="@/assets/img/16-GAMES/G14-tape-recorder/record.svg"
                v-if="!isStartRecording"
              />
              <img
                src="@/assets/img/16-GAMES/G14-tape-recorder/record-start.svg"
                alt=""
                v-else
              />
            </div>
            <div class="recorder-time" v-if="isShowTime">
              {{ recorderTime }}
            </div>
          </div> -->
          <!-- 评分 -->
          <!-- <div class="finish-record-area" v-else>
            <div class="button-play">
              <img
                src="@/assets/img/16-GAMES/G14-tape-recorder/button-play.svg"
                alt=""
                @click="playRecorder('socket')"
              />
            </div>
            <div class="button-record">
              <img
                src="@/assets/img/16-GAMES/G14-tape-recorder/record-next.svg"
                alt=""
                @click="backToRecord('socket')"
              />
            </div>
          </div> -->
        </div>
      </div>

      <div class="disorganize">
        <div class="turntable-area">
          <img
            :src="require('@/assets/img/16-GAMES/G57-turntable/circle.svg')"
            alt=""
            class="turntable-img"
          />
          <div class="needle-area">
            <img
              :src="require('@/assets/img/16-GAMES/G57-turntable/needle.svg')"
              alt=""
              :class="'rotateAnimation-' + clickIndex"
              class="glow-red"
              @click="startPlayingVideo('socket')"
            />
          </div>
        </div>
        <!-- <span class="next-btn" @click="handleClickNext">
          <i class="el-icon-caret-right"></i>
        </span> -->
        <!-- <span class=""></span> -->
        <!-- <span class="change-btn" @click="startPlayingVideo('socket')">
          <i class="el-icon-refresh"></i>
        </span> -->
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import Recorder from 'js-audio-recorder'
import { audioToMp3 } from '@/assets/js/axios/kidsApi.js'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          titleZh: '',
          titlePy: '',
          bgImg: '',
          textList: []
        }
      }
    }
  },
  data() {
    return {
      localData: {
        pageInfo: {
          titlePinyin: 'nǐhǎo！wǒshìdōngdōng',
          titleHanzi: '你好!我是冬冬',
          bgImg: require('@/assets/img/13-Video/lesson-02-video-1.svg')
        },
        wordsInfoList: [
          {
            id: 0,
            pinyin: 'Nǐhǎo',
            hanzi: '你好',
            status: 2,
            timeStamp: 20
          },
          {
            id: 0,
            pinyin: 'WǒshìXiǎoÀi.',
            hanzi: '我是小爱',
            status: 2,
            timeStamp: 20
          },
          {
            id: 0,
            pinyin: 'Nǐhǎo WǒshìDōngdong.',
            hanzi: '你好我是冬冬',
            status: 2,
            timeStamp: 20
          }
        ]
      },
      pageInfo: {
        titlePinyin: '',
        titleHanzi: '',
        bgImg: ''
      },
      wordsInfoList: [],
      checkGif: true,
      isShowCheckGif: false,
      recorderOption: {
        sampleBits: 8, // 采样位数，支持 8 或 16，默认是16
        sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
        numChannels: 1 // 声道，支持 1 或 2， 默认是1
        // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
      },
      recorder: null,

      isVideoPlay: false,

      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'play',
            startNumberRange: 1,
            endNumberRange: 3
          }
        ]
      },
      videoDom: '',
      duration: '',
      currentTime: '',
      isRecordPageOpen: false,
      currentWordsInfo: {},
      isStartRecording: false,
      isShowTime: false,
      timeNum: 0,
      recorderTime: '',
      currentStep: 0,
      isFinishRecording: false,
      isfinishCheck: true,
      recorderUrl: '',

      playVideoSocketInfo: {},
      playVoiceSocketInfo: {},
      recorderSocketInfo: {},
      // nextRecorderSocketInfo: {},
      // palyRecorderSocketInfo: {},
      generatedNumbers: [],
      currentNumber: null,
      closeRecorderSocketInfo: {},
      clickIndex: null
    }
  },
  created() {
    this.recorder = new Recorder({
      ...this.recorderOption
    })
    // console.log(this.$route);
  },
  mounted() {
    this.$nextTick(() => {
      this.videoDom = this.$refs.videoBox
    })
    this.$bus.$on('getTeacherRate', ({ wrongList, correctList }) => {
      this.checkWords(wrongList, correctList)
    })
    // this.$bus.$on('recordButton', () => {
    //   this.startRecorder('socket', true)
    // })
    this.$bus.$on('nextRecordButton', () => {
      this.backToRecord('socket', true)
    })
    this.$bus.$on('playVideoButton', (data) => {
      // console.log(data,'00000000000000');

      this.startPlayingVideo('socket', true, data)
    })
    this.$bus.$on('clickVoiceButton', () => {
      this.playVoice('socket', true)
    })
    this.$bus.$on('palyRecorder', () => {
      this.playRecorder('socket', true)
    })
    this.$bus.$on('closeRecorder', () => {
      this.closeRecorder('socket', true)
    })
    // this.$bus.$on('showFinishRecord',() => {
    //   this.isFinishRecording = true
    // })
  },
  beforeDestroy() {
    this.$bus.$off('getTeacherRate')
    // this.$bus.$off('recordButton')
    this.$bus.$off('nextRecordButton')
    this.$bus.$off('playVideoButton')
    this.$bus.$off('clickVoiceButton')
    this.$bus.$off('palyRecorder')
    this.$bus.$off('closeRecorder')
    // this.$bus.$off('showFinishRecord')
  },
  watch: {
    isShowTime(value) {
      if (!value) {
        // 发送socket信息
        // const
        this.getMp3Data().then((res) => {
          // console.log(res);
          this.recorderUrl = res
          const data = {
            clickType: 11000,
            data: { value: res },
            text: 'speakingPage录音socket'
          }
          this.sendSocketInfo = { ...data }
          this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
          // 提醒老师接受音频
          console.log(this.sendSocketInfo)
          this.isFinishRecording = true
        })
      }
    },
    playVideoSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003301,
          data: { value: value.value, currentNumber: value.currentNumber },
          text: 'speakingPage组件播放按钮socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
        console.log(this.sendSocketInfo)
      },
      deep: true
    },
    playVoiceSocketInfo: {
      handler() {
        const data = {
          clickType: 2003302,
          data: { value: null },
          text: 'speakingPage组件播放按钮socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
        console.log(this.sendSocketInfo)
      },
      deep: true
    },
    // recorderSocketInfo: {
    //   handler() {
    //     const data = {
    //       clickType: 2003303,
    //       data: { value: null },
    //       text: 'speakingPage组件录音按钮socket'
    //     }
    //     this.sendSocketInfo = { ...data }
    //     this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
    //     console.log(this.sendSocketInfo)
    //   },
    //   deep: true
    // },
    // nextRecorderSocketInfo: {
    //   handler() {
    //     const data = {
    //       clickType: 2003304,
    //       data: { value: null },
    //       text: 'speakingPage组件再次录音按钮socket'
    //     }
    //     this.sendSocketInfo = { ...data }
    //     this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
    //     console.log(this.sendSocketInfo)
    //   },
    //   deep: true
    // },
    // palyRecorderSocketInfo: {
    //   handler() {
    //     const data = {
    //       clickType: 2003305,
    //       data: { value: null },
    //       text: 'speakingPage组件再次录音按钮socket'
    //     }
    //     this.sendSocketInfo = { ...data }
    //     this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
    //     console.log(this.sendSocketInfo)
    //   },
    //   deep: true
    // },
    closeRecorderSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003306,
          data: { value },
          text: 'speakingPage组件关闭弹窗'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
        console.log(this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  methods: {
    initData() {
      //   console.log(this.componentProps, '------')
      this.pageInfo = {
        titlePinyin: this.componentProps.titlePy,
        titleHanzi: this.componentProps.titleZh,
        bgImg: this.componentProps.bgImg
      }
      this.wordsInfoList = this.componentProps.textList.map((item, index) => {
        //   console.log(item,'00');
        return {
          //   singleWordList: item[0].options.map((ele, eleIndex) => {
          //     return {
          //       id: eleIndex,
          //       pinyin: ele.wordPy,
          //       hanzi: ele.wordZh,
          //       status: 2
          //     }
          //   }),
          pinyin:
            item[0].options && item[0].options.length
              ? item[0].options.map((ele) => ele.wordPy).join('')
              : '',
          hanzi:
            item[0].options && item[0].options.length
              ? item[0].options.map((ele) => ele.wordZh).join('')
              : '',
          id: index,
          status: 2,
          timeStamp: 20,
          options:
            item[0].options && item[0].options.length
              ? item[0].options.map((ele, eleIndex) => {
                  return {
                    id: eleIndex,
                    pinyin: ele.wordPy,
                    hanzi: ele.wordZh,
                    status: 2
                  }
                })
              : []
        }
      })
      //   console.log(this.wordsInfoList[this.currentStep],'00');

      this.currentWordsInfo = this.wordsInfoList[this.currentStep]
      //   console.log( this.wordsInfoList,' this.wordsInfoList');
    },
    playVoice(socket, isFromSocket = false) {
      // to do list : 播放语音
      console.log('播放语音')
      if (!isFromSocket) {
        this.playVoiceSocketInfo = {
          value: Math.random()
        }
      }
    },
    getDuration() {
      this.duration = this.videoDom.duration
      //   console.log(this.duration)
    },
    toRecordPage() {
      this.currentTime = this.videoDom.currentTime
      // console.log(this.currentTime);
      if (this.currentTime >= this.currentWordsInfo.timeStamp) {
        this.videoDom.pause()
        this.isRecordPageOpen = true
      }
    },

    /**
     *  录音的具体操作功能
     * */
    // 开始录音
    // startRecorder(socket, isFromSocket = false) {
    //   if (!isFromSocket) {
    //     this.recorderSocketInfo = {
    //       value: Math.random()
    //     }
    //   }
    //   this.isStartRecording = !this.isStartRecording
    //   if (this.isStartRecording) {
    //     this.recorder.start()
    //     this.isShowTime = true
    //     this.timer = setInterval(() => {
    //       this.timeNum++
    //       this.recorderTime = '00:' + String(this.timeNum).padStart(2, '0')
    //     }, 1000)
    //   } else {
    //     this.timeNum = 0
    //     this.recorderTime = ''
    //     clearInterval(this.timer)
    //     this.isShowTime = false
    //     this.recorder.stop()
    //   }
    // },
    // 录音播放
    // playRecorder(socket, isFromSocket = false) {
    //   if (!isFromSocket) {
    //     this.palyRecorderSocketInfo = {
    //       value: Math.random()
    //     }
    //   }
    //   this.recorder.play()
    // },
    destroyRecorder() {
      this.recorder.destroy()
    },
    // backToRecord(socket, isFromSocket = false) {
    //   if (!isFromSocket) {
    //     this.nextRecorderSocketInfo = {
    //       value: Math.random()
    //     }
    //   }
    //   this.isFinishRecording = false
    //   this.destroyRecorder()
    // },
    // 判断正确
    checkWords(wrongList, correctList) {
      wrongList.forEach((item) => {
        // this.$emit('changeWordStatus', this.currentStep, item, 0)step, index, status
        this.wordsInfoList[this.currentStep].status = 0
      })
      correctList.forEach((item) => {
        // this.$emit('changeWordStatus', this.currentStep, item, 1)
        this.wordsInfoList[this.currentStep].status = 1
      })
      if (wrongList.length === 0) {
        // 正确 继续播放视频等操作
        this.isShowCheckGif = true
        this.checkGif = true
        playCorrectSound()
        startConfetti()
        setTimeout(() => {
          this.isShowCheckGif = false
          this.currentStep++
          if (this.currentStep >= this.wordsInfoList.length - 1) {
            this.currentStep = this.wordsInfoList.length - 1
            this.isLastStep = true
          }
          this.isfinishCheck = true
          this.isFinishRecording = true
        }, 500)
      } else {
        // 错误  字体变色

        this.isShowCheckGif = true
        this.checkGif = false
        playCorrectSound(false)

        setTimeout(() => {
          this.isShowCheckGif = false
        }, 1000)
      }
    },
    async getMp3Data() {
      // const formData = new FormData();
      const blob = this.recorder.getWAVBlob() // 获取wav格式音频数据
      const calendarEventId = this.$route.params.calendarEventId
      const formData = new FormData()

      formData.append('uploadFile', blob)
      formData.append('calendarEventId', calendarEventId)
      formData.append('oldAudio', this.recorderUrl)

      const res = await audioToMp3(formData)
      return res.data
    },
    handleClickNext() {
      this.isRecordPageOpen = true
    },
    startPlayingVideo(socket, isFromSocket = false, data) {
      if(this.isRecordPageOpen){
        this.closeRecorder('socket')
        return
      }
      let newNumber
      const possibleNumbers = []
      // 生成所有可能的随机数
      for (let i = 0; i < this.wordsInfoList.length; i++) {
        if (!this.generatedNumbers.includes(i)) {
          possibleNumbers.push(i)
        }
      }
      // 如果没有可用的随机数，则提示用户
      if (possibleNumbers.length === 0) {
        this.$message.warning('所有对话已经练习完成')
        this.isRecordPageOpen = false
        return
      }
      //   console.log('newNumber', newNumber);

      if (!isFromSocket) {
        this.clickIndex = Math.floor(Math.random() * 4) + 1
        // 从剩余的可能随机数中随机选择一个
        newNumber =
          possibleNumbers[Math.floor(Math.random() * possibleNumbers.length)]

        this.generatedNumbers.push(newNumber)
        this.currentNumber = newNumber
        this.playVideoSocketInfo = {
          value: this.clickIndex,
          currentNumber: this.currentNumber
        }
      } else {
        this.clickIndex = data.value
        this.currentNumber = data.currentNumber
      }
      setTimeout(() => {
        //   this.currentWordsInfo = this.wordsInfoList[this.currentStep]
        // this.currentWordsInfo = this.wordsInfoList[this.currentStep]
        this.currentWordsInfo = this.wordsInfoList[this.currentNumber]
        // this.videoDom.play()
        this.isVideoPlay = true
        this.isfinishCheck = false
        // this.isRecordPageOpen = false;
        this.isRecordPageOpen = true
        this.isFinishRecording = false
      }, 1200)
    },
    closeRecorder(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.closeRecorderSocketInfo = {
          value: false
        }
      }
      this.generatedNumbers = this.generatedNumbers.filter(
        (item) => item !== this.currentNumber
      )
      this.isRecordPageOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
.speaking-page-container {
  width: 100%;
  height: 100%;
  .speaking-page-content {
    width: 100%;
    height: 100%;
    position: relative;
    .video-area {
      width: 100%;
      height: 100%;
      .video-player {
        border-radius: 58px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .title-area {
      position: absolute;
      //   width: 25%;
      //   height: 18%;
      padding: 20px;
      left: 0;
      top: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #cd4c3f;
      color: #fff;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      z-index: 2;
      font-family: 'GenSen-Han';
    }
    .content-area {
      width: 80%;
      height: 60%;
      overflow: auto;
      position: absolute;
      top: 18%;
      left: 10%;
      background-color: rgba(255, 255, 255, 0.5);
      opacity: 0.5;
      padding: 20px;
      border-radius: 25px;
      .content-line {
        display: flex;
        // align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;
        .word-item {
          display: flex;
          flex-direction: column;
          font-family: 'GenSen-Han';
          .pinyin {
            font-size: 16px;
          }
          .hanzi {
            font-size: 22px;
          }
        }
      }
    }
    .recorder-area {
      width: 70%;
      height: 65%;
      position: absolute;
      top: 10%;
      left: 15%;
      .recorder-content {
        position: absolute;
        background: #224e96;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        border-radius: 56px;
        z-index: 3;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        .trumpet-area,
        .words-area,
        .recorder-button,
        .finish-record-area {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .trumpet-area {
          .trumpet-content {
            width: 60px;
            height: 60px;
            background: #cd4c3f;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 60%;
              cursor: pointer;
            }
          }
        }
        .words-area {
          width: 100%;
          .words-content {
            // height: 50%;
            color: #224e96;
            padding: 5% 10%;
            background: #fff;
            border-radius: 20px;
            display: flex;
            font-family: "GenSen-Han";
          }
        }
        .recorder-button {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .button-img {
            width: 60%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 90%;
              cursor: pointer;
            }
          }
          .recorder-time {
            color: #fff;
            position: absolute;
            left: -50%;
          }
        }
        .finish-record-area {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .button-play,
          .button-record {
            display: flex;
            justify-content: flex-end;
            img {
              width: 70%;
              cursor: pointer;
            }
          }
        }
      }
    }
    .disorganize {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 30px;
      right: 20px;
      .turntable-area {
        position: relative;
        .turntable-img {
          max-width: 150px;
        }
        .needle-area {
          position: absolute;
          z-index: 100;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 15%;
            cursor: pointer;
            transform-origin: 50% 50%;
          }
        }
        .rotateAnimation-1 {
          transform: rotate(135deg);
          animation: rotateAnimation1 1s ease-out;
        }
        .rotateAnimation-2 {
          transform: rotate(45deg);
          animation: rotateAnimation2 1s ease-out;
        }
        .rotateAnimation-3 {
          transform: rotate(-45deg);

          animation: rotateAnimation3 1s ease-out;
        }
        .rotateAnimation-4 {
          transform: rotate(225deg);
          animation: rotateAnimation4 1s ease-out;
        }

        @keyframes rotateAnimation1 {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(855deg);
          }
        }
        @keyframes rotateAnimation2 {
          0% {
            transform: rotate(135deg);
          }

          100% {
            // transform: rotate(615deg);
            transform: rotate(1125deg);
          }
        }
        @keyframes rotateAnimation3 {
          0% {
            transform: rotate(45deg);
          }

          100% {
            // transform: rotate(757.5deg);
            transform: rotate(1035deg);
          }
        }
        @keyframes rotateAnimation4 {
          0% {
            transform: rotate(-45deg);
          }

          100% {
            // transform: rotate(472.5deg);
            transform: rotate(945deg);
          }
        }
        @keyframes rotateAnimation5 {
          0% {
            transform: rotate(-45deg);
          }

          100% {
            // transform: rotate(472.5deg);
            transform: rotate(945deg);
          }
        }
        @keyframes rotateAnimation6 {
          0% {
            transform: rotate(-45deg);
          }

          100% {
            // transform: rotate(472.5deg);
            transform: rotate(1045deg);
          }
        }
      }
      .next-btn {
        padding: 10px 20px;
        background-color: #224e96;
        color: #fff;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 15px;
      }
      .change-btn {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        cursor: pointer;
        color: #fff;
        background-color: #cd4c3f;
      }
      img {
        width: 100%;
      }
    }
    .teacher-rate-area {
      background-image: url('../../../../assets/img/16-GAMES/G14-tape-recorder/controls.svg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: 0;
      width: 70%;
      height: 20%;
      left: 15%;
      img {
        height: 100%;
      }
    }

    .words {
      text-align: center;
    }
    .watingStatus {
      opacity: 0.3;
    }
    .correctColor {
      color: #35af76;
      opacity: 1;
    }
    .wrongColor {
      color: #cd4c3f;
    }
  }
}
</style>
