<template>
  <div class="choose-pic-sentence-container">
    <div class="game-content">
      <div class="title-area" v-if="titleInfo && Object.keys(titleInfo).length">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ titleInfo.hanzi }}
          </div>
        </div>
      </div>
      <div class="back-ground-img-area">
        <img class="bg-img" :src="bgImg" alt="" />
        <img
          :src="require('@/assets/img/FixedPicture/background-board.svg')"
          alt=""
          class="top-img"
        />
      </div>
      <div class="pic-words-area" v-if="isShowshadow">
        <div class="overlay"></div>
        <div class="pic-box">
          <div
            class="pic-item"
            v-for="(item, index) in sentenceList"
            :key="index"
          >
            <div class="pic-detail">
              <img :src="item.bgImg" alt="" v-if="index === currentId - 1" />
            </div>
          </div>
        </div>
        <div class="words-box">
          <div
            class="hanzi-item"
            :class="{ noPadding: !item.hanzi }"
            v-for="(item, index) in sentenceList"
            :key="index"
            v-show="index === currentId - 1 && lock"
          >
            <div class="pinyin sentence-hanzi font-pinyin-medium">
              {{ item.pinyin }}
            </div>
            <div class="sentence-hanzi font-hanzi-medium">
              {{ item.hanzi }}
            </div>
          </div>
        </div>
      </div>
      <div class="game-area">
        <div class="pic-area" @click="handleClickPic(null)"></div>
        <div class="side-menu-area">
          <div class="menu-item" v-for="(item, index) in picList" :key="index">
            <img
              :src="item.menuImg"
              alt=""
              @click="handleClickPic(item.id)"
              :class="{
                hasClick: showIdList.indexOf(item.id) > -1,
                grayColor: showIdList.indexOf(item.id) === -1
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          picMenuChooseList: [],
          titlePy: '',
          titleZh: '',
          bgImg: ''
        }
      }
    }
  },
  data() {
    return {
      bgImg: '',
      titleInfo: {},
      sentenceList: [],
      picList: [],
      showIdList: [],
      lock: true,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'speak',
            startNumberRange: 0,
            endNumberRange: 100
          }
        ]
      },
      dragId: 0,
      step: 0,
      showHnaziId: 0,
      dropAvatarSocketInfo: {},
      clickSocketInfo: {},
      currentId: 0,
      isShowshadow: false,
      currentIndex: 0,
      localData: {
        bgImg: require('@/assets/img/16-GAMES/G26-click-zoom/lesson-38/background-board.svg'),
        titleInfo: {
          hanzi: '谁的个子高?',
          pinyin: 'Shéi de gèzi gāo?'
        },
        sentenceList: [
          {
            id: 1,
            bgImg: require('@/assets/img/16-GAMES/G26-click-zoom/lesson-38/menu-image-1.svg'),
            hanzi: '狗的个子高。',
            pinyin: 'Gǒu de gèzi gāo.'
          },
          {
            id: 2,
            bgImg: require('@/assets/img/16-GAMES/G26-click-zoom/lesson-38/menu-image-2.svg'),
            hanzi: '鸟的个子高。',
            pinyin: 'Niǎo de gèzi gāo.'
          },
          {
            id: 3,
            bgImg: require('@/assets/img/16-GAMES/G26-click-zoom/lesson-38/menu-image-3.svg'),
            hanzi: '爸爸的个子高。',
            pinyin: 'Bàba de gèzi gāo.'
          }
        ],
        picList: [
          {
            id: 1,
            menuImg: require('@/assets/img/16-GAMES/G26-click-zoom/lesson-38/menu-image-1.svg'),
            // menuGrayImg: require('@/assets/img/16-GAMES/G26-click-zoom/lesson-38/menu-image-1-bw.svg')
          },
          {
            id: 2,
            menuImg: require('@/assets/img/16-GAMES/G26-click-zoom/lesson-38/menu-image-2.svg'),
            // menuGrayImg: require('@/assets/img/16-GAMES/G26-click-zoom/lesson-38/menu-image-2-bw.svg')
          },
          {
            id: 3,
            menuImg: require('@/assets/img/16-GAMES/G26-click-zoom/lesson-38/menu-image-3.svg'),
            // menuGrayImg: require('@/assets/img/16-GAMES/G26-click-zoom/lesson-38/menu-image-3-bw.svg')
          }
        ]
      }
    }
  },
  watch: {
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20049,
          data: { value },
          text: 'ChoosePicBySentenceGame 点击menu socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        this.initData()
      },
      deep: true
    }
  },
  mounted() {
    // this.initData()
    this.$bus.$on('ClickSentenceMenu', ({ id }) => {
      this.handleClickPic(id, true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('ClickSentenceMenu')
  },

  methods: {
    initData() {
      // console.log(this.componentProps, '000')

      this.sentenceList = []
      this.picList = []
      if (this.componentProps.titlePy || this.componentProps.titleZh) {
        this.titleInfo = {
          pinyin: this.componentProps.titlePy,
          hanzi: this.componentProps.titleZh
        }
      }
      this.bgImg = this.componentProps.bgImg
      if (
        this.componentProps.picMenuChooseList &&
        this.componentProps.picMenuChooseList.length
      ) {
        this.componentProps.picMenuChooseList.forEach((item, index) => {
          this.sentenceList.push({
            id: index + 1,
            bgImg: item[0].value,
            hanzi: item[2].value,
            pinyin: item[1].value
          })
          this.picList.push({
            id: index + 1,
            menuImg: item[0].value
          })
        })
        // console.log(this.sentenceList,'sentence');
      }
    },
    handleClickPic(id, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickSocketInfo = {
          id,
          value: Math.random()
        }
      }
      if (id) {
        this.lock = true
        // console.log(id)
        this.currentId = id
        this.isShowshadow = true
        playCorrectSound(true, false)
        if (this.showIdList.indexOf(id) === -1) {
          this.showIdList.push(id)

          this.currentIndex++
        }
      } else {
        this.lock = false
        if (this.currentIndex === this.sentenceList.length) {
          startConfetti()
          playCorrectSound()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.choose-pic-sentence-container {
  width: 100%;
  height: 100%;
  line-height: 1;
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    background: #f7f3df;
    border-radius: 48px;
    .title-area {
      z-index: 9;
      position: absolute;
      top: 0;
      left: 0;
      top: 10%;
      width: 30%;
      height: 17%;
      background: #cd4c3f;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      color: #fff;
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }
    }
    .back-ground-img-area {
      width: 100%;
      height: 100%;
      position: relative;
      .bg-img {
        border-radius: 48px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .top-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 98%;
      }
    }
    .game-area,
    .pic-words-area {
      position: absolute;
      top: 0;
    }
    .pic-words-area {
      width: 100%;
      height: 100%;
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(159, 150, 150, 0.4); /* 灰色蒙层，透明度为40% */
        border-radius: 48px;
        z-index: 1;
      }

      .pic-box,
      .words-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .pic-box {
        // background-color: rgba(159, 150, 150, 0.4);
        // border-radius: 48px;
        .pic-item {
          position: absolute;
          // width: 100%;
          // height: 100%;
          left: 11%;
          top: 15%;
          border-radius: 45px;
          border: 20px solid #204f95;
          width: 65%;
          height: 45%;
          // transform: translateX(-50%);
          .pic-detail {
            width: calc(100% - 40px);
            height: calc(100% - 40px);
            border-radius: 25px;
            border: 20px solid #f4d737;
            box-shadow: inset 0 0 0 18px rgba(0, 0, 0, 0.1); /* 内阴影效果，模拟内侧圆角 */

          }

          img {
            position: absolute;
            width: calc(100% - 40px);
            height: calc(100% - 40px);
            // width: 100%;
            // height: 100%;
            // object-fit: cover;
            border-radius: 10px;
            z-index: 2;
          }
        }
      }
      .words-box {
        .hanzi-item {
          width: 50%;
          margin: 0 auto;
          background: #cd4c3f;
          position: absolute;
          bottom: 7%;
          left: 25%;
          padding: 1% 1%;
          color: #fff;
          border-radius: 40px;
          .sentence-hanzi,
          .sentence-pinyin {
            text-align: center;
          }
        }
        .noPadding {
          display: none;
        }
      }
    }
    .game-area {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;

      .pic-area {
        width: 100%;
        height: 100%;
        position: relative;
        flex: 4;
        cursor: pointer;
      }
      .side-menu-area {
        height: 70%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        z-index: 2;
        .menu-item {
          width: 80%;
          height: 22%;
          margin-top: 5%;

          img {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            border: 10px solid #cd4c3f;
            border-radius: 20px;
            object-fit: cover;

            cursor: pointer;
          }
          .hasClick {
            border: 10px solid #f4d737;
          }
          .grayColor {
            filter: grayscale(100%);
          }
        }
      }
    }
  }
}
</style>
