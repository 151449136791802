<template>
  <div class="wordcard-container">
    <div class="wordcard-content">
      <Star :totalStarNumber="wordCardList.length" :currentIndex="starNum" />
      <div class="wordcard-detail">
        <div class="title-area" v-if="Object.keys(titleInfo).length">
          <div class="title">
            <div class="title-pinyin pinyin font-pinyin-medium">
              {{ titleInfo.pinyin }}
            </div>
            <div class="title-hanzi font-hanzi-medium">
              {{ titleInfo.hanzi }}
            </div>
          </div>
        </div>

        <div
          class="word-card-item"
          v-for="(item, index) in wordCardList"
          :key="index + 'cards'"
        >
          <div
            class="contray-content"
            :class="{
              redColor: item.Showing,
              blueColor: !item.Showing,
              'card-1': index === 0,
              'card-2': index === 1,
              'card-3': index === 2,
              'card-4': index === 3,
              'card-5': index === 4
            }"
            @click="clickImage(index)"
          >
            <img :src="item.Showing ? item.imgShow : item.image" alt="" />
          </div>

          <div class="words-box">
            <div
              class="hanzi-item"
              v-for="(item, index) in wordCardList"
              :key="index"
              v-show="showHnaziIndex === index"
            >
              <div class="pinyin sentence-hanzi font-pinyin-medium">
                {{ item.pinyin }}
              </div>
              <div class="sentence-hanzi font-hanzi-medium">
                {{ item.hanzi }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Star from '@/components/Course/GamePage/Star'
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    Star
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          titlePy: '',
          titleZh: '',
          cardList: []
        }
      }
    }
  },
  watch: {
    clickImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20007,
          data: { value },
          text: 'wordCardsPage点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  mounted() {
    // this.initData()
    this.$bus.$on('clickSingleCard', ({ index }) => {
      this.clickImage(index, true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickSingleCard')
  },
  data() {
    return {
      localData: {
        titleInfo: {
          hanzi: '他/她是谁?',
          pinyin: 'Tā/tā shì shéi?'
        },
        wordCardList: [
          {
            pinyin: 'Tā shì Tútu.',
            hanzi: '他是图图。',
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-24/image-tutu.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-24/image-tutu-hidden.svg`),
            Showing: true
          },
          {
            pinyin: 'Tā shì Xiàxia.',
            hanzi: '她是夏夏。',
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-24/image-meimei.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-24/image-meimei-hidden.svg`),
            Showing: true
          },
          {
            pinyin: "Tā shì Xiǎo'ài.",
            hanzi: '她是小爱。',
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-24/image-xiaoai.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-24/image-xiaoai-hidden.svg`),
            Showing: true
          },
          {
            pinyin: 'Tā shì Dōngdōng.',
            hanzi: '他是冬冬。',
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-24/image-dongdong.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-24/image-dongdong-hidden.svg`),
            Showing: true
          },
          {
            pinyin: 'Tā shì Xiǎotiān.',
            hanzi: '他是小天。',
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-24/image-xiaotian.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-24/image-xiaotian-hidden.svg`),
            Showing: true
          }
        ]
      },
      titleInfo: {},
      wordCardList: [],
      starNum: 0,
      showHnaziIndex: false,
      isLastStep: false,
      selecteArr: [],
      clickImgSocketInfo: {},
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'speak',
            startNumberRange: 1,
            endNumberRange: 100
          }
        ]
      }
    }
  },
  methods: {
    initData() {
      // console.log(this.componentProps, '---')
      if (this.componentProps.titlePy || this.componentProps.titleZh) {
        this.titleInfo = {
          pinyin: this.componentProps.titlePy,
          hanzi: this.componentProps.titleZh
        }
      }
      if (this.componentProps.cardList && this.componentProps.cardList.length) {
        this.wordCardList = this.componentProps.cardList.map((item, index) => {
          return {
            image: item[3].value,
            pinyin: item[0].value,
            hanzi: item[1].value,
            Showing: true,
            imgShow: item[2].value
          }
        })
      }
    },
    clickImage(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickImgSocketInfo = {
          index,
          value: Math.random()
        }
      }
      //   this.$emit('changeTheImg', index)
      this.wordCardList[index].Showing = !this.wordCardList[index].Showing
      this.starNum++

      playCorrectSound(true, false)
      if (this.selecteArr.indexOf(index) === -1) {
        this.selecteArr.push(index)
      }
      this.showHnaziIndex = index
      if (this.selecteArr.length === this.wordCardList.length) {
        this.isLastStep = true
        playCorrectSound()
        startConfetti()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wordcard-container {
  width: 100%;
  height: 100%;
  .wordcard-content {
    background: url('../../../../assets/img/03-Backgrounds/background-practice.svg')
      no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .wordcard-detail {
      width: 60%;
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap-reverse;
      .title-area {
        z-index: 9;
        position: absolute;
        left: 0;
        top: 10%;
        width: 20%;
        height: 22%;
        background: #214e96;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 22px;
        color: #fff;
        .title {
          .title-pinyin,
          .title-hanzi {
            text-align: center;
          }
        }
      }
      .word-card-item {
        width: 29%;
        display: flex;
        flex-direction: column;
        margin: 0 2%;
        .frontage-area {
          position: absolute;
          top: 50%;
          left: 50%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          height: 70%;
        }
        p {
          line-height: 40px;
        }
        .redColor {
          background: #ce4c3f;
          border-bottom-color: #a53d32;
        }
        .blueColor {
          background: #224e96;
          border-bottom-color: #173b74;
        }
        .contray-content {
          width: 100%;
          height: 0;
          padding-bottom: 100%;
          margin-bottom: 10%;
          // background: #ce4c3f;
          position: relative;
          border-radius: 18px;
          // border-bottom: 15px solid #a53d32;
          border-bottom-width: 15px;
          border-bottom-style: solid;
          vertical-align: middle;
          cursor: pointer;
          &:hover {
            border-bottom: 15px solid #f6c752;
          }
          img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            margin-left: 20%;
            width: 60%;
          }
        }
        .card-1 {
          transform: rotate(6deg);
        }
        .card-2 {
          transform: rotate(-2deg);
        }
        .card-3 {
          transform: rotate(0deg);
        }
        .card-4 {
          transform: rotate(7deg);
        }
        .card-5 {
          transform: rotate(6deg);
        }
        .hanzi-item {
          width: 50%;
          margin: 0 auto;
          background: #214e96;
          position: absolute;
          bottom: 6%;
          left: 25%;
          padding: 1% 1%;
          color: #fff;
          border-radius: 40px;
          .sentence-hanzi,
          .sentence-pinyin {
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
