<template>
  <div
    class="game-summary-page-container"
    :style="{ backgroundImage: 'url(\'' + bgImg + '\')' }"
  >
    <div class="game-summary-page-background">
      <div class="summary-container">
        <div class="summary-title">
          <div
            class="title-box"
            v-for="(item, index) in summaryList"
            :key="item.id"
          >
            <p class="pinyin font-pinyin-large">{{ item.pinyin }}</p>
            <p class="font-hanzi-large">{{ item.hanzi }}</p>
          </div>
        </div>
        <div class="summary-word-area">
          <div class="word-item redBgColor">
            <div
              class="word-img"
              :style="{ backgroundImage: 'url(\'' + redSummaryImg + '\')' }"
            ></div>
            <div class="summary-content">
              <div
                class="summary-name"
                v-for="(item, index) in redSummaryList"
                :key="item.id"
              >
                <p class="pinyin font-pinyin-medium">
                  {{ item.pinyin }}
                </p>
                <p class="font-hanzi-medium">{{ item.hanzi }}</p>
              </div>
            </div>
          </div>
          <div class="word-item yellowBgColor">
            <div
              class="word-img"
              :style="{ backgroundImage: 'url(\'' + yellowSummaryImg + '\')' }"
            ></div>
            <div class="summary-content">
              <div
                class="summary-name fontBlue"
                v-for="(item, index) in yellowSummaryList"
                :key="item.id"
              >
                <p class="pinyin font-pinyin-medium">
                  {{ item.pinyin }}
                </p>
                <p class="font-hanzi-medium">{{ item.hanzi }}</p>
              </div>
            </div>
          </div>
          <div class="word-item blueBgColor">
            <div
              class="word-img"
              :style="{ backgroundImage: 'url(\'' + blueSummaryImg + '\')' }"
            ></div>
            <div class="summary-content">
              <div
                class="summary-name"
                v-for="(item, index) in blueSummaryList"
                :key="item.id"
              >
                <p class="pinyin font-pinyin-medium">
                  {{ item.pinyin }}
                </p>
                <p class="font-hanzi-medium">{{ item.hanzi }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          bgImg: '',
          redSummaryList: [],
          yellowSummaryList: [],
          blueSummaryList: []
        }
      }
    }
  },
  data() {
    return {
      redSummaryImg: '',
      yellowSummaryImg: '',
      blueSummaryImg: '',
      redSummaryList: [],
      yellowSummaryList: [],
      blueSummaryList: [],
      summaryList: [],
      bgImg: '',
      localData: {
        bgImg: require('../../../../assets/img/03-Backgrounds/sky-blue-background-image.jpg'),
        redSummaryImg: require('../../../../assets/img/16-GAMES/title-xiaoai.svg'),
        yellowSummaryImg: require('../../../../assets/img/16-GAMES/title-bb.svg'),
        blueSummaryImg: require('../../../../assets/img/16-GAMES/title-dongdong.svg'),
        redSummaryList: [
          {
            id: 1,
            pinyin: 'Xiǎo Ài',
            hanzi: '小爱'
          }
        ],
        yellowSummaryList: [
          {
            id: 1,
            pinyin: 'BB',
            hanzi: 'BB'
          }
        ],
        blueSummaryList: [
          {
            id: 1,
            pinyin: 'Dōngdōng',
            hanzi: '冬冬'
          }
        ],
        summaryList: [
          {
            id: 1,
            pinyin: 'Nǐ hǎo! Wǒ shì……',
            hanzi: '你好！我是……'
          }
        ]
      }
    }
  },
  watch: {
    componentProps: {
      handler(value) {
        // console.log(value, '这是watch的数据')
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
        let summaryTitleId = 0
        this.summaryList = value.summaryTitle.map((item) => {
          return {
            id: summaryTitleId++,
            pinyin: item[0].value,
            hanzi: item[1].value
          }
        })
        this.redSummaryImg = value.redSummaryImg
        this.yellowSummaryImg = value.yellowSummaryImg
        this.blueSummaryImg = value.blueSummaryImg
        let redSummaryListId = 0
        this.redSummaryList = value.redSummaryList.map((item) => {
          return {
            id: redSummaryListId++,
            pinyin: item[1].value,
            hanzi: item[0].value
          }
        })
        let yellowSummaryListId = 0
        this.yellowSummaryList = value.yellowSummaryList.map((item) => {
          return {
            id: yellowSummaryListId++,
            pinyin: item[1].value,
            hanzi: item[0].value
          }
        })
        let blueSummaryListId = 0
        this.blueSummaryList = value.blueSummaryList.map((item) => {
          return {
            id: blueSummaryListId++,
            pinyin: item[1].value,
            hanzi: item[0].value
          }
        })
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    initData() {
      this.bgImg = this.componentProps.bgImg
    }
  }
}
</script>
<style scoped lang="scss">
.game-summary-page-container {
  line-height: 1;
  width: 100%;
  height: 100%;
  border-radius: 56px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  -moz-background-size: 100% 100%;
  .game-summary-page-background {
    background: url('../../../../assets/img/03-Backgrounds/background-syllabus-01.svg')
      no-repeat;
    line-height: 1;
    width: 100%;
    height: 100%;
    border-radius: 56px;
    position: relative;
    background-position: center; /* 水平和垂直方向居中 */
    // background-size:90%; /* 背景图等比例放大并覆盖整个容器 */
    // background-image: url('@assets/img/03-Backgrounds/background-syllabus-01.svg');
  }
  .summary-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .summary-title {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #224e96;
    align-items: center;
    display: flex;
    margin-top: 5%;
  }
  .summary-word-area {
    width: 100%;
    display: flex;
    height: 60%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 3%;
    .word-item {
      background: #224e96;
      height: 80%;
      width: 70%;
      align-items: center;
      border-radius: 20px;
      display: flex;
      margin-bottom: 1%;
      justify-content: center;
      position: relative;
      .word-img {
        position: absolute;
        left: -1%;
        height: 55%;
        width: 13%;
        background: #f7f4e0;
        border-radius: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
      .summary-content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .summary-name {
          white-space: nowrap; // 确保文本不换行
          width: auto;
          color: #f7f4f4;
          text-align: center;
          cursor: default;
          margin-right: 1%;
        }
        .fontBlue {
          color: #224e96;
        }
      }
    }
    .redBgColor {
      background: #ce4c3f;
      // &:hover {
      //   background: #b5392c;
      // }
    }
    .yellowBgColor {
      background: #f4d738;
      // &:hover {
      //   background: #dec32c;
      // }
    }
    .blueBgColor {
      background: #214f96;
      // &:hover {
      //   background: #1b4589;
      // }
    }
  }
  .font-pinyin-medium {
    font-size: 20px !important;
    // very large screen
    @media screen and (max-width: 2200px) {
      // external screen
      font-size: 16px !important;
    }
    @media screen and (max-width: 970px) {
      // laptop
      font-size: 12px !important;
    }
    @media screen and (max-width: 840px) {
      // ipad
      font-size: 8px !important;
    }
  }

  .font-hanzi-medium {
    font-size: 30px !important;
    // very large screen
    @media screen and (max-width: 2200px) {
      // external screen
      font-size: 30px !important;
    }
    @media screen and (max-width: 1500px) {
      // laptop
      font-size: 27px !important;
    }
    @media screen and (max-width: 1300px) {
      // ipad
      font-size: 25px !important;
    }
  }
  .font-pinyin-large {
    font-size: 45px !important;
    // very large screen
    @media screen and (max-width: 2200px) {
      // external screen
      font-size: 30px !important;
    }
    @media screen and (max-width: 1500px) {
      // laptop
      font-size: 25px !important;
    }
    @media screen and (max-width: 1300px) {
      // ipad
      font-size: 15px !important;
    }
  }
  .font-hanzi-large {
    // font-size: 4.5em;
    font-size: 85px !important;
    // very large screen
    @media screen and (max-width: 2200px) {
      // external screen
      font-size: 65px !important;
    }
    @media screen and (max-width: 1500px) {
      // laptop
      font-size: 55px !important;
    }
    @media screen and (max-width: 1300px) {
      // ipad
      font-size: 36px !important;
      line-height: 35px; //verify  lesson 1 game 1 (cards)
    }
    font-weight: 500 !important;
  }
}
</style>
<style lang="scss">
.template-view-container ::v-deep {
}
</style>
