var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "speaking-page-container" }, [
    _c("div", { staticClass: "speaking-page-content" }, [
      _c(
        "div",
        {
          staticClass: "video-area",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.closeRecorder("socket")
            },
          },
        },
        [
          _c("video", {
            ref: "videoBox",
            staticClass: "video-player",
            attrs: { autoplay: false, poster: _vm.pageInfo.bgImg },
            on: { canplay: _vm.getDuration, timeupdate: _vm.toRecordPage },
          }),
        ]
      ),
      !_vm.isVideoPlay
        ? _c("div", { staticClass: "title-area" }, [
            _c("div", { staticClass: "title-content" }, [
              _c("div", { staticClass: "words pinyin font-pinyin-medium" }, [
                _vm._v(" " + _vm._s(_vm.pageInfo.titlePinyin) + " "),
              ]),
              _c("div", { staticClass: "words font-hanzi-medium" }, [
                _vm._v(" " + _vm._s(_vm.pageInfo.titleHanzi) + " "),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "content-area" },
        _vm._l(_vm.wordsInfoList, function (item, index) {
          return _c(
            "div",
            { key: index + "word", staticClass: "content-line" },
            _vm._l(item.options, function (word, wordIndex) {
              return _c(
                "div",
                { key: wordIndex + "worditem", staticClass: "word-item" },
                [
                  _c("span", { staticClass: "words pinyin" }, [
                    _vm._v(_vm._s(word.pinyin)),
                  ]),
                  _c("span", { staticClass: "words hanzi" }, [
                    _vm._v(_vm._s(word.hanzi)),
                  ]),
                ]
              )
            }),
            0
          )
        }),
        0
      ),
      _vm.isRecordPageOpen
        ? _c("div", { staticClass: "recorder-area" }, [
            _vm.isShowCheckGif
              ? _c("div", {
                  directives: [
                    {
                      name: "checkgif",
                      rawName: "v-checkgif",
                      value: _vm.checkGif,
                      expression: "checkGif",
                    },
                  ],
                  staticClass: "checkGif",
                  class: { checkGifWrong: !_vm.checkGif },
                })
              : _vm._e(),
            _c("div", { staticClass: "red-background-border" }),
            _c("div", { staticClass: "recorder-content" }, [
              _c("div", { staticClass: "words-area" }, [
                _c("div", { staticClass: "words-content" }, [
                  _c(
                    "div",
                    {
                      staticClass: "word-item",
                      class: {
                        watingStatus:
                          _vm.isFinishRecording &&
                          _vm.currentWordsInfo.status === 2,
                        correctColor:
                          _vm.currentWordsInfo.status != 0 &&
                          _vm.isFinishRecording,
                        wrongColor:
                          _vm.currentWordsInfo.status === 0 &&
                          _vm.isFinishRecording,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "words pinyin font-pinyin-medium" },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.currentWordsInfo.pinyin) + " "
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "words font-hanzi-medium" }, [
                        _vm._v(" " + _vm._s(_vm.currentWordsInfo.hanzi) + " "),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "disorganize" }, [
        _c("div", { staticClass: "turntable-area" }, [
          _c("img", {
            staticClass: "turntable-img",
            attrs: {
              src: require("@/assets/img/16-GAMES/G57-turntable/circle.svg"),
              alt: "",
            },
          }),
          _c("div", { staticClass: "needle-area" }, [
            _c("img", {
              staticClass: "glow-red",
              class: "rotateAnimation-" + _vm.clickIndex,
              attrs: {
                src: require("@/assets/img/16-GAMES/G57-turntable/needle.svg"),
                alt: "",
              },
              on: {
                click: function ($event) {
                  return _vm.startPlayingVideo("socket")
                },
              },
            }),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }