<template>
  <div class="game-scratch-pic">
    <div class="scratch-content">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="currentIndex"
        @changeTheNumbers="changeTheNumbers"
      >
      </SceneSwitchThumbnails>

      <div class="title-area" v-if="Object.keys(gameInfo).length">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ gameInfo.titlePinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ gameInfo.titleHanzi }}
          </div>
        </div>
      </div>

      <div class="scratch-area" @click="handleShowAnswer">
        <div class="scratch-img-area">
          <template v-for="(item, index) in gameList">
            <div
              class="img-item"
              :key="index"
              v-if="index === currentIndex - 1"
            >
              <!-- :class="item.frame ? 'yellow-frame' : 'red-frame'" -->
              <transition name="el-fade-in">
                <img :src="item.frame ? yellowFrame : redFrame" alt="" />
                <!-- <img :src="item.bgImg" alt="" /> -->
              </transition>
            </div>
          </template>
        </div>
      </div>

      <div class="erase-area">
        <div
          :class="
            position[currentIndex - 1].frame
              ? 'erase-shape-right'
              : 'erase-shape-left'
          "
        >
          <KidsWhiteBoard
            class="white-board"
            :displayColorIndex="currentColorIndex"
            :cursorStyle="true"
          />
        </div>
        <div
          :class="
            position[currentIndex - 1].frame
              ? 'erase-answer-right'
              : 'erase-answer-left'
          "
        >
          <div v-if="!isShowAnswer" @click="handleShowAnswer">
            <img
              class="show-answer-icon"
              :src="this.imgList[currentColorIndex - 1].image"
              alt=""
            />
          </div>
          <div v-else>
            <div class="answer-pinyin pinyin">
              {{ position[currentIndex - 1].pinyin }}
            </div>
            <div class="answer-hanzi YuantiBold">
              {{ position[currentIndex - 1].hanzi }}
            </div>
          </div>
          <div
            :class="
              position[currentIndex - 1].frame
                ? 'answer-photo-right'
                : 'answer-photo-left'
            "
            v-if="position[currentIndex - 1].photo"
          >
            <img :src="position[currentIndex - 1].photo" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KidsWhiteBoard from '@/components/Course/WhiteBoard/WhiteBoard.vue' // 白板组件件
import SceneSwitchThumbnails from '../GameComponent/SceneSwitchThumbnails.vue'
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    SceneSwitchThumbnails,
    KidsWhiteBoard
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          titleZh: '',
          titlePy: '',
          scratchPicList: []
        }
      }
    }
  },
  mounted() {
    // this.initData()
    this.$bus.$emit('startScratchPage', true)
    this.$bus.$on('ClickEraseMenu', ({ index }) => {
      this.changeTheNumbers(index, true)
    })
    this.$bus.$on('ClickEraseImg', () => {
      this.handleShowAnswer('socket', true)
    })
    this.$bus.$emit('chooseEraser')
  },
  watch: {
    clickMenuSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2005201,
          data: { value },
          text: 'ScratchPicGame点击菜单socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    clickImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2005202,
          data: { value },
          text: 'ScratchPicGame点击imgsocket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  beforeDestroy() {
    this.$bus.$off('ClickEraseMenu')
    this.$bus.$off('ClickEraseImg')
    this.$bus.$emit('startScratchPage', false)
  },
  data() {
    return {
      localData: {
        buttonList: [
          {
            image: require('@/assets/img/FixedPicture/blue-white-question.svg'),
            isCircle: true,
            startNumberRange: 1,
            endNumberRange: 1
          },
          {
            image: require('@/assets/img/FixedPicture/blue-red-question.svg'),
            isCircle: true,
            startNumberRange: 2,
            endNumberRange: 2
          },
          {
            image: require('@/assets/img/FixedPicture/blue-yellow-question.svg'),
            isCircle: true,
            startNumberRange: 3,
            endNumberRange: 3
          },
          {
            image: require('@/assets/img/FixedPicture/green-white-question.svg'),
            isCircle: true,
            startNumberRange: 4,
            endNumberRange: 4
          }
        ],

        gameInfo: {
          titlePinyin: 'Tā / Tā  shì shéi',
          titleHanzi: '他/她是谁?'
        },
        position: [
          {
            frame: false,
            pinyin: 'tí piàn yī',
            hanzi: '图片一',
            photo: require('@/assets/img/16-GAMES/G27-click-and-erase/image-1-1.svg')
          },
          {
            frame: true,
            pinyin: 'tí piàn èr',
            hanzi: '图片二',
            photo: require('@/assets/img/16-GAMES/G27-click-and-erase/image-2-1.svg')
          },
          {
            frame: false,
            pinyin: 'tí piàn dān',
            hanzi: '图片三',
            photo: require('@/assets/img/16-GAMES/G27-click-and-erase/image-3-1.svg')
          },
          {
            frame: true,
            pinyin: 'tí piàn sì',
            hanzi: '图片四',
            photo: require('@/assets/img/16-GAMES/G27-click-and-erase/image-4-1.svg')
          }
        ],
        gameList: [
          { frame: false },
          { frame: true },
          { frame: false },
          { frame: true }
        ]
        // gameList: [
        //   {
        //     bgImg: require('@/assets/img/16-GAMES/G27-click-and-erase/image-1-1.svg'),
        //     answerImg: require('@/assets/img/16-GAMES/G27-click-and-erase/image-1-2.svg')
        //   },
        //   {
        //     bgImg: require('@/assets/img/16-GAMES/G27-click-and-erase/image-2-1.svg'),
        //     answerImg: require('@/assets/img/16-GAMES/G27-click-and-erase/image-2-2.svg')
        //   },
        //   {
        //     bgImg: require('@/assets/img/16-GAMES/G27-click-and-erase/image-3-1.svg'),
        //     answerImg: require('@/assets/img/16-GAMES/G27-click-and-erase/image-3-2.svg')
        //   },
        //   {
        //     bgImg: require('@/assets/img/16-GAMES/G27-click-and-erase/image-4-1.svg'),
        //     answerImg: require('@/assets/img/16-GAMES/G27-click-and-erase/image-4-2.svg')
        //   }
        // ]
      },
      redFrame: require('@/assets/img/FixedPicture/red-photo-frame.svg'),
      yellowFrame: require('@/assets/img/FixedPicture/yellow-photo-frame.svg'),
      buttonList: [],
      imgList: [
        {
          id: 1,
          image: require('@/assets/img/FixedPicture/blue-white-question.svg')
        },

        {
          id: 2,
          image: require('@/assets/img/FixedPicture/blue-red-question.svg')
        },
        {
          id: 3,
          image: require('@/assets/img/FixedPicture/blue-yellow-question.svg')
        },
        {
          id: 4,
          image: require('@/assets/img/FixedPicture/green-white-question.svg')
        },
        {
          id: 5,
          image: require('@/assets/img/FixedPicture/yellow-white-question.svg')
        }
      ],
      currentIndex: 1,
      currentColorIndex: 1, // 1 yellow 2 blue  3  red
      clickMenuSocketInfo: {},
      clickImgSocketInfo: {},
      currentStepIndex: 0,
      curretnIndex: 0,
      position: [{ frame: false, pinyin: '', hanzi: '', photo: '' }],
      isShowAnswer: false,
      gameInfo: {},
      gameList: []
    }
  },
  methods: {
    initData() {
      console.log(this.componentProps, 'componentProps')
      if (this.componentProps.titlePy || this.componentProps.titleZh) {
        this.gameInfo = {
          titlePinyin: this.componentProps.titlePy,
          titleHanzi: this.componentProps.titleZh
        }
      }
      this.gameList = []
      this.buttonList = []
      this.position = []
      if (
        this.componentProps.scratchPicList &&
        this.componentProps.scratchPicList.length
      ) {
        this.componentProps.scratchPicList.forEach((item, index) => {
          this.gameList.push({
            frame: item[0].value,
            photo: item[1].value
            // answerImg: item[1].value
          })
          this.buttonList.push({
            image: this.imgList[index % this.imgList.length].image,
            isCircle: true,
            startNumberRange: index + 1,
            endNumberRange: index + 1
          })
          this.position.push({
            frame: item[0].value || false,
            hanzi: item[2].value || '',
            pinyin: item[3].value || '',
            photo: item[1].value || ''
          })
          // console.log(this.position, 'position')
        })
      }
      // console.log(this.gameList, 'gameList',this.buttonList);
    },
    changeTheNumbers(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random()
        }
      }
      setTimeout(() => {
        this.$bus.$emit('startScratchPage', true)
      }, 50)
      this.isShowAnswer = false
      // playCorrectSound(true, false)
      this.currentIndex = index
      this.currentColorIndex = index % 3 === 0 ? 3 : index % 3
      // console.log(index, this.currentColorIndex,'0000');
    },
    handleShowAnswer(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickImgSocketInfo = {
          value: Math.random()
        }
      }
      this.isShowAnswer = true
      playCorrectSound(true, false)
      if (this.currentIndex >= this.gameList.length) {
        startConfetti()
        playCorrectSound()
      }
    }
  }
}
</script>
<style lang="scss">

</style>>

</style>
<style lang="scss" scoped>

.game-scratch-pic {
  line-height: 1;
  width: 100%;
  height: 100%;
  .scratch-content {
    width: 100%;
    height: 100%;
    position: relative;
    background: #f7f4e0;
    border-radius: 58px;
    .title-area,
    .scratch-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .title-area {
      z-index: 11;
      top: 10%;
      width: auto;
      height: auto;
      padding: 20px 30px;
      background: #224e96;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
      color: #fff;
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }
    }
    .scratch-area {
      display: flex;
      justify-content: center;
      align-items: center;
      .scratch-img-area {
        width: 100%;
        height: 100%;
        max-width: 800px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .img-item {
          width: 100%;
          height: 100%;
          max-width: 800px;
          img {
            cursor: pointer;
            // border-radius: 58px;
            height: 100%;
            width: 100%;
            // object-fit: cover;
          }
          // .yellow-frame {
          //   scale: 0.9;
          // }
          // .red-frame {
          //   scale: 0.9;
          // }
        }
      }
    }
    .erase-area {
      width: 85%;
      height: 100%;
      max-width: 800px;
      z-index: 10;
      .erase-shape-left {
        // background: #000;
        position: absolute;
        border-radius: 4px;
        width: 18%;
        height: 23%;
        top: 54%; /* 移动到父容器的中间 */
        left: 50%; /* 移动到父容器的中间 */
        max-width: 180px;
        min-width: 180px;
        max-height: 170px;
        min-height: 170px;
        transform: translate(-50%, -50%) rotate(5deg);
        cursor: pointer;
        z-index: 100;
      }
      .erase-shape-right {
        // background: #000;
        position: absolute;
        border-radius: 4px;
        width: 24%;
        height: 28%;
        max-width: 180px;
        min-width: 180px;
        max-height: 175px;
        min-height: 175px;
        top: 55%; /* 移动到父容器的中间 */
        left: 50%; /* 移动到父容器的中间 */
        transform: translate(-50%, -50%) rotate(-7deg);
        cursor: pointer;
        z-index: 100;
      }
      // position: relative;
    }
    .erase-answer-left {
      position: absolute;
      bottom: 22%;
      left: 30%;
      width: 36%;
      text-align: center;
      transform: rotate(5deg);
      min-height: 60px;
      .answer-hanzi {
        font-size: 22px;
        color: #fff;
        text-align: center;
        line-height: 22px;
        margin-top: 8px;
      }
      .answer-pinyin {
        font-size: 18px;
        color: #fff;
        text-align: center;
      }
      .show-answer-icon {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        margin-top: 10px;
      }
    }
    .erase-answer-right {
      position: absolute;
      bottom: 21%;
      left: 32%;
      width: 40%;
      text-align: center;
      transform: rotate(-8deg);
      min-height: 60px;
      .answer-hanzi {
        font-size: 22px;
        color: #224e96;
        text-align: center;
        line-height: 22px;
        margin-top: 8px;
      }
      .answer-pinyin {
        font-size: 18px;
        color: #224e96;
        text-align: center;
      }
      .show-answer-icon {
        width: 40px;
        height: 40px;
        border-radius: 100%;
         margin-top: 10px;
      }
    }
    .answer-photo-left img {
      position: absolute;
      bottom: 140%;
      left: 26%;
      // width: 60%;
      object-fit: cover;
      z-index: 10;
      max-width: 170px;
      max-height: 170px;
    }
    .answer-photo-right img {
      position: absolute;
      bottom: 120%;
      left: 27%;
      // width: 60%;
      object-fit: cover;
      z-index: 10;
      max-width: 170px;
      max-height: 170px;
    }
    @media (min-width: 1680px) {
    .erase-answer-left {
      position: absolute;
      bottom: 26%;
      left: 30%;
      width: 36%;
      text-align: center;
      transform: rotate(5deg);
      min-height: 60px;
      .answer-hanzi {
        font-size: 22px;
        color: #fff;
        text-align: center;
        line-height: 22px;
        margin-top: 8px;
      }
      .answer-pinyin {
        font-size: 18px;
        color: #fff;
        text-align: center;
      }
      .show-answer-icon {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        margin-top: 5px;
      }
    }
    .erase-answer-right {
      position: absolute;
      bottom: 26%;
      left: 31%;
      width: 40%;
      text-align: center;
      transform: rotate(-8deg);
      min-height: 60px;
      .answer-hanzi {
        font-size: 22px;
        color: #224e96;
        text-align: center;
        line-height: 22px;
        margin-top: 4px;
      }
      .answer-pinyin {
        font-size: 18px;
        color: #224e96;
        text-align: center;
      }
      .show-answer-icon {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        margin-top: 10px;
      }
    }
    .answer-photo-left img {
      position: absolute;
      bottom: 130%;
      left: 29%;
      width: 60%;
      object-fit: cover;
      z-index: 10;
      max-width: 180px;
      max-height: 180px;
    }
    .answer-photo-right img {
      position: absolute;
      bottom: 115%;
      left: 32%;
      width: 60%;
      object-fit: cover;
      z-index: 10;
      max-width: 160px;
      max-height: 170px;
    }
    }
     @media (min-width: 1920px) {
    .erase-answer-left {
      position: absolute;
      bottom: 27%;
      left: 31%;
      width: 36%;
      text-align: center;
      transform: rotate(5deg);
      min-height: 60px;
      .answer-hanzi {
        font-size: 22px;
        color: #fff;
        text-align: center;
        line-height: 22px;
        margin-top: 8px;
      }
      .answer-pinyin {
        font-size: 18px;
        color: #fff;
        text-align: center;
      }
      .show-answer-icon {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        margin-top: 5px;
      }
    }
    .erase-answer-right {
      position: absolute;
      bottom: 26%;
      left: 31%;
      width: 40%;
      text-align: center;
      transform: rotate(-8deg);
      min-height: 60px;
      .answer-hanzi {
        font-size: 22px;
        color: #224e96;
        text-align: center;
        line-height: 22px;
        margin-top: 4px;
      }
      .answer-pinyin {
        font-size: 18px;
        color: #224e96;
        text-align: center;
      }
      .show-answer-icon {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        margin-top: 10px;
      }
    }
    .answer-photo-left img {
      position: absolute;
      bottom: 120%;
      left: 29%;
      width: 60%;
      object-fit: cover;
      z-index: 10;
      max-width: 180px;
      max-height: 180px;
    }
    .answer-photo-right img {
      position: absolute;
      bottom: 115%;
      left: 32%;
      width: 60%;
      object-fit: cover;
      z-index: 10;
      max-width: 160px;
      max-height: 170px;
    }
    }
  }
}
</style>
