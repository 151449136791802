var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-draw-by-img-container" }, [
    _c("div", { staticClass: "red-background-border" }),
    _c(
      "div",
      { staticClass: "write-hanzi-game-box" },
      [
        _c("PageButton", {
          attrs: {
            bottomCornerButtonList: _vm.bottomCornerButtonList,
            isLastStep: _vm.isLastStep,
          },
          on: { clickNext: _vm.nextPage },
          nativeOn: {
            click: function ($event) {
              return _vm.handleWrite.apply(null, arguments)
            },
          },
        }),
        _c(
          "div",
          {
            staticClass: "write-hanzi-button",
            class: {
              btnEyeHover: _vm.isBtnEyeHover,
              btnRestartHover: _vm.isBtnRestartHover,
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "btn-item eye-btn",
                on: {
                  click: _vm.handleClickEyeButton,
                  mouseenter: function ($event) {
                    _vm.isBtnEyeHover = true
                  },
                  mouseleave: function ($event) {
                    _vm.isBtnEyeHover = false
                  },
                },
              },
              [
                _vm.isShowTips
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/16-GAMES/G20-writing-hanzi/button-eye-on.svg"),
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@/assets/img/16-GAMES/G20-writing-hanzi/button-eye-off.svg"),
                        alt: "",
                      },
                    }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "btn-item restart-btn",
                on: {
                  click: function ($event) {
                    return _vm.restartGame("socket")
                  },
                  mouseenter: function ($event) {
                    _vm.isBtnRestartHover = true
                  },
                  mouseleave: function ($event) {
                    _vm.isBtnRestartHover = false
                  },
                },
              },
              [
                !_vm.eyeIsHover
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/16-GAMES/G20-writing-hanzi/button-restart.svg"),
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@/assets/img/16-GAMES/G20-writing-hanzi/button-restart.svg"),
                        alt: "",
                      },
                    }),
              ]
            ),
          ]
        ),
        _c("div", { staticClass: "write-hanzi-game-content layout-red-bgc" }, [
          _c("div", { staticClass: "write-game-content-left-box" }, [
            _c("div", { staticClass: "blue-background-border" }, [
              _c("div", { staticClass: "write-game-left-content" }, [
                _c("div", { staticClass: "hanzi-write-area" }, [
                  _c("span", { staticClass: "pinyin font-pinyin-large" }, [
                    _vm._v(_vm._s(_vm.strokePinYin)),
                  ]),
                  _vm.currentStrokeNum == 0 || !_vm.isShowTips
                    ? _c("div", { staticClass: "hengIcon" }, [
                        _c("img", {
                          staticClass: "stroke-square-box",
                          attrs: { src: _vm.strokeHanZiBgImg },
                        }),
                      ])
                    : _vm._e(),
                  _vm.isShowTips
                    ? _c(
                        "div",
                        { staticClass: "hengIcon-gif" },
                        _vm._l(_vm.strokeStepImgList, function (item, index) {
                          return _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: index + 1 === _vm.currentStrokeNum,
                                expression: "index + 1 === currentStrokeNum",
                              },
                            ],
                            key: index,
                            staticClass: "stroke-square-box",
                            attrs: { src: item },
                          })
                        }),
                        0
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "canvas-box",
                      class: { canvas: _vm.isStartWriting },
                      on: { click: _vm.handleSingleClick },
                    },
                    [_vm.showCanvas ? _c("KidsWhiteBoard") : _vm._e()],
                    1
                  ),
                ]),
                _vm.isShowTips
                  ? _c("div", { staticClass: "hanzi-stroke-tips-area" }, [
                      _c("img", {
                        staticClass: "templateOne",
                        attrs: { src: _vm.strokeStepImg, alt: "" },
                      }),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "write-game-content-right-box" }, [
            _c(
              "div",
              { staticClass: "steps-img-box" },
              [
                _vm._l(_vm.rightImgList, function (item, index) {
                  return [
                    _c("img", {
                      key: index,
                      staticClass: "cloud",
                      class: {
                        fadeOut: _vm.isFadeOut && index !== _vm.currentImgNum,
                        fadeIn: _vm.isFadeIn && index === _vm.currentImgNum,
                        isHidden: index > _vm.currentImgNum,
                      },
                      attrs: { src: item },
                    }),
                  ]
                }),
              ],
              2
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }