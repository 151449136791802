<template>
  <div class="game-basketball-container">
    <div class="basketball-box">
      <div class="basketball-game-area">
        <img class="bgIamge" />
        <div class="basketball-game-content">
          <div class="trumpter" @click="playAudio">
            <!-- <img class="volume-button" :src="volumeImgSrc" @ /> -->
            <AudioPlayer :isPlay="isStartPlaying" :audioUrl="songUrl" />
          </div>
          <img class="objectDisplay" :src="board" />

          <div v-if="playAnimation" class="ballDrop">
            <div class="image-container ball">
              <img class="img_basketball" :src="basketball" alt="" />
              <div class="centered-text pinyin" v-if="audioPy">
                {{ audioPy }}
              </div>
            </div>
          </div>
          <img class="basketDisplay" :src="basket" />
        </div>

        <div class="bottonCont">
          <div class="redBackground"></div>
          <div class="bottonsWrapper">
            <div
              v-for="(item, index) in chooseList"
              :key="index"
              class="option-item"
              :class="{
                shake:
                  item.id !== answerList[stepsIndex] &&
                  isClicked &&
                  index === chooseIndex
              }"
              @click="foundAnswer(index)"
            >
              <div v-if="item.showObj" class="image-container">
                <!-- <img  :src="item.bottomImg" alt="" /> -->
                <img class="img_basketball" :src="basketball" alt="" />
                <div class="centered-text pinyin" v-if="item.audioPy">
                  {{ item.audioPy }}
                </div>
              </div>

              <img
                v-else
                class="img_basketball"
                :src="basketball_gray"
                alt=""
              />
            </div>
          </div>
        </div>

        <Star :totalStarNumber="startNum" :currentIndex="stepsIndex" />
      </div>
    </div>
  </div>
</template>

<script>
import Star from '@/components/Course/GamePage/Star'
import AudioPlayer from '@/components/Course/GamePage/AudioPlayer'
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    Star,
    AudioPlayer
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          // imgOptionList: [],
          basketBallList: []
        }
      }
    }
  },
  data() {
    return {
      chooseList: [],
      answerList: [],
      optionList: [],
      audioList: [],
      audioPy: null,
      basketball: require('@/assets/img/FixedPicture/backetball.svg'),
      basketball_gray: require('@/assets/img/FixedPicture/basketball-gray.svg'),
      basket: require('@/assets/img/16-GAMES/G45-basketball/basket.svg'),
      board: require('@/assets/img/16-GAMES/G45-basketball/board.svg'),
      // basketball_transparent: require('@/assets/img/16-GAMES/G45-basketball/basketball-transparent.svg'),
      // shootball: require('@/assets/img/16-GAMES/G45-basketball/basketball-transparent.svg'),
      // volumeImgSrc: require('@/assets/img/06-Sound/sound-white.svg'),
      playAnimation: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'speak',
            startNumberRange: 0,
            endNumberRange: 0
          }
        ]
      },
      isLastStep: false,
      stepsIndex: 0,
      question: 0,

      basketballSocketInfo: {},
      audioSocketInfo: {},
      isClicked: false,
      chooseIndex: null,
      isStartPlaying: null,
      songUrl: null,
      startNum: null,
      localData: {
        answerList: [1, 2, 3, 4],
        chooseList: [
          {
            id: 1,
            audioPy: 'a',
            showObj: true
          },
          {
            id: 2,
            audioPy: 'o',
            showObj: true
          },
          {
            id: 3,
            audioPy: 'e',
            showObj: true
          },
          {
            id: 4,
            audioPy: 'i',
            showObj: true
          }
        ],
        optionList: [
          {
            id: 1,
            audioPy: 'a',
            showObj: true
          },
          {
            id: 2,
            audioPy: 'o',
            showObj: true
          },
          {
            id: 3,
            audioPy: 'e',
            showObj: true
          },
          {
            id: 4,
            audioPy: 'i',
            showObj: true
          },
          {
            id: 4,
            audioPy: 'u',
            showObj: true
          }
        ],
        audioList: ['', '', '', ''],
        startNum: 4
      }
    }
  },
  mounted() {
    // this.initData()
    this.$bus.$on('clickBasketballGame', ({ index }) => {
      this.foundAnswer(index, true)
    })
    this.$bus.$on('clickBasketballGameAudio', () => {
      this.playAudio('socket', true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickBasketballGame')
    this.$bus.$off('clickBasketballGameAudio')
  },
  watch: {
    basketballSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20035,
          data: { value },
          text: 'BasketballGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003501,
          data: { value },
          text: 'BasketballGame点击音频socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        this.initData()
      },
      deep: true
    }
  },
  methods: {
    initData() {
      console.log(this.componentProps, '00000000')
      this.optionList = []
      this.answerList = []
      this.audioList = []
      const list = []
      if (
        this.componentProps.imgOptionList &&
        this.componentProps.imgOptionList.length
      ) {
        this.componentProps.imgOptionList.forEach((item, index) => {
          list.push({
            bottomImg: item.value,
            id: index + 1,
            showObj: true
          })
        })
      }
      if (
        this.componentProps.basketBallList &&
        this.componentProps.basketBallList.length
      ) {
        this.componentProps.basketBallList.forEach((item, index) => {
          this.audioList.push({ audioUrl: item[0].value, id: index })
          this.answerList.push(index)
          this.optionList.push({
            id: index,
            audioPy: item[1].value,
            showObj: true
          })
        })
      }
      this.startNum = this.audioList.length
      this.chooseList = this.getFourElements(
        this.optionList,
        this.answerList[this.stepsIndex]
      )
      // this.chooseList.forEach((index) => {

      // })
      console.log(this.chooseList, 'this.chooseList')
    },
    playAudio(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.audioSocketInfo = {
          value: Math.random()
        }
      }
      if (this.stepsIndex !== this.answerList.length) {
        this.isStartPlaying = Math.random()
        this.songUrl = this.audioList[this.stepsIndex].audioUrl
      }

      console.log(this.songUrl, 'this.songUrl')
    },
    foundAnswer(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.basketballSocketInfo = {
          index,
          value: Math.random()
        }
      }
      this.chooseIndex = index
      console.log(this.chooseIndex, 'this.chooseIndex')
      console.log(this.chooseList, 'this.chooseList[index]')
      console.log(
        this.answerList,
        this.stepsIndex,
        index,
        'this.answerList[this.stepsIndex]'
      )
      if (
        this.answerList[this.stepsIndex] ===
        this.chooseList[this.chooseIndex].id
      ) {
        console.log('1232131')

        // this.shootball = this.optionList[this.question][index].bottomImg
        this.playAnimation = true
        this.audioPy = this.chooseList[index].audioPy
        playCorrectSound(true, false)
        this.stepsIndex++
        this.chooseList[index].showObj = false
        this.timer = setTimeout(() => {
          this.chooseList[index].showObj = true

          this.chooseList[index] = this.chooseList[index]

          this.playAnimation = false
          if (this.stepsIndex === this.answerList.length) {
            //   this.stepsIndex++
            // } else {
            this.isLastStep = true
            playCorrectSound()
            startConfetti()
          } else {
            this.chooseList = this.getFourElements(
              this.optionList,
              this.answerList[this.stepsIndex]
            )
          }
          this.playAudio('socket')
        }, 1500)
      } else {
        this.isClicked = true
        playCorrectSound(false)
        setTimeout(() => {
          this.isClicked = false
        }, 1000)
      }
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
      }
      return array
    },
    /**
     * 随机选择四个元素，其中有一个是正确答案，其他三个是错误答案
     * @param {Array} arr 所有元素
     * @param {Number} correctAnswer 正确答案id
     * @returns {Array} 四个元素的数组
     * @example
     **/
    getFourElements(arr, correctAnswer) {
      if (arr.length === 0) return []
      // 如果数组中的元素少于 4 个，直接返回打乱的整个数组
      if (arr.length <= 4) {
        console.log(
          arr.slice().sort(() => Math.random() - 0.5),
          'arr.slice().sort(() => Math.random() - 0.5)'
        )

        return arr.slice().sort(() => Math.random() - 0.5) // 使用 slice() 来创建副本
      }

      // 确保数组中包含正确答案，只取第一个匹配的元素
      let result = [arr.find((item) => item.id === correctAnswer)]

      // 创建剩余元素的副本，并过滤掉正确答案
      let remainingElements = arr.filter((item) => item.id !== correctAnswer)

      // 随机选择三个元素
      for (let i = 0; i < 3; i++) {
        let randomIndex = Math.floor(Math.random() * remainingElements.length)
        result.push(remainingElements[randomIndex])
        // 为了避免重复选择，移除已经选过的元素
        remainingElements.splice(randomIndex, 1)
      }

      // 随机打乱四个元素的顺序
      result.sort(() => Math.random() - 0.5)
      console.log(result, 'result')

      return result
    }
  }
}
</script>

<style lang="scss" scoped>
.game-basketball-container {
  width: 100%;
  height: 100%;
  .basketball-box {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 48px;
    position: relative;
    overflow: hidden;
    .basketball-game-area {
      text-align: -webkit-center;
      position: absolute;
      height: 100%;
      width: 100%;
      background: #f9f6e6;
      border-radius: 48px;
      margin: auto;
      overflow: hidden;
      .bgIamge {
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      .basketball-game-content {
        position: relative;
        width: -moz-fit-content;
        width: 100%;
        top: 17%;
        height: calc(80% - 150px);
        justify-content: center;
        z-index: 0;
        border-radius: 10%;
        display: flex;
        .trumpter {
          position: absolute;
          left: 45%;
          top: 6%;
          width: 7vh;
          height: 7vh;
          z-index: 1000;
          background-color: #224e96;
          border-radius: 50%;
          text-align: center;
          .volume-button {
            cursor: pointer;
          }
          img {
            width: 5vh;
            height: 5vh;
          }
        }
        .trumpter:hover {
          background-color: #1b4589;
        }
        .objectDisplay {
          position: absolute;
          height: 70%;
          width: 100%;
          z-index: 300;
        }
        .ballDrop {
          height: 100%;
          // width: 13%;
          z-index: 100;

          .ball {
            width: 100%;
            animation: ballAnim 3s 1;
            animation-direction: normal;
          }

          @keyframes ballAnim {
            0% {
              margin-top: 0;
            }

            100% {
              margin-top: 500%;
            }
          }
        }
        .basketDisplay {
          position: absolute;
          top: 55%;
          height: 50%;
          width: 100%;
          z-index: 200;
        }
      }
      .bottonCont {
        bottom: 0;
        width: 80%;
        /* margin-top: 45%; */
        height: 150px;
        position: absolute;
        display: flex;
        justify-content: center;
        left: 10%;
        right: 10%;
        .redBackground {
          width: 100%;
          height: 50%;
          border-top-right-radius: 30px;
          border-top-left-radius: 30px;
          background-color: #d87065;
          position: absolute;
          bottom: 0;
        }
        .bottonsWrapper {
          display: flex;
          bottom: 0;
          height: -moz-fit-content;
          justify-content: space-between;
          height: 100%;
          width: 78%;
          position: absolute;

          border-top-right-radius: 30px;
          border-top-left-radius: 30px;
          background-color: #ffffff;
          justify-content: space-evenly;
          align-items: center;
          padding: 10px 20px;
          .option-item {
            max-width: 27%;
            min-width: 100px;
            min-height: 100px;
            z-index: 1;
            border-radius: 15px;

            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            // span {
            //   color: #fff;
            //   font-size: 25px;
            // }
            // span:nth-child(2) {
            //   color: #fff;
            //   font-size: 30px;
            // }
            // img {
            //   max-width: 80%;
            // }
          }
          .option-item:active {
            transform: scale(0.95);
          }
          .option-item:hover {
            min-width: 100px;
            min-height: 100px;
            border-radius: 15px;
            border-bottom: 0px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            span {
              color: #fff;
              font-size: 25px;
            }
            span:nth-child(2) {
              color: #fff;
              font-size: 30px;
            }
          }
        }
      }
    }
  }
  .image-container {
    position: relative;
    display: inline-block;
    .img_basketball {
      width: 100px !important;
      height: 100px !important;
    }
    .img_basketball_grgy {
      width: 100px !important;
      height: 100px !important;
    }
  }

  // .image-container img {
  //   width: 100%;
  //   height: auto;
  // }

  .centered-text {
    position: absolute;
    top: 48%;
    left: 48%;
    transform: translate(-50%, -50%);
    color: white; /* 根据图片背景调整文字颜色 */
    font-size: 42px; /* 根据需要调整字体大小 */
    text-align: center;
    // margin-top: -4px;
    font-weight: 600;
  }
}
</style>
