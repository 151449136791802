var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "title-area",
      class: {
        redColor: _vm.titleInfo.bgColor === "redColor",
        blueColor: _vm.titleInfo.bgColor === "blueColor",
      },
    },
    [
      _c("div", { staticClass: "title" }, [
        _c("div", { staticClass: "title-pinyin pinyin font-pinyin-medium" }, [
          _vm._v(" " + _vm._s(_vm.titleInfo.pinyin) + " "),
        ]),
        _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
          _vm._v(" " + _vm._s(_vm.titleInfo.hanzi) + " "),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }