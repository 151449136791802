<template>
  <div class="speaking-sentence-container">
    <div class="speaking-sentence-content">
      <div class="title-area" v-if="Object.keys(titleInfo).length">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ titleInfo.hanzi }}
          </div>
        </div>
      </div>
      <div class="bgImg-area">
        <template v-for="(item, index) in gameList">
          <div
            class="img-box"
            :key="index"
            v-if="index + 1 === currentIndex"
            @click="handleClickImg(index)"
          >
            <transition name="el-fade-in">
              <img :src="item.bgImg" alt="" />
            </transition>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          titleZh: '',
          titlePy: '',
          imgList: []
        }
      }
    }
  },
  mounted() {
    // this.dataInit()
    this.$bus.$on('clickSpeakingSentenceImg', ({ index }) => {
      this.handleClickImg('socket', index, true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickSpeakingSentenceImg')
  },
  watch: {
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  data() {
    return {
      titleInfo: {
        // pinyin: 'Shéi?',
        // hanzi: '谁？'
      },
      currentIndex: 1,
      clickIndex: 1,
      isLastStep: false,
      gameList: [],
      lock: false,
      clickImgSocketInfo: {},
      localData: {
        titleInfo: {
          pinyin: 'Shéi?',
          hanzi: '谁？'
        },
        gameList: [
          {
            bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-25-1/image-0.svg`)
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-25-1/image-1.svg`)
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-25-1/image-2.svg`)
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-25-1/image-3.svg`)
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-25-1/image-4.svg`)
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-25-1/image-5.svg`)
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-25-1/image-6.svg`)
          }
        ]
      }
    }
  },
  methods: {
    initData() {
      this.gameList = []
      if (this.componentProps.titleZh || this.componentProps.titlePy) {
        this.titleInfo = {
          pinyin: this.componentProps.titlePy,
          hanzi: this.componentProps.titleZh
        }
      }
      if (this.componentProps.imgList && this.componentProps.imgList.length) {
        this.componentProps.imgList.forEach((item) => {
          this.gameList.push({
            bgImg: item.value
          })
        })
      }
    },
    handleClickImg(index, socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickImgSocketInfo = {
          value: Math.random()
        }
      }
      this.lock = false
      if (this.currentIndex == this.gameList.length) {
        return
      }
      this.currentIndex++

      // console.log(this.lock)
      if (this.lock) {
        return
      }
      playCorrectSound(true, false)
      this.lock = true
      if (this.currentIndex === this.gameList.length) {
        this.isLastStep = true
        playCorrectSound()
        startConfetti()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.speaking-sentence-container {
  line-height: 0;
  width: 100%;
  height: 100%;
  .speaking-sentence-content {
    width: 100%;
    height: 100%;
    position: relative;
    .bgImg-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .img-box {
        cursor: pointer;
        width: 100%;
        height: 100%;
        img {
          border-radius: 58px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .title-area {
      z-index: 9;
      position: absolute;
      top: 0;
      left: 0;
      top: 10%;
      min-width: 18%;
      height: 17%;
      background: #cd4c3f;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      color: #fff;
      width: fit-content;
      padding: 10px 25px;
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }
    }
  }
}
</style>
