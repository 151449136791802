var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "game-choose-words-container",
      style: { backgroundImage: "url('" + _vm.bgImg + "')" },
    },
    [
      _c("div", { staticClass: "left-container" }, [
        _c("div", { staticClass: "phone-top" }, [
          _c("p", { staticClass: "left-pinyin" }, [
            _vm._v(_vm._s(_vm.phonePinyin.join(" "))),
          ]),
          _c("p", { staticClass: "left-hanzi" }, [
            _vm._v(_vm._s(_vm.phoneHanzi.join(""))),
          ]),
          _c("img", {
            attrs: {
              src: require("@/assets/img/16-GAMES/G23-phone-number/delete.svg"),
              alt: "",
            },
            on: { click: _vm.handleDeleteWord },
          }),
        ]),
        _c(
          "div",
          { staticClass: "phone-content-wrapper" },
          _vm._l(
            _vm.processedLevels[_vm.currentLevelIndex] &&
              _vm.processedLevels[_vm.currentLevelIndex].jsonData,
            function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "phone-content",
                  on: {
                    click: function ($event) {
                      return _vm.handleImageClick(item)
                    },
                    mouseover: function ($event) {
                      _vm.hoveredItem = index
                    },
                    mouseleave: function ($event) {
                      _vm.hoveredItem = null
                    },
                  },
                },
                [
                  _c("div", { staticClass: "img-item" }, [
                    _c("img", {
                      attrs: {
                        src:
                          index === _vm.hoverId ? _vm.hoverImg : _vm.defaultImg,
                        alt: "",
                      },
                      on: {
                        mouseenter: function ($event) {
                          _vm.getWordsId(index, (_vm.isHover = true))
                        },
                        mouseleave: function ($event) {
                          _vm.getWordsId(index, (_vm.isHover = false))
                        },
                      },
                    }),
                    _c("div", { staticClass: "text-container" }, [
                      _c("span", { staticClass: "pinyin" }, [
                        _vm._v(_vm._s(item.pinyin)),
                      ]),
                      _c("span", { staticClass: "hanzi" }, [
                        _vm._v(_vm._s(item.hanzi)),
                      ]),
                    ]),
                  ]),
                ]
              )
            }
          ),
          0
        ),
        _c(
          "div",
          { staticClass: "btn", on: { click: _vm.handleConfirmAnswer } },
          [
            _c("img", {
              attrs: {
                src: _vm.isCorrect
                  ? require("@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-submit-green.svg")
                  : require("@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-submit-red.svg"),
                alt: "",
              },
            }),
          ]
        ),
      ]),
      _c("div", { staticClass: "right-container" }, [
        _c("div", { staticClass: "right-img" }, [
          _vm.processedLevels[_vm.currentLevelIndex].img
            ? _c("img", {
                attrs: {
                  src:
                    _vm.processedLevels[_vm.currentLevelIndex] &&
                    _vm.processedLevels[_vm.currentLevelIndex].img,
                  alt: "",
                },
              })
            : _vm._e(),
        ]),
        _c(
          "div",
          {
            staticClass: "speaker-wrapper",
            on: { click: _vm.handlePlayPinyinAudio },
          },
          [
            _c("AudioPlayer", {
              attrs: {
                isPlay: _vm.isStartPlaying,
                audioUrl:
                  _vm.processedLevels[_vm.currentLevelIndex] &&
                  _vm.processedLevels[_vm.currentLevelIndex].audioSrc,
                title:
                  _vm.processedLevels[_vm.currentLevelIndex] &&
                  _vm.processedLevels[_vm.currentLevelIndex].answer,
              },
            }),
          ],
          1
        ),
        _vm._m(0),
        _c("div", { staticClass: "right-bottom" }, [
          _vm.processedLevels[_vm.currentLevelIndex] &&
          _vm.processedLevels[_vm.currentLevelIndex].img === ""
            ? _c("p", { staticClass: "right-hanzi" }, [_vm._v(" ? ")])
            : _vm._e(),
        ]),
      ]),
      _c("Star", {
        attrs: {
          totalStarNumber: _vm.processedLevels.length,
          currentIndex: _vm.starIndex,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right-bottom" }, [
      _c("p", { staticClass: "right-pinyin" }),
      _c("p", { staticClass: "right-hanzi" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }