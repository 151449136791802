<template>
  <div class="game-practice-word-container">
    <div class="word-content">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="currentIndex"
        @changeTheNumbers="changeTheNumbers"
      />

      <div class="choose-hanzi-box">
        <div
          class="answer-area"
          v-if="chooseHanziList && chooseHanziList.length"
        >
          <div
            class="answer-img"
            v-if="chooseHanziList && chooseHanziList.length"
          >
            <img :src="chooseHanziList[currentIndex].bgImg" alt="" />
          </div>
          <div class="answer-hanzi-area" v-if="!isShowAllWords">
            <div
              class="hanzi-item"
              v-for="(item, index) in chooseHanziList[currentIndex]
                .hanziRightList"
              :key="index + 'right'"
              :class="{
                active: insertIndex === index,
                hasInserted: rightHanziIndexArr.indexOf(index + 1) > -1
              }"
              v-show="
                chooseHanziList &&
                chooseHanziList[currentIndex].hanziRightList &&
                chooseHanziList[currentIndex].hanziRightList.length
              "
            >
              <span class="pinyin font-pinyin-medium">{{ item.pinyin }}</span>
              <span class="font-hanzi-medium">{{ item.hanzi }}</span>
            </div>
          </div>

          <div class="answer-right-hanzi-area" v-else>
            <div class="right-hanzi-box">
              <span class="pinyin font-pinyin-medium">{{
                chooseHanziList[currentIndex].wordsPinyin
              }}</span>
              <span class="font-hanzi-medium">{{
                chooseHanziList[currentIndex].wordsHanzi
              }}</span>
            </div>
          </div>
        </div>

        <!-- 无提示部分 -->

        <div
          class="choose-hanzi-area"
          v-if="chooseHanziList && chooseHanziList.length"
        >
          <div
            class="hanzi-item"
            v-for="(item, index) in chooseHanziList[currentIndex]
              .hanziChooseList"
            :key="index + 'choose'"
            :class="{ dispear: choosedHanziArr.indexOf(index) > -1 }"
            @click="handleChooseHanzi(item.id, index)"
          >
            <span class="pinyin font-pinyin-medium">{{ item.pinyin }}</span>
            <span class="font-hanzi-medium">{{ item.hanzi }}</span>
          </div>
        </div>
      </div>
    </div>
    <CommonButton @shuffleClickEvent="handleShuffleClickEvent" />
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import SceneSwitchThumbnails from '../GameComponent/SceneSwitchThumbnails.vue'
import { templatePreviewMixin } from './templatePreviewMixin.js'
import CommonButton from '../GameComponent/CommonButton.vue'
export default {
  mixins: [templatePreviewMixin],
  components: {
    SceneSwitchThumbnails,
    CommonButton
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          practiceImgList: []
        }
      }
    }
  },
  data() {
    return {
      localData: {
        buttonList: [
          {
            image: require('@/assets/img/01-Menu/menu-1.svg'),
            startNumberRange: 0,
            endNumberRange: 0
          },
          {
            image: require('@/assets/img/01-Menu/menu-2.svg'),
            startNumberRange: 1,
            endNumberRange: 1
          },
          {
            image: require('@/assets/img/01-Menu/menu-3.svg'),
            startNumberRange: 2,
            endNumberRange: 2
          },
          {
            image: require('@/assets/img/01-Menu/menu-4.svg'),
            startNumberRange: 3,
            endNumberRange: 3
          },
          {
            image: require('@/assets/img/01-Menu/menu-5.svg'),
            startNumberRange: 4,
            endNumberRange: 4
          }
        ],
        imgList: [
          {
            id: 1,
            image: require('@/assets/img/FixedPicture/1.svg')
          },
          {
            id: 2,
            image: require('@/assets/img/FixedPicture/2.svg')
          },
          {
            id: 3,
            image: require('@/assets/img/FixedPicture/3.svg')
          },
          {
            id: 4,
            image: require('@/assets/img/FixedPicture/4.svg')
          },
          {
            id: 5,
            image: require('@/assets/img/FixedPicture/5.svg')
          }
        ],
        chooseHanziList: [
          {
            bgImg: require(`@/assets/img/16-GAMES/G31-make-sentences/lesson-10/story-1.svg`),
            wordsPinyin: 'Wǒ ài nǐ.',
            wordsHanzi: '我爱你。',
            hanziRightList: [
              {
                pinyin: 'wǒ',
                hanzi: '我',
                id: 2
              },
              {
                pinyin: 'ài',
                hanzi: '爱',
                id: 3
              },
              {
                pinyin: 'nǐ',
                hanzi: '你',
                id: 1
              }
            ],
            hanziChooseList: [
              {
                pinyin: 'nǐ',
                hanzi: '你',
                id: 1
              },
              {
                pinyin: 'wǒ',
                hanzi: '我',
                id: 2
              },
              {
                pinyin: 'ài',
                hanzi: '爱',
                id: 3
              }
            ]
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G31-make-sentences/lesson-10/story-2.svg`),
            wordsPinyin: 'Wǒ ài lǎoshī.',
            wordsHanzi: '我爱老师。',
            hanziRightList: [
              {
                pinyin: 'wǒ',
                hanzi: '我',
                id: 2
              },
              {
                pinyin: 'ài',
                hanzi: '爱',
                id: 3
              },
              {
                pinyin: 'lǎoshī',
                hanzi: '老师',
                id: 1
              }
            ],
            hanziChooseList: [
              {
                pinyin: 'lǎoshī',
                hanzi: '老师',
                id: 1
              },
              {
                pinyin: 'wǒ',
                hanzi: '我',
                id: 2
              },
              {
                pinyin: 'ài',
                hanzi: '爱',
                id: 3
              }
            ]
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G31-make-sentences/lesson-10/story-3.svg`),
            wordsPinyin: 'Wǒ ài māma.',
            wordsHanzi: '我爱妈妈。',
            hanziRightList: [
              {
                pinyin: 'wǒ',
                hanzi: '我',
                id: 2
              },
              {
                pinyin: 'ài',
                hanzi: '爱',
                id: 3
              },
              {
                pinyin: 'māma',
                hanzi: '妈妈',
                id: 1
              }
            ],
            hanziChooseList: [
              {
                pinyin: 'māma',
                hanzi: '妈妈',
                id: 1
              },
              {
                pinyin: 'wǒ',
                hanzi: '我',
                id: 2
              },
              {
                pinyin: 'ài',
                hanzi: '爱',
                id: 3
              }
            ]
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G31-make-sentences/lesson-10/story-4.svg`),
            wordsPinyin: 'Wǒ ài bàba.',
            wordsHanzi: '我爱爸爸。',
            hanziRightList: [
              {
                pinyin: 'wǒ',
                hanzi: '我',
                id: 2
              },
              {
                pinyin: 'ài',
                hanzi: '爱',
                id: 3
              },
              {
                pinyin: 'bàba',
                hanzi: '爸爸',
                id: 1
              }
            ],
            hanziChooseList: [
              {
                pinyin: 'ài',
                hanzi: '爱',
                id: 3
              },
              {
                pinyin: 'wǒ',
                hanzi: '我',
                id: 2
              },
              {
                pinyin: 'bàba',
                hanzi: '爸爸',
                id: 1
              }
            ]
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G31-make-sentences/lesson-10/story-5.svg`),
            wordsPinyin: 'Wǒ ài wǒ jiā.',
            wordsHanzi: '我爱我家。',
            hanziRightList: [
              {
                pinyin: 'wǒ',
                hanzi: '我',
                id: 1
              },
              {
                pinyin: 'ài',
                hanzi: '爱',
                id: 2
              },
              {
                pinyin: 'wǒ',
                hanzi: '我',
                id: 1
              },
              {
                pinyin: 'jiā',
                hanzi: '家',
                id: 3
              }
            ],
            hanziChooseList: [
              {
                pinyin: 'jiā',
                hanzi: '家',
                id: 3
              },
              {
                pinyin: 'wǒ',
                hanzi: '我',
                id: 1
              },
              {
                pinyin: 'ài',
                hanzi: '爱',
                id: 2
              },
              {
                pinyin: 'wǒ',
                hanzi: '我',
                id: 1
              }
            ]
          }
        ]
      },
      buttonList: [],
      imgList: [
        {
          id: 1,
          image: require('@/assets/img/FixedPicture/1.svg')
        },
        {
          id: 2,
          image: require('@/assets/img/FixedPicture/2.svg')
        },
        {
          id: 3,
          image: require('@/assets/img/FixedPicture/3.svg')
        },
        {
          id: 4,
          image: require('@/assets/img/FixedPicture/4.svg')
        },
        {
          id: 5,
          image: require('@/assets/img/FixedPicture/5.svg')
        }
      ],
      chooseHanziList: [],
      isShowAllWords: false,
      rightHanziIndexArr: [],
      lockIndex: 0,
      choosedHanziArr: [],
      insertIndex: 0,
      hanziSocketInfo: {},
      clickMenuSocketInfo: {},
      currentIndex: 0,
      currentStarIndex: 0,
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'look',
            startNumberRange: 0,
            endNumberRange: 100
          }
        ]
      },
      shuffleSocketValue: null,
      shuffleSocketInfo: {}
    }
  },
  mounted() {
    // this.initData()
    this.$bus.$on('clickToChooseWords', ({ id, index }) => {
      this.handleChooseHanzi(id, index, true)
    })
    this.$bus.$on('chooseWordsByPicGameClickMenu', ({ index }) => {
      this.changeTheNumbers(index, true)
    })
    this.$bus.$on("shuffleArray", ({ ramdomList }) => {
      this.chooseHanziList[this.currentIndex].hanziChooseList  = [];
      this.chooseHanziList[this.currentIndex].hanziChooseList  = ramdomList;

      this.shuffleSocketValue = true;
    });
  },
  beforeDestroy() {
    this.$bus.$off('clickToChooseWords')
    this.$bus.$off('chooseWordsByPicGameClickMenu')
    this.$bus.$off('shuffleArray')
  },
  watch: {
    hanziSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20043,
          data: { value },
          text: 'ChooseWordsByPicGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    clickMenuSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004301,
          data: { value },
          text: 'ChooseWordsByPicGame点击 menu socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    },
    shuffleSocketInfo: {
      handler(value) {
        if (!this.shuffleSocketValue) {
          const data = {
            clickType: 2001004,
            data: { value },
            text: "DragGameBtn shuffleSocketValue",
          };
          console.log(data);
          this.$bus.$emit("kids_webSocket_sendInfo", data);
        }
      },
      deep: true,
    },
  },
  methods: {
    /**
     * @description: 乱序回调
     * @return {*}
     */
    handleShuffleClickEvent() {
      console.log('乱序')
      const orderList = this.shuffleArray(this.chooseHanziList[this.currentIndex].hanziChooseList)
      this.chooseHanziList[this.currentIndex].hanziChooseList = []
      this.chooseHanziList[this.currentIndex].hanziChooseList = orderList

      this.shuffleSocketValue = false

      this.shuffleSocketInfo = {
        ramdomList: this.chooseHanziList[this.currentIndex].hanziChooseList,
        ramdomIndex: Math.random()
      }
    },
    /**
     * @description: 数组乱序
     * @param {*} array
     * @return {*}
     */
    shuffleArray(array) {
      let currentIndex = array.length,
        temporaryValue,
        randomIndex

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1

        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
      }
      console.log(array)

      return array
    },
    initData() {
      // console.log(this.componentProps, '---')
      this.buttonList = []
      this.chooseHanziList = []
      if (
        this.componentProps.practiceImgList &&
        this.componentProps.practiceImgList.length
      ) {
        this.componentProps.practiceImgList.forEach((item, index) => {
          this.buttonList.push({
            image: this.imgList[index % this.imgList.length].image,
            startNumberRange: index,
            endNumberRange: index
          })
          const pinyinList = []
          if (item[2].value && item[2].value.length) {
            item[2].value.forEach((ele) => {
              item[1].value.forEach((i) => {
                if (i[1].value === ele) {
                  pinyinList.push(i[0].value)
                }
              })
            })
          }
          const hanziRightList = []
          item[2].value && item[2].value.length
            ? item[2].value.map((ele, eleIndex) => {
                item[1].value.forEach((i, Iindex) => {
                  if (i[1].value === ele) {
                    hanziRightList.push({
                      pinyin: i[0].value,
                      hanzi: i[1].value,
                      id: Iindex
                    })
                  }
                })
              })
            : []
          // console.log(pinyinList,'pinyinList')
          this.chooseHanziList.push({
            bgImg: item[0].value,
            wordsPinyin:
              pinyinList && pinyinList.length ? pinyinList.join('') : '',
            wordsHanzi:
              item[2].value && item[2].value.length
                ? item[2].value.join('')
                : '',
            hanziRightList: hanziRightList,
            hanziChooseList:
              item[1].value && item[1].value.length
                ? item[1].value.map((ele, eleIndex) => {
                    return {
                      pinyin: ele[0].value,
                      hanzi: ele[1].value,
                      id: eleIndex
                    }
                  })
                : []
          })
        })
        // console.log(this.chooseHanziList, 'this.chooseHanziList')
      }
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
      }
      return array
    },
    changeTheNumbers(index, isFromSocket = false) {
      this.currentIndex = index
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random()
        }
      }
      this.initGame()
    },
    initGame() {
      this.rightHanziIndexArr = []
      this.lockIndex = 0
      this.choosedHanziArr = []
      this.insertIndex = 0
      this.isShowAllWords = false
    },
    handleChooseHanzi(id, index, isFromSocket = false) {
      if (!isFromSocket) {
        this.hanziSocketInfo = {
          id,
          index,
          value: Math.random()
        }
      }
      if (
        id ===
        this.chooseHanziList[this.currentIndex].hanziRightList[this.lockIndex]
          .id
      ) {
        this.choosedHanziArr.push(index)
        setTimeout(() => {
          this.lockIndex++
          this.insertIndex++
          this.rightHanziIndexArr.push(this.lockIndex)
          this.checkToNext()
        }, 10)
      } else {
        playCorrectSound(false)
      }
    },
    checkToNext() {
      if (
        this.lockIndex >=
        this.chooseHanziList[this.currentIndex].hanziRightList.length
      ) {
        this.isShowAllWords = true
        playCorrectSound(true, false)
        this.nextPart()
      }
    },
    nextPart() {
      if (this.currentIndex >= this.chooseHanziList.length - 1) {
        startConfetti()
        playCorrectSound()
        this.isLastStep = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.game-practice-word-container {
  width: 100%;
  height: 100%;
  .word-content {
    width: 100%;
    height: 100%;
    position: relative;
    background: #f7f4e0;
    border-radius: 58px;
    display: flex;
    flex-direction: column;
    .choose-hanzi-box {
      width: 100%;
      height: 100%;
      .answer-area {
        height: 70%;
        display: flex;
        align-items: center;
        .answer-img {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 2;
          img {
            width: 70%;
            transform: rotate(-10deg);
          }
        }
        .answer-right-hanzi-area {
          height: 100%;
          flex: 3;
          display: flex;
          align-items: center;
          justify-content: center;
          .right-hanzi-box {
            border-radius: 30px;
            background: #224e96;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 130px;
            padding: 20px 70px;
            span {
              display: inline-block;
              color: #fff;
            }
          }
        }
      }
      .answer-hanzi-area {
        flex: 3;
        height: 40%;
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        .hanzi-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 20px;
          border: 5px dashed #cfc8b0;
          border-radius: 20px;
          color: #f7f4e0;
          background: transparent;
          color: #fff;
          display: flex;
          flex-direction: column;
          width: 90px;
          height: 100px;
          padding: 20px 20px;
          justify-content: center;
          span {
            visibility: hidden;
            display: inline-block;
          }
        }
        .lesson-11 {
          height: 45%;
        }
        .active {
          border: 5px dashed #ce4c3f;
        }
        .hasInserted {
          border: none;
          background: #224e96;
          color: #fff;
          display: flex;
          flex-direction: column;
          padding: 20px 20px;
          span {
            visibility: visible;
          }
        }
      }
      .choose-hanzi-area {
        height: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        .hanzi-item {
          box-sizing: border-box;
          padding: 10px 30px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 20px;

          background-color: #ce524c;
          color: #fff;

          border-bottom: 12px solid #a53d32;
          border-top: 12px solid #d87065;

          border-radius: 30px;
          cursor: pointer;
          span {
            display: inline-block;
          }
        }
        .dispear {
          opacity: 0;
        }
        .hanzi-item:hover {
          background-color: #912016;
          border-bottom: 12px solid #d87065;
          border-top: 12px solid #a53d32;
        }
      }
    }
  }
  .common-button-container {
    position: absolute;
    bottom: 3px;
    right: 0;
    height: 20%;
    max-height: 160px;
  }
}
</style>
