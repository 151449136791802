
<template>
  <div class="audio-player-container">
    <img :src="audioImg" />
  </div>
</template>

<script>
import { textToAudio } from '@/assets/js/axios/api'
export default {
  props: {
    audioUrl: {
      type: String,
      require: true,
    },
    audioId: {
      type: Number,
      require: true,
    },
    title: {
      type: String,
      require: true,
      default: ''
    }
  },
  data() {
    return {
      audioImg: require("@/assets/img/06-Sound/sound-white.svg"),
    };
  },
  methods: {
    async play() {
      let audio = ''
      if(this.audioUrl){
        audio = new Audio(this.audioUrl);
      } else if(this.title){
        console.log(this.title);
        const res = await textToAudio({
        voice_type: '天才童声',
        text: this.title
      })
      const response = await res.json()
      if (response.code && response.code === 200) {
        const tts = response.data.tts
        const blob = await this.base64ToBlob(tts, 'audio/mpeg')
        const file = new File([blob], 'audio.mp3', { type: blob.type })
        const url = URL.createObjectURL(file)
        audio = new Audio(url);
      } else {
        this.$message({
          type: 'error',
          message: response.message
        })
      }
      } else {
        return
      }
      setTimeout(() => {
        audio.play();
      }, 150);
      this.audioImg = require("@/assets/img/06-Sound/sound-white-big.gif");
      setTimeout(() => {
        this.audioImg = require("@/assets/img/06-Sound/sound-white.svg");
      }, 1500);
    },
  },
};
</script>

<style lang="scss" scoped>
.audio-player-container {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
}
</style>