var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "common-button-container" },
    _vm._l(_vm.buttons, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "btn-item",
          on: {
            mouseenter: function ($event) {
              item.isHover = true
            },
            mouseleave: function ($event) {
              item.isHover = false
            },
          },
        },
        [
          _c("img", {
            attrs: { src: item.isHover ? item.imgHover : item.img, alt: "" },
            on: {
              click: function ($event) {
                return _vm.handleBtnClick(item.type)
              },
            },
          }),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }