var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "find-element-game-container layout-border" },
    [
      _c(
        "div",
        { staticClass: "game-content", class: "lesson-" + 57 },
        [
          _vm.titleInfo
            ? _c("div", { staticClass: "title-area" }, [
                _c("div", { staticClass: "title" }, [
                  _c(
                    "div",
                    { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                    [_vm._v(" " + _vm._s(_vm.titleInfo.pinyin) + " ")]
                  ),
                  _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
                    _vm._v(" " + _vm._s(_vm.titleInfo.hanzi) + " "),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c("img", { staticClass: "bg-img", attrs: { src: _vm.bgPic } }),
          _vm._l(_vm.imgList, function (item, index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.findIdList.indexOf(item.id) !== -1,
                    expression: "findIdList.indexOf(item.id) !== -1",
                  },
                ],
                key: index,
                staticClass: "img-area",
              },
              [_c("img", { attrs: { src: item.image, alt: "" } })]
            )
          }),
          _c(
            "div",
            { staticClass: "click-area" },
            _vm._l(_vm.imgList, function (item, index) {
              return _c("div", {
                key: index + "click",
                staticClass: "click-item",
                class: "click-" + item.id,
                on: {
                  click: function ($event) {
                    return _vm.handleClick(item.id)
                  },
                },
              })
            }),
            0
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }